import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';
import i18next from '../../Components/i18n';
import { ERating } from '../../Domain/Enum/ERating';
import PropertyHelper from './PropertyHelper';
import { injectable } from 'inversify';

@injectable()
export class EnumService {
    private _intOptionsCache = new Array<any>();

    public GetListIntOptionsLocalized<T extends number>(enumGetter: () => any): Array<ListIntOptionDTO> {
        let enumName = PropertyHelper.GetPropertyName(enumGetter);
        if (this._intOptionsCache[enumName] !== undefined){
            return this._intOptionsCache[enumName];
        }
        let enumVal = enumGetter();
        this._intOptionsCache[enumName] = this.GetValues<T>(enumVal).map(idx => {
            let option = new ListIntOptionDTO();
            option.Id = idx;// as any + 1;
            option.Name = i18next.t("enum:" + enumName + "_" + enumVal[idx]);
            return option;
        });

        return this._intOptionsCache[enumName];
    }

    public GetOptionLocalized<T extends number>(enumGetter: () => any, value: number): string {
        let enumName = PropertyHelper.GetPropertyName(enumGetter);
        let enumVal = enumGetter();

        return i18next.t("enum:" + enumName + "_" + enumVal[value]);
    }

    public GetOptionStringLocalized<T extends number>(enumGetter: () => any, value: string): string {
        let enumName = PropertyHelper.GetPropertyName(enumGetter);

        return i18next.t("enum:" + enumName + "_" + value);
    }

    private GetNames(e: any) {
        return this.GetObjValues(e).filter(v => typeof v === "string") as string[];
    }

    private GetValues<T extends number>(e: any) {
        return this.GetObjValues(e).filter(v => typeof v === "number") as T[];
    }

    private GetObjValues(e: any): (number | string)[] {
        return Object.keys(e).map(k => e[k]);
    }
}