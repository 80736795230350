import { injectable } from 'inversify';
import {
    CARGO_TRANSPORTER_CONTRACTS_LOADED, CARGO_TRANSPORTER_CONTRACTS_REMOVED, CARGO_TRANSPORTER_CONTRACT_ADDED,
    CARGO_TRANSPORTER_CONTRACT_EDITED, CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED, CARGO_TRANSPORTER_DRIVERS_LOADED, CARGO_TRANSPORTER_DRIVER_ADDED,
    CARGO_TRANSPORTER_DRIVER_EDITED, CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED,
    CARGO_TRANSPORTER_RATING_ADDED,
    CARGO_TRANSPORTER_RATING_EDITED, CARGO_TRANSPORTER_VEHICLES_LOADED, CARGO_TRANSPORTER_VEHICLE_ADDED,
    CARGO_TRANSPORTER_VEHICLE_EDITED
} from '../../../Constants/AppConstants';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { ContractTableDTO } from '../../../Domain/DTO/ContractTableDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { VehicleTableDTO } from '../../../Domain/DTO/VehicleTableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { CargoTransporterHelperProps } from './IHelperProps';

type IState = IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>;
@injectable()
export class CargoTransporterReducer extends EntityReducer<CargoTransporterDTO, CargoTransporterHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case CARGO_TRANSPORTER_DRIVERS_LOADED:
                return this.ReduceDriversLoaded(state, action.Payload)
            case CARGO_TRANSPORTER_VEHICLES_LOADED:
                return this.ReduceVehiclesLoaded(state, action.Payload)
            case CARGO_TRANSPORTER_DRIVER_ADDED:
                return this.ReduceDriverAdded(state, action.Payload);
            case CARGO_TRANSPORTER_DRIVER_EDITED:
                return this.ReduceDriverEdited(state, action.Payload);
            case CARGO_TRANSPORTER_VEHICLE_ADDED:
                return this.ReduceVehicleAdded(state, action.Payload);
            case CARGO_TRANSPORTER_VEHICLE_EDITED:
                return this.ReduceVehicleEdited(state, action.Payload);
            case CARGO_TRANSPORTER_RATING_ADDED:
                return this.ReduceRatingAdded(state, action.Payload);
            case CARGO_TRANSPORTER_RATING_EDITED:
                return this.ReduceRatingEdited(state, action.Payload);
            case CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED:
                return this.ReduceCurrentRatingsLoaded(state, action.Payload);
            case CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED:
                return this.ReduceForecastRatingsLoaded(state, action.Payload);
            case CARGO_TRANSPORTER_CONTRACTS_LOADED:
                return this.ReduceContractsLoaded(state, action.Payload)
            case CARGO_TRANSPORTER_CONTRACT_ADDED:
                return this.ReduceContractAdded(state, action.Payload);
            case CARGO_TRANSPORTER_CONTRACT_EDITED:
                return this.ReduceContractEdited(state, action.Payload);
            case CARGO_TRANSPORTER_CONTRACTS_REMOVED:
                return this.ReduceContractsRemoved(state, action.Payload);
            default:
                return state;
        }
    }    

    private ReduceContractsRemoved(state: IState, payload: string[]) : IState{
        const newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Contracts = super.CopyCollectionObject(state.DataHelper.Contracts);

        for(const id of payload){
            const index = newState.DataHelper.Contracts.findIndex(x => x.Id === id)
            if (index >= 0)
                newState.DataHelper.Contracts.splice(index, 1);
        }

        return newState;
    }

    private ReduceDriversLoaded(state: IState, payload: Array<DriverTableDTO>): IState {
        return super.CollectionLoaded<DriverTableDTO>(state, payload, [x => x.Drivers, x => x.DriversOriginal]);
    }

    private ReduceVehiclesLoaded(state: IState, payload: Array<VehicleTableDTO>): IState {
        return super.CollectionLoaded<VehicleTableDTO>(state, payload, [x => x.Vehicles, x => x.VehiclesOriginal]);
    }

    private ReduceCurrentRatingsLoaded(state: IState, payload: Array<RatingTableDTO>): IState {       
        return super.CollectionLoaded(state, payload, [x => x.CurrentRating, x => x.CurrentRatingOriginal]);
    }

    private ReduceForecastRatingsLoaded(state: IState, payload: Array<RatingTableDTO>): IState {
        return super.CollectionLoaded(state, payload, [x => x.ForecastRating]);
    }

    private ReduceDriverAdded(state: IState, payload: DriverTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper }, DataDTO: { ...state.DataDTO } };
        super.ItemAddedToCollection(newState.DataHelper, payload, [x => x.Drivers, x => x.DriversOriginal]);
        return newState;
    }

    private ReduceDriverEdited(state: IState, payload: DriverTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };
        super.ItemEditedInCollection(newState.DataHelper, payload, [x => x.Drivers, x => x.DriversOriginal]);
        return newState;
    }

    private ReduceVehicleAdded(state: IState, payload: VehicleTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper }, DataDTO: { ...state.DataDTO } };
        super.ItemAddedToCollection(newState.DataHelper, payload, [x => x.Vehicles, x => x.VehiclesOriginal]);
        return newState;
    }

    private ReduceVehicleEdited(state: IState, payload: VehicleTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };
        super.ItemEditedInCollection(newState.DataHelper, payload, [x => x.Vehicles, x => x.VehiclesOriginal]);
        return newState;
    }

    private ReduceRatingAdded(state: IState, payload: RatingTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper }};
        super.ItemAddedToCollection(newState.DataHelper, payload, [x => x.CurrentRating, x => x.CurrentRatingOriginal]);
        return newState;
    }

    private ReduceRatingEdited(state: IState, payload: RatingTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };
        super.ItemEditedInCollection(newState.DataHelper, payload, [x => x.CurrentRating, x => x.CurrentRatingOriginal]);
        return newState;
    }

    private ReduceContractsLoaded(state: IState, payload: Array<ContractTableDTO>): IState {
        return super.CollectionLoaded<ContractTableDTO>(state, payload, [x => x.Contracts, x => x.ContractsOriginal]);
    }

    private ReduceContractAdded(state: IState, payload: ContractTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper }, DataDTO: { ...state.DataDTO } };
        super.ItemAddedToCollection(newState.DataHelper, payload, [x => x.Contracts, x => x.ContractsOriginal]);
        return newState;
    }

    private ReduceContractEdited(state: IState, payload: ContractTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };
        super.ItemEditedInCollection<CargoTransporterHelperProps, CargoTransporterDTO>(newState.DataHelper, payload, [x => x.Contracts, x => x.ContractsOriginal]);
        return newState;
    }
}
