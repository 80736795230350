import { injectable } from 'inversify';
import {
    TENDER_BETS_LOADED,
    TENDER_CHANGELOGS_LOADED,
    TENDER_CONSIGNOR_LOADED,
    TENDER_DELIVERIES_LOADED,
    TENDER_LOADING_POINT_LOADED,
    TENDER_PAYLOAD_LOADED,
    TENDER_REJECTED_DELIVERIES_LOADED,
    TENDER_WINNER_LOADED,
    TENDER_WINNERS_BETS_LOADED,
    TENDER_ZONE_LOADED,
    TENDER_MULTI_FACTORY_LOADED,
} from '../../../Constants/AppConstants';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { TenderBetTableDTO } from '../../../Domain/DTO/TenderBetTableDTO';
import { TenderChangelogTableDTO } from '../../../Domain/DTO/TenderChangelogTableDTO';
import { TenderRejectedDeliveryTableDTO } from '../../../Domain/DTO/TenderRejectedDeliveryTableDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { MultiTransporterEntity, MultiTransporterTableDTO } from '../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes';
import { EntityAction } from '../BaseEntity/EntityAction';
import { MultiFactoryDTO } from '../../../Domain/DTO/MultiFactoryDTO';

@injectable()
export class TenderActions extends EntityAction {

    public LoadBets(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.Tender,
            id,
            EEntityType.TenderBet,
            undefined,
            undefined,
            waitingText,
            this.BetsLoaded);
    }

    public LoadWinnersBets(id: string, waitingText: string) {
		return super.LoadChildDataTableItems(
			EEntityType.Tender,
			id,
            EEntityType.TenderWinnerBets,
			(x: TenderBetTableDTO) => x.Timestamp,
            ESortDir.Desc,
            waitingText,
            this.WinnersBetsLoaded);
    }

    public LoadChangelogs(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.Tender,
            id,
            EEntityType.TenderChangelog,
            (x: TenderChangelogTableDTO) => x.Timestamp,
            ESortDir.Desc,
            waitingText,
            this.ChangelogsLoaded);
    }

    public LoadDeliveries(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.Tender,
            id,
            EEntityType.Delivery,
            (x: DeliveryTableDTO) => x.Identifier,
            ESortDir.Asc,
            waitingText,
            this.DeliveriesLoaded);
    }

    public LoadRejectedDeliveries(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.Tender,
            id,
            EEntityType.TenderRejectedDelivery,
            (x: TenderRejectedDeliveryTableDTO) => x.Identifier,
            ESortDir.Asc,
            waitingText,
            this.RejectedDeliveriesLoaded);
    }

    public LoadZone(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.Zone,
            waitingText,
            this.ZoneLoaded);
    }

    public LoadConsignor(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.Consignor,
            waitingText,
            this.ConsignorLoaded);
    }

    public LoadLoadingPoint(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.LoadingPoint,
            waitingText,
            this.LoadingPointLoaded);
    }

    public LoadMultiFactory(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.MultiFactory,
            waitingText,
            this.MultiFactoryLoaded);
    }

    public LoadWinnerCargoTransporter(id: string, waitingText: string, entity: MultiTransporterEntity) {
        return super.LoadEntityCached(
            id,
            entity,
            waitingText,
            this.WinnerCargoTransporterLoaded);
    }

    public LoadPayload(id: string, waitingText: string) {
        return super.LoadEntityCached<PayloadDTO>(
            id,
            EEntityType.Payload,
            waitingText,
            x => this.PayloadLoaded(x));
    }

    public BetsLoaded(value: Array<TenderBetTableDTO>): IActionPayloaded<Array<TenderBetTableDTO>> {
        return { type: TENDER_BETS_LOADED, Payload: value };
    }

    public WinnersBetsLoaded(value: Array<TenderBetTableDTO>): IActionPayloaded<Array<TenderBetTableDTO>> {
        return { type: TENDER_WINNERS_BETS_LOADED, Payload: value };
    }

    private ChangelogsLoaded(value: Array<TenderChangelogTableDTO>): IActionPayloaded<Array<TenderChangelogTableDTO>> {
        return { type: TENDER_CHANGELOGS_LOADED, Payload: value };
    }

    private DeliveriesLoaded(value: Array<DeliveryTableDTO>): IActionPayloaded<Array<DeliveryTableDTO>> {
        return { type: TENDER_DELIVERIES_LOADED, Payload: value };
    }

    private RejectedDeliveriesLoaded(value: Array<TenderRejectedDeliveryTableDTO>): IActionPayloaded<Array<TenderRejectedDeliveryTableDTO>> {
        return { type: TENDER_REJECTED_DELIVERIES_LOADED, Payload: value };
    }

    private ZoneLoaded(value: ZoneDTO): IActionPayloaded<ZoneDTO> {
        return { type: TENDER_ZONE_LOADED, Payload: value };
    }

    private ConsignorLoaded(value: ConsignorDTO): IActionPayloaded<ZoneDTO> {
        return { type: TENDER_CONSIGNOR_LOADED, Payload: value };
    }

    private LoadingPointLoaded(value: LoadingPointDTO): IActionPayloaded<LoadingPointDTO> {
        return { type: TENDER_LOADING_POINT_LOADED, Payload: value };
    }

    private MultiFactoryLoaded(value: MultiFactoryDTO): IActionPayloaded<MultiFactoryDTO> {
        return { type: TENDER_MULTI_FACTORY_LOADED, Payload: value };
    }

    private WinnerCargoTransporterLoaded(value: MultiTransporterTableDTO): IActionPayloaded<MultiTransporterTableDTO> {
        return { type: TENDER_WINNER_LOADED, Payload: value };
    }

    private PayloadLoaded(value: PayloadDTO): IActionPayloaded<PayloadDTO> {
        return { type: TENDER_PAYLOAD_LOADED, Payload: value };
    }
}