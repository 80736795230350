import { IEntityReducer } from '../Entity/BaseEntity/IEntityReducer';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';
import { IVolmaTableColumn } from '../Table/VolmaTable/IVolmaTableProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { FileExtendedDTO } from './FileExtendedDTO';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import i18next from '../i18n';
import { BaseEntityService } from '../Entity/BaseEntity/BaseEntityService';
import { FileReducer } from './FileReducer';
import VolmaFile from '../VolmaFile';
import * as React from 'react';
import { injectable } from 'inversify';


@injectable()
export class FileEntityService extends BaseEntityService<FileExtendedDTO, {}>{
    private _reducer: FileReducer;

    constructor() {
        super();

        this._reducer = VolmaContainer.get<FileReducer>(Types.FileReducer);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<FileExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<FileExtendedDTO, {}>): JSX.Element {
        return this.GetImportEditor(props);
    }

    public GetEditor(props: IBaseEntityProps<FileExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<FileExtendedDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: FileExtendedDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<FileExtendedDTO, {}> {
        return this._reducer;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private GetImportEditor(props: IBaseEntityProps<FileExtendedDTO, {}>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.FilePart(props))}
                </div>
            </div>
        );
    }

    private FilePart(props: IBaseEntityProps<FileExtendedDTO, {}>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaFile
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: FileExtendedDTO) => val.FileId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: FileExtendedDTO) => val.FileId)}
                            Label={i18next.t('file:File')}
                            Value={props.DataDTO.FileId}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}