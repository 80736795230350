// code copied from https://github.com/bvaughn/react-virtualized/releases/tag/8.8.1 since in the last
// version behaviour of autosizer has been changed and flags disableWidth and disableHeight
// completely remove width and height values from the child function calls (we are getting undefined instead
// of values)
/** @flow */
import React, { Component, ReactNode } from 'react'
import createDetectElementResize from './detectElementResize'

interface IVolmaAutoSizerProps {
  disableHeight?: boolean;
  disableWidth?:boolean;
  onResize?: (sizes: { height:number, width:number }) => void;
  children?: (sizes: { height:number, width:number }) => ReactNode;
}

interface IVolmaAutoSizerState {
  height: number;
  width:number;
}

interface IOuterStyle{
  overflow: string;
  height?: number;
  width?:number;
}
/**
 * Decorator component that automatically adjusts the width and height of a single child.
 * Child component should not be declared as a child but should rather be specified by a `ChildComponent` property.
 * All other properties will be passed through to the child component.
 */
class VolmaAutoSizer extends Component<IVolmaAutoSizerProps, IVolmaAutoSizerState> {
  static defaultProps = {
    onResize: () => {}
  };
  _parentNode: any;
  _autoSizer: any;
  _detectElementResize: { addResizeListener: (element: any, fn: any) => void; removeResizeListener: (element: any, fn: any) => void; };

  constructor (props) {
    super(props)

    this.state = {
      height: 0,
      width: 0
    }

    this._onResize = this._onResize.bind(this)
    this._setRef = this._setRef.bind(this)
  }

  componentDidMount () {
    // Delay access of parentNode until mount.
    // This handles edge-cases where the component has already been unmounted before its ref has been set,
    // As well as libraries like react-lite which have a slightly different lifecycle.
    this._parentNode = this._autoSizer.parentNode

    // Defer requiring resize handler in order to support server-side rendering.
    // See issue #41
    this._detectElementResize = createDetectElementResize()
    this._detectElementResize.addResizeListener(this._parentNode, this._onResize)

    this._onResize()
  }

  componentWillUnmount () {
    if (this._detectElementResize) {
      this._detectElementResize.removeResizeListener(this._parentNode, this._onResize)
    }
  }

  render () {
    const { children, disableHeight, disableWidth } = this.props
    const { height, width } = this.state

    // Outer div should not force width/height since that may prevent containers from shrinking.
    // Inner component should overflow and use calculated width/height.
    // See issue #68 for more information.
    const outerStyle:IOuterStyle = { overflow: 'visible' }

    if (!disableHeight) {
      outerStyle.height = 0
    }

    if (!disableWidth) {
      outerStyle.width = 0
    }

    return (
      <div
        ref={this._setRef}
        style={outerStyle}
      >
        {children({ height, width })}
      </div>
    )
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this._shallowCompare(this, nextProps, nextState)
  }

  private _onResize () {
    const { onResize } = this.props

    // Gaurd against AutoSizer component being removed from the DOM immediately after being added.
    // This can result in invalid style values which can result in NaN values if we don't handle them.
    // See issue #150 for more context.

    const boundingRect = this._parentNode.getBoundingClientRect()
    const height = boundingRect.height || 0
    const width = boundingRect.width || 0

    const style:any = window.getComputedStyle(this._parentNode) || {}
    const paddingLeft = parseInt(style.paddingLeft, 10) || 0
    const paddingRight = parseInt(style.paddingRight, 10) || 0
    const paddingTop = parseInt(style.paddingTop, 10) || 0
    const paddingBottom = parseInt(style.paddingBottom, 10) || 0

    this.setState({
      height: height - paddingTop - paddingBottom,
      width: width - paddingLeft - paddingRight
    })

    onResize({ height, width })
  }

  private _setRef (autoSizer) {
    this._autoSizer = autoSizer
  }

  private _shallowCompare(instance, nextProps, nextState) {
    return (
      !this._shallowEqual(instance.props, nextProps) ||
      !this._shallowEqual(instance.state, nextState)
    );
  }

  private _shallowEqual(objA, objB) {
    if (this._is(objA, objB)) {
      return true;
    }

    if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
      return false;
    }

    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (var i = 0; i < keysA.length; i++) {
      if (!objB.hasOwnProperty(keysA[i]) || !this._is(objA[keysA[i]], objB[keysA[i]])) {
        return false;
      }
    }

    return true;
  }

  private _is(x, y) {
    if (x === y) {
      // Steps 1-5, 7-10
      // Steps 6.b-6.e: +0 != -0
      // Added the nonzero y check to make Flow happy, but it is redundant
      return x !== 0 || y !== 0 || 1 / x === 1 / y;
    } else {
      // Step 6.a: NaN == NaN
      return x !== x && y !== y;
    }
  }
}

export default VolmaAutoSizer;