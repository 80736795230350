import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { ERating } from '../../../Domain/Enum/ERating';
import { GroupRate } from './GroupRate';

export class RouteRateHelperProps{
    RateList: Array<GroupRate>
    Settings: SettingsDTO
}

export const InitialRouteRateHelperProps: RouteRateHelperProps = <RouteRateHelperProps>{ 
    Settings: undefined,
    RateList: [{RateGroup: ERating.A, RateValue: 0}, {RateGroup: ERating.B, RateValue: 0}, {RateGroup: ERating.C, RateValue: 0}, {RateGroup: ERating.Outsiders, RateValue: 0}]
};