import { Container } from 'inversify';
import 'reflect-metadata';
import { AppActions } from '../Components/App/AppActions';
import { AboutActions } from '../Components/Entity/About/AboutActions';
import { AboutEntityService } from '../Components/Entity/About/AboutEntityService';
import { BaseEntityActions } from '../Components/Entity/BaseEntity/BaseEntityActions';
import { EntityAction } from '../Components/Entity/BaseEntity/EntityAction';
import { BiddingActions } from '../Components/Entity/Bidding/BiddingActions';
import { BiddingEntityService } from '../Components/Entity/Bidding/BiddingEntityService';
import { BiddingReducer } from '../Components/Entity/Bidding/BiddingReducer';
import { BodyTypeEntityService } from '../Components/Entity/BodyType/BodyTypeEntityService';
import { CalendarEntryActions } from '../Components/Entity/CalendarEntry/CalendarEntryActions';
import { CalendarEntryEntityService } from '../Components/Entity/CalendarEntry/CalendarEntryEntityService';
import { CalendarEntryReducer } from '../Components/Entity/CalendarEntry/CalendarEntryReducer';
import { CargoTransporterRouteRateActions } from '../Components/Entity/CargoTransporter/CargoTransporterRoteRate/CargoTransporterRouteRateActions';
import { CargoTransporterRouteRateEntityService } from '../Components/Entity/CargoTransporter/CargoTransporterRoteRate/CargoTransporterRouteRateEntityService';
import { CargoTransporterRouteRateReducer } from '../Components/Entity/CargoTransporter/CargoTransporterRoteRate/CargoTransporterRouteRateReducer';
import { CargoRouteRateTableActions } from '../Components/Entity/CargoTransporter/CargoTransporterRoteRate/Table/CargoRouteRateTableActions';
import { CargoRouteRateTableReducer } from '../Components/Entity/CargoTransporter/CargoTransporterRoteRate/Table/CargoRouteRateTableReducer';
import { DriverActions } from '../Components/Entity/CargoTransporter/Driver/DriverActions';
import { DriverEntityService } from '../Components/Entity/CargoTransporter/Driver/DriverEntityService';
import { ContractEntityService } from '../Components/Entity/CargoTransporter/Contract/ContractEntityService';
import { DriverReducer } from '../Components/Entity/CargoTransporter/Driver/DriverReducer';
import { ContractReducer } from '../Components/Entity/CargoTransporter/Contract/ContractReducer';
import { SelectVehicleActions } from '../Components/Entity/CargoTransporter/Driver/SelectVehicleDialog/SelectVehicleActions';
import { SelectVehicleEntityService } from '../Components/Entity/CargoTransporter/Driver/SelectVehicleDialog/SelectVehicleEntityService';
import { SelectContractEntityService } from '../Components/Entity/CargoTransporter/Contract/SelectContractDialog/SelectContractEntityService';
import { SelectContractActions } from '../Components/Entity/CargoTransporter/Contract/SelectContractDialog/SelectContractActions';
import { SelectVehicleReducer } from '../Components/Entity/CargoTransporter/Driver/SelectVehicleDialog/SelectVehicleReducer';
import { SelectContractReducer } from '../Components/Entity/CargoTransporter/Contract/SelectContractDialog/SelectContractReducer';
import { RatingActions } from '../Components/Entity/CargoTransporter/Rating/RatingActions';
import { RatingEntityService } from '../Components/Entity/CargoTransporter/Rating/RatingEntityService';
import { RatingReducer } from '../Components/Entity/CargoTransporter/Rating/RatingReducer';
import { RatingTableActions } from '../Components/Entity/CargoTransporter/Rating/Table/RatingTableActions';
import { RatingTableReducer } from '../Components/Entity/CargoTransporter/Rating/Table/RatingTableReducer';
import { SelectDriverActions } from '../Components/Entity/CargoTransporter/Vehicle/SelectDriverDialog/SelectDriverActions';
import { SelectDriverEntityService } from '../Components/Entity/CargoTransporter/Vehicle/SelectDriverDialog/SelectDriverEntityService';
import { SelectDriverReducer } from '../Components/Entity/CargoTransporter/Vehicle/SelectDriverDialog/SelectDriverReducer';
import { VehicleActions } from '../Components/Entity/CargoTransporter/Vehicle/VehicleActions';
import { ContractActions } from '../Components/Entity/CargoTransporter/Contract/ContractActions';
import { VehicleEntityService } from '../Components/Entity/CargoTransporter/Vehicle/VehicleEntityService';
import { VehicleReducer } from '../Components/Entity/CargoTransporter/Vehicle/VehicleReducer';
import { CargoTransporterActions } from '../Components/Entity/CargoTransporterEntity/CargoTransporterActions';
import { CargoTransporterEntityService } from '../Components/Entity/CargoTransporterEntity/CargoTransporterEntityService';
import { CargoTransporterReducer } from '../Components/Entity/CargoTransporterEntity/CargoTransporterReducer';
import { CargoTransporterTableActions } from '../Components/Entity/CargoTransporterEntity/Table/CargoTransporterTableActions';
import { CargoTransporterUserActions } from '../Components/Entity/CargoTransporterUser/CargoTransporterUserActions';
import { CargoTransporterUserEntityService } from '../Components/Entity/CargoTransporterUser/CargoTransporterUserEntityService';
import { CargoTransporterUserReducer } from '../Components/Entity/CargoTransporterUser/CargoTransporterUserReducer';
import { ComplaintCommentEntityService } from '../Components/Entity/Complaint/Comment/ComplaintCommentEntityService';
import { ComplaintActions } from '../Components/Entity/Complaint/ComplaintActions';
import { ComplaintEntityService } from '../Components/Entity/Complaint/ComplaintEntityService';
import { ComplaintHelper } from '../Components/Entity/Complaint/ComplaintHelper';
import { ComplaintReducer } from '../Components/Entity/Complaint/ComplaintReducer';
import { ComplaintActiveEntityService } from '../Components/Entity/Complaint/Subsidiaries/ComplaintActiveEntityService';
import { ComplaintClosedEntityService } from '../Components/Entity/Complaint/Subsidiaries/ComplaintClosedEntityService';
import { ComplaintDraftEntityService } from '../Components/Entity/Complaint/Subsidiaries/ComplaintDraftEntityService';
import { ComplaintWithNewMessagesEntityService } from '../Components/Entity/Complaint/Subsidiaries/ComplaintWithNewMessagesEntityService';
import { ComplaintTableActions } from '../Components/Entity/Complaint/Table/ComplaintTableActions';
import { ComplaintTemplateActions } from '../Components/Entity/ComplaintTemplate/ComplaintTemplateActions';
import { ComplaintTemplateEntityService } from '../Components/Entity/ComplaintTemplate/ComplaintTemplateEntityService';
import { ComplaintTemplateReducer } from '../Components/Entity/ComplaintTemplate/ComplaintTemplateReducer';
import { ConsignorActions } from '../Components/Entity/Consignor/ConsignorActions';
import { ConsignorEntityService } from '../Components/Entity/Consignor/ConsignorEntityService';
import { BiddingBetEntityService } from '../Components/Entity/Delivery/ActionBet/BiddingBetEntityService';
import { DeliveryConfirmActions } from '../Components/Entity/Delivery/ActionConfirm/DeliveryConfirmActions';
import { DeliveryConfirmEntityService } from '../Components/Entity/Delivery/ActionConfirm/DeliveryConfirmEntityService';
import { DeliveryConfirmReducer } from '../Components/Entity/Delivery/ActionConfirm/DeliveryConfirmReducer';
import { DeliveryManualAssignEntityService } from '../Components/Entity/Delivery/ActionManualAssign/DeliveryManualAssignEntityService';
import { DeliveryMarkAsDeliveredEntityService } from '../Components/Entity/Delivery/ActionMarkAsDelivered/DeliveryMarkAsDeliveredEntityService';
import { DeliveryNotExecutedEntityService } from '../Components/Entity/Delivery/ActionNotExecuted/DeliveryNotExecutedEntityService';
import { DeliveryOneCommentEntityService } from '../Components/Entity/Delivery/ActionOneComment/DeliveryOneCommentEntityService';
import { DeliveryUpdateAssignTypeEntityService } from '../Components/Entity/Delivery/ActionUpdateAssignType/DeliveryUpdateAssignTypeEntityService';
import { DeliveryWithdrawEntityService } from '../Components/Entity/Delivery/ActionWithdraw/DeliveryWithdrawEntityService';
import { DeliveryActions } from '../Components/Entity/Delivery/DeliveryActions';
import { DeliveryEntityService } from '../Components/Entity/Delivery/DeliveryEntityService';
import { DeliveryReducer } from '../Components/Entity/Delivery/DeliveryReducer';
import { DeliveryStateEntityService } from '../Components/Entity/Delivery/DeliveryState/DeliveryStateEntityService';
import { DeliveryStateHelper } from '../Components/Entity/Delivery/DeliveryState/DeliveryStateHelper';
import { RoutePointActions } from '../Components/Entity/Delivery/RouteSegment/RoutePointActions';
import { RoutePointEntityService } from '../Components/Entity/Delivery/RouteSegment/RoutePointEntityService';
import { RoutePointReducer } from '../Components/Entity/Delivery/RouteSegment/RoutePointReducer';
import { DeliveryActiveEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryActiveEntityService';
import { DeliveryAuctionEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryAuctionEntityService';
import { DeliveryFinishedEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryFinishedEntityService';
import { DeliveryFutureEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryFutureEntityService';
import { DeliveryManualEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryManualEntityService';
import { DeliveryPerformingEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryPerformingEntityService';
import { DeliveryToAcceptConfirmEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryToAcceptConfirmEntityService';
import { DeliveryWithClaimEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryWithClaimEntityService';
import { DeliveryTableActions } from '../Components/Entity/Delivery/Table/DeliveryTableActions';
import { DeliveryTableReducer } from '../Components/Entity/Delivery/Table/DeliveryTableReducer';
import { HandlingTypeEntityService } from '../Components/Entity/HandlingType/HandlingTypeEntityService';
import { LoadingPointEntityService } from '../Components/Entity/LoadingPoint/LoadingPointTypeEntityService';
import { LocalityActions } from '../Components/Entity/Locality/LocalityActions';
import { LocalityEntityService } from '../Components/Entity/Locality/LocalityEntityService';
import { LocalityReducer } from '../Components/Entity/Locality/LocalityReducer';
import { PayloadEntityService } from '../Components/Entity/Payload/PayloadEntityService';
import { PortalConfigurationActions } from '../Components/Entity/PortalConfiguration/PortalConfigurationActions';
import { PortalConfigurationEntityService } from '../Components/Entity/PortalConfiguration/PortalConfigurationEntityService';
import { PortalConfigurationReducer } from '../Components/Entity/PortalConfiguration/PortalConfigurationReducer';
import { ReportCargoTransportersRatingsExcelService } from '../Components/Entity/Report/ConcreteReports/ReportCargoTransportersRatingsExcelService';
import { ReportDeliveriesAttachedFilesService } from '../Components/Entity/Report/ConcreteReports/ReportDeliveriesAttachedFilesService';
import { ReportCargoTransportersRatingSummaryExcelService } from '../Components/Entity/Report/ConcreteReports/ReportCargoTransportersRatingSummaryExcelService';
import { ReportComplaintsExcelService } from '../Components/Entity/Report/ConcreteReports/ReportComplaintsExcelService';
import { ReportDeliveriesExcelService } from '../Components/Entity/Report/ConcreteReports/ReportDeliveriesExcelService';
import { ReportRatesExcelService } from '../Components/Entity/Report/ConcreteReports/ReportRatesExcelService';
import { ReportReceivedDeliveriesService } from '../Components/Entity/Report/ConcreteReports/ReportReceivedDeliveriesService';
import { ReportTendersExcelService } from '../Components/Entity/Report/ConcreteReports/ReportTendersExcelService';
import { ReportActions } from '../Components/Entity/Report/ReportActions';
import { ReportComponentActions } from '../Components/Entity/Report/ReportComponentActions';
import { ReportEntityService } from '../Components/Entity/Report/ReportEntityService';
import { ReportReducer } from '../Components/Entity/Report/ReportReducer';
import { RequestLogActions } from '../Components/Entity/RequestLog/RequestLogActions';
import { RequestLogLogEntityService } from '../Components/Entity/RequestLog/RequestLogEntityService';
import { UserRoleEntityService } from '../Components/Entity/Role/UserRoleEntityService';
import { RouteRateActions } from '../Components/Entity/RouteRate/RouteRateActions';
import { RouteRateEntityService } from '../Components/Entity/RouteRate/RouteRateEntityService';
import { RouteRateReducer } from '../Components/Entity/RouteRate/RouteRateReducer';
import { SearchComponentActions } from '../Components/Entity/Search/SearchComponentActions';
import { SearchComponentReducer } from '../Components/Entity/Search/SearchComponentReducer';
import { SettingsActions } from '../Components/Entity/Settings/SettingsActions';
import { SettingsEntityService } from '../Components/Entity/Settings/SettingsEntityService';
import { SettingsReducer } from '../Components/Entity/Settings/SettingsReducer';
import { ShipperEntityService } from '../Components/Entity/Shipper/ShipperEntityService';
import { ShipperUserActions } from '../Components/Entity/ShipperUser/ShipperUserActions';
import { ShipperUserEntityService } from '../Components/Entity/ShipperUser/ShipperUserEntityService';
import { ShipperUserReducer } from '../Components/Entity/ShipperUser/ShipperUserReducer';
import { TenderActiveEntityService } from '../Components/Entity/Tender/Subsidiary/TenderActiveEntityService';
import { TenderBettingPerformigEntityService } from '../Components/Entity/Tender/Subsidiary/TenderBettingPerformingEntityService';
import { TenderDraftEntityService } from '../Components/Entity/Tender/Subsidiary/TenderDraftEntityService';
import { TenderFinishedEntityService } from '../Components/Entity/Tender/Subsidiary/TenderFinishedEntityService';
import { TenderFutureEntityService } from '../Components/Entity/Tender/Subsidiary/TenderFutureEntityService';
import { TenderInactiveEntityService } from '../Components/Entity/Tender/Subsidiary/TenderInactiveEntityService';
import { TenderTableActions } from '../Components/Entity/Tender/Table/TenderTableActions';
import { TenderTableReducer } from '../Components/Entity/Tender/Table/TenderTableReducer';
import { TenderActions } from '../Components/Entity/Tender/TenderActions';
import { TenderBetEntityService } from '../Components/Entity/Tender/TenderBetEntityService';
import { TenderChangelogEntityService } from '../Components/Entity/Tender/TenderChangelogEntityService';
import { TenderDeliveryEntityService } from '../Components/Entity/Tender/TenderDeliveryEntityService';
import { TenderEntityService } from '../Components/Entity/Tender/TenderEntityService';
import { TenderReducer } from '../Components/Entity/Tender/TenderReducer';
import { TenderWithdrawEntityService } from '../Components/Entity/Tender/Withdraw/TenderWithdrawEntityService';
import { UpdatePasswordEntityService } from '../Components/Entity/UpdatePassword/UpdatePasswordEntityService';
import { ZoneActions } from '../Components/Entity/Zone/ZoneActions';
import { ZoneEntityService } from '../Components/Entity/Zone/ZoneEntityService';
import { ZoneReducer } from '../Components/Entity/Zone/ZoneReducer';
import { FileEntityService } from '../Components/File/FileEntityService';
import { FileReducer } from '../Components/File/FileReducer';
import { FooterActions } from '../Components/Footer/FooterActions';
import { HeaderActions } from '../Components/Header/HeaderActions';
import { LoginActions } from '../Components/Login/LoginActions';
import { MainInfoItemActions } from '../Components/MainInfoItem/MainInfoItemActions';
import { PasswordResetActions } from '../Components/PasswordReset/PasswordResetActions';
import { PasswordRestoreActions } from '../Components/PasswordRestore/PasswordRestoreActions';
import { RegisterActions } from '../Components/Register/RegisterActions';
import { ApiActions } from '../Components/Shared/ApiActions';
import { BaseTableActions } from '../Components/Table/BaseTable/BaseTableActions';
import { VolmaTableHeaderRenderers } from '../Components/Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableActions } from '../Components/Table/VolmaTable/VolmaTableActions';
import { VolmaTableReducer } from '../Components/Table/VolmaTable/VolmaTableReducer';
import { VolmaCalendarActions } from '../Components/VolmaCalendar/VolmaCalendarActions';
import { VolmaCalendarReducer } from '../Components/VolmaCalendar/VolmaCalendarReducer';
import { VolmaFileActions } from '../Components/VolmaFile/VolmaFileActions';
import { VolmaFileReducer } from '../Components/VolmaFile/VolmaFileReducer';
import { VolmaFormActions } from '../Components/VolmaForm/VolmaFormActions';
import { VolmaFormReducer } from '../Components/VolmaForm/VolmaFormReducer';
import { VolmaInputActions } from '../Components/VolmaInput/VolmaInputActions';
import { VolmaInputReducer } from '../Components/VolmaInput/VolmaInputReducer';
import { VolmaModalActions } from '../Components/VolmaModal/VolmaModalActions';
import { VolmaCheckBoxActions } from '../Components/VolmaCheckBox/VolmaCheckBoxActions';
import { VolmaCheckBoxReducer } from '../Components/VolmaCheckBox/VolmaCheckBoxReducer';
import { VolmaNumberActions } from '../Components/VolmaNumber/VolmaNumberActions';
import VolmaNumberReducer from '../Components/VolmaNumber/VolmaNumberReducer';
import { VolmaSelectActions } from '../Components/VolmaSelect/VolmaSelectActions';
import { VolmaSelectReducer } from '../Components/VolmaSelect/VolmaSelectReducer';
import { VolmaTimeActions } from '../Components/VolmaTime/VolmaTimeActions';
import { VolmaTimeReducer } from '../Components/VolmaTime/VolmaTimeReducer';
import { VolmaTimeTimeDTOActions } from '../Components/VolmaTimeTimeDTO/VolmaTimeTimeDTOActions';
import { VolmaTimeTimeDTOReducer } from '../Components/VolmaTimeTimeDTO/VolmaTimeTimeDTOReducer';
import { RouterService } from './Router/RouterService';
import { AboutServerInteraction } from './ServerInteraction/AboutServerInteraction';
import { AuthServerInteraction } from './ServerInteraction/AuthServerInteraction';
import { BiddingServerInteraction } from './ServerInteraction/BiddingServerInteraction';
import { CargoTransporterServerInteraction } from './ServerInteraction/CargoTransporterServerInteraction';
import { ComplaintServerInteraction } from './ServerInteraction/ComplaintServerInteraction';
import { DeliveryServerInteraction } from './ServerInteraction/DeliveryServerInteraction';
import { FileServerInteraction } from './ServerInteraction/FileServerInteraction';
import { PortalConfigurationServerInteraction } from './ServerInteraction/PortalConfigurationServerInteraction';
import { RatingServerInteraction } from './ServerInteraction/RatingServerInteraction';
import { ReportServerInteraction } from './ServerInteraction/ReportServerInteraction';
import { RequestServerInteraction } from './ServerInteraction/RequestServerInteraction';
import { SearchServerInteraction } from './ServerInteraction/SearchServerInteraction';
import { ServiceServerInteraction } from './ServerInteraction/ServiceServerInteraction';
import { SettingsServerInteraction } from './ServerInteraction/SettingsServerInteraction';
import { TableServerInteraction } from './ServerInteraction/TableServerInteraction';
import { TableSignalRServerInteraction } from './ServerInteraction/TableSignalRServerInteraction';
import { TenderServerInteraction } from './ServerInteraction/TenderServerInteraction';
import { UrlFabric } from './ServerInteraction/UrlFabric';
import { AuthenticationService } from './Services/AuthService';
import { AuthorizationService } from './Services/AccessService/AuthorizationService';
import { ChangelogService } from './Services/ChangelogService';
import { DeliveryService } from './Services/DeliveryService';
import { EntityService } from './Services/EntityService';
import { EnumService } from './Services/EnumService';
import { FormatService } from './Services/FormatService';
import { InMemoryStorageService } from './Services/InMemoryStorageService';
import { LocalStorageService } from './Services/LocalStorageService';
import { LogService } from './Services/LogService';
import { SignalRService } from './Services/SignalRService';
import { TimeService } from './Services/TimeService';
import { VolmaTableService } from './Services/VomaTableService';
import { HubProxyActions } from './SignalR/HubProxyActions';
import { Types } from './Types';
import { EmptyValidator } from './Validation/EmptyValidator';
import { NonEmptyTextValidator } from './Validation/NonEmptyTextValidatorValidator';
import { VolmaCalendarValidator } from './Validation/VolmaCalendarValidator';
import { VolmaFileValidator } from './Validation/VolmaFileValidator';
import { VolmaInputValidator } from './Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from './Validation/VolmaNumberValidator';
import { VolmaSelectValidator } from './Validation/VolmaSelectValidator';
import { VolmaTimeTimeDTOValidator } from './Validation/VolmaTimeTimeDTOValidator';
import { VolmaTimeValidator } from './Validation/VolmaTimeValidator';
import { TenderRatingActions } from '../Components/Entity/Tender/Rating/TenderRatingActions';
import { ConsigneeEntityService } from '../Components/Entity/Consignee/ConsigneeEntityService';
import { DeliveryComplaintEntityService } from '../Components/Entity/Delivery/Complaint/DeliveryComplaintEntityService';
import { DriverMonitoringActions } from '../Components/Entity/DriverMonitoring/DriverMonitoringActions';
import { DriverMonitoringReducer } from '../Components/Entity/DriverMonitoring/DriverMonitoringReducer';
import { DriverMonitoringEntityService } from '../Components/Entity/DriverMonitoring/DriverMonitoringEntityService';
import { ReportDriverScoreService } from '../Components/Entity/Report/ConcreteReports/ReportDriverScoreService';
import { LoadingPointAction } from '../Components/Entity/LoadingPoint/LoadingPointAction';
import { LoadingPointReducer } from '../Components/Entity/LoadingPoint/LoadingPointReducer';
import { VolmaMapWithSelectDTOReducer } from '../Components/VolmaMapWithSelect/VolmaMapWithSelectDTOReducer';
import { VolmaMapWithSelectDTOActions } from '../Components/VolmaMapWithSelect/VolmaMapWithSelectDTOActions';
import { MultiFactoryEntityService } from '../Components/Entity/MultiFactory/MultiFactoryEntityService';
import { MultiFactoryReducer } from '../Components/Entity/MultiFactory/MultiFactoryReducer';
import { VolmaTableCommonColumnDefinition } from '../Components/Table/VolmaTableCommonColumnDefinition';
import { MultiCargoTransporterEntityService } from '../Components/Entity/CargoTransporter/MultiCargoTransporter/MultiCargoTransporterEntityService';
import { MultiCargoTransporterActions } from '../Components/Entity/CargoTransporter/MultiCargoTransporter/MultiCargoTransporterActions';
import { MultiCargoTransporterReducer } from '../Components/Entity/CargoTransporter/MultiCargoTransporter/MultiCargoTransporterReducer';
import { VolmaTimeZoneValidator } from './Validation/VolmaTimeZoneValidator';
import { DeliveryManualAssignActions } from '../Components/Entity/Delivery/ActionManualAssign/DeliveryManualAssignActions';
import { DeliveryManualAssignReducer } from '../Components/Entity/Delivery/ActionManualAssign/DeliveryManualAssignReducer';
import { DeliveryForComplaintEntityService } from '../Components/Entity/Delivery/Subsidiary/DeliveryForComplaint';
import { InstructionReducer } from '../Components/Entity/Instruction/InstructionReducer';
import { InstructionEntityService } from './../Components/Entity/Instruction/InstructionEntityService';
import { TenderRejectedDeliveryEntityService } from '../Components/Entity/Tender/TenderRejectedDeliveryEntityService';


export let VolmaContainer = new Container();

// signalr
VolmaContainer.bind(Types.HubProxyActions).to(HubProxyActions).inSingletonScope();
VolmaContainer.bind(Types.SignalRService).to(SignalRService).inSingletonScope();

// validators
VolmaContainer.bind(Types.EmptyValidator).to(EmptyValidator).inSingletonScope();
VolmaContainer.bind(Types.NonEmptyTextValidator).to(NonEmptyTextValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaInputValidator).to(VolmaInputValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaNumberValidator).to(VolmaNumberValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaFileValidator).to(VolmaFileValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaCalendarValidator).to(VolmaCalendarValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeValidator).to(VolmaTimeValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeZoneValidator).to(VolmaTimeZoneValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaSelectValidator).to(VolmaSelectValidator).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeTimeDTOValidator).to(VolmaTimeTimeDTOValidator).inSingletonScope();

// server interaction
VolmaContainer.bind(Types.UrlFabric).to(UrlFabric).inSingletonScope();
VolmaContainer.bind(Types.AuthServerInteraction).to(AuthServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.TableServerInteraction).to(TableServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.TableSignalRServerInteraction).to(TableSignalRServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.FileServerInteraction).to(FileServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.SettingsServerInteraction).to(SettingsServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.ReportServerInteraction).to(ReportServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.DeliveryServerInteraction).to(DeliveryServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.TenderServerInteraction).to(TenderServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.PortalConfigurationServerInteraction).to(PortalConfigurationServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.RatingServerInteraction).to(RatingServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterServerInteraction).to(CargoTransporterServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.RequestServerInteraction).to(RequestServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.ComplaintServerInteraction).to(ComplaintServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.AboutServerInteraction).to(AboutServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.ServiceServerInteraction).to(ServiceServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.SearchServerInteraction).to(SearchServerInteraction).inSingletonScope();
VolmaContainer.bind(Types.BiddingServerInteraction).to(BiddingServerInteraction).inSingletonScope();

//table renderers
VolmaContainer.bind(Types.VolmaTableHeaderRenderers).to(VolmaTableHeaderRenderers).inSingletonScope();
VolmaContainer.bind(Types.VolmaTableCommonColumnDefinition).to(VolmaTableCommonColumnDefinition).inSingletonScope();

// services
VolmaContainer.bind(Types.EnumService).to(EnumService).inSingletonScope();
VolmaContainer.bind(Types.LogService).to(LogService).inSingletonScope();
VolmaContainer.bind(Types.AuthenticationService).to(AuthenticationService).inSingletonScope();
VolmaContainer.bind(Types.AuthorizationService).to(AuthorizationService).inSingletonScope();
VolmaContainer.bind(Types.LocalStorageService).to(LocalStorageService).inSingletonScope();
VolmaContainer.bind(Types.InMemoryStorageService).to(InMemoryStorageService).inSingletonScope();
VolmaContainer.bind(Types.RouterService).to(RouterService).inSingletonScope();
VolmaContainer.bind(Types.FormatService).to(FormatService).inSingletonScope();
VolmaContainer.bind(Types.ChangelogService).to(ChangelogService).inSingletonScope();
VolmaContainer.bind(Types.TimeService).to(TimeService).inSingletonScope();

VolmaContainer.bind(Types.EntityService).to(EntityService).inSingletonScope();
VolmaContainer.bind(Types.VolmaTableService).to(VolmaTableService).inSingletonScope();
VolmaContainer.bind(Types.PayloadEntityService).to(PayloadEntityService).inSingletonScope();
VolmaContainer.bind(Types.ZoneEntityService).to(ZoneEntityService).inSingletonScope();
VolmaContainer.bind(Types.LocalityEntityService).to(LocalityEntityService).inSingletonScope();
VolmaContainer.bind(Types.BodyTypeEntityService).to(BodyTypeEntityService).inSingletonScope();
VolmaContainer.bind(Types.LoadingPointEntityService).to(LoadingPointEntityService).inSingletonScope();
VolmaContainer.bind(Types.HandlingTypeEntityService).to(HandlingTypeEntityService).inSingletonScope();
VolmaContainer.bind(Types.RouteRateEntityService).to(RouteRateEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintTemplateEntityService).to(ComplaintTemplateEntityService).inSingletonScope();
VolmaContainer.bind(Types.MultiFactoryEntityService).to(MultiFactoryEntityService).inSingletonScope();
VolmaContainer.bind(Types.MultiCargoTransporterEntityService).to(MultiCargoTransporterEntityService).inSingletonScope();
VolmaContainer.bind(Types.DriverEntityService).to(DriverEntityService).inSingletonScope();
VolmaContainer.bind(Types.ContractEntityService).to(ContractEntityService).inSingletonScope();
VolmaContainer.bind(Types.VehicleEntityService).to(VehicleEntityService).inSingletonScope();
VolmaContainer.bind(Types.ShipperUserEntityService).to(ShipperUserEntityService).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterUserEntityService).to(CargoTransporterUserEntityService).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterEntityService).to(CargoTransporterEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryEntityService).to(DeliveryEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryFinishedEntityService).to(DeliveryFinishedEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryFutureEntityService).to(DeliveryFutureEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryActiveEntityService).to(DeliveryActiveEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryPerformingEntityService).to(DeliveryPerformingEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryWithClaimEntityService).to(DeliveryWithClaimEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryManualEntityService).to(DeliveryManualEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryAuctionEntityService).to(DeliveryAuctionEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryToAcceptConfirmEntityService).to(DeliveryToAcceptConfirmEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryStateEntityService).to(DeliveryStateEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryUpdateAssignTypeEntityService).to(DeliveryUpdateAssignTypeEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryManualAssignEntityService).to(DeliveryManualAssignEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryWithdrawEntityService).to(DeliveryWithdrawEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryMarkAsDeliveredEntityService).to(DeliveryMarkAsDeliveredEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryNotExecutedEntityService).to(DeliveryNotExecutedEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryOneCommentEntityService).to(DeliveryOneCommentEntityService).inSingletonScope();
VolmaContainer.bind(Types.BiddingBetEntityService).to(BiddingBetEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderEntityService).to(TenderEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderFinishedEntityService).to(TenderFinishedEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderActiveEntityService).to(TenderActiveEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderBettingPerformingEntityService).to(TenderBettingPerformigEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderFutureEntityService).to(TenderFutureEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderInactiveEntityService).to(TenderInactiveEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderDraftEntityService).to(TenderDraftEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderBetEntityService).to(TenderBetEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderDeliveryEntityService).to(TenderDeliveryEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderRejectedDeliveryEntityService).to(TenderRejectedDeliveryEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryComplaintEntityService).to(DeliveryComplaintEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderChangelogEntityService).to(TenderChangelogEntityService).inSingletonScope();
VolmaContainer.bind(Types.TenderWithdrawEntityService).to(TenderWithdrawEntityService).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterRouteRateEntityService).to(CargoTransporterRouteRateEntityService).inSingletonScope();
VolmaContainer.bind(Types.SettingsEntityService).to(SettingsEntityService).inSingletonScope();
VolmaContainer.bind(Types.CalendarEntryEntityService).to(CalendarEntryEntityService).inSingletonScope();
VolmaContainer.bind(Types.RatingEntityService).to(RatingEntityService).inSingletonScope();
VolmaContainer.bind(Types.ReportEntityService).to(ReportEntityService).inSingletonScope();
VolmaContainer.bind(Types.RoutePointEntityService).to(RoutePointEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryConfirmEntityService).to(DeliveryConfirmEntityService).inSingletonScope();
VolmaContainer.bind(Types.UserRoleEntityService).to(UserRoleEntityService).inSingletonScope();
VolmaContainer.bind(Types.SelectDriverEntityService).to(SelectDriverEntityService).inSingletonScope();
VolmaContainer.bind(Types.SelectVehicleEntityService).to(SelectVehicleEntityService).inSingletonScope();
VolmaContainer.bind(Types.SelectContractEntityService).to(SelectContractEntityService).inSingletonScope();
VolmaContainer.bind(Types.ShipperEntityService).to(ShipperEntityService).inSingletonScope();
VolmaContainer.bind(Types.PortalConfigurationEntityService).to(PortalConfigurationEntityService).inSingletonScope();
VolmaContainer.bind(Types.UpdatePasswordEntityService).to(UpdatePasswordEntityService).inSingletonScope();
VolmaContainer.bind(Types.ConsignorEntityService).to(ConsignorEntityService).inSingletonScope();
VolmaContainer.bind(Types.ConsigneeEntityService).to(ConsigneeEntityService).inSingletonScope();
VolmaContainer.bind(Types.RequestLogLogEntityService).to(RequestLogLogEntityService).inSingletonScope();
VolmaContainer.bind(Types.FileEntityService).to(FileEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintEntityService).to(ComplaintEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintCommentEntityService).to(ComplaintCommentEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintDraftEntityService).to(ComplaintDraftEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintActiveEntityService).to(ComplaintActiveEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintWithNewMessagesEntityService).to(ComplaintWithNewMessagesEntityService).inSingletonScope();
VolmaContainer.bind(Types.ComplaintClosedEntityService).to(ComplaintClosedEntityService).inSingletonScope();
VolmaContainer.bind(Types.AboutEntityService).to(AboutEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryService).to(DeliveryService).inSingletonScope();
VolmaContainer.bind(Types.BiddingEntityService).to(BiddingEntityService).inSingletonScope();
VolmaContainer.bind(Types.DriverMonitoringEntityService).to(DriverMonitoringEntityService).inSingletonScope();
VolmaContainer.bind(Types.DeliveryForComplaintEntityService).to(DeliveryForComplaintEntityService).inSingletonScope();
VolmaContainer.bind(Types.InstructionEntityService).to(InstructionEntityService).inSingletonScope();

// actions
VolmaContainer.bind(Types.EntityAction).to(EntityAction).inSingletonScope();
VolmaContainer.bind(Types.AppActions).to(AppActions).inSingletonScope();
VolmaContainer.bind(Types.HeaderActions).to(HeaderActions).inSingletonScope();
VolmaContainer.bind(Types.ApiActions).to(ApiActions).inSingletonScope();
VolmaContainer.bind(Types.LoginActions).to(LoginActions).inSingletonScope();
VolmaContainer.bind(Types.RegisterActions).to(RegisterActions).inSingletonScope();
VolmaContainer.bind(Types.PasswordRestoreActions).to(PasswordRestoreActions).inSingletonScope();
VolmaContainer.bind(Types.PasswordResetActions).to(PasswordResetActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaFormActions).to(VolmaFormActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaInputActions).to(VolmaInputActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaNumberActions).to(VolmaNumberActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaCheckBoxActions).to(VolmaCheckBoxActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaSelectActions).to(VolmaSelectActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaFileActions).to(VolmaFileActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaCalendarActions).to(VolmaCalendarActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeActions).to(VolmaTimeActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeTimeDTOActions).to(VolmaTimeTimeDTOActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaTableActions).to(VolmaTableActions).inSingletonScope();
VolmaContainer.bind(Types.VolmaModalActions).to(VolmaModalActions).inSingletonScope();
VolmaContainer.bind(Types.ZoneEntityActions).to(ZoneActions).inSingletonScope();
VolmaContainer.bind(Types.BaseEntityActions).to(BaseEntityActions).inSingletonScope();
VolmaContainer.bind(Types.BaseTableActions).to(BaseTableActions).inSingletonScope();
VolmaContainer.bind(Types.RouteRateActions).to(RouteRateActions).inSingletonScope();
VolmaContainer.bind(Types.ComplaintTemplateActions).to(ComplaintTemplateActions).inSingletonScope();
VolmaContainer.bind(Types.DriverActions).to(DriverActions).inSingletonScope();
VolmaContainer.bind(Types.VehicleActions).to(VehicleActions).inSingletonScope();
VolmaContainer.bind(Types.ContractActions).to(ContractActions).inSingletonScope();
VolmaContainer.bind(Types.ShipperUserActions).to(ShipperUserActions).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterActions).to(CargoTransporterActions).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterUserActions).to(CargoTransporterUserActions).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterTableActions).to(CargoTransporterTableActions).inSingletonScope();
VolmaContainer.bind(Types.DeliveryActions).to(DeliveryActions).inSingletonScope();
VolmaContainer.bind(Types.DeliveryTableActions).to(DeliveryTableActions).inSingletonScope();
VolmaContainer.bind(Types.TenderActions).to(TenderActions).inSingletonScope();
VolmaContainer.bind(Types.TenderTableActions).to(TenderTableActions).inSingletonScope();
VolmaContainer.bind(Types.ComplaintActions).to(ComplaintActions).inSingletonScope();
VolmaContainer.bind(Types.ComplaintTableActions).to(ComplaintTableActions).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterRouteRateActions).to(CargoTransporterRouteRateActions).inSingletonScope();
VolmaContainer.bind(Types.CargoRouteRateTableActions).to(CargoRouteRateTableActions).inSingletonScope();
VolmaContainer.bind(Types.SettingsActions).to(SettingsActions).inSingletonScope();
VolmaContainer.bind(Types.CalendarEntryActions).to(CalendarEntryActions).inSingletonScope();
VolmaContainer.bind(Types.RatingActions).to(RatingActions).inSingletonScope();
VolmaContainer.bind(Types.RatingTableActions).to(RatingTableActions).inSingletonScope();
VolmaContainer.bind(Types.TenderRatingActions).to(TenderRatingActions).inSingletonScope();
VolmaContainer.bind(Types.ReportActions).to(ReportActions).inSingletonScope();
VolmaContainer.bind(Types.ReportComponentActions).to(ReportComponentActions).inSingletonScope();
VolmaContainer.bind(Types.LocalityActions).to(LocalityActions).inSingletonScope();
VolmaContainer.bind(Types.RoutePointActions).to(RoutePointActions).inSingletonScope();
VolmaContainer.bind(Types.ConfirmActions).to(DeliveryConfirmActions).inSingletonScope();
VolmaContainer.bind(Types.DeliveryManualAssignActions).to(DeliveryManualAssignActions).inSingletonScope();
VolmaContainer.bind(Types.SelectDriverActions).to(SelectDriverActions).inSingletonScope();
VolmaContainer.bind(Types.SelectVehicleActions).to(SelectVehicleActions).inSingletonScope();
VolmaContainer.bind(Types.SelectContractActions).to(SelectContractActions).inSingletonScope();
VolmaContainer.bind(Types.PortalConfigurationActions).to(PortalConfigurationActions).inSingletonScope();
VolmaContainer.bind(Types.FooterActions).to(FooterActions).inSingletonScope();
VolmaContainer.bind(Types.ConsignorActions).to(ConsignorActions).inSingletonScope();
VolmaContainer.bind(Types.MainInfoItemActions).to(MainInfoItemActions).inSingletonScope();
VolmaContainer.bind(Types.RequestLogActions).to(RequestLogActions).inSingletonScope();
VolmaContainer.bind(Types.AboutActions).to(AboutActions).inSingletonScope();
VolmaContainer.bind(Types.SearchComponentActions).to(SearchComponentActions).inSingletonScope();
VolmaContainer.bind(Types.BiddingActions).to(BiddingActions).inSingletonScope();
VolmaContainer.bind(Types.DriverMonitoringActions).to(DriverMonitoringActions).inSingletonScope();
VolmaContainer.bind(Types.LoadingPointAction).to(LoadingPointAction).inSingletonScope();
VolmaContainer.bind(Types.VolmaMapWithSelectDTOAction).to(VolmaMapWithSelectDTOActions).inSingletonScope();
VolmaContainer.bind(Types.MultiCargoTransporterActions).to(MultiCargoTransporterActions).inSingletonScope();

// reducers
VolmaContainer.bind(Types.VolmaFormReducer).to(VolmaFormReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaNumberReducer).to(VolmaNumberReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaSelectReducer).to(VolmaSelectReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaInputReducer).to(VolmaInputReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaFileReducer).to(VolmaFileReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaCalendarReducer).to(VolmaCalendarReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeReducer).to(VolmaTimeReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaTimeTimeDTOReducer).to(VolmaTimeTimeDTOReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaTableReducer).to(VolmaTableReducer).inSingletonScope();
VolmaContainer.bind(Types.ZoneEntityReducer).to(ZoneReducer).inSingletonScope();
VolmaContainer.bind(Types.RouteRateReducer).to(RouteRateReducer).inSingletonScope();
VolmaContainer.bind(Types.ComplaintTemplateReducer).to(ComplaintTemplateReducer).inSingletonScope();
VolmaContainer.bind(Types.DriverReducer).to(DriverReducer).inSingletonScope();
VolmaContainer.bind(Types.ContractReducer).to(ContractReducer).inSingletonScope();
VolmaContainer.bind(Types.VehicleReducer).to(VehicleReducer).inSingletonScope();
VolmaContainer.bind(Types.ShipperUserReducer).to(ShipperUserReducer).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterUserReducer).to(CargoTransporterUserReducer).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterReducer).to(CargoTransporterReducer).inSingletonScope();
VolmaContainer.bind(Types.DeliveryReducer).to(DeliveryReducer).inSingletonScope();
VolmaContainer.bind(Types.DeliveryTableReducer).to(DeliveryTableReducer).inSingletonScope();
VolmaContainer.bind(Types.TenderReducer).to(TenderReducer).inSingletonScope();
VolmaContainer.bind(Types.TenderTableReducer).to(TenderTableReducer).inSingletonScope();
VolmaContainer.bind(Types.ComplaintReducer).to(ComplaintReducer).inSingletonScope();
VolmaContainer.bind(Types.CargoTransporterRouteRateReducer).to(CargoTransporterRouteRateReducer).inSingletonScope();
VolmaContainer.bind(Types.CargoRouteRateTableReducer).to(CargoRouteRateTableReducer).inSingletonScope();
VolmaContainer.bind(Types.SettingsReducer).to(SettingsReducer).inSingletonScope();
VolmaContainer.bind(Types.CalendarEntryReducer).to(CalendarEntryReducer).inSingletonScope();
VolmaContainer.bind(Types.RatingReducer).to(RatingReducer).inSingletonScope();
VolmaContainer.bind(Types.RatingTableReducer).to(RatingTableReducer).inSingletonScope();
VolmaContainer.bind(Types.ReportReducer).to(ReportReducer).inSingletonScope();
VolmaContainer.bind(Types.LocalityReducer).to(LocalityReducer).inSingletonScope();
VolmaContainer.bind(Types.RoutePointReducer).to(RoutePointReducer).inSingletonScope();
VolmaContainer.bind(Types.ConfirmReducer).to(DeliveryConfirmReducer).inSingletonScope();
VolmaContainer.bind(Types.DeliveryManualAssignReducer).to(DeliveryManualAssignReducer).inSingletonScope();
VolmaContainer.bind(Types.SelectDriverReducer).to(SelectDriverReducer).inSingletonScope();
VolmaContainer.bind(Types.SelectVehicleReducer).to(SelectVehicleReducer).inSingletonScope();
VolmaContainer.bind(Types.SelectContractReducer).to(SelectContractReducer).inSingletonScope();
VolmaContainer.bind(Types.PortalConfigurationReducer).to(PortalConfigurationReducer).inSingletonScope();
VolmaContainer.bind(Types.FileReducer).to(FileReducer).inSingletonScope();
VolmaContainer.bind(Types.SearchComponentReducer).to(SearchComponentReducer).inSingletonScope();
VolmaContainer.bind(Types.BiddingReducer).to(BiddingReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaCheckBoxReducer).to(VolmaCheckBoxReducer).inSingletonScope();
VolmaContainer.bind(Types.DriverMonitoringReducer).to(DriverMonitoringReducer).inSingletonScope();
VolmaContainer.bind(Types.LoadingPointReducer).to(LoadingPointReducer).inSingletonScope();
VolmaContainer.bind(Types.VolmaMapWithSelectDTOReducer).to(VolmaMapWithSelectDTOReducer).inSingletonScope();
VolmaContainer.bind(Types.MultiFactoryReducer).to(MultiFactoryReducer).inSingletonScope();
VolmaContainer.bind(Types.MultiCargoTransporterReducer).to(MultiCargoTransporterReducer).inSingletonScope();
VolmaContainer.bind(Types.InstructionReducer).to(InstructionReducer).inSingletonScope();

// reports
VolmaContainer.bind(Types.ReportTendersExcelService).to(ReportTendersExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportRatesExcelService).to(ReportRatesExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportDeliveriesExcelService).to(ReportDeliveriesExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportComplaintsExcelService).to(ReportComplaintsExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportCargoTransportersRatingsExcelService).to(ReportCargoTransportersRatingsExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportDeliveriesAttachedFilesService).to(ReportDeliveriesAttachedFilesService).inSingletonScope();
VolmaContainer.bind(Types.ReportCargoTransportersRatingSummaryExcelService).to(ReportCargoTransportersRatingSummaryExcelService).inSingletonScope();
VolmaContainer.bind(Types.ReportReceivedDeliveriesService).to(ReportReceivedDeliveriesService).inSingletonScope();
VolmaContainer.bind(Types.ReportDriverScore).to(ReportDriverScoreService).inSingletonScope();

// helpers
VolmaContainer.bind(Types.DeliveryStateHelper).to(DeliveryStateHelper).inSingletonScope();
VolmaContainer.bind(Types.ComplaintHelper).to(ComplaintHelper).inSingletonScope();
