import { injectable } from 'inversify';
import * as React from 'react';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { TimeDTO } from '../../../Domain/DTO/TimeDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { EmptyValidator } from '../../../Infrastructure/Validation/EmptyValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { VolmaTimeZoneValidator } from '../../../Infrastructure/Validation/VolmaTimeZoneValidator';
import i18next from '../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaCheckBox from '../../VolmaCheckBox';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaMapWithSelect from "../../VolmaMapWithSelect";
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import { VolmaTimeTimeDTO } from '../../VolmaTimeTimeDTO/VolmaTimeTimeDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ILoadingPointHelperProps, InitialLoadingPointHelperProps } from "./IHelperProps";
import "./LoadingPoint.scss";
import { LoadingPointAction } from './LoadingPointAction';
import { LoadingPointReducer } from './LoadingPointReducer';
@injectable()
export class LoadingPointEntityService extends BaseEntityService<LoadingPointDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;

    private _emptyValidator: EmptyValidator;
    private _timezoneValidator: VolmaTimeZoneValidator;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;

    private _dispatch: any;
    private _loadingPointAction: LoadingPointAction
    private _loadingPointReducer: LoadingPointReducer

    constructor() {
        super();
        
        this._loadingPointAction = VolmaContainer.get<LoadingPointAction>(Types.LoadingPointAction)
        this._loadingPointReducer = VolmaContainer.get<LoadingPointReducer>(Types.LoadingPointReducer)

        this._emptyValidator = VolmaContainer.get<EmptyValidator>(Types.EmptyValidator);  

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);   
        this._timezoneValidator = VolmaContainer.get<VolmaTimeZoneValidator>(Types.VolmaTimeZoneValidator);   
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
         
        this.GetTimeDtoCellText = this.GetTimeDtoCellText.bind(this);
        this.OnIsMaxPercentageAvailableUpdate = this.OnIsMaxPercentageAvailableUpdate.bind(this)
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.LoadingPoint]
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.LoadingPoint,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.Description), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.MaxPercentageOfBetIncrementation), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, 
                    CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined,
                    IsSortable: false,
                    IsFilterable: false
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.HasGeoCoordinates), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, 
                    CellRenderer: this.GeoCoordinateRenderer,
                    IsFilterable: false,
                    IsSortable: true
                },
            ]
        );
    }

    public GeoCoordinateRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        const cellData = params.cellData as LoadingPointTableDTO['HasGeoCoordinates'];
        let result: string = "";

        if (cellData === undefined || cellData === null || cellData === false) {
            result = i18next.t("common:NoData");
        } else {
            result =  i18next.t("common:FullData");
        }

        return (
            <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected })} style={style} key={key}>
                <div className={VolmaTableCommonCellRenderers._tableCont()}>
                    <span className={VolmaTableCommonCellRenderers._tableCont("text")}>{result}</span>
                </div>
            </div>
        )
    }


    public GetEntityId(props: IBaseEntityProps<LoadingPointTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: LoadingPointTableDTO) => x.Code),
            (x: LoadingPointTableDTO, id: string) => x.Code.toLowerCase() === id);
    }


    public GetEditorModal(props: IBaseEntityProps<LoadingPointDTO, any>): JSX.Element {
        const isEditable = this.IsEditable(props);
        const isAdmin = this._authService.IsAdministrator();
        return (
            <div className={this._mainInfo()}>
                {this.GetMainInfoNoDropItem(false, true, i18next.t("volmamap:Map"),                      
                    <VolmaMapWithSelect
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.GeoCoordinate) }
                        Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.GeoCoordinate)}
                        Label={i18next.t('loadingpoint:MainInfoTitle')}
                        Placeholder={i18next.t('loadingpoint:MainInfoDescription')}
                        Value={props.DataDTO.GeoCoordinate}
                        Validator={this._emptyValidator}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />)}
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("loadingpoint:MaxPercentageOfBetIncrementation"), i18next.t("loadingpoint:MainInfoDescription"), this.GetMaxPercentageBlock(props, isEditable, isAdmin), undefined, props, this._dispatch)}
                </div>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""])}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("loadingpoint:MainInfoTitle"), i18next.t("loadingpoint:MainInfoDescription"), this.GetMainInfoBlock(props, isEditable, isAdmin), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    public GetHeaderEditor(props: IBaseEntityProps<LoadingPointDTO, any>): JSX.Element{
        const isGodMode = this._authService.IsAdministrator && this.IsGodMode(props);
        return (
            <div>
                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.Code)}
                        Label={i18next.t('loadingpoint:Code')}
                        Placeholder={i18next.t('loadingpoint:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isGodMode}
                        dispatch={props.dispatch} />
                </div>
                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.Name)}
                        Label={i18next.t('loadingpoint:Name')}
                        Placeholder={i18next.t('loadingpoint:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isGodMode}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<LoadingPointDTO, any>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<LoadingPointDTO, any>){
        this._dispatch = props.dispatch;
    }

    public GetInitialDataHelper() {
        return InitialLoadingPointHelperProps;
    }

    public OnAfterDataLoaded(dto: LoadingPointDTO): void {       
        this._dispatch(this._loadingPointAction.setIsMaxPercentageAvailable(!this.IsMaxPercentageOfBetIncrementationNullable(dto.MaxPercentageOfBetIncrementation)))
    }
    
    public GetReducer(){
        return this._loadingPointReducer;
    }

    private GetMaxPercentageBlock(props: IBaseEntityProps<LoadingPointDTO, any>, isEditable: boolean, isAdministrator: boolean): JSX.Element  {
        return this.GetEditableMaxPercentageOfBetIncrementation(props, isAdministrator)
    }

    private GetMainInfoBlock(props: IBaseEntityProps<LoadingPointDTO, any>, isEditable: boolean, isAdministrator: boolean): JSX.Element  {
        const isGodMode = this._authService.IsAdministrator && this.IsGodMode(props);
        
        return (<div>
                <div className={this._mainInfo("row")}>
                    {this.GetTimezone(props, isAdministrator)}
                    <div className={this._mainInfo("cell", { full: true, 100: true })}>
                        <div className={this._mainInfo("line", { full: true })}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.Description) }
                                Type="textarea"
                                Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.Description)}
                                Label={i18next.t('loadingpoint:Description')}
                                Value={props.DataDTO.Description}
                                Validator={this._inputValidator}
                                Required={true}
                                Readonly={!isGodMode}
                                dispatch={props.dispatch}/>
                        </div>
                    </div>
                </div>
        </div>
           
        );
    }

    private GetTimezone(props: IBaseEntityProps<LoadingPointDTO, any>, isEditable: boolean): JSX.Element {
        if (props.DataDTO.Timezone === undefined) {
            props.DataDTO.Timezone = this.GetTimeFromMinutes(this._timeService.GetTimeZoneOffsetInMinutes());
        }
        return this.GetEditableTimezone(props, isEditable);
    }

    private GetEditableTimezone(props: IBaseEntityProps<LoadingPointDTO, any>, isEditable: boolean ): JSX.Element {
        return (
            <div className={this._mainInfo("cell", { full: true, 100: true })}>
                <div className={this._mainInfo("line", { full: true })}>
                    <VolmaTimeTimeDTO
                        {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.Timezone) as any) }
                        Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.Timezone)}
                        Label={i18next.t('loadingpoint:Timezone')}
                        Value= {props.DataDTO.Timezone}
                        CanBeNegative={true}
                        Validator={this._timezoneValidator}
                        Required={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    private IsMaxPercentageOfBetIncrementationNullable(value?: number) {
        return value === undefined || value as any === ""
    }

    private GetEditableMaxPercentageOfBetIncrementation(props: IBaseEntityProps<LoadingPointDTO, any>, isEditable: boolean): JSX.Element {       
        const isReadonly = !props.DataHelper.IsMaxPercentageAvailable 
        return (
            <div>
                <div className={this._mainInfo("cell", { full: true, 100: true })}>
                    <div className={this._mainInfo("line", { full: true })}>
                        <VolmaCheckBox
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ILoadingPointHelperProps) => val.IsMaxPercentageAvailable) }
                            Name={PropertyHelper.GetPropertyName((val: ILoadingPointHelperProps) => val.IsMaxPercentageAvailable)}
                            Label={i18next.t('loadingpoint:IsMaxPercentageAvailable')}
                            Checked={props.DataHelper.IsMaxPercentageAvailable}
                            Required={false}
                            CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.OnIsMaxPercentageAvailableUpdate(state, dtoFieldName, fieldVal, isEditable)}
                            Readonly={!isEditable}
                            dispatch={props.dispatch}
                        />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true, 100: true })}>
                    <div className={this._mainInfo("line", { full: true })}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LoadingPointDTO) => val.MaxPercentageOfBetIncrementation) }
                            Name={PropertyHelper.GetPropertyName((val: LoadingPointDTO) => val.MaxPercentageOfBetIncrementation)}
                            Label={i18next.t('loadingpoint:MaxPercentageOfBetIncrementation')}
                            Placeholder={i18next.t('loadingpoint:PlaceholderMaxPercentageOfBetIncrementation')}
                            Value={props.DataDTO.MaxPercentageOfBetIncrementation}
                            Validator={props.DataHelper.IsMaxPercentageAvailable ? this._numberValidator : this._emptyValidator}
                            MinValue={() => 0}
                            MaxValue={() => 100}
                            Disabled={isReadonly}
                            dispatch={props.dispatch}
                            Required={false}
                            Readonly={!isEditable || isReadonly}
                            CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.OnMaxPercentageOfBetIncrementationUpdate(state, dtoFieldName, fieldVal)}
                        />
                    </div>
                </div>
            </div>
            
        );
    }

    private OnIsMaxPercentageAvailableUpdate(state: IBaseEntityProps<LoadingPointDTO, any>, dtoFieldName: string, fieldValue: any, isEditable: boolean): void {
        if (!isEditable) {
            return;
        }

        state.DataHelper.IsMaxPercentageAvailable = fieldValue

        if (fieldValue && this.IsMaxPercentageOfBetIncrementationNullable(state.DataDTO.MaxPercentageOfBetIncrementation)) {
            state.DataDTO.MaxPercentageOfBetIncrementation = 0
            // ситуация, когда мы только отметили чекбокс, в MaxPercentageOfBetIncrementation у нас лежит undefined, но мы хотим всех обмануть
        }

        if (!fieldValue) {
            state.DataDTO.MaxPercentageOfBetIncrementation = undefined
        } 
    }

    private OnMaxPercentageOfBetIncrementationUpdate(state: IBaseEntityProps<LoadingPointDTO, any>, dtoFieldName: string, fieldValue: any) {
        if (state.DataHelper.IsMaxPercentageAvailable) {
            state.DataDTO.MaxPercentageOfBetIncrementation = fieldValue
        } else {
            state.DataDTO.MaxPercentageOfBetIncrementation = undefined
        }
    }

    private GetTimeDtoCellText(props: IVolmaTableProps<{}>, rowIndex: number) {
        let dto = props.Data[rowIndex][PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.Timezone)] as TimeDTO;
        if(dto === undefined)
        {
            let timezoneMinutes = this._timeService.GetTimeZoneOffsetInMinutes();
            dto = this.GetTimeFromMinutes(timezoneMinutes);
        }
        return this.FormatTimeDTO(dto);
    }

    private FormatTimeDTO(time: TimeDTO): string {
        return (time.IsNegative ? "-" : "+") +  i18next.t("common:TimeDTO", time)
    }

    private GetTimeFromMinutes(totalMinutes: number): TimeDTO {
        const timezone = totalMinutes;
        const hours = Math.abs(Math.floor(timezone/60));
        const minutes = Math.abs(Math.floor(timezone - hours*60));
        const time = new TimeDTO();
        time.Hours = hours;
        time.Minutes = minutes;
        time.IsNegative = timezone < 0;
        return time;
    }
}