import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import i18next from '../../../i18n';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import { DeliveryMarkAsDeliveredDTO } from '../ActionMarkAsDelivered/DeliveryMarkAsDeliveredDTO';

export class MarkAsDeliveredAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:MarkAsDelivered";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean {
        if(this._authService.IsCargoTransporter() || this._authService.IsShipper()){
            if (selectedIds === undefined || selectedIds.length !== 1)
                return false;

            for(let selectedId of selectedIds){
                let itm = data.find(x => x.Id === selectedId);
                if (itm === undefined)
                    return false
                if (/*itm.State !== EDeliveryState.Approved && 
                    itm.State !== EDeliveryState.WaitForLoading && */
                    itm.State !== EDeliveryState.VehicleArrivedAtLoadingPoint && 
                    itm.State !== EDeliveryState.VehicleArrivedAtUnloadingPoint && 
                    itm.State !== EDeliveryState.LatenessRegistration && 
                    itm.State !== EDeliveryState.LoadingBegin && 
                    itm.State !== EDeliveryState.LoadingEnd && 
                    itm.State !== EDeliveryState.Delivery)
                    return false
            }
            return true;
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        let dto = new DeliveryMarkAsDeliveredDTO();
        dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryMarkAsDelivered, dto, undefined, (dto) => {
            let stateDTO = new DeliveryStateDTO();
            stateDTO.DeliveryId = id;
            stateDTO.DeliveryState = EDeliveryState.Delivered;
            stateDTO.CommentsForCargoTransporter = dto.Comment;
			stateDTO.CommentsForShipper = dto.Comment;
	        stateDTO.FileId = dto.FileId;

            dispatch(this._deliveryTableActions.DeliveryAddState(stateDTO, i18next.t("common:Loading"), reloadData));
        }))
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}