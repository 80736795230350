import { ICommonProps } from './ICommonProps';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { IReduce } from '../../Infrastructure/Reducer/IReducer';

export interface IBaseInputProps<T> extends ICommonProps {
    Name?: string,
    Label?: string,
    Placeholder?: string,
    Required?: any,
    Readonly?: boolean,
    Disabled?: boolean,

    Validator?: BaseValidator<T>;
    Reducer?: IReduce<any>;

    IsTouched?: boolean;
    IsSubmitted?: boolean;
    IsValid?: boolean;
    ErrorMessage?: string;

    AdditionalText?: string;

    SkipRegistration?: boolean;
}

export const VolmaBaseInputPropsInitial: IBaseInputProps<any> = <IBaseInputProps<any>>{
    IsTouched: false,
    IsSubmitted: false,
    IsValid: true,
    ErrorMessage: "",
    Reducer: undefined,
    SkipRegistration: false,

    Readonly: false,
    Required: false,
    Disabled: false,
};