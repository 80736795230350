import { LocalityTableDTO } from '../../../../Domain/DTO/LocalityTableDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import { TimeDTO } from '../../../../Domain/DTO/TimeDTO';

export class RoutePointHelperProps{
    AllLocalities   : Array<LocalityTableDTO>
    AllLoadingPoints: Array<LoadingPointTableDTO>
    AllHandlingTypes: Array<HandlingTypeTableDTO>

    SelectedLocality     : LocalityTableDTO;
    SelectedLoadingPoint : LoadingPointTableDTO;
    SelectedUnloadingType: HandlingTypeTableDTO;
    
    ArrivalTime: TimeDTO;
    EditingExtraInfoFieldIds: Array<string>

    HasCustomPointField: boolean;
}

export const InitialRoutePointHelperProps: RoutePointHelperProps = <RoutePointHelperProps>{ 
    AllLocalities: [], 
    AllLoadingPoints: [], 
    AllHandlingTypes: [], 

    EditingExtraInfoFieldIds: [],
    ArrivalTime: new TimeDTO()
};

