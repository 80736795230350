import { ERegisterResult } from '../../Domain/Enum/ERegisterResult';
import { ERestorePasswordResult } from '../../Domain/Enum/ERestorePasswordResult';
import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';

export interface IRegisterHelperProps {
    AllRoles: Array<ListIntOptionDTO>;
    SelectedRole: ListIntOptionDTO

    RegisterResult: ERegisterResult;
    Errors: Array<string>

}

export const InitialRegisterHelperProps: IRegisterHelperProps = {
    AllRoles: [],
    SelectedRole: undefined,

    RegisterResult: undefined,
    Errors: []
}