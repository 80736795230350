import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import {
    CargoTransporterServerInteraction
} from '../../../../Infrastructure/ServerInteraction/CargoTransporterServerInteraction';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';


@injectable()
export class CargoTransporterTableActions extends EntityAction {

    private _modalActions       : VolmaModalActions;
    private _cargotransporterApi: CargoTransporterServerInteraction;

    constructor() {
        super();

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._cargotransporterApi = VolmaContainer.get<CargoTransporterServerInteraction>(Types.CargoTransporterServerInteraction);
    }
    
    public CargoTransporterImport(id: string, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._cargotransporterApi.Import(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }
}