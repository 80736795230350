import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaTimeProps } from '../VolmaTime/IVolmaTimeProps';
import { VolmaTimePropsInitial } from './IVolmaTimeProps';
import { IVolmaTimeValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
export class VolmaTimeReducer extends BaseInputReducer<IVolmaTimeProps> {

    public Reduce(state: IVolmaTimeProps = VolmaTimePropsInitial, action: IActionPayloaded<any>): IVolmaTimeProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaTimeProps, payload: IVolmaTimeValueChangedPayload) {
        let newState: IVolmaTimeProps = {...state, Value: payload.Value};
        return newState;
    }
}

