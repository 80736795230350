import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { Types } from '../../../Infrastructure/Types';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { IVolmaTableAction } from './IVolmaTableProps';
import { TimeService } from '../../../Infrastructure/Services/TimeService';

export abstract class VolmaTableAction<THelper> implements IVolmaTableAction<THelper>{
    protected _authService: AuthenticationService;
    protected _timeService: TimeService;

    constructor()
    {
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._timeService = VolmaContainer.get<TimeService>(Types.TimeService);
    }
    
    abstract Title: string;
    abstract IsAvailable(selectedIds: Array<string>, data: Array<ITableDTO>, dtoHelper: THelper): boolean;
    abstract OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ITableDTO>, dispatch: any, reloadData: () => void, dateHelper: any): void;
}
