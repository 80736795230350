import {
    FORM_SUBMITTED,
    INPUT_CALENDAR_TOGGLE_OVERLAY,
    INPUT_FILE_PROGRESS_UPDATED,
    INPUT_FILE_REMOVE_FILE,
    INPUT_FILE_FILES_CHANGED,
    INPUT_FILE_UPLOAD_ERROR,
    INPUT_FILE_UPLOAD_STARTED,
    INPUT_FILE_UPLOAD_SUCCESSFULLY,
    INPUT_FILE_FILE_INFO_LOADED,
    INPUT_REGISTER,
    INPUT_REMOVE_ACTIVE,
    INPUT_SET_ACTIVE,
    INPUT_SET_VALIDATOR,
    INPUT_TOUCHED,
    INPUT_VALIDATE,
    INPUT_VALUE_CHANGE,
    SELECT_REGISTER,
    SELECT_TOUCHED,
    SELECT_VALIDATE,
    SELECT_VALUE_CHANGE,
    VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED,
    VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE,
    VOLMA_TABLE_CLEAR_SELECTION,
    VOLMA_TABLE_CLOSE_FILTER,
    VOLMA_TABLE_DATA_LOADED,
    VOLMA_TABLE_DATA_UPDATED,
    VOLMA_TABLE_EDIT_ROW_REQUIRED,
    VOLMA_TABLE_OPEN_FILTER,
    VOLMA_TABLE_REGISTER,
    VOLMA_TABLE_REMOVE_COLUMN_FILTER,
    VOLMA_TABLE_SORT,
    VOLMA_TABLE_TOGGLE_ROW_SELECTION
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IVolmaTableProps, VolmaTablePropsInitial } from '../Table/VolmaTable/IVolmaTableProps';
import { ITableRegisterPayload, IVolmaTablePayload } from '../Table/VolmaTable/Payload';
import { IVolmaInputProps } from '../VolmaInput/IVolmaInputProps';
import { VolmaInputPropsInitial } from '../VolmaInput/IVolmaInputProps';
import { IInputPayload, IRegisterPayload } from '../VolmaInput/Payloads';
import { IVolmaSelectProps } from '../VolmaSelect/IVolmaSelectProps';
import { ISelectPayload, ISelectRegisterPayload } from '../VolmaSelect/Payloads';
import { IVolmaFormProps } from './IVolmaFormProps';
import { injectable } from 'inversify';
import { VOLMA_ENTITY_DATA_LOADED, VOLMA_TABLE_FILTER_OPTIONS, VOLMA_TABLE_SORT_OPTIONS, VOLMA_TABLE_UPDATE_FILTERS } from '../../Constants/AppConstants';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { LocalStorageService } from '../../Infrastructure/Services/LocalStorageService';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';



@injectable()
export class VolmaFormReducer {

    private _localStorageService: LocalStorageService;

    constructor(){
        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public Reduce<T extends IVolmaFormProps>(state: T, action: IActionPayloaded<any>): T {
        switch (action.type) {
            case FORM_SUBMITTED:
                return this.ReduceSubmitted(state);
            case VOLMA_TABLE_REGISTER:
                return this.ReduceRegisterTable(state, action.Payload);

            case VOLMA_ENTITY_DATA_LOADED:
                state = this.ReduceEntityDataLoaded(state, action.Payload);
                return this.ReduceValidate(state);

            case INPUT_VALUE_CHANGE:
                return this.ReduceInput(state, action);
            case INPUT_REGISTER:
                return this.ReduceRegisterInput(state, action.Payload);
            case INPUT_TOUCHED:
                state = this.ReduceTouched(state);
                return this.ReduceInput(state, action);
            case INPUT_VALIDATE:
                state = this.ReduceInput(state, action);
                return this.ReduceValidate(state);

            case INPUT_SET_ACTIVE:
            case INPUT_REMOVE_ACTIVE:
                return this.ReduceInput(state, action);

            case SELECT_REGISTER:
                return this.ReduceRegisterSelect(state, action.Payload);
            case SELECT_VALUE_CHANGE:
                return this.ReduceSelect(state, action);
            case SELECT_TOUCHED:
                state = this.ReduceTouched(state);
                return this.ReduceSelect(state, action);
            case SELECT_VALIDATE:
                state = this.ReduceSelect(state, action);
                return this.ReduceValidate(state);

            case INPUT_FILE_REMOVE_FILE:
            case INPUT_FILE_FILES_CHANGED:
            case INPUT_FILE_UPLOAD_ERROR:
            case INPUT_FILE_UPLOAD_STARTED:
            case INPUT_FILE_UPLOAD_SUCCESSFULLY:
            case INPUT_FILE_FILE_INFO_LOADED:
            case INPUT_FILE_PROGRESS_UPDATED:
            case INPUT_CALENDAR_TOGGLE_OVERLAY:
            case INPUT_SET_VALIDATOR:
                return this.ReduceInput(state, action);
            case VOLMA_TABLE_OPEN_FILTER:
            case VOLMA_TABLE_CLOSE_FILTER:
            case VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE:
            case VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED:
            case VOLMA_TABLE_REMOVE_COLUMN_FILTER:
            case VOLMA_TABLE_SORT:
            case VOLMA_TABLE_TOGGLE_ROW_SELECTION:
            case VOLMA_TABLE_EDIT_ROW_REQUIRED:
            case VOLMA_TABLE_DATA_LOADED:
            case VOLMA_TABLE_DATA_UPDATED:
            case VOLMA_TABLE_CLEAR_SELECTION:
            case VOLMA_TABLE_UPDATE_FILTERS:
                return this.ReduceTable(state, action);
            default:
                return state;
        }
    }

    private ReduceRegisterInput<T extends IVolmaFormProps>(state: T, payload: IRegisterPayload): T {
        let inputs = state.Inputs;
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].Name === payload.InputName) {
                return state;
            }
        }

        let input = <IVolmaInputProps>{...VolmaInputPropsInitial, Name: payload.InputName, Reducer: payload.Reducer, Validator: payload.Validator, ...payload.Props};
        let newState: T = <T>{ ...<IVolmaFormProps>state, Inputs: state.Inputs.slice(0)};
        newState.Inputs.push(input);
        return newState;
    }

    private ReduceRegisterSelect<T extends IVolmaFormProps>(state: T, payload: ISelectRegisterPayload): T {
        let selects = state.Selects;
        for (let i = 0; i < selects.length; i++) {
            if (selects[i].Name === payload.SelectName) {
                return state;
            }
        }

        let select = <IVolmaSelectProps>{...VolmaInputPropsInitial,
            Name: payload.SelectName,
            DTOFieldName: payload.DTOFieldName,
            HelperFieldName: payload.HelperFieldName,
            HelperFieldToDTOFieldTranslator: payload.HelperFieldToDTOFieldTranslator,
            Reducer: payload.Reducer,
            Validator: payload.Validator,
            ...payload.Props};
        let newState: T = <T>{ ...<IVolmaFormProps>state, Selects: state.Selects.slice(0)};
        newState.Selects.push(select);
        return newState;
    }

    private ReduceRegisterTable<T extends IVolmaFormProps>(state: T, payload: ITableRegisterPayload): T {
        let tables = state.Tables;
        for (let i = 0; i < tables.length; i++) {
            if (tables[i].Name === payload.TableName) {
                return state;
            }
        }

        let table = <IVolmaTableProps<any>>{...VolmaTablePropsInitial, Name: payload.TableName, Reducer: payload.Reducer, ...payload.Props};
        let newState: T = <T>{ ...<IVolmaFormProps>state, Tables: state.Tables.slice(0)};

        newState.Tables.push(table);
        return newState;
    }

    private ReduceEntityDataLoaded<T extends IVolmaFormProps>(state: T, payload: IEntityDTO): T{
        let newState = <T>{ ...<IVolmaFormProps>state};
        for(let key in payload){
            for (let i = 0; i < newState.Inputs.length; i ++){
                if (newState.Inputs[i].Name === key){
                    newState.Inputs[i].Value = payload[key];
                    newState.Inputs[i] = newState.Inputs[i].Reducer.Reduce(newState.Inputs[i], {type: INPUT_VALIDATE})
                }
            }
            for (let i = 0; i < newState.Selects.length; i ++){
                if (newState.Selects[i].Name === key){
                    newState.Selects[i].Value = payload[key];
                    newState.Selects[i] = newState.Selects[i].Reducer.Reduce(newState.Selects[i], {type: INPUT_VALIDATE})
                }
            }
        }

        return newState;
    }

    private ReduceTouched<T extends IVolmaFormProps>(state: T): T {
        let newState: T = <T>{ ...<IVolmaFormProps>state, IsTouched: true};
        return newState;
    }

    private ReduceSubmitted<T extends IVolmaFormProps>(state: T): T {
        let newState: T = <T>{ ...<IVolmaFormProps>state, IsSubmitted: true, Inputs: state.Inputs.slice(), Selects: state.Selects.slice()};
        for (let i = 0; i < newState.Inputs.length; i++) {
            newState.Inputs[i].IsSubmitted = true;
        }
        for (let i = 0; i < newState.Selects.length; i++) {
            newState.Selects[i].IsSubmitted = true;
        }

        return newState;
    }

    private ReduceValidate<T extends IVolmaFormProps>(state: T): T {
        let isValid = true;

        if (state.Inputs.length > 0) {
            isValid = isValid && state.Inputs.map(x => x.IsValid).reduce((first, second) => first && second);
        }

        if (state.Selects.length > 0) {
            isValid = isValid && state.Selects.map(x => x.IsValid).reduce((first, second) => first && second);
        }

        if (isValid !== state.IsValid) {
            return <T>{ ...<IVolmaFormProps>state, IsValid: isValid };
        }
        
        return state;
    }

    private ReduceInput<T extends IVolmaFormProps>(state: T, action: IActionPayloaded<IInputPayload>): T {
        let newState: T = <T>{ ...<IVolmaFormProps>state, Inputs: state.Inputs.slice() };

        let inputs = newState.Inputs;
        let inputStateIndex = inputs.findIndex(x => x.Name === action.Payload.InputName);

        if (inputStateIndex < 0) {
            console.warn("Unregistered input name '" + action.Payload.InputName + "'");
            return state;
        }

        inputs[inputStateIndex] = inputs[inputStateIndex].Reducer.Reduce(inputs[inputStateIndex], action);

        return newState;
    }

    private ReduceSelect<T extends IVolmaFormProps>(state: T, action: IActionPayloaded<ISelectPayload>): T {
        let newState: T = <T>{ ...<IVolmaFormProps>state, Selects: state.Selects.slice() };

        let selects = newState.Selects;
        let selectStateIndex = selects.findIndex(x => x.Name === action.Payload.SelectName);

        if (selectStateIndex < 0) {
            console.warn("Unregistered select name '" + action.Payload.SelectName + "'");
            return state;
        }

        selects[selectStateIndex] = selects[selectStateIndex].Reducer.Reduce(selects[selectStateIndex], action);

        return newState;
    }

    private ReduceTable<T extends IVolmaFormProps>(state: T, action: IActionPayloaded<IVolmaTablePayload>): T {
        let newState: T = <T>{ ...<IVolmaFormProps>state, Tables: state.Tables.slice() };

        let tables = newState.Tables;
        let tableStateIndex = tables.findIndex(x => x.Name === action.Payload.TableName);

        if (tableStateIndex < 0) {
            console.warn("Unregistered table name '" + action.Payload.TableName + "'")
            return state;
        }

        tables[tableStateIndex] = tables[tableStateIndex].Reducer.Reduce(tables[tableStateIndex], action as any);

        return newState;
    }
}