import * as React from 'react';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaTimeValidator } from '../../Infrastructure/Validation/VolmaTimeValidator';
import { IVolmaTimeProps } from './IVolmaTimeProps';
import { VolmaTimeActions } from './VolmaTimeActions';
import { VolmaTimeReducer } from './VolmaTimeReducer';
import { Component } from 'react';
import * as moment from 'moment';
import { LogService } from '../../Infrastructure/Services/LogService';
import { VolmaTimeInput } from './VolmaTimeInput';

class VolmaTime extends Component<IVolmaTimeProps, {}> {
    private _validator: BaseValidator<IVolmaTimeProps>;
    private _defaultValidator: VolmaTimeValidator;
    private _timeReducer;

    private _logService: LogService;

    private _hoursUpTimer: any;
    private _hoursDownTimer: any;
    private _minutesUpTimer: any;
    private _minutesDownTimer: any;
    private _minutesStepTimer: any;

    private _timerIntervalMs: any = 150;
    private _timerStepIntervalMs: any = 250;
    private _minutesStep = 1;
    private _muteMinutesKeyEvent: boolean = false;

    private _timeActions: VolmaTimeActions;

    private _defaultInput      = volmaBlock("volma-input");
    private _numberNav         = volmaBlock("number-nav");
    private _defaultInputError = volmaBlock('default-input-error');
    private _volmaTime         = volmaBlock('volma-time');

    constructor(props: IVolmaTimeProps, context: any) {
        super(props, context);

        this._timeActions = VolmaContainer.get<VolmaTimeActions>(Types.VolmaTimeActions);
        this._defaultValidator = VolmaContainer.get<VolmaTimeValidator>(Types.VolmaTimeValidator);
        this._timeReducer = VolmaContainer.get<VolmaTimeReducer>(Types.VolmaTimeReducer);
        this._logService = VolmaContainer.get<LogService>(Types.LogService);

        this.handleHoursChange = this.handleHoursChange.bind(this);
        this.handleMinutesChange = this.handleMinutesChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.OnUpHours = this.OnUpHours.bind(this);
        this.OnDownHours = this.OnDownHours.bind(this);
        this.OnUpMinutes = this.OnUpMinutes.bind(this);
        this.OnDownMinutes = this.OnDownMinutes.bind(this);
        this.IncreaseMinutesStep = this.IncreaseMinutesStep.bind(this);
    }


    public shouldComponentUpdate(nextProps: IVolmaTimeProps, nextState: any) {
        const shouldUpdate = nextProps.Value !== this.props.Value ||
            nextProps.Disabled !== this.props.Disabled ||
            nextProps.IsTouched !== this.props.IsTouched ||
            nextProps.IsSubmitted !== this.props.IsSubmitted ||
            nextProps.ErrorMessage !== this.props.ErrorMessage ||
            nextProps.IsValid !== this.props.IsValid;
        return shouldUpdate;
    }

    render() {
        this._logService.Trace('volma time named', this.props.Name, 'value is', this.props.Value, 'is valid is', this.props.IsValid, 'is touched is', this.props.IsTouched);
            
        const commonProps = {
            type: "text",
            placeholder: "00",
            onBlur: this.handleFocus,
            className: this._defaultInput("input", { center: true }).mix(["input", "input-number"])
        }
            
        const hoursProps = {
            ...commonProps,
            // @ts-ignore: This expression is not callable
            value: this.props.Value === undefined ? undefined : this.formatTimeValue(moment(this.props.Value).hours()),
            onChange: this.handleHoursChange,
        }

        const minutesProps = {
            ...commonProps,
            // @ts-ignore: This expression is not callable
            value: this.props.Value === undefined ? undefined : this.formatTimeValue(moment(this.props.Value).minutes()),
            onChange: this.handleMinutesChange,
        }

        return (
            <div>
                <div className={(this._volmaTime()).toString()}>
                    <div className={(this._defaultInput.mix(this._volmaTime("text"))).toString()}>
                        <span className={(this._defaultInput("input", {text: true }).mix(["input"])).toString()}>{this.props.Label}</span>
                    </div>

                    <VolmaTimeInput
                        className={"hours"}
                        inputControl={hoursProps} 
                        onInputKeyDown={this.OnHoursKeyPressStart.bind(this)} 
                        onInputKeyUp={this.OnHoursKeyPressEnd.bind(this)}
                        onUpValueStart={this.OnUpHoursStart.bind(this)} 
                        onUpValueEnd={this.OnUpHoursEnd.bind(this)}                        
                        onDownValueStart={this.OnDownHoursStart.bind(this)}
                        onDownValueEnd={this.OnDownHoursEnd.bind(this)}                        
                        onSkipEvent={this.SkipEvent.bind(this)} />

                    <VolmaTimeInput 
                        className={"minutes"}
                        inputControl={minutesProps} 
                        onInputKeyDown={this.OnMinutesKeyPressStart.bind(this)} 
                        onInputKeyUp={this.OnMinutesKeyPressEnd.bind(this)}
                        onUpValueStart={this.OnUpMinutesStart.bind(this)} 
                        onUpValueEnd={this.OnUpMinutesEnd.bind(this)}                        
                        onDownValueStart={this.OnDownMinutesStart.bind(this)}
                        onDownValueEnd={this.OnDownMinutesEnd.bind(this)}                        
                        onSkipEvent={this.SkipEvent.bind(this)} />         
                </div>
                
                <div className={this._defaultInput("message-container").toString()}>
                    {this.GetAdditionalTextBlock()}
                    {this.GetErrors()}
                </div>
            </div>
        )
    }

    public componentDidMount() {
        let props: IVolmaTimeProps = {
            Label: this.props.Label,
            Value: this.props.Value,
            CustomDataUpdate: this.props.CustomDataUpdate,
            Required: this.props.Required,
            Disabled: this.props.Disabled
        }

        this.props.dispatch(this._timeActions.Register(this.props.Name, this._timeReducer, this.props.Validator || this._defaultValidator, props));
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    public componentWillUnmount() {
        clearInterval(this._hoursUpTimer);
        clearInterval(this._hoursDownTimer);
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
    }

    private handleHoursChange(event: any) {
        this.props.dispatch(this._timeActions.ChangeHoursValue(this.props.Name, this.props.Value, event.target.value))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private handleMinutesChange(event: any) {
        this.props.dispatch(this._timeActions.ChangeMinutesValue(this.props.Name, this.props.Value, event.target.value))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private handleFocus() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
    }

    private handleBlur() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnHoursKeyPressStart(event: KeyboardEvent) {
        if (event.keyCode === 38) // up key
            this.OnUpHoursStart(event);
        if (event.keyCode === 40) // down key
            this.OnDownHoursStart(event);
    }

    private OnHoursKeyPressEnd(event: KeyboardEvent) {
        if (event.keyCode === 38) // up key
            this.OnUpHoursEnd(event);
        if (event.keyCode === 40) // down key
            this.OnDownHoursEnd(event);
    }

    // hours up
    private OnUpHoursStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.IncrementHours(this.props.Name, this.props.Value));
        clearInterval(this._hoursUpTimer);
        this._hoursUpTimer = setInterval(this.OnUpHours, this._timerIntervalMs);
    }

    private OnUpHoursEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._hoursUpTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnUpHours() {
        this.props.dispatch(this._timeActions.IncrementHours(this.props.Name, this.props.Value));
    }

    // hours down
    private OnDownHoursStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.DecrementHours(this.props.Name, this.props.Value));
        clearInterval(this._hoursDownTimer);
        this._hoursDownTimer = setInterval(this.OnDownHours, this._timerIntervalMs);
    }

    private OnDownHoursEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._hoursDownTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnDownHours() {
        this.props.dispatch(this._timeActions.DecrementHours(this.props.Name, this.props.Value));
    }

    private OnMinutesKeyPressStart(event: KeyboardEvent) {
        if (!this._muteMinutesKeyEvent && event.keyCode === 38) { // up key
            this._muteMinutesKeyEvent = true;
            this.OnUpMinutesStart(event);
        }
        if (!this._muteMinutesKeyEvent && event.keyCode === 40) { // down key
            this._muteMinutesKeyEvent = true;
            this.OnDownMinutesStart(event);
        }
    }

    private OnMinutesKeyPressEnd(event: KeyboardEvent) {
        if (event.keyCode === 38) { // up key
            this.OnUpMinutesEnd(event);
            this._muteMinutesKeyEvent = false;
        }
        if (event.keyCode === 40) { // down key
            this.OnDownMinutesEnd(event);
            this._muteMinutesKeyEvent = false;
        }
    }

    // minutes up
    private OnUpMinutesStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this._minutesStep = 1;
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.IncrementMinutes(this.props.Name, this.props.Value, this._minutesStep));
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesStepTimer);
        this._minutesUpTimer = setInterval(this.OnUpMinutes, this._timerIntervalMs);
        this._minutesStepTimer = setInterval(this.IncreaseMinutesStep, this._timerStepIntervalMs);
    }

    private OnUpMinutesEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesStepTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnUpMinutes() {
        this.props.dispatch(this._timeActions.IncrementMinutes(this.props.Name, this.props.Value, this._minutesStep));
    }

    //minutes down
    private OnDownMinutesStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this._minutesStep = 1;
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.DecrementMinutes(this.props.Name, this.props.Value, this._minutesStep));
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
        this._minutesDownTimer = setInterval(this.OnDownMinutes, this._timerIntervalMs);
        this._minutesStepTimer = setInterval(this.IncreaseMinutesStep, this._timerStepIntervalMs);
    }

    private OnDownMinutesEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private SkipEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    private OnDownMinutes() {
        this.props.dispatch(this._timeActions.DecrementMinutes(this.props.Name, this.props.Value, this._minutesStep));
    }


    private IncreaseMinutesStep() {
        if (this._minutesStep < 30)
            this._minutesStep++;
    }

    private GetErrors(): JSX.Element | undefined {
        const errors = <>
            <div className={(this._defaultInputError()).toString()}>
                <div className={(this._defaultInputError("text")).toString()}>{this.props.ErrorMessage}</div>
            </div>
        </>;

        return (!this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted)) ? errors : undefined;
    }

    private GetAdditionalTextBlock(): JSX.Element | undefined {
        if (!this.props.AdditionalText) {
            return;
        }

        return <>
            <div className={(this._defaultInput("additional-text")).toString()}>{this.props.AdditionalText}</div>
        </>;
    }

    private formatTimeValue(value: string | number) {
        if (String(value).length === 1) {
            return `0${value}`;
        }

        return value;
    }
}

export default VolmaTime;