import {
    VOLMA_ENTITY_DATA_LOADED,
    ZONE_LOCALITIES_LOADED,
} from '../../../Constants/AppConstants';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ZoneHelperProps } from './IHelperProps';
import { injectable } from 'inversify';


@injectable()
export class ZoneReducer extends EntityReducer<ZoneDTO, ZoneHelperProps> {

    public Reduce(state: IBaseEntityProps<ZoneDTO, ZoneHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<ZoneDTO, ZoneHelperProps>{
        switch (action.type) {
            case ZONE_LOCALITIES_LOADED:
                return this.ReduceLocalitiesLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceLocalitiesLoaded(state: IBaseEntityProps<ZoneDTO, ZoneHelperProps>, payload: Array<LocalityTableDTO>): IBaseEntityProps<ZoneDTO, ZoneHelperProps> {
        return super.CollectionLoaded<LocalityTableDTO>(state, payload, [x => x.Localities, x => x.LocalitiesOriginal]);
    }
}
