import { History } from 'history';
import * as query_string from 'query-string'

export class RouteUtils{
    public static GetQueryValue(search: string, param: string): string{
        const parsed = query_string.parse(search);
        return parsed[param] as string;

    }

    public static SetQueryValue(history: History<undefined>, pathname: string, search: string, param: string, val: string): void {
        const parsed = query_string.parse(search);
        parsed[param] = val;
        history.push({
            pathname: pathname,
            search: `?${query_string.stringify(parsed)}`
        });
    }
}