import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import {
    BUSINESS_CALENDAR_LOADED,
    EMPTY_GUID,
    FORM_SUBMITTED,
    SETTINGS_CALENDAR_ENTRIES_REMOVED,
    SETTINGS_CALENDAR_ENTRY_ADDED,
    SETTINGS_CALENDAR_ENTRY_UPDATED,
    SETTINGS_COMPLAINT_TEMPLATES_LOADED,
    SETTINGS_LOADED,
    SETTINGS_MOUNTED,
    SETTINGS_SAVED,
    SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED,
    SETTINGS_UNMOUNTED,
    VOLMA_ENTITY_CLEAR_FOOTER,
    VOLMA_ENTITY_DTO_STRINGIFY_UPDATED,
} from '../../../Constants/AppConstants';
import { BusinessCalendarDTO } from '../../../Domain/DTO/BusinessCalendarDTO';
import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { TableDTO } from '../../../Domain/DTO/TableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { SettingsServerInteraction } from '../../../Infrastructure/ServerInteraction/SettingsServerInteraction';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { FormatService } from '../../../Infrastructure/Services/FormatService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';

@injectable()
export class SettingsActions extends ComponentActions {
    private _apiActions: ApiActions;
    private _api: SettingsServerInteraction;
    private _apiTable: TableServerInteraction;
    private _formatService: FormatService;

    constructor() {
        super();
        super.Initialize(SETTINGS_MOUNTED, SETTINGS_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._api = VolmaContainer.get<SettingsServerInteraction>(Types.SettingsServerInteraction);
        this._apiTable = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._formatService = VolmaContainer.get<FormatService>(Types.FormatService);
    }

    public SaveSettings(dto: IEntityDTO, isFormValid: boolean, waitingText: string, onSuccess: () => void) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.PutSettings(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    dispatch(this.SavedSyccessfully());
                    onSuccess();
                });
        }
    }

    public LoadSettings(waitingText: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.Settings, PropertyHelper.GetPropertyName((x: SettingsDTO) => x.Id), ESortDir.Asc, undefined, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data: TableDTO = JSON.parse(response.data);
                    if (data.TotalItems === 0){
                        this._apiActions.DoApiRequest(
                            dispatch,
                            () => this._api.CreateSettings(),
                            waitingText,
                            (response: AxiosResponse, dispatch: any) => {
                                this.LoadSettings(waitingText);
                            });
                    }
                    else{
                        dispatch(this.SettingsLoaded(data.Items[0]));
                        dispatch(this.LoadSelectedComplaintTemplates(waitingText, data.Items[0] as any));
                    }
                });
        }
    }

    public LoadComplaintTemplates(waitingText: string){
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.ComplaintTemplate, PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name), ESortDir.Asc, undefined, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    dispatch(this.ComplaintsTemplatesLoaded(data.Items));
                });
        }
    }

    public LoadSelectedComplaintTemplates(waitingText: string, dto: SettingsDTO){
        let ids = [];

        if (this._formatService.IsValueNotEmpty(dto.TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId))
            ids.push(dto.TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId);
        if (this._formatService.IsValueNotEmpty(dto.TemplateForCargotransporterLateForLoadingComplaintId))
            ids.push(dto.TemplateForCargotransporterLateForLoadingComplaintId);
        if (this._formatService.IsValueNotEmpty(dto.TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId))
            ids.push(dto.TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId);
        if (this._formatService.IsValueNotEmpty(dto.TemplateForCargoTransporterSupplyDisruptionComplaintId))
            ids.push(dto.TemplateForCargoTransporterSupplyDisruptionComplaintId);

        return (dispatch: any) => {
            for (let id of ids){
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => this._apiTable.GetEntity(EEntityType.ComplaintTemplate, id),
                    waitingText,
                    (response: AxiosResponse, dispatch: any) => {
                        let data = JSON.parse(response.data);
                        dispatch(this.SelectedComplaintTemplateLoaded(data));
                    });
            }
        }
    }

    public ImportData(fileId: string, waitingText: string){
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.GetCalendarEntries(fileId),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data : BusinessCalendarDTO = response.data;
                    dispatch(this.BusinessCalendarLoaded(data.CalendarEntries));
                });
        }
    }

    private BusinessCalendarLoaded(items: Array<CalendarEntryDTO>) : IActionPayloaded<Array<CalendarEntryDTO>>{
        return { type: BUSINESS_CALENDAR_LOADED, Payload: items};
    }

    public ComplaintsTemplatesLoaded(items: Array<ComplaintTemplateTableDTO>): IActionPayloaded<Array<ComplaintTemplateTableDTO>>{
        return {type: SETTINGS_COMPLAINT_TEMPLATES_LOADED, Payload: items};
    }

    public SelectedComplaintTemplateLoaded(items: ComplaintTemplateDTO): IActionPayloaded<ComplaintTemplateDTO>{
        return {type: SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED, Payload: items};
    }

    public CalendarEntryAdded(dto: CalendarEntryDTO): IActionPayloaded<CalendarEntryDTO>{
        return {type: SETTINGS_CALENDAR_ENTRY_ADDED, Payload: dto};
    }

    public CalendarEntryUpdated(dto: CalendarEntryDTO): IActionPayloaded<CalendarEntryDTO>{
        return {type: SETTINGS_CALENDAR_ENTRY_UPDATED, Payload: dto};
    }

    public CalendarEntriesRemoved(ids: string[]): IActionPayloaded<Array<string>>{
        return {type: SETTINGS_CALENDAR_ENTRIES_REMOVED, Payload: ids};
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    public ClearFooter() : IAction{
        return { type: VOLMA_ENTITY_CLEAR_FOOTER };
    }

    private SettingsLoaded(data: any): IActionPayloaded<Array<any>> {
        return { type: SETTINGS_LOADED, Payload: data };
    }

    private SavedSyccessfully(): IAction{
        return {type: SETTINGS_SAVED };
    }

    public UpdateDTOStringify(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_ENTITY_DTO_STRINGIFY_UPDATED, Payload: data };
    }
}