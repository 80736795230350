import { ERating } from '../../../../Domain/Enum/ERating';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { RatingDTO } from '../../../../Domain/DTO/RatingDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { CargoTransporterDTO } from '../../../../Domain/DTO/CargoTransporterDTO';
import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { RatingDetailsDTO } from '../../../../Domain/DTO/RatingDetailsDTO';
import { MultiFactoryDTO } from '../../../../Domain/DTO/MultiFactoryDTO';


export class RatingHelperProps{
    RatingHistory        : Array<RatingTableDTO>;
    RatingHistoryOriginal: Array<RatingTableDTO>;

    AvailableLoadingPoints: Array<LoadingPointTableDTO>;
    AllLoadingPoints      : Array<LoadingPointTableDTO>;
    CurrentRatings        : Array<RatingTableDTO>;

    CargoTransporter: CargoTransporterDTO;
    LoadingPoint    : LoadingPointDTO;
    MultiFactory    : MultiFactoryDTO;
    ForecastRating  : RatingDTO
}

export const InitialRatingHelperProps: RatingHelperProps = <RatingHelperProps>{ 
    RatingHistory: [],
    RatingHistoryOriginal: [],

    ForecastRatingReasons: [],
    ForecastRatingReasonsOriginal: [],

    AvailableLoadingPoints: [],
    AllLoadingPoints: [],
    CurrentRatings: [],

    CargoTransporter: undefined,
    LoadingPoint    : undefined,
    MultiFactory    : undefined,
    ForecastRating  : undefined,
};