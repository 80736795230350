import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { MultiFactoryDTO } from '../../../Domain/DTO/MultiFactoryDTO';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { TenderBetTableDTO } from '../../../Domain/DTO/TenderBetTableDTO';
import { TenderChangelogTableDTO } from '../../../Domain/DTO/TenderChangelogTableDTO';
import { TenderStateDTO } from '../../../Domain/DTO/TenderStateDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { MultiTransporterTableDTO } from '../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes';
export class TenderHelperProps {
    LoadingPoint: LoadingPointDTO;
    MultiFactory: MultiFactoryDTO;
    Zone: ZoneDTO;
    Consignor: ConsignorDTO;
    Payloads: Array<PayloadDTO>
    Bets: Array<TenderBetTableDTO>
    Changelogs: Array<TenderChangelogTableDTO>
    WinnersBets: Array<TenderBetTableDTO>
    Deliveries: Array<DeliveryTableDTO>
    RejectedDeliveries: Array<DeliveryTableDTO>
    CargoTransporterForecastRatings: Array<RatingTableDTO>
    PayloadsOriginal: Array<PayloadDTO>
    BetsOriginal: Array<TenderBetTableDTO>
    ChangelogsOriginal: Array<TenderChangelogTableDTO>
    DeliveriesOriginal: Array<DeliveryTableDTO>
    RejectedDeliveriesOriginal: Array<DeliveryTableDTO>
    CargoTransporterForecastRatingsOriginal: Array<RatingTableDTO>
    WinnerCargoTransporter: MultiTransporterTableDTO;
}



export const InitialTenderHelperProps: TenderHelperProps = <TenderHelperProps> {
    Payloads: [],
    Bets: [],
    WinnersBets: [],
    Changelogs: [],
    Deliveries: [],
    RejectedDeliveries: [],
    CargoTransporterForecastRatings: [],
    PayloadsOriginal: [],
    BetsOriginal: [],
    ChangelogsOriginal: [],
    DeliveriesOriginal: [],
    RejectedDeliveriesOriginal: [],
    CargoTransporterForecastRatingsOriginal: [],    
    WinnerCargoTransporter: undefined,
    LoadingPoint: undefined,
    MultiFactory: undefined,
    Zone: undefined,
    Consignor: undefined,
};