import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { IStatus } from '../../../../Infrastructure/Status/IStatus';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { ComplaintHelperProps } from '../IHelperProps';


export abstract class ComplaintStatusDefault extends StatusDefault<ComplaintDTO, ComplaintHelperProps>{
    protected _prevState: IStatus<ComplaintDTO, ComplaintHelperProps>;
    protected _currentStatus: EComplaintState;

    public IsActive(dto: ComplaintDTO, dtoHelper: ComplaintHelperProps) {
        return this.IsCurrent(dto) || dtoHelper.Comments.find(x => x.ComplaintState === this._currentStatus) !== undefined;
    }

    public IsCurrent(dto: ComplaintDTO){
        return dto.ComplaintState === this._currentStatus;
    }
    
    public IsFinished(dto: ComplaintDTO){
        return this._prevState.IsFinished(dto) && !this.IsCurrent(dto);
    }


    public GetStatusFinishedDate(dto: ComplaintDTO, dtoHelper: ComplaintHelperProps) {
        let states = dtoHelper.Comments.filter(x => x.ComplaintState === this._currentStatus); 
        if (states !== undefined && states.length > 0){
            let state = states[states.length - 1];
            return state.Timestamp;
        }
    }

    constructor(){
        super();
    }
}