import { FORM_SUBMITTED } from '../../Constants/AppConstants';
import { IAction } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { LogService } from '../../Infrastructure/Services/LogService';
import { Types } from '../../Infrastructure/Types';
import { injectable } from 'inversify';

@injectable()
class VolmaFormActions {
    private _log: LogService;

    constructor() {
        this._log = VolmaContainer.get<LogService>(Types.LogService);
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }
}

export {VolmaFormActions};