import { ZoneReducer } from './ZoneReducer';
import { ZoneHelperProps, InitialZoneHelperProps } from './IHelperProps';
import * as React from 'react';
import { injectable } from 'inversify';
import VolmaInput from '../../VolmaInput';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { ZoneTableDTO } from '../../../Domain/DTO/ZoneTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ZoneActions } from './ZoneActions';
import { LocalityEntityService } from '../Locality/LocalityEntityService';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import i18next from '../../i18n';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber';

@injectable()
export class ZoneEntityService extends BaseEntityService<ZoneDTO, ZoneHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberInputValidator: BaseValidator<IVolmaNumberProps>;
    private _reducer: ZoneReducer;
    private _action: ZoneActions;
    private _localityService: LocalityEntityService;
    private _localityColumns: Array<IVolmaTableColumn>;
    private _dispatch: any;


    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer = VolmaContainer.get<ZoneReducer>(Types.ZoneEntityReducer);
        this._action = VolmaContainer.get<ZoneActions>(Types.ZoneEntityActions);
        this._localityService = VolmaContainer.get<LocalityEntityService>(Types.LocalityEntityService);
        this._numberInputValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._localityColumns = this._localityService.GetColumnsList();
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Zone]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Zone,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ZoneTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: ZoneTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ZoneTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
            ]
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ZoneDTO, ZoneHelperProps>): JSX.Element {
        return undefined;
    }

    public GetEntityId(props: IBaseEntityProps<ZoneTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: ZoneTableDTO) => x.Code),
            (x: ZoneTableDTO, id: string) => x.Code.toLowerCase() === id);
    }


    public GetHeaderEditor(props: IBaseEntityProps<ZoneDTO, ZoneHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);        
        return (
            <div>
                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ZoneDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: ZoneDTO) => val.Code)}
                        Label={i18next.t('zone:Code')}
                        Placeholder={i18next.t('zone:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>


                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ZoneDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: ZoneDTO) => val.Name)}
                        Label={i18next.t('zone:Name')}
                        Placeholder={i18next.t('zone:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<ZoneDTO, ZoneHelperProps>): JSX.Element {
        if(this.NonEmptyCollection(props.DataHelper.Localities)){
            return (
                <div className={this._mainInfo()}>
                    <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])}>
                        {this.GetMainInfoItem(true, false, true, 0, i18next.t("zone:LocalitiesListTitle"), "", this.LocalitiesPart(props), undefined, props, this._dispatch)}
                    </div>
                </div>
            );
        }
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<ZoneDTO, ZoneHelperProps>) {
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialZoneHelperProps;
    }

    public OnAfterDataLoaded(dto: ZoneDTO) {
        this._dispatch(this._action.LoadLocalities(dto.Id, i18next.t("common:LoadingLocalitiesTableData")));
    }

    private LocalitiesPart(props: IBaseEntityProps<ZoneDTO, ZoneHelperProps>): JSX.Element{
        return (
        <div className={this._mainInfo("row")}>
            <div className={this._mainInfo("cell", { full: true, 100: true })}>
                <div className={this._mainInfo("line", { fill: true })}>
                    <ExperimentalVolmaTable
                        {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: ZoneDTO) => val.Localities) }
                        Name={PropertyHelper.GetPropertyName((x: ZoneDTO) => x.Localities)}
                        Columns={this._localityColumns}
                        Data={props.DataHelper.Localities}
                        DataOriginal={props.DataHelper.LocalitiesOriginal}
                        LoadDataFromServer={false}
                        IsTableInsideEntity={true}
                        dispatch={props.dispatch}/>
                </div>
            </div>
        </div>
        );
    }
}