import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { history } from '../../../ConfigureStore';
import { HTML_BR } from '../../../Constants/AppConstants';
import { SearchResultDTO } from '../../../Domain/DTO/SearchResultDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { RouteUtils } from '../../../Infrastructure/Router/RouteUtils';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { EnumService } from '../../../Infrastructure/Services/EnumService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import Header from '../../Header';
import i18next from '../../i18n';
import { VolmaComponent } from '../../Shared/VolmaComponent';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCellHeightMeasurers } from '../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableSearchCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableSearchCellRenderers';
import VolmaInput from '../../VolmaInput';
import { ISearchProps } from './ISearchProps';
import { SearchComponentActions } from './SearchComponentActions';
import { SearchDTO } from './SearchDTO';

class Search extends VolmaComponent<ISearchProps>{
    protected _header     = volmaBlock('header');
    protected _createCard = volmaBlock('create-card');
    protected _btn        = volmaBlock('btn');
    protected _mainInfo   = volmaBlock('main-info');
    protected _footer     = volmaBlock('footer');
    protected _content    = volmaBlock('content');
    protected _search     = volmaBlock('search');

    private _oldSearch: string = undefined;

    private _searchDebounceTime = 300;// ms
    private _searchDebounceTimeout: any;

    protected _volmaTableService: VolmaTableService;
    protected _routerService: RouterService;
    protected _enumService: EnumService;
    private _columns: Array<IVolmaTableColumn>;
    private _isGodMode: any;

    private _searchComponentActions: SearchComponentActions;

    constructor(props: ISearchProps, context: any) {
        super(props, context);

        this._searchComponentActions = VolmaContainer.get<SearchComponentActions>(Types.SearchComponentActions);
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
        this._enumService = VolmaContainer.get<EnumService>(Types.EnumService);

        super.SetActions(this._searchComponentActions);

        this._columns = this.GetSearchColumns();
        this.RebuildIndex = this.RebuildIndex.bind(this);
        this._isGodMode = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(props.location, x => x.search), "GodMode");
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public componentDidMount(){
        super.componentDidMount();
        let search = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(this.props.location, x => x.search), "search");
        if (PropertyHelper.IsString(search) && search.length > 0)
            this.OnSearchValueChange(search)
        else
            this.OnSearchValueChange("")
        this._oldSearch = search;
    }

    public componentDidUpdate(prevProps) {
        let newSearch = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(this.props.location, x => x.search), "search");
        if (newSearch !== this._oldSearch ) {
            if (PropertyHelper.IsString(newSearch) && newSearch.length > 0)
                this.OnSearchValueChange(newSearch)
            else
                this.OnSearchValueChange("")
            this._oldSearch = newSearch;
        }
    }

    public render(){
        if (this.props.DataDTO === undefined)
            return null;
        return (
            <div className="wrapper" >
                <header className={(this._header).toString()}>
                    <Header />
                </header>

                <div className={(this._search).toString()}>
                    <div className={(this._search("left")).toString()}>
                        <svg className={(this._search("left-ico").mix(["search-ico"])).toString()}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#search"></use></svg>
                    </div>
                    <div className={(this._search("center")).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(this.props.Inputs, (val: SearchDTO) => val.SearchString) as any) }
                            Name={PropertyHelper.GetPropertyName((val: SearchDTO) => val.SearchString)}
                            Label={i18next.t("search:SearchString")}
                            Placeholder={i18next.t("search:SearchStringPlaceholder")}
                            IsActive={true}
                            Value={this.props.DataDTO.SearchString}
                            IsInHeader={true}
                            MaxLength={255}
                            Autofocus={true}
                            OnValueChange={(event: any) => this.OnSearchValueChange(event.target.value)}
                            dispatch={this.props.dispatch}
                        />
                    </div>
                    {this._isGodMode && <div className={(this._search("right")).toString()}>
                        <span className={(this._search("rebuild")).toString()} onClick={this.RebuildIndex}>
                            <svg className={(this._search("rebuild-ico").mix("search-relaod-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#relaod"></use>
                            </svg>
                        </span>
                    </div>}
                </div>
                <main className="content">
                    <div className='content-wrap' style={this.props.style}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByNameString(this.props.Tables, "search") }
                            Name={"table"}
                            {...this.props}
                            HideHeader={true}
                            HideActions={true}
                            Data={this.props.DataHelper.SearchResults}
                            DataOriginal={this.props.DataHelper.SearchResults}
                            LoadDataFromServer={false}
                            Columns={this._columns}
                            Entity={EEntityType.Search}
                            ReloadOnSignalREvent={false}
                            DisableItemEdit={true}
                            IsFilterEnabled={true}>
                        </ExperimentalVolmaTable>
                    </div>
                </main>
            </div>
        )
    }

    protected RouterWillLeave() {
        return true;
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public LoadData(){
        this.props.dispatch(this._searchComponentActions.SetEntityType(EEntityType.Search));
        this.props.dispatch(this._searchComponentActions.SetEntityType(EEntityType.Search));
    }

    public IsEditable(): boolean {
        return false
    }

    protected OnSearchValueChange(value: string) {
        this.props.dispatch(this._searchComponentActions.SearchTextUpdated(value));

        if (this._searchDebounceTimeout !== undefined)
            clearTimeout(this._searchDebounceTimeout);

        this._searchDebounceTimeout = setTimeout(() => {
            this.props.dispatch(this._searchComponentActions.GetSearchResult(value, i18next.t("common:Loading")))
            RouteUtils.SetQueryValue(this.props.history, this.props.location.pathname, this.props.location.search, "search", value);
            history.replace(this.props.location);
            this._oldSearch = value;
        }, this._searchDebounceTime);
    }

    protected RebuildIndex(){
        this.props.dispatch(this._searchComponentActions.RebuildIndex(i18next.t("common:Loading")));
    }

    protected GetSearchColumns(){
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Delivery,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: SearchResultDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: SearchResultDTO) => val.Type),
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EEntityType, this._enumService, props, cellRendererProps),
                    Weight: 0.5
                },
                // {
                //     DataKey: PropertyHelper.GetPropertyName((val: SearchResultDTO) => val.Name),
                //     Weight: 0.5
                // },
                {
                    DataKey: "Context",
                    CellTextGetter: this.GetSearchContextCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.SearchResultHeightMeasurer,
                    Weight: 2.5
                },
                {
                    DataKey: "Link",
                    CellRenderer: (props: IVolmaTableProps<{}>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableSearchCellRenderers.SearchLinkCellRenderer(props, params, this._routerService),
                    Weight: 0.5
                },
            ]
        );

    }

    protected GetSearchContextCellText (props: IVolmaTableProps<{}>, rowIndex: number): string {

        let dto = props.Data[rowIndex] as SearchResultDTO;
        if (!PropertyHelper.IsObject(dto))
            return "";

        let entity = EEntityType[dto.Type].toLowerCase();
        if (dto.Type === EEntityType.RequestForDeliveryState)
            entity = EEntityType[EEntityType.DeliveryState].toLowerCase();
        if (dto.Type === EEntityType.ComplaintComment)
            entity = EEntityType[EEntityType.Complaint].toLowerCase();

        let result = new Array<string>();
        if (PropertyHelper.IsArray(dto.FieldItems)){
            for(let i = 0; i < dto.FieldItems.length; i++){
                let field = dto.FieldItems[i];
                result.push("<span class='table-cont__search-field-name'>" + i18next.t(entity + ":" + field.Name) + "</span>: " + field.Content);
            }
        }

        if (PropertyHelper.IsArray(dto.ContextItems)){
            for(let i = 0; i < dto.ContextItems.length; i++){
                let context = dto.ContextItems[i];
                result.push("<span class='table-cont__search-field-name'>" + i18next.t(entity + ":" + context.Name) + "</span>: ");
                if (PropertyHelper.IsArray(context.FieldItems)) {
                    for (let i = 0; i < context.FieldItems.length; i++) {
                        let field = context.FieldItems[i];
                        result.push("-<span class='table-cont__search-field-name'>" + i18next.t(entity + ":" + field.Name) + "</span>: " + field.Content);
                    }
                }
            }
        }

        return result.join(HTML_BR);
    }
}


const translated = withTranslation(['common'])(withRouter(Search));

function select(state: IGlobalState, ownProps: ISearchProps): ISearchProps {
    return state.Search;
}

let connectedSearchEntity = connect<ISearchProps, {}, {}>(select)(translated);
export default connectedSearchEntity;
