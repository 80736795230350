import { EFilterType } from '../Enum/EFilterType';
import { ITableDTO } from '../ITableDTO';

export class FilterDTO {
    public Type: EFilterType = undefined;
    public Key?: string = undefined;
    public TextValue?: string = undefined;
    public DateMinValue: Date = undefined;
    public DateMinOverlayShown: boolean = undefined;
    public DateMaxValue: Date = undefined;
    public DateMaxOverlayShown: boolean = undefined;
    public TimeMinValue: number = undefined;
    public TimeMaxValue: number = undefined;
    public SelectSelectedValues: Array<ITableDTO> = undefined;
}
