import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import * as React from 'react';
import { BiddingBetTableDTO } from '../../../../Domain/DTO/BiddingBetTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import i18next from '../../../i18n';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import VolmaNumber from '../../../VolmaNumber';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { BiddingBetExtendedDTO } from './BiddingBetExtendedDTO';
import { injectable } from 'inversify';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';


@injectable()
export class BiddingBetEntityService extends BaseEntityService<BiddingBetExtendedDTO, {}>{
    private _volmaTableService: VolmaTableService;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _minValue: number;
    private _maxValue: number;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this.MinValueGetter = this.MinValueGetter.bind(this);
        this.MaxValueGetter = this.MaxValueGetter.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if(this._authService.IsShipper()){
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.BiddingBet,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Id)},
                    { DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Timestamp),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    { DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.CargoTransporterName),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.CargoTransporterUserName),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                        CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined
                    },
                    { DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Bet),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                ]
            );
        }
        else{
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.BiddingBet,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Id) },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Timestamp),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: BiddingBetTableDTO) => val.Bet),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                ]
            );
        }
    }

    public GetHeaderEditor(props: IBaseEntityProps<BiddingBetExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<BiddingBetExtendedDTO, {}>): JSX.Element {
        this._minValue = props.DataDTO.MinBetValue;
        this._maxValue = props.DataDTO.MaxBetValue;
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaNumber
                                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: BiddingBetExtendedDTO) => val.Bet) }
                                        Name={PropertyHelper.GetPropertyName((val: BiddingBetExtendedDTO) => val.Bet)}
                                        Label={i18next.t('biddingbet:Bet')}
                                        Placeholder={i18next.t('biddingbet:Bet')}
                                        Value={props.DataDTO.Bet}
                                        Validator={this._numberValidator}
                                        MinValue={this.MinValueGetter}
                                        MaxValue={this.MaxValueGetter}
                                        Required={true}
                                        Step={1}
                                        Unit={props.DataDTO.Currency}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<BiddingBetExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<BiddingBetExtendedDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: BiddingBetExtendedDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<BiddingBetExtendedDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private MinValueGetter(){
        return this._minValue;
    }

    private MaxValueGetter(){
        return this._maxValue;
    }
}