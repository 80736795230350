import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import i18next from '../../../i18n';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { Types } from '../../../../Infrastructure/Types';
import { ITableDTO } from '../../../../Domain/ITableDTO';
import { CargoTransporterUserActions } from '../CargoTransporterUserActions';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { CargoTransporterUserTableDTO } from '../../../../Domain/DTO/CargoTransporterUserTableDTO';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { ApiActions } from '../../../Shared/ApiActions';
import { AxiosResponse } from 'axios';
import { TableServerInteraction } from '../../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { SelectionCreateDTO } from '../../../../Domain/DTO/SelectionCreateDTO';
import { AuthServerInteraction } from '../../../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { ECargoTransporterUserState } from '../../../../Domain/Enum/ECargoTransporterUserState';

export class UnblockCargotransporterUserAccountStatusAction<THelper> implements IVolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _authService: AuthenticationService;
    protected _apiActions: ApiActions;
    protected _tableApi: TableServerInteraction;
    private _accountApi: AuthServerInteraction;

    public Title: string = "cargotransporteruser:UnblockSelectedUser";

    public IsAvailable(selectedIds: Array<string>, data: Array<CargoTransporterUserTableDTO>){
        if(!this._authService.IsAdministrator())
            return false;
        if(selectedIds === undefined || selectedIds.length < 1)
            return false;
        var itm = data.find(x => x.Id === selectedIds[0]);
        if(itm === undefined)
            return false;
        if(data.filter(x => selectedIds.some( y=> y == x.Id)).some(x => x.IsBlocked !== ECargoTransporterUserState.Blocked))
            return false;
        return true;
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<CargoTransporterUserTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds !== undefined && selectedIds.length > 0 ? selectedIds[0] : undefined;
        if(id !== undefined){
            let selectedItemIds : SelectionCreateDTO = {
                SelectedIds : selectedIds
            }
            dispatch(this._modalActions.OpenConfirmationModal("cargotransporteruser:AreYouSureYouWantToUnblockSelectedItem", () => {
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => this._tableApi.PostTableSelectItems(entity, selectedItemIds),
                    i18next.t("common:Loading"),
                    (response: AxiosResponse, dispatch: any) => {
                        let data = response.data;
                        this._apiActions.DoApiRequest(
                            dispatch,
                            () => this._accountApi.UnblockSelectedUsers(data.Data),
                            i18next.t("common:Loading"),
                            (response: AxiosResponse, dispatch: any) => {
                                reloadData();
                            });
                    });
            }));
        }
    }

    constructor(){
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._tableApi = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._accountApi = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
    }
}