import { INPUT_REMOVE_ACTIVE, INPUT_SET_ACTIVE, INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaInputProps } from '../VolmaInput/IVolmaInputProps';
import { VolmaInputPropsInitial } from './IVolmaInputProps';
import { IStringValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
export class VolmaInputReducer extends BaseInputReducer<IVolmaInputProps> {

    public Reduce(state: IVolmaInputProps = VolmaInputPropsInitial, action: IActionPayloaded<any>): IVolmaInputProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            case INPUT_SET_ACTIVE: 
                return this.ReduceSetActive(state);
            case INPUT_REMOVE_ACTIVE: 
                return this.ReduceRemoveActive(state);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaInputProps, payload: IStringValueChangedPayload) {
        let newState: IVolmaInputProps = {...state, Value: payload.Value};
        return newState;
    }

    private ReduceSetActive(state: IVolmaInputProps): IVolmaInputProps{
        return {...state, IsActive: true};
    }

    private ReduceRemoveActive(state: IVolmaInputProps): IVolmaInputProps {
        return{...state, IsActive: false};
    }
}