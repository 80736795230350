import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { injectable } from "inversify";
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import axios from 'axios';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { AxiosResponse } from 'axios';
import { IActionPayloaded, IAction } from '../../../Infrastructure/Action/IAction';
import { BodyTypeTableDTO } from '../../../Domain/DTO/BodyTypeTableDTO';
import { HandlingTypeTableDTO } from '../../../Domain/DTO/HandlingTypeTableDTO';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { ZoneTableDTO } from '../../../Domain/DTO/ZoneTableDTO';

import {
    ROUTE_RATE_SETTINGD_LOADED,
    ROUTE_RATE_RATE_UPDATED } from '../../../Constants/AppConstants';

import { EntityAction } from '../BaseEntity/EntityAction';
import { SettingsEntityService } from '../Settings/SettingsEntityService';
import { SettingsServerInteraction } from '../../../Infrastructure/ServerInteraction/SettingsServerInteraction';
import { ERateChangeType } from '../../../Domain/Enum/ERateChangeType';
import { TableDTO } from '../../../Domain/DTO/TableDTO';

@injectable()
export class RouteRateActions extends EntityAction {

    private _settingsApi: SettingsServerInteraction;

    constructor(){
        super();
        this._settingsApi = VolmaContainer.get<SettingsServerInteraction>(Types.SettingsServerInteraction);
    }


    public LoadSettings(waitingText: string, onLoadCallback?: () => void) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.Settings, PropertyHelper.GetPropertyName((x: SettingsDTO) => x.Id), ESortDir.Asc, undefined, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data: TableDTO = JSON.parse(response.data);
                    if (data.TotalItems === 0) {
                        this._apiActions.DoApiRequest(
                            dispatch,
                            () => this._settingsApi.CreateSettings(),
                            waitingText,
                            (response: AxiosResponse, dispatch: any) => {
                                this.LoadSettings(waitingText, onLoadCallback);
                            });
                    }
                    else {
                        dispatch(this.SettingsLoaded(data.Items[0] as any));
                        onLoadCallback();
                    }
                });
        }
    }

    public RateUpdated(value: number): IActionPayloaded<number>{
        return { type: ROUTE_RATE_RATE_UPDATED, Payload: value };
    }

    private SettingsLoaded(value: SettingsDTO): IActionPayloaded<SettingsDTO>{
        return {type: ROUTE_RATE_SETTINGD_LOADED, Payload: value};
    }
}