import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ESortDir } from '../../Domain/Enum/ESortDir';
import { ECQRSApi } from './ECQRSApi';
import { injectable } from 'inversify';
import { EReportType } from '../../Domain/Enum/EReportType';
import { EFilterType } from '../../Domain/Enum/EFilterType';
import * as moment from 'moment';
import { RegisterCargoTransporterUserDTO } from '../../Domain/DTO/RegisterCargoTransporterUserDTO';
import { RegisterShipperUserDTO } from '../../Domain/DTO/RegisterShipperUserDTO';
import PropertyHelper from '../Services/PropertyHelper';
import { ReportParametersDTO } from '../../Domain/DTO/ReportParametersDTO';
import { RatesReportParametersDTO } from '../../Domain/DTO/RatesReportParametersDTO';

@injectable()
export class UrlFabric {
    private static _writeApi:string = "/writeApi";
    private static _readApi:string = "/readApi";

    public Login(): string {
        return  UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/login";
    }

    public Reset(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/reset";
    }

    public Restore(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/restore";
    }

    public Register(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/register";
    }

    public RegisterCargoTransporterUser(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/cargotransporter/register";
    }

    public RegisterShipperUser(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/shipper/register";
    }

    public UpdateCargoTransporterUser(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/cargotransporter";
    }

    public UpdateShipperUser(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/shipper";
    }

    public DeleteCargoTransporterUser(id: string): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/cargotransporter/" + id;
    }

    public DeleteShipperUser(id: string): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/shipper/" + id;
    }

    public SetRole(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/role";
    }

    public UpdatePassword(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/password";
    }

    public Logout(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/auth/logout";
    }

    public GetUserInfo(): string {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Read) + "/api/v1/account/info";
    }

    public TableData(entity: EEntityType, id: string, childEntity: EEntityType, sortBy: string, sortDir: ESortDir, filter: Array<FilterDTO>, itemsPerPage: number, page: number){
        let params = new Array<FilterDTO>();
        if(filter != undefined)
            params = filter.map(x => {return {...x, Key: "filter_" + x.Key}});

        let route = UrlFabric.GetApiBaseUrl(ECQRSApi.Read) + "/api/v1/tables/" + EEntityType[entity].toLowerCase();
        if(id !== undefined && childEntity !== undefined){
            route += "/" + id + "/" + EEntityType[childEntity].toLowerCase();
        }

        if(sortBy !== undefined){
            let filter = new FilterDTO();
            filter.Key = "sortBy";
            filter.TextValue = sortBy;
            filter.Type = EFilterType.Text;
            params.push(filter);
        }

        if (sortDir !== undefined){
            let sort = new FilterDTO();
            sort.Key = "sortDir";
            sort.TextValue = ESortDir[sortDir];
            sort.Type = EFilterType.Text;
            params.push(sort);
        }

        if (itemsPerPage !== undefined) {
            let pageCapacity = new FilterDTO();
            pageCapacity.Key = "itemsPerPage";
            pageCapacity.TextValue = itemsPerPage.toString();
            pageCapacity.Type = EFilterType.Text;
            params.push(pageCapacity);
        } else {
            let pageCapacity = new FilterDTO();
            pageCapacity.Key = "itemsPerPage";
            pageCapacity.TextValue = "25000";
            pageCapacity.Type = EFilterType.Text;
            params.push(pageCapacity);

        }

        if(page !== undefined){
            let pageIndex = new FilterDTO();
            pageIndex.Key = "pageNumber";
            pageIndex.TextValue = page.toString();
            pageIndex.Type = EFilterType.Text;
            params.push(pageIndex);
        }

        if(params.length > 0)
            route += "/?" + params.map(x => {
                let value = this.GetFilterValue(x);
                if (value === undefined || value.length > 0)
                    return encodeURIComponent(x.Key) + "=" + this.GetFilterValue(x)
                return undefined;
            }).filter(x => x !== undefined).join("&")
        return route;
    }

    public GetFilterValue(filter: FilterDTO): string{
        switch(filter.Type){
            case EFilterType.Text:
                if (filter.TextValue === undefined || filter.TextValue === null || filter.TextValue.length === 0)
                    return "";
                return encodeURIComponent(filter.TextValue);
            case EFilterType.DateMin:
            case EFilterType.DateMax:
                let minDate = "";
                let maxDate = "";
                if (filter.DateMinValue !== undefined)
                    // @ts-ignore: This expression is not callable
                    minDate = moment(filter.DateMinValue).toISOString(true);
                if(filter.DateMaxValue !== undefined)
                    // @ts-ignore: This expression is not callable
                    maxDate = moment(filter.DateMaxValue).toISOString(true);
                if (minDate === "" && maxDate === "")
                    return "";
                return encodeURIComponent(minDate + "-" + maxDate);

            case EFilterType.TimeMin:
                throw new Error('not implemented')
                // return filter.TimeMinValue;
            case EFilterType.TimeMax:
                throw new Error('not implemented')
                // return filter.TimeMaxValue;
            case EFilterType.Select:
                if (filter.SelectSelectedValues === undefined || filter.SelectSelectedValues === null || filter.SelectSelectedValues.length === 0)
                    return "";
                return  encodeURIComponent(filter.SelectSelectedValues.map(x => x.Id).join(";"));
            default:
                throw new Error('Unknown filter type: ' + filter.Type);
        }
    }

    public Entity(cqrsApi: ECQRSApi, entity: EEntityType, id?: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tables/" + EEntityType[entity].toLowerCase() + "/items" + (id !== undefined ? ("/" + id) : "");
    }

    public EntitiesSelection(cqrsApi: ECQRSApi, entity: EEntityType, id?: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tables/" + EEntityType[entity].toLowerCase() + "/selections" + (id !== undefined ? ("/" + id) : "");
    }

    // upload file / get file info
    public File(cqrsApi: ECQRSApi, fileId: string): string{
        return UrlFabric.GetApiBaseUrl(cqrsApi) + '/api/v1/files/' + fileId;
    }

    public FileDownload(fileId: string): string{
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + '/api/v1/files/' + fileId + "?attachment=true";
    }

    // upload file / get file info
    public FileInfo(cqrsApi: ECQRSApi, fileId: string): string{
        return UrlFabric.GetApiBaseUrl(cqrsApi) + '/api/v1/tables/file/items/' + fileId;
    }

    // create empty temporary file
    public PostCreateEmptyTemporaryFile(): string{
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + '/api/v1/files'
    }

    // mark files as selected
    public PostSelectFiles(): string{
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + '/api/v1/files/selections'
    }

    // delete selected files
    public DeleteSelectedFiles(id: string): string{
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + '/api/v1/files/' + id;
    }

    // settings
    // writeapi settings
    public CreateUpdateSettings(cqrsApi: ECQRSApi) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/settings";
    }

    public GetCalendarEntries(fileId: string) {
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/businesscalendar/" + fileId;
    }

    //portal configuration
    public CreateUpdatePortalConfiguration(cqrsApi: ECQRSApi) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/configuration";
    }

    // report
    public Report(){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Read) + "/api/v1/reports/items";
    }

    public ExcelReport(id: string){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Read) + "/api/v1/reports/download/" + id;
    }

    public ExcelReportParams(cqrsApi: ECQRSApi, entity: EEntityType){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/reports/" + EEntityType[entity];
    }

    public ReportData(cqrsApi: ECQRSApi, entity: EEntityType, reportParams?: any){

        let route = UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/reports/" + EEntityType[entity];
        let params = new Array<FilterDTO>();
        for(let key in reportParams){
            if (reportParams[key] !== undefined){
                let filterDTO = new FilterDTO();
                filterDTO.Key = "filter_" + key;
                if (key === PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.DateFrom) && entity !== EEntityType.RateReportItem){
                    let filter = params.find(x => x.Type === EFilterType.DateMin || x.Type === EFilterType.DateMax);
                    if(filter === undefined){
                        filterDTO.Key = "filter_Date";
                        filterDTO.Type = EFilterType.DateMin;
                        // @ts-ignore: This expression is not callable
                        filterDTO.DateMinValue = moment(reportParams[key]).toDate();
                    }
                    else{
                        filterDTO = undefined;
                        filter.Type = EFilterType.DateMin;
                        // @ts-ignore: This expression is not callable
                        filter.DateMinValue = moment(reportParams[key]).toDate();
                    }
                }
                else if (key === PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.DateTo)){
                    let filter = params.find(x => x.Type === EFilterType.DateMin || x.Type === EFilterType.DateMax);
                    if(filter === undefined){
                        filterDTO.Key = "filter_Date";
                        filterDTO.Type = EFilterType.DateMax;
                        // @ts-ignore: This expression is not callable
                        filterDTO.DateMaxValue = moment(reportParams[key]).toDate();
                    }
                    else{
                        filterDTO = undefined;
                        filter.Type = EFilterType.DateMax;
                        // @ts-ignore: This expression is not callable
                        filter.DateMaxValue = moment(reportParams[key]).toDate();
                    }
                }
                else if (PropertyHelper.IsArray(reportParams[key])) {
                    filterDTO.Type = EFilterType.Select;
                    filterDTO.SelectSelectedValues = reportParams[key];
                }
                else{
                    filterDTO.Type = EFilterType.Text;
                    filterDTO.TextValue = reportParams[key];
                }
                if (filterDTO !== undefined)
                    params.push(filterDTO);
            }
        }
        if (params.length > 0)
            route += "/?" + params.map(x => {
                let value = this.GetReportFilterValue(x);
                if (value === undefined || value.length > 0)
                    return encodeURIComponent(x.Key) + "=" + this.GetReportFilterValue(x)
                return undefined;
            }).filter(x => x !== undefined).join("&")
        return route;
    }

    public GetReportFilterValue(filter: FilterDTO): string {
        switch (filter.Type) {
            case EFilterType.Text:
                if (filter.TextValue === undefined || filter.TextValue === null || filter.TextValue.length === 0)
                    return "";
                return encodeURIComponent(filter.TextValue);
            case EFilterType.TimeMin:
            case EFilterType.TimeMax:
                throw new Error('not implemented');
            case EFilterType.DateMin:
            case EFilterType.DateMax:
                let minDate = "";
                let maxDate = "";
                if (filter.DateMinValue !== undefined)
                    // @ts-ignore: This expression is not callable
                    minDate = moment(filter.DateMinValue).toISOString();
                if (filter.DateMaxValue !== undefined)
                    // @ts-ignore: This expression is not callable
                    maxDate = moment(filter.DateMaxValue).toISOString();
                if (minDate === "" && maxDate === "")
                    return "";
                return encodeURIComponent(minDate + "-" + maxDate);
            case EFilterType.Select:
                if (filter.SelectSelectedValues === undefined || filter.SelectSelectedValues === null || filter.SelectSelectedValues.length === 0)
                    return "";
                return encodeURIComponent(filter.SelectSelectedValues.join(";"));
            default:
                throw new Error('Unknown filter type: ' + filter.Type);
        }
    }

    public ReportParameters(cqrsApi: ECQRSApi, id?: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/reports/parameters" + (id !== undefined ? ("/" + id) : "");
    }

    // delivery
    public BiddingBet(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tables/biddingbet/items/";
    }

    public DeliveryState(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tables/" + EEntityType[EEntityType.RequestForDeliveryState] + "/items/";
    }

    public ManulaAssign(cqrsApi: ECQRSApi, id: string){
        return `${UrlFabric.GetApiBaseUrl(cqrsApi)}/api/v1/delivery/items/${id}/manualAssign`;
    }


    public DeliveryStates(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tables/" + EEntityType[EEntityType.RequestForDeliveryState];
    }

    public RequestForShipment(id: string){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/reports/delivery/" + id + "/requestForShipment/";
    }

    public DeliveryActualize(id: string){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/items/" + id + "/actualize";
    }

    public GetStatement(){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/documents/statement";
    }

    public GetInvoice(){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/documents/invoice";
    }

    public GetAccount(){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/documents/account";
    }

    public AcceptPendingChanges(id: string){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/" + id + "/changes/accept";
    }

    public RejectPendingChanges(id: string){
        return UrlFabric.GetApiBaseUrl(ECQRSApi.Write) + "/api/v1/delivery/" + id + "/changes/reject";
    }

    // tender

    public TenderBet(cqrsApi: ECQRSApi) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/bets";
    }

    public TenderWithdraw(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/" + id + "/cancel";
    }

    public TenderPublish(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/" + id + "/publish";
    }

    public TenderImport(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/import/" + id;
    }

    public TenderImportExcel(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/import/" + id + "/excel";
    }

    // rating
    public LoadForecastRating(cqrsApi: ECQRSApi, idCargo: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/tenders/import/" + idCargo;
    }

    public static GetApiBaseUrl(cqrsApi: ECQRSApi){
        return (cqrsApi === ECQRSApi.Write ? UrlFabric._writeApi : UrlFabric._readApi)
    }

    // cargotransporter
    public CargoTransporterImport(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/cargotransporter/import/" + id;
    }

    public CargoTransporterImportExcel(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/cargotransporter/import/" + id + "/excel";
    }

    public CargoTransporterImportRating(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/cargotransporter/ratings/import/" + id;
    }

    // repeat request
    public RepeatRequest(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/request/items/" + id + "/repeat";
    }

    // complaint
    public ComplaintSetCommentsReaded(cqrsApi: ECQRSApi) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/comment/read";
    }

    public ComplaintAddComment(cqrsApi: ECQRSApi) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/comment";
    }

    public ComplaintUpdateAdditionalFiles(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/" + id + "/additionalfiles";
    }

    public ComplaintUpdateFile(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/" + id + "/file";
    }

    public ComplaintChangeState(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/" + id + "/state";
    }

    public ComplaintUpdateAcceptedPayment(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/complaint/" + id + "/acceptedpayment";
    }

    public ComplaintGetCargotransportersByDelivery(cqrsApi: ECQRSApi, deliveryId: string) {
        return `${UrlFabric.GetApiBaseUrl(cqrsApi)}/api/v1/complaint/delivery/${deliveryId}/cargotransporters`;
    }

    // service info
    public ServiceInfo(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/serviceinfo";
    }

    // server time
    public GetServerTime(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/service/time";
    }

    public GetDifferenceFromServerTime(cqrsApi: ECQRSApi, localTime: Date){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + `/api/v1/service/offset?clientTime=${localTime.toISOString()}`;
    }

    // search
    public Search(cqrsApi: ECQRSApi, text: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/search?text=" + encodeURIComponent(text);
    }

    public RebuildIndex(cqrsApi: ECQRSApi){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/search/rebuild";
    }

    public BlockSelectedCargotransporterUsers(cqrsApi: ECQRSApi, id: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/cargotransporter/user/selections/" + id + "/block";
    }

    public UnblockSelectedCargotransporterUsers(cqrsApi: ECQRSApi, id: string){
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/cargotransporter/user/selections/" + id + "/unblock";
    }

    public DeliveryAddAdditionalFiles(cqrsApi: ECQRSApi, id: string) {
        return UrlFabric.GetApiBaseUrl(cqrsApi) + "/api/v1/delivery/" + id + "/additionalfiles";
    }
}