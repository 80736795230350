import {
    BASE_ENTITY_MOUNTED,
    BASE_ENTITY_UNMOUNTED,
    REQUEST_BEGIN,
    REQUEST_END,
    VOLMA_ENTITY_CLEAR_REQUESTS,
    VOLMA_ENTITY_DATA_LOADED,
    VOLMA_ENTITY_DTO_STRINGIFY_UPDATED,
    VOLMA_ENTITY_INFO_LINE_TOGGLED,
    VOLMA_ENTITY_SET_ENTITY,
    VOLMA_ENTITY_SET_ENTITY_SERVICE
} from '../../../Constants/AppConstants';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../../Shared/VolmaComponentReducer';
import { IBaseEntityProps, BaseEntityPropsInitial } from './IBaseEntityProps';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IEntityService } from './IEntityService';
import { LocalStorageService } from '../../../Infrastructure/Services/LocalStorageService';
import { FooterNavLink } from '../../Footer/IFooterProps';
import { VOLMA_ENTITY_FOOTER_NAV_ADDED, VOLMA_ENTITY_CLEAR_FOOTER, VOLMA_ENTITY_FOOTER_CLOSED } from '../../../Constants/AppConstants';
import { IRequestBeginPayload, IRequestEndPayload } from '../../App/Payloads';

export class BaseEntityReducer extends VolmaComponentReducer<IBaseEntityProps<IEntityDTO, any>> {
    private _entityService: EntityService;
    private _storageService: LocalStorageService;
    private readonly _infoLineStates: string = "infoLineStates";

    constructor() {
        super(BASE_ENTITY_MOUNTED, BASE_ENTITY_UNMOUNTED);

        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._storageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public Reduce(state: IBaseEntityProps<IEntityDTO, any> = BaseEntityPropsInitial, action: IActionPayloaded<any>): IBaseEntityProps<IEntityDTO, any> {

        let entityService = state.EntityService;
        if(entityService !== undefined){
            let entityReducer = entityService.GetReducer();
            if (entityReducer !== undefined){
                state = entityReducer.Reduce(state, action);
            }
        }

        state = super.Reduce(state, action);
        switch (action.type) {
            case REQUEST_BEGIN:
                return this.RequestBegin(state, action.Payload);
            case REQUEST_END:
                return this.RequestEnd(state, action.Payload);
            case VOLMA_ENTITY_CLEAR_REQUESTS:
                return this.ReduceClearRequests(state);
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case VOLMA_ENTITY_SET_ENTITY:
                return this.ReduceSetEntity(state, action.Payload);
            case VOLMA_ENTITY_SET_ENTITY_SERVICE:
                return this.ReduceSetEntityService(state, action.Payload);
            case VOLMA_ENTITY_INFO_LINE_TOGGLED:
                return this.ReduceInfoLineToggled(state, action.Payload);
            case VOLMA_ENTITY_FOOTER_NAV_ADDED:
                return this.ReduceFooterNavAdded(state, action.Payload);
            case VOLMA_ENTITY_CLEAR_FOOTER:
                return this.ReduceClearFooter(state);
            case VOLMA_ENTITY_FOOTER_CLOSED:
                return this.ReduceFooterClosed(state);
            case VOLMA_ENTITY_DTO_STRINGIFY_UPDATED:
                return this.ReduceDTOStringifyUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    private RequestBegin(state: IBaseEntityProps<IEntityDTO, any>, payload: IRequestBeginPayload): IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state };
        newState.IsSending = true;
        newState.RequestsInProgress.push(payload);
        return newState;
    }

    private RequestEnd(state: IBaseEntityProps<IEntityDTO, any>, payload: IRequestEndPayload): IBaseEntityProps<IEntityDTO, any> {
        let newState = { ...state, RequestsInProgress: [] };
        for (let i = 0; i < state.RequestsInProgress.length; i++) {
            let request = state.RequestsInProgress[i];
            if (request.Uuid !== payload.Uuid) {
                newState.RequestsInProgress.push(request);
            }
        }
        newState.IsSending = newState.RequestsInProgress.length > 0;
        return newState;
    }

    private ReduceClearRequests(state: IBaseEntityProps<IEntityDTO, any>): IBaseEntityProps<IEntityDTO, any> {
        return { ...state, IsSending: false, RequestsInProgress: [] };
    }

    private ReduceDataLoaded(state: IBaseEntityProps<IEntityDTO, any>, payload: IEntityDTO): IBaseEntityProps<IEntityDTO, any> {
        let currentInfoLinesStates = {};
        let savedCurrentInfoLinesStates = this._storageService.GetKey(this._infoLineStates);
        if (savedCurrentInfoLinesStates !== undefined)
            currentInfoLinesStates = JSON.parse(savedCurrentInfoLinesStates);
        let savedClosedCardParts = [];
        if (currentInfoLinesStates[state.EntityType] !== undefined)
            savedClosedCardParts = currentInfoLinesStates[state.EntityType];

        return { ...state, DataDTO: payload, DataDTOStringify: JSON.stringify(payload), ClosedCardParts: savedClosedCardParts };
    }

    private ReduceDTOStringifyUpdated(state: IBaseEntityProps<IEntityDTO, any>, payload: IEntityDTO): IBaseEntityProps<IEntityDTO, any> {
        return { ...state, DataDTOStringify: JSON.stringify(payload)};
    }


    private ReduceSetEntity(state: IBaseEntityProps<IEntityDTO, any>, payload: EEntityType): IBaseEntityProps<IEntityDTO, any> {
        return { ...state, EntityType: payload };
    }

    private ReduceSetEntityService(state: IBaseEntityProps<IEntityDTO, any>, payload: IEntityService<IEntityDTO, any>): IBaseEntityProps<IEntityDTO, any> {
        let dataHelper = payload.GetInitialDataHelper();
        if (dataHelper !== undefined && dataHelper !== null){
            dataHelper = JSON.parse(JSON.stringify(dataHelper));// otherwise we will change original initial items
        }
        return { ...state, EntityService: payload, DataHelper: dataHelper};
    }

    private ReduceInfoLineToggled(state: IBaseEntityProps<IEntityDTO, any>, payload: number): IBaseEntityProps<IEntityDTO, any> {
        let newState = { ...state, ClosedCardParts: state.ClosedCardParts.slice()};
        newState.ClosedCardParts[payload] = state.ClosedCardParts[payload] === true ? false : true

        let currentInfoLinesStates = {};
        let savedCurrentInfoLinesStates = this._storageService.GetKey(this._infoLineStates);
        if (savedCurrentInfoLinesStates !== undefined)
            currentInfoLinesStates = JSON.parse(savedCurrentInfoLinesStates);
        currentInfoLinesStates[state.EntityType] = newState.ClosedCardParts;
        this._storageService.SetKey(this._infoLineStates, JSON.stringify(currentInfoLinesStates));

        return newState;
    }

    private ReduceFooterNavAdded(state: IBaseEntityProps<IEntityDTO, any>, payload: FooterNavLink): IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state, FooterNavList: state.FooterNavList.slice()};

        newState.FooterNavList[payload.Index] = payload;
        newState.FooterOpened = true;

        return newState;
    }

    private ReduceClearFooter(state: IBaseEntityProps<IEntityDTO, any>): IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state};
        newState.FooterNavList = [];

        return newState;
    }

    private ReduceFooterClosed(state: IBaseEntityProps<IEntityDTO, any>) : IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state };

        newState.FooterOpened = false;

        return newState;
    }

    public static Initialize(): (state: IBaseEntityProps<IEntityDTO, any>, action: IActionPayloaded<IBaseEntityProps<IEntityDTO, any>>) => IBaseEntityProps<IEntityDTO, any> {
        const reducer = new BaseEntityReducer();
        return (state: IBaseEntityProps<IEntityDTO, any>, action: IActionPayloaded<IBaseEntityProps<IEntityDTO, any>>) => reducer.Reduce(state, action);
    }
}