import { GIS_COORDINATES_CHANGED, INPUT_VALUE_CHANGE, LAT_COORDINATES_CHANGED, LON_COORDINATES_CHANGED } from '../../Constants/AppConstants';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputActions } from '../Shared/BaseInputActions';

import { injectable } from 'inversify';
import * as moment from 'moment';
import { IVolmaTimeTimeDTOValueChangedPayload } from './Payloads';
import { isInteger } from '../../Infrastructure/Services/Utils';
import { LatLngExpression } from 'leaflet';

@injectable()
export class VolmaMapWithSelectDTOActions extends BaseInputActions {
    public GisCoordinatesChanged(value: LatLngExpression): IActionPayloaded<LatLngExpression>{     
        return { type: GIS_COORDINATES_CHANGED, Payload: value};
    }
    public LatitudeChanged(value: LatLngExpression): IActionPayloaded<LatLngExpression>{                
        return { type: LAT_COORDINATES_CHANGED, Payload: value};
    }
    public LongitudeChanged(value: LatLngExpression): IActionPayloaded<LatLngExpression>{       
        return { type: LON_COORDINATES_CHANGED, Payload: value};
    }
    
}

