import { injectable } from 'inversify';
import {
    HEADER_TOGGLE_NAV,
    NOOP,
    VOLMA_COMPONENT_INITIALIZE,
    VOLMA_ENTITY_SET_ENTITY,
    VOLMA_SOMEWHERE_CLICKED
} from '../../Constants/AppConstants';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';

@injectable()
export class ComponentActions {
    private _componentMountedConstant: string;
    private _componentUnmountedConstant: string;

    public Initialize(mountedConstant: string, unmountedConstant: string) {
        this._componentMountedConstant = mountedConstant;
        this._componentUnmountedConstant = unmountedConstant;
    }

    public Mount(): IAction {
        return { type: this._componentMountedConstant };
    }

    public Noop(): IAction {
        return { type: NOOP };
    }

    public Unmount(): IAction {
        return { type: this._componentUnmountedConstant };
    }

    public LoadData(dataDTO: any): IActionPayloaded<any>{
        return {type: VOLMA_COMPONENT_INITIALIZE, Payload: dataDTO};
    }

    public SetEntityType(entity: EEntityType): IActionPayloaded<EEntityType> {
        return { type: VOLMA_ENTITY_SET_ENTITY, Payload: entity };
    }

    public CloseNav(): IActionPayloaded<number> {
        return { type: HEADER_TOGGLE_NAV, Payload: -1 }
    }

    public SomewhereClicked(): IAction{
        return { type: VOLMA_SOMEWHERE_CLICKED }
    }
}