import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';

export class Saved extends ComplaintStatusDefault{
    public TitleCurrent : string = "complaint:StatusSavedCurrent";
    public TitleFinished: string = "complaint:StatusSavedFinished";
    public TitleFuture  : string = "complaint:StatusSavedFuture";

    public IsActive(dto: ComplaintDTO) {
        return true;
    }

    public IsCurrent(dto: ComplaintDTO){
        return false;
    }
    
    public IsFinished(dto: ComplaintDTO){
        return true;
    }

    public GetActions(dto: ComplaintDTO){
        return [];
    }

    constructor(){
        super();

        this._currentStatus = EComplaintState.Saved;
    }
}