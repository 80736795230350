import {
    REGISTER_FAILED,
    REGISTER_MOUNTED,
    REGISTER_ROLES_LOADED,
    REGISTER_SUCCESSFUL,
    REGISTER_UNMOUNTED
} from '../../Constants/AppConstants';
import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';
import { RegisterUserDTO } from '../../Domain/DTO/RegisterUserDTO';
import { RegisterUserResultDTO } from '../../Domain/DTO/RegisterUserResultDTO';
import { ERegisterResult } from '../../Domain/Enum/ERegisterResult';
import { EUserRole } from '../../Domain/Enum/EUserRole';
import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { Types } from '../../Infrastructure/Types';
import { ApiActions } from '../Shared/ApiActions';
import { ComponentActions } from '../Shared/ComponentActions';
import { IRegisterFailedPayload } from './Payloads';
import { injectable } from 'inversify';
import { EnumService } from '../../Infrastructure/Services/EnumService';
import { AxiosResponse } from 'axios';

@injectable()
class RegisterActions extends ComponentActions {
    private _api: AuthServerInteraction;
    private _apiActions: ApiActions;
    private _enumService: EnumService;

    constructor() {
        super();
        super.Initialize(REGISTER_MOUNTED, REGISTER_UNMOUNTED);
        this._api = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._enumService = VolmaContainer.get<EnumService>(Types.EnumService);
    }

    public DoRegister(registerCommand: RegisterUserDTO, text: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.Register(registerCommand),
                text,
                (response: AxiosResponse, dispatch: any) => {
                    let data: RegisterUserResultDTO = response.data;
                    if (data.Result === ERegisterResult.Success)
                        dispatch(this.RegisterSuccesful());
                    else
                        dispatch(this.RegisterFailed(data.Result, data.Errors));
                });
        }
    }

    public LoadAllRoles(): IActionPayloaded<Array<ListIntOptionDTO>> {
        let roles = this._enumService.GetListIntOptionsLocalized(() => EUserRole);
        return { type: REGISTER_ROLES_LOADED, Payload: roles };

    }

    private RegisterSuccesful(): IAction {
        return { type: REGISTER_SUCCESSFUL };
    }

    private RegisterFailed(result: ERegisterResult, errors: Array<string>): IActionPayloaded<IRegisterFailedPayload> {
        return { type: REGISTER_FAILED, Payload: <IRegisterFailedPayload>{ Result: result, Errors: errors } };
    }

}


export { RegisterActions }