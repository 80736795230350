import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import VolmaInput from '../../VolmaInput';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { ShipperUserReducer } from './ShipperUserReducer';
import { ShipperUserActions } from './ShipperUserActions';
import { IVolmaTableColumn, IVolmaTableAction, IVolmaTableCellRendererParams, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ShipperUserDTO } from '../../../Domain/DTO/ShipperUserDTO';
import { ShipperUserHelperProps, InitialShipperUserHelperProps } from './IHelperProps';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import * as React from 'react';
import { injectable } from 'inversify';
import { ShipperUserTableDTO } from '../../../Domain/DTO/ShipperUserTableDTO';
import VolmaSelect from '../../VolmaSelect/index';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import i18next from '../../i18n';
import { RegisterShipperUserDTO } from '../../../Domain/DTO/RegisterShipperUserDTO';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { DeleteShipperUsersAction } from './Actions/DeleteShipperUsersAction';
import { RouterService } from '../../../Infrastructure/Router/RouterService';

@injectable()
export class ShipperUserEntityService extends BaseEntityService<ShipperUserDTO, ShipperUserHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: ShipperUserReducer;
    private _action: ShipperUserActions;
    private _dispatch: any;
    private _routerService: RouterService;

    private _dto: ShipperUserDTO;
    private _id: string;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer = VolmaContainer.get<ShipperUserReducer>(Types.ShipperUserReducer);
        this._action = VolmaContainer.get<ShipperUserActions>(Types.ShipperUserActions);

        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this.RoleFilter = this.RoleFilter.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.ShipperUser,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.Fio), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.UserName), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.Email), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.PhoneNumber), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.Position), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: ShipperUserTableDTO) => val.Role),
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EUserRole, this._enumService, props, cellRendererProps),
                    IsSortable: false
                },
            ]
        );
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<ShipperUserHelperProps>> {
        if (this.GetTableCanAddEntity(this._entity))
            return [new DeleteShipperUsersAction()];
        return [];
    }

    public GetEntityId(props: IBaseEntityProps<ShipperUserDTO, {}>): string {
        const path: string = props.location.pathname;
        let id: string;

        if (path === this._routerService.GetProfilePathname(EEntityType.ShipperUser))
            id = this._authService.GetCurrentUser().Id;
        else if (props.match.params.id !== undefined && props.match.params.id !== null && props.match.params.id !== "")
            id = props.match.params.id;

        return id;
    }

    public GetEditorModal(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        if(props.DataDTO === undefined || props.DataDTO.Id === undefined)
            return this.CreateShipperUserModalEditor(props);
        return this.EditShipperUserEditorModal(props);
    }

    public GetEditor(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.ShipperUser]
    }

    public IsEditable(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): boolean {
        const currentUser = this._authService.GetCurrentUser();
        if(currentUser.Role === EUserRole.Administrator)
            return true;
        if (currentUser.Role === EUserRole.ShipperLogistExtended && (this._dto === undefined || this._dto.Role === EUserRole.ShipperLogist))
            return true;
        return this.IsEditingCurrentUser();
    }

    public GetHeaderEditor(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperUserDTO) => val.UserName) }
                        Name={PropertyHelper.GetPropertyName((val: ShipperUserDTO) => val.UserName)}
                        Label={i18next.t('shipperuser:UserName')}
                        Placeholder={i18next.t('shipperuser:UserNamePlaceholder')}
                        Value={props.DataDTO.UserName}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable || (props.DataDTO !== undefined && props.DataDTO.Id !== undefined)}
                        dispatch={props.dispatch} />
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperUserDTO) => val.Fio) }
                        Name={PropertyHelper.GetPropertyName((val: ShipperUserDTO) => val.Fio)}
                        Label={i18next.t('shipperuser:Fio')}
                        Placeholder={i18next.t('shipperuser:FioPlaceholder')}
                        Value={props.DataDTO.Fio}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>) {
        this._dispatch = props.dispatch;
        this._dto = undefined;
        this._id = undefined;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialShipperUserHelperProps;
    }

    public OnAfterDataLoaded(dto: ShipperUserDTO): void {
        this._dto = dto;
        this._id = dto.Id;
    }

    public CustomEntitySaveAction(entity: EEntityType, dto: IEntityDTO, isFormValid: boolean, waitingText: string, id?: string) {
        return this._action.SaveEntity(entity, dto, isFormValid, waitingText, id);
    }

    private CreateShipperUserModalEditor(props: IBaseEntityProps<RegisterShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 0, i18next.t("shipperuser:MainInfoTitle"), i18next.t("shipperuser:MainInfoTitleDescription"), this.GetCreateUserMainInfoBlock(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetCreateUserInfoBlock(props))}
                    </div>
                </div>
            </div>
        );
    }

    private EditShipperUserEditorModal(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 0, i18next.t("shipperuser:MainInfoTitle"), i18next.t("shipperuser:MainInfoTitleDescription"), this.GetEditUserMainInfoBlock(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetEditUserInfoBlock(props))}
                    </div>
                </div>
            </div>
        );
    }

    private GetEditUserMainInfoBlock(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperUserDTO) => val.Email) }
                            Name={PropertyHelper.GetPropertyName((val: ShipperUserDTO) => val.Email)}
                            Label={i18next.t('shipperuser:Email')}
                            Value={props.DataDTO.Email}
                            Validator={this._inputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
                <div className={(this._mainInfo("cell", {33: true })).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperUserDTO) => val.PhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ShipperUserDTO) => val.PhoneNumber)}
                            Label={i18next.t('shipperuser:PhoneNumber')}
                            Required={true}
                            Value={props.DataDTO.PhoneNumber}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell", {33: true })).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperUserDTO) => val.Position) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ShipperUserDTO) => val.Position)}
                            Label={i18next.t('shipperuser:Position')}
                            Value={props.DataDTO.Position}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetCreateUserMainInfoBlock(props: IBaseEntityProps<RegisterShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RegisterShipperUserDTO) => val.Email) }
                            Name={PropertyHelper.GetPropertyName((val: RegisterShipperUserDTO) => val.Email)}
                            Label={i18next.t('shipperuser:Email')}
                            Value={props.DataDTO.Email}
                            Validator={this._inputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
                <div className={(this._mainInfo("cell", {33: true })).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RegisterShipperUserDTO) => val.PhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: RegisterShipperUserDTO) => val.PhoneNumber)}
                            Label={i18next.t('shipperuser:PhoneNumber')}
                            Required={true}
                            Value={props.DataDTO.PhoneNumber}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell", {33: true })).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RegisterShipperUserDTO) => val.Position) as any) }
                            Name={PropertyHelper.GetPropertyName((val: RegisterShipperUserDTO) => val.Position)}
                            Label={i18next.t('shipperuser:Position')}
                            Value={props.DataDTO.Position}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetEditUserInfoBlock(props: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ShipperUserDTO) => val.Role) as any) }
                            Name={PropertyHelper.GetPropertyName((x: ShipperUserDTO) => x.Role)}
                            Label={i18next.t("shipperuser:Role")}
                            Placeholder={i18next.t("shipperuser:SelectRole")}
                            EnumGetter={() => EUserRole}
                            OptionsFilter={x => !isEditable || this.RoleFilter(x)}
                            Value={props.DataDTO.Role}
                            Readonly={this.IsEditingCurrentUser() ? true : !isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RegisterShipperUserDTO) => val.ShipperId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RegisterShipperUserDTO) => x.ShipperId)}
                            Label={i18next.t("shipperuser:ShipperId")}
                            Placeholder={i18next.t("shipperuser:SelectShipper")}
                            Value={props.DataDTO.ShipperId}
                            Entity={EEntityType.Shipper}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetCreateUserInfoBlock(props: IBaseEntityProps<RegisterShipperUserDTO, ShipperUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RegisterShipperUserDTO) => val.Role) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RegisterShipperUserDTO) => x.Role)}
                            Label={i18next.t("shipperuser:Role")}
                            Placeholder={i18next.t("shipperuser:SelectRole")}
                            EnumGetter={() => EUserRole}
                            OptionsFilter={x => !isEditable || this.RoleFilter(x)}
                            Value={props.DataDTO.Role}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RegisterShipperUserDTO) => val.Password) as any) }
                            Name={PropertyHelper.GetPropertyName((val: RegisterShipperUserDTO) => val.Password)}
                            Type={"password"}
                            Label={i18next.t('shipperuser:Password')}
                            Value={props.DataDTO.Password}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell", {33: true })).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RegisterShipperUserDTO) => val.ShipperId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RegisterShipperUserDTO) => x.ShipperId)}
                            Label={i18next.t("shipperuser:ShipperId")}
                            Placeholder={i18next.t("shipperuser:SelectShipper")}
                            Value={props.DataDTO.ShipperId}
                            Entity={EEntityType.Shipper}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private RoleFilter(value: ListIntOptionDTO): boolean {
        const currentUser = this._authService.GetCurrentUser();
        const isEditingCurrentUser = this.IsEditingCurrentUser();
        if (isEditingCurrentUser)
            return value.Id === currentUser.Role;
        if (this._authService.IsAdministrator())
            return value.Id === EUserRole.ShipperLogist || value.Id === EUserRole.ShipperLogistExtended || value.Id === EUserRole.Administrator;
        if (this._authService.IsShipperExtended())
            return value.Id === EUserRole.ShipperLogist;
        return false;
    }

    private IsEditingCurrentUser(): boolean {
        const currentUser = this._authService.GetCurrentUser();
        return this._dto !== undefined && this._dto.Id === currentUser.Id;
    }
}