import * as React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { ITableDTO } from '../../Domain/ITableDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import { Types } from '../../Infrastructure/Types';
import { IGlobalState } from '../../Reducers/IGlobalState';
import i18next from '../i18n';
import VolmaForm from '../VolmaForm';
import { IVolmaModalProps } from './IVolmaModalProps';
import { IVolmaModalServerEntityEditPayload } from './Payloads';
import { VolmaModalActions } from './VolmaModalActions';

class VolmaModalServerEntityEdit extends Component<IVolmaModalProps<IEntityDTO, any>, {}>{

    private _modalActions: VolmaModalActions;
    private _entityService: EntityService;
    private _entity: EEntityType;
    private _id: string;
    private _header: string;
    private _onSaved: (dto: ITableDTO) => void;
    private _onCancelled: () => void;

    private _modal        = volmaBlock("modal");
    private _modalWrap    = volmaBlock("modal-wrap");
    private _modalCont    = volmaBlock("modal-cont");
    private _modalDefault = volmaBlock("modal-default");
    private _btnGreen     = volmaBlock("btn-green");
    private _btnRed       = volmaBlock("btn-red");

    constructor(props: IVolmaModalProps<any, any>, context: any) {
        super(props, context);

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);

        const state = this.props.Props as IVolmaModalServerEntityEditPayload;
        this._entity = state.Type;
        this._header = this._entityService.GetEntityHeader(this._entity);
        this._id = state.Id;
        this._onSaved = state.OnSaved;
        this._onCancelled = state.OnCancelled;
    }

    public UNSAFE_componentWillMount() {
        this.props.dispatch(this._modalActions.SetEntityService(this._entityService.GetEntityService(this._entity)));
        this.LoadData();
    }

    public render() {
        this.AdjustMatchParams();
        let editor = this.props.DataDTO !== undefined ? this._entityService.GetEntityService(this._entity).GetEditorModal(this.props) : undefined;

        return (
            <div className={(this._modal.mix(["active"])).toString()}>
                <div className={(this._modalWrap({cont: true })).toString()}>
                    <div className={(this._modalCont).toString()}>
                        <span className={(this._modalCont("title")).toString()}>{i18next.t(this._header)}</span>
                        <VolmaForm {...this.props as any} ClassName={(this._modalDefault).toString()}>
                            <div className={(this._modalDefault("row", {column: true, "no": "shrink" })).toString()}>
                                {editor}
                            </div>
                            <div className={(this._modalDefault("row")).toString()}>
                                <div className={(this._modalDefault("cell")).toString()}>
                                    <button onClick={this.OnSaveClick.bind(this)} className={(this._btnGreen({large: true }).mix(["btn"])).toString()}>{i18next.t('common:ButtonSave')}</button>
                                </div>
                                <div className={(this._modalDefault("cell")).toString()}>
                                    <button onClick={this.OnCancelClick.bind(this)} className={(this._btnRed({large: true }).mix(["btn"])).toString()}>{i18next.t('common:ButtonCancel')}</button>
                                </div>
                            </div>
                        </VolmaForm>
                        <a onClick={this.OnCancelClick.bind(this)} className={(this._modal("close")).toString()}>
                            <svg className={(this._modal("close-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div >
        );
        {/*<Modal open={true} onClose={() => this.OnCancelClick()}>
                <div>
                    <h1>{this._header} {this._id}</h1>
                    <div>{requestsInProgress}</div>
                    <div>{error}</div>
                    {this.props.ConfirmOnUnsavedChangesShown && <div>{i18next.t("common:There are unsaved changes. Leave anyway?")}
                        <button onClick={this.OnCancelClick.bind(this)}>{i18next.t("common:Yes")}</button>
                        <button onClick={this.OnCloseConfirmMessageClick.bind(this)}>{i18next.t("common:No")}</button></div>}


                    <VolmaForm {...this.props}>
                        {editor}
                    </VolmaForm>
                    <VolmaButton label={i18next.t('common:ButtonSave')} onClick={this.OnSaveClick.bind(this)} />
                    <VolmaButton label={i18next.t('common:ButtonCancel')} onClick={this.OnCancelClick.bind(this)} />
                </div>
            </Modal>*/}
    }

    private OnSaveClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        let service = this._entityService.GetEntityService(this._entity);

        if (this.props.IsValid) {
            this.props.dispatch(this._modalActions.SaveServerEntity(this._entity, this.GetBaseEntity(), this.props.DataDTO, this.props.IsValid, i18next.t('common:WaitingForTheServer'), this._id, this._onSaved, service.AlwaysPost()))
        }
        else
            this.props.dispatch(this._modalActions.Submit());
    }

    private OnCancelClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        // if (this.props.DataDTOStringify === JSON.stringify(this.props.DataDTO) || this.props.ConfirmOnUnsavedChangesShown) {
            this._onCancelled();
            this.props.dispatch(this._modalActions.CloseModal());
        // }
        // else
        //     this.props.dispatch(this._modalActions.ShowConfirmOnUnsavedChanges());
    }

    private OnCloseConfirmMessageClick() {
        this.props.dispatch(this._modalActions.HideConfirmOnUnsavedChanges());
    }

    public LoadData() {
        let currentEntotyService = this._entityService.GetEntityService(this._entity);
        this.AdjustMatchParams();
        currentEntotyService.InitializeEntity(this.props)
        if (this._id !== undefined){
            this.props.dispatch(this._modalActions.LoadServerEntity(currentEntotyService, this._entity, this.GetBaseEntity(), i18next.t('common:WaitingForLoadingEntityData'), this._id))
        }
        else {
            let data = {}
            currentEntotyService.InitializeDefaultDTOValues(data)
            this.props.dispatch(this._modalActions.EntityLoaded(data));
        }
    }

    private GetBaseEntity(): EEntityType {
        let entityService = this._entityService.GetEntityService(this._entity);
        let baseEntity = this._entity;
        if (entityService.GetBaseEntity() !== undefined)
            baseEntity = entityService.GetBaseEntity();
        return baseEntity;
    }

    private AdjustMatchParams(){

        this.props.match.params = this.props.Props.Params;
    }

}

const translated = withTranslation(['common'])(withRouter(VolmaModalServerEntityEdit));

function select(state: IGlobalState, ownProps: IVolmaModalProps<any, any>): IVolmaModalProps<any, any> {
    return state.ModalEntity;
}

export const ConnectedVolmaModalServerEntityEdit = connect<IVolmaModalProps<any, any>, {}, {}>(select)(translated);