import { IEntityDTO } from '../../Domain/IEntityDTO';
import { BaseEntityPropsInitial, IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { ObjectValidationResultDTO } from '../../Domain/DTO/ObjectValidationResultDTO';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { IRequestBeginPayload } from '../App/Payloads';
import { WithTranslationProps } from 'react-i18next/*';


export interface IVolmaModalProps<T extends IEntityDTO, THelper> extends IBaseEntityProps<T, THelper>, WithTranslationProps {
    IsSending?: boolean;
    ValidationError?: ObjectValidationResultDTO;
    ServerError?: ServerErrorDTO;
    RequestsInProgress?: Array<IRequestBeginPayload>;
    ConfirmOnUnsavedChangesShown?: boolean;
    Props?: any;
}

export const VolmaModalPropsInitial: IVolmaModalProps<any, any> = <IVolmaModalProps<any, any>>{
    ...BaseEntityPropsInitial,
    IsSending: false,
    ValidationError: undefined,
    ServerError: undefined,
    RequestsInProgress: [],
    ConfirmOnUnsavedChangesShown: false
};