import { TenderCancelDTO } from '../../../../Domain/DTO/TenderCancelDTO';
import { TENDER_TABLE_BET_ADDED_OR_UPDATED, TENDER_TABLE_BETS_LOADED } from '../../../../Constants/AppConstants';
import { TenderBetDTO } from '../../../../Domain/DTO/TenderBetDTO';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { injectable } from 'inversify';
import i18next from '../../../i18n';
import { TenderServerInteraction } from '../../../../Infrastructure/ServerInteraction/TenderServerInteraction';
import { AxiosResponse } from 'axios';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { ECargoTransporterType } from '../../../../Domain/Enum/ECargoTransporterType';


@injectable()
export class TenderTableActions extends EntityAction {
    private _tenderApi: TenderServerInteraction;

    constructor() {
        super();

        this._tenderApi = VolmaContainer.get<TenderServerInteraction>(Types.TenderServerInteraction);
    }

    public LoadUserBets() {
        return super.LoadDataTable<TenderBetTableDTO>(
            EEntityType.ActiveTenderBets,
            x => x.CargoTransporterType === ECargoTransporterType.MultiCargoTransporter ? x.MultiCargoTransporterName : x.CargoTransporterName,
            ESortDir.Asc,
            undefined,
            i18next.t("common:Loading"),
            this.BetsLoaded);
    }

    public AddedOrEditedBet(id: string, dto: TenderBetDTO, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._tenderApi.AddBet(dto),
                i18next.t("common:Loading"),
                (response: AxiosResponse, dispatch: any) => {
                    dispatch(this.BetAddedOrUpdated(dto));
                    reload();
                });
        }
    }
    
    public TenderWithdraw(id: string, dto: TenderCancelDTO, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._tenderApi.Withdraw(id, dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public TenderPublish(id: string, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._tenderApi.Publish(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public TenderImport(id: string, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._tenderApi.Import(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }


    private BetsLoaded(value: Array<TenderBetTableDTO>): IActionPayloaded<Array<TenderBetTableDTO>> {
        return { type: TENDER_TABLE_BETS_LOADED, Payload: value };
    }

    private BetAddedOrUpdated(dto: TenderBetTableDTO): IActionPayloaded<TenderBetTableDTO> {
        return { type: TENDER_TABLE_BET_ADDED_OR_UPDATED, Payload: dto };
    }
}