import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { CloseAction } from '../Action/CloseAction';
import { RejectExaminationAction } from '../Action/RejectExaminationAction';
import { TakeIntoExaminationAction } from '../Action/TakeIntoExaminationAction';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { Saved } from './Saved';
import { CancelAction } from '../Action/CancelAction';


export class Billed extends ComplaintStatusDefault{
    
    public TitleCurrent : string = "complaint:StatusBilledCurrent";
    public TitleFinished: string = "complaint:StatusBilledFinished";
    public TitleFuture  : string = "complaint:StatusBilledFuture";


    public GetActions(dto: ComplaintDTO){
        return [new TakeIntoExaminationAction(), new RejectExaminationAction(), new CancelAction()];
    }

    constructor(){
        super();
        
        this._prevState = new Saved();
        this._currentStatus = EComplaintState.Billed
    }
}