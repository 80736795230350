import * as moment from 'moment';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';
import { isDefined } from '../../../../Infrastructure/Services/Utils';
import { Types } from '../../../../Infrastructure/Types';
import i18next from '../../../i18n';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { ITenderTableHelperProps } from '../Table/ITableHelperProps';
import { TenderBetExtendedDTO } from '../Table/TenderBetExtendedDTO';
import { TenderTableActions } from '../Table/TenderTableActions';

export class TenderBetAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _tenderTableActions: TenderTableActions;
    private _formatService: FormatService;

    public Title: string = "tender:TenderBet";

    constructor() {
        super();

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tenderTableActions = VolmaContainer.get<TenderTableActions>(Types.TenderTableActions);
        this._formatService = VolmaContainer.get<FormatService>(Types.FormatService);
    }

    public IsAvailable(selectedIds: Array<string>, data: Array<TenderTableDTO>, dtoHelper: THelper) {
        if (!this._authService.IsCargoTransporter())
            return false;        
        
        if (data === undefined || selectedIds === undefined || selectedIds.length !== 1)
            return false;        

        const tender = data.find(x => x.Id === selectedIds[0]);

        if (tender === undefined)
            return false;

        const helper = dtoHelper as any;        

        if (tender.ForecastRatingFilter as any !== "" && isDefined(tender.ForecastRatingFilter) && isDefined(helper.CargoTransporterForecastRatings)) {
            const isMultiFactoryTender = this._formatService.IsValueNotEmpty(tender.MultiFactoryId);
            
            const forecastRating = helper.CargoTransporterForecastRatings.find((x: RatingTableDTO) => 
                isMultiFactoryTender ? x.MultiFactoryId == tender.MultiFactoryId : x.LoadingPointId == tender.LoadingPointId);
            
            if (forecastRating === undefined)
                return false;

            if (((Number)(tender.ForecastRatingFilter)) < ((Number)(forecastRating.RatingValue)))
                return false;            
        }

        // @ts-ignore: This expression is not callable
        return tender.State === ETenderState.Published && moment().isBetween(this._timeService.GetLocalTime(tender.StartBiddingDate), this._timeService.GetLocalTime(tender.EndBiddingDate));
    }

    public OnActivate(
        name: string, 
        entity: EEntityType, 
        selectedIds: Array<string>, 
        data: Array<TenderTableDTO>, 
        dispatch: any, reloadData: () => void, 
        dataHelper: ITenderTableHelperProps) {
        const id = selectedIds[0];
        const tender = data.find(x => x.Id === id);

        if (tender === undefined || dataHelper === undefined)
            return;

        const userBetTableDTO: TenderBetTableDTO = dataHelper.AllUserBets !== undefined ? dataHelper.AllUserBets.find((x: TenderBetTableDTO) => x.TenderId === id) : undefined
        const userBet = userBetTableDTO !== undefined ? userBetTableDTO.Bet : undefined;
        const maxBet = tender.MaxBet;

        const dto = new TenderBetExtendedDTO();

        dto.Bet = userBet === undefined ? 0 : userBet;
        dto.MinBetValue = 1;
        dto.MaxBetValue = maxBet;
        dto.TenderId = id;

        dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.TenderBet, dto, undefined, (dto) => {
            dispatch(this._tenderTableActions.AddedOrEditedBet(id, dto, i18next.t("common:Loading"), reloadData));
        }));
    }

    
}