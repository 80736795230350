import {
    BASE_TABLE_MOUNTED,
    BASE_TABLE_SET_ENTITY_SERVICE,
    BASE_TABLE_UNMOUNTED
} from '../../../Constants/AppConstants';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { IBaseEntityProps } from '../../Entity/BaseEntity/IBaseEntityProps';
import { IEntityService } from '../../Entity/BaseEntity/IEntityService';
import { VolmaComponentReducer } from '../../Shared/VolmaComponentReducer';
import { BaseTablePropsInitial, IBaseTableProps } from './IBaseTableProps';
import { BASE_TABLE_CLEAR_FILTERS_AND_SELECTION } from '../../../Constants/AppConstants';

export class BaseTableReducer extends VolmaComponentReducer<IBaseTableProps<any>> {

    constructor(){
        super(BASE_TABLE_MOUNTED, BASE_TABLE_UNMOUNTED)
    }

    public Reduce(state: IBaseTableProps<any> = BaseTablePropsInitial, action: IActionPayloaded<any>): IBaseTableProps<any> {

        let entityService = state.EntityService;
        if (entityService !== undefined) {
            let entityReducer = entityService.GetTableReducer();
            if (entityReducer !== undefined) {
                state = entityReducer.Reduce(state, action);
            }
        }

        let newState = super.Reduce(state, action);
        switch(action.type){
            case BASE_TABLE_SET_ENTITY_SERVICE:
                return this.ReduceSetEntityService(state, action.Payload);
            case BASE_TABLE_CLEAR_FILTERS_AND_SELECTION:
                return this.ReduceClearFiltersAndSelection(state);
        }
        return newState;
    }

    private ReduceSetEntityService(state: IBaseTableProps<any>, payload: IEntityService<IEntityDTO, any>): IBaseEntityProps<IEntityDTO, any> {
        return { ...state, EntityService: payload };
    }

    private ReduceClearFiltersAndSelection(state: IBaseTableProps<any>): IBaseEntityProps<IEntityDTO, any> {
        let newState = { ...state };

        for (let t of newState.Tables){
            t.Filter = [];
            t.IsFilterOpened = false;
            t.OpenedFilter = undefined;
            t.SortOptions = {};
            t.SelectedIds = [];
        }

        return newState;
    }

    public static Initialize(): (state: IBaseTableProps<any>, action: IActionPayloaded<IBaseTableProps<any>>) => IBaseTableProps<any> {
        const reducer = new BaseTableReducer();
        return (state: IBaseTableProps<any>, action: IActionPayloaded<IBaseTableProps<any>>) => reducer.Reduce(state, action);
    }
}