import * as React from "react";
import i18next from "../../i18n";
import { VolmaMap } from "../../VolmaMap/VolmaMap";
import { DeliveryDriverMonitoringDTO, SelectedDelivery } from "./IHelperProps";
export interface DriverMapProps {
    drivers: Array<DeliveryDriverMonitoringDTO>
    center?: SelectedDelivery
}
export class DriverMap extends React.Component<DriverMapProps, unknown> {
    render() {
        let locations = this.props.drivers.map(this.GetDriverLocation)

        if (this.props.center) {
            const {lat, lng} = this.props.center.location
            locations = locations.filter((el) => el.Id === this.props.center.id || (el.Latitude !== lat && el.Longitude !== lng))        
        }
        
        return <VolmaMap center={this.props.center?.location} locations={locations} />
    }

    private GetDriverLocation(dto: DeliveryDriverMonitoringDTO) {
        return {
            Latitude: dto.Latitude, Longitude: dto.Longitude, 
            Id:  dto.Id,
            OrderNumber: dto.OrderNumber,
            PopupText: <div>
                {i18next.t("drivermonitoring:OrderNumber")}: {dto.OrderNumber} <br />
                {i18next.t("drivermonitoring:Identifier")}: {dto.Identifier} <br />
                {i18next.t("drivermonitoring:AssignedDriverName")}: {dto.AssignedDriverName} <br />
                {i18next.t("drivermonitoring:LocationUpdateDate")}: {dto.LocationUpdateDate} <br />
            </div>
        }
    }
}