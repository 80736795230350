import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import {BaseValidator} from "./BaseValidator";
import { IVolmaNumberProps } from '../../Components/VolmaNumber/IVolmaNumberProps';
import i18next from '../../Components/i18n';

@injectable()
export class VolmaNumberValidator extends BaseValidator<any> {
    private _intRegex = /^-?\d+$/;
    private _floatRegex = /^-?((\d+(\.\d *)?)|((\d*\.)?\d+))$/;

    public Validate(entity: IVolmaNumberProps): ValidationResult {
        if (entity === undefined || entity === null)
            throw new Error("Null reference exception");

        if (entity.Value === undefined && entity.Required)
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty"));
        if (entity.Value === undefined && !entity.Required)
            return ValidationResult.Success();

        if (!this._floatRegex.test(entity.Value.toString()))
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeIntegerValue"));
        let value = parseFloat(entity.Value.toString());
        if (entity.MinValue !== undefined && value < entity.MinValue())
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeLargerThenMinValue", <any>{ value: entity.MinValue() }));
        if (entity.MaxValue !== undefined && value > entity.MaxValue())
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeLessThenMaxValue", <any>{ value: entity.MaxValue() }));
        return ValidationResult.Success();
    }
}