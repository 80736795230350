import {
    INPUT_FILE_FILE_INFO_LOADED,
    INPUT_FILE_FILES_CHANGED,
    INPUT_FILE_PROGRESS_UPDATED,
    INPUT_FILE_REMOVE_FILE,
    INPUT_FILE_UPLOAD_ERROR,
    INPUT_FILE_UPLOAD_STARTED,
    INPUT_FILE_UPLOAD_SUCCESSFULLY,
    INPUT_VALUE_CHANGE
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaFileProps } from '../VolmaFile/IVolmaFileProps';
import { VolmaFilePropsInitial, ETagType, IVolmaFile, ProgressDTO } from './IVolmaFileProps';
import { IFileValueChangedPayload, IFileUploadErrorPayload, IFileUploadSuccessfullyPayload, IFileProgressUpdatedPayload, IFileUploadStartedPayload, IFileFilesChangedPayload } from './Payloads';
import { injectable } from 'inversify';
import { FileDTO } from '../../Domain/DTO/FileDTO';
import { IInputPayload } from '../VolmaInput/Payloads';
import * as axios from 'axios';

@injectable()
export class VolmaFileReducer extends BaseInputReducer<IVolmaFileProps> {

    public Reduce(state: IVolmaFileProps = VolmaFilePropsInitial, action: IActionPayloaded<any>): IVolmaFileProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_FILE_FILES_CHANGED:
                return this.ReduceValue(state, action.Payload);
            case INPUT_FILE_REMOVE_FILE:
                return this.ReduceRemove(state, action.Payload)
            case INPUT_FILE_UPLOAD_ERROR:
                return this.ReduceUploadError(state, action.Payload)
            case INPUT_FILE_UPLOAD_STARTED:
                return this.ReduceUploadStarted(state, action.Payload)
            case INPUT_FILE_UPLOAD_SUCCESSFULLY:
                return this.ReduceUploadedSuccessfully(state, action.Payload)
            case INPUT_FILE_FILE_INFO_LOADED:
                return this.ReduceInfoLoadedSuccessfully(state, action.Payload)
            case INPUT_FILE_PROGRESS_UPDATED:
                return this.ReduceProgressUpdated(state, action.Payload)
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaFileProps, payload: IFileFilesChangedPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        let files = payload.Files;
        if (files.length > 0) {
            let regexp = new RegExp(newState.FilesFilter, "i");

            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                if (file.type.match(regexp) || (file.type.length === 0 && file.name.match(regexp))) {
                    newState.FileData.IsFileUploading = false;
                    newState.FileData.File = this.GetFile(file);
                    newState.FileData.ProgressDTO = new ProgressDTO();
                    newState.Value = undefined;
                    break;
                }
            }
        }

        newState.FileData.UploadErrorMessage = undefined;

        return newState;
    }

    private ReduceRemove(state: IVolmaFileProps, payload: IFileValueChangedPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        newState.FileData.IsFileUploading = false;
        newState.FileData.File = undefined;
        newState.FileData.ProgressDTO = new ProgressDTO();
        newState.FileData.UploadErrorMessage = undefined;
        newState.Value = undefined;

        return newState;
    }

    private ReduceUploadError(state: IVolmaFileProps, payload: IFileUploadErrorPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        newState.FileData.IsFileUploading = false;
        newState.FileData.UploadErrorMessage = payload.Response.message;
        newState.Value = undefined;

        return newState;
    }

    private ReduceUploadStarted(state: IVolmaFileProps, payload: IFileUploadStartedPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        if (newState.FileData.IsFileUploading){
            throw new Error('Upload must be aborted first');
        }

        newState.FileData.CancelTokenSource = payload.CancelTokenSource;
        newState.FileData.IsFileUploading = true;
        newState.FileData.ProgressDTO = new ProgressDTO();
        newState.Value = undefined;
        newState.FileData.UploadErrorMessage = undefined;

        return newState;
    }

    private ReduceUploadedSuccessfully(state: IVolmaFileProps, payload: IFileUploadSuccessfullyPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        newState.FileData.IsFileUploading = false;
        newState.FileData.ProgressDTO = new ProgressDTO();
        newState.FileData.UploadErrorMessage = undefined;
        newState.FileData.File = undefined;
        newState.Value = payload.Value.Id;

        return newState;
    }

    private ReduceInfoLoadedSuccessfully(state: IVolmaFileProps, payload: IFileUploadSuccessfullyPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        //newState.FileTableDTO = payload.Value;

        return newState;
    }

    private ReduceProgressUpdated(state: IVolmaFileProps, payload: IFileProgressUpdatedPayload) {
        let newState: IVolmaFileProps = { ...state, FileData: { ...state.FileData } };

        newState.FileData.ProgressDTO.Progress = payload.Progress;

        return newState;
    }

    private GetFile(file): IVolmaFile {
        return <IVolmaFile>{
            Element: undefined,
            File: file,
            FileName: file.name,
            DataUrl: window.URL.createObjectURL(file),
            FileType: file.type,
            TagType: ETagType.Object
        };
    }
}