import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputActions } from '../Shared/BaseInputActions';
import { IVolmaTimeValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';
import * as moment from 'moment';
import { isInteger } from '../../Infrastructure/Services/Utils';

@injectable()
export class VolmaTimeActions extends BaseInputActions {

    public ChangeHoursValue(name: string, time: Date, value: string): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let numValue = this.GetNumberValue(value);
        let newTime = this.GetTimeMoment(time);
        newTime.hours(numValue);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }

    public ChangeMinutesValue(name: string, time: Date, value: string): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let numValue = this.GetNumberValue(value);
        let newTime = this.GetTimeMoment(time);
        newTime.minutes(numValue);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }

    public IncrementHours(name: string, time: Date): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let newTime = this.GetTimeMoment(time);
        newTime.add(1, 'hours');
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }


    public DecrementHours(name: string, time: Date): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let newTime = this.GetTimeMoment(time);
        newTime.subtract(1, 'hours');
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }

    public IncrementMinutes(name: string, time: Date, step: number): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let newTime = this.GetTimeMoment(time);
        newTime.add(step, 'minutes');
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }

    public DecrementMinutes(name: string, time: Date, step: number): IActionPayloaded<IVolmaTimeValueChangedPayload> {
        let newTime = this.GetTimeMoment(time);
        newTime.subtract(step, 'minutes');
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime.toDate() } };
    }

    private GetTimeMoment(time: Date): moment.Moment{
        if (time !== undefined)
            // @ts-ignore: This expression is not callable
            return moment(time);
        // @ts-ignore: This expression is not callable
        let res = moment(new Date())
        res.hours(0);
        res.minutes(0);
        res.seconds(0);
        res.milliseconds(0);

        return res;
    }

    private GetNumberValue(value: string | number): number {
        if (isInteger(<number>value))
            return <number>value;

        let numValue: number = undefined;
        if (value === undefined || value === null)
            numValue = 0;
        else if (!isNaN(parseInt(<string>value))) {
            numValue = parseInt(<string>value);
        }
        else
            numValue = 0;

        return numValue;
    }

    private EnsureNoUndefined(time: TimeDTO) {
        if (time.Hours === undefined)
            time.Hours = 0;
        if (time.Minutes === undefined)
            time.Minutes = 0;
    }

    private NormalizeTime(time: TimeDTO) {
        let totalMinutes = time.Hours * 60 + time.Minutes;
        if (totalMinutes < 0)
            totalMinutes = 0;
        time.Hours = Math.floor(totalMinutes / 60);
        time.Minutes = totalMinutes - time.Hours * 60;
        if (time.Hours > 23)
            time.Hours = 0;
    }
}

