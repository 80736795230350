import { InstructionDTO } from './../../../Domain/DTO/InstructionDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { injectable } from 'inversify';

@injectable()
export class InstructionReducer extends EntityReducer<InstructionDTO, any> {

    public Reduce(state: IBaseEntityProps<InstructionDTO, any>, action: IActionPayloaded<any>): IBaseEntityProps<InstructionDTO, any>{
        switch (action.type) {
            default:
                return state;
        }
    }
}
