import { TenderCancelDTO } from '../../../../Domain/DTO/TenderCancelDTO';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import i18next from '../../../i18n';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { BiddingBetTableDTO } from '../../../../Domain/DTO/BiddingBetTableDTO';
import { BiddingBetExtendedDTO } from '../ActionBet/BiddingBetExtendedDTO';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import { IDeliveryTableHelperProps } from '../Table/ITableHelperProps';
import * as moment from 'moment';
import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';

export class BiddingBetAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:BiddingBet";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>) {
        if (this._authService.IsCargoTransporter()){
            if (data === undefined || selectedIds === undefined || selectedIds.length !== 1)
                return false;
            let delivery = data.find(x => x.Id === selectedIds[0]);
            if (delivery === undefined)
                return false;
            let deadline = delivery.AuctionDeadline || (delivery as DeliveryDTO).EndBiddingDate;
            return delivery.State === EDeliveryState.Assignation && delivery.AssignType === ERequestForDeliveryAssigner.Bidding && this._timeService.IsInFuture(deadline);
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void, dataHelper: IDeliveryTableHelperProps) {
        let id = selectedIds[0];
        let delivery = data.find(x => x.Id === id);

        if(delivery === undefined)
            return;
        if(dataHelper === undefined)
            return;

        let userBetTableDTO: BiddingBetTableDTO = dataHelper.AllUserBets !== undefined ? dataHelper.AllUserBets.find((x: BiddingBetTableDTO) => x.DeliveryId === id) : undefined
        let userBet = userBetTableDTO !== undefined ? userBetTableDTO.Bet : undefined;

        dispatch(this._deliveryTableActions.AddOrEditBet(userBet, delivery.Currency, id));
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}