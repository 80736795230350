import i18next from 'i18next';
import { isNullOrUndefined } from 'util';
import { IVolmaTimeTimeDTOProps } from '../../Components/VolmaTimeTimeDTO/IVolmaTimeTimeDTOProps';
import ValidationResult from './ValidationResult';
import { VolmaTimeTimeDTOValidator } from './VolmaTimeTimeDTOValidator';


export class VolmaTimeZoneValidator extends VolmaTimeTimeDTOValidator {
    private readonly TimeZoneMinHoursBorder = 12;
    private readonly TimeZoneMaxHoursBorder = 14;

    protected HoursAndMinutesRule(entity: IVolmaTimeTimeDTOProps): ValidationResult | undefined {
        if (isNullOrUndefined(entity.Value)) {
            return undefined;
        }

        const { Hours: hours, Minutes: minutes, IsNegative: isNegative } = entity.Value;

        if (hours > this.TimeZoneMinHoursBorder && isNegative)
            return ValidationResult.Fail(i18next.t("validate:TimeZoneHoursMin", { min: -this.TimeZoneMinHoursBorder }));

        if (hours > this.TimeZoneMaxHoursBorder && !isNegative)
            return ValidationResult.Fail(i18next.t("validate:TimeZoneHoursMax", { max: this.TimeZoneMaxHoursBorder }));

        if ((hours === this.TimeZoneMinHoursBorder && isNegative || hours === this.TimeZoneMaxHoursBorder && !isNegative) && minutes > 0)
            return ValidationResult.Fail(i18next.t("validate:MinutesNotAllowedForHoursBorder"));

        if (minutes < 0) return ValidationResult.Fail(i18next.t("validate:MinutesMustBeNonNegative"));
        if (minutes > 59) return ValidationResult.Fail(i18next.t("validate:MinutesMustBeLessThan60"));

        return ValidationResult.Success();
    }
}
