import { injectable } from "inversify";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IVolmaTableColumn } from "../../../Table/VolmaTable/IVolmaTableProps";
import { ComplaintEntityService } from "../ComplaintEntityService";

@injectable()
export class ComplaintActiveEntityService extends ComplaintEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Complaint;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        const commonTemplates = [
            { ...this._complaintColumnTemplates.Id },
            { ...this._complaintColumnTemplates.RegistrationCode },
            { ...this._complaintColumnTemplates.TemplateName },
            { ...this._complaintColumnTemplates.ComplaintState },
            { ...this._complaintColumnTemplates.DateOfCreation },
            { ...this._complaintColumnTemplates.DeliveriesIdentifiers },
            { ...this._complaintColumnTemplates.AcceptedPayment },
        ];

        if (this._authService.IsShipper()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
                ...commonTemplates,
                { ...this._complaintColumnTemplates.MultiTransporterName },
                { ...this._complaintColumnTemplates.DateOfLastUpdate },
            ]);
        }

        if (this._authService.IsIncludedCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
                ...commonTemplates,
                { ...this._complaintColumnTemplates.CargoTransporterName },
                { ...this._complaintColumnTemplates.DateOfLastUpdate },
            ]);
        }

        return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
            ...commonTemplates,
            { ...this._complaintColumnTemplates.DateOfLastUpdate },
        ]);
    }
}
