import {
    CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED,
    CARGO_TRANSPORTER_DRIVER_ADDED,
    CARGO_TRANSPORTER_DRIVER_EDITED,
    CARGO_TRANSPORTER_DRIVERS_LOADED,
    CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED,
    CARGO_TRANSPORTER_RATING_ADDED,
    CARGO_TRANSPORTER_RATING_EDITED,
    CARGO_TRANSPORTER_VEHICLE_ADDED,
    CARGO_TRANSPORTER_VEHICLE_EDITED,
    CARGO_TRANSPORTER_VEHICLES_LOADED,
    CARGO_TRANSPORTER_CONTRACTS_LOADED,
    CARGO_TRANSPORTER_CONTRACT_ADDED,
    CARGO_TRANSPORTER_CONTRACT_EDITED,
    CARGO_TRANSPORTER_CONTRACTS_REMOVED
    
} from '../../../Constants/AppConstants';
import { DriverDTO } from '../../../Domain/DTO/DriverDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { VehicleTableDTO } from '../../../Domain/DTO/VehicleTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { RatingActions } from '../CargoTransporter/Rating/RatingActions';
import { injectable } from 'inversify';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { RatingDTO } from '../../../Domain/DTO/RatingDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { EntityAction } from '../BaseEntity/EntityAction';
import { AxiosResponse } from 'axios';
import { ContractTableDTO } from '../../../Domain/DTO/ContractTableDTO';


@injectable()
export class CargoTransporterActions extends EntityAction {

    public LoadDrivers(id: string, waitingText: string, filter: string = undefined) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.Driver,
            (x: DriverTableDTO) => x.Name,
            ESortDir.Asc,
            waitingText,
            this.DriversLoaded);
    }

    public LoadContracts(id: string, waitingText: string, filter: string = undefined) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.Contract,
            (x: ContractTableDTO) => x.EndTime,
            ESortDir.Asc,
            waitingText,
            this.ContractsLoaded);
    }

    public LoadVehicles(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.Vehicle,
            (x: VehicleTableDTO) => x.Name,
            ESortDir.Asc,
            waitingText,
            this.VehiclesLoaded);
    }

    public LoadCurrentRatings(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.TopCurrentRating,
            (x: RatingTableDTO) => x.LoadingPointName,
            ESortDir.Asc,
            waitingText,
            this.CurrentRatingsLoaded);
    }

    public LoadForecastRatings(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.ForecastRating,
            (x: RatingTableDTO) => x.Timestamp,
            ESortDir.Desc,
            waitingText,
            this.ForecastRatingsLoaded);
    }


    public DriverAdded(dto: DriverTableDTO): IActionPayloaded<DriverTableDTO> {
        return { type: CARGO_TRANSPORTER_DRIVER_ADDED, Payload: dto }
    }

    public DriverEdited(dto: DriverTableDTO): IActionPayloaded<DriverTableDTO> {
        return { type: CARGO_TRANSPORTER_DRIVER_EDITED, Payload: dto }
    }

    public VehicleAdded(dto: VehicleTableDTO): IActionPayloaded<VehicleTableDTO> {
        return { type: CARGO_TRANSPORTER_VEHICLE_ADDED, Payload: dto }
    }

    public VehicleEdited(dto: VehicleTableDTO): IActionPayloaded<VehicleTableDTO> {
        return { type: CARGO_TRANSPORTER_VEHICLE_EDITED, Payload: dto }
    }

    public RatingAdded(dto: RatingTableDTO): IActionPayloaded<RatingTableDTO> {
        return { type: CARGO_TRANSPORTER_RATING_ADDED, Payload: dto }
    }

    public RatingEdited(dto: RatingTableDTO): IActionPayloaded<RatingTableDTO> {
        return { type: CARGO_TRANSPORTER_RATING_EDITED, Payload: dto }
    }

    private DriversLoaded(value: Array<DriverTableDTO>): IActionPayloaded<Array<DriverTableDTO>> {
        return { type: CARGO_TRANSPORTER_DRIVERS_LOADED, Payload: value };
    }

    private ContractsLoaded(value: Array<ContractTableDTO>): IActionPayloaded<Array<ContractTableDTO>> {
        return { type: CARGO_TRANSPORTER_CONTRACTS_LOADED, Payload: value };
    }

    public ContractAdded(dto: ContractTableDTO): IActionPayloaded<ContractTableDTO> {
        return { type: CARGO_TRANSPORTER_CONTRACT_ADDED, Payload: dto }
    }

    public ContractEdited(dto: ContractTableDTO): IActionPayloaded<ContractTableDTO> {
        return { type: CARGO_TRANSPORTER_CONTRACT_EDITED, Payload: dto }
    }

    private VehiclesLoaded(value: Array<VehicleTableDTO>): IActionPayloaded<Array<VehicleTableDTO>> {
        return { type: CARGO_TRANSPORTER_VEHICLES_LOADED, Payload: value };
    }

    private CurrentRatingsLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED, Payload: value };
    }

    private ForecastRatingsLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED, Payload: value };
    }

    public ContractsRemovedFromCargoTransporter(value: string[]): IActionPayloaded<Array<string>>{
        return { type: CARGO_TRANSPORTER_CONTRACTS_REMOVED, Payload: value};
    }
}