import * as moment from 'moment';
import * as React from 'react';
import InfiniteCalendar from 'react-infinite-calendar';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { VolmaCalendarValidator } from '../../Infrastructure/Validation/VolmaCalendarValidator';
import { IVolmaCalendarProps } from './IVolmaCalendarProps';
import { VolmaCalendarActions } from './VolmaCalendarActions';
import { VolmaCalendarReducer } from './VolmaCalendarReducer';
import * as ru_locale from 'date-fns/locale/ru'

class VolmaCalendar extends React.Component<IVolmaCalendarProps, {}> {
    private _defaultValidator: VolmaCalendarValidator = VolmaContainer.get<VolmaCalendarValidator>(Types.VolmaCalendarValidator);
    private _calendarActions: VolmaCalendarActions;
    private _calendarReducer: VolmaCalendarReducer;
    private _input;
    private _clickedInside: boolean;
    private _selectedDateChanged: boolean;
    private _clickTimeout: any;

    private _defaultInput       = volmaBlock('volma-input');
    private _darkInput          = volmaBlock('input-dark');
    private _defaultDate        = volmaBlock('default-date');
    private _defaultPlaceholder = volmaBlock('volma-placeholder');
    private _defaultInputError  = volmaBlock('default-input-error');
    private _volmaCalendar      = volmaBlock('volma-calendar');

    private readonly BACKSPACE = 8;
    private readonly DELETE = 46;

    constructor(props: IVolmaCalendarProps, context: any) {
        super(props, context);

        this._calendarActions = VolmaContainer.get<VolmaCalendarActions>(Types.VolmaCalendarActions);
        this._calendarReducer = VolmaContainer.get<VolmaCalendarReducer>(Types.VolmaCalendarReducer);

        this.OnFocus = this.OnFocus.bind(this);
        this.OnBlur = this.OnBlur.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnIconClicked = this.OnIconClicked.bind(this);
        this.OnClear = this.OnClear.bind(this);
    }

    componentWillUnmount() {
        clearTimeout(this._clickTimeout);
    }

    render() {
        let errors = (!this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted)) ?
            <div className={(this._defaultInputError()).toString()}>
                <div className={(this._defaultInputError("text")).toString()}>{this.props.ErrorMessage}</div>
            </div>
            :
            undefined;

        let currentInput = this.props.IsInHeader ? this._darkInput : this._defaultInput;
        let isErrorShown = errors !== undefined;

        let inputProps = {
            type: "text",
            ref: (el) => { this._input = el; },
            value: this.props.Value === undefined ? "" : new Intl.DateTimeFormat('ru-RU', {year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(this.props.Value)),
            onKeyDown: this.OnKeyDown,
            onFocus: this.OnFocus,
            onBlur: this.OnBlur,
            readOnly:true,
            className: currentInput("input").mix(["input"])
        }

        let isActive = this.props.IsActive || this.props.Value !== undefined;
        return (
            <div>
                <div className={(currentInput()).toString()} onMouseDown={this.OnMouseDown.bind(this)}>
                    {!this.props.IsInHeader && <label className={(this._defaultPlaceholder.mix([isActive ? "active" : ""])).toString()} onClick={this.OnFocus}>{this.props.Label}</label>}
                    <input  {...inputProps} />
                    <a onClick={this.OnIconClicked} className={(this._defaultDate("ico-wrapper", { dark: this.props.IsInHeader })).toString()}>
                        <svg className={(this._defaultDate("ico", { dark: this.props.IsInHeader }).mix(["date-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                        </svg>
                    </a>
                    {this.props.IsClearable && <a className={(this._defaultDate("ico-wrapper", { clear: true, dark: this.props.IsInHeader })).toString()}>
                        <span onClick={this.OnClear} className="Select-clear-zone" title="Clear value" aria-label="Clear value">
                            <span className="Select-clear">×</span>
                        </span>
                    </a>}
                    {this.props.ShowOverlay && <div className={(this._volmaCalendar()).toString()}>
                        <div className={(this._volmaCalendar("content", { dark: this.props.IsInHeader })).toString()}>
                            <InfiniteCalendar
                                minDate={this.props.MinDate}
                                maxDate={this.props.MaxDate}
                                shouldHeaderAnimate={true}
                                width={400}
                                height={250}
                                selected={this.props.Value}
                                keyboardSupport={true}
                                locale={{
                                    locale: ru_locale,
                                    headerFormat: 'dddd, D MMM',
                                    weekdays: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                                    blank: 'Дата не выбрана',
                                    todayLabel: {
                                        long: 'Сегодня',
                                        short: 'Сег.'
                                    },
                                    weekStartsOn: 1,
                                }}
                                theme={{
                                    selectionColor: '#00b4e5',
                                    textColor: {
                                        default: '#7b8693',
                                        active: '#FFF'
                                    },
                                    weekdayColor: '#00b4e5',
                                    headerColor: '#4a80c1',
                                    floatingNav: {
                                        background: '#31547d',
                                        color: '#FFF',
                                        chevron: '#FFA726'
                                    }
                                }}
                                onSelect={this.OnSelectedDateChanged.bind(this)}/>
                        </div>
                    </div>
                    }
                </div>
                {!this.props.IsInHeader && <div className={this._defaultInput("message-container").toString()}>
                    {this.GetAdditionalTextBlock()}
                    {errors}
                </div>}
                {this.props.IsInHeader && <span className={(currentInput("desc")).toString()}>{this.props.Label}  {isErrorShown && (" - " + this.props.ErrorMessage)}</span>}
            </div>
        )
    }

    public componentDidMount() {
        let props: IVolmaCalendarProps = {
            Value: this.props.Value,
            DefaultHours: this.props.DefaultHours,
            DefaultMinutes: this.props.DefaultMinutes,
            DefaultSeconds: this.props.DefaultSeconds,
            ShowOverlay: false,
            CustomDataUpdate: this.props.CustomDataUpdate,
            Required: this.props.Required,
            Disabled: this.props.Disabled,
            IsActive: this.props.Value !== undefined,
            IsInHeader: this.props.IsInHeader === undefined ? false : this.props.IsInHeader,
            IsClearable: this.props.IsClearable,
        }

        this.props.dispatch(this._calendarActions.Register(this.props.Name, this._calendarReducer, this.props.Validator || this._defaultValidator, props));
        this.props.dispatch(this._calendarActions.Validate(this.props.Name));
    }

    private OnSelectedDateChanged(selectedDate: Date) {
        if (this.props.OnValueChange !== undefined)
            this.props.OnValueChange(selectedDate);
        else {
            let hours = this.props.DefaultHours !== undefined ? this.props.DefaultHours : 0;
            let minutes = this.props.DefaultMinutes !== undefined ? this.props.DefaultMinutes : 0;
            let seconds = this.props.DefaultSeconds !== undefined ? this.props.DefaultSeconds : 0;
            if (this.props.Value !== undefined) {
                // @ts-ignore: This expression is not callable
                let momentDate = moment(this.props.Value);
                hours = momentDate.hours();
                minutes = momentDate.minutes();
                seconds = momentDate.seconds();
            }
            let newDate;
            if (selectedDate !== undefined) {
                // @ts-ignore: This expression is not callable
                newDate = moment(selectedDate);
                newDate.hours(hours);
                newDate.minutes(minutes);
                newDate.seconds(seconds);
            }

            this.props.dispatch(this._calendarActions.ChangeValue(this.props.Name, selectedDate !== undefined ? newDate.toDate() : undefined));
            this.props.dispatch(this._calendarActions.Validate(this.props.Name));
        }

        if (!this.props.IsActive)
            this.props.dispatch(this._calendarActions.SetActive(this.props.Name))
        this._selectedDateChanged = true;
        setTimeout(() => {
            this._selectedDateChanged = false;
        });
        this._input.blur();
    }

    private OnMouseDown() {
        console.log('mouse down')
        // this.MarkTouched();
        // in order to filter out blur event that
        this._clickedInside = true;
        this._clickTimeout = setTimeout(() => {
            this._clickedInside = false;
        }, 0);

    }

    private OnIconClicked() {
        if (this._input !== undefined && this._input !== null) {
            this.OnMouseDown();
            this._input.focus()
        }          
            
        if (this.props.ShowOverlay) {
            this.ToggleOverlay(false);
            this.props.dispatch(this._calendarActions.RemoveActive(this.props.Name));
        }            
    }

    private OnClear() {
        if (this.props.OnValueChange) {
            this.props.OnValueChange(undefined);
        }
        
        this.props.dispatch(this._calendarActions.RemoveActive(this.props.Name));
        this.props.dispatch(this._calendarActions.ChangeValue(this.props.Name, undefined));
        this.props.dispatch(this._calendarActions.Validate(this.props.Name));
    }

    private OnFocus() {
        console.log('focus')
        this.MarkTouched();
        if (!this.props.IsActive) {
            this.props.dispatch(this._calendarActions.SetActive(this.props.Name));
        }

        if (!this.props.ShowOverlay) {
            this.ToggleOverlay(true);
        }
    }

    private OnBlur() {
        console.log('blur ' + this._clickedInside);
        let showOverlay = this._clickedInside;

        if (!showOverlay) {
            if (this.props.Value === undefined && !this._selectedDateChanged) {
                this.props.dispatch(this._calendarActions.RemoveActive(this.props.Name));
            }

            this.ToggleOverlay(showOverlay)
        }

        if (showOverlay) {
            setTimeout(() => this._input.focus());
        }
    }

    private OnKeyDown(e: any) {
        if (e.keyCode === this.BACKSPACE || e.keyCode === this.DELETE) {
            this.OnSelectedDateChanged(undefined);
        }
    }

    private ToggleOverlay(val: boolean) {
        if (this.props.OnOverlayToggled !== undefined)
            this.props.OnOverlayToggled(val);
        else
            this.props.dispatch(this._calendarActions.ToggleOverlay(this.props.Name, val))
    }

    private MarkTouched() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._calendarActions.Touched(this.props.Name))
    }

    private GetAdditionalTextBlock(): JSX.Element | undefined {
        if (!this.props.AdditionalText) {
            return;
        }

        return <>
            <div className={(this._defaultInput("additional-text")).toString()}>{this.props.AdditionalText}</div>
        </>;
    }
}

export default VolmaCalendar;