import { injectable } from 'inversify';
import {
    REPORT_LOADED,
    REPORT_MOUNTED,
    REPORT_UNMOUNTED
} from '../../../Constants/AppConstants';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { VolmaComponentReducer } from '../../Shared/VolmaComponentReducer';
import { IReportProps, ReportPropsInitial } from './IReportProps';
import { ReportEntityService } from './ReportEntityService';
import { ReportReducer } from './ReportReducer';


@injectable()
export class ReportComponentReducer extends VolmaComponentReducer<IReportProps> {
    private _reportEntityService: ReportEntityService;
    private _reportReducer: ReportReducer;

    constructor() {
        super(REPORT_MOUNTED, REPORT_UNMOUNTED);

        this._reportEntityService = VolmaContainer.get<ReportEntityService>(Types.ReportEntityService);
        this._reportReducer = this._reportEntityService.GetReducer();
    }

    public Reduce(state: IReportProps = ReportPropsInitial, action: IActionPayloaded<any>): IReportProps {
        state = super.Reduce(state, action);
        if (!state.IsMounted)
            return state;
        state = this._reportReducer.Reduce(state, action);
        switch (action.type) {
            case REPORT_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            default:
                return state;

        }
    }

    private ReduceDataLoaded(state: IReportProps, payload: IEntityDTO): IReportProps {
        return <IReportProps>{ ...state, DataDTO: payload };
    }

    public static Initialize(): (state: IReportProps, action: IActionPayloaded<IReportProps>) => IReportProps {
        const reducer = new ReportComponentReducer();
        return (state: IReportProps, action: IActionPayloaded<IReportProps>) => reducer.Reduce(state, action);
    }
}