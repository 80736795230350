/** @flow */
import * as React from 'react'
import { Component } from 'react'

// Import directly to avoid Webpack bundling the parts of react-virtualized that we are not using
import List from 'react-virtualized/dist/commonjs/List'
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { VolmaSelectActions } from './VolmaSelectActions';
import { Types } from '../../Infrastructure/Types';
import { IVolmaSelectProps, SelectAsyncResult, VolmaSelectExtendedTableDTO } from './IVolmaSelectProps';
import { VolmaSelectReducer } from './VolmaSelectReducer';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaSelectValidator } from '../../Infrastructure/Validation/VolmaSelectValidator';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import Select from './react-select/Select';
import Async from './react-select/Async';
import Creatable from './react-select/Creatable';
import i18next from '../i18n';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { TableServerInteraction } from '../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { EFilterType } from '../../Domain/Enum/EFilterType';
import { ESortDir } from '../../Domain/Enum/ESortDir';
import { DataTableDTO } from '../../Domain/DTO/DataTableDTO';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';
import { EnumService } from '../../Infrastructure/Services/EnumService';
import { ITableDTO } from '../../Domain/ITableDTO';
import * as PropTypes from 'prop-types'; // ES6
import VolmaAutoSizer from '../VolmaAutoSizer/VolmaAutoSizer';
import { isDefined, isNullOrUndefined } from '../../Infrastructure/Services/Utils';
import { ISelectMultiEntityData } from './Payloads';

export default class VolmaSelect extends Component<IVolmaSelectProps, any> {
    private _virtualScroll: any;
    private _defaultValidator: VolmaSelectValidator;
    private _actions: VolmaSelectActions;
    private _reducer: VolmaSelectReducer;
    private _enumService: EnumService;

    private _cache: any;
    private _selectOptionsCache: any;
    private _selectedItemsCache: Array<ITableDTO>;

    private _darkSelect           = volmaBlock("dark-select");
    private _defaultSelect        = volmaBlock("default-select");
    private _defaultSelectWrapper = volmaBlock("default-select-wrapper");
    private _defaultSelectOuter   = volmaBlock("default-select-outer");
    private _defaultInputError    = volmaBlock('default-input-error');
    private _defaultPlaceholder   = volmaBlock('volma-placeholder');

    private _api: TableServerInteraction;
    private _entityService: EntityService;

    static propTypes = {
        async: PropTypes.bool,
        listProps: PropTypes.object,
        maxHeight: PropTypes.number.isRequired,
        optionHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
        optionRenderer: PropTypes.func,
        selectComponent: PropTypes.func,
    };

    static defaultProps = {
        async: false,
        maxHeight: 250,
        optionHeight: 65
    };

    constructor(props, context) {
        super(props, context)

        this._api = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._enumService = VolmaContainer.get<EnumService>(Types.EnumService);

        this._renderMenu = this._renderMenu.bind(this);
        this._optionRenderer = this._optionRenderer.bind(this);
        this._GetOptionsAsync = this._GetOptionsAsync.bind(this);
        this._OnSelectedValuesChange = this._OnSelectedValuesChange.bind(this);

        this._actions = VolmaContainer.get<VolmaSelectActions>(Types.VolmaSelectActions);
        this._reducer = VolmaContainer.get<VolmaSelectReducer>(Types.VolmaSelectReducer);
        this._defaultValidator = VolmaContainer.get<VolmaSelectValidator>(Types.VolmaSelectValidator);
        this._cache = {};
        this._selectOptionsCache = {};
        this._selectedItemsCache = []
    }

    public shouldComponentUpdate(nextProps: IVolmaSelectProps, nextState: any) {
        const shouldUpdate = nextProps.Value !== this.props.Value ||
            nextProps.Options !== this.props.Options ||
            nextProps.Disabled !== this.props.Disabled ||
            nextProps.IsTouched !== this.props.IsTouched ||
            nextProps.IsSubmitted !== this.props.IsSubmitted ||
            nextProps.ErrorMessage !== this.props.ErrorMessage ||
            nextProps.IsValid !== this.props.IsValid ||
            nextProps.ServerRequestFilter !== this.props.ServerRequestFilter;
        return shouldUpdate;
    }

    /** See List#recomputeRowHeights */
    recomputeOptionHeights(index = 0) {
        if (this._virtualScroll) {
            this._virtualScroll.recomputeRowHeights(index)
        }
    }

    render() {
        let errors = (!this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted)) ?
            <div className={this._defaultInputError()}>
                <div className={this._defaultInputError("text")}>{this.props.ErrorMessage}</div>
            </div>
            :
            undefined;

        const SelectComponent = this._getSelectComponent()
        let currentSelect = this.props.IsInHeader ? this._darkSelect : this._defaultSelect;

        let loadOptionsAsync = this.props.Entity !== undefined;
        let labelKey = this._GetLabelKey(loadOptionsAsync);

        let options;
        if (this.props.EnumGetter !== undefined) { // select on enum
            options = this._enumService.GetListIntOptionsLocalized(this.props.EnumGetter);

            if (this.props.OptionsFilter !== undefined && !Array.isArray(this.props.OptionsFilter)) {
                const enumFilter = this.props.OptionsFilter as ((x: ListIntOptionDTO) => boolean);
                options = options.filter(x => enumFilter(x));
            }
            labelKey = PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Name)
        }
        else
            options = this.props.Options;

        const selectProps = {
            ...this.props,
            placeholder: this.props.Placeholder,
            className: currentSelect.mix([this.props.InputClassName, "select"]).toString(),
            multi: this.props.AllowMultiselect,
            labelKey: labelKey,
            labelKeyGetter: this.props.LabelKeyGetter,
            isLabelKeyGetterAvailable: () => this._IsLabelKeyGetterAvailable(),
            valueKey: this.props.ValueKey || "Id",
            matchProp: 'label',
            options: loadOptionsAsync ? undefined : options,
            value: this.props.Value,
            newOptionCreator: this.props.OptionCreator,
            searchPromptText: i18next.t("common:TypeToSearch"),
            noResultsText: i18next.t("common:NoResultsText"),
            loadingPlaceholder: i18next.t("common:LoadingPlaceholder"),
            disabled: this.props.Readonly === true || this.props.Disabled,
            promptTextCreator: ((label: string) => i18next.t(this.props.OptionCreatorPromtText, { label: label } as any)).bind(this),
            onChange: (selected) => this._OnSelectedValuesChange(selected, loadOptionsAsync),
            arrowRenderer: (() => <svg className={currentSelect("arrow")}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use></svg>).bind(this),
            menuRenderer: this._renderMenu,
            menuStyle: { overflow: 'hidden' },
            loadOptions: (input: string) => this._GetOptionsAsync(Array.isArray(this.props.Entity) ? this.props.Entity : [this.props.Entity], this.props.ServerRequestFilter, input, this.props.ServerRequestLimit),
            cache: this.props.Cache != undefined ?  this.props.Cache : this._selectOptionsCache,
        } as any;

        let selectComponent = this.props.AllowItemCreation ? <Creatable {...selectProps} /> : (loadOptionsAsync ? <Async {...selectProps} /> : <SelectComponent {...selectProps} />);
        return (
            <div className={this._defaultSelectOuter()}>
                <div className={this._defaultSelectWrapper()}>
                    {selectComponent}
                </div>
                {errors}
                {this.props.Value !== undefined && this.props.Value !== null && (this.props.Value.length === undefined || this.props.Value.length > 0) &&
                 this.props.Label !== undefined && this.props.Label !== null && this.props.Label.length > 0 &&
                 <label className={this._defaultPlaceholder.mix(["active"])}>{this.props.Label}</label>}
            </div>
        )
    }

    public componentDidMount() {
        let props: IVolmaSelectProps = {
            Value: this.props.Value,
            Options: this.props.Options,
            LabelKey: this.props.LabelKey,
            ValueKey: this.props.ValueKey,
            HelperFieldName: this.props.HelperFieldName,
            HelperFieldToDTOFieldTranslator: this.props.HelperFieldToDTOFieldTranslator || (x => (x as any).Id),
            OnValueChanged: this.props.OnValueChanged || (x => null),
            CustomDataUpdate: this.props.CustomDataUpdate,
            IsInHeader: this.props.IsInHeader,
            AllowItemCreation: this.props.AllowItemCreation,
            OptionCreator: this.props.OptionCreator,
            OptionCreatorPromtText: this.props.OptionCreatorPromtText,
            Readonly: this.props.Readonly,
            Entity: this.props.Entity,
            EntityDtoField: this.props.EntityDtoField,
            EntityOptionIcon: this.props.EntityOptionIcon,
            EntityMultiValue: this.props.EntityMultiValue,
            OptionsFilter: this.props.OptionsFilter,
            EnumGetter: this.props.EnumGetter,
            Required: this.props.Required,
            Disabled: this.props.Disabled
        }

        this.props.dispatch(this._actions.Register(this.props.Name, this.props.DTOFieldName || this.props.Name, this.props.HelperFieldName, this.props.HelperFieldToDTOFieldTranslator, this._reducer, this.props.Validator || this._defaultValidator, props));
        this.props.dispatch(this._actions.Validate(this.props.Name));
    }

    protected async _GetOptionsAsync(entities: EEntityType[], serverRequestFilter: (value: string) => Array<FilterDTO>, value: string, serverRequestLimit: number = 25000): Promise<SelectAsyncResult> {
        const res: SelectAsyncResult = { options: [], complete: false };

        for (const [entityIdx, entity] of entities.entries()) {
            if (this._cache[entity] !== undefined && serverRequestFilter === undefined) {
                res.options.push(...this._cache[entity].options);
                continue;
            }

            const sortBy = this._entityService.GetEntityDefaultNameProperty(entity);
            const filter = new FilterDTO();
            filter.Key = sortBy;
            filter.TextValue = value;
            filter.Type = EFilterType.Text;
            const serverFilter = serverRequestFilter !== undefined ? serverRequestFilter(value) : undefined;

            const tableDataResponse = await this._api.GetTableData(entity, this._entityService.GetEntityDefaultNameProperty(entity), ESortDir.Asc, serverFilter, serverRequestLimit, 1);
            const data: DataTableDTO = JSON.parse(tableDataResponse.data);

            const entityFilter = this._GetEntityItemByIdx(this.props.OptionsFilter, entityIdx);
            if (entityFilter) {
                data.Items = data.Items.filter(x => entityFilter(x));
            }

            const labelKey = this.props.LabelKey === undefined ? this._entityService.GetEntityDefaultNameProperty(entity) : this.props.LabelKey;
            const labelGetter = this._entityService.GetEntityDefaultSelectLabelGetter(entity);
            const entityIcon = this._GetEntityItemByIdx(this.props.EntityOptionIcon, entityIdx);

            if (data.Items !== undefined) {
                data.Items = data.Items.map(x => this._WithHelperFields(x, { labelKey, labelGetter }, { entityIcon, entityIdx }));
            }

            this._selectedItemsCache.forEach(selectedItem => {
                if (data.Items.findIndex(x => x.Id == selectedItem.Id) === -1) {
                    data.Items.push(selectedItem)
                }
            });

            res.options.push(...data.Items as VolmaSelectExtendedTableDTO[]);
            this._cache[entity] = { options: data.Items, complete: true };

            if (entityIdx + 1 === entities.length) {
                res.complete = true;
            }
        }

        return res;
    }

    private _WithHelperFields(
        x: ITableDTO,
        labelInfo: { labelKey: string, labelGetter: (x: ITableDTO) => string },
        entityInfo: { entityIcon: JSX.Element, entityIdx: number }
        ): VolmaSelectExtendedTableDTO {
        const label =
            isDefined(this.props.LabelKeyGetter) ? this.props.LabelKeyGetter(x) :
            isDefined(labelInfo.labelGetter) ? labelInfo.labelGetter(x) :
            isDefined(labelInfo.labelKey) ? x[labelInfo.labelKey] : undefined;

        return {
            ...x,

            [labelInfo.labelKey]: label,

            Icon: entityInfo.entityIcon,
            EntityIdx: entityInfo.entityIdx,
        };
    }

    private _GetLabelKey(loadOptionsAsync: boolean): string | undefined{
        let labelKey: string | undefined;

        if (this.props.LabelKey === undefined) {
            labelKey = loadOptionsAsync && !Array.isArray(this.props.Entity)
                ? this._entityService.GetEntityDefaultNameProperty(this.props.Entity)
                : undefined;
        } else {
            labelKey = this.props.LabelKey;
        }

        return labelKey;
    }

    private _OnSelectedValuesChange(val: any, loadOptionsAsync: boolean): void {
        if (loadOptionsAsync) {
            this._HandleLoadOptionsAsync(val);
        }

        if (this.props.OnValueChanged !== undefined) {
            this.props.OnValueChanged(val);
        }

        this._HandleSelectedValue(val);
        this.props.dispatch(this._actions.Validate(this.props.Name));

    }

    private _HandleLoadOptionsAsync(val): void {
        this._selectedItemsCache = [];

        if (this.props.AllowMultiselect) {
            this._selectedItemsCache = [...val];
        } else {
            this._selectedItemsCache.push(val);
        }
    }

    private _HandleSelectedValue(val): void {
        if (this._IsGroupingValueByEntitiesAvailable() && this.props.AllowMultiselect) {
            this._GroupChangedManyValuesByEntities(val, this.props.Entity as EEntityType[]);
        }
        else if (this._IsGroupingValueByEntitiesAvailable() && !this.props.AllowMultiselect) {
            this._GroupChangedSingleValueByEntities(val, this.props.Entity as EEntityType[]);
        }
        else {
            this.props.dispatch(this._actions.ChangeValue(this.props.Name, val));
        }
    }

    private _IsGroupingValueByEntitiesAvailable(): boolean {
        const entityFieldsDefined = !isNullOrUndefined(this.props.EntityDtoField)
        return this._AreManyEntitiesAvailable() && entityFieldsDefined;
    }

    private _AreManyEntitiesAvailable(): boolean {
        return this.props.Entity && Array.isArray(this.props.Entity) && this.props.Entity.length > 1;
    }

    private _GroupChangedManyValuesByEntities(val: any[], entities: EEntityType[]): void {
        const ungroupedValues = [...val];

        for (const [entityIdx, entity] of entities.entries()) {
            const entityValues = [];

            for (let ungrpdIdx = 0; ungrpdIdx < ungroupedValues.length; ungrpdIdx++) {
                const ungrpdVal = ungroupedValues[ungrpdIdx];

                if (this._cache[entity].options.includes(ungrpdVal)) {
                    entityValues.push(ungrpdVal);
                    ungroupedValues.splice(ungrpdIdx, 1);
                    ungrpdIdx--;
                }
            }

            this._DispatchValueByEntityIdx(entityValues, entityIdx);
        }
    }

    private _GroupChangedSingleValueByEntities(ungroupedValue: any, entities: EEntityType[]): void {
        for (const [entityIdx, entity] of entities.entries()) {
            let entityValue = undefined;

            if (this._cache[entity].options.includes(ungroupedValue)) {
                entityValue = ungroupedValue;
            }

            this._DispatchValueByEntityIdx(entityValue, entityIdx);
        }
    }

    private _DispatchValueByEntityIdx(entityValue: any, entityIdx: number): void {
        const entityDtoField = this._GetEntityItemByIdx(this.props.EntityDtoField, entityIdx);
        const entityData: ISelectMultiEntityData = { DTOFieldName: entityDtoField, EntityIdx: entityIdx };

        this.props.dispatch(this._actions.ChangeValue(this.props.Name, entityValue, entityData));
    }

    private _GetEntityItemByIdx<T>(field: T | T[], entityIdx: number): T | undefined {
        const itemFromArrayDefined = Array.isArray(field) && !isNullOrUndefined(field[entityIdx]);
        const itemSingleDefined = !Array.isArray(field) && !isNullOrUndefined(field);

        const entityItem = itemFromArrayDefined && this._AreManyEntitiesAvailable() ? field[entityIdx] : itemSingleDefined ? field : undefined;

        return entityItem;
    }

    private _IsLabelKeyGetterAvailable(): boolean {
        return isDefined(this.props.LabelKeyGetter) && !this.props.Entity;
    }

    // See https://github.com/JedWatson/react-select/#effeciently-rendering-large-lists-with-windowing
    _renderMenu({ focusedOption, focusOption, labelKey, onSelect, options, selectValue, valueArray }) {
        const { listProps, optionRenderer } = this.props as any
        const focusedOptionIndex = options.indexOf(focusedOption)
        const height = this._calculateListHeight({ options })
        const innerRowRenderer = optionRenderer || this._optionRenderer

        // react-select 1.0.0-rc2 passes duplicate `onSelect` and `selectValue` props to `menuRenderer`
        // The `Creatable` HOC only overrides `onSelect` which breaks an edge-case
        // In order to support creating items via clicking on the placeholder option,
        // We need to ensure that the specified `onSelect` handle is the one we use.
        // See issue #33

        function wrappedRowRenderer({ index, key, style }) {
            const option = options[index]

            return innerRowRenderer({
                focusedOption,
                focusedOptionIndex,
                focusOption,
                key,
                labelKey,
                onSelect,
                option,
                optionIndex: index,
                options,
                selectValue: onSelect,
                style,
                valueArray
            })
        }

        return (
            <VolmaAutoSizer disableHeight>
                {({ width }) => (
                    <List
                        className='VirtualSelectGrid'
                        height={height}
                        ref={(ref) => this._virtualScroll = ref}
                        rowCount={options.length}
                        rowHeight={({ index }) => this._getOptionHeight({
                            option: options[index]
                        })}
                        rowRenderer={wrappedRowRenderer}
                        scrollToIndex={focusedOptionIndex}
                        width={width}
                        {...listProps}
                    />
                )}
            </VolmaAutoSizer>
        )
    }

    _calculateListHeight({ options }) {
        const { maxHeight } = this.props as any

        let height = 0

        for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
            let option = options[optionIndex]

            height += this._getOptionHeight({ option })

            if (height > maxHeight) {
                return maxHeight
            }
        }

        return height
    }

    _getOptionHeight({ option }) {
        const { optionHeight } = this.props as any

        return optionHeight instanceof Function
            ? optionHeight({ option })
            : optionHeight
    }

    _getSelectComponent() {
        const { async, selectComponent } = this.props as any

        if (selectComponent) {
            return selectComponent
        } else if (async) {
            return Async
        } else {
            return Select
        }
    }

    _optionRenderer({ focusedOption, focusOption, key, labelKey, option, selectValue, style }) {
        const className = ['VirtualizedSelectOption']

        if (option === focusedOption) {
            className.push('VirtualizedSelectFocusedOption')
        }

        if (option.disabled) {
            className.push('VirtualizedSelectDisabledOption')
        }

        const events = option.disabled
            ? {}
            : {
                onClick: () => selectValue(option),
                onMouseOver: () => focusOption(option)
            }

        const optionContent = this._IsLabelKeyGetterAvailable() ? this.props.LabelKeyGetter(option) : option[labelKey];

        return (
            <div
                className={className.join(' ')}
                key={key}
                style={style}
                {...events}
            >
                <div className="custom-select__drop-item">
                    <a className="custom-select__drop-link">
                        <span className="custom-select__drop-label">
                            {option.Icon && option.Icon}
                            {optionContent}
                        </span>
                    </a>
                </div>
            </div>
        )
    }
}
