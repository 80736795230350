import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import * as React from 'react';
import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { IVolmaTableColumn, IVolmaTableCellRendererParams, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaCalendar from '../../VolmaCalendar/index';
import VolmaSelect from '../../VolmaSelect/VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { CalendarEntryActions } from './CalendarEntryActions';
import { ICalendarEntryHelperProps, InitialCalendarEntryHelperProps } from './IHelperProps';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { injectable } from 'inversify';
import { CalendarEntryReducer } from './CalendarEntryReducer';
import { ECalendarEntryType } from '../../../Domain/Enum/ECalendarEntryType';
import i18next from '../../i18n';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { DateDTO } from '../../../Domain/DTO/DateDTO';
import * as moment from 'moment';
import { VolmaCalendarActions } from '../../VolmaCalendar/VolmaCalendarActions';

@injectable()
export class CalendarEntryEntityService extends BaseEntityService<CalendarEntryDTO, ICalendarEntryHelperProps>{
    private _tableService: VolmaTableService;
    private _reducer: CalendarEntryReducer;
    private _calendarActions: VolmaCalendarActions;

    constructor(){
        super();

        this._tableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._calendarActions = VolmaContainer.get<VolmaCalendarActions>(Types.VolmaCalendarActions);
        this._reducer = VolmaContainer.get<CalendarEntryReducer>(Types.CalendarEntryReducer);
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._tableService.GetColumnsByKeys(
            EEntityType.CalendarEntry,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Date),
                    CellRenderer: VolmaTableCommonCellRenderers.DateDTOCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Type),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => ECalendarEntryType,
                    CellRenderer: (props: IVolmaTableProps<ICalendarEntryHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ECalendarEntryType, this._enumService, props, cellRendererParams)

                },
            ]
        )
    }

    public GetEditorModal(props: IBaseEntityProps<CalendarEntryDTO, ICalendarEntryHelperProps>): JSX.Element{
        var dateValue = undefined;
        if(PropertyHelper.SafeGetValue2(props.DataDTO, x => x.Date, x => x.Day) != undefined &&
        PropertyHelper.SafeGetValue2(props.DataDTO, x => x.Date, x => x.Month) != undefined &&
        PropertyHelper.SafeGetValue2(props.DataDTO, x => x.Date, x => x.Year) != undefined){
            dateValue = new Date(props.DataDTO.Date.Year, props.DataDTO.Date.Month, props.DataDTO.Date.Day);
        }
        return (
            <div>
                <VolmaCalendar
                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CalendarEntryDTO) => val.Date) as any) }
                    Name={PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Date)}
                    Label={i18next.t('calendarentry:Date')}
                    Required={true}
                    Value={dateValue}
                    OnValueChange={(date) => {
                        let dateDtoValue = new DateDTO();
                        dateDtoValue.Day = date.getDate();
                        dateDtoValue.Month = date.getMonth();
                        dateDtoValue.Year = date.getFullYear();

                        let name = PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Date);

                        props.dispatch(this._calendarActions.ChangeValue(name, dateDtoValue as any));
                        props.dispatch(this._calendarActions.Validate(name));
                    }}
                    dispatch={props.dispatch} />

                <VolmaSelect
                    {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CalendarEntryDTO) => val.Type) as any) }
                    Name={PropertyHelper.GetPropertyName((val: CalendarEntryDTO) => val.Type)}
                    Label={i18next.t("calendarentry:Type")}
                    Placeholder={i18next.t("calendarentry:SelectType")}
                    EnumGetter={() => ECalendarEntryType}
                    AllowMultiselect={false}
                    Value={props.DataDTO.Type}
                    Required={true}
                    dispatch={props.dispatch} />
            </div>
        )
    }

    public GetEditor(props: IBaseEntityProps<CalendarEntryDTO, ICalendarEntryHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<CalendarEntryDTO, ICalendarEntryHelperProps>) {
    }

    public OnAfterDataLoaded(dto: CalendarEntryDTO){

    }

    public GetReducer(){
        return this._reducer;
    }

    public GetInitialDataHelper(){
        return InitialCalendarEntryHelperProps;
    }
}