import { CARGO_TRANSPORTER_USER_ROLE_UPDATED } from '../../../Constants/AppConstants';
import { DataDTO } from '../../../Domain/DTO/DataDTO';
import { CargoTransporterUserDTO } from '../../../Domain/DTO/CargoTransporterUserDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { CargoTransporterUserHelperProps } from './IHelperProps';
import { injectable } from 'inversify';


@injectable()
export class CargoTransporterUserReducer extends EntityReducer<CargoTransporterUserDTO, CargoTransporterUserHelperProps> {

    public Reduce(state: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps> {
        switch (action.type) {
            case CARGO_TRANSPORTER_USER_ROLE_UPDATED:
                return this.ReduceRoleUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceRoleUpdated(state: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>, payload: number): IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>{
        let newState = {...state, DataDTO: {...state.DataDTO}};
        newState.DataDTO.Role = payload;
        return newState;
    }

}
