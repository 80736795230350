import * as React from 'react';
import { Component } from 'react';
import * as ReactDOM from 'react-dom';

import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import i18next from '../i18n';
import { FooterActions } from './FooterActions';
import { IFooterProps } from './IFooterProps';

class Footer extends Component<IFooterProps, {}>{
    private _footer    = volmaBlock("footer");
    private _container = volmaBlock('container');
    private _footerNav = volmaBlock('footer-nav');

    private _actions: FooterActions;

    public props: IFooterProps;

    constructor(props: IFooterProps, context: any) {
        super(props, context);

        this._actions = VolmaContainer.get<FooterActions>(Types.FooterActions);
    }

    render() {
        return (
            <div className={(this._footer('box')).toString()}>
                <div className={(this._container()).toString()}>
                    <div className={(this._footer('flex')).toString()}>
                        <span className={(this._footer('text')).toString()}>{i18next.t("common:FooterNavigationBlockTitle")}</span>
                        <nav className={(this._footerNav()).toString()}>
                            <ul className={(this._footerNav('list')).toString()}>
                                {this.GetLinks()}
                            </ul>
                        </nav>
                        <a className={(this._footer('close')).toString()} onClick={(e) => { e.preventDefault(); this.CloseFooter() }}>
                            <span className={(this._footer('close-text')).toString()}>{i18next.t("common:CloseFooterNavigationBlock")}</span>
                            <svg className={(this._footer('close-ico').mix("close-ico")).toString()}>
                                <use xlinkHref="#close"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    private ScrollToView(link: HTMLElement) {
        let el = ReactDOM.findDOMNode(link) as HTMLElement;

        if (window.outerWidth >= 1000) {
            let scrollHeight = this.props.ParentDiv.scrollTop;
            let finalPosition = el.offsetTop;
            let scrollInterval = 20;
            let timeToPerformScroll = 600;//ms
            let delta = Math.max(5, Math.abs(finalPosition - scrollHeight) * scrollInterval / timeToPerformScroll);

            let id = setInterval(() => {
                let initTopScroll = this.props.ParentDiv.scrollTop;
                if (this.props.ParentDiv.scrollTop > finalPosition) {
                    this.props.ParentDiv.scrollTop = Math.abs(this.props.ParentDiv.scrollTop - delta);
                }
                else {
                    this.props.ParentDiv.scrollTop = Math.abs(this.props.ParentDiv.scrollTop + delta);
                }

                if (Math.abs(this.props.ParentDiv.scrollTop - finalPosition) <= delta || initTopScroll === this.props.ParentDiv.scrollTop) {
                    this.props.ParentDiv.scrollTop = finalPosition;
                    clearInterval(id);
                }
            }, 20);
        }
        else {
            let scrollHeight = window.pageYOffset;
            let finalPosition = el.offsetTop + (this.props.ParentDiv as HTMLElement).offsetTop;
            let scrollInterval = 20;
            let timeToPerformScroll = 600;//ms
            let delta = Math.max(5, Math.abs(finalPosition - scrollHeight) * scrollInterval / timeToPerformScroll);

            let lastScrollPosition = undefined;
            let id = setInterval(() => {
                if (window.pageYOffset > finalPosition) {
                    window.scrollTo(0, Math.abs(window.pageYOffset - delta));
                }
                else {
                    window.scrollTo(0, Math.abs(window.pageYOffset + delta));
                }

                if (Math.abs(window.pageYOffset - finalPosition) <= delta || lastScrollPosition === window.pageYOffset) {
                    window.scrollTo(0, finalPosition);
                    clearInterval(id);
                }
                lastScrollPosition = window.pageYOffset;
            }, 20);

        }
    }

    private GetLinks(): Array<JSX.Element> {
        let blocks = new Array<JSX.Element>();
        if (this.props.Blocks !== undefined) {
            for (let block of this.props.Blocks) {
                if (block !== undefined)
                    blocks.push(
                        <li className={(this._footerNav('list-item')).toString()} key={block.Index}>
                            <a className={(this._footerNav('list-link')).toString()} onClick={(e) => { e.preventDefault(); this.ScrollToView(block.Ref) }}>{block.Title}</a>
                        </li>
                    );
            }
        }
        else
            return [];

        return blocks;
    }

    private CloseFooter() {
        this.props.dispatch(this._actions.FooterClosed());
    }
}

export default Footer;