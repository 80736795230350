import * as React from "react";
import {MouseEvent} from "react";

export interface VolmaButtonProps {
    label: string
    onClick: (evt: MouseEvent<HTMLButtonElement>) => void
}

export interface VolmaButtonState { }

export class VolmaButton extends React.Component<VolmaButtonProps, VolmaButtonState> {
    render() {
        return <button onClick={this.props.onClick}>{this.props.label}</button>;
    }
}