import { injectable } from 'inversify';
import * as moment from 'moment';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { IBaseTableProps } from '../../../Table/BaseTable/IBaseTableProps';
import { IVolmaTableAction, IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableTenderCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableTenderCellRenderers';
import { TenderBetAction } from '../Actions/TenderBetAction';
import { TenderImportAction } from '../Actions/TenderImportAction';
import { TenderImportExcelAction } from '../Actions/TenderImportExcelAction';
import { TenderPublishAction } from '../Actions/TenderPublishAction';
import { TenderWithdrawAction } from '../Actions/TenderWithdrawAction';
import { ITenderTableHelperProps } from '../Table/ITableHelperProps';
import { TenderTableExtendedDTO } from '../Table/TenderTableExtendedDTO';
import { TenderEntityService } from '../TenderEntityService';
import { TenderHelperProps } from '../IHelperProps';
import i18next from '../../../i18n';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';
import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';

@injectable()
export class TenderBettingPerformigEntityService extends TenderEntityService {

    public GetBaseEntity(): EEntityType {
        return EEntityType.Tender;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if (this._authService.IsCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Tender,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: TenderTableExtendedDTO) => val.Id) },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Identifier),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                    VolmaTableCommonColumnDefinition.GetMultiPointColumn({ 
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointName),
                    }),
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneName),
                        HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                        Entity: EEntityType.Zone,
                        FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneId)
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.EndBiddingDate),
                        CellRenderer: VolmaTableCommonCellRenderers.CountDownCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.MaxBet),
                        CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.BestBet),
                        CellRenderer: VolmaTableTenderCellRenderers.TenderBetCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                        Weight: 1.5
                    },
                ]
            );
        }

        if (this._authService.IsAdministrator()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Tender,
                [
                    ...this.getShipperColumns(),
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.BestBet),
                        CellRenderer: VolmaTableTenderCellRenderers.TenderBetCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                        Weight: 1.5
                    },
                ]
            );
        }
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Tender,
            this.getShipperColumns(),
        );
    }

    public InitializeTable(props: IBaseTableProps<ITenderTableHelperProps>): void {
        this._signalRService.TenderBetSetted.On(this.TenderBetAdded);
    }

    public DisposeTable(): void {
        this._signalRService.TenderBetSetted.Off(this.TenderBetAdded);
    }

    public OnBeforeTableDataLoaded(props: IBaseTableProps<ITenderTableHelperProps>): void {
        this._dispatch = props.dispatch;
        props.dispatch(this._tenderTableAction.LoadUserBets());
        props.dispatch(this._tenderRatingActions.LoadForecastRating([],i18next.t("common:Loading")));
    }

    public GetTableReducer() {
        return this._tenderTableReducer;
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<TenderHelperProps>> {
        return [new TenderImportAction(), new TenderImportExcelAction(), new TenderWithdrawAction(), new TenderPublishAction(), new TenderBetAction()];
    }

    public GetTableRowClass(rowData: TenderTableDTO): string {
        // @ts-ignore: This expression is not callable
        return this._table("row", { body: true, finished: !this._timeService.GetLocalTime(rowData.EndBiddingDate).isAfter(moment()) }).toString();
    }

    private getShipperColumns() {
        return [
            { DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Id) },
            {
                DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Identifier),
                HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
            },
            VolmaTableCommonColumnDefinition.GetMultiPointColumn({ 
                DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointName),
            }),
            {
                DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneName),
                HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                Entity: EEntityType.Zone,
                FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneId)
            },
            {
                DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.EndBiddingDate),
                CellRenderer: VolmaTableCommonCellRenderers.CountDownCellRenderer,
                HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
            },
            {
                DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.MaxBet),
                CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
            }
        ]
    }

}