import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { IVolmaTableHeaderRenderProps, IVolmaTableSortProps, IVolmaTableProps } from '../IVolmaTableProps';
import { injectable } from 'inversify';
import { MouseEvent } from 'react';
import { VolmaTableActions } from '../VolmaTableActions';
import { FilterDTO } from '../../../../Domain/DTO/FilterDTO';
import { volmaBlock } from '../../../../Infrastructure/Services/BEM';
import { EFilterType } from '../../../../Domain/Enum/EFilterType';
import VolmaInput from '../../../VolmaInput';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import VolmaCalendar from '../../../VolmaCalendar/index';
import VolmaSelect from '../../../VolmaSelect/index';
import { ListIntOptionDTO } from '../../../../Domain/DTO/ListIntOptionDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { debounce } from '../../../../Infrastructure/Services/DebounceService';
import i18next from '../../../i18n';
import * as moment from 'moment';
import { isNullOrUndefined } from '../../../../Infrastructure/Services/Utils';
import { VolmaSelectExtendedTableDTO } from '../../../VolmaSelect/IVolmaSelectProps';

@injectable()
export class VolmaTableHeaderRenderers {
    private static _table = volmaBlock("table");
    private static _tableFilter = volmaBlock("table-filter");
    private static _tableFilterDate = volmaBlock("table-filter-date");
    private static _tableFilterText = volmaBlock("table-filter-text");

    private static _filterDebounceTime = 500; // ms
    private static _filterDebounceTimeout: any;

    private static _volmaTableActions: VolmaTableActions;

    constructor() {
        VolmaTableHeaderRenderers._volmaTableActions = VolmaContainer.get<VolmaTableActions>(Types.VolmaTableActions);
    }

    public static DefaultHeaderRenderer(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps) {

        let style = VolmaTableHeaderRenderers.GetStyle(headerRenderProps);

        let key = VolmaTableHeaderRenderers.GetReactKey(headerRenderProps);
        return (
            <div className={(VolmaTableHeaderRenderers._table("cell", {head: true })).toString()} style={style} key={key}>
                <div className={(VolmaTableHeaderRenderers._table("head-left")).toString()} onClick={(event: MouseEvent<any>) => 
                        VolmaTableHeaderRenderers.OnColumnSortClick(props, loadDataFunc, event, headerRenderProps)}>
                    <h3 className={(VolmaTableHeaderRenderers._table("head-title")).toString()}>{headerRenderProps.Label}</h3>
                </div>
                <div className={(VolmaTableHeaderRenderers._table("head-right")).toString()}>
                    {VolmaTableHeaderRenderers.GetFilterState(props, loadDataFunc, headerRenderProps)}
                </div>

            </div>
        );
    }

    public static DateHeaderRenderer(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps) {

        let style = VolmaTableHeaderRenderers.GetStyle(headerRenderProps);
        let minDate = undefined;
        let minDateOverlayShown = false;
        if (props.OpenedFilter !== undefined){
            minDate = props.OpenedFilter.DateMinValue;
            minDateOverlayShown = props.OpenedFilter.DateMinOverlayShown;
        }

        let maxDate = undefined;
        let maxDateOverlayShown = false;
        if (props.OpenedFilter !== undefined){
            maxDate = props.OpenedFilter.DateMaxValue;
            maxDateOverlayShown = props.OpenedFilter.DateMaxOverlayShown;
        }

        let key = VolmaTableHeaderRenderers.GetReactKey(headerRenderProps);
        return (
            <div className={(VolmaTableHeaderRenderers._table("cell", {head: true }).mix([headerRenderProps.FilterActive ? "active" : ""])).toString()} style={style} key={key}>
                <div className={(VolmaTableHeaderRenderers._table("head-left")).toString()} onClick={(event: MouseEvent<any>) => 
                        VolmaTableHeaderRenderers.OnColumnSortClick(props, loadDataFunc, event, headerRenderProps)}>
                    <h3 className={(VolmaTableHeaderRenderers._table("head-title")).toString()}>{headerRenderProps.Label}</h3>
                </div>
                <div className={(VolmaTableHeaderRenderers._table("head-right")).toString()}>
                    <a className={(VolmaTableHeaderRenderers._table("head-link", {active: VolmaTableHeaderRenderers.IsFilterActive(props.Filter, headerRenderProps.FilterKey)})).toString()} 
                        onClick={(event: MouseEvent<any>) => VolmaTableHeaderRenderers.OnFilterOpenClick(props, event, headerRenderProps, EFilterType.DateMin)}>
                        <svg className={(VolmaTableHeaderRenderers._table("head-link-ico").mix("table-date-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                        </svg>
                    </a>
                    {VolmaTableHeaderRenderers.GetFilterState(props, loadDataFunc, headerRenderProps)}
                </div>
                <div className={(VolmaTableHeaderRenderers._tableFilter.mix([headerRenderProps.FilterActive ? "active" : ""])).toString()}>
                    <div className={(VolmaTableHeaderRenderers._tableFilter("left")).toString()}>
                        <svg className={(VolmaTableHeaderRenderers._tableFilter("left-ico").mix("table-drop-filter-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#filter"></use>
                        </svg>
                    </div>
                    {headerRenderProps.FilterActive && <div className={(VolmaTableHeaderRenderers._tableFilter("center")).toString()}>
                        <div className={(VolmaTableHeaderRenderers._tableFilterDate()).toString()}>
                            <div className={(VolmaTableHeaderRenderers._tableFilterDate("cell")).toString()}>
                                <div className={(VolmaTableHeaderRenderers._tableFilterDate("date")).toString()}>
                                    <VolmaCalendar
                                        Name={headerRenderProps.DataKey + "MinDateFilter"}
                                        Label={i18next.t(headerRenderProps.Label)}
                                        Placeholder={i18next.t(headerRenderProps.Description)}
                                        IsActive={true}
                                        ShowOverlay={minDateOverlayShown}
                                        Value={minDate}
                                        DefaultHours={0}
                                        DefaultMinutes={0}
                                        DefaultSeconds={0}
                                        IsInHeader={true}
                                        OnOverlayToggled={(value: boolean) => VolmaTableHeaderRenderers.OnFilterCalendarToggled(props, EFilterType.DateMin, value)}
                                        OnValueChange={(date: Date) => VolmaTableHeaderRenderers.DateFilterValueChanged(props, loadDataFunc, EFilterType.DateMin, date, false)}
                                        IsClearable
                                        dispatch={props.dispatch}
                                    />
                                </div>
                            </div>
                            <div className={(VolmaTableHeaderRenderers._tableFilterDate("cell")).toString()}>
                                <div className={(VolmaTableHeaderRenderers._tableFilterDate("date")).toString()}>
                                    <VolmaCalendar
                                        Name={headerRenderProps.DataKey + "MaxDateFilter"}
                                        Label={i18next.t(headerRenderProps.Label)}
                                        Placeholder={i18next.t(headerRenderProps.Description)}
                                        IsActive={true}
                                        ShowOverlay={maxDateOverlayShown}
                                        Value={maxDate}
                                        DefaultHours={23}
                                        DefaultMinutes={59}
                                        DefaultSeconds={59}
                                        IsInHeader={true}
                                        OnOverlayToggled={(value: boolean) => VolmaTableHeaderRenderers.OnFilterCalendarToggled(props, EFilterType.DateMax, value)}
                                        OnValueChange={(date: Date) => VolmaTableHeaderRenderers.DateFilterValueChanged(props, loadDataFunc, EFilterType.DateMax, date, true)}
                                        IsClearable
                                        dispatch={props.dispatch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className={(VolmaTableHeaderRenderers._tableFilter("right")).toString()}>
                        <span className={(VolmaTableHeaderRenderers._tableFilter("close")).toString()} onClick={(event: MouseEvent<any>) => 
                                VolmaTableHeaderRenderers.OnFilterCloseClick(props, event, headerRenderProps)}>
                            <svg className={(VolmaTableHeaderRenderers._tableFilter("close-ico").mix("table-close-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                            </svg>
                        </span>
                    </div>
                </div >
            </div>
        );
    }

    public static TextHeaderRenderer(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps) {

        let style = VolmaTableHeaderRenderers.GetStyle(headerRenderProps);
        let filterType = EFilterType.Text;
        let filter = "";
        if(props.OpenedFilter !== undefined)
            filter = props.OpenedFilter.TextValue;
        let inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);

        let key = VolmaTableHeaderRenderers.GetReactKey(headerRenderProps);

        const filterElement = <a className={(VolmaTableHeaderRenderers._table("head-link", 
            { active: VolmaTableHeaderRenderers.IsFilterActive(props.Filter, headerRenderProps.FilterKey)})).toString()}
            onClick={(event: MouseEvent<any>) => VolmaTableHeaderRenderers.OnFilterOpenClick(props, event, headerRenderProps, EFilterType.Text)}>
            <svg className={(VolmaTableHeaderRenderers._table("head-link-ico").mix("table-search-ico")).toString()}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#search"></use>
            </svg>
        </a>

        const IsFilterable = headerRenderProps.IsFilterable === true || headerRenderProps.IsFilterable === undefined
        const IsSortable = headerRenderProps.IsSortable === true || headerRenderProps.IsSortable === undefined

        return (
            <div className={(VolmaTableHeaderRenderers._table("cell", {head: true }).mix([headerRenderProps.FilterActive ? "active" : ""])).toString()} style={style} key={key}>
                {VolmaTableHeaderRenderers.TextHeaderSortable(props, loadDataFunc, headerRenderProps)}
                <div className={(VolmaTableHeaderRenderers._table("head-right")).toString()}>
                    {IsFilterable && filterElement }
                    {IsSortable && VolmaTableHeaderRenderers.GetFilterState(props, loadDataFunc, headerRenderProps)} 
                </div>
                

                <div className={(VolmaTableHeaderRenderers._tableFilter.mix([headerRenderProps.FilterActive ? "active" : ""])).toString()}>
                    <div className={(VolmaTableHeaderRenderers._tableFilter("left")).toString()}>
                        <svg className={(VolmaTableHeaderRenderers._tableFilter("left-ico").mix("table-drop-filter-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#filter"></use>
                        </svg>
                    </div>
                    {headerRenderProps.FilterActive && <div className={(VolmaTableHeaderRenderers._tableFilter("center")).toString()}>
                        <div className={(VolmaTableHeaderRenderers._tableFilterText()).toString()}>
                            <VolmaInput
                                Name={headerRenderProps.DataKey + "TextFilter"}
                                Label={i18next.t(headerRenderProps.Label)}
                                Placeholder={i18next.t(headerRenderProps.Description)}
                                IsActive={true}
                                Value={filter}
                                IsInHeader={true}
                                MaxLength={255}
                                Autofocus={headerRenderProps.FilterActive}
                                Validator={inputValidator}
                                OnValueChange={(event: any) => VolmaTableHeaderRenderers.OnFilterValueChange(props, loadDataFunc, EFilterType.Text, event.target.value)}
                                dispatch={props.dispatch}
                            />
                        </div>
                    </div>}
                    <div className={(VolmaTableHeaderRenderers._tableFilter("right")).toString()}>
                        <span className={(VolmaTableHeaderRenderers._tableFilter("close")).toString()} onClick={(event: MouseEvent<any>) => 
                            VolmaTableHeaderRenderers.OnFilterCloseClick(props, event, headerRenderProps)}>
                            <svg className={(VolmaTableHeaderRenderers._tableFilter("close-ico").mix("table-close-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                            </svg>
                        </span>
                    </div>
                </div >
            </div>
        );
    }

    private static TextHeaderSortable(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps): JSX.Element {
        if(headerRenderProps.IsSortable) {
            return (
                <div className={(VolmaTableHeaderRenderers._table("head-left")).toString()} onClick={(event: MouseEvent<any>) => 
                    VolmaTableHeaderRenderers.OnColumnSortClick(props, loadDataFunc, event, headerRenderProps)}>
                    <h3 className={(VolmaTableHeaderRenderers._table("head-title")).toString()}>{headerRenderProps.Label}</h3>
                </div>
                );
        }
        else {
            return (
                <div className={(VolmaTableHeaderRenderers._table("head-left")).toString()} >
                    <h3 className={(VolmaTableHeaderRenderers._table("head-title")).toString()}>{headerRenderProps.Label}</h3>
                </div>
                );
        }
    }    

    public static SelectHeaderRenderer(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps) {    
        const style = VolmaTableHeaderRenderers.GetStyle(headerRenderProps);
        const key = VolmaTableHeaderRenderers.GetReactKey(headerRenderProps);

        const selectedValues = VolmaTableHeaderRenderers.GetSelectedValueByFilterKey(props, headerRenderProps.FilterKey);
        VolmaTableHeaderRenderers.SetActualIconsForSelectedValues(selectedValues, headerRenderProps.EntityOptionIcon);            

        return (
            <div className={(VolmaTableHeaderRenderers._table("cell", {head: true }).mix([headerRenderProps.FilterActive ? "active" : ""])).toString()} style={style} key={key}>
                <div className={(VolmaTableHeaderRenderers._table("head-left")).toString()} onClick={(event: MouseEvent<any>) => 
                    VolmaTableHeaderRenderers.OnColumnSortClick(props, loadDataFunc, event, headerRenderProps)}>
                    <h3 className={(VolmaTableHeaderRenderers._table("head-title")).toString()}>{headerRenderProps.Label}</h3>
                </div>
                <div className={(VolmaTableHeaderRenderers._table("head-right")).toString()}>
                    <a className={(VolmaTableHeaderRenderers._table("head-link", { active: VolmaTableHeaderRenderers.IsFilterActive(props.Filter, headerRenderProps.FilterKey)})).toString()} 
                        onClick={(event: MouseEvent<any>) => VolmaTableHeaderRenderers.OnFilterOpenClick(props, event, headerRenderProps, EFilterType.Select)}>
                        <svg className={(VolmaTableHeaderRenderers._table("head-link-ico").mix("table-search-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#search"></use>
                        </svg>
                    </a>
                    {VolmaTableHeaderRenderers.GetFilterState(props, loadDataFunc, headerRenderProps)}
                </div>
                <div className={(VolmaTableHeaderRenderers._tableFilter.mix([headerRenderProps.FilterActive ? "active" : ""])).toString()}>
                    <div className={(VolmaTableHeaderRenderers._tableFilter("left")).toString()}>
                        <svg className={(VolmaTableHeaderRenderers._tableFilter("left-ico").mix("table-drop-filter-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#filter"></use>
                        </svg>
                    </div>
                    {headerRenderProps.FilterActive && <div className={(VolmaTableHeaderRenderers._tableFilter("center")).toString()}>
                        <div className={(VolmaTableHeaderRenderers._tableFilterText()).toString()}>
                            <VolmaSelect
                                Name={headerRenderProps.DataKey + "SelectFilter"}
                                Entity={headerRenderProps.FilterEntity}
                                EntityOptionIcon={headerRenderProps.EntityOptionIcon}
                                EnumGetter={headerRenderProps.FilterEnum}
                                Value={selectedValues}
                                Options={headerRenderProps.Options}
                                OptionsFilter={headerRenderProps.OptionsFilter}
                                LabelKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Name)}
                                ValueKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Id)}
                                AllowMultiselect={true}
                                IsInHeader={true}
                                OnValueChanged={(value: any) => VolmaTableHeaderRenderers.OnFilterValueChange(props, loadDataFunc, EFilterType.Select, value, headerRenderProps.FilterKey)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>}
                    <div className={(VolmaTableHeaderRenderers._tableFilter("right")).toString()}>
                        <span className={(VolmaTableHeaderRenderers._tableFilter("close")).toString()} onClick={(event: MouseEvent<any>) => 
                            VolmaTableHeaderRenderers.OnFilterCloseClick(props, event, headerRenderProps)}>
                            <svg className={(VolmaTableHeaderRenderers._tableFilter("close-ico").mix("table-close-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                            </svg>
                        </span>
                    </div>
                </div >
            </div>
        );
    }

    protected static OnColumnSortClick(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        event: MouseEvent<any>,
        headerRenderProps: IVolmaTableHeaderRenderProps) {

        if(headerRenderProps.IsSortable === false)
            return;

        let sortDirection = ESortDir.Asc;
        if (headerRenderProps.SortKey === props.SortOptions.sortBy && props.SortOptions.sortDirection == ESortDir.Asc)
            sortDirection = ESortDir.Desc;

        var sortData: IVolmaTableSortProps = { sortBy: headerRenderProps.SortKey, sortDirection: sortDirection } as IVolmaTableSortProps;
        props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.SortData(props.Name, sortData))
        loadDataFunc(sortData.sortBy, sortData.sortDirection);
    }

    protected static OnFilterOpenClick(props: IVolmaTableProps<any>, event: MouseEvent<any>, headerRendererProps: IVolmaTableHeaderRenderProps, filterType: EFilterType) {
        props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.OpenFilter(props.Name, VolmaTableHeaderRenderers.GetFilterKey(headerRendererProps.FilterKey), filterType));
    }

    protected static OnFilterCloseClick(props: IVolmaTableProps<any>, event: MouseEvent<any>, headerRendererProps: IVolmaTableHeaderRenderProps) {
        props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.CloseFilter(props.Name));
    }

    protected static DateFilterValueChanged(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        filterType: EFilterType,
        value: any,
        isMaxDate: boolean) {
        if(value !== undefined && isMaxDate === true){
            // @ts-ignore: This expression is not callable
            let filterValue = moment(value).add(1, 'days').add(-1, 'seconds');
            VolmaTableHeaderRenderers.OnFilterValueChange(props, loadDataFunc, filterType, filterValue.format());
        }
        else {
            VolmaTableHeaderRenderers.OnFilterValueChange(props, loadDataFunc, filterType, value);
        }
    }

    protected static OnFilterValueChange(
        props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        filterType: EFilterType, 
        value: any, 
        filterKey?: string | string[]) {

        if (Array.isArray(filterKey) && Array.isArray(value)) {
            VolmaTableHeaderRenderers.OnFilterMultiplesValuesChange(props, filterType, value, filterKey);           
        } else {
            props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.ChangeFilterValue(props.Name, filterType, value));
        }        
        
        if (VolmaTableHeaderRenderers._filterDebounceTimeout !== undefined)
            clearTimeout(VolmaTableHeaderRenderers._filterDebounceTimeout);

        VolmaTableHeaderRenderers._filterDebounceTimeout = debounce(loadDataFunc, VolmaTableHeaderRenderers._filterDebounceTime, false)();
    }  

    protected static OnFilterCalendarToggled(props: IVolmaTableProps<any>, filterType: EFilterType, value: boolean) {
        props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.CalendarOverlayToggled(props.Name, filterType, value));
    }

    protected static GetStyle(headerRenderProps: IVolmaTableHeaderRenderProps) {
            type Style = {height?: string, width: string, minHeight?: string, maxHeight?: string}
            let style: Style = {
                width: headerRenderProps.ScrollbarWidth !== 0 ? ("calc(" + headerRenderProps.Width + "% - " + headerRenderProps.ScrollbarWidth + "px)") : (headerRenderProps.Width + "%")
            }
            if(headerRenderProps.Height !== undefined)
                style = { ...style,
                    height: headerRenderProps.Height + "px",
                    minHeight: headerRenderProps.Height + "px",
                    maxHeight: headerRenderProps.Height + "px",
                }

            return style;
        }

    protected static GetReactKey(headerRenderProps: IVolmaTableHeaderRenderProps) {
        return headerRenderProps.DataKey + "-" + headerRenderProps.Index.toString();
    }

    private static GetFilterState(props: IVolmaTableProps<any>,
        loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void,
        headerRenderProps: IVolmaTableHeaderRenderProps) {
        if (headerRenderProps.IsSortable === false)
            return undefined;

        return (
            <a className={VolmaTableHeaderRenderers._table("head-filter", {
                active: PropertyHelper.SafeGetValue(props.SortOptions, x => x.sortBy) === headerRenderProps.SortKey
            })} onClick={(event: MouseEvent<any>) => VolmaTableHeaderRenderers.OnColumnSortClick(props, loadDataFunc, event, headerRenderProps)}>
                <svg className={VolmaTableHeaderRenderers._table("head-filter-ico", {
                    asc: PropertyHelper.SafeGetValue(props.SortOptions, x => x.sortBy) === headerRenderProps.SortKey &&
                    PropertyHelper.SafeGetValue(props.SortOptions, x => x.sortDirection) === ESortDir.Asc,
                    desc: PropertyHelper.SafeGetValue(props.SortOptions, x => x.sortBy) === headerRenderProps.SortKey &&
                    PropertyHelper.SafeGetValue(props.SortOptions, x => x.sortDirection) === ESortDir.Desc
                }).mix("table-filter-ico")}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#filter"></use>
                </svg>
            </a>
        );
    }

    private static GetSelectedValueByFilterKey(props: IVolmaTableProps<any>, filterKey: string | string[]): VolmaSelectExtendedTableDTO[] | undefined {
        let selectedValues = [];

        if (isNullOrUndefined(props.OpenedFilter)) {
            return undefined;
        }

        if (props.Filter && Array.isArray(filterKey)) {
            for (const key of filterKey) {
                const filter = props.Filter.find(f => f.Key === key);

                if (filter && filter.SelectSelectedValues) {
                    selectedValues.push(...filter.SelectSelectedValues);
                }                
            }
        } else {
            selectedValues = props.OpenedFilter.SelectSelectedValues;
        }        

        return selectedValues;
    }

    private static SetActualIconsForSelectedValues(selectedValues: VolmaSelectExtendedTableDTO[], icons: JSX.Element | JSX.Element[]): void {
        if (!isNullOrUndefined(selectedValues) && Array.isArray(icons)) {
            for (const val of selectedValues) {
                if (icons) {
                    const validJsxIcon = icons[val.EntityIdx];                    
                    val.Icon = validJsxIcon;                    
                }
            }
        }
    }

    private static GetFilterKey(filterKey: string | string[]): string {
        return Array.isArray(filterKey) ? filterKey[0] : filterKey;
    }

    private static OnFilterMultiplesValuesChange(
        props: IVolmaTableProps<any>,
        filterType: EFilterType, 
        value: any, 
        filterKey?: string[]
        ) {
        const groupedEntityValue = VolmaTableHeaderRenderers.GroupValueBetweenEntitiesByFilterKey(filterKey, value);

        for (const key of Object.keys(groupedEntityValue)) {
            const entityValue = groupedEntityValue[key];
            props.dispatch(VolmaTableHeaderRenderers._volmaTableActions.ChangeFilterValue(props.Name, filterType, entityValue, key));
        }  
    }

    private static GroupValueBetweenEntitiesByFilterKey(filterKey: string[], value: VolmaSelectExtendedTableDTO[]): Record<string, any[]> {
        const groupedEntityValue: Record<string, any[]> = {};

        for (const key of filterKey) {
            groupedEntityValue[key] = [];
        }

        for (const item of value) {
            const entityIdx = item.EntityIdx;
            if (entityIdx !== undefined) {
                const filterEntityKey = filterKey[entityIdx];
                groupedEntityValue[filterEntityKey].push(item);
            }
        }

        return groupedEntityValue;
    }

    

    private static IsFilterActive(filter: Array<FilterDTO>, key: string | string[]) : boolean {
        if (!filter)
            return false;
        
        if (Array.isArray(key)) {         
            return VolmaTableHeaderRenderers.IsMultiFilterActive(filter, key);         
        } else {
            return VolmaTableHeaderRenderers.IsSingleFilterActive(filter, key);
        }     
    }

    private static IsMultiFilterActive(filter: Array<FilterDTO>, key: string[]) {
        return filter.some(f => key.some(k => k === f.Key && f.SelectSelectedValues && f.SelectSelectedValues.length > 0));
    }

    private static IsSingleFilterActive(filter: Array<FilterDTO>, key: string) {
        const item = filter.find(x => x.Key === key);
        
        if (isNullOrUndefined(item)) {
            return false;
        }

        switch (item.Type){
            case EFilterType.Text:
                return item.TextValue !== undefined && item.TextValue !== "";
            case EFilterType.Select:
                return item.SelectSelectedValues !== undefined && item.SelectSelectedValues.length > 0;
            case EFilterType.DateMin:
            case EFilterType.DateMax:
                return item.DateMinValue !== undefined || item.DateMaxValue !== undefined;
            case EFilterType.TimeMin:
            case EFilterType.TimeMax:
                return item.TimeMinValue !== undefined || item.TimeMaxValue !== undefined;
        }
    }    



    
}
