import { VolmaContainer } from '../InversifyInject';
import { UrlFabric } from './UrlFabric';
import { Types } from '../Types';
import { AxiosPromise } from 'axios';
import { CommonServerInteraction } from './CommonServerInteraction';
import { injectable } from 'inversify';
import { ECQRSApi } from './ECQRSApi';

@injectable()
export class SearchServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public Search(text: string): AxiosPromise {
        return this.Get(this._urlFabric.Search(ECQRSApi.Read, text));
    }

    public RebuildIndex(): AxiosPromise {
        return this.Get(this._urlFabric.RebuildIndex(ECQRSApi.Write));
    }
}