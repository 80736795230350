import { TenderCancelDTO } from '../../../../Domain/DTO/TenderCancelDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { TenderTableActions } from '../Table/TenderTableActions';
import i18next from '../../../i18n';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';

export class TenderPublishAction<THelper> extends  VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _tenderTableActions: TenderTableActions;

    public Title: string = "tender:TenderPublish";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<TenderTableDTO>) {
        if (this._authService.IsShipper()) {
            if (data === undefined || selectedIds === undefined || selectedIds.length !== 1)
                return false;
            let tender = data.find(x => x.Id === selectedIds[0]);
            if(tender === undefined)
                return false;
            return tender.State === ETenderState.Draft;
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<TenderTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        dispatch(this._modalActions.OpenConfirmationModal("tenderpublish:Publish", () => {
            dispatch(this._tenderTableActions.TenderPublish(id, i18next.t("common:Loading"), reloadData));
        }))
        
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tenderTableActions = VolmaContainer.get<TenderTableActions>(Types.TenderTableActions);
    }
}