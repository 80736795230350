import {
    INPUT_CALENDAR_TOGGLE_OVERLAY,
    INPUT_REMOVE_ACTIVE,
    INPUT_SET_ACTIVE,
    INPUT_VALUE_CHANGE
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaCalendarProps } from '../VolmaCalendar/IVolmaCalendarProps';
import { VolmaCalendarPropsInitial } from './IVolmaCalendarProps';
import { ICalendarToggleOverlayPayload, ICalendarValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
export class VolmaCalendarReducer extends BaseInputReducer<IVolmaCalendarProps> {

    public Reduce(state: IVolmaCalendarProps = VolmaCalendarPropsInitial, action: IActionPayloaded<any>): IVolmaCalendarProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            case INPUT_CALENDAR_TOGGLE_OVERLAY:
                return this.ReduceShowOverlay(state, action.Payload);
            case INPUT_SET_ACTIVE:
                return this.ReduceSetActive(state);
            case INPUT_REMOVE_ACTIVE:
                return this.ReduceRemoveActive(state);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaCalendarProps, payload: ICalendarValueChangedPayload) {
        let newState: IVolmaCalendarProps = { ...state, Value: payload.Value, ShowOverlay: payload.ShowOverlay };

        return newState;
    }

    private ReduceShowOverlay(state: IVolmaCalendarProps, payload: ICalendarToggleOverlayPayload) {
        let newState: IVolmaCalendarProps = { ...state, ShowOverlay: payload.ShowOverlay };

        return newState;
    }

    private ReduceSetActive(state: IVolmaCalendarProps): IVolmaCalendarProps {
        return { ...state, IsActive: true };
    }

    private ReduceRemoveActive(state: IVolmaCalendarProps): IVolmaCalendarProps {
        return { ...state, IsActive: false };
    }

}