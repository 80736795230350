import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { ComplaintHelperProps } from '../IHelperProps';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';

export class Closed extends ComplaintStatusDefault{

    public TitleCurrent : string = "complaint:StatusClosedCurrent";
    public TitleFinished: string = "complaint:StatusClosedFinished";
    public TitleFuture  : string = "complaint:StatusClosedFuture";

    public IsActive(dto: ComplaintDTO, dtoHelper: ComplaintHelperProps) {
        return this.IsFinished(dto);
    }

    public IsCurrent(dto: ComplaintDTO){
        return false;
    }
    
    public IsFinished(dto: ComplaintDTO){
        return dto.ComplaintState === EComplaintState.Closed;
    }

    public GetActions(dto: ComplaintDTO){
        return [];
    }

    constructor(){
        super();
        
        this._currentStatus = EComplaintState.Closed;
    }
}