import { injectable } from 'inversify';
import i18next from '../../Components/i18n';
import { EMPTY_GUID, HTML_BR } from '../../Constants/AppConstants';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { FormatService } from './FormatService';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import PropertyHelper from './PropertyHelper';
import * as moment from 'moment';

@injectable()
export class ChangelogService {
    private _formatService: FormatService;

    constructor() {
        this._formatService = VolmaContainer.get<FormatService>(Types.FormatService);
    }

    public GetChangelog(
        entity: EEntityType,
        oldValuesOrig: any,
        newValuesOrig: any,
        customLocalizers: Array<{ isApplicable(parentFields: Array<string>): boolean, localizer: (parentFields: Array<string>, value: any) => string }> = []) {
        let oldValues = {};
        let newValues = {};
        try {
            oldValues = JSON.parse(oldValuesOrig);
            newValues = JSON.parse(newValuesOrig);
        }
        catch (ex) {
            oldValues = {};
            newValues = {}
        }

        let changes = this.GetChangesForObject(newValues, oldValues, [], entity, customLocalizers, 0);

        return changes.join(HTML_BR);
    }

    private GetChangesForObject(
        newValues: any,
        oldValues: any,
        parentFields: Array<string>,
        entity: EEntityType,
        customLocalizers: Array<{ isApplicable(parentFields: Array<string>): boolean, localizer: (parentFields: Array<string>, value: string) => string }> = [],
        level: number): Array<string> {

        let addedKeys = Array<string>();
        let removedKeys = Array<string>();
        let modifiedKeys = Array<string>();
        let changes = new Array<string>();
        let localizationPrefix = EEntityType[entity].toLowerCase() + ":";
        let changesPrefix = level > 0 ? " ": "";
        for (let index = 0; index < level; index++) {
            changesPrefix = "-" + changesPrefix;
        }

        let isArray = PropertyHelper.IsArray(newValues);

        let newValuesKeys = newValues !== null && newValues !== undefined ?
            (isArray ? newValues.map(x => this.GetIdValue(x)) : Object.keys(newValues)) : [];
        let oldValuesKeys = oldValues !== null && oldValues !== undefined ?
            (isArray ? oldValues.map(x => this.GetIdValue(x)) : Object.keys(oldValues)) : [];

        newValuesKeys = newValuesKeys.filter(x => x !== undefined && x !== null && x !== 'Id' && x !== 'OrderNum' && x !== 'Key');
        oldValuesKeys = oldValuesKeys.filter(x => x !== undefined && x !== null && x !== 'Id' && x !== 'OrderNum' && x !== 'Key');

        addedKeys    = newValuesKeys.filter(x => oldValuesKeys.find(y => y === x) === undefined);
        modifiedKeys = newValuesKeys.filter(x => oldValuesKeys.find(y => y === x) !== undefined);
        removedKeys  = oldValuesKeys.filter(x => newValuesKeys.find(y => y === x) === undefined);

        for (let addedKey of addedKeys) {
            let fieldName = i18next.t(localizationPrefix + addedKey);
            let parentFieldsNew = parentFields.slice(0);
            parentFieldsNew.push(addedKey);
            let newValue = this.GetValue(newValues, addedKey, parentFieldsNew, isArray, customLocalizers);
            let oldValue = undefined;
            isArray ?
                changes = changes.concat(this.ConstructChanges("common:ChangelogArrayAddedItemNumber", "common:ChangelogArrayAddedItemString", "common:ChangelogArrayAddedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level))
                :
                changes = changes.concat(this.ConstructChanges("common:ChangelogAddedItemNumber", "common:ChangelogAddedItemString", "common:ChangelogAddedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level));
        }

        for (let modifiedKey of modifiedKeys) {
            let fieldName = i18next.t(localizationPrefix + modifiedKey);
            let parentFieldsNew = parentFields.slice(0);
            parentFieldsNew.push(modifiedKey);
            let newValue = this.GetValue(newValues, modifiedKey, parentFieldsNew, isArray, customLocalizers);
            let oldValue = this.GetValue(oldValues, modifiedKey, parentFieldsNew, isArray, customLocalizers);

            isArray ?
                changes = changes.concat(this.ConstructChanges("common:ChangelogArrayModifiedItemNumber", "common:ChangelogArrayModifiedItemString", "common:ChangelogArrayModifiedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level))
                :
                changes = changes.concat(this.ConstructChanges("common:ChangelogModifiedItemNumber", "common:ChangelogModifiedItemString", "common:ChangelogModifiedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level));
        }

        for (let removedKey of removedKeys) {
            let fieldName = i18next.t(localizationPrefix + removedKey);
            let parentFieldsNew = parentFields.slice(0);
            parentFieldsNew.push(removedKey);
            let newValue = undefined;
            let oldValue = this.GetValue(oldValues, removedKey, parentFieldsNew, isArray, customLocalizers);

            isArray ?
                changes = changes.concat(this.ConstructChanges("common:ChangelogArrayRemovedItemNumber", "common:ChangelogArrayRemovedItemString", "common:ChangelogArrayRemovedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level))
                :
                changes = changes.concat(this.ConstructChanges("common:ChangelogRemovedItemNumber", "common:ChangelogRemovedItemString", "common:ChangelogRemovedSection",
                    changesPrefix, fieldName, parentFieldsNew, newValue, oldValue, entity, customLocalizers, level));
        }

        return changes;
    }

    private ConstructChanges(
        numberKey: string,
        stringKey: string,
        sectinKey: string,
        changesPrefix: string,
        fieldName: string,
        parentFields: Array<string>,
        newValue: any,
        oldValue: any,
        entity: EEntityType,
        customLocalizers: Array<{ isApplicable(parentFields: Array<string>): boolean, localizer: (parentFields: Array<string>, value: string) => string }> = [],
        level: number): Array<string> {

        let changes = new Array<string>();
        if (PropertyHelper.IsNumber(newValue || oldValue)) {
            changes.push(changesPrefix + i18next.t(numberKey, {
                fieldName: fieldName,
                oldValue: this._formatService.GetSafeString(oldValue),
                newValue: this._formatService.GetSafeString(newValue)
            } as any));
        }
        else if (PropertyHelper.IsObject(newValue || oldValue) || PropertyHelper.IsArray(newValue || oldValue)) {
            changes.push(changesPrefix + i18next.t(sectinKey, { fieldName: fieldName } as any));
            changes = changes.concat(this.GetChangesForObject(newValue, oldValue, parentFields, entity, customLocalizers, level + 1));
        }
        else {
            changes.push(changesPrefix + i18next.t(stringKey, {
                fieldName: fieldName,
                oldValue: this._formatService.GetSafeString(oldValue),
                newValue: this._formatService.GetSafeString(newValue)
            } as any));
        }

        return changes;
    }

    private GetValue(
        baseObject: any,
        key: string,
        parentFields: Array<string>,
        isArray: boolean,
        customLocalizers: Array<{ isApplicable(parentFields: Array<string>): boolean, localizer: (parentFields: Array<string>, value: string) => string }> = []): any {
        let value = undefined;
        if (isArray)
            value = baseObject.find(x => this.GetIdValue(x) === key);
        else
            value = PropertyHelper.SafeGetValue(baseObject, x => x[key]);

        let customLocalizer = customLocalizers.find(x => x.isApplicable(parentFields));
        if (customLocalizer !== undefined) {
            value = customLocalizer.localizer(parentFields, value);
        }
        
        //const format = moment.ISO_8601;
        const format = moment.HTML5_FMT.DATETIME_LOCAL_MS + 'SSSZ';
        
        // @ts-ignore: This expression is not callable
        if (moment(value, format, true).isValid())
            value = this._formatService.GetSafeDateTime(value);

        return value;
    }

    private GetIdValue(obj: any){
        if (obj.Key !== undefined)
            return obj.Key;
        if (obj.OrderNum !== undefined)
            return obj.OrderNum;
        if (obj.Id !== undefined)
            return obj.Id;
    }
}