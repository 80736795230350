import { injectable } from 'inversify';
import * as React from 'react';
import { CHART_BORDER_WIDTH } from '../../../../Constants/AppConstants';
import { CargoTransportersRatingSummaryReportParametersDTO } from '../../../../Domain/DTO/CargoTransportersRatingSummaryReportParametersDTO';
import { ListOptionDTO } from '../../../../Domain/DTO/ListOptionDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EMonth } from '../../../../Domain/Enum/EMonth';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { SafeMergeArrays } from '../../../../Infrastructure/Services/Utils';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import i18next from '../../../i18n';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../../VolmaNumber/VolmaNumber';
import VolmaSelect from '../../../VolmaSelect';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IChartDataset } from '../IChart';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { ReportService } from './ReportService';
import { VolmaMultiTransporterSelect } from '../../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';


@injectable()
export class ReportCargoTransportersRatingSummaryExcelService extends ReportService<CargoTransportersRatingSummaryReportParametersDTO>{
    public Title: string = "ReportRatesExcelService";
    private _numberValidator: BaseValidator<IVolmaNumberProps>;

    constructor() {
        super();
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
    }

    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<CargoTransportersRatingSummaryReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    {!this._authService.IsCargoTransporter() && 
                        <div className={this._mainInfo("cell", { 33: true }).toString()}>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaMultiTransporterSelect
                                    {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransportersRatingSummaryReportParametersDTO) => val.CargoTransporterId) as any) }
                                    Name={PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.CargoTransporterId)}
                                    EntityDtoField={[
                                        PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.CargoTransporterId), 
                                        PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.MultiCargoTransporterId),
                                    ]}
                                    OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                                    Value={props.DataHelper.ReportParams.CargoTransporterId || props.DataHelper.ReportParams.MultiCargoTransporterId}
                                    CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                    dispatch={props.dispatch}
                                    Required /> 
                            </div>
                        </div>}
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransportersRatingSummaryReportParametersDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.LoadingPointId, props.DataHelper.ReportParams.MultiFactoryId])}
                                AllowMultiselect={true}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransportersRatingSummaryReportParametersDTO) => val.Year) }
                                Name={PropertyHelper.GetPropertyName((val: CargoTransportersRatingSummaryReportParametersDTO) => val.Year)}
                                Label={i18next.t('report:Year')}
                                Placeholder={i18next.t('report:Year')}
                                Value={props.DataHelper.ReportParams.Year}
                                MinValue={() => 2000}
                                Validator={this._numberValidator}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransportersRatingSummaryReportParametersDTO) => val.Months) as any) }
                                Name={PropertyHelper.GetPropertyName((x: CargoTransportersRatingSummaryReportParametersDTO) => x.Months)}
                                Label={i18next.t('report:Month')}
                                Placeholder={i18next.t('report:Month')}
                                EnumGetter={() => EMonth}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.Months}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public HasChart(): boolean {
        return false;
    }

    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset) {
        dataset.backgroundColor = [];
        dataset.borderColor = [];
        dataset.borderWidth = CHART_BORDER_WIDTH;
        for (let data in dataset.data) {
            dataset.backgroundColor.push(backgroundColor);
            dataset.borderColor.push(borderColor);
        }
    }

    public InitializeParams(props: IBaseEntityProps<ReportDTO, IReportHelperProps<CargoTransportersRatingSummaryReportParametersDTO>>) : void
    {
        if(props.DataHelper.ReportParams.Year === undefined)
            props.DataHelper.ReportParams.Year = new Date().getFullYear();
    }
}