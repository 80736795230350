export enum EDeliveryState {    
    New,
    Assignation,
    NotApproved,
    NotAccepted,
    Assigned,
    Accepted,
    Approved,
    WaitForLoading,
    VehicleArrivedAtLoadingPoint,
    LoadingBegin,
    LoadingEnd,
    Delivery,
    Delivered,
    NotExecuted,
    Cancelled,
    Closed,
    ClosedOld,
    Revoked,
    VehicleArrivedAtUnloadingPoint,
    LatenessRegistration,
    LoadingLatenessRegistration
}