import { IAction } from '../../Infrastructure/Action/IAction';
import { VOLMA_ENTITY_FOOTER_CLOSED } from '../../Constants/AppConstants';
import { injectable } from 'inversify';

@injectable()
export class FooterActions {

    public FooterClosed(): IAction {
        return { type: VOLMA_ENTITY_FOOTER_CLOSED };
    }
}