import { TENDER_FORECAST_RATING_LOADED } from '../../../../Constants/AppConstants';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { RatingServerInteraction } from '../../../../Infrastructure/ServerInteraction/RatingServerInteraction';
import { Types } from '../../../../Infrastructure/Types';
import { EntityAction } from '../../BaseEntity/EntityAction';
import i18next from '../../../i18n';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { FilterDTO } from '../../../../Domain/DTO/FilterDTO';

export class TenderRatingActions extends EntityAction{

    constructor() {
        super();
    }

    
    public LoadForecastRating(loadingPoints: Array<LoadingPointDTO>, waitingText: string) {
        let baseKey = "filter_"
        let filters = new Array<FilterDTO>();
        if(loadingPoints !== undefined && loadingPoints.length > 0)
        {
            for (var loadingPoint of loadingPoints) {
                if(loadingPoint !== undefined)
                { 
                    let filter =  <FilterDTO>{
                        Key: baseKey+ PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointId),
                        TextValue: loadingPoint.Id
                    };
                    filters.push(filter);
                }
            }
        }
        return super.LoadDataTable<RatingTableDTO>(
            EEntityType.ForecastRating,
            x => x.Timestamp,
            ESortDir.Desc,
            filters,
            waitingText,
            this.ForecastRatingLoaded);
    }

    private ForecastRatingLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: TENDER_FORECAST_RATING_LOADED, Payload: value };
    }

}