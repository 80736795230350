import {
    ROUTE_POINT_ADD_EXTRA_INFO_FIELD,
    ROUTE_POINT_ALL_HANDLING_TYPES_LOADED,
    ROUTE_POINT_ALL_LOADING_POINTS_LOADED,
    ROUTE_POINT_ALL_LOCALITIES_LOADED,
    ROUTE_POINT_EDIT_EXTRA_INFO_FIELD,
    ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED,
    ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED,
    ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD
} from '../../../../Constants/AppConstants';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { LocalityTableDTO } from '../../../../Domain/DTO/LocalityTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IAction, IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { IExtraFieldChanged } from './Payload';
import { injectable } from 'inversify';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import { ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED } from '../../../../Constants/AppConstants';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';

@injectable()
export class RoutePointActions extends EntityAction {
    constructor() {
        super();
    }

    public LoadAllLoadingPoints(waitingText: string){
        return super.LoadDataTable<LoadingPointTableDTO>(
            EEntityType.LoadingPoint,
            x => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllLoadingPointsLoaded);
    }

    public LoadAllLocalities(waitingText: string){
        return super.LoadDataTable<LocalityTableDTO>(
            EEntityType.Locality,
            x => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllLocalitiesLoaded);
    }

    public LoadAllHandlingTypes(waitingText: string){
        return super.LoadDataTable<HandlingTypeTableDTO>(
            EEntityType.HandlingType,
            x => x.Type,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllHandlingTypesLoaded);
    }

    public AddExtraInfoField(): IAction {
        return { type: ROUTE_POINT_ADD_EXTRA_INFO_FIELD };
    }

    public RemoveAddExtraInfoField(id: string): IActionPayloaded<string> {
        return { type: ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD, Payload: id };
    }

    public EditExtraInfoField(id: string): IActionPayloaded<string> {
        return { type: ROUTE_POINT_EDIT_EXTRA_INFO_FIELD, Payload: id };
    }

    public ExtraInfoFieldKeyChanged(id: string, value: string): IActionPayloaded<IExtraFieldChanged>{
        return { type: ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED, Payload: {Id: id, Value: value} };
    }

    public ExtraInfoFieldValueChanged(id: string, value: string): IActionPayloaded<IExtraFieldChanged>{
        return { type: ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED, Payload: {Id: id, Value: value} };
    }

    public ShowCustomPointFieldChanged(flag: boolean): IActionPayloaded<boolean>{
        return { type: ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED, Payload: flag};
    }

    private AllLoadingPointsLoaded(value: Array<LoadingPointTableDTO>): IActionPayloaded<Array<LoadingPointTableDTO>> {
        return { type: ROUTE_POINT_ALL_LOADING_POINTS_LOADED, Payload: value };
    }

    private AllLocalitiesLoaded(value: Array<LocalityTableDTO>): IActionPayloaded<Array<LocalityTableDTO>> {
        return { type: ROUTE_POINT_ALL_LOCALITIES_LOADED, Payload: value };
    }

    private AllHandlingTypesLoaded(value: Array<HandlingTypeTableDTO>): IActionPayloaded<Array<HandlingTypeTableDTO>> {
        return { type: ROUTE_POINT_ALL_HANDLING_TYPES_LOADED, Payload: value };
    }

}