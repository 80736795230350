export const VERSION = "1.11.8.157"
export const HIDE_UNFINISHED = false;
export const HIDE_FORECAST_DETAILS = true;
export const HIDE_RATINGS_LIST = true;
export const DEBUG = false;
export const ID_COLUMN = "Id";
export const HTML_BR = "<br />";

// request overlay debounce
export const REQUEST_OVERLAY_DEBOUNCE = 400;
export const GENERAL_INFORM_NOTIFICATION_LIFETIME = 5400000; // 90min
export const SHORT_INFORM_NOTIFICATION_LIFETIME = 1200000; // 20min

export const UPSTREAM_SERVER_FAILED = 502;
export const REQUEST_FORBIDDEN      = 403;
export const REQUEST_UNAUTHORIZED   = 401;
export const REQUEST_BAD_REQUEST    = 400;
export const REQUEST_OK             = 200;

export const SIGNALR_UNMUTE_THRESHOLD = 1000;
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

export const CHART_BACKGROUND_COLORS = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
];
export const CHART_BORDER_COLORS = [
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
];
export const CHART_BORDER_WIDTH = 1;

// local storage keys
export const VOLMA_TABLE_FILTER_OPTIONS     = "filterOptions";
export const VOLMA_TABLE_SORT_OPTIONS       = "sortOptions";
export const VOLMA_INFO_LINE_STATES         = "infoLineStates";
export const VOLMA_TABLE_CURRENT_SCROLL_POS = "tableCurrentScrollPos";
export const INFO_BLOCK_COLLAPSED           = "infoBlockCollapsed";

// volma component
export const VOLMA_COMPONENT_INITIALIZE = 'VOLMA_COMPONENT_INITIALIZE';

// noop
export const NOOP = 'NOOP';

// form
export const FORM_SUBMITTED = 'FORM_SUBMITTED';

// signalr
export const VOLMA_SIGNALR_START_CONNECTION          = 'VOLMA_SIGNALR_START_CONNECTION';
export const VOLMA_SIGNALR_STOP_CONNECTION           = 'VOLMA_SIGNALR_STOP_CONNECTION';
export const VOLMA_SIGNALR_INVOKE                    = 'VOLMA_SIGNALR_INVOKE';
export const VOLMA_SIGNALR_STATE_CHANGED             = 'VOLMA_SIGNALR_STATE_CHANGED';
export const VOLMA_SIGNALR_ENTITY_ADDED              = 'VOLMA_SIGNALR_ENTITY_ADDED';
export const VOLMA_SIGNALR_ENTITY_UPDATED            = 'VOLMA_SIGNALR_ENTITY_UPDATED';
export const VOLMA_SIGNALR_ENTITY_DELETED            = 'VOLMA_SIGNALR_ENTITY_DELETED';
export const VOLMA_SIGNALR_DELIVERY_STATE_UPDATED    = 'VOLMA_SIGNALR_DELIVERY_STATE_UPDATED';
export const VOLMA_SIGNALR_TENDER_BET_SETTED         = 'VOLMA_SIGNALR_TENDER_BET_SETTED';
export const VOLMA_SIGNALR_BIDDING_BET_SETTED        = 'VOLMA_SIGNALR_BIDDING_BET_SETTED';
export const VOLMA_SIGNALR_TENDER_BIDDING_STARTED    = 'VOLMA_SIGNALR_TENDER_BIDDING_STARTED';
export const VOLMA_SIGNALR_TENDER_BIDDING_END        = 'VOLMA_SIGNALR_TENDER_BIDDING_END';
export const VOLMA_SIGNALR_COMPLAINT_ADDED           = 'VOLMA_SIGNALR_COMPLAINT_ADDED';
export const VOLMA_SIGNALR_COMPLAINT_UPDATED         = 'VOLMA_SIGNALR_COMPLAINT_UPDATED';
export const VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED   = 'VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED';
export const VOLMA_SIGNALR_COMPLAINT_COMMENT_UPDATED = 'VOLMA_SIGNALR_COMPLAINT_COMMENT_UPDATED';
export const VOLMA_SIGNALR_MUTE                      = 'VOLMA_SIGNALR_MUTE';
export const VOLMA_SIGNALR_UNMUTE                    = 'VOLMA_SIGNALR_UNMUTE';
export const VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED = 'VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED';
export const VOLMA_SIGNALR_CARGO_TRANSPORTER_CHANGED = 'VOLMA_SIGNALR_CARGO_TRANSPORTER_CHANGED';


// input
export const INPUT_VALUE_CHANGE  = 'INPUT_VALUE_CHANGE';
export const INPUT_TOUCHED       = 'INPUT_TOUCHED';
export const INPUT_SET_ACTIVE    = 'INPUT_SET_ACTIVE';
export const INPUT_REMOVE_ACTIVE = 'INPUT_REMOVE_ACTIVE';
export const INPUT_REGISTER      = 'INPUT_REGISTER';
export const INPUT_VALIDATE      = 'INPUT_VALIDATE';
export const INPUT_SET_VALIDATOR = 'INPUT_SET_VALIDATOR';

// select
export const SELECT_VALUE_CHANGE = 'SELECT_VALUE_CHANGE';
export const SELECT_TOUCHED      = 'INPUT_TOUCHED';
export const SELECT_REGISTER     = 'SELECT_REGISTER';
export const SELECT_VALIDATE     = 'SELECT_VALIDATE';

// file
export const INPUT_FILE_REMOVE_FILE         = 'INPUT_FILE_REMOVE_FILE';
export const INPUT_FILE_FILES_CHANGED       = 'INPUT_FILE_FILES_CHANGED';
export const INPUT_FILE_UPLOAD_STARTED      = 'INPUT_FILE_UPLOAD_STARTED';
export const INPUT_FILE_UPLOAD_ERROR        = 'INPUT_FILE_UPLOAD_ERROR';
export const INPUT_FILE_UPLOAD_SUCCESSFULLY = 'INPUT_FILE_UPLOAD_SUCCESSFULLY';
export const INPUT_FILE_FILE_INFO_LOADED    = 'INPUT_FILE_FILE_INFO_LOADED';
export const INPUT_FILE_PROGRESS_UPDATED    = 'INPUT_FILE_PROGRESS_UPDATED';

// calendar
export const INPUT_CALENDAR_TOGGLE_OVERLAY = 'INPUT_CALENDAR_TOGGLE_OVERLAY';

// app
export const REQUEST_BEGIN                  = 'REQUEST_BEGIN';
export const REQUEST_END                    = 'REQUEST_END';
export const REQUEST_SERVER_ERROR           = 'REQUEST_SERVER_ERROR';
export const REQUEST_VALIDATION_ERROR       = 'REQUEST_VALIDATION_ERROR';
export const REQUEST_CLEAR_ERROR            = 'REQUEST_CLEAR_ERROR';
export const REQUEST_DEBOUNCE               = 'REQUEST_DEBOUNCE';
export const APP_CLOSE_VALIDATION_ERRORS    = 'APP_CLOSE_VALIDATION_ERRORS';
export const APP_CLOSE_INFO_MESSAGE         = 'APP_CLOSE_INFO_MESSAGE';
export const APP_TOGGLE_INFO_MESSAGE        = 'APP_TOGGLE_INFO_MESSAGE';
export const APP_SET_INFO_MESSAGE_COLLAPSED = 'APP_SET_INFO_MESSAGE_COLLAPSED';
export const APP_CLOSE_SERVER_ERRORS        = 'APP_CLOSE_SERVER_ERRORS';
export const APP_REMOVE_VIEWVIED_UPDATES    = 'APP_REMOVE_VIEWVIED_UPDATES';
export const APP_SERVICE_INFO_LOADED        = 'APP_SERVICE_INFO_LOADED';

export const APP_LOGIN            = 'APP_LOGIN';
export const APP_LOGOUT           = 'APP_LOGOUT';

export const VOLMA_SOMEWHERE_CLICKED = 'VOLMA_SOMEWHERE_CLICKED';

// header
export const HEADER_TOGGLE_USER_MENU = 'HEADER_TOGGLE_USER_MENU';
export const HEADER_TOGGLE_NAV       = 'HEADER_TOGGLE_NAV';
export const HEADER_SEARCH_UPDATED   = ' HEADER_SEARCH_UPDATED';

// user profile
export const USER_PROFILE_CHANGED = "USER_PROFILE_CHANGED";

// login
//export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_MOUNTED    = 'LOGIN_MOUNTED';
export const LOGIN_UNMOUNTED  = 'LOGIN_UNMOUNTED';

// restore
export const RESTORE_MOUNTED                 = 'RESTORE_MOUNTED';
export const RESTORE_UNMOUNTED               = 'RESTORE_UNMOUNTED';
export const RESTORE_SUCCESSFUL_SENT_MESSAGE = 'RESTORE_SUCCESSFUL_SENT_MESSAGE';

// register
export const REGISTER_MOUNTED      = 'REGISTER_MOUNTED';
export const REGISTER_UNMOUNTED    = 'REGISTER_UNMOUNTED';
export const REGISTER_SUCCESSFUL   = 'REGISTER_SUCCESSFUL';
export const REGISTER_ROLES_LOADED = 'REGISTER_ROLES_LOADED';
export const REGISTER_FAILED       = 'REGISTER_FAILED';

// restore password
export const PASSWORD_RESTORE_MOUNTED          = 'PASSWORD_RESTORE_MOUNTED';
export const PASSWORD_RESTORE_UNMOUNTED        = 'PASSWORD_RESTORE_UNMOUNTED';
export const PASSWORD_RESTORE_SET_USERID_TOKEN = 'PASSWORD_RESTORE_SET_USERID_TOKEN';
export const PASSWORD_RESTORE_SUCCESSFULLY     = 'PASSWORD_RESTORE_SUCCESSFULLY';
export const PASSWORD_RESTORE_FAILED           = 'PASSWORD_RESTORE_FAILED';
export const PASSWORD_RESTORE_CLEAR            = 'PASSWORD_RESTORE_CLEAR';

// restore password
export const PASSWORD_RESET_MOUNTED                 = 'PASSWORD_RESET_MOUNTED';
export const PASSWORD_RESET_UNMOUNTED               = 'PASSWORD_RESET_UNMOUNTED';
export const PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE = 'PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE';
export const PASSWORD_RESET_CLEAR                   = 'PASSWORD_RESET_CLEAR';

// table
export const BASE_TABLE_MOUNTED   = 'BASE_TABLE_MOUNTED';
export const BASE_TABLE_UNMOUNTED = 'BASE_TABLE_UNMOUNTED';
export const BASE_TABLE_SET_ENTITY_SERVICE = 'BASE_TABLE_SET_ENTITY_SERVICE';
export const BASE_TABLE_CLEAR_FILTERS_AND_SELECTION   = 'BASE_TABLE_CLEAR_FILTERS';

export const VOLMA_TABLE_REGISTER                   = 'VOLMA_TABLE_REGISTER';
export const VOLMA_TABLE_OPEN_FILTER                = 'VOLMA_TABLE_OPEN_FILTER';
export const VOLMA_TABLE_CLOSE_FILTER               = 'VOLMA_TABLE_CLOSE_FILTER';
export const VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE = 'VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE';
export const VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED   = 'VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED';
export const VOLMA_TABLE_REMOVE_COLUMN_FILTER       = 'VOLMA_TABLE_REMOVE_COLUMN_FILTER';
export const VOLMA_TABLE_SORT                       = 'VOLMA_TABLE_SORT';
export const VOLMA_TABLE_TOGGLE_ROW_SELECTION       = 'VOLMA_TABLE_TOGGLE_ROW_SELECTION';
export const VOLMA_TABLE_CLEAR_SELECTION            = 'VOLMA_TABLE_CLEAR_SELECTION';
export const VOLMA_TABLE_EDIT_ROW_REQUIRED          = 'VOLMA_TABLE_EDIT_ROW_REQUIRED';
export const VOLMA_TABLE_PERFORM_ACTION_ON_ROW      = 'VOLMA_TABLE_PERFORM_ACTION_ON_ROW';
export const VOLMA_TABLE_DATA_LOADED                = 'VOLMA_TABLE_DATA_LOADED';
export const VOLMA_TABLE_CLEAR_DATA                 = ' VOLMA_TABLE_CLEAR_DATA';
export const VOLMA_TABLE_DATA_UPDATED               = 'VOLMA_TABLE_DATA_UPDATED';
export const VOLMA_TABLE_UPDATE_FILTERS             = 'VOLMA_TABLE_UPDATE_FILTERS';
export const VOLMA_TABLE_RESET_PAGING               = 'VOLMA_TABLE_RESET_PAGING';
export const VOLMA_TABLE_SCROLLED                   = 'VOLMA_TABLE_SCROLLED';


// entity
export const BASE_ENTITY_MOUNTED   = 'BASE_ENTITY_MOUNTED';
export const BASE_ENTITY_UNMOUNTED = 'BASE_ENTITY_UNMOUNTED';

export const VOLMA_ENTITY_DTO_STRINGIFY_UPDATED = 'VOLMA_ENTITY_DTO_STRINGIFY_UPDATED';
export const VOLMA_ENTITY_DATA_LOADED           = 'VOLMA_ENTITY_DATA_LOADED';
export const VOLMA_ENTITY_STATUS_TOGGLED        = 'VOLMA_ENTITY_STATUS_TOGGLED';
export const VOLMA_ENTITY_INFO_LINE_TOGGLED     = 'VOLMA_ENTITY_INFO_LINE_TOGGLED';
export const VOLMA_ENTITY_SET_ENTITY            = 'VOLMA_ENTITY_SET_ENTITY';
export const VOLMA_ENTITY_SET_ENTITY_SERVICE    = 'VOLMA_ENTITY_SET_ENTITY_SERVICE';
export const VOLMA_ENTITY_FOOTER_NAV_ADDED      = 'VOLMA_ENTITY_FOOTER_NAV_ADDED';
export const VOLMA_ENTITY_CLEAR_FOOTER          = 'VOLMA_ENTITY_CLEAR_FOOTER';
export const VOLMA_ENTITY_CLEAR_REQUESTS        = 'VOLMA_ENTITY_CLEAR_REQUESTS';
export const VOLMA_ENTITY_FOOTER_CLOSED         = 'VOLMA_ENTITY_FOOTER_CLOSED';

// zone
export const ZONE_LOCALITIES_LOADED           = 'ZONE_LOCALITIES_LOADED';

// locality
export const LOCALITY_ZONES_LOADED = 'LOCALITY_ZONES_LOADED';

export const GIS_COORDINATES_CHANGED = 'GIS_COORDINATES_CHANGED';
export const LAT_COORDINATES_CHANGED     = 'LAT_COORDINATES_CHANGED';
export const LON_COORDINATES_CHANGED     = 'LON_COORDINATES_CHANGED';


export const SET_IS_MAX_PERCENTAGE_AVAILABLE = 'SET_IS_MAX_PERCENTAGE_AVAILABLE';


// vehicle type
export const VEHICLE_TYPE_BODY_TYPES_LOADED              = 'VEHICLE_TYPE_BODY_TYPES_LOADED';
export const VEHICLE_TYPE_HANDLING_TYPES_LOADED          = 'VEHICLE_TYPE_HANDLING_TYPES_LOADED';

// vehicle
export const VEHICLE_DRIVERS_LOADED                  = 'VEHICLE_DRIVERS_LOADED';
export const VEHICLE_ALL_DRIVER_LOADED               = "VEHICLE_ALL_DRIVER_LOADED";
export const VEHICLE_DRIVER_ADDED_TO_VEHICLE         = "VEHICLE_DRIVER_ADDED_TO_VEHICLE";
export const VEHICLE_DRIVER_REMOVED_TO_VEHICLE       = "VEHICLE_DRIVER_REMOVED_TO_VEHICLE";

// cargo transporter user
export const CARGO_TRANSPORTER_CHANGE_USER_ROLE_REQUIRED = 'CARGO_TRANSPORTER_CHANGE_USER_ROLE_REQUIRED';
export const CARGO_TRANSPORTER_USER_ROLE_UPDATED = 'CARGO_TRANSPORTER_USER_ROLE_UPDATED';

// route rate
export const ROUTE_RATE_SETTINGD_LOADED       = 'ROUTE_RATE_SETTINGD_LOADED';
export const ROUTE_RATE_RATE_UPDATED          = 'ROUTE_RATE_RATE_UPDATED';


// complaint template
export const COMPLAINT_TEMPLATE_FILE_INFO_LOADED      = 'COMPLAINT_TEMPLATE_FILE_INFO_LOADED';
export const COMPLAINT_TEMPLATE_FILE_DELETED          = 'COMPLAINT_TEMPLATE_FILE_DELETED';
export const COMPLAINT_TEMPLATE_FILE_UPLOADED         = 'COMPLAINT_TEMPLATE_FILE_UPLOADED';
export const COMPLAINT_TEMPLATE_FILE_FAILED_TO_UPLOAD = 'COMPLAINT_TEMPLATE_FILE_FAILED_TO_UPLOAD';

// delivery
export const DELIVERY_DELIVERY_STATES_LOADED      = 'DELIVERY_DELIVERY_STATES_LOADED';
export const DELIVERY_BIDDING_BETS_LOADED         = 'DELIVERY_BIDDING_BETS_LOADED';
export const DELIVERY_BIDDING_WINNER_BETS_LOADED  = 'DELIVERY_BIDDING_WINNER_BETS_LOADED';
export const DELIVERY_COMPLAINTS_LOADED           = 'DELIVERY_COMPLAINTS_LOADED';
export const DELIVERY_VEHICLE_LOADED              = 'DELIVERY_VEHICLE_LOADED';
export const DELIVERY_DRIVER_LOADED               = 'DELIVERY_DRIVER_LOADED';
export const DELIVERY_CARGOTRANSPORTER_LOADED     = 'DELIVERY_CARGOTRANSPORTER_LOADED';
export const DELIVERY_CONSIGNOR_LOADED            = 'DELIVERY_CONSIGNOR_LOADED';
export const DELIVERY_ADDITION_FILES_LOADED       = 'DELIVERY_ADDITION_FILES_LOADED';
export const DELIVERY_LOADING_POINT_LOADED        = 'DELIVERY_LOADING_POINT_LOADED';
export const DELIVERY_LOCALITY_LOADED             = 'DELIVERY_LOCALITY_LOADED';
export const DELIVERY_HANDLING_TYPE_LOADED        = 'DELIVERY_HANDLING_TYPE_LOADED';
export const DELIVERY_PAYLOAD_LOADED              = 'DELIVERY_PAYLOAD_LOADED';
export const DELIVERY_ROUTE_POINT_ADDED           = 'DELIVERY_ROUTE_POINT_ADDED';
export const DELIVERY_ROUTE_POINT_UPDATED         = 'DELIVERY_ROUTE_POINT_UPDATED';
export const DELIVERY_ROUTE_POINTS_DELETED        = 'DELIVERY_ROUTE_POINTS_DELETED';
export const DELIVERY_ROUTE_POINTS_LOADED         = 'DELIVERY_ROUTE_POINTS_LOADED';
export const DELIVERY_PENDING_CHANGES_LOADED      = 'DELIVERY_PENDING_CHANGES_LOADED';
export const DELIVERY_FIRST_LOADING_POINT_LOADED  = 'DELIVERY_FIRST_LOADING_POINT_LOADED';
export const DELIVERY_ZONE_LOADED                 = 'DELIVERY_ZONE_LOADED';

// route point
export const ROUTE_POINT_ADD_EXTRA_INFO_FIELD           = 'ROUTE_POINT_ADD_EXTRA_INFO_FIELD';
export const ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD        = 'ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD';
export const ROUTE_POINT_EDIT_EXTRA_INFO_FIELD          = 'ROUTE_POINT_EDIT_EXTRA_INFO_FIELD';
export const ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED   = 'ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED';
export const ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED = 'ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED';
export const ROUTE_POINT_ALL_LOADING_POINTS_LOADED      = 'ROUTE_POINT_ALL_LOADING_POINTS_LOADED';
export const ROUTE_POINT_ALL_LOCALITIES_LOADED          = 'ROUTE_POINT_ALL_LOCALITIES_LOADED';
export const ROUTE_POINT_ALL_HANDLING_TYPES_LOADED      = 'ROUTE_POINT_ALL_HANDLING_TYPES_LOADED';
export const ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED = 'ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED';



// delivery confirm
export const DELIVERY_CONFIRM_ALL_DRIVERS_LOADED                    = 'DELIVERY_CONFIRM_ALL_DRIVERS_LOADED';
export const DELIVERY_CONFIRM_ALL_VEHICLES_LOADED                   = 'DELIVERY_CONFIRM_ALL_VEHICLES_LOADED';
export const DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER              = 'DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER';
export const DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED        = 'DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED';

// manual assign
export const DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED     = 'DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED';
export const DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER            = 'DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER';

// delivery table
export const DELIVERY_TABLE_BET_ADDED_OR_UPDATED            = 'DELIVERY_TABLE_BET_ADDED_OR_UPDATED'
export const DELIVERY_TABLE_BETS_LOADED                     = 'DELIVERY_TABLE_BETS_LOADED'
export const DELIVERY_TABLE_WHITH_BETS_REMOVED              = 'DELIVERY_TABLE_WHITH_BETS_REMOVED'

// driver
export const DRIVER_VEHICLES_LOADED             = 'DRIVER_VEHICLES_LOADED';
export const DRIVER_All_VEHICLES_LOADED         = 'DRIVER_All_VEHICLES_LOADED';
export const DRIVER_VEHICLE_ADDED_TO_DRIVER     = 'DRIVER_VEHICLE_ADDED_TO_DRIVER';
export const DRIVER_VEHICLE_REMOVED_FROM_DRIVER = 'DRIVER_VEHICLE_REMOVED_FROM_DRIVER';

// coordinates
export const DRIVER_COORDINATES_LOADED = 'DRIVER_COORDINATES_LOADED'
export const DRIVER_SCORE_LOADED = 'DRIVER_SCORE_LOADED'


// contract
export const DRIVER_CONTRACTS_LOADED             = 'DRIVER_CONTRACTS_LOADED';
export const DRIVER_All_CONTRACTS_LOADED         = 'DRIVER_All_CONTRACTS_LOADED';


// cargo transporter
export const CARGO_TRANSPORTER_CONTRACTS_LOADED          = 'CARGO_TRANSPORTER_CONTRACTS_LOADED';
export const CARGO_TRANSPORTER_DRIVERS_LOADED            = 'CARGO_TRANSPORTER_DRIVERS_LOADED';
export const CARGO_TRANSPORTER_VEHICLES_LOADED           = 'CARGO_TRANSPORTER_VEHICLES_LOADED';
export const CARGO_TRANSPORTER_RATING_LOADED             = 'CARGO_TRANSPORTER_RATING_LOADED';
export const CARGO_TRANSPORTER_LOADING_POINTS_LOADED     = 'CARGO_TRANSPORTER_LOADING_POINTS_LOADED';
export const CARGO_TRANSPORTER_CURRENT_RATING_LOADED     = 'CARGO_TRANSPORTER_CURRENT_RATING_LOADED';
export const CARGO_TRANSPORTER_FORECAST_RATING_LOADED    = 'CARGO_TRANSPORTER_FORECAST_RATING_LOADED';
export const CARGO_TRANSPORTER_OPEN_HISTORY_REQUIRED     = 'CARGO_TRANSPORTER_OPEN_HISTORY_REQUIRED';
export const CARGO_TRANSPORTER_CLOSE_HISTORY_REQUIRED    = 'CARGO_TRANSPORTER_CLOSE_HISTORY_REQUIRED';
export const CARGO_TRANSPORTER_DRIVER_ADDED              = 'CARGO_TRANSPORTER_DRIVER_ADDED';
export const CARGO_TRANSPORTER_DRIVER_EDITED             = 'CARGO_TRANSPORTER_DRIVER_EDITED';
export const CARGO_TRANSPORTER_VEHICLE_ADDED             = 'CARGO_TRANSPORTER_VEHICLE_ADDED';
export const CARGO_TRANSPORTER_VEHICLE_EDITED            = 'CARGO_TRANSPORTER_VEHICLE_EDITED';
export const CARGO_TRANSPORTER_RATING_ADDED              = 'CARGO_TRANSPORTER_RATING_ADDED';
export const CARGO_TRANSPORTER_RATING_EDITED             = 'CARGO_TRANSPORTER_RATING_EDITED';
export const CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED    = 'CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED';
export const CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED   = 'CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED';
export const CARGO_TRANSPORTER_CONTRACT_ADDED            = 'CARGO_TRANSPORTER_CONTRACT_ADDED';
export const CARGO_TRANSPORTER_CONTRACT_EDITED           = 'CARGO_TRANSPORTER_CONTRACT_EDITED';
export const CARGO_TRANSPORTER_CONTRACTS_REMOVED         = "CARGO_TRANSPORTER_CONTRACTS_REMOVED";

// multi cargo transporter
export const MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS = 'MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS';
export const MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED = 'MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED';
export const MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED = 'MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED';
export const MULTI_CARGO_TRANSPORTER_RATING_EDITED = "MULTI_CARGO_TRANSPORTER_RATING_EDITED";

// modal
export const VOLMA_MODAL_OPEN                   = 'VOLMA_MODAL_OPEN';
export const VOLMA_MODAL_CLOSE                  = 'VOLMA_MODAL_CLOSE';
export const VOLMA_MODAL_ENTITY_SAVED           = 'VOLMA_MODAL_ENTITY_SAVED';
export const VOLMA_MODAL_DATA_LOADED            = 'VOLMA_MODAL_DATA_LOADED';
export const VOLMA_MODAL_SET_ENTITY_SERVICE     = 'VOLMA_MODAL_SET_ENTITY_SERVICE';
export const VOLMA_MODAL_INITIALIZE_ENTITY      = 'VOLMA_MODAL_INITIALIZE_ENTITY';
export const VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE = 'VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE';


// tender
export const TENDER_TABLE_BETS_LOADED                     = 'TENDER_TABLE_BETS_LOADED';
export const TENDER_TABLE_BET_ADDED_OR_UPDATED            = 'TENDER_TABLE_BET_ADDED_OR_UPDATED';

export const TENDER_BETS_LOADED                = 'TENDER_BETS_LOADED';
export const TENDER_WINNERS_BETS_LOADED        = 'TENDER_WINNERS_BETS_LOADED';
export const TENDER_CHANGELOGS_LOADED          = 'TENDER_CHANGELOGS_LOADED';
export const TENDER_DELIVERIES_LOADED          = 'TENDER_DELIVERIES_LOADED';
export const TENDER_REJECTED_DELIVERIES_LOADED = 'TENDER_REJECTED_DELIVERIES_LOADED';
export const TENDER_ZONE_LOADED                = 'TENDER_ZONE_LOADED';
export const TENDER_CONSIGNOR_LOADED           = 'TENDER_CONSIGNOR_LOADED';
export const TENDER_LOADING_POINT_LOADED       = 'TENDER_LOADING_POINT_LOADED';
export const TENDER_MULTI_FACTORY_LOADED       = 'TENDER_MULTI_FACTORY_LOADED';
export const TENDER_WINNER_LOADED              = 'TENDER_WINNER_LOADED';
export const TENDER_PAYLOAD_LOADED             = 'TENDER_PAYLOAD_LOADED';
export const TENDER_FORECAST_RATING_LOADED     = 'TENDER_FORECAST_RATING_LOADED';

// complaint
export const COMPLAINT_COMPLAINT_COMMENTS_LOADED      = 'COMPLAINT_COMPLAINT_COMMENTS_LOADED';
export const COMPLAINT_COMPLAINT_MESSAGE_CHANGED      = 'COMPLAINT_COMPLAINT_MESSAGE_CHANGED';
export const COMPLAINT_ACCEPTED_PAYMENT_EDITING       = 'COMPLAINT_ACCEPTED_PAYMENT_EDITING';
export const COMPLAINT_CARGO_TRANSPORTER_LOADED       = 'COMPLAINT_CARGO_TRANSPORTER_LOADED';
export const COMPLAINT_COMPLAINT_TEMPLATE_LOADED      = 'COMPLAINT_COMPLAINT_TEMPLATE_LOADED';
export const COMPLAINT_AUTHOR_LOADED                  = 'COMPLAINT_AUTHOR_LOADED';
export const COMPLAINT_COMPLAINT_FILE_LOADED          = 'COMPLAINT_COMPLAINT_FILE_LOADED';
export const COMPLAINT_ADDITIONAL_FILES_LOADED        = 'COMPLAINT_ADDITIONAL_FILES_LOADED';
export const COMPLAINT_COMPLAINT_TEMPLATE_UPDATED     = 'COMPLAINT_COMPLAINT_TEMPLATE_UPDATED';
export const COMPLAINT_COMPLAINT_FILE_UPDATED         = 'COMPLAINT_COMPLAINT_FILE_UPDATED';
export const COMPLAINT_COMPLAINT_FILE_DELETED         = 'COMPLAINT_COMPLAINT_FILE_DELETED';
export const COMPLAINT_ADDITION_FILE_ADDED            = 'COMPLAINT_ADDITION_FILE_ADDED';
export const COMPLAINT_ADDITION_FILE_DELETED          = 'COMPLAINT_ADDITION_FILE_DELETED';
export const COMPLAINT_COMMENT_ADDED                  = 'COMPLAINT_COMMENT_ADDED';
export const COMPLAINT_COMMENT_READED                 = 'COMPLAINT_COMMENT_READED';
export const COMPLAINT_STATE_UPDATED                  = 'COMPLAINT_STATE_UPDATED';
export const COMPLAINT_CARGOTRANSPORTERS_UPDATED      = 'COMPLAINT_CARGOTRANSPORTERS_UPDATED';
export const COMPLAINT_SHOWDUMMYSELECT_UPDATED        = 'COMPLAINT_SHOWDUMMYSELECT_UPDATED';
export const COMPLAINT_DELIVERIESVALUE_CLEAR          = 'COMPLAINT_DELIVERIESVALUE_CLEAR';

// route rate
export const CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT = 'CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT';
export const CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT   = 'CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT';
export const CARGO_TRANSPORTER_ROUTE_RATES_LOADED    = 'CARGO_TRANSPORTER_ROUTE_RATES_LOADED';
export const CARGO_TRANSPORTER_ROUTE_RATE_ADDED      = 'CARGO_TRANSPORTER_ROUTE_RATE_ADDED';
export const CARGO_TRANSPORTER_ROUTE_RATE_UPDATED    = 'CARGO_TRANSPORTER_ROUTE_RATE_UPDATED';

// settings
export const SETTINGS_MOUNTED                   = 'SETTINGS_MOUNTED';
export const SETTINGS_UNMOUNTED                 = 'SETTINGS_UNMOUNTED';
export const SETTINGS_LOADED                    = 'SETTINGS_LOADED';
export const SETTINGS_SAVED                     = 'SETTINGS_SAVED';
export const SETTINGS_CALENDAR_ENTRY_ADDED      = 'SETTINGS_CALENDAR_ENTRY_ADDED';
export const SETTINGS_CALENDAR_ENTRY_UPDATED    = 'SETTINGS_CALENDAR_ENTRY_UPDATED';
export const SETTINGS_CALENDAR_ENTRIES_REMOVED  = 'SETTINGS_CALENDAR_ENTRIES_REMOVED';
export const SETTINGS_COMPLAINT_TEMPLATES_LOADED  = 'SETTINGS_COMPLAINT_TEMPLATES_LOADED';
export const SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED = 'SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED';
export const SETTINGS_CLEAR_FOOTER              = 'SETTINGS_CLEAR_FOOTER';
export const SETTINGS_FOOTER_CLOSED              = 'SETTINGS_FOOTER_CLOSED';

//portal configuration
export const PORTAL_CONFIGURATION_MOUNTED         = 'PORTAL_CONFIGURATION_MOUNTED';
export const PORTAL_CONFIGURATION_UNMOUNTED       = 'PORTAL_CONFIGURATION_UNMOUNTED';
export const PORTAL_CONFIGURATION_LOADED          = 'PORTAL_CONFIGURATION_LOADED';
export const PORTAL_CONFIGURATION_SAVED           = 'PORTAL_CONFIGURATION_SAVED';
export const PORTAL_CONFIGURATION_CLEAR_FOOTER    = 'PORTAL_CONFIGURATION_CLEAR_FOOTER';

// about
export const ABOUT_MOUNTED   = 'ABOUT_MOUNTED';
export const ABOUT_UNMOUNTED = 'ABOUT_UNMOUNTED';
export const ABOUT_LOADED    = 'ABOUT_LOADED';

// rating
export const RATING_CURRENT_RATING_LOADED    = 'RATING_CURRENT_RATING_LOADED';
export const RATING_LOADING_POINTS_LOADED    = 'RATING_LOADING_POINTS_LOADED';
export const RATING_TABLE_FORECAST_LOADED    = 'RATING_TABLE_FORECAST_LOADED';
export const RATING_RATING_HISTORY_LOADED    = 'RATING_RATING_HISTORY_LOADED';
export const RATING_LOADING_POINT_LOADED     = 'RATING_LOADING_POINT_LOADED';
export const RATING_MULTI_FACTORY_LOADED     = 'RATING_MULTI_FACTORY_LOADED';
export const RATING_CARGO_TRANSPORTER_LOADED = 'RATING_CARGO_TRANSPORTER_LOADED';
export const RATING_FORECAST_RATING_LOADED   = 'RATING_FORECAST_RATING_LOADED';

// calendar entry
export const CALENDAR_ENTRY_CALENDAR_ENTRY_TYPES_LOADED = 'CALENDAR_ENTRY_CALENDAR_ENTRY_TYPES_LOADED';
export const CALENDAR_ENTRY_CALENDAR_ENTRY_TYPE_UPDATED = 'CALENDAR_ENTRY_CALENDAR_ENTRY_TYPE_UPDATED';

// business calendar
export const BUSINESS_CALENDAR_LOADED = 'BUSINESS_CALENDAR_LOADED';

// reports
export const REPORT_REPORT_CHARTS_LOADED  = 'REPORT_REPORT_CHARTS_LOADED';
export const REPORT_REPORT_TYPE_UPDATED   = 'REPORT_REPORT_TYPE_UPDATED';
export const REPORT_MOUNTED               = 'REPORT_MOUNTED';
export const REPORT_UNMOUNTED             = 'REPORT_UNMOUNTED';
export const REPORT_LOADED                = 'REPORT_LOADED';
export const REPORT_CHART_ADDED           = 'REPORT_CHART_ADDED';
export const REPORT_CHART_UPDATED         = 'REPORT_CHART_UPDATED';
export const REPORT_CHART_DELETED         = 'REPORT_CHART_DELETED';
export const REPORT_EXCEL_FILE_ID_UPDATED = 'REPORT_EXCEL_FILE_ID_UPDATED';

// search
export const SEARCH_LOADED         = 'SEARCH_LOADED';
export const SEARCH_MOUNTED        = 'SEARCH_MOUNTED';
export const SEARCH_UNMOUNTED      = 'SEARCH_UNMOUNTED';
export const SEARCH_RESULTS_LOADED = 'SEARCH_RESULTS_LOADED';
export const SEARCH_TEXT_UPDATED   = 'SEARCH_TEXT_UPDATED';

// bidding
export const BIDDING_BIDDING_BETS_LOADED = 'BIDDING_BIDDING_BETS_LOADED';


// driver monitoring
export const DRIVER_MONITORING_SELECTED_ROW = "DRIVER_MONITORING_SELECTED_ROW";
export const DRIVER_MONITORING_DELIVERIES_LOADED = "DRIVER_MONITORING_DELIVERIES_LOADED";
export const DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED = "DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED";
export const DRIVER_MONITORING_DRIVERS_LOADED = "DRIVER_MONITORING_DRIVERS_LOADED";
export const DRIVER_MONITORING_DRIVERS_SELECTED = "DRIVER_MONITORING_DRIVERS_SELECTED";
export const DRIVER_MONITORING_FILTERS_CHANGED = "DRIVER_MONITORING_FILTERS_CHANGED";

export const DRIVER_MONITORING_MOUNTED = "DRIVER_MONITORING_MOUNTED";
export const DRIVER_MONITORING_UNMOUNTED = "DRIVER_MONITORING_UNMOUNTED";
