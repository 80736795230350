import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import * as React from 'react';
import { CargoTransporterRouteRateDTO } from '../../../../Domain/DTO/CargoTransporterRouteRateDTO';
import { CargoTransporterRouteRateTableDTO } from '../../../../Domain/DTO/CargoTransporterRouteRateTableDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { ZoneTableDTO } from '../../../../Domain/DTO/ZoneTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableAction, IVolmaTableCellRendererParams } from '../../../Table/VolmaTable/IVolmaTableProps';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import VolmaNumber from '../../../VolmaNumber';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaSelect from '../../../VolmaSelect';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { CargoTransporterRouteRateActions } from './CargoTransporterRouteRateActions';
import { CargoTransporterRouteRateReducer } from './CargoTransporterRouteRateReducer';
import { CargoTransporterRouteRateHelperProps, InitialCargoTransporterRouteRateHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { CargoRouteRateTableReducer } from './Table/CargoRouteRateTableReducer';
import { IReducePayloaded } from '../../../../Infrastructure/Reducer/IReducer';
import { ICargoRouteRateTableHelperProps } from './Table/ITableHelperProps';
import { CargoRouteRateTableActions } from './Table/CargoRouteRateTableActions';
import { RouteRateTableDTO } from '../../../../Domain/DTO/RouteRateTableDTO';
import { ECargoTransporterRouteRateEditingEntity } from './Table/EEditingEntity';
import { VolmaTableRouteRateCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableRouteRateCellRenderers';


@injectable()
export class CargoTransporterRouteRateEntityService extends BaseEntityService<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: CargoTransporterRouteRateReducer;
    private _tableReducer: CargoRouteRateTableReducer;
    private _action: CargoTransporterRouteRateActions;
    private _tableAction: CargoRouteRateTableActions;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._reducer = VolmaContainer.get<CargoTransporterRouteRateReducer>(Types.CargoTransporterRouteRateReducer);
        this._tableReducer = VolmaContainer.get<CargoRouteRateTableReducer>(Types.CargoRouteRateTableReducer);
        this._action = VolmaContainer.get<CargoTransporterRouteRateActions>(Types.CargoTransporterRouteRateActions);
        this._tableAction = VolmaContainer.get<CargoRouteRateTableActions>(Types.CargoRouteRateTableActions);

        this.GetRateCellText                         = this.GetRateCellText.bind(this);
        this.GetCargoTransporterRateCellText         = this.GetCargoTransporterRateCellText.bind(this);
        this.GetCargoTransporterVehicleCountCellText = this.GetCargoTransporterVehicleCountCellText.bind(this);
        this.StartVehicleCountEditing                = this.StartVehicleCountEditing.bind(this);
        this.StartRateEditing                        = this.StartRateEditing.bind(this);
        this.EndEditing                              = this.EndEditing.bind(this);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.CargoTransporterRouteRate]
    }

    public GetTableEntity(): EEntityType {
        return EEntityType.RouteRate;
    }

    public GetDisableItemEdit(): boolean {
        return true;
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CargoTransporterRouteRate,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.Id)},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.LoadingPointName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.LoadingPoint,
                    FilterKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.LoadingPointId),
                    CellRenderer: VolmaTableRouteRateCellRenderers.RouteLoadingPointCellRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.TargetZoneName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.Zone,
                    FilterKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.ZoneId),
                    CellRenderer: VolmaTableRouteRateCellRenderers.RouteZoneCellRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.Rate),
                    CellTextGetter: this.GetRateCellText,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer
                },
                {
                    DataKey: "CargoTransporterRate",
                    CellTextGetter: this.GetCargoTransporterRateCellText,
                    IsSortable: false,
                    CellRenderer: (props: IVolmaTableProps<ICargoRouteRateTableHelperProps>, params: IVolmaTableCellRendererParams) => {
                        return VolmaTableRouteRateCellRenderers.EditableCargoRateCellRenderer(
                            props,
                            params,
                            ECargoTransporterRouteRateEditingEntity.Rate,
                            this._authService.GetCurrentUser().CargoTransporterId,
                            this.StartRateEditing,
                            this.EndEditing
                        );
                    }
                },
                {
                    DataKey: "CargoTransporterVehicleCount",
                    IsSortable: false,
                    CellTextGetter: this.GetCargoTransporterVehicleCountCellText,
                    CellRenderer: (props: IVolmaTableProps<ICargoRouteRateTableHelperProps>, params: IVolmaTableCellRendererParams) => {
                        return VolmaTableRouteRateCellRenderers.EditableCargoRateCellRenderer(
                            props,
                            params,
                            ECargoTransporterRouteRateEditingEntity.VehicleCount,
                            this._authService.GetCurrentUser().CargoTransporterId,
                            this.StartVehicleCountEditing,
                            this.EndEditing
                        )
                    }
                },
            ]
        );
    }

    public IsEditable(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): boolean {
        return false;
    }

    public GetTableCanAddEntity(entity: EEntityType): boolean {
        return false;
    }

    public GetTableReducer(): IReducePayloaded<IVolmaTableProps<ICargoRouteRateTableHelperProps>> {
        return this._tableReducer;
    }
    public OnBeforeTableDataLoaded(props: IVolmaTableProps<ICargoRouteRateTableHelperProps>): void {
        this._dispatch = props.dispatch;
        this._dispatch(this._tableAction.LoadCargoTransporterRouteRates());
    }

    public GetEditorModal(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {

        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.GetMainInfoBlock(props))}
                    {this.GetMainInfoNoTitleItem(false, true, this.GetRateInfoBlock(props))}
                    {this.GetMainInfoNoTitleItem(true, false, this.GetCargoTransporterBlock(props))}
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        if (this.IsEditable(props))
            return this.GetRouteRateEditor(props);
        return this.GetReadonlyRouteRateEditor(props);
    }

    public InitializeEntity(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>){
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialCargoTransporterRouteRateHelperProps;
    }

    public OnAfterDataLoaded(dto: CargoTransporterRouteRateDTO): void {
    }

    private GetRouteRateEditor(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporterrouterate:MainInfoTitle"), i18next.t("cargotransporterrouterate:MainInfoTitleDescription"), this.GetMainInfoBlock(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.GetRateInfoBlock(props))}
                        {this._authService.IsAdministrator() && this.GetMainInfoNoTitleItem(true, false, this.GetCargoTransporterBlock(props))}
                    </div>
                </div>
            </div>
        );
    }

    private GetReadonlyRouteRateEditor(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporterrouterate:MainInfoTitle"), i18next.t("cargotransporterrouterate:MainInfoTitleDescription"), this.GetMainInfoBlock(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.GetRateInfoBlock(props))}
                    </div>
                </div>
            </div>
        );
    }

    private GetMainInfoBlock(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransporterRouteRateDTO) => val.LoadingPointId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: CargoTransporterRouteRateDTO) => x.LoadingPointId)}
                            Label={i18next.t('cargotransporterrouterate:LoadingPointId')}
                            Placeholder={i18next.t('cargotransporterrouterate:SelectLoadingPoint')}
                            Value={props.DataDTO.LoadingPointId}
                            Entity={EEntityType.LoadingPoint}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransporterRouteRateDTO) => val.TargetZoneId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: CargoTransporterRouteRateDTO) => x.TargetZoneId)}
                            Label={i18next.t('cargotransporterrouterate:TargetZoneId')}
                            Placeholder={i18next.t('cargotransporterrouterate:SelectTargetZone')}
                            Value={props.DataDTO.TargetZoneId}
                            Entity={EEntityType.Zone}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetRateInfoBlock(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterRouteRateDTO) => val.Rate) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterRouteRateDTO) => val.Rate)}
                            Label={i18next.t('cargotransporterrouterate:Rate')}
                            Value={props.DataDTO.Rate}
                            MinValue={() => 0}
                            Step={100}
                            Unit={i18next.t('common:rub')}
                            Validator={this._numberValidator}
                            Readonly={!isEditable}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterRouteRateDTO) => val.VehicleCount) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterRouteRateDTO) => val.VehicleCount)}
                            Label={i18next.t('cargotransporterrouterate:VehicleCount')}
                            Value={props.DataDTO.VehicleCount}
                            MinValue={() => 0}
                            Step={1}
                            Unit={i18next.t('common:pcs')}
                            Validator={this._numberValidator}
                            Readonly={!isEditable}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    private GetCargoTransporterBlock(props: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransporterRouteRateDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: CargoTransporterRouteRateDTO) => x.CargoTransporterId)}
                            Label={i18next.t('cargotransporterrouterate:CargoTransporterId')}
                            Placeholder={i18next.t('cargotransporterrouterate:SelectCargoTransporter')}
                            Value={props.DataDTO.CargoTransporterId}
                            Entity={EEntityType.CargoTransporter}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetRateCellText(props: IVolmaTableProps<{}>, rowIndex: number) {
        let dto = props.Data[rowIndex] as RouteRateTableDTO;

        return this.GetSafeMoney(dto.Rate);
    }

    private GetCargoTransporterRateCellText(props: IVolmaTableProps<ICargoRouteRateTableHelperProps>, rowIndex: number) {
        let dto = props.Data[rowIndex] as RouteRateTableDTO;

        let cargoDTO: CargoTransporterRouteRateTableDTO;
        if (props.DataHelper.RouteRates !== undefined)
            cargoDTO = props.DataHelper.RouteRates.find(x => x.LoadingPointId === dto.LoadingPointId && x.TargetZoneId === dto.ZoneId);

        return this.GetSafeMoney(PropertyHelper.SafeGetValue(cargoDTO, x =>x.Rate));
    }

    private GetCargoTransporterVehicleCountCellText(props: IVolmaTableProps<ICargoRouteRateTableHelperProps>, rowIndex: number) {
        let dto = props.Data[rowIndex] as RouteRateTableDTO;

        let cargoDTO: CargoTransporterRouteRateTableDTO;
        if (props.DataHelper.RouteRates !== undefined)
            cargoDTO = props.DataHelper.RouteRates.find(x => x.LoadingPointId === dto.LoadingPointId && x.TargetZoneId === dto.ZoneId);

        return this.GetSafeString(PropertyHelper.SafeGetValue(cargoDTO, x => x.VehicleCount));
    }

    private StartVehicleCountEditing(tableDTO: CargoTransporterRouteRateTableDTO){
        this._dispatch(this._tableAction.EditVehicleCount(tableDTO));
    }

    private StartRateEditing(tableDTO: CargoTransporterRouteRateTableDTO){
        this._dispatch(this._tableAction.EditRate(tableDTO));
    }

    private EndEditing(dto: CargoTransporterRouteRateTableDTO, isValid: boolean){
        if(isValid)
            this._dispatch(this._tableAction.SaveData(dto));
        this._dispatch(this._tableAction.EndEditing());
    }
}