import * as React from 'react';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { EmptyValidator } from '../../Infrastructure/Validation/EmptyValidator';
import { IVolmaInputProps } from './IVolmaInputProps';
import { VolmaInputActions } from './VolmaInputActions';
import { VolmaInputReducer } from './VolmaInputReducer';
import { Component } from 'react';
import { VolmaInputValidator } from '../../Infrastructure/Validation/VolmaInputValidatorValidator';

class VolmaInput extends Component<IVolmaInputProps, {}> {
    private _defaultValidator: EmptyValidator;
    private _inputReducer;
    private _inputEl;

    private _defaultInput = volmaBlock('volma-input');
    private _borderInput = volmaBlock('input-border');
    private _defaultPlaceholder = volmaBlock('volma-placeholder');
    private _defaultInputError = volmaBlock('default-input-error');
    private _input = volmaBlock('input');

    public props: IVolmaInputProps;

    private _inputActions: VolmaInputActions;

    constructor(props: IVolmaInputProps, context: any) {
        super(props, context);

        this._inputActions = VolmaContainer.get<VolmaInputActions>(Types.VolmaInputActions);
        this._defaultValidator = VolmaContainer.get<EmptyValidator>(Types.EmptyValidator);
        this._defaultValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._inputReducer = VolmaContainer.get<VolmaInputReducer>(Types.VolmaInputReducer);

        this.OnValueChange = this.OnValueChange.bind(this);
        this.OnFocus = this.OnFocus.bind(this);
        this.OnBlur = this.OnBlur.bind(this);
        this.OnBorderClick = this.OnBorderClick.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
    }

    public shouldComponentUpdate(nextProps: IVolmaInputProps, nextState: any) {
        const shouldUpdate = nextProps.Value !== this.props.Value ||
            nextProps.Disabled !== this.props.Disabled ||
            nextProps.IsTouched !== this.props.IsTouched ||
            nextProps.IsSubmitted !== this.props.IsSubmitted ||
            nextProps.ErrorMessage !== this.props.ErrorMessage ||
            nextProps.IsValid !== this.props.IsValid ||
            nextProps.Autofocus !== this.props.Autofocus ||
            nextProps.IsActive !== this.props.IsActive;
        return shouldUpdate;
    }


    public render() {

        let errors = (!this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted)) ?
            <div className={(this._defaultInputError()).toString()}>
                <div className={(this._defaultInputError("text")).toString()}>{this.props.ErrorMessage}</div>
            </div>
            :
            undefined;

        let isErrorShown = errors !== undefined;
        let isTextarea = this.props.Type === "textarea";
        let currentInput = this.props.IsInHeader ? this._borderInput : this._defaultInput;
        let errorState = !isTextarea && this.props.IsInHeader ? { mistake: isErrorShown } : { error: isErrorShown }

        let inputProps = {
            ref: (ref) => this._inputEl = ref,
            type: this.props.Type,
            placeholder: this.props.Placeholder,
            value: this.props.Value || '',
            disabled: this.props.Disabled,
            onChange: this.props.OnValueChange || this.OnValueChange,
            onKeyDown: this.OnKeyDown,
            onFocus: this.OnFocus,
            onBlur: this.OnBlur,
            className: this._input(errorState).mix([this.props.InputClassName, currentInput("input", { "no-uppercase": true }), isTextarea ? "textarea" : ""]),
            readOnly: this.props.Readonly === true
        }
        
        if (this.props.Autofocus === true) {
            setTimeout(() => {
                if (this._inputEl !== undefined && this._inputEl !== null) {
                    this._inputEl.focus();
                }
            }, 100);
        }

        let button = <button onClick={(event) => {
            this.props.OnButtonClicked(event);
            if (this._inputEl !== undefined && this._inputEl !== null) {
                this._inputEl.focus();
            }
        }} type="submit" className={(currentInput("btn").mix([this.props.ButtonClass])).toString()}>
            <svg className={(currentInput("btn-ico").mix([this.props.ButtonIcoClass])).toString()}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={this.props.ButtonSvg}></use>
            </svg>
        </button>


        const showButton = this.props.ButtonClass !== undefined && this.props.ButtonIcoClass !== undefined && this.props.ButtonSvg !== undefined;

        const input = !isTextarea ? <input {...inputProps} /> : <textarea  {...inputProps} />
        const charCount = this.GetCharCount(this.props.Value);
        const isActive = this.props.IsActive || (charCount > 0)
        const isCharCounter = this.props.CharCounter === undefined || this.props.CharCounter === true // если не передан, либо true
        const placeholderClassName = this.props.PlaceholderClassName

        return (
            <div key={this.props.Name}>
                <div className={(currentInput(errorState, {button: showButton })).toString()} onClick={this.OnBorderClick}>
                    {!this.props.IsInHeader && <label className={(this._defaultPlaceholder.mix([placeholderClassName, isActive ? "active" : ""])).toString()}>{this.props.Label}</label>}
                    {input}
                    {showButton && button}
                    {isCharCounter && !this.props.IsInHeader && <div className={(currentInput("char-counter")).toString()}>{charCount} / {this.props.MaxLength}</div>}
                    {isCharCounter && this.props.IsInHeader && <span className={(currentInput("desc")).toString()}>{this.props.Label} ({charCount}/{this.props.MaxLength}) {isErrorShown && (" - " + this.props.ErrorMessage)}</span>}
                </div>
                {!this.props.IsInHeader && errors}
            </div>
        )
    }

    public componentDidMount() {
        let props: IVolmaInputProps = {
            Value:  this.props.Value,
            MaxLength: this.props.MaxLength || 255,
            Type: this.props.Type || "text",
            Mask: this.props.Mask,
            IsInHeader: this.props.IsInHeader,
            IsActive: this.GetCharCount(this.props.Value) > 0,
            CustomDataUpdate: this.props.CustomDataUpdate,
            Readonly: this.props.Readonly,
            Required: this.props.Required,
            Disabled: this.props.Disabled,

            ButtonClass: this.props.ButtonClass,
            ButtonIcoClass: this.props.ButtonIcoClass,
            ButtonSvg: this.props.ButtonSvg

        };

        if (!this.props.SkipRegistration)
            this.props.dispatch(this._inputActions.Register(this.props.Name, this._inputReducer, this.props.Validator || this._defaultValidator, props));

        this.props.dispatch(this._inputActions.Validate(this.props.Name));

        if (this.props.DefaultValue !== undefined && (this.props.Value || this.props.DefaultValue) === this.props.DefaultValue) {
            this.props.dispatch(this._inputActions.ChangeValue(this.props.Name, this.props.DefaultValue))
            this.props.dispatch(this._inputActions.Validate(this.props.Name))
        }
    }

    private OnBorderClick() {
        if (this._inputEl !== undefined && this._inputEl !== null)
            this._inputEl.focus();
    }

    private OnValueChange(event: any) {
        this.props.dispatch(this._inputActions.ChangeValue(this.props.Name, event.target.value))
        this.props.dispatch(this._inputActions.Validate(this.props.Name));
    }

    private OnKeyDown(event: React.KeyboardEvent<any>) {
        if (event.keyCode === 13 && event.ctrlKey) {
            if (this.props.OnButtonClicked != undefined && this.props.OnButtonClicked !== null)
                this.props.OnButtonClicked(event as any);
        }
    }

    private OnFocus() {
        if (!this.props.IsActive)
            this.props.dispatch(this._inputActions.SetActive(this.props.Name))
    }

    private OnBlur() {
        if (this.props.Value === undefined || this.props.Value.length === 0)
            this.props.dispatch(this._inputActions.RemoveActive(this.props.Name))

        if (!this.props.IsTouched)
            this.props.dispatch(this._inputActions.Touched(this.props.Name))
    }

    private GetCharCount(value: string): number {
        if (!value || typeof value !== "string")
            return 0;

        return value.length;
    }
}

export default VolmaInput;