import { VolmaContainer } from '../InversifyInject';
import { AuthenticationService } from '../Services/AuthService';
import { LogService } from '../Services/LogService';
import { Types } from '../Types';
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import axios from 'axios';
declare const escape: any;

@injectable()
export class CommonServerInteraction {
    private _logger: LogService;
    private _authService: AuthenticationService;
    private _configOptions: AxiosRequestConfig;
    private _http: AxiosInstance;

    constructor() {
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._logger = VolmaContainer.get<LogService>(Types.LogService);
        this._http = axios.create();
    }

    public Get(url: string, config?: AxiosRequestConfig): AxiosPromise {
        return this._http.get(url, { ...config, ...this.GetAuthHeader() });
    }

    public Post(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise {
        return this._http.post(url, data, { ...config, ...this.GetAuthHeader()});
    }

    public Put(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise {
        return this._http.put(url, data, { ...config, ...this.GetAuthHeader()});
    }

    public Delete(url: string, config?: AxiosRequestConfig): AxiosPromise {
        return this._http.delete(url, { ...config, ...this.GetAuthHeader()});
    }

    public DownloadFileWithParams(url: string, data: any) : Promise<any>{
        return this.DownloadFileInternal(url, 'POST', data);
    }
    public DownloadFileNoParams(url: string) : Promise<any>{
        return this.DownloadFileInternal(url, 'GET', undefined);
    }
    private DownloadFileInternal(url: string, method: string, data: any) : Promise<any>{
        return new Promise((resolve, reject) => {
            var xhr = new XMLHttpRequest();
            xhr.open(method, url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onerror = (ev) => {
                reject(ev);
            }
            xhr.onload = () => {
                
                if (xhr.status === 200) {
                    resolve(xhr); // TODO тут не уверен
                    var filename = decodeURI(xhr.getResponseHeader("X-File-Name")) || "";
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1 && filename === "") {
                        var filenameRegex = /filename[^;=\n]*=((p['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                    }
                    var type = xhr.getResponseHeader('Content-Type');

                    var blob = new Blob([xhr.response], { type: type });
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var URL = window.URL || (window as any).webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        if (filename) {
                            // use HTML5 a[download] attribute to specify filename
                            var a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                (window as any).location = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            (window as any).location = downloadUrl;
                        }

                        setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                    }
                }
                else {

                    let dataString = decodeURIComponent(escape(String.fromCharCode.apply(null, new Uint8Array(xhr.response))));
                    try{
                        data = JSON.parse(dataString);
                    }
                    catch(error){console.warn(error)};
                    reject({response: {
                        data: data,
                        status: xhr.status,
                        config: {
                            url: url
                        }
                    }});
                }
            };
            xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
            if(data !== undefined)
                xhr.send(data);
            else
                xhr.send();
        });
    }

    private GetAuthHeader(): AxiosRequestConfig {
        // if (this._authService.IsAuthenticated())
        //     return {
        //         headers: "Authorization : Bearer " + this._authService.GetAuthenticationToken()
        //     }
        return {};
    }
}