import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { DeliveryHelperProps } from '../IHelperProps';

export class Created extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusCreatedCurrent";
    public TitleFinished: string = "delivery:StatusCreatedFinished";
    public TitleFuture  : string = "delivery:StatusCreatedFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO) {
        return false;
    }

    public IsFinished(dto: DeliveryDTO) {
        return true;
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService) {
        return [];
    }
}