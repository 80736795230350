import { injectable } from 'inversify';
import { FooterNavLink } from '../Footer/IFooterProps';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VOLMA_ENTITY_FOOTER_NAV_ADDED, VOLMA_ENTITY_INFO_LINE_TOGGLED } from '../../Constants/AppConstants';

@injectable()
export class MainInfoItemActions {

    public FooterLinkAdded(item: FooterNavLink): IActionPayloaded<FooterNavLink>{
        return { type: VOLMA_ENTITY_FOOTER_NAV_ADDED, Payload: item};
    }

    public ToggleInfoLine(index: number): IActionPayloaded<number>{
        return { type: VOLMA_ENTITY_INFO_LINE_TOGGLED, Payload: index}
    }
}