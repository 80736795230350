import { VolmaContainer } from '../InversifyInject';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { AxiosPromise } from 'axios';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { TenderCancelDTO } from '../../Domain/DTO/TenderCancelDTO';


@injectable()
export class TenderServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public AddBet(dto: TenderBetDTO): AxiosPromise {
        return this.Post(this._urlFabric.TenderBet(ECQRSApi.Write), dto);
    }

    public Withdraw(id: string, dto: TenderCancelDTO): AxiosPromise {
        return this.Post(this._urlFabric.TenderWithdraw(ECQRSApi.Write, id), dto);
    }

    public Publish(id: string): AxiosPromise {
        return this.Post(this._urlFabric.TenderPublish(ECQRSApi.Write, id));
    }

    public Import(id: string): AxiosPromise {
        return this.Post(this._urlFabric.TenderImport(ECQRSApi.Write, id));
    }

    public ImportExcel(id: string): AxiosPromise {
        return this.Post(this._urlFabric.TenderImportExcel(ECQRSApi.Write, id));
    }
}