import * as React from 'react';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaTimeValidator } from '../../Infrastructure/Validation/VolmaTimeValidator';
import { Component } from 'react';
import * as moment from 'moment';
import { IVolmaTimeTimeDTOProps } from './IVolmaTimeTimeDTOProps';
import { VolmaTimeTimeDTOValidator } from '../../Infrastructure/Validation/VolmaTimeTimeDTOValidator';
import { VolmaTimeTimeDTOReducer } from './VolmaTimeTimeDTOReducer';
import { VolmaTimeTimeDTOActions } from './VolmaTimeTimeDTOActions';

export class VolmaTimeTimeDTO extends Component<IVolmaTimeTimeDTOProps, {}> {
    private _validator: BaseValidator<IVolmaTimeTimeDTOProps>;
    private _defaultValidator: VolmaTimeTimeDTOValidator;
    private _timeReducer;

    private _hoursUpTimer: any;
    private _hoursDownTimer: any;
    private _minutesUpTimer: any;
    private _minutesDownTimer: any;
    private _minutesStepTimer: any;

    private _timerIntervalMs: any = 150;
    private _timerStepIntervalMs: any = 250;
    private _minutesStep = 1;
    private _muteMinutesKeyEvent: boolean = false;
    // private _muteHoursKeyEvent: boolean = false;

    private _timeActions: VolmaTimeTimeDTOActions;

    private _defaultInput      = volmaBlock("volma-input");
    private _numberNav         = volmaBlock("number-nav");
    private _defaultInputError = volmaBlock('default-input-error');
    private _volmaTime         = volmaBlock('volma-time');

    constructor(props: IVolmaTimeTimeDTOProps, context: any) {
        super(props, context);

        this._timeActions = VolmaContainer.get<VolmaTimeTimeDTOActions>(Types.VolmaTimeTimeDTOActions);
        this._defaultValidator = VolmaContainer.get<VolmaTimeTimeDTOValidator>(Types.VolmaTimeTimeDTOValidator);
        this._timeReducer = VolmaContainer.get<VolmaTimeTimeDTOReducer>(Types.VolmaTimeTimeDTOReducer);

        this.handleHoursChange = this.handleHoursChange.bind(this);
        this.handleMinutesChange = this.handleMinutesChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.OnUpHours = this.OnUpHours.bind(this);
        this.OnDownHours = this.OnDownHours.bind(this);
        this.OnUpMinutes = this.OnUpMinutes.bind(this);
        this.OnDownMinutes = this.OnDownMinutes.bind(this);
        this.IncreaseMinutesStep = this.IncreaseMinutesStep.bind(this);
    }

    private GetHoursValue(isNegative: boolean, hours: number): string
    {
        return isNegative ? "-"+ hours : hours.toString();
    }
    render() {
        let errors = (!this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted)) ?
            <div className={(this._defaultInputError()).toString()}>
                <div className={(this._defaultInputError("text")).toString()}>{this.props.ErrorMessage}</div>
            </div>
            :
            undefined;

        var hoursValue = this.props.Value === undefined ?
                                                undefined :
                                                this.GetHoursValue(this.props.Value.IsNegative, this.props.Value.Hours);
        let hoursProps = {
            type: "text",
            placeholder: "12",
            value: hoursValue,
            onChange: this.handleHoursChange,
            onBlur: this.handleFocus,
            className: this._defaultInput("input", { center: true }).mix(["input", "input-number"]),
            readOnly: this.props.Readonly === true
        }

        let minutesProps = {
            type: "text",
            placeholder: "00",
            value: this.props.Value === undefined ? undefined : this.props.Value.Minutes,
            onChange: this.handleMinutesChange,
            onBlur: this.handleFocus,
            className: this._defaultInput("input", { center: true }).mix(["input", "input-number"]),
            readOnly: this.props.Readonly === true
        }
        if(this.props.Readonly === true)
            return this.GetReadOnlyEditor(hoursProps, minutesProps, errors);
        return this.GetEditableEditor(hoursProps, minutesProps, errors);
    }

    private GetEditableEditor(hoursProps: any, minutesProps: any, errors: JSX.Element): JSX.Element{
        return (
            <div>
                <div className={(this._volmaTime()).toString()}>
                    <div className={(this._defaultInput.mix(this._volmaTime("text"))).toString()}>
                        <span className={(this._defaultInput("input", {text: true }).mix(["input"])).toString()}>{this.props.Label}</span>
                    </div>
                    <div className={(this._defaultInput.mix(this._volmaTime("hours"))).toString()}>
                        <input
                            {...hoursProps}
                            onKeyDown={this.OnHoursKeyPressStart.bind(this)}
                            onKeyUp={this.OnHoursKeyPressEnd.bind(this)} />
                        <div className={(this._numberNav()).toString()}>
                            <div className={(this._numberNav("link", {up: true, readonly: this.props.Readonly === true })).toString()}
                                onMouseDown={this.OnUpHoursStart.bind(this)}
                                onMouseUp={this.OnUpHoursEnd.bind(this)}
                                onMouseLeave={this.OnUpHoursEnd.bind(this)}
                                onClick={this.SkipEvent.bind(this)}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </div>
                            <div className={(this._numberNav("link", {down: true, readonly: this.props.Readonly === true })).toString()}
                                onMouseDown={this.OnDownHoursStart.bind(this)}
                                onMouseUp={this.OnDownHoursEnd.bind(this)}
                                onMouseLeave={this.OnDownHoursEnd.bind(this)}
                                onClick={this.SkipEvent.bind(this)}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg >
                            </div >
                        </div>
                    </div>
                    <div className={(this._defaultInput.mix(this._volmaTime("minutes"))).toString()}>
                        <input
                            {...minutesProps}
                            onKeyDown={this.OnMinutesKeyPressStart.bind(this)}
                            onKeyUp={this.OnMinutesKeyPressEnd.bind(this)} />
                        <div className={(this._numberNav()).toString()}>
                            <div className={(this._numberNav("link", {up: true, readonly: this.props.Readonly === true })).toString()}
                                onMouseDown={this.OnUpMinutesStart.bind(this)}
                                onMouseUp={this.OnUpMinutesEnd.bind(this)}
                                onMouseLeave={this.OnUpMinutesEnd.bind(this)}
                                onClick={this.SkipEvent.bind(this)}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </div>
                            <div className={(this._numberNav("link", {down: true, readonly: this.props.Readonly === true  })).toString()}
                                onMouseDown={this.OnDownMinutesStart.bind(this)}
                                onMouseUp={this.OnDownMinutesEnd.bind(this)}
                                onMouseLeave={this.OnDownMinutesEnd.bind(this)}
                                onClick={this.SkipEvent.bind(this)}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg >
                            </div >
                        </div>
                    </div>
                </div>
                {errors}
            </div>
        );
    }

    private GetReadOnlyEditor(hoursProps: any, minutesProps: any, errors: JSX.Element): JSX.Element{
        return (
            <div>
                <div className={(this._volmaTime()).toString()}>
                    <div className={(this._defaultInput.mix(this._volmaTime("text"))).toString()}>
                        <span className={(this._defaultInput("input", {text: true }).mix(["input"])).toString()}>{this.props.Label}</span>
                    </div>
                    <div className={(this._defaultInput.mix(this._volmaTime("hours"))).toString()}>
                        <input {...hoursProps}/>
                        <div className={(this._numberNav()).toString()}>
                            <div className={(this._numberNav("link", {up: true, readonly: this.props.Readonly === true })).toString()}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </div>
                            <div className={(this._numberNav("link", {down: true, readonly: this.props.Readonly === true })).toString()}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg >
                            </div >
                        </div>
                    </div>
                    <div className={(this._defaultInput.mix(this._volmaTime("minutes"))).toString()}>
                        <input {...minutesProps}/>
                        <div className={(this._numberNav()).toString()}>
                            <div className={(this._numberNav("link", {up: true, readonly: this.props.Readonly === true })).toString()}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </div>
                            <div className={(this._numberNav("link", {down: true, readonly: this.props.Readonly === true  })).toString()}>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                                <svg className={this._numberNav("link-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg >
                            </div >
                        </div>
                    </div>
                </div>
                {errors}
            </div>
        );
    }

    public componentDidMount() {
        let props: IVolmaTimeTimeDTOProps = {
            Label: this.props.Label,
            Value: this.props.Value,
            CustomDataUpdate: this.props.CustomDataUpdate,
            Required: this.props.Required,
            Disabled: this.props.Disabled,
            CanBeNegative: this.props.CanBeNegative === undefined ? false : this.props.CanBeNegative,
            Readonly: this.props.Readonly
        }

        this.props.dispatch(this._timeActions.Register(this.props.Name, this._timeReducer, this.props.Validator || this._defaultValidator, props));
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    public componentWillUnmount() {
        clearInterval(this._hoursUpTimer);
        clearInterval(this._hoursDownTimer);
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
    }

    private handleHoursChange(event: any) {
        this.props.dispatch(this._timeActions.ChangeHoursValue(this.props.Name, this.props.Value, event.target.value, this.props.CanBeNegative))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private handleMinutesChange(event: any) {
        this.props.dispatch(this._timeActions.ChangeMinutesValue(this.props.Name, this.props.Value, event.target.value, this.props.CanBeNegative))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private handleFocus() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
    }

    private handleBlur() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnHoursKeyPressStart(event: KeyboardEvent) {
        if (event.keyCode === 38) // up key
            this.OnUpHoursStart(event);
        if (event.keyCode === 40) // down key
            this.OnDownHoursStart(event);
    }

    private OnHoursKeyPressEnd(event: KeyboardEvent) {
        if (event.keyCode === 38) // up key
            this.OnUpHoursEnd(event);
        if (event.keyCode === 40) // down key
            this.OnDownHoursEnd(event);
    }

    // hours up
    private OnUpHoursStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.IncrementHours(this.props.Name, this.props.Value, this.props.CanBeNegative));
        clearInterval(this._hoursUpTimer);
        this._hoursUpTimer = setInterval(this.OnUpHours, this._timerIntervalMs);
    }

    private OnUpHoursEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._hoursUpTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnUpHours() {
        this.props.dispatch(this._timeActions.IncrementHours(this.props.Name, this.props.Value, this.props.CanBeNegative));
    }

    // hours down
    private OnDownHoursStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.DecrementHours(this.props.Name, this.props.Value, this.props.CanBeNegative));
        clearInterval(this._hoursDownTimer);
        this._hoursDownTimer = setInterval(this.OnDownHours, this._timerIntervalMs);
    }

    private OnDownHoursEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._hoursDownTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnDownHours() {
        this.props.dispatch(this._timeActions.DecrementHours(this.props.Name, this.props.Value, this.props.CanBeNegative));
    }

    private OnMinutesKeyPressStart(event: KeyboardEvent) {
        if (!this._muteMinutesKeyEvent && event.keyCode === 38) { // up key
            this._muteMinutesKeyEvent = true;
            // console.log('up pressed')
            this.OnUpMinutesStart(event);
        }
        if (!this._muteMinutesKeyEvent && event.keyCode === 40) { // down key
            this._muteMinutesKeyEvent = true;
            this.OnDownMinutesStart(event);
            // console.log('down pressed')
        }
    }

    private OnMinutesKeyPressEnd(event: KeyboardEvent) {
        if (event.keyCode === 38) { // up key
            // console.log('up released')
            this.OnUpMinutesEnd(event);
            this._muteMinutesKeyEvent = false;
        }
        if (event.keyCode === 40) { // down key
            this.OnDownMinutesEnd(event);
            // console.log('down released')
            this._muteMinutesKeyEvent = false;
        }
    }

    // minutes up
    private OnUpMinutesStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this._minutesStep = 1;
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.IncrementMinutes(this.props.Name, this.props.Value, this._minutesStep, this.props.CanBeNegative));
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesStepTimer);
        this._minutesUpTimer = setInterval(this.OnUpMinutes, this._timerIntervalMs);
        this._minutesStepTimer = setInterval(this.IncreaseMinutesStep, this._timerStepIntervalMs);
    }

    private OnUpMinutesEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._minutesUpTimer);
        clearInterval(this._minutesStepTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private OnUpMinutes() {
        this.props.dispatch(this._timeActions.IncrementMinutes(this.props.Name, this.props.Value, this._minutesStep, this.props.CanBeNegative));
    }

    //minutes down
    private OnDownMinutesStart(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this._minutesStep = 1;
        if (!this.props.IsTouched)
            this.props.dispatch(this._timeActions.Touched(this.props.Name))
        this.props.dispatch(this._timeActions.DecrementMinutes(this.props.Name, this.props.Value, this._minutesStep, this.props.CanBeNegative));
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
        this._minutesDownTimer = setInterval(this.OnDownMinutes, this._timerIntervalMs);
        this._minutesStepTimer = setInterval(this.IncreaseMinutesStep, this._timerStepIntervalMs);
    }

    private OnDownMinutesEnd(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this._minutesDownTimer);
        clearInterval(this._minutesStepTimer);
        this.props.dispatch(this._timeActions.Validate(this.props.Name));
    }

    private SkipEvent(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }

    private OnDownMinutes() {
        this.props.dispatch(this._timeActions.DecrementMinutes(this.props.Name, this.props.Value, this._minutesStep, this.props.CanBeNegative));
    }


    private IncreaseMinutesStep() {
        if (this._minutesStep < 30)
            this._minutesStep++;
    }
}