import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';

export interface ICalendarEntryHelperProps {
    AllCalendarEntryTypes: Array<ListIntOptionDTO>;

    SelectedCalendarEntry: ListIntOptionDTO;
}

export const InitialCalendarEntryHelperProps: ICalendarEntryHelperProps = <ICalendarEntryHelperProps>{
    AllCalendarEntryTypes: [],
    SelectedCalendarEntry: undefined,
};