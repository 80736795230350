import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { SelectionCreateDTO } from '../../Domain/DTO/SelectionCreateDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ESortDir } from '../../Domain/Enum/ESortDir';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { AxiosPromise } from 'axios';


@injectable()
export class TableServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public GetTableData(entity: EEntityType, sortBy: string, sortDir: ESortDir, filter: Array<FilterDTO>, itemsPerPage: number, page: number): AxiosPromise {
        return this.Get(this._urlFabric.TableData(entity, undefined, undefined, sortBy, sortDir, filter, itemsPerPage, page));
    }

    public GetChildTableData(baseEntity: EEntityType, id: string, childEntity: EEntityType, sortBy: string, sortDir: ESortDir, filter: Array<FilterDTO>, itemsPerPage: number, page: number): AxiosPromise {
        return this.Get(this._urlFabric.TableData(baseEntity, id, childEntity,  sortBy, sortDir, filter, itemsPerPage, page));
    }

    public GetEntity(entity: EEntityType, id?: string): AxiosPromise {
        return this.Get(this._urlFabric.Entity(ECQRSApi.Read, entity, id));
    }

    public PostEntity(entity: EEntityType, dto: IEntityDTO): AxiosPromise {
        return this.Post(this._urlFabric.Entity(ECQRSApi.Write, entity), dto);
    }

    public PutEntity(entity: EEntityType, id: string, dto: IEntityDTO): AxiosPromise {
        return this.Put(this._urlFabric.Entity(ECQRSApi.Write, entity, id), dto);
    }

    public PostTableSelectItems(entity: EEntityType, itemIds: SelectionCreateDTO): AxiosPromise {
        return this.Post(this._urlFabric.EntitiesSelection(ECQRSApi.Write, entity), itemIds);
    }

    public DeleteTableSelectedItems(entity: EEntityType, itemId: string): AxiosPromise {
        return this.Delete(this._urlFabric.EntitiesSelection(ECQRSApi.Write, entity, itemId));
    }
}