import { injectable } from "inversify";
import { RATING_TABLE_FORECAST_LOADED } from "../../../../../Constants/AppConstants";
import { RatingTableDTO } from "../../../../../Domain/DTO/RatingTableDTO";
import { IActionPayloaded } from "../../../../../Infrastructure/Action/IAction";
import { IReducePayloaded } from "../../../../../Infrastructure/Reducer/IReducer";
import { IBaseTableProps } from "../../../../Table/BaseTable/IBaseTableProps";
import { IRatingTableHelperProps } from "./ITableHelperProps";

type IState = IBaseTableProps<IRatingTableHelperProps>;
@injectable()
export class RatingTableReducer implements IReducePayloaded<IState> {
    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case RATING_TABLE_FORECAST_LOADED:
                return this.ReduceForecastLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceForecastLoaded(state: IState, payload: Array<RatingTableDTO>): IState {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.ForecastRating = payload;

        return newState;
    }
}
