import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { DeliveryWithdrawAction } from '../Actions/DeliveryWithdrawAction';
import { ManualAssignDeliveriesAction } from '../Actions/ManualAssignDeliveryAction';
import { MarkAsNotExecutedAction } from '../Actions/MarkAsNotExecutedAction';
import { MassAuctionAction } from '../Actions/MassAuctionAction';
import { SynchronizeDeliveryAction } from '../Actions/SynchronizeDeliveryAction';
import { UpdateAssignTypeDeliveriesAction } from '../Actions/UpdateAssignTypeAction';
import { DeliveryHelperProps } from '../IHelperProps';
import { Waiting } from './Waiting';


export class Assignation extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusAssignationCurrent";
    public TitleFinished: string = "delivery:StatusAssignationFinished";
    public TitleFuture  : string = "delivery:StatusAssignationFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.Assignation || dto.State === EDeliveryState.NotAccepted || dto.State === EDeliveryState.NotApproved;
    }

    public IsFinished(dto: DeliveryDTO){
        return !this.IsCurrent(dto) && new Waiting().IsFinished(dto);
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [new DeliveryWithdrawAction(), new ManualAssignDeliveriesAction(), new MassAuctionAction(), new UpdateAssignTypeDeliveriesAction(), new SynchronizeDeliveryAction(), /*new BiddingBetAction(),*/ new MarkAsNotExecutedAction()];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;
        return undefined;
    }

}
