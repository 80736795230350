import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import * as React from 'react';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { NonEmptyTextValidator } from '../../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import { ContractReducer } from './ContractReducer';
import { ContractActions } from './ContractActions';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { DriverDTO } from '../../../../Domain/DTO/DriverDTO';
import { ContractHelperProps, InitialContractHelperProps } from './IHelperProps';
import { BodyTypeTableDTO } from '../../../../Domain/DTO/BodyTypeTableDTO';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaInput from '../../../VolmaInput';
import VolmaNumber from '../../../VolmaNumber';
import VolmaSelect from '../../../VolmaSelect';
import { injectable } from 'inversify';
import VolmaTable from '../../../Table/VolmaTable/index';
import { VehicleEntityService } from '../Vehicle/VehicleEntityService';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import ExperimentalVolmaTable from '../../../Table/VolmaTable/ExperimentalVolmaTable';
import { volmaBlock } from '../../../../Infrastructure/Services/BEM';
import i18next from '../../../i18n';
import { SelectDriverDTO } from '../Vehicle/SelectDriverDialog/SelectDriverDTO';
import { SelectContractDTO } from './SelectContractDialog/SelectContractDTO';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { CARGO_TRANSPORTER_OPEN_HISTORY_REQUIRED } from '../../../../Constants/AppConstants';
import { LocalDeleteItemsAction } from '../../../Table/VolmaTable/Actions/LocalDeleteItemsAction';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { ContractDTO } from '../../../../Domain/DTO/ContractDTO';
import { ContractTableDTO } from '../../../../Domain/DTO/ContractTableDTO';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import VolmaCalendar from '../../../VolmaCalendar/VolmaCalendar';


@injectable()
export class ContractEntityService extends BaseEntityService<ContractDTO, ContractHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _vehicleService: VehicleEntityService;
    private _vehicleColumns: Array<IVolmaTableColumn>;
    private _reducer: ContractReducer;
    private _action: ContractActions;
    private _dispatch: any;
    private _t: any;
    private _modalActions: VolmaModalActions;
    private _currentCargoTransporterId: string;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._reducer = VolmaContainer.get<ContractReducer>(Types.ContractReducer);
        this._action = VolmaContainer.get<ContractActions>(Types.ContractActions);

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

        this._vehicleService = VolmaContainer.get<VehicleEntityService>(Types.VehicleEntityService);
        this._vehicleColumns = this._vehicleService.GetColumnsList();
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Driver]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Contract,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ContractTableDTO) => val.Id) },
                { DataKey: PropertyHelper.GetPropertyName((val: ContractTableDTO) => val.ContractNumber), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: ContractTableDTO) => val.EndTime), HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer, CellRenderer: VolmaTableCommonCellRenderers.OnlyDateCellRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: ContractTableDTO) => val.ConsignorName), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );

    }

    public GetEditorModal(props: IBaseEntityProps<DriverDTO, ContractHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.GetHeaderBlock(props))}
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<DriverDTO, ContractHelperProps>): JSX.Element {

        return null;
    }

    public GetHeaderEditor(props: IBaseEntityProps<DriverDTO, ContractHelperProps>): JSX.Element {
       return null;
    }

    private GetHeaderBlock(props: IBaseEntityProps<ContractDTO, ContractHelperProps>): JSX.Element {
        let isEditable = this._authService.IsShipper();
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ContractDTO) => val.ContractNumber) }
                            Name={PropertyHelper.GetPropertyName((val: ContractDTO) => val.ContractNumber)}
                            Label={i18next.t('contract:ContractNumber')}
                            Value={props.DataDTO.ContractNumber}
                            Validator={this._nonEmptyInputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ContractDTO) => val.EndTime) as any) }
                                Name={PropertyHelper.GetPropertyName((val: ContractDTO) => val.EndTime)}
                                Label={i18next.t('contract:EndTime')}
                                Required={true}
                                Value={props.DataDTO.EndTime}
                                dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ContractDTO) => val.ConsignorId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: ContractDTO) => x.ConsignorId)}
                                Label={i18next.t('contract:ShipperId')}
                                Placeholder={i18next.t('contract:ShipperId')}
                                Required={true}
                                Entity={EEntityType.Consignor}
                                Value={props.DataDTO.ConsignorId}
                                dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<DriverDTO, ContractHelperProps>) {
        this._dispatch = props.dispatch;
        if (props.match.params !== undefined) {
            let entity = props.match.params.entity;
            let id = props.match.params.id;
            if (entity === EEntityType[EEntityType.CargoTransporter].toLowerCase())
                this._currentCargoTransporterId = id;
            else
                this._currentCargoTransporterId = undefined;
        }

        if (this._authService.IsCargoTransporter())
            this._currentCargoTransporterId = this._authService.GetCurrentUser().CargoTransporterId;

    }

    public InitializeDefaultDTOValues(dto: ContractDTO) {
        if (this._currentCargoTransporterId !== undefined && this._currentCargoTransporterId !== dto.CargoTransporterId){
            dto.CargoTransporterId = this._currentCargoTransporterId;
        }
    }

    public GetInitialDataHelper() {
        return InitialContractHelperProps;
    }

    public OnAfterDataLoaded(dto: ContractDTO) {
        return undefined;
    }

    public GetReducer() {
        return this._reducer;
    }
}