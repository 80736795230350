import { ApiMessageLogTableDTO } from '../../../../Domain/DTO/ApiMessageLogTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ITableDTO } from '../../../../Domain/ITableDTO';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { RequestLogActions } from '../RequestLogActions';
import i18next from '../../../i18n';
import { EApiRequestDirection } from '../../../../Domain/Enum/EApiRequestDirection';


export class RepeatRequestAction<THelper> extends VolmaTableAction<THelper> {
    private _modalActions: VolmaModalActions;
    private _actions: RequestLogActions;

    public Title: string = "requestlog:RepeatRequest";

    public IsAvailable(selectedIds: Array<string>, data: Array<ApiMessageLogTableDTO>) {
        if (this._authService.IsAdministrator()) {
            if(selectedIds !== undefined && selectedIds.length === 1){
                let id = selectedIds[0];
                let log = data.find(x => x.Id === id);
                if(log !== undefined){
                    return log.Direction === EApiRequestDirection.Outcoming;
                }
            }
        }
        return false
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ApiMessageLogTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds !== undefined && selectedIds.length > 0 ? selectedIds[0] : undefined;
        if (id !== undefined) {
            let log = data.find(x => x.Id === id);
            if (log !== undefined){
                dispatch(this._modalActions.OpenConfirmationModal("requestlog:AreYouSureYouWantToRepeatRequest", () => {
                    dispatch(this._actions.Repeat(id, i18next.t("common:Loading"),  reloadData));
                }));
            }
        }
    }

    constructor() {
        super();

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._actions = VolmaContainer.get<RequestLogActions>(Types.RequestLogActions);
    }
}