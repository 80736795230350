import { injectable } from 'inversify';
import * as React from 'react';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { MultiCargoTransporterDTO } from '../../../../Domain/DTO/MultiCargoTransporterDTO';
import { MultiCargoTransporterTableDTO } from '../../../../Domain/DTO/MultiCargoTransporterTableDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERating } from '../../../../Domain/Enum/ERating';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import i18next from '../../../i18n';
import VolmaTable from '../../../Table/VolmaTable';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableRatingCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableRatingCellRenderers';
import VolmaInput from '../../../VolmaInput/index';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { InitialMultiCargoTransporterHelperProps, MultiCargoTransporterHelperProps } from './InitialMultiCargoTransporterHelperProps';
import { MultiCargoTransporterActions } from './MultiCargoTransporterActions';
import { MultiCargoTransporterReducer } from './MultiCargoTransporterReducer';


@injectable()
export class MultiCargoTransporterEntityService extends BaseEntityService<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps> {
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _router: RouterService;

    private _reducer: MultiCargoTransporterReducer;
    private _action: MultiCargoTransporterActions;
    private _volmaModalAction: VolmaModalActions;

    private _dispatch: any;
    private _id: string;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._router = VolmaContainer.get<RouterService>(Types.RouterService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._reducer = VolmaContainer.get<MultiCargoTransporterReducer>(Types.MultiCargoTransporterReducer);
        this._action = VolmaContainer.get<MultiCargoTransporterActions>(Types.MultiCargoTransporterActions);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
    }

    public InitializeEntity(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>) {
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialMultiCargoTransporterHelperProps;
    }

    public OnAfterDataLoaded(dto: MultiCargoTransporterDTO): void {
        this._id = dto.Id;

        if (dto.MainCargoTransporter) {
            this._dispatch(this._action.LoadCurrentRatings(dto.MainCargoTransporter, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadForecastRatings(dto.MainCargoTransporter, i18next.t("common:Loading")));
        }
        
        if (dto.CargoTransporterIds.length) {
            this._dispatch(this._action.LoadCargoTransportersByIds(dto.CargoTransporterIds, i18next.t("common:Loading")));
        }
    }


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.MultiCargoTransporter]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.MultiCargoTransporter,
            [
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: MultiCargoTransporterTableDTO) => val.Id)
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: MultiCargoTransporterTableDTO) => val.Name), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer 
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: MultiCargoTransporterTableDTO) => val.CargoTransporters), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, 
                    IsSortable: false,
                    FilterKey: "CargoTransporterNames"
                },
                
            ]
        );
    }

    public GetEditor(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetEditorModal(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("multicargotransporter:CargoTransportersTitle"), i18next.t("multicargotransporter:CargoTransportersTitleDescription"), this.CargoTransportersPart(props), undefined, props, this._dispatch)}
                </div>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, false, 1, i18next.t("multicargotransporter:MainCargoTransporterTitle"), i18next.t("multicargotransporter:MainCargoTransporterTitleDescription"), this.MainCargoTransporterPart(props), undefined, props, this._dispatch)}
                </div>
                {props.DataHelper.CurrentRating.length && <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 2, i18next.t("multicargotransporter:RatingTitle"), i18next.t("multicargotransporter:RatingTitleDescription"), this.RatingPart(props), undefined, props, this._dispatch)}
                </div>}
            </div>
        );
    }    

    public GetHeaderEditor(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._createCard("cell")).toString()}>
                <VolmaInput
                    {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: MultiCargoTransporterDTO) => val.Name) }
                    Name={PropertyHelper.GetPropertyName((val: MultiCargoTransporterDTO) => val.Name)}
                    Label={i18next.t('multicargotransporter:Name')}
                    Placeholder={i18next.t('multicargotransporter:NamePlaceholder')}
                    Value={props.DataDTO.Name}
                    Validator={this._nonEmptyInputValidator}
                    Required={true}
                    IsInHeader={true}
                    Readonly={!isEditable}
                    dispatch={props.dispatch} />
            </div>
        );
    }
    
    private CargoTransportersPart(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: MultiCargoTransporterDTO) => val.CargoTransporterIds)) }
                            Name={PropertyHelper.GetPropertyName((x: MultiCargoTransporterDTO) => x.CargoTransporterIds)}
                            Label={i18next.t('multicargotransporter:CargoTransporters')}
                            Placeholder={i18next.t('multicargotransporter:SelectCargoTransporters')}                            
                            Entity={EEntityType.CargoTransporter}
                            OptionsFilter={(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter || x.MultiCargoTransporterId === props.DataDTO.Id}
                            OnValueChanged={(value: CargoTransporterTableDTO[]) => this.OnCargoTransportersChange(value, props.DataDTO)}
                            Value={props.DataDTO.CargoTransporterIds}
                            AllowMultiselect
                            Readonly={!isEditable}
                            Required                            
                            dispatch={props.dispatch} />
                    </div>
                </div>                
            </div>
        );
    }

    private OnCargoTransportersChange(value: CargoTransporterTableDTO[], dataDto: MultiCargoTransporterDTO): void {
        if (value.length === 0) {
            dataDto.MainCargoTransporter = undefined;
        }

        this._dispatch(this._action.SetSelectedCargoTransporters(value));
    }

    private MainCargoTransporterPart(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>                
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: MultiCargoTransporterDTO) => val.MainCargoTransporter)) }
                            Name={PropertyHelper.GetPropertyName((x: MultiCargoTransporterDTO) => x.MainCargoTransporter)}
                            Label={i18next.t('multicargotransporter:MainCargoTransporter')}
                            Placeholder={i18next.t('multicargotransporter:SelectMainCargoTransporter')}
                            Options={props.DataHelper.SelectedCargoTransporters}
                            LabelKey={PropertyHelper.GetPropertyName((x: CargoTransporterTableDTO) => x.Name)}
                            ValueKey={PropertyHelper.GetPropertyName((x: CargoTransporterTableDTO) => x.Id)}                            
                            Value={props.DataDTO.MainCargoTransporter}
                            Readonly={!isEditable}
                            Required
                            Disabled={!props.DataDTO.CargoTransporterIds || props.DataDTO.CargoTransporterIds?.length === 0}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private RatingPart(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>): JSX.Element {
        let rowEdit = () => null;

        if (this.IsEditable(props))
            rowEdit = ((rowData: RatingTableDTO) => this.OnEditRatingRequired(props, rowData.Id)).bind(this);

        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {..."CurrentRating" }
                            Name={"CurrentRating"}
                            Columns={this.GetRatingColumnsList()}
                            Data={props.DataHelper.CurrentRating}
                            DataOriginal={props.DataHelper.CurrentRatingOriginal}
                            DataHelper={{ForecastRating: props.DataHelper.ForecastRating}}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={rowEdit}
                            Actions={[]}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    protected OnEditRatingRequired(props: IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>, id: string) {
        if (!this.IsEditable(props))
            return;
            
        this._dispatch(this._volmaModalAction.OpenServerEntityModal(
            EEntityType.CurrentRating, 
            id, 
            props.match.params.params, 
            dto => this._dispatch(this._action.RatingEdited(dto)))
        );
    }

    private GetRatingColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CurrentRating,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointName),
                    CellRenderer: (props: IVolmaTableProps<MultiCargoTransporterHelperProps>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.RatingMultiPointNameCellRenderer(props, params,
                        this._router.GetCertainEntityPathname(EEntityType.CargoTransporter, this._id)),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointId),
                    Entity: EEntityType.LoadingPoint
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.RatingValue),
                    CellRenderer: (props: IVolmaTableProps<MultiCargoTransporterHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                {
                    DataKey: "Forecast",
                    CellRenderer: (props: IVolmaTableProps<MultiCargoTransporterHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.ForecastRatingRenderer(this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
            ]
        );
    }
}
