import { DRIVER_VEHICLES_LOADED, DRIVER_VEHICLE_ADDED_TO_DRIVER, DRIVER_VEHICLE_REMOVED_FROM_DRIVER } from '../../../../Constants/AppConstants';
import { DriverDTO } from '../../../../Domain/DTO/DriverDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { ContractHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import { ContractDTO } from '../../../../Domain/DTO/ContractDTO';


@injectable()
export class ContractReducer extends EntityReducer<ContractDTO, ContractHelperProps> {

    public Reduce(state: IBaseEntityProps<ContractDTO, ContractHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<ContractDTO, ContractHelperProps>{
        switch (action.type) {
            default:
                return state;
        }
    }
}
