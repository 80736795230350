import { ComplaintTableDTO } from '../../../../Domain/DTO/ComplaintTableDTO';
import { DeliveryService } from '../../../../Infrastructure/Services/DeliveryService';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableCellRendererParams } from '../../../Table/VolmaTable/IVolmaTableProps';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { injectable } from 'inversify';
import * as React from 'react';
import { VolmaTableDeliveryCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers';


@injectable()
export class DeliveryComplaintEntityService {
    private _volmaTableService: VolmaTableService;
    private _enumService      : EnumService;
    private _routerService    : RouterService;
    private _deliveryService  : DeliveryService;
    private _authService      : AuthenticationService;

    constructor() {
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._enumService       = VolmaContainer.get<EnumService>(Types.EnumService);
        this._routerService     = VolmaContainer.get<RouterService>(Types.RouterService)
        this._deliveryService   = VolmaContainer.get<DeliveryService>(Types.DeliveryService);
        this._authService       = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);

    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if(this._authService.IsShipper())
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Complaint,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.Id)},
                    { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.RegistrationCode),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                    { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.TemplateName),
                        HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                        Entity: EEntityType.ComplaintTemplate,
                        FilterKey: "ComplaintTemplateId",
                        SortKey: "ComplaintTemplateName"
                    },
                    { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.ComplaintState),
                        HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                        FilterEnum: () => EComplaintState,
                        CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                            VolmaTableCommonCellRenderers.EnumCellRenderer(() => EComplaintState, this._enumService, props, cellRendererParams),
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfCreation),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.AcceptedPayment),
                        CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.CargoTransporterName),
                        Entity: EEntityType.CargoTransporter,
                        FilterKey: PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.CargoTransporterId),
                        HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer
                    },
                    { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfLastUpdate),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                ]
            );

        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Complaint,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.RegistrationCode),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellRenderer: (props: IVolmaTableProps<{}>, params: IVolmaTableCellRendererParams) => VolmaTableDeliveryCellRenderers.ComplaintIdentifierLinkCellRenderer(props, params, this._routerService)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.TemplateName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.ComplaintTemplate,
                    FilterKey: "ComplaintTemplateId",
                    SortKey: "ComplaintTemplateName"
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.ComplaintState),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => EComplaintState,
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EComplaintState, this._enumService, props, cellRendererParams),
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfCreation),
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.AcceptedPayment),
                    CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfLastUpdate),
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                },
            ]
        );
    }
}