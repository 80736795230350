import { EntityReducer } from '../../../BaseEntity/EntityReducer';
import { SelectContractHelperProps } from './HelperProps';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { IBaseEntityProps } from '../../../BaseEntity/IBaseEntityProps';
import { SelectContractDTO } from './SelectContractDTO';
import { VehicleTableDTO } from '../../../../../Domain/DTO/VehicleTableDTO';
import { DRIVER_All_CONTRACTS_LOADED } from '../../../../../Constants/AppConstants';

export class SelectContractReducer extends EntityReducer<SelectContractDTO, SelectContractHelperProps>{

    public Reduce(state: IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<SelectContractDTO, SelectContractHelperProps> {
        switch (action.type) {
             case DRIVER_All_CONTRACTS_LOADED:
                return this.ReduceAllContractsLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceAllContractsLoaded(state: IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>, payload: Array<VehicleTableDTO>): IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>{
        if (state.DataDTO.SelectedContractsIds === undefined){
            state.DataDTO.SelectedContractsIds = new Array();
        }
        let filtered = payload.filter(x => state.DataDTO.SelectedContractsIds.indexOf(x.Id) == -1);

        return super.CollectionLoaded<VehicleTableDTO>(state, filtered, [x => x.ContractList]);
    }
}