import { injectable } from 'inversify';
import { DeliveryCommentDTO } from '../../../../Domain/DTO/DeliveryCommentDTO';
import i18next from '../../../i18n';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';

@injectable()
export class DeliveryStateHelper{

    public GetComment(comment: any, delimeter: string): string{
        let isObject: boolean = false;
        let objectComment: any = undefined;
        try {
            objectComment = JSON.parse(comment);
            isObject = true;
        }
        catch (e) { };

        if (isObject && (PropertyHelper.IsObject(objectComment) || PropertyHelper.IsArray(objectComment))) {
            let result = new Array<string>();
            if (objectComment instanceof Array) {
                let erpComments = objectComment as Array<DeliveryCommentDTO>
                for (let erpComment of erpComments) {
                    let fieldName = i18next.t("delivery:" + erpComment.FieldId);
                    result.push(fieldName + ": " + erpComment.Comment);
                }
            }
            else {
                for (let key in objectComment) {
                    let fieldName = i18next.t("delivery:" + key);
                    result.push(fieldName + ": " + objectComment[key]);
                }
            }

            if(result.length > 0)
                return result.join(delimeter);
            if (comment === "[]")// [] - no comments
                return "";
        }

        return comment;
    }

    public IsCommentCameFromDeliveryUpdate(comment: string): boolean{
        if (!PropertyHelper.IsString(comment) || comment.length < 1)
            return false;
        let isObject: boolean = false;
        let objectComment: any = undefined;
        try {
            objectComment = JSON.parse(comment);
            isObject = true;
        }
        catch (e) { };

        return isObject && (PropertyHelper.IsObject(objectComment) || PropertyHelper.IsArray(objectComment));
    }
}