import {
    VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED,
    VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE,
    VOLMA_TABLE_CLEAR_DATA,
    VOLMA_TABLE_CLEAR_SELECTION,
    VOLMA_TABLE_CLOSE_FILTER,
    VOLMA_TABLE_DATA_LOADED,
    VOLMA_TABLE_DATA_UPDATED,
    VOLMA_TABLE_EDIT_ROW_REQUIRED,
    VOLMA_TABLE_OPEN_FILTER,
    VOLMA_TABLE_REGISTER,
    VOLMA_TABLE_REMOVE_COLUMN_FILTER,
    VOLMA_TABLE_RESET_PAGING,
    VOLMA_TABLE_SCROLLED,
    VOLMA_TABLE_SORT,
    VOLMA_TABLE_TOGGLE_ROW_SELECTION
} from '../../../Constants/AppConstants';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { IReduce } from '../../../Infrastructure/Reducer/IReducer';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import {
    IVolmaTableHeaderRenderProps,
    IVolmaTableSortProps,
} from './IVolmaTableProps';
import {
    ITableRegisterPayload,
    IVolmaTableChangeFilterPayload,
    IVolmaTableCloseFilterPayload,
    IVolmaTableDataPayload,
    IVolmaTableOpenFilterPayload,
    IVolmaTableRemoveColumnFilterPayload,
    IVolmaTableSortPayload,
    IVolmaTableToggleSelectionPayload
} from './Payload';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { IVolmaTableEditRowRequiredPayload, IVolmaTablePayload, IVolmaTableCalendarToggledPayload, IVolmaTableUpdateFilterPayload, IVolmaTableResetPagingPayload } from './Payload';
import { DataTableDTO } from '../../../Domain/DTO/DataTableDTO';
import { SelectionCreateDTO } from '../../../Domain/DTO/SelectionCreateDTO';
import { EFilterType } from '../../../Domain/Enum/EFilterType';
import { VOLMA_TABLE_UPDATE_FILTERS } from '../../../Constants/AppConstants';
import { UrlFabric } from '../../../Infrastructure/ServerInteraction/UrlFabric';
import { TableSignalRServerInteraction } from '../../../Infrastructure/ServerInteraction/TableSignalRServerInteraction';

@injectable()
export class VolmaTableActions {
    protected _api: TableServerInteraction;
    protected _apiSignalR: TableSignalRServerInteraction;
    protected _apiActions: ApiActions;
    protected _urlFabric: UrlFabric;

    constructor() {
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._api = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._apiSignalR = VolmaContainer.get<TableSignalRServerInteraction>(Types.TableSignalRServerInteraction);
        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public LoadData(name: string, entity: EEntityType, text: string, sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>, itemsPerPage?: number, page?: number, dataLoadedPostProcessingAction?: (data: Array<ITableDTO>) => Array<ITableDTO> | undefined) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.GetTableData(entity, sortBy, sortDir, filter, itemsPerPage, page),
                text,
                (response: AxiosResponse, dispatch: any) => {
                    let data: IVolmaTableDataPayload = JSON.parse(response.data);

                    if (dataLoadedPostProcessingAction !== undefined) {
                        const postProcessingData = dataLoadedPostProcessingAction(data.Items);

                        if (postProcessingData !== undefined) {
                            data = { ...data, Items: postProcessingData};
                        }                        
                    }
                    
                    dispatch(this.TableDataLoaded(name, data));                    
                });
        }
    }

    public LoadDataOverSignalR(name: string, entity: EEntityType, text: string, sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>, itemsPerPage?: number, pageNumber?: number) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiSignalR.GetTableData(entity, sortBy, sortDir, filter, itemsPerPage, pageNumber)
                    .then((tableData) => dispatch(this.TableDataLoaded(name, tableData)))
                    .catch((error) => {console.error(error); dispatch(this.LoadData(name, entity, text, sortBy, sortDir, filter, itemsPerPage, pageNumber))}),
                text,
                (response: AxiosResponse, dispatch: any) => {},
            );
        }
    }

    public ClearData(name: string): IActionPayloaded<IVolmaTablePayload> {
        return { type: VOLMA_TABLE_CLEAR_DATA, Payload: { TableName: name}};
    }

    public DeleteItems(name: string, entity: EEntityType, waitingText: string, ids: Array<string>, onItemsDeleted: () => void) {
        let selectedItemIds : SelectionCreateDTO = {
            SelectedIds : ids
        }

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.PostTableSelectItems(entity, selectedItemIds),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = response.data;
                    this._apiActions.DoApiRequest(
                        dispatch,
                        () => this._api.DeleteTableSelectedItems(entity, data.Data),
                        waitingText,
                        (response: AxiosResponse, dispatch: any) => {
                            onItemsDeleted();
                        });
                });
        }
    }

    public UpdateData(name: string, data: Array<ITableDTO>, sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) {
        let adjustedData = this.SortAndFilterData(data, sortBy, sortDir, filter);
        return this.TableDataUpdated(name, adjustedData);
    }

    public OpenFilter(name: string, dataKey: string, filterType: EFilterType): IActionPayloaded<IVolmaTableOpenFilterPayload> {
        return { type: VOLMA_TABLE_OPEN_FILTER, Payload: { TableName: name, FilterColumnName: dataKey, FilterType:  filterType} };
    }

    public CloseFilter(name: string): IActionPayloaded<IVolmaTableCloseFilterPayload> {
        return { type: VOLMA_TABLE_CLOSE_FILTER, Payload: { TableName: name } };
    }

    public ChangeFilterValue(name: string, filterType: EFilterType, value: any, filterColumnName?: string): IActionPayloaded<IVolmaTableChangeFilterPayload> {
        return { type: VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE, Payload: { TableName: name, FilterType: filterType, Value: value, FilterColumnName: filterColumnName } };
    }

    public CalendarOverlayToggled(name: string, filterType: EFilterType, value: boolean): IActionPayloaded<IVolmaTableCalendarToggledPayload> {
        return { type: VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED, Payload: { TableName: name, FilterType: filterType, Value: value } };
    }

    public RemoveColumnFilter(name: string, filterColumnName: string): IActionPayloaded<IVolmaTableRemoveColumnFilterPayload> {
        return { type: VOLMA_TABLE_REMOVE_COLUMN_FILTER, Payload: { TableName: name, FilterColumnName: filterColumnName } };
    }

    public SortData(name: string, props: IVolmaTableSortProps): IActionPayloaded<IVolmaTableSortPayload> {
        return { type: VOLMA_TABLE_SORT, Payload: { TableName: name, SortBy: props.sortBy, SortDirection: props.sortDirection } };
    }

    public ToggleSelection(name: string, id: string, ctrlPressed: boolean, shiftPressed: boolean): IActionPayloaded<IVolmaTableToggleSelectionPayload> {
        return { type: VOLMA_TABLE_TOGGLE_ROW_SELECTION, Payload: { TableName: name, Id: id, CtrlPressed: ctrlPressed, ShiftPressed: shiftPressed } };
    }

    public ClearSelection(name: string): IActionPayloaded<IVolmaTablePayload> {
        return { type: VOLMA_TABLE_CLEAR_SELECTION, Payload: { TableName: name } };
    }

    public EditRowRequired(name: string, id: string): IActionPayloaded<IVolmaTableEditRowRequiredPayload> {
        return { type: VOLMA_TABLE_EDIT_ROW_REQUIRED, Payload: { TableName: name, RowDataId: id } };
    }

    public UpdateFilters(name: string, entityType: EEntityType) : IActionPayloaded<IVolmaTableUpdateFilterPayload>{
        return { type: VOLMA_TABLE_UPDATE_FILTERS, Payload: { TableName : name, Type: entityType} };
    }

    public ResetPaging(name: string, entityType: EEntityType): IActionPayloaded<IVolmaTableResetPagingPayload>{
        return { type: VOLMA_TABLE_RESET_PAGING, Payload: { TableName : name, Type: entityType} };
    }

    public TableDataLoaded(name: string, data: DataTableDTO): IActionPayloaded<IVolmaTableDataPayload> {
        return { type: VOLMA_TABLE_DATA_LOADED, Payload: { TableName: name, Items: data.Items,
            PageNumber: data.PageNumber,
            TotalPages: data.TotalPages,
            ItemsPerPage: data.ItemsPerPage,
            TotalItems: data.TotalItems } };
    }

    public TableDataUpdated(name: string, data: Array<ITableDTO>): IActionPayloaded<IVolmaTableDataPayload> {
        return { type: VOLMA_TABLE_DATA_UPDATED, Payload: { TableName: name, Items: data } };
    }

    public Register(name: string, reducer: IReduce<any>, props: any): IActionPayloaded<ITableRegisterPayload> {
        return { type: VOLMA_TABLE_REGISTER, Payload: { TableName: name, Reducer: reducer, Props: props } };
    }

    private SortAndFilterData(data: Array<ITableDTO>, sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) {
        let newData = data !== undefined ? data.slice(0) : [];

        if (filter !== undefined && filter.length > 0) {
            for (let i = 0; i < filter.length; i++) {
                let filterColumn = filter[i].Key;
                if (filter[i].Type === EFilterType.Text){
                    let filterValue = filter[i].TextValue.toLowerCase();
                    newData = newData.filter((value: ITableDTO) => typeof value[filterColumn] === "string" && value[filterColumn].toLowerCase().indexOf(filterValue) !== -1)
                }
            }
        }

        if (sortBy !== undefined) {
            newData = newData.sort((first: ITableDTO, second: ITableDTO) => {
                if (first[sortBy] < second[sortBy])
                    return sortDir === ESortDir.Asc ? -1 : 1;
                if (first[sortBy] > second[sortBy])
                    return sortDir === ESortDir.Asc ? 1 : -1;
                return 0;
            });
        }

        return newData;
    }
}
