import * as React from 'react';

import { injectable } from 'inversify';
import { BiddingDTO } from '../../../Domain/DTO/BiddingDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../VolmaInput/VolmaInput';
import i18next from '../../i18n';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { BiddingActions } from './BiddingActions';
import { BiddingReducer } from './BiddingReducer';
import { BiddingHelperProps, InitialBiddingHelperProps } from './IHelperProps';
import { Bidding } from '../Tender/Statuses/Bidding';
import VolmaTable from '../../Table/VolmaTable/index';
import { BiddingBetEntityService } from '../Delivery/ActionBet/BiddingBetEntityService';
import { DataDTO } from '../../../Domain/DTO/DataDTO';

@injectable()
export class BiddingEntityService extends BaseEntityService<BiddingDTO, BiddingHelperProps> {

    private _inputValidator: VolmaInputValidator;
    private _reducer: BiddingReducer;
    private _action: BiddingActions;
    private _dispatch: any;

    private _dto: BiddingDTO;
    private _id: string;

    private _prevNoopObject: any;
    private _cachedComponents: Array<JSX.Element>;

    private _biddingBetService: BiddingBetEntityService;
    private _biddingBetColumns: Array<IVolmaTableColumn>;

    constructor() {
        super();

        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._biddingBetService    = VolmaContainer.get<BiddingBetEntityService>(Types.BiddingBetEntityService);
        this._reducer = VolmaContainer.get<BiddingReducer>(Types.BiddingReducer);
        this._action = VolmaContainer.get<BiddingActions>(Types.BiddingActions);

        this._biddingBetColumns = this._biddingBetService.GetColumnsList();
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Bidding];
    }

    public IsEditable(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): boolean {
        return false;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return []
    }

    public GetEditorModal(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): JSX.Element {
        if (this._prevNoopObject !== undefined && this._prevNoopObject !== props.NoopObject) {
            this._prevNoopObject = props.NoopObject;
            return (
                <div className={(this._mainInfo()).toString()}>
                    {this._cachedComponents}
                </div>
            )
        }

        let components = new Array<JSX.Element>();

        let isDark = true;
        let index = 0;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("bidding:ReadonlyMainInfoTitle"), i18next.t("bidding:ReadonlyMainInfoTitleDescription"), this.ReadonlyMainInfoInfoPart(props), undefined, props, this._dispatch)}
            </div>
        );

        index++;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("bidding:BetTitle"), i18next.t("bidding:BetTitleDescription"), this.GetBetsPart(props), undefined, props, this._dispatch)}
            </div>
        );

        return (
            <div className={(this._mainInfo()).toString()}>
                {components}
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): JSX.Element {

        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: BiddingDTO) => val.DeliveryIdentifier)}
                        key={this._id}
                        Name={PropertyHelper.GetPropertyName((val: BiddingDTO) => val.DeliveryIdentifier)}
                        Label={i18next.t('bidding:Identifier')}
                        Placeholder={i18next.t('bidding:IdentifierPlaceholder')}
                        Value={props.DataDTO.DeliveryIdentifier}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={true}
                        dispatch={props.dispatch} />
                </div>

            </div>
        );
    }


    public InitializeEntity(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>) {
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialBiddingHelperProps;
    }

    public OnAfterDataLoaded(dto: BiddingDTO): void {
        this._id = dto.Id;
        this._dto = dto;
        this._prevNoopObject = undefined;
        if (dto.Id !== undefined) {
            this._dispatch(this._action.LoadBets(dto.Id, i18next.t("common:Loading")));
        }
    }

    private ReadonlyMainInfoInfoPart(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): JSX.Element {
       let  biddingStartTime = this._timeService.ConvertToSpecifiedTimezone(PropertyHelper.SafeGetValue(props.DataDTO, x => x.StartBidding), props.DataDTO.Timezone);
       let  biddingEndTime = this._timeService.ConvertToSpecifiedTimezone(PropertyHelper.SafeGetValue(props.DataDTO, x => x.EndBidding), props.DataDTO.Timezone);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { flex50: true }).toString()}>
                        <div className={(this._mainInfoSpecification).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("bidding:StartBiddingDate").toString()}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(biddingStartTime).toString()}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("bidding:EndBiddingDate").toString()}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(biddingEndTime).toString()}</dd>
                                </div>

                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetBetsPart(props: IBaseEntityProps<BiddingDTO, BiddingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: BiddingHelperProps) => val.Bets) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: BiddingHelperProps) => x.Bets)}
                            Columns={this._biddingBetColumns}
                            Data={props.DataHelper.Bets}
                            DataOriginal={props.DataHelper.BetsOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}