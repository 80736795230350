import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import i18next from '../../i18n';
import { BaseCustomLoadSaveComponent } from '../BaseCustomLoadSave/BaseCustomLoadSaveComponent';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ISettingsProps } from './ISettingsProps';
import { SettingsActions } from './SettingsActions';
import { SettingsEntityService } from './SettingsEntityService';

class Settings extends BaseCustomLoadSaveComponent<IBaseEntityProps<SettingsDTO, any>>{
    private _settingsActions: SettingsActions;
    private _settingsEntityService: SettingsEntityService;

    constructor(props: IBaseEntityProps<SettingsDTO, any>, context: any) {
        super(props, context, EEntityType.Settings);

        this._key = "settings";
        this._settingsActions = VolmaContainer.get<SettingsActions>(Types.SettingsActions);
        this._settingsEntityService = VolmaContainer.get<SettingsEntityService>(Types.SettingsEntityService);

        super.SetActions(this._settingsActions);

    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.IsValid) {
            this.props.dispatch(this._settingsActions.SaveSettings(this.props.DataDTO, this.props.IsValid, i18next.t('common:WaitingForTheServer'), ()=> {
                this.props.dispatch(this._modalActions.OpenNotificationModal(i18next.t('common:SettingsSaved')));
                this.props.dispatch(this._settingsActions.UpdateDTOStringify(this.props.DataDTO));
            }))
        }
        else{
            this.props.dispatch(this._settingsActions.Submit());
        }
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();

    }

    public LoadData(){
        this._settingsEntityService.InitializeEntity(this.props);
        this.props.dispatch(this._settingsActions.LoadSettings(i18next.t('common:WaitingForLoadingSettings')))
        this.props.dispatch(this._settingsActions.SetEntityType(EEntityType.Settings));
    }

    public IsEditable(): boolean{
        return this._settingsEntityService.IsEditable(this.props);
    }
}

const translated = withTranslation(['common'])(withRouter(Settings));

function select(state: IGlobalState, ownProps: ISettingsProps): ISettingsProps {
    return state.Settings;
}

let connectedSettingsEntity = connect<ISettingsProps, {}, {}>(select)(translated);
export default connectedSettingsEntity;
