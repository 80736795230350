import { IReducePayloaded } from '../../../Infrastructure/Reducer/IReducer';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { ICommonProps } from '../../Shared/ICommonProps';
import { EIcon } from '../../../Domain/Enum/EIcon';
import { TablePagingDTO } from '../../../Domain/DTO/PagingDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { EntityUpdatedDTO } from '../../../Domain/DTO/EntityUpdatedDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { IVolmaTableToggleSelectionPayload } from './Payload';

export interface IVolmaTableSortProps {
    sortBy?: string;
    sortDirection?: ESortDir;
}


export interface IVolmaTableProps<THelper> extends ICommonProps {
    Name?: string;
    Reducer?: IReducePayloaded<IVolmaTableProps<THelper>>;
    LoadDataFromServer?: boolean;
    HideHeader?:boolean;
    HideActions?:boolean;
    Entity?: EEntityType;
    BaseEntity?: EEntityType;
    IsTableInsideEntity?: boolean; 
    UseRealHeight?: boolean

    IsFilterOpened?: boolean;
    OpenedFilter?: FilterDTO;
    
    Filter?: Array<FilterDTO>;
    SortOptions?: IVolmaTableSortProps;
    Paging?: TablePagingDTO;

    SelectedIds?: Array<string>;
    IsFilterEnabled?: boolean;
    Data?: Array<ITableDTO>;
    DataOriginal?: Array<ITableDTO>;
    DataHelper?: THelper;

    ItemsPerPage?: number;
    Columns?: Array<IVolmaTableColumn>;
    Actions?: Array<IVolmaTableAction<THelper>>;
    OnRowClick?: (name: string, id: string, ctrlPressed: boolean, shiftPressed: boolean) => IActionPayloaded<IVolmaTableToggleSelectionPayload>
    CustomRowRenderer?: (props: IVolmaTableRowRendererParams) => JSX.Element;
    CustomCellRenderer?: (props: IVolmaTableCellRendererParams) => JSX.Element;
    
    OnRowEditRequired?: (rowData: any) => void;
    OnBeforeDataLoaded?: (props: IVolmaTableProps<any>) => void;
    OnAfterDataLoaded?: (data: Array<ITableDTO>) => Array<ITableDTO> | undefined;
    NoopObject?: {};

    AlternateRowClass?: (rowDara:any) => string

    ReloadOnSignalREvent?: boolean;
    DisableItemEdit?: boolean;
    
    Inputs?: Array<IVolmaInputProps>;
    OnEntityUpdated?:(dto: EntityUpdatedDTO, debounce: () => void, dispatchFilteredData:() => void) => void;
}

export interface IVolmaTableAction<THelper>{
    Title:string;
    IsAvailable(selectedIds: Array<string>, data: Array<ITableDTO>, dtoHelper: THelper): boolean;
    OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ITableDTO>, dispatch: any, reloadData: () => void, dateHelper: any): void;
}

export interface IVolmaTableColumn {
    DataKey: string;    
    SortKey: string;
    Label: string;
    Description: string;
    IsSortable: boolean;
    IsFilterable: boolean;
    Icon?: EIcon;
    Weight: number;
    FilterEnum: () => any;
    FilterEntity: EEntityType | EEntityType[];
    FilterKey: string | string[];
    Options: Array<ListIntOptionDTO>,
    OptionsFilter: ((x: any) => boolean) | ((x: any) => boolean)[];
    EntityOptionIcon?: JSX.Element | JSX.Element[];
    CellTextGetter?: (props: IVolmaTableProps<any>, rowIndex: number) => string;
    CellRenderer?: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) => JSX.Element;
    CellHeightMeasurer?: (cellText: string,  width: number) => number;
    HeaderRenderer?: (props: IVolmaTableProps<any>, loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void, headerRenderProps: IVolmaTableHeaderRenderProps) => JSX.Element;
}

export interface IVolmaTableColumnDefinition {
    DataKey: string;
    FilterKey?: string | string[];
    SortKey?: string;
    Icon?: EIcon;
    Weight?: number,
    FilterEnum?: any,
    Entity?: EEntityType | EEntityType[],
    Options?: Array<ListIntOptionDTO>,
    OptionsFilter?: ((x: any) => boolean) | ((x: any) => boolean)[];
    EntityOptionIcon?: JSX.Element | JSX.Element[];
    IsSortable?: boolean,
    IsFilterable?: boolean;
    CellTextGetter?: (props: IVolmaTableProps<any>, rowIndex: number) => string;
    CellRenderer?: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) => JSX.Element;
    CellHeightMeasurer?: (cellText: string, width: number) => number;
    HeaderRenderer?: (props: IVolmaTableProps<any>, loadDataFunc: (sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>) => void, headerRenderProps: IVolmaTableHeaderRenderProps) => JSX.Element;
}

export interface IVolmaTableActions {
    Name: string;
    Actions: Array<((id: string) => void) | ((ids: Array<string>) => void)>
}

export interface IVolmaTableHeaderRenderProps {
    ColumnData: string;
    DataKey: string;
    FilterKey: string | string[];
    SortKey: string;
    IsSortable: boolean;
    IsFilterable: boolean;
    Label: string;
    Description: string;
    SortBy: string;
    SortDirection: string;
    Width: number;
    Height: number;
    FilterEnum: () => any;
    FilterEntity: EEntityType | EEntityType[];
    ScrollbarWidth: number;
    Index: number;
    FilterActive: boolean;
    Options: Array<ListIntOptionDTO>;
    OptionsFilter: ((x: any) => boolean) | ((x: any) => boolean)[];
    EntityOptionIcon?: JSX.Element | JSX.Element[];
}

export interface IVolmaTableRowRendererParams{
    className: string,
    columns: Array<JSX.Element>,
    index: number,
    isScrolling: boolean,
    key: string,
    onRowClick?: (index: number, rowData: any) => void,
    onRowDoubleClick?: (index: number, rowData: any) => void,
    onRowMouseOver?: (index: number, rowData: any) => void,
    onRowMouseOut?: (index: number, rowData: any) => void,
    rowData: ITableDTO,
    style: any,
    a11yProps: any
}

export interface IVolmaTableCellRendererParams{
    cellData?: any,
    columnData?: any,
    dataKey: string,
    additionalDataKey?: string,
    rowData: ITableDTO,
    rowIndex: number,
    cellIndex: number,
    width: number,
    height: number,
    selected: boolean,
}

export interface IVolmaTableCellDataGetterParams{
    columnData: any;
    dataKey: string;
    rowData: any;
}

export const VolmaTablePropsInitial: IVolmaTableProps<any> = {
    Name: undefined,
    LoadDataFromServer: true,
    Entity: undefined,
    IsTableInsideEntity: false,
    IsFilterEnabled: true,
    IsFilterOpened: false,
    OpenedFilter: new FilterDTO(),

    Filter: [],
    SortOptions: {},
    Paging: <any>{ PageNumber: 1 },
    SelectedIds: [],
    Data:[],
    DataHelper: {},
    Columns: [],
    Actions: [],
    CustomRowRenderer: undefined,
    CustomCellRenderer: undefined,
    OnBeforeDataLoaded: () => null,
    OnAfterDataLoaded: () => null,
    DisableItemEdit: false,
    Inputs: [],
};

