import { DeliveryDTO } from "../../../../Domain/DTO/DeliveryDTO";
import { DeliveryStateDTO } from "../../../../Domain/DTO/DeliveryStateDTO";
import { DeliveryTableDTO } from "../../../../Domain/DTO/DeliveryTableDTO";
import { EDeliveryState } from "../../../../Domain/Enum/EDeliveryState";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { TableDataDTO } from "../../../../Domain/TableDataDTO";
import { VolmaContainer } from "../../../../Infrastructure/InversifyInject";
import { FormatService } from "../../../../Infrastructure/Services/FormatService";
import { isNullOrUndefined } from "../../../../Infrastructure/Services/Utils";
import { Types } from "../../../../Infrastructure/Types";
import i18next from "../../../i18n";
import { VolmaTableAction } from "../../../Table/VolmaTable/VolmaTableAction";
import { VolmaModalActions } from "../../../VolmaModal/VolmaModalActions";
import { DeliveryConfirmDTO } from "../ActionConfirm/DeliveryConfirmDTO";
import { Confirmation } from "../Status/Confirmation";
import { WaitingDelivery } from "../Status/WaitingDelivery";
import { DeliveryTableActions } from "../Table/DeliveryTableActions";

export class ChangeCargoTransporterAndConfirmAction<THelper> extends VolmaTableAction<THelper> {
    public Title: string;

    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;
    private _formatService: FormatService;

    private readonly _confirmation = new Confirmation();
    private readonly _waitingDelivery = new WaitingDelivery();

    private readonly _actionTitles = {
        waitingDelivery: "delivery:ChangeCargoTransporter",
        confirmation: "delivery:ConfirmDelivery",
    };

    constructor() {
        super();

        this.Title = this._actionTitles.waitingDelivery;

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
        this._formatService = VolmaContainer.get<FormatService>(Types.FormatService);
    }

    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean {
        if (isNullOrUndefined(selectedIds) || selectedIds.length !== 1) {
            return false;
        }

        const deliveryDTO = data.find((x) => x.Id === selectedIds[0]);

        if (isNullOrUndefined(deliveryDTO)) {
            return false;
        }

        if (this._authService.IsCargoTransporter()) {
            if (this._waitingDelivery.IsCurrent(deliveryDTO) && this.IsMultiCargoTransporterDefined(deliveryDTO)) {
                this.Title = this._actionTitles.waitingDelivery;
                return true;
            }

            if (this._confirmation.IsCurrent(deliveryDTO)) {
                this.Title = this._actionTitles.confirmation;
                return true;
            }
        }

        return false;
    }

    public OnActivate(
        name: string,
        entity: EEntityType,
        selectedIds: Array<string>,
        data: Array<DeliveryTableDTO>,
        dispatch: any,
        reloadData: () => void
    ) {
        const deliveryDTO = data.find((x) => x.Id === selectedIds[0]);

        if (isNullOrUndefined(deliveryDTO)) {
            return;
        }

        const dto = new DeliveryConfirmDTO();

        dto.DriverId = deliveryDTO.AssignedDriverId || (deliveryDTO as DeliveryDTO).DriverId;
        dto.VehicleId = deliveryDTO.AssignedVehicleId || (deliveryDTO as DeliveryDTO).VehicleId;
        dto.ContainerId = (deliveryDTO as DeliveryDTO).ContainerId;

        if (this.IsMultiCargoTransporterDefined(deliveryDTO)) {
            dto.CargoTransporterId = deliveryDTO.CargoTransporterId;
            dto.IsCargoTransporterAvailable = true;
        }

        dispatch(
            this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryConfirm, dto, i18next.t(this.Title), (dto) =>
                this.OnSavedLocalEntityModal(dto, selectedIds, dispatch, reloadData, deliveryDTO)
            )
        );
    }

    private IsMultiCargoTransporterDefined(deliveryDTO: DeliveryTableDTO): boolean {
        return this._formatService.IsValueNotEmpty(deliveryDTO.MultiCargoTransporterId);
    }

    private OnSavedLocalEntityModal(
        dto: DeliveryConfirmDTO,
        selectedIds: Array<string>,
        dispatch: any,
        reloadData: () => void,
        deliveryDTO: DeliveryTableDTO
    ) {
        const tableDataDTO = new TableDataDTO<DeliveryStateDTO>();
        tableDataDTO.Items = new Array<DeliveryStateDTO>();
        tableDataDTO.Count = 0;

        for (const selectedId of selectedIds) {
            const stateDTO = new DeliveryStateDTO();

            stateDTO.DeliveryId = selectedId;
            stateDTO.DriverId = dto.DriverId;
            stateDTO.VehicleId = dto.VehicleId;
            stateDTO.ContainerId = dto.ContainerId;
            stateDTO.DeliveryState = EDeliveryState.Approved;

            if (this.IsMultiCargoTransporterDefined(deliveryDTO)) {
                stateDTO.CargoTransporterId = dto.CargoTransporterId;
            }

            tableDataDTO.Items.push(stateDTO);
            tableDataDTO.Count++;
        }

        dispatch(this._deliveryTableActions.DeliveryAddStates(tableDataDTO, i18next.t("common:Loading"), reloadData));
    }
}
