import * as React from 'react';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { VolmaComponent } from '../../Shared/VolmaComponent';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import Header from '../../Header/index';
import i18next from '../../i18n';
import VolmaForm from '../../VolmaForm/index';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import { SettingsActions } from '../Settings/SettingsActions';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IEntityService } from '../BaseEntity/IEntityService';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import Footer from '../../Footer/Footer';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { SettingsEntityService } from '../Settings/SettingsEntityService';
import { PortalConfigurationEntityService } from '../PortalConfiguration/PortalConfigurationEntityService';

export abstract class BaseCustomLoadSaveComponent<T extends IBaseEntityProps<any, any>> extends VolmaComponent<T> {
    private _authService: AuthenticationService;

    protected _header     = volmaBlock('header');
    protected _createCard = volmaBlock('create-card');
    protected _btn        = volmaBlock('btn');
    protected _mainInfo   = volmaBlock('main-info');
    protected _footer     = volmaBlock('footer');
    protected _content    = volmaBlock('content');

    private _parentDiv: Element;
    private _entityService: IEntityService<any, any>;

    protected _key                : string = "none";
    protected _formClassName      : string = "";
    protected _createCardModifiers: any    = {};

    constructor(props: T, context: any, entityType: EEntityType) {
        super(props, context);

        let entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._entityService = entityService.GetEntityService(entityType);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
    }

    public render() {

        let headerElement = this.props.DataDTO !== undefined ? this._entityService.GetHeaderEditor(this.props) : undefined;
        let editorElement = this.props.DataDTO !== undefined ? this._entityService.GetEditor(this.props) : undefined;
        return (
            <div className="wrapper">
                <header className={(this._header).toString()}>
                    <Header />
                </header>

                <main className={this._content({"footer-active": this.props.FooterOpened}).toString()} >
                    <div className='content-wrap' style={this.props.style}>

                        <VolmaForm {...this.props as any} Ref={((l) => this._parentDiv = l).bind(this)} key={this._key} ClassName={(this._formClassName).toString()}>
                            <div className={(this._createCard(this._createCardModifiers)).toString()}>
                                <div className="container">
                                    <div className={(this._createCard("form")).toString()}>
                                        <div className={this._createCard("top", {"padding-top": true}).toString()}>
                                            <span className={(this._createCard("title")).toString()}>
                                                {headerElement}
                                            </span>
                                            <div className={(this._createCard("buttons")).toString()}>
                                                {/*<button onClick={this.OnCancelClick.bind(this)} className={(this._createCard("link").mix(["btn", "btn-red"])).toString()}>
                                                    <span className={(this._btn("text")).toString()}>{i18next.t("common:ButtonCancel")}</span>
                                                    <svg className={(this._btn("ico").mix("close-ico")).toString()}>
                                                        <use xlinkHref="#close"></use>
                                                    </svg>
                                                </button>*/}
                                                {this.IsEditable() && <button onClick={this.OnSaveClick.bind(this)} className={(this._createCard("link").mix(["btn", "btn-green"])).toString()}>
                                                    <span className={(this._btn("text")).toString()}>{i18next.t("common:ButtonSave")}</span>
                                                    <svg className={this._btn("ico").mix("plus-ico").toString()}>
                                                        <use xlinkHref="#plus"></use>
                                                    </svg>
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {editorElement}
                        </VolmaForm>
                    </div>
                </main>
                {this.GetFooter()}
            </div>
        );
    }

    public abstract OnSaveClick(e: any)
    public abstract OnCancelClick(e: any)
    public abstract LoadData()
    public abstract IsEditable(): boolean;

    private GetFooter() : JSX.Element {
        if (this.props.FooterNavList !== undefined && this.props.FooterNavList.length > 0){
            return (
                <div>
                    <footer className={this._footer({open: true}).mix([this.props.FooterOpened ? "active": ""]).toString()}>
                        <Footer Blocks={this.props.FooterNavList} dispatch={this.props.dispatch} ParentDiv={this._parentDiv}/>
                    </footer>
                </div>);
        }

        return undefined;
    }
}