import { ERestorePasswordResult } from '../../Domain/Enum/ERestorePasswordResult';

export interface IPasswordRestoreHelperProps {
    RestoreResult: ERestorePasswordResult;
    Errors: Array<string>
}

export const InitialPasswordRestoreHelperProps: IPasswordRestoreHelperProps = {
    RestoreResult: undefined,
    Errors: []
}