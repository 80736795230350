import { ComplaintDTO } from "../../../../Domain/DTO/ComplaintDTO";
import { ComplaintTableDTO } from "../../../../Domain/DTO/ComplaintTableDTO";
import { EComplaintState } from "../../../../Domain/Enum/EComplaintState";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { VolmaContainer } from "../../../../Infrastructure/InversifyInject";
import { EnumService } from "../../../../Infrastructure/Services/EnumService";
import PropertyHelper from "../../../../Infrastructure/Services/PropertyHelper";
import { Types } from "../../../../Infrastructure/Types";
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumnDefinition,
    IVolmaTableProps,
} from "../../../Table/VolmaTable/IVolmaTableProps";
import { VolmaTableCommonCellRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers";
import { VolmaTableHeaderRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers";
import { VolmaTableCommonColumnDefinition } from "../../../Table/VolmaTableCommonColumnDefinition";

export class ComplaintColumnTemplates {
    private _enumService: EnumService;

    constructor() {
        this._enumService = VolmaContainer.get<EnumService>(Types.EnumService);
    }

    public get Id(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.Id),
        };
    }

    public get RegistrationCode(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.RegistrationCode),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get TemplateName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.TemplateName),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            Entity: EEntityType.ComplaintTemplate,
            FilterKey: "ComplaintTemplateId",
            SortKey: "ComplaintTemplateName",
        };
    }
    public get ComplaintState(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.ComplaintState),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            FilterEnum: () => EComplaintState,
            CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                VolmaTableCommonCellRenderers.EnumCellRenderer(
                    () => EComplaintState,
                    this._enumService,
                    props,
                    cellRendererParams
                ),
        };
    }

    public get DateOfCreation(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfCreation),
            CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
            HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
        };
    }

    public get DeliveriesIdentifiers(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DeliveriesIdentifiers),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get AcceptedPayment(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.AcceptedPayment),
            CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
        };
    }

    public get CargoTransporterName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.CargoTransporterName),
            Entity: EEntityType.CargoTransporter,
            FilterKey: PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.CargoTransporterId),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
        };
    }

    public get DateOfLastUpdate(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.DateOfLastUpdate),
            CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
            HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
        };
    }

    public get MultiTransporterName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiTransporterColumn({
            DataKey: PropertyHelper.GetPropertyName((val: ComplaintTableDTO) => val.CargoTransporterName),
        });
    }
}
