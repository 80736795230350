import { injectable } from "inversify";
import { DeliveryTableDTO } from "../../../../Domain/DTO/DeliveryTableDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IVolmaTableColumn } from "../../../Table/VolmaTable/IVolmaTableProps";
import { DeliveryEntityService } from "../DeliveryEntityService";

@injectable()
export class DeliveryManualEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
            { ...this._deliveryColumnTemplates.Id },
            { ...this._deliveryColumnTemplates.Identifier },
            { ...this._deliveryColumnTemplates.LoadingDate },
            { ...this._deliveryColumnTemplates.UnloadingDate },
            { ...this._deliveryColumnTemplates.MultiPointName, Weight: 0.7 },
            { ...this._deliveryColumnTemplates.UnloadingPointName },
            { ...this._deliveryColumnTemplates.SalesDepartment },
        ]);
    }

    public GetTableRowClass(rowData: DeliveryTableDTO): string {
        return this._table("row", {
            body: true,
            "bet-exceeded-rate": rowData.IsBetExceededRate === true,
            "bet-contains": rowData.ContainsBet && rowData.IsBetExceededRate === false && this._authService.IsShipper(),
        }).toString();
    }
}
