import { injectable } from "inversify";
import {LogService} from "./LogService";
import {Types} from "../Types";
import {VolmaContainer} from "../InversifyInject";

@injectable()
export class LocalStorageService {
    private _logger: LogService;
    private _isLocalStorageAvailable: boolean;

    constructor() {
        this._logger = VolmaContainer.get<LogService>(Types.LogService);

        this._isLocalStorageAvailable = this.TestLocalstorage();
        //if (this._isLocalStorageAvailable) {
        //    this._logger.Trace("localstorage is available");
        //}
        //else
        //    this._logger.Error("localstorage is not available");
    }

    public IsKeyExist(name: string): boolean {
        if (this.EnsureLocalstorageAvailable()) {
            let value = localStorage.getItem(name);
            if (value !== null)
                return true;
            return false;
        }
        return false;
    }

    public GetKey(name: string): string {
        if (this.EnsureLocalstorageAvailable()) {
            if (this.IsKeyExist(name))
                return localStorage.getItem(name);
        }
        return undefined;
    }

    public SetKey(name: string, value: string): void {
        if (this.EnsureLocalstorageAvailable()) {
            localStorage.setItem(name, value);
        }
    }

    public RemoveKey(name: string): void {
        if (this.EnsureLocalstorageAvailable()) {
            if (this.IsKeyExist(name)) {
                localStorage.removeItem(name);
            }
        }
    }

    private TestLocalstorage() {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    private EnsureLocalstorageAvailable(): boolean {
        if (this._isLocalStorageAvailable) {
            return true;
        }
        this._logger.Error("localstorage not available");
        return false;
    }
}