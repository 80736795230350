import { injectable } from "inversify";
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { COMPLAINT_TEMPLATE_FILE_INFO_LOADED } from '../../../Constants/AppConstants';
import { FileDTO } from '../../../Domain/DTO/FileDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { EComplaintTemplateDirection } from '../../../Domain/Enum/EComplaintTemplateDirection';
import { EntityAction } from '../BaseEntity/EntityAction';

@injectable()
export class ComplaintTemplateActions extends EntityAction{

    public LoadFile(waitingText: string, fileId: string) {
        return super.LoadEntityCached(
            fileId,
            EEntityType.File,
            waitingText,
            this.FileInfoLoaded);
    }

    private FileInfoLoaded(value: FileDTO): IActionPayloaded<FileDTO> {
        return { type: COMPLAINT_TEMPLATE_FILE_INFO_LOADED, Payload: value };
    }
}