import { injectable } from 'inversify';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { CHART_BORDER_WIDTH } from '../../../../Constants/AppConstants';
import { ListOptionDTO } from '../../../../Domain/DTO/ListOptionDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { RatesReportParametersDTO } from '../../../../Domain/DTO/RatesReportParametersDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { SafeMergeArrays } from '../../../../Infrastructure/Services/Utils';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar';
import VolmaSelect from '../../../VolmaSelect';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IChartDataset } from '../IChart';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { ReportService } from './ReportService';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import { VolmaMultiTransporterSelect } from '../../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';


declare const Chart: any;
@injectable()
export class ReportCargoTransportersRatingsExcelService extends ReportService<RatesReportParametersDTO>{
    public Title:string = "ReportCargoTransportersRatingsExcelService";
    protected _options = {
        tooltips: {
            callbacks: {
                label: (tooltipItem, data) => {
                    let ratingValue = "";
                    let dataset = data.datasets[tooltipItem.datasetIndex];
                    switch (dataset.data[tooltipItem.index]){
                        case 0:
                            ratingValue = i18next.t("report:NoRating");
                            break;
                        case 1:
                            ratingValue = "C";
                            break;
                        case 2:
                            ratingValue = "B";
                            break;
                        case 3:
                            ratingValue = "A";
                            break;
                    }
                    return dataset.label + ": " + ratingValue;
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    autoSkip:false,
                    beginAtZero: true,
                    max: 3.5,
                    min: 0,
                    fixedStepSize: 1,
                    callback: value => {
                        switch(value){
                            case 1:
                                return "C";
                            case 2:
                                return "B";
                            case 3:
                                return "A";
                        }
                        return "";
                    }
                }
            }],

        },
    }


    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<RatesReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RatesReportParametersDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.LoadingPointId, props.DataHelper.ReportParams.MultiFactoryId])}
                                AllowMultiselect={true}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    {this._authService.IsShipper() && <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaMultiTransporterSelect
                                    {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RatesReportParametersDTO) => val.CargoTransporterId) as any) }
                                    Name={PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.CargoTransporterId)}
                                    EntityDtoField={[
                                        PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.CargoTransporterId), 
                                        PropertyHelper.GetPropertyName((x: RatesReportParametersDTO) => x.MultiCargoTransporterId),
                                    ]}
                                    OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                                    Value={SafeMergeArrays([props.DataHelper.ReportParams.CargoTransporterId, props.DataHelper.ReportParams.MultiCargoTransporterId])}
                                    CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                    dispatch={props.dispatch}
                                    AllowMultiselect /> 
                        </div>
                    </div>}
                    {this._authService.IsShipper() && <div className={this._mainInfo("cell", { 33: true }).toString()}>
                    </div>}
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RatesReportParametersDTO) => val.DateFrom) as any) }
                                Name={PropertyHelper.GetPropertyName((val: RatesReportParametersDTO) => val.DateFrom)}
                                Label={i18next.t('report:LabelDateFrom')}
                                Value={props.DataHelper.ReportParams.DateFrom}
                                DefaultHours={0}
                                DefaultMinutes={0}
                                DefaultSeconds={0}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RatesReportParametersDTO) => val.DateTo) as any) }
                                Name={PropertyHelper.GetPropertyName((val: RatesReportParametersDTO) => val.DateTo)}
                                Label={i18next.t('report:LabelDateTo')}
                                Value={props.DataHelper.ReportParams.DateTo}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    public HasChart(): boolean {
        return true;
    }

   public GetChart(data: any){
        return <Bar data={data} options={this._options}/>
    }

    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset){
        dataset.backgroundColor = [];
        dataset.borderColor = [];
        dataset.borderWidth = CHART_BORDER_WIDTH;
        for (let data in dataset.data) {
            dataset.backgroundColor.push(backgroundColor);
            dataset.borderColor.push(borderColor);
        }
    }
}