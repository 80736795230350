import { injectable } from "inversify";
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { ZONE_LOCALITIES_LOADED } from '../../../Constants/AppConstants';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import axios from 'axios';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { AxiosResponse } from 'axios';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityAction } from '../BaseEntity/EntityAction';

@injectable()
export class ZoneActions extends EntityAction{

    public LoadLocalities(id: string, waitingText: string){
        return super.LoadChildDataTableItems(
            EEntityType.Zone,
            id,
            EEntityType.Locality,
            (x: LocalityTableDTO) => x.Name,
            ESortDir.Asc,
            waitingText,
            this.LocalitiesLoaded);
    }

    private LocalitiesLoaded(value: Array<LocalityTableDTO>): IActionPayloaded<Array<LocalityTableDTO>> {
        return { type: ZONE_LOCALITIES_LOADED, Payload: value };
    }
    
    
}