import { LOCALITY_ZONES_LOADED } from '../../../Constants/AppConstants';
import { ZoneTableDTO } from '../../../Domain/DTO/ZoneTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityAction } from '../BaseEntity/EntityAction';
import axios from 'axios';
import { injectable } from 'inversify';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { LatLngExpression } from 'leaflet';

@injectable()
export class LocalityActions extends EntityAction{

    public LoadAllZones(waitingText: string){
        return super.LoadDataTable(
            EEntityType.Zone,
            (x: ZoneTableDTO) => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllZonesLoaded);
    }

    private AllZonesLoaded(value: Array<ZoneTableDTO>): IActionPayloaded<Array<ZoneTableDTO>> {
        return { type: LOCALITY_ZONES_LOADED, Payload: value };
    }

}