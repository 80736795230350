import { injectable } from 'inversify';
import {
    RATING_TABLE_FORECAST_LOADED, TENDER_BETS_LOADED, TENDER_CHANGELOGS_LOADED, TENDER_CONSIGNOR_LOADED,
    TENDER_DELIVERIES_LOADED, TENDER_FORECAST_RATING_LOADED, TENDER_LOADING_POINT_LOADED, TENDER_MULTI_FACTORY_LOADED, TENDER_PAYLOAD_LOADED,
    TENDER_REJECTED_DELIVERIES_LOADED, TENDER_WINNERS_BETS_LOADED, TENDER_WINNER_LOADED, TENDER_ZONE_LOADED, VOLMA_ENTITY_STATUS_TOGGLED,
    VOLMA_SOMEWHERE_CLICKED
} from '../../../Constants/AppConstants';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { TenderBetTableDTO } from '../../../Domain/DTO/TenderBetTableDTO';
import { TenderChangelogTableDTO } from '../../../Domain/DTO/TenderChangelogTableDTO';
import { TenderDTO } from '../../../Domain/DTO/TenderDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import {  TenderHelperProps } from './IHelperProps';
import { MultiTransporterTableDTO } from '../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes';
import { MultiFactoryDTO } from '../../../Domain/DTO/MultiFactoryDTO';
import { TenderStateDTO } from '../../../Domain/DTO/TenderStateDTO';

type IState = IBaseEntityProps<TenderDTO, TenderHelperProps>;
@injectable()
export class TenderReducer extends EntityReducer<TenderDTO, TenderHelperProps> {
    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case TENDER_DELIVERIES_LOADED:
                return this.ReduceDeliveriesLoaded(state, action.Payload)
            case TENDER_REJECTED_DELIVERIES_LOADED:
                return this.ReduceRejectedDeliveriesLoaded(state, action.Payload)
            case RATING_TABLE_FORECAST_LOADED:
                return this.ReduceForecastLoaded(state, action.Payload)
            case TENDER_BETS_LOADED:
                return this.ReduceTenderBetsLoaded(state, action.Payload)
            case TENDER_WINNERS_BETS_LOADED:
                return this.ReduceTenderWinnersBetsLoaded(state, action.Payload)
            case TENDER_CHANGELOGS_LOADED:
                return this.ReduceTenderChangelogsLoaded(state, action.Payload)
            case TENDER_ZONE_LOADED:
                return this.ReduceZoneLoaded(state, action.Payload)
            case TENDER_CONSIGNOR_LOADED:
                return this.ReduceConsignorLoaded(state, action.Payload)
            case TENDER_LOADING_POINT_LOADED:
                return this.ReduceLoadingPointLoaded(state, action.Payload)
            case TENDER_MULTI_FACTORY_LOADED:
                return this.ReduceMultiFactoryLoaded(state, action.Payload)
            case TENDER_WINNER_LOADED:
                return this.ReduceWinnerCargoTransporterLoaded(state, action.Payload)
            case VOLMA_ENTITY_STATUS_TOGGLED:
                return this.ReduceStatusToggled(state, action.Payload);
            case VOLMA_SOMEWHERE_CLICKED:
                return this.ReduceStatusClose(state);
            case TENDER_PAYLOAD_LOADED:
                return this.ReducePayloadLoaded(state, action.Payload)
            case TENDER_FORECAST_RATING_LOADED:
                return this.ReduceForecastLoaded(state, action.Payload)
            default:
                return state;
        }
    }    

    private ReduceForecastLoaded(state: IState, payload: Array<RatingTableDTO>): IState {        
        return super.CollectionLoaded<RatingTableDTO>(state, payload, [x => x.CargoTransporterForecastRatings, x => x.CargoTransporterForecastRatingsOriginal]);
    }

    private ReduceDeliveriesLoaded(state: IState, payload: Array<DeliveryTableDTO>): IState {
        return super.CollectionLoaded<DeliveryTableDTO>(state, payload, [x => x.Deliveries, x => x.DeliveriesOriginal]);
    }

    private ReduceRejectedDeliveriesLoaded(state: IState, payload: Array<DeliveryTableDTO>): IState {
        return super.CollectionLoaded<DeliveryTableDTO>(state, payload, [x => x.RejectedDeliveries, x => x.RejectedDeliveriesOriginal]);
    }

    private ReduceTenderBetsLoaded(state: IState, payload: Array<TenderBetTableDTO>): IState {
        return super.CollectionLoaded<TenderBetTableDTO>(state, payload, [x => x.Bets, x => x.BetsOriginal]);
    }

    private ReduceTenderWinnersBetsLoaded(state: IState, payload: Array<TenderBetTableDTO>): IState {
        return super.CollectionLoaded<TenderBetTableDTO>(state, payload, [x => x.WinnersBets]);
    }

    private ReduceTenderChangelogsLoaded(state: IState, payload: Array<TenderBetTableDTO>): IState {
        return super.CollectionLoaded<TenderChangelogTableDTO>(state, payload, [x => x.Changelogs, x => x.ChangelogsOriginal]);
    }

    private ReduceZoneLoaded(state: IState, payload: ZoneDTO): IState {
        return super.EntityLoaded<ZoneDTO>(state, payload, x => x.Zone);
    }

    private ReduceConsignorLoaded(state: IState, payload: ConsignorDTO): IState {
        return super.EntityLoaded<ConsignorDTO>(state, payload, x => x.Consignor);
    }

    private ReduceLoadingPointLoaded(state: IState, payload: LoadingPointDTO): IState {
        return super.EntityLoaded<LoadingPointDTO>(state, payload, x => x.LoadingPoint);
    }

    private ReduceMultiFactoryLoaded(state: IState, payload: MultiFactoryDTO): IState {
        return super.EntityLoaded<MultiFactoryDTO>(state, payload, x => x.MultiFactory);
    }

    private ReduceWinnerCargoTransporterLoaded(state: IState, payload: MultiTransporterTableDTO): IState {
        return super.EntityLoaded<MultiTransporterTableDTO>(state, payload, x => x.WinnerCargoTransporter);
    }

    private ReduceStatusToggled(state: IState, payload: number): IState {
        return { ...state, OpenedStatusIndex: payload };
    }

    private ReduceStatusClose(state: IState): IState {
        return { ...state, OpenedStatusIndex: -1 };
    }
    
    private ReducePayloadLoaded(state: IState, payload: PayloadDTO): IState {
        const newState = <IState>{ ...state, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Payloads = this.CopyCollectionObject(newState.DataHelper.Payloads);
        newState.DataHelper.Payloads = newState.DataHelper.Payloads.filter(x => x.Id !== payload.Id);
        newState.DataHelper.Payloads.push(payload);

        return newState;
    }
}
