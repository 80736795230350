import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import i18next from '../../../i18n';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { Types } from '../../../../Infrastructure/Types';
import { ITableDTO } from '../../../../Domain/ITableDTO';
import { CargoTransporterUserActions } from '../CargoTransporterUserActions';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { CargoTransporterUserTableDTO } from '../../../../Domain/DTO/CargoTransporterUserTableDTO';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';

export class DeleteCargoTransporterUsersAction<THelper> implements IVolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _actions: CargoTransporterUserActions;
    private _authService: AuthenticationService;

    public Title: string = "cargotransporteruser:DeleteSelectedItem";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<CargoTransporterUserTableDTO>){
        if(selectedIds === undefined || selectedIds.length !== 1)
            return false;
        
        var itm = data.find(x => x.Id === selectedIds[0]);
        if(itm === undefined)
            return false;
        
        let currentUser = this._authService.GetCurrentUser();
        if (this._authService.IsCargoTransporter() || (this._authService.IsShipper() && (currentUser.Role === EUserRole.Administrator || currentUser.Role === EUserRole.ShipperLogistExtended)))
            return true;
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ITableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds !== undefined && selectedIds.length > 0 ? selectedIds[0] : undefined;
        if(id !== undefined){
            dispatch(this._modalActions.OpenConfirmationModal("cargotransporteruser:AreYouSureYouWantToDeleteSelectedItem", () => {
                dispatch(this._actions.DeleteEntity(name, entity, i18next.t("common:Loading"), id, reloadData));
            }));
        }
    }

    constructor(){
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._actions = VolmaContainer.get<CargoTransporterUserActions>(Types.CargoTransporterUserActions);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
    }
}