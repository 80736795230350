import { injectable } from "inversify";
import { VolmaContainer } from "../InversifyInject";
import { Types } from '../Types';
import { CommonServerInteraction } from "./CommonServerInteraction";
import { UrlFabric } from './UrlFabric';

@injectable()
export class BiddingServerInteraction extends CommonServerInteraction {

    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }
}