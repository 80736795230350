
export class DeliveryConfirmDTO {
    Id: string
    DriverId: string;
    VehicleId: string;

    ContainerId?: string;

    CargoTransporterId?: string;
    MultiCargoTransporterId?: string;
    IsCargoTransporterAvailable?: boolean;
}
