import { IBaseInputProps } from "../../Components/Shared/IBaseInputProps";
import { BaseValidator } from "./BaseValidator";
import ValidationResult from "./ValidationResult";

export abstract class VolmaBaseTimeValidator<T> extends BaseValidator<any> {

    protected abstract MustBeNonEmptyRule(entity: IBaseInputProps<T>): ValidationResult;
    protected abstract HoursAndMinutesRule(entity: IBaseInputProps<T>): ValidationResult | undefined;

    public Validate(entity: IBaseInputProps<T>): ValidationResult {       
        if (entity === undefined || entity === null){
            throw new Error("Null reference exception");
        }

        const mustBeNonEmptyRule = this.MustBeNonEmptyRule(entity);
        const hoursAndMinutesRule = this.HoursAndMinutesRule(entity);

        if (mustBeNonEmptyRule && !mustBeNonEmptyRule.IsValid) {
            return mustBeNonEmptyRule;
        } else if (hoursAndMinutesRule && !hoursAndMinutesRule.IsValid) {
            return hoursAndMinutesRule;
        }

        return ValidationResult.Success();
    }
}