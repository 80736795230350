import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { BillAction } from '../Action/BillAction';
import { CloseAction } from '../Action/CloseAction';
import { Billed } from './Billed';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { ComplaintHelperProps } from '../IHelperProps';

export class Cancelled extends ComplaintStatusDefault {

    public TitleCurrent : string = "complaint:StatusCancelledCurrent";
    public TitleFinished: string = "complaint:StatusCancelledFinished";
    public TitleFuture  : string = "complaint:StatusCancelledFuture";


    public IsActive(dto: ComplaintDTO, dtoHelper: ComplaintHelperProps) {
        return this.IsFinished(dto);
    }

    public IsCurrent(dto: ComplaintDTO) {
        return false;
    }

    public IsFinished(dto: ComplaintDTO) {
        return dto.ComplaintState === EComplaintState.Cancelled;
    }

    public GetActions(dto: ComplaintDTO) {
        return [];
    }

    constructor() {
        super();

        this._currentStatus = EComplaintState.Cancelled;
    }
}