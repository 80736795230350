import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';

export interface IVolmaNumberProps extends IBaseInputProps<IVolmaNumberProps> {
    Step?: number;
    MinValue?: () => number;
    MaxValue?: () => number;
    Value: number;
    Unit?: string;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
    OnLostFocus?: (cancelled: boolean) => void;
    Autofocus?: boolean;
    HideUpDownBtns?: boolean;
}

export const VolmaNumberPropsInitial: IVolmaNumberProps = <IVolmaNumberProps>{
    ...VolmaBaseInputPropsInitial,
    Value: 0
};