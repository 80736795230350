import { EntityAction } from '../BaseEntity/EntityAction';
import { injectable } from 'inversify';


@injectable()
export class CalendarEntryActions extends EntityAction{
    
    constructor(){
        super();
    }
    
}