import * as React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { ITableDTO } from '../../Domain/ITableDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import { Types } from '../../Infrastructure/Types';
import { IGlobalState } from '../../Reducers/IGlobalState';
import { BaseEntityActions } from '../Entity/BaseEntity/BaseEntityActions';
import i18next from '../i18n';
import VolmaForm from '../VolmaForm';
import { IVolmaModalProps } from './IVolmaModalProps';
import { IVolmaModalLocalEntityEditPayload } from './Payloads';
import { VolmaModalActions } from './VolmaModalActions';

class VolmaModalLocalEntityEdit extends Component<IVolmaModalProps<IEntityDTO, any>, {}>{
    private _baseActions: BaseEntityActions;
    private _modalActions: VolmaModalActions;
    private _entityService: EntityService;
    private _entity: EEntityType;
    private _dto: any;
    private _header: string;
    private _onSaved: (dto: ITableDTO) => void;
    private _onCancelled: () => void;

    private _modal        = volmaBlock("modal");
    private _modalWrap    = volmaBlock("modal-wrap");
    private _modalCont    = volmaBlock("modal-cont");
    private _modalDefault = volmaBlock("modal-default");
    private _btnModal     = volmaBlock("btn-modal");


    constructor(props: IVolmaModalProps<any, any>, context: any) {
        super(props, context);

        this._baseActions = VolmaContainer.get<BaseEntityActions>(Types.BaseEntityActions);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);

        const state = this.props.Props as IVolmaModalLocalEntityEditPayload<any>;
        this._entity = state.Type;
        this._header = state.Header || i18next.t(this._entityService.GetEntityHeader(this._entity));
        this._dto = state.DTO;
        this._onSaved = state.OnSaved;
        this._onCancelled = state.OnCancelled;
    }

    public UNSAFE_componentWillMount() {
        this.props.dispatch(this._modalActions.SetEntityService(this._entityService.GetEntityService(this._entity)));
        this.LoadData();
    }

    public render() {
        let editor = this.props.DataDTO !== undefined ? this._entityService.GetEntityService(this._entity).GetEditorModal(this.props) : undefined;

        return (
            <div className={(this._modal.mix(["active"])).toString()}>
                <div className={(this._modalWrap({cont: true })).toString()}>
                    <div className={(this._modalCont).toString()}>
                        <span className={(this._modalCont("title")).toString()}>{this._header}</span>
                        <VolmaForm {...this.props as any} ClassName={this._modalDefault.toString()}>
                            <div className={(this._modalDefault("row", {"no-shrink": true })).toString()}>
                                <div className={(this._modalDefault("row-inner")).toString()}>
                                    {editor}
                                </div>
                            </div>
                            <div className={(this._modalDefault("row", {"no-shrink": true })).toString()}>
                                <div className={(this._modalDefault("cell")).toString()}>
                                    <button onClick={this.OnSaveClick.bind(this)} className={(this._btnModal({large: true, green: true }).mix(["btn"])).toString()} disabled={!this.IsModalSendingAvailable()}>{i18next.t('common:ButtonSave')}</button>
                                </div>
                                <div className={(this._modalDefault("cell")).toString()}>
                                    <button onClick={this.OnCancelClick.bind(this)} className={(this._btnModal({large: true, red: true }).mix(["btn"])).toString()}>{i18next.t('common:ButtonCancel')}</button>
                                </div>
                            </div>
                        </VolmaForm>
                        <a onClick={this.OnCancelClick.bind(this)} className={(this._modal("close")).toString()}>
                            <svg className={(this._modal("close-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div >
        );
    }

    public LoadData() {
        this._entityService.GetEntityService(this._entity).InitializeEntity(this.props)       
        this.props.dispatch(this._modalActions.LoadLocalEntity(this._entityService.GetEntityService(this._entity), this._dto))
    }

    private IsModalSendingAvailable(): boolean {
        return this._entityService.GetEntityService(this._entity).IsEntitySendingAvailable(this.props.DataDTO, this.props.DataHelper);
    }

    private OnSaveClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.IsValid) {
            this.props.dispatch(this._modalActions.SaveLocalEntity(this._entity, this.props.DataDTO, this.props.IsValid, this._onSaved, this.props.DataHelper))
        }
        else
            this.props.dispatch(this._baseActions.Submit());
    }

    private OnCancelClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        this._onCancelled();
        this.props.dispatch(this._modalActions.CloseModal());
        // if (this.props.DataDTOStringify === JSON.stringify(this.props.DataDTO) || this.props.ConfirmOnUnsavedChangesShown) {
        // }
        // else
        //     this.props.dispatch(this._modalActions.ShowConfirmOnUnsavedChanges());
    }

    private OnCloseConfirmMessageClick() {
        this.props.dispatch(this._modalActions.HideConfirmOnUnsavedChanges());
    }    
}

const translated = withTranslation(['common'])(withRouter(VolmaModalLocalEntityEdit));

function select(state: IGlobalState, ownProps: IVolmaModalProps<any, any>): IVolmaModalProps<any, any> {
    return state.ModalEntity;
}

export const ConnectedVolmaModalLocalEntityEdit = connect<IVolmaModalProps<any, any>, {}, {}>(select)(translated);