import { injectable } from 'inversify';

import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { DeliveryEntityService } from '../DeliveryEntityService';

@injectable()
export class DeliveryForComplaintEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }
}
