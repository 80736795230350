import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import i18next from '../../i18n';
import { BaseCustomLoadSaveComponent } from '../BaseCustomLoadSave/BaseCustomLoadSaveComponent';
import { IPortalConfigurationProps } from './IPortalConfigurationProps';
import { PortalConfigurationActions } from './PortalConfigurationActions';
import { PortalConfigurationEntityService } from './PortalConfigurationEntityService';

class PortalConfiguration extends BaseCustomLoadSaveComponent<IPortalConfigurationProps> {
    private _configurationActions : PortalConfigurationActions;
    private _portalConfigurationEntityService: PortalConfigurationEntityService;

    constructor(props: IPortalConfigurationProps, context: any) {
        super(props, context, EEntityType.Configuration);

        this._key = "configuration";
        this._configurationActions = VolmaContainer.get<PortalConfigurationActions>(Types.PortalConfigurationActions);
        this._portalConfigurationEntityService = VolmaContainer.get<PortalConfigurationEntityService>(Types.PortalConfigurationEntityService);

        this.SetActions(this._configurationActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.IsValid) {
            this.props.dispatch(this._configurationActions.SavePortalConfiguration(this.props.DataDTO, i18next.t('common:WaitingForLoadingConfigurationSettings'), () => {
                this.props.dispatch(this._modalActions.OpenNotificationModal(i18next.t('common:SettingsSaved')));
                this.props.dispatch(this._configurationActions.UpdateDTOStringify(this.props.DataDTO));
            }))
        }
        else {
            this.props.dispatch(this._configurationActions.Submit());
        }
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();

    }

    public LoadData(){
        this._portalConfigurationEntityService.InitializeEntity(this.props);
        this.props.dispatch(this._configurationActions.LoadPortalConfiguration(i18next.t('common:WaitingForLoadingConfigurationSettings')));
        this.props.dispatch(this._configurationActions.SetEntityType(EEntityType.Configuration));
    }

    public IsEditable(): boolean {
        return this._portalConfigurationEntityService.IsEditable(this.props);
    }

}

const translated = withTranslation(['common'])(withRouter(PortalConfiguration));

function select(state: IGlobalState, ownProps: IPortalConfigurationProps): IPortalConfigurationProps {
    return state.PortalConfiguration;
}

let connectedPortalConfigurationEntity = connect<IPortalConfigurationProps, {}, {}>(select)(translated);
export default connectedPortalConfigurationEntity;