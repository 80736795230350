import { injectable } from "inversify";
import { ComplaintTableDTO } from "../../../../Domain/DTO/ComplaintTableDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableColumnDefinition } from '../../../Table/VolmaTable/IVolmaTableProps';
import { ComplaintEntityService } from "../ComplaintEntityService";
import { ComplaintHelperProps } from "../IHelperProps";

@injectable()
export class ComplaintWithNewMessagesEntityService extends ComplaintEntityService {
    constructor() {
        super();

        this.GetLastMessageCellText = this.GetLastMessageCellText.bind(this);
    }
    public GetBaseEntity(): EEntityType {
        return EEntityType.Complaint;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        const commonTemplates = [
            { ...this._complaintColumnTemplates.Id },
            { ...this._complaintColumnTemplates.RegistrationCode },
            { ...this._complaintColumnTemplates.TemplateName },
            { ...this._complaintColumnTemplates.ComplaintState },
            { ...this._complaintColumnTemplates.DeliveriesIdentifiers },
        ];
        
        
        if (this._authService.IsShipper()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
                ...commonTemplates,
                { ...this._complaintColumnTemplates.MultiTransporterName },
                { ...this.LastMessage },
            ]);
        }

        if (this._authService.IsIncludedCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
                ...commonTemplates,
                { ...this._complaintColumnTemplates.CargoTransporterName },
                { ...this.LastMessage },
            ]);
        }

        return this._volmaTableService.GetColumnsByKeys(EEntityType.Complaint, [
            ...commonTemplates,
            { ...this.LastMessage },
        ]);
    }

    private get LastMessage(): IVolmaTableColumnDefinition {
        return {
            DataKey: "LastMessage",
            CellTextGetter: this.GetLastMessageCellText,
            IsSortable: false,
            Weight: 2.5,
        }
    }

    private GetLastMessageCellText(props: IVolmaTableProps<ComplaintHelperProps>, rowIndex: number): string {
        const dto = props.Data[rowIndex] as ComplaintTableDTO;

        if (this._helper.IsAlice(dto)) {
            return this.GetSafeString(dto.LastUnreadedMessageResponder);
        }
        if (this._helper.IsBob(dto)) {
            return this.GetSafeString(dto.LastUnreadedMessageAuthor);
        }

        return this.GetSafeString(undefined);
    }
}
