import { injectable } from 'inversify';
import { NOOP, TENDER_FORECAST_RATING_LOADED, TENDER_TABLE_BETS_LOADED, TENDER_TABLE_BET_ADDED_OR_UPDATED } from '../../../../Constants/AppConstants';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { IReducePayloaded } from '../../../../Infrastructure/Reducer/IReducer';
import { IBaseTableProps } from '../../../Table/BaseTable/IBaseTableProps';
import { ITenderTableHelperProps } from './ITableHelperProps';

type IState = IBaseTableProps<ITenderTableHelperProps>;

@injectable()
export class TenderTableReducer implements IReducePayloaded<IState>{

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case NOOP: 
                return this.ReduceUpdateTable(state);
            case TENDER_TABLE_BETS_LOADED:
                return this.ReduceBetsLoaded(state, action.Payload);
            case TENDER_TABLE_BET_ADDED_OR_UPDATED:
                return this.ReduceBetAddedOrUpdatedLoaded(state, action.Payload);
            case TENDER_FORECAST_RATING_LOADED:
                return this.ReduceForecastLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceForecastLoaded(state: IState, payload: Array<TenderBetTableDTO>): IState {
        const newState = { ...state};
        newState.DataHelper.CargoTransporterForecastRatings = payload;
        return newState;
    }

    private ReduceUpdateTable(state: IState){
        const newState = {...state};
        newState.NoopObject = new Object();
        if (newState.Tables !== undefined && newState.Tables.length > 0) {
            for (const table of newState.Tables) {
                table.NoopObject = new Object();
            }
        }
    
        return newState;
    }

    private ReduceBetsLoaded(state: IState, payload: Array<TenderBetTableDTO>): IState {
        const newState = { ...state, DataHelper: {...state.DataHelper}};
        newState.DataHelper.AllUserBets = payload;
        return newState;
    }

    private ReduceBetAddedOrUpdatedLoaded(state: IState, payload: TenderBetTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };

        if (newState.DataHelper.AllUserBets === undefined) {
            newState.DataHelper.AllUserBets = new Array<TenderBetTableDTO>();
            newState.DataHelper.AllUserBets.push(payload);
            
            return newState;
        }
        newState.DataHelper.AllUserBets = newState.DataHelper.AllUserBets.slice(0);
        
        let betIndex = newState.DataHelper.AllUserBets.findIndex(x => x.TenderId === payload.TenderId);
        if (betIndex < 0)
            newState.DataHelper.AllUserBets.push(payload);
        else
            newState.DataHelper.AllUserBets.splice(betIndex, 1, payload);

        return newState;
    }
}