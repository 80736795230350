import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaTimeTimeDTOValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';
import { IVolmaTimeTimeDTOProps, VolmaTimeTimeDTOPropsInitial } from './IVolmaTimeTimeDTOProps';

@injectable()
export class VolmaTimeTimeDTOReducer extends BaseInputReducer<IVolmaTimeTimeDTOProps> {

    public Reduce(state: IVolmaTimeTimeDTOProps = VolmaTimeTimeDTOPropsInitial, action: IActionPayloaded<any>): IVolmaTimeTimeDTOProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaTimeTimeDTOProps, payload: IVolmaTimeTimeDTOValueChangedPayload) {
        let newState: IVolmaTimeTimeDTOProps = {...state, Value: {...payload.Value}};
        return newState;
    }
}

