import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { IVolmaFormProps, VolmaFormPropsInitial } from '../../VolmaForm/IVolmaFormProps';
import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { IEntityService } from '../BaseEntity/IEntityService';
import { SettingsEntityService } from './SettingsEntityService';
import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';

export interface ISettingsHelperProps {
    ComplaintTemplateList: Array<ComplaintTemplateTableDTO>;
    SelectedTemplateForCargotransporterLateForLoadingComplaint: ComplaintTemplateDTO;
    SelectedTemplateForCargoTransporterSupplyDisruptionComplaint: ComplaintTemplateDTO;
    SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint: ComplaintTemplateDTO;
    SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint: ComplaintTemplateDTO;
}

export const SettingsHelperPropsInitial: ISettingsHelperProps = { 
    ComplaintTemplateList: [], 
    SelectedTemplateForCargotransporterLateForLoadingComplaint: undefined,
    SelectedTemplateForCargoTransporterSupplyDisruptionComplaint: undefined,
    SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint: undefined,
    SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint: undefined };