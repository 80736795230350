import { injectable } from 'inversify';
import { BiddingDTO } from '../../../Domain/DTO/BiddingDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { BiddingHelperProps } from './IHelperProps';
import { BIDDING_BIDDING_BETS_LOADED } from '../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';

@injectable()
export class BiddingReducer extends EntityReducer<BiddingDTO, BiddingHelperProps> {

    public Reduce(state: IBaseEntityProps<BiddingDTO, BiddingHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<BiddingDTO, BiddingHelperProps> {
        switch (action.type) {
            case BIDDING_BIDDING_BETS_LOADED:
                return this.ReduceBiddingBetsLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceBiddingBetsLoaded(state: IBaseEntityProps<BiddingDTO, BiddingHelperProps>, payload: Array<BiddingBetTableDTO>): IBaseEntityProps<BiddingDTO, BiddingHelperProps> {
        return super.CollectionLoaded<BiddingBetTableDTO>(state, payload, [x => x.Bets, x => x.BetsOriginal]);
    }

}