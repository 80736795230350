import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { history } from '../../../ConfigureStore';
import { CARGO_TRANSPORTER_USER_ROLE_UPDATED, FORM_SUBMITTED } from '../../../Constants/AppConstants';
import { CargoTransporterUserDTO } from '../../../Domain/DTO/CargoTransporterUserDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { RegisterCargoTransporterUserDTO } from '../../../Domain/DTO/RegisterCargoTransporterUserDTO';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import i18next from '../../i18n';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import { EntityAction } from '../BaseEntity/EntityAction';
import { SetRoleExtendedDTO } from '../Role/SetRoleExtendedDTO';

@injectable()
export class CargoTransporterUserActions extends EntityAction {
    private _accountApi: AuthServerInteraction;
    private _modalActions: VolmaModalActions;
    private _authService: AuthenticationService;

    constructor(){
        super();

        this._accountApi = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
    }

    public SaveEntity(entity: EEntityType, dto: CargoTransporterUserDTO | RegisterCargoTransporterUserDTO, isFormValid: boolean, waitingText: string, id?: string) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            this._apiActions.DoApiRequest(
                dispatch,
                () => id === undefined ? this._accountApi.RegisterCargoTransporterUser(dto) : this._accountApi.UpdateCargoTransporterUser(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = response.data
                    let entityService = VolmaContainer.get<EntityService>(Types.EntityService);
                    history.push(entityService.GetEntityTableRoute(entity));
                    this._apiActions.DoApiRequest(dispatch, () => this._accountApi.GetUserInfo(), waitingText, (response: AxiosResponse, dispatch: any) => {
                        let userDTO: UserDTO = JSON.parse(response.data);
                        this._authService.SetUser(userDTO);
                    });
                });
        }
    }

    public DeleteEntity(name: string, entity: EEntityType, waitingText: string, id: string, onItemsDeleted: () => void) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._accountApi.DeleteCargoTransporterUser(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    onItemsDeleted();
                });
        }
    }

    public RoleChangeRequired(userId: string, roleId: number, availableRoles: Array<ListIntOptionDTO>) {
        return (dispatch) => {
            let dto = new SetRoleExtendedDTO();
            dto.UserId = userId;
            dto.Role = roleId;
            dto.AvailableOptions = availableRoles;
            dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.UserRole, dto, undefined, (dto) => {
                let request = this._accountApi.SetRole(dto)
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => request,
                    i18next.t("common:Loading"),
                    (response: AxiosResponse, dispatch: any) => {
                        dispatch(this.RoleChanged(roleId));
                    });
                return request;
            }));
        }
    }

    public RoleChanged(roleId: number): IActionPayloaded<number>{
        return { type: CARGO_TRANSPORTER_USER_ROLE_UPDATED, Payload: roleId };
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

}