import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import {BaseValidator} from "./BaseValidator";

import i18next from "../../Components/i18n";
import { IVolmaInputProps } from '../../Components/VolmaInput/IVolmaInputProps';


@injectable()
export class VolmaInputValidator extends BaseValidator<IVolmaInputProps> {
    public Validate(entity: IVolmaInputProps): ValidationResult {

        if (entity === undefined || entity === null)
            throw new Error("Null reference exception");

        if ((entity.Value === null || entity.Value === undefined || entity.Value === "") && entity.Required)
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty"));
        if ((entity.Value === null || entity.Value === undefined || entity.Value === "") && !entity.Required)
            return ValidationResult.Success();
            
        if (entity.Value.toString().length > entity.MaxLength)
            return ValidationResult.Fail(i18next.t("validate:ValueMustNotExceedCharsLimit", {count: entity.MaxLength}));
        return ValidationResult.Success();
    }
}