import i18next from 'i18next';
import { injectable } from 'inversify';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { ListIntOptionDTO } from '../../../../Domain/DTO/ListIntOptionDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaActionLink } from '../../../Shared/VolmaActionLink';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableColumnDefinition, IVolmaTableProps } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { DeliveryEntityService } from '../DeliveryEntityService';
import { isNullOrUndefined } from '../../../../Infrastructure/Services/Utils';


@injectable()
export class DeliveryPerformingEntityService extends DeliveryEntityService {

    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {    
        if (this._authService.IsShipper()) {
            const isShipperStateFilterDeliveryStates = [
                EDeliveryState.Approved,
                EDeliveryState.VehicleArrivedAtLoadingPoint,
                EDeliveryState.VehicleArrivedAtUnloadingPoint,
                EDeliveryState.LoadingBegin,
                EDeliveryState.LoadingEnd,
                EDeliveryState.Delivery,
                EDeliveryState.Delivered
            ];

            return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
                { ...this._deliveryColumnTemplates.Id },
                { ...this._deliveryColumnTemplates.Identifier },
                { ...this._deliveryColumnTemplates.LoadingDate },
                { ...this._deliveryColumnTemplates.UnloadingDate },
                { ...this._deliveryColumnTemplates.MultiPointName, Weight: 0.7 },
                { ...this._deliveryColumnTemplates.UnloadingPointName },
                { ...this._deliveryColumnTemplates.MultiTransporterName },
                { ...this._deliveryColumnTemplates.SalesDepartment, Weight: 0.5 },
                { ...this.AssignedDriverNameAndPhone },
                { ...this.getState(isShipperStateFilterDeliveryStates), },
                { ...this._deliveryColumnTemplates.StateTimestamp },
            ]);
        } else if (this._authService.IsCargoTransporter()) {
            const isCargoTransporterStateFilterDeliveryStates = [
                EDeliveryState.Approved,
                EDeliveryState.VehicleArrivedAtLoadingPoint,
                EDeliveryState.VehicleArrivedAtUnloadingPoint,
                EDeliveryState.LoadingBegin,
                EDeliveryState.LoadingEnd,
                EDeliveryState.Delivery,
                EDeliveryState.Delivered
            ];
            
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
                { ...this._deliveryColumnTemplates.Id },
                { ...this._deliveryColumnTemplates.Identifier },
                { ...this._deliveryColumnTemplates.LoadingDate },
                { ...this._deliveryColumnTemplates.UnloadingDate },
                { ...this._deliveryColumnTemplates.MultiPointName, Weight: 0.6 },
                { ...this._deliveryColumnTemplates.UnloadingPointName },
                { ...this.AssignedDriverNameAndPhone },
                { ...this._deliveryColumnTemplates.SalesDepartment, Weight: 0.5 },
                { ...this.getState(isCargoTransporterStateFilterDeliveryStates), },
                { ...this._deliveryColumnTemplates.StateTimestamp },
            ]);
        }
    }

    public get AssignedDriverNameAndPhone(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.AssignedDriverNameAndPhone),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
            CellRenderer: this.DriverPhoneRenderer,
            Weight: 0.9,
        };
    }

    public getState(deliveryStates: EDeliveryState[]): IVolmaTableColumnDefinition {
        return {
            ...this._deliveryColumnTemplates.State,
            OptionsFilter: (x: ListIntOptionDTO) => deliveryStates.some(state => state === x.Id),
            Weight: 0.5,
        };
    }

    public DriverPhoneRenderer = (props: IVolmaTableProps<{}>, params: IVolmaTableCellRendererParams): JSX.Element => {
        const dto = params.rowData as DeliveryTableDTO;
        const isCoordExists = !isNullOrUndefined(dto.Longitude) && !isNullOrUndefined(dto.Latitude);
        const isDelivering = dto.State !== EDeliveryState.Delivered && dto.State !== EDeliveryState.Closed && dto.State !== EDeliveryState.ClosedOld;
        const result = params.cellData ? params.cellData : dto.AssignedDriverName ? dto.AssignedDriverName : i18next.t("common:NoData");

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);        

        const geoSvg = <svg width="25px" height="25px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" fill="#4a80c1" d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
            <path fillRule="evenodd" fill="#4a80c1" d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>;

        return (
            <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected }).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{result}</span>
                    {isCoordExists && isDelivering && !this._authService.IsCargoTransporter() &&
                        <VolmaActionLink path={this.GetDriverMonitoringLink(dto)} className="drivermonitoring-icon">
                            {geoSvg}
                        </VolmaActionLink>
                    }
                </div>
            </div>
        )
    }

    private GetDriverMonitoringLink(dto: DeliveryTableDTO): string {
        if (dto.MultiCargoTransporterId) {
            return `/driverMonitoring?driver=${dto.AssignedDriverId}&multiCargoTransporter=${dto.MultiCargoTransporterId}&identifier=${dto.Identifier}`
        }

        return `/driverMonitoring?driver=${dto.AssignedDriverId}&cargoTransporter=${dto.CargoTransporterId}&identifier=${dto.Identifier}`
    }

}