import { injectable } from 'inversify';
import * as React from 'react';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { EComplaintTemplateDirection } from '../../../Domain/Enum/EComplaintTemplateDirection';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import i18next from '../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaFile from '../../VolmaFile/VolmaFile';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ComplaintTemplateReducer } from './ComplaintTemplateReducer';
import { ComplaintTemplateHelperProps, InitialComplaintTemplateHelperProps } from './IHelperProps';


@injectable()
export class ComplaintTemplateEntityService extends BaseEntityService<ComplaintTemplateDTO, ComplaintTemplateHelperProps>{
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: ComplaintTemplateReducer;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._reducer = VolmaContainer.get<ComplaintTemplateReducer>(Types.ComplaintTemplateReducer);
    }


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.ComplaintTemplate]
    }


    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.ComplaintTemplate,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTemplateTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: ComplaintTemplateTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ComplaintTemplateTableDTO) => val.Direction),
                    CellRenderer: (props: IVolmaTableProps<{}>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EComplaintTemplateDirection, this._enumService, props, cellRendererParams),
                    IsSortable: false
                },
            ]
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("complainttemplate:MainInfoTitle"), i18next.t("complainttemplate:MainInfoTitleDescription"), this.MainInfoItem(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.DescriptionItem(props))}
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._createCard("cell")).toString()}>
                <VolmaInput
                    {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintTemplateDTO) => val.Name) }
                    Name={PropertyHelper.GetPropertyName((val: ComplaintTemplateDTO) => val.Name)}
                    Label={i18next.t('complainttemplate:Name')}
                    Placeholder={i18next.t('complainttemplate:PlaceholderName')}
                    Value={props.DataDTO.Name}
                    Validator={this._nonEmptyInputValidator}
                    Required={true}
                    IsInHeader={true}
                    Readonly={!isEditable}
                    dispatch={props.dispatch} />
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>){
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialComplaintTemplateHelperProps;
    }

    public OnAfterDataLoaded(dto: ComplaintTemplateDTO): void {
    }

    private MainInfoItem(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintTemplateDTO) => val.Direction) as any) }
                            Name={PropertyHelper.GetPropertyName((x: ComplaintTemplateDTO) => x.Direction)}
                            Label={i18next.t("complainttemplate:Direction")}
                            Placeholder={i18next.t("complainttemplate:SelectDirection")}
                            AllowMultiselect={true}
                            EnumGetter={() => EComplaintTemplateDirection}
                            Value={props.DataDTO.Direction}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaFile
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintTemplateDTO) => val.FileId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintTemplateDTO) => val.FileId)}
                            Label={i18next.t('complainttemplate:FileId')}
                            Value={props.DataDTO.FileId}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private DescriptionItem(props: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintTemplateDTO) => val.Description) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintTemplateDTO) => val.Description)}
                            Type="textarea"
                            Label={i18next.t('complainttemplate:Description')}
                            Value={props.DataDTO.Description}
                            Validator={this._nonEmptyInputValidator}
                            Readonly={!isEditable}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}