import { CancelToken, CancelTokenSource } from 'axios';
import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { FileTableDTO } from '../../Domain/DTO/FileTableDTO';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { FileDTO } from '../../Domain/DTO/FileDTO';

export interface IVolmaFileProps extends IBaseInputProps<IVolmaFileProps> {
    Value: string;
    FilesFilter: string;
    OnFileChanged: (dto: FileTableDTO) => void;
    OnFileDeleted: (fileId: string) => void;
    FileData: VolmaFileData;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
}

export enum ETagType {
    Image,
    Video,
    Audio,
    Object
}

export interface IVolmaFile {
    File: File;
    FileName: string;
    DataUrl: string;
    TagType: ETagType;
    FileType: string;
    Element: any;
}

export class ProgressDTO {
    public Progress: number;
    //public UploadPromise: angular.angularFileUpload.IUploadPromise<Object>;
    public IsPaused: boolean;

    constructor() {
        this.Progress = 0;
        //this.UploadPromise = undefined;
        this.IsPaused = false;
    }

}

export class VolmaFileData {
    File: IVolmaFile;
    ProgressDTO: ProgressDTO;
    IsFileUploading: boolean;
    UploadErrorMessage: string;
    CancelTokenSource: CancelTokenSource;
    
    constructor() {
        this.ProgressDTO = new ProgressDTO();
        this.IsFileUploading = false;
        this.File = undefined;
        this.UploadErrorMessage = undefined;
    }
}

export const VolmaFilePropsInitial: IVolmaFileProps = <IVolmaFileProps>{
    ...VolmaBaseInputPropsInitial,
    Value: undefined,
    FileTableDTO: undefined,
    FilesFilter: undefined,
    OnFileChanged: (dto: FileTableDTO) => undefined,
    OnFileDeleted: (dto: FileTableDTO) => undefined,
    FileData: new VolmaFileData(),
    CustomDataUpdate: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => undefined
    
};