import * as React from 'react';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ILocalityHelperProps } from '../Locality/IHelperProps';
import { SetRoleExtendedDTO } from './SetRoleExtendedDTO';
import { injectable } from 'inversify';
import i18next from '../../i18n';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';


@injectable()
export class UserRoleEntityService extends BaseEntityService<SetRoleExtendedDTO, any>{
    constructor() {
        super();

    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetEditorModal(props: IBaseEntityProps<SetRoleExtendedDTO, {}>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SetRoleExtendedDTO) => val.Role) as any) }
                                        Name={PropertyHelper.GetPropertyName((x: SetRoleExtendedDTO) => x.Role)}
                                        Options={props.DataDTO.AvailableOptions}
                                        Label={i18next.t("userrole:Role")}
                                        Placeholder={i18next.t("userrole:SelectRole")}
                                        LabelKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Name)}
                                        ValueKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Id)}
                                        Value={props.DataDTO.Role}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetHeaderEditor(props: IBaseEntityProps<SetRoleExtendedDTO, ILocalityHelperProps>): JSX.Element {
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<SetRoleExtendedDTO, ILocalityHelperProps>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<SetRoleExtendedDTO, ILocalityHelperProps>) {
    }

    public GetReducer() {
        return undefined
    }

    public OnAfterDataLoaded(dto: SetRoleExtendedDTO): void {
    }

    public GetInitialDataHelper() {
    }
}