import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { DeliveryManualAssignDTO } from '../../../../Domain/DTO/DeliveryManualAssignDTO';
import i18next from '../../../i18n';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TableDataDTO } from '../../../../Domain/TableDataDTO';

export class ManualAssignDeliveriesAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:ManualAssignDelivery";

    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>){
        if(this._authService.IsShipper()){
            if (!PropertyHelper.IsArray(selectedIds) || selectedIds.length < 1) {
                return false;
            }

            let itm = data.find(x => x.Id === selectedIds[0]);
            if (itm === undefined) {
                return false;
            }
            const currency = itm.Currency;
            
            for (let selectedId of selectedIds) {
                let itm = data.find(x => x.Id === selectedId);
                if (itm === undefined) {
                    return false;
                }

                if (itm.Currency !== currency) {
                    return false;
                }

                if (!(itm.State === EDeliveryState.New ||
                    itm.State === EDeliveryState.Revoked ||
                    itm.State === EDeliveryState.Assignation ||
                    itm.State === EDeliveryState.NotApproved ||
                    itm.State === EDeliveryState.NotAccepted ||
                    itm.State === EDeliveryState.Assigned ||
                    itm.State === EDeliveryState.Accepted ||
                    itm.State === EDeliveryState.Approved ||
                    itm.State === EDeliveryState.LoadingLatenessRegistration))
                    return false
            }
            return true;
        }
        return false;
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        const selectedDeliveries = data.filter(x => selectedIds.indexOf(x.Id) >= 0);
		if (selectedDeliveries.length < 1) {
            return;
        }

        let dto = new DeliveryManualAssignDTO();
        dto.Rate = selectedDeliveries[0].BaseRate;
        dto.Currency = selectedDeliveries[0].Currency;
        dispatch(
            this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryManualAssign, dto, undefined, (dto) => {
                let tableDataDTO = new TableDataDTO<DeliveryStateDTO>();
                tableDataDTO.Items = new Array<DeliveryStateDTO>();
                tableDataDTO.Count = 0;
                for (let selectedId of selectedIds) {
                    let stateDTO = new DeliveryStateDTO();
                    stateDTO.DeliveryId = selectedId;
                    stateDTO.DeliveryState = EDeliveryState.Assigned;
                    stateDTO.CargoTransporterId = dto.CargoTransporterId;
                    stateDTO.CommentsForCargoTransporter = dto.Comment;
                    stateDTO.CommentsForShipper = dto.Comment;
                    stateDTO.Rate = dto.Rate;
                    tableDataDTO.Items.push(stateDTO);
                    tableDataDTO.Count++;
                }

                dispatch(
                    this._deliveryTableActions.DeliveryAddStates(
                        tableDataDTO,
                        i18next.t('common:Loading'),
                        reloadData
                    )
                );
            })
        );
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}