import {
    HEADER_SEARCH_UPDATED,
    HEADER_TOGGLE_NAV,
    HEADER_TOGGLE_USER_MENU,
    USER_PROFILE_CHANGED,
    VOLMA_ENTITY_SET_ENTITY
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { HeaderPropsInitial, IHeaderProps } from './IHeaderProps';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { UserDTO } from '../../Domain/DTO/UserDTO';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';

export class HeaderReducer implements IReducePayloaded<IHeaderProps> {

    public Reduce(state: IHeaderProps = HeaderPropsInitial, action: IActionPayloaded<any>): IHeaderProps {
        switch (action.type) {
            case HEADER_TOGGLE_USER_MENU:
                return this.ReduceToggleUserMenu(state)
            case HEADER_TOGGLE_NAV:
                return this.ReduceToggleNav(state, action.Payload);
            case VOLMA_ENTITY_SET_ENTITY:
                return this.ReduceSetCurrentEntity(state, action.Payload);
            case USER_PROFILE_CHANGED:
                return this.ReduceUserProfileChanged(state, action.Payload);
            case HEADER_SEARCH_UPDATED:
                return this.ReduceSearchUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceToggleUserMenu(state: IHeaderProps): IHeaderProps{
        let newState: IHeaderProps = { ...state, IsUserMenuOpened: state.IsUserMenuOpened === true ? false : true}
        return newState;
    }

    private ReduceToggleNav(state: IHeaderProps, payload: number): IHeaderProps{
        let newState: IHeaderProps = { ...state, OpenedNav: state.OpenedNav === payload ? -1 : payload, IsUserMenuOpened: false}
        return newState;
    }
    
    private ReduceSetCurrentEntity(state: IHeaderProps, payload: EEntityType): IHeaderProps{
        let newState: IHeaderProps = { ...state, CurrentEntity: payload}
        return newState;
    }

    private ReduceUserProfileChanged(state: IHeaderProps, payload: UserDTO): IHeaderProps {
        let newState = { ...state};
        newState.CurrentUser = payload;

        return newState;
    }

    private ReduceSearchUpdated(state: IHeaderProps, payload: string): IHeaderProps {
        let newState = { ...state};
        newState.SearchString = payload;

        return newState;
    }

    public static Initialize(): (state: IHeaderProps, action: IActionPayloaded<any>) => IHeaderProps{
        const reducer = new HeaderReducer();
        return (state: IHeaderProps, action: IActionPayloaded<any>) => reducer.Reduce(state, action);
    }
}

