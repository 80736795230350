import {
    ROUTE_POINT_ADD_EXTRA_INFO_FIELD,
    ROUTE_POINT_ALL_HANDLING_TYPES_LOADED,
    ROUTE_POINT_ALL_LOADING_POINTS_LOADED,
    ROUTE_POINT_ALL_LOCALITIES_LOADED,
    ROUTE_POINT_EDIT_EXTRA_INFO_FIELD,
    ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED,
    ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED,
    ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD,
    VOLMA_ENTITY_DATA_LOADED
} from '../../../../Constants/AppConstants';
import { DataDTO } from '../../../../Domain/DTO/DataDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { LocalityTableDTO } from '../../../../Domain/DTO/LocalityTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { RoutePointHelperProps } from './IHelperProps';
import { IExtraFieldChanged } from './Payload';
import { injectable } from 'inversify';
import * as uuid from 'uuid';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import { RouteSegmentDTO } from '../../../../Domain/DTO/RouteSegmentDTO';
import { ExtraInformationDTO } from '../../../../Domain/DTO/ExtraInformationDTO';
import { ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED } from '../../../../Constants/AppConstants';



@injectable()
export class RoutePointReducer extends EntityReducer<RouteSegmentDTO, RoutePointHelperProps> {

    public Reduce(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps> {
        switch (action.type) {
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case ROUTE_POINT_ALL_LOADING_POINTS_LOADED:
                return this.ReduceAllLoadingPointsLoaded(state, action.Payload);
            case ROUTE_POINT_ALL_LOCALITIES_LOADED:
                return this.ReduceAllLocalitiesLoaded(state, action.Payload);
            case ROUTE_POINT_ALL_HANDLING_TYPES_LOADED:
                return this.ReduceAllHandlingTypesLoaded(state, action.Payload);
            case ROUTE_POINT_ADD_EXTRA_INFO_FIELD:
                return this.ReduceAddExtraInfoField(state);
            case ROUTE_POINT_REMOVE_EXTRA_INFO_FIELD:
                return this.ReduceRemoveExtraInfoField(state, action.Payload);
            case ROUTE_POINT_EDIT_EXTRA_INFO_FIELD:
                return this.ReduceEditExtraInfoField(state, action.Payload);
            case ROUTE_POINT_EXTRA_INFO_FIELD_KEY_CHANGED:
                return this.ReduceExtraInfoKeyChanged(state, action.Payload);
            case ROUTE_POINT_EXTRA_INFO_FIELD_VALUE_CHANGED:
                return this.ReduceExtraInfoValueChanged(state, action.Payload);
            case ROUTE_POINT_HAS_CUSTOM_POINT_FIELD_CHANGED:
                return this.ReduceHasCustomPointFieldChanged(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceAllLocalitiesLoaded(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: Array<LocalityTableDTO>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        if (state.DataDTO.UnloadingPointId !== undefined && payload.find(x => x.Id === state.DataDTO.UnloadingPointId) === undefined)
            payload.push(state.DataDTO.UnloadingPointId as any);
        return super.CollectionLoaded<LocalityTableDTO>(state, payload, [x => x.AllLocalities]);
    }

    private ReduceAllLoadingPointsLoaded(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: Array<LoadingPointTableDTO>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        if (state.DataDTO.LoadingPointId !== undefined && payload.find(x => x.Id === state.DataDTO.LoadingPointId) === undefined)
            payload.push(state.DataDTO.LoadingPointId as any);
        return super.CollectionLoaded<LoadingPointTableDTO>(state, payload, [x => x.AllLoadingPoints]);
    }

    private ReduceAllHandlingTypesLoaded(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: Array<HandlingTypeTableDTO>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        return super.CollectionLoaded<HandlingTypeTableDTO>(state, payload, [x => x.AllHandlingTypes]);
    }

    private ReduceDataLoaded(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: RouteSegmentDTO): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps> {
        let newState = super.DataLoaded(state, payload);

        return newState;
    }

    private ReduceHasCustomPointFieldChanged(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: boolean): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState = {...state, DataHelper: { ...state.DataHelper}};

        newState.DataHelper.HasCustomPointField = payload;

        return newState;
    }

    protected UpdateDTO(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps> {
        let newState = state;

        newState = super.UpdatedSelected<string, LoadingPointTableDTO>(newState,
            newState.DataDTO.LoadingPointId,
            x => x.LoadingPointId,
            x => x.SelectedLoadingPoint,
            x => x.Id)

        newState = super.UpdatedSelected<string, LocalityTableDTO>(newState,
            newState.DataDTO.UnloadingPointId,
            x => x.UnloadingPointId,
            x => x.SelectedLocality,
            x => x.Id)

        newState = super.UpdatedSelected<string, HandlingTypeTableDTO>(newState,
            newState.DataDTO.HandlingTypeId,
            x => x.HandlingTypeId,
            x => x.SelectedUnloadingType,
            x => x.Id)

        return newState;
    }

    private ReduceAddExtraInfoField(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState =  {...state, DataDTO: {...state.DataDTO}};

         newState.DataDTO.ExtraInformation = this.GetExtraInformationCopy(newState);

         let entity = new ExtraInformationDTO();
         entity.Id = uuid.v4();
         entity.Key = '';
         entity.Value = '';
         newState.DataDTO.ExtraInformation.push(entity);

        return newState;
    }

    private ReduceRemoveExtraInfoField(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: string): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState =  {...state, DataDTO: {...state.DataDTO}};

         newState.DataDTO.ExtraInformation = this.GetExtraInformationCopy(newState);

         let index = newState.DataDTO.ExtraInformation.findIndex(x => x.Id === payload);

         if (index >= 0)
             newState.DataDTO.ExtraInformation.splice(index, 1);
         else return state;

        return newState;
    }

    private ReduceEditExtraInfoField(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: string): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState = { ...state, DataHelper: { ...state.DataHelper}};

         newState.DataHelper.EditingExtraInfoFieldIds = this.EditingExtraInfoFieldIdsCopy(newState);

         newState.DataHelper.EditingExtraInfoFieldIds.push(payload);

        return newState;
    }

    private ReduceExtraInfoKeyChanged(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: IExtraFieldChanged): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState = { ...state, DataDTO: { ...state.DataDTO } };

         newState.DataDTO.ExtraInformation = this.GetExtraInformationCopy(newState);

         let index = newState.DataDTO.ExtraInformation.findIndex(x => x.Id === payload.Id)
         if (index >= 0)
             newState.DataDTO.ExtraInformation[index].Key = payload.Value;
         else return state;

        return newState
    }

    private ReduceExtraInfoValueChanged(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>, payload: IExtraFieldChanged): IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>{
        let newState = { ...state, DataDTO: { ...state.DataDTO } };

         newState.DataDTO.ExtraInformation = this.GetExtraInformationCopy(newState);

         let index = newState.DataDTO.ExtraInformation.findIndex(x => x.Id === payload.Id)
         if (index >= 0)
             newState.DataDTO.ExtraInformation[index].Value = payload.Value;
         else return state;

        return newState
    }

    private GetExtraInformationCopy(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>){
         if (state.DataDTO.ExtraInformation === undefined)
             return new Array()
         else
             return  state.DataDTO.ExtraInformation.slice(0);
    }

    private EditingExtraInfoFieldIdsCopy(state: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>){
         if (state.DataHelper.EditingExtraInfoFieldIds === undefined)
             return new Array()
         else
             return state.DataHelper.EditingExtraInfoFieldIds.slice(0);
    }

}
