import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { injectable } from "inversify";
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import axios from 'axios';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { AxiosResponse } from 'axios';
import { IActionPayloaded, IAction } from '../../../Infrastructure/Action/IAction';
import { BodyTypeTableDTO } from '../../../Domain/DTO/BodyTypeTableDTO';
import { HandlingTypeTableDTO } from '../../../Domain/DTO/HandlingTypeTableDTO';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { ZoneTableDTO } from '../../../Domain/DTO/ZoneTableDTO';

import { 
    ROUTE_RATE_SETTINGD_LOADED, 
    ROUTE_RATE_RATE_UPDATED } from '../../../Constants/AppConstants';

import { EntityAction } from '../BaseEntity/EntityAction';
import { SettingsEntityService } from '../Settings/SettingsEntityService';
import { SettingsServerInteraction } from '../../../Infrastructure/ServerInteraction/SettingsServerInteraction';
import { ERateChangeType } from '../../../Domain/Enum/ERateChangeType';
import { TableDTO } from '../../../Domain/DTO/TableDTO';
import { RequestServerInteraction } from '../../../Infrastructure/ServerInteraction/RequestServerInteraction';

@injectable()
export class RequestLogActions extends EntityAction {

    private _requestApi: RequestServerInteraction;

    constructor(){
        super();
        this._requestApi = VolmaContainer.get<RequestServerInteraction>(Types.RequestServerInteraction);
    }


    public Repeat(id: string, waitingText: string, reload: () => void){
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._requestApi.Repeat(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }
}