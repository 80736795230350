import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { CargoTransporterRouteRateDTO } from '../../../../Domain/DTO/CargoTransporterRouteRateDTO';
import { CargoTransporterRouteRateHelperProps } from './IHelperProps';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import {
    VOLMA_ENTITY_DATA_LOADED
} from '../../../../Constants/AppConstants';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { ZoneTableDTO } from '../../../../Domain/DTO/ZoneTableDTO';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { injectable } from 'inversify';



@injectable()
export class CargoTransporterRouteRateReducer extends EntityReducer<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps> {

    public Reduce(state: IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<CargoTransporterRouteRateDTO, CargoTransporterRouteRateHelperProps>{
        switch (action.type) {
            
            default:
                return state;
        }
    }
}
