import { INPUT_REMOVE_ACTIVE, INPUT_SET_ACTIVE, INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputActions } from '../Shared/BaseInputActions';
import { IInputPayload, IStringValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
export class VolmaInputActions extends BaseInputActions {

    public ChangeValue(name: string, value: string): IActionPayloaded<IStringValueChangedPayload> {
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: value} };
    }

    public SetActive(name: string): IActionPayloaded<IInputPayload> {
        return { type: INPUT_SET_ACTIVE, Payload: { InputName: name} };
    }

    public RemoveActive(name: string): IActionPayloaded<IInputPayload> {
        return { type: INPUT_REMOVE_ACTIVE, Payload: { InputName: name} };
    }
}

