import {
    BUSINESS_CALENDAR_LOADED,
    SETTINGS_CALENDAR_ENTRIES_REMOVED,
    SETTINGS_CALENDAR_ENTRY_ADDED,
    SETTINGS_CALENDAR_ENTRY_UPDATED,
    SETTINGS_COMPLAINT_TEMPLATES_LOADED,
    SETTINGS_LOADED,
    SETTINGS_MOUNTED,
    SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED,
    SETTINGS_UNMOUNTED
} from '../../../Constants/AppConstants';
import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import { BaseCustomLoadSaveReducer } from '../BaseCustomLoadSave/BaseCustomLoadSaveReducer';
import { SettingsHelperPropsInitial } from './ISettingsHelperProps';
import { ISettingsProps, SettingsPropsInitial } from './ISettingsProps';
import { injectable } from 'inversify';

@injectable()
export class SettingsReducer extends BaseCustomLoadSaveReducer{//VolmaComponentReducer<ISettingsProps> {
    private _entityService: EntityService;

    constructor() {
        super(SETTINGS_MOUNTED, SETTINGS_UNMOUNTED);

        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
    }

    public Reduce(state: ISettingsProps = SettingsPropsInitial, action: IActionPayloaded<any>): ISettingsProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case SETTINGS_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case SETTINGS_COMPLAINT_TEMPLATES_LOADED:
                return this.ReduceComplaintTemplatesLoaded(state, action.Payload);
            case SETTINGS_CALENDAR_ENTRY_ADDED:
                return this.ReduceCalendarEntryAdded(state, action.Payload);
            case SETTINGS_CALENDAR_ENTRY_UPDATED:
                return this.ReduceCalendarEntryUpdated(state, action.Payload);
            case SETTINGS_CALENDAR_ENTRIES_REMOVED:
                return this.ReduceCalendarEntriesRemoved(state, action.Payload);
            case SETTINGS_SELECTED_COMPLAINT_TEMPLATE_LOADED:
               return this.ReduceSelectedComplaintTemplateLoaded(state, action.Payload);
               case BUSINESS_CALENDAR_LOADED:
               return this.ReduceCalendarEntriesLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceDataLoaded(state: ISettingsProps, payload: SettingsDTO): ISettingsProps {
        let newState = <ISettingsProps>{ ...state,
            DataDTO: payload,
            DataHelper: JSON.parse(JSON.stringify(SettingsHelperPropsInitial)),
            DataDTOStringify: JSON.stringify(payload),
            ClosedCardParts: this.GetInfoPartStatesFromLocalStorage(state.EntityType
            )};
        return newState;
    }

    private ReduceComplaintTemplatesLoaded(state: ISettingsProps, payload: Array<ComplaintTemplateTableDTO>): ISettingsProps{
        let newState = { ...state, DataDTO: { ...state.DataDTO }};

        newState.DataHelper.ComplaintTemplateList = payload;

        return newState;
    }

    private ReduceSelectedComplaintTemplateLoaded(state: ISettingsProps, payload: ComplaintTemplateDTO): ISettingsProps{
        let newState = { ...state, DataDTO: { ...state.DataDTO }};

        if (payload.Id === newState.DataDTO.TemplateForCargotransporterLateForLoadingComplaintId)
            newState.DataHelper.SelectedTemplateForCargotransporterLateForLoadingComplaint = payload;
        if (payload.Id === newState.DataDTO.TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId)
            newState.DataHelper.SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint = payload;
        if (payload.Id === newState.DataDTO.TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId)
            newState.DataHelper.SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint = payload;
        if (payload.Id === newState.DataDTO.TemplateForCargoTransporterSupplyDisruptionComplaintId)
            newState.DataHelper.SelectedTemplateForCargoTransporterSupplyDisruptionComplaint = payload;

        return newState;
    }

    private ReduceCalendarEntryAdded(state: ISettingsProps, payload: CalendarEntryDTO): ISettingsProps {
        let newState = this.GetStateForCalendarEvent(state);

        newState.DataDTO.BusinessCalendar.push(payload);

        return newState;
    }

    private ReduceCalendarEntryUpdated(state: ISettingsProps, payload: CalendarEntryDTO): ISettingsProps {
        let newState = this.GetStateForCalendarEvent(state);

        let index = this.GetCalendarEntryIndex(newState, payload);
        newState.DataDTO.BusinessCalendar[index] = payload;

        return newState;
    }

    private ReduceCalendarEntriesRemoved(state: ISettingsProps, payload: string[]): ISettingsProps {
        let newState = this.GetStateForCalendarEvent(state);

        for(let id of payload){
            let index = newState.DataDTO.BusinessCalendar.findIndex(x => x.Id === id)
            if (index >= 0)
                newState.DataDTO.BusinessCalendar.splice(index, 1);
        }

        return newState;
    }

    public static Initialize(): (state: ISettingsProps, action: IActionPayloaded<ISettingsProps>) => ISettingsProps {
        const reducer = new SettingsReducer();
        return (state: ISettingsProps, action: IActionPayloaded<ISettingsProps>) => reducer.Reduce(state, action);
    }

    private GetStateForCalendarEvent(state: ISettingsProps): ISettingsProps{
        let newState = { ...state, DataHelper: { ...state.DataHelper} };

        if (newState.DataDTO.BusinessCalendar === undefined)
            newState.DataDTO.BusinessCalendar = [];
        else
            newState.DataDTO.BusinessCalendar = newState.DataDTO.BusinessCalendar.slice(0);

        return newState;
    }

    private GetCalendarEntryIndex(state: ISettingsProps, dto: CalendarEntryDTO){

        let index = state.DataDTO.BusinessCalendar.findIndex(x => x.Id === dto.Id);
        if (index < 0)
            throw new Error('failed to find payload [' + JSON.stringify(dto) + '] in the array ' + JSON.stringify(state.DataDTO.BusinessCalendar));

        return index;
    }

    private ReduceCalendarEntriesLoaded(state: ISettingsProps, payload: Array<CalendarEntryDTO>): ISettingsProps{
        let newState = this.GetStateForCalendarEvent(state);

        newState.DataDTO.BusinessCalendar = payload;
        return newState;
    }
}