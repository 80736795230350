import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { BillAction } from '../Action/BillAction';
import { CloseAction } from '../Action/CloseAction';
import { Billed } from './Billed';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { CancelAction } from '../Action/CancelAction';

export class Rejected extends ComplaintStatusDefault{

    public TitleCurrent : string = "complaint:StatusRejectedCurrent";
    public TitleFinished: string = "complaint:StatusRejectedFinished";
    public TitleFuture  : string = "complaint:StatusRejectedFuture";


    public GetActions(dto: ComplaintDTO){
        return [new CancelAction(), new BillAction()];
    }

    constructor(){
        super();

        this._prevState = new Billed();
        this._currentStatus = EComplaintState.Rejected;
    }
}