import { injectable } from 'inversify';
import * as React from 'react';
import { HTML_BR } from '../../../../Constants/AppConstants';
import { DeliveryStateTableDTO } from '../../../../Domain/DTO/DeliveryStateTableDTO';
import { ExtraInformationDTO } from '../../../../Domain/DTO/ExtraInformationDTO';
import { RouteSegmentDTO } from '../../../../Domain/DTO/RouteSegmentDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { ERoutePointType } from '../../../../Domain/Enum/ERoutePointType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { ChangelogService } from '../../../../Infrastructure/Services/ChangelogService';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCellHeightMeasurers } from '../../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableDeliveryCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import i18next from '../../../i18n';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { DeliveryStateHelper } from './DeliveryStateHelper';
import { IDeliveryStateTableHelperProps } from './ITableHelperProps';
import { DeliveryService } from '../../../../Infrastructure/Services/DeliveryService';
import { EWithdrawOwner } from '../../../../Domain/Enum/EWithdrawOwner';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import { EDeliveryStateSource } from '../../../../Domain/Enum/EDeliveryStateSource';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';

@injectable()
export class DeliveryStateEntityService extends BaseEntityService<DeliveryStateTableDTO, {}> {
    private _volmaTableService: VolmaTableService;
    private _helper:            DeliveryStateHelper;
    private _changelogService:  ChangelogService;
    private _routeService:      RouterService;
    private _deliveryService:   DeliveryService;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._helper            = VolmaContainer.get<DeliveryStateHelper>(Types.DeliveryStateHelper);
        this._changelogService  = VolmaContainer.get<ChangelogService>(Types.ChangelogService);
        this._routeService      = VolmaContainer.get<RouterService>(Types.RouterService);
        this._deliveryService   = VolmaContainer.get<DeliveryService>(Types.DeliveryService);

        this.GetExtraInfoCellText = this.GetExtraInfoCellText.bind(this);
        this.GetChangelogCellText = this.GetChangelogCellText.bind(this);
        this.GetCommentCellText   = this.GetCommentCellText.bind(this);
        this.GetTableRowClass = this.GetTableRowClass.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.DeliveryState,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryStateTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryStateTableDTO) => val.Timestamp),
                    CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryDateCellRenderer,
                    Weight: 0.5
                },
                {
                    DataKey: "CurrentState",
                    CellRenderer: (props: IVolmaTableProps<IDeliveryStateTableHelperProps>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableDeliveryCellRenderers.DeliveryStateStatusCellRenderer(
                            props,
                            params,
                            this._enumService,
                            this._routeService,
                            this._formatService,
                            this._deliveryService,
                            this._authService),
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                },
                {
                    DataKey: "CurrentStateExtraInfo",
                    CellTextGetter: this.GetExtraInfoCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer,
                },
                {
                    DataKey: "Changelog",
                    CellTextGetter: this.GetChangelogCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                },
                {
                    DataKey: "Comments",
                    CellTextGetter: this.GetCommentCellText,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                }
            ]
        );
    }

    private GetExtraInfoCellText(props: IVolmaTableProps<IDeliveryStateTableHelperProps>, rowIndex: number): string {

        let dto = props.Data[rowIndex] as DeliveryStateTableDTO;
        let tender: string = undefined;
        if (this.IsValueNotEmpty(dto.TenderId)) {
            tender = dto.TenderIdentifier;
        }

        let startBidding: string = undefined;
        let endBidding: string = undefined;
        if (dto.DeliveryState === EDeliveryState.Assignation && dto.Assigner === ERequestForDeliveryAssigner.Bidding) {
            startBidding = this.GetSafeDateTime(dto.BiddingStart);
            endBidding = this.GetSafeDateTime(dto.BiddingEnd);
        }

        let driver: string = undefined;
        let vehicle: string = undefined;
        if (dto.DeliveryState === EDeliveryState.Approved) {
            driver = this.GetSafeString(dto.DriverName);
            vehicle = this.GetSafeString(dto.VehicleName);
        }

        let driverLocation: string = undefined
        if (dto.DriverLocation) {
            driverLocation = `${dto.DriverLocation.Latitude} ${dto.DriverLocation.Longitude}`
        }


        let withdrawOwner: EWithdrawOwner = undefined;
        if(dto.WithdrawOwner !== undefined)
            withdrawOwner = dto.WithdrawOwner;

        let result = new Array<string>();
        if (tender !== undefined)
            result.push(`${i18next.t("deliverystate:TenderId")}: ${tender}`);

        if (startBidding !== undefined) {
            result.push(`${i18next.t("deliverystate:BiddingStart")}: ${startBidding}`);
            result.push(`${i18next.t("deliverystate:BiddingEnd")}: ${endBidding}`);
        }
        if (driver !== undefined) {
            result.push(`${i18next.t("deliverystate:DriverId")}: ${driver}`);
            result.push(`${i18next.t("deliverystate:VehicleId")}: ${vehicle}`);
        }
        if (driverLocation !== undefined) {
            result.push(`${i18next.t("deliverystate:DriverLocation")}: ${driverLocation}`)
        }

        if (withdrawOwner !== undefined && withdrawOwner !== null) {
            result.push(`${this._enumService.GetOptionLocalized(() => EWithdrawOwner, withdrawOwner)}`);
        }

        return result.join(HTML_BR);
    }

    private GetChangelogCellText(props: IVolmaTableProps<IDeliveryStateTableHelperProps>, rowIndex: number): string {

        let dto = props.Data[rowIndex] as DeliveryStateTableDTO;
        return this._changelogService.GetChangelog(EEntityType.Delivery, dto.OldValues, dto.NewValues, [{
            isApplicable: (parentFields: Array<string>) => parentFields.length > 1 && parentFields[parentFields.length - 2] === PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.ExtraInformation),
            localizer: (fieldName, fieldValue: ExtraInformationDTO) => fieldValue.Key + ":" + fieldValue.Value
        }
        ])
    }



    private GetCommentCellText(props: IVolmaTableProps<IDeliveryStateTableHelperProps>, rowIndex: number): string {

        let dto = props.Data[rowIndex] as DeliveryStateTableDTO;
        let comment: string = undefined;
        if (this._authService.IsCargoTransporter)
            comment = dto.CommentsForCargoTransporter;
        else
            comment = dto.CommentsForShipper;

        const splitter = "#"
        if (comment && comment.includes(splitter)) {
            const [date, text] = comment.split(splitter)
            comment = `${i18next.t("deliverystate:NewArriveTime")}: ${date} ${HTML_BR}${i18next.t("deliverystate:Cause")}: ${text}`
        }

        return this._helper.GetComment(comment, HTML_BR);
    }

    public GetTableRowClass(rowData: DeliveryStateTableDTO): string{
        return this._table("row", { body: true, mobile: rowData.StateSource === EDeliveryStateSource.Mobile}).toString();
    }

    public InitializeEntity() {
        return undefined;
    }
    public GetReducer() {
        return undefined;
    }
    public GetEditor() {
        return <span></span>;
    }
    public GetEditorModal() {
        return <span></span>;
    }
    public GetInitialDataHelper() {
        return undefined;
    }
    public OnAfterDataLoaded() {
        return undefined;
    }

}