import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { history } from '../../../ConfigureStore';
import {
    BASE_ENTITY_MOUNTED,
    BASE_ENTITY_UNMOUNTED,
    FORM_SUBMITTED,
    SIGNALR_UNMUTE_THRESHOLD,
    VOLMA_ENTITY_CLEAR_FOOTER,
    VOLMA_ENTITY_CLEAR_REQUESTS,
    VOLMA_ENTITY_DATA_LOADED,
    VOLMA_ENTITY_DTO_STRINGIFY_UPDATED,
    VOLMA_ENTITY_SET_ENTITY_SERVICE,
    VOLMA_SIGNALR_MUTE,
    VOLMA_SIGNALR_UNMUTE,
} from '../../../Constants/AppConstants';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { TableSignalRServerInteraction } from '../../../Infrastructure/ServerInteraction/TableSignalRServerInteraction';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { IEntityService } from './IEntityService';
import { IRouteProps } from '../../../Infrastructure/Router/RouteProps';
import { RouteUtils } from '../../../Infrastructure/Router/RouteUtils';

@injectable()
export class BaseEntityActions extends ComponentActions {
    private _apiActions: ApiActions;
    private _entityService: EntityService;
    private _api: TableServerInteraction;
    private _apiSignalR: TableSignalRServerInteraction;

    private _unmuteThreshold = SIGNALR_UNMUTE_THRESHOLD

    constructor() {
        super();
        super.Initialize(BASE_ENTITY_MOUNTED, BASE_ENTITY_UNMOUNTED);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._api = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._apiSignalR = VolmaContainer.get<TableSignalRServerInteraction>(Types.TableSignalRServerInteraction);
    }

    public SaveEntity(entity: EEntityType, baseEntity: EEntityType, dto: IEntityDTO, isFormValid: boolean, waitingText: string, props: IRouteProps, alwaysPost: boolean, id?: string) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            dispatch(this.MuteSignalR())
            this._apiActions.DoApiRequest(
                dispatch,
                () => (id === undefined || alwaysPost) ? this._api.PostEntity(baseEntity, dto) : this._api.PutEntity(baseEntity, id, dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    dispatch(this.UpdateDTOStringify(dto));
                    let back = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(props.location, x => x.search), "back");
                    if (back !== undefined){
                        history.push(back);
                    }
                    else{
                        history.push(this._entityService.GetEntityTableRoute(entity));
                    }
                    setTimeout(() => dispatch(this.UnmuteSignalR()), this._unmuteThreshold);
                },
                () => setTimeout(() => dispatch(this.UnmuteSignalR()), this._unmuteThreshold));
        }
    }

    public SetEntityService(entityService: IEntityService<IEntityDTO, any>): IActionPayloaded<IEntityService<IEntityDTO, any>> {
        return { type: VOLMA_ENTITY_SET_ENTITY_SERVICE, Payload: entityService };
    }

    public ClearFooter() : IAction{
        return { type: VOLMA_ENTITY_CLEAR_FOOTER };
    }

    public ClearRequests(): IAction {
        return { type: VOLMA_ENTITY_CLEAR_REQUESTS };
    }

    public LoadEntityNoSignalR(entityService: IEntityService<IEntityDTO, any>, entity: EEntityType, waitingText: string, id?: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.GetEntity(entity, id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data.replace(/null/g, "\"\""));

                    entityService.OnAfterDataLoaded(data);
                    entityService.InitializeDefaultDTOValues(data)
                    dispatch(this.EntityLoaded(data));
                });
        }
    }

    public LoadEntity(entityService: IEntityService<IEntityDTO, any>, entity: EEntityType, waitingText: string, id?: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiSignalR.GetEntity(entity, id)
                    .then((data) => {
                        data = JSON.parse(JSON.stringify(data).replace(/null/g, "\"\""));

                        entityService.OnAfterDataLoaded(data);
                        entityService.InitializeDefaultDTOValues(data)
                        dispatch(this.EntityLoaded(data));
                     })
                    .catch((error) => { console.error(error); dispatch(this.LoadEntityNoSignalR(entityService, entity, waitingText, id)) })
                ,
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    public EntityLoaded(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_ENTITY_DATA_LOADED, Payload: data };
    }

    public UpdateDTOStringify(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_ENTITY_DTO_STRINGIFY_UPDATED, Payload: data };
    }

    private MuteSignalR(): IAction{
        return { type: VOLMA_SIGNALR_MUTE };
    }

    private UnmuteSignalR(): IAction{
        return { type: VOLMA_SIGNALR_UNMUTE };
    }
}