import { LatLng } from 'leaflet';
import * as React from 'react';
import { Component } from 'react';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../Infrastructure/Validation/VolmaNumberValidator';
import i18next from '../i18n';
import { VolmaMap } from '../VolmaMap/VolmaMap';
import { IVolmaNumberProps } from '../VolmaNumber/IVolmaNumberProps';
import { IVolmaMapWithSelectDTOProps } from './IVolmaMapWithSelectDTOProps';
import "./VolmaMapWithSelect.scss";
import { VolmaMapWithSelectDTOActions } from './VolmaMapWithSelectDTOActions';
import { VolmaMapWithSelectDTOReducer } from './VolmaMapWithSelectDTOReducer';

export class VolmaMapWithSelectDTO extends Component<IVolmaMapWithSelectDTOProps, {}> {
    private _mainInfo = volmaBlock('main-info');

    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _mapReducer: VolmaMapWithSelectDTOReducer;
    private _mapAction: VolmaMapWithSelectDTOActions;
    private _volmaNumber       = volmaBlock('volma-number');
    private _defaultInput      = volmaBlock("volma-input");
    private _numberNav         = volmaBlock("number-nav");
    private _defaultInputError = volmaBlock('default-input-error');
    private _volmaTime         = volmaBlock('volma-time');

    constructor(props: IVolmaMapWithSelectDTOProps, context: any) {
        super(props, context);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._mapReducer = VolmaContainer.get<VolmaMapWithSelectDTOReducer>(Types.VolmaMapWithSelectDTOReducer);
        this._mapAction = VolmaContainer.get<VolmaMapWithSelectDTOActions>(Types.VolmaMapWithSelectDTOAction)
    }

    public componentDidMount() {
        this.props.dispatch(this._mapAction.Register(this.props.Name, this._mapReducer, this.props.Validator || this._numberValidator, this.props));        
    }
  
    private handleLonChange = (event: any) => {   
        if (this._numberValidator.Validate({Value: event.target.value}).IsValid) {
            this.props.dispatch(this._mapAction.LongitudeChanged(event.target.value))
        }
    }

    private handleLatChange = (event: any) => {
        if (this._numberValidator.Validate({Value: event.target.value}).IsValid) {
            this.props.dispatch(this._mapAction.LatitudeChanged(event.target.value))
        }
    }

    private handleClick = (latLng: LatLng) => {
        if (!this.props.Readonly) {
            this.props.dispatch(this._mapAction.GisCoordinatesChanged(latLng));
        }                  
    }

    render() {        
        const latProps = {
            type: "text",
            value: this.props.Value?.Latitude !== undefined ? this.props.Value.Latitude : 0,
            onChange: this.handleLatChange,
            className: this._defaultInput("input", { center: true }).mix(["input", "input-number", "volma-map-input-number"]),
            readOnly: this.props.Readonly === true
        }

        const lonProps = {
            type: "text",
            value: this.props.Value?.Longitude !== undefined ? this.props.Value.Longitude : 0,
            onChange: this.handleLonChange,
            className: this._defaultInput("input", { center: true }).mix(["input", "input-number", "volma-map-input-number"]),
            readOnly: this.props.Readonly === true
        }

        return this.GetMapPart(latProps, lonProps);
    }

    public GetLongInput(inputProps: any, label: string) {
        return <div className={(this._volmaNumber()).toString()}>
                    <div className={(this._defaultInput.mix(this._volmaNumber("text"))).toString()}>
                        <span className={(this._defaultInput("input", {text: true }).mix(["input"])).toString()}>{label}</span>
                    </div>
                    <div className={(this._defaultInput.mix(['long-value', this._volmaNumber("value")])).toString()}>
                        <input {...inputProps} />
                    </div>
                </div>
    }

    public GetMapPart(latProps, lonProps) {
        const initialCircleNumber = 1
        return (
            <div>
                <div className={this._mainInfo("row")}>
                    <div className={this._mainInfo("cell", { 33: true })}>
                        <div className={this._mainInfo("line", { full: true })}>
                            {this.GetLongInput(latProps, i18next.t('volmamap:Latitude'))}
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true })}>
                        <div className={this._mainInfo("line", { full: true })}>
                            {this.GetLongInput(lonProps, i18next.t('volmamap:Longitude'))}
                        </div>
                    </div>
                </div>

                <div className={this._mainInfo("row")}>
                    <div className={this._mainInfo("line", { fill: true })}>
                        <VolmaMap 
                            center={{lat: latProps.value, lng: lonProps.value}} 
                            onClick={this.handleClick} 
                            locations={[{Latitude: latProps.value, Longitude: lonProps.value, OrderNumber: initialCircleNumber}]} />
                    </div>
                </div>

            </div>

        )
    }

}