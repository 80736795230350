import { EDayOfWeek } from '../Enum/EDayOfWeek';
import { EMonth } from '../Enum/EMonth';
import { ERating } from '../Enum/ERating';
import { EDeliveryState } from '../Enum/EDeliveryState';
import { EUserRole } from '../Enum/EUserRole';
import { EComplaintTemplateDirection } from '../Enum/EComplaintTemplateDirection';
import { EEntityType } from '../Enum/EEntityType';
import { ETenderOperationType } from '../Enum/ETenderOperationType';
import { ETenderState } from '../Enum/ETenderState';
import { EComplaintState } from '../Enum/EComplaintState';
import { EComplaintCommentState } from '../Enum/EComplaintCommentState';
import { ECalendarEntryType } from '../Enum/ECalendarEntryType';
import { EReportType } from '../Enum/EReportType';
import { ERequestForDeliveryAssigner} from '../Enum/ERequestForDeliveryAssigner';
import { ERestorePasswordResult} from '../Enum/ERestorePasswordResult';
import { ERegisterResult} from '../Enum/ERegisterResult';
import { EUpdatePasswordResult} from '../Enum/EUpdatePasswordResult';
import { ERoutePointType} from '../Enum/ERoutePointType';
import { ERatingType} from '../Enum/ERatingType';
import { EApiRequestDirection } from '../Enum/EApiRequestDirection';
import { EComplaintCommentDirection } from '../Enum/EComplaintCommentDirection';
import { EDeliveryCompletionState } from '../Enum/EDeliveryCompletionState';
import { EWithdrawOwner } from '../Enum/EWithdrawOwner';
import { EDriverScoreKind } from '../Enum/EDriverScoreKind';
import { EDeliveryStateSource } from '../Enum/EDeliveryStateSource';
import { ECargoTransporterRatingReportType } from '../Enum/ECargoTransporterRatingReportType';
import { EOperationType } from '../Enum/EOperationType';



export class CargoTransporterRouteRateDTO {
	public LoadingPointId?: string = undefined;
	public TargetZoneId?: string = undefined;
	public Rate?: number = undefined;
	public VehicleCount?: number = undefined;
	public CargoTransporterId?: string = undefined;
	public Id?: string = undefined;
}
 
