import { WithTranslationProps } from 'react-i18next/*';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IEntityService } from '../../Entity/BaseEntity/IEntityService';
import { IVolmaFormProps, VolmaFormPropsInitial } from '../../VolmaForm/IVolmaFormProps';
import { IRouteProps } from '../../../Infrastructure/Router/RouteProps';

export interface IBaseTableProps<THelper> extends IVolmaFormProps, WithTranslationProps, IRouteProps {
    HeaderLinks?: JSX.Element;
    EntityService?: IEntityService<IEntityDTO, any>;
    DataHelper?: THelper;
}

export const BaseTablePropsInitial: IBaseTableProps<any> = {
    ...VolmaFormPropsInitial,
    HeaderLinks: undefined,
    EntityService: undefined,
    DataHelper: {}
};
