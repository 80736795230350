import { VolmaTableCommonCellRenderers } from './VolmaTableCommonCellRenderers';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { TenderChangelogTableDTO } from '../../../../Domain/DTO/TenderChangelogTableDTO';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { ChangelogService } from '../../../../Infrastructure/Services/ChangelogService';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../../Infrastructure/Types';
import { TenderBetAction } from '../../../Entity/Tender/Actions/TenderBetAction';
import { ITenderTableHelperProps } from '../../../Entity/Tender/Table/ITableHelperProps';
import { TenderTableActions } from '../../../Entity/Tender/Table/TenderTableActions';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';
import { injectable } from 'inversify';
import i18next from '../../../i18n';
import * as React from 'react';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import { isNullOrUndefined } from '../../../../Infrastructure/Services/Utils';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';


@injectable()
export class VolmaTableTenderCellRenderers {

    public static TenderBetCellRenderer(
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        const tender = props.Data[params.rowIndex] as TenderTableDTO
        const tenderId = tender.Id;
        const userBet: TenderBetTableDTO = props.DataHelper.AllUserBets !== undefined ? props.DataHelper.AllUserBets.find((x: TenderBetTableDTO) => x.TenderId === tenderId) : undefined

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        const authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);

        const isActive = VolmaTableCommonCellRenderers.GetTimeService().IsInFuture(tender.EndBiddingDate);
        if (authService.IsCargoTransporter()) {  
                      
            const forecastRatingPredicate = tender.MultiFactoryId 
                ? (x: RatingTableDTO) => x.MultiFactoryId === tender.MultiFactoryId 
                : (x: RatingTableDTO) => x.LoadingPointId === tender.LoadingPointId;

            const forecastRatingsDefined = !isNullOrUndefined(props.DataHelper.CargoTransporterForecastRatings);
            const rating = forecastRatingsDefined ? props.DataHelper.CargoTransporterForecastRatings.find(forecastRatingPredicate) : undefined;
            
            const isFiltered = isNullOrUndefined(tender.ForecastRatingFilter) || (rating !== undefined && ((Number)(rating.RatingValue)) <= ((Number)(tender.ForecastRatingFilter)));
            
            if (userBet !== undefined)
                return (
                    <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, orange: true })).toString()} style={style} key={key}>
                        <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}><b>{i18next.t("tender:YourBetIs")}:</b> {userBet.Bet}</span>
                        </div>
                        {isFiltered && isActive && <a onClick={(event: any) => VolmaTableTenderCellRenderers.AddOrEditTenderBet(event, tenderId, props, params)}
                            className={(VolmaTableCommonCellRenderers._setWager({orange: true }).mix([VolmaTableCommonCellRenderers._table("wager")])).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._setWager("ico").mix(["rub-circle-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub-circle"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._setWager("text")).toString()}>{i18next.t("tender:MakeBet")}</span>
                            <span className={(VolmaTableCommonCellRenderers._setWager("bg")).toString()}>
                                <svg className={(VolmaTableCommonCellRenderers._setWager("bg-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                                </svg>
                            </span>
                        </a>}
                    </div>
                )
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("tender:YouDidNotDoABet")}</span>
                    </div>
                    {isFiltered && isActive && <a onClick={(event: any) => VolmaTableTenderCellRenderers.AddOrEditTenderBet(event, tenderId, props, params)} className={(VolmaTableCommonCellRenderers._setWager.mix([VolmaTableCommonCellRenderers._table("wager")])).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._setWager("ico").mix(["rub-circle-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub-circle"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._setWager("text")).toString()}>{i18next.t("tender:MakeBet")}</span>
                        <span className={(VolmaTableCommonCellRenderers._setWager("bg")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._setWager("bg-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                        </span>
                    </a>}
                </div>
            )
        }
        else {
            if (userBet !== undefined)
                return (
                    <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, green: true })).toString()} style={style} key={key}>
                        <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}><b>{i18next.t("tender:BetIs")}:</b> {userBet.Bet}</span>
                        </div>
                    </div>
                )
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("tender:NoBets")}</span>
                    </div>
                </div>
            )
        }
    }

    public static TenderChangelogCellRenderer(
        entity: EEntityType,
        changelogService: ChangelogService,
        enumService: EnumService,
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        const changelog = props.Data[params.rowIndex] as TenderChangelogTableDTO;
        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);

        const changes = changelogService.GetChangelog(EEntityType.Tender, changelog.OldValues, changelog.NewValues, [{
            isApplicable: (parentFields: Array<string>) => parentFields.length > 0 && parentFields[parentFields.length - 1] === PropertyHelper.GetPropertyName((x: TenderTableDTO) => x.State),
            localizer: (fieldNam, fieldValue) => enumService.GetOptionStringLocalized(() => ETenderState, fieldValue)
        }
        ])
        const hasChanges = PropertyHelper.IsString(changes) && changes.length > 0;
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {multi: true, selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    {hasChanges && <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-relaod-ico"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#relaod"></use>
                    </svg>}
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()} dangerouslySetInnerHTML={{ __html: changes }}></span>
                </div>
            </div>
        )
    }

    public static TenderCommentsCellRenderer(
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        const changelog = props.Data[params.rowIndex] as TenderChangelogTableDTO;
        let comments = '';
        const authService: AuthenticationService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        if (authService.IsShipper())
            comments = changelog.CommentsForShipper;
        if (authService.IsCargoTransporter())
            comments = changelog.CommentsForCargoTransporterShipper;

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{comments}</span>
                </div>
            </div>
        )
    }

    protected static AddOrEditTenderBet(
        event: Event,
        tenderId: string,
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        event.preventDefault();
        event.stopPropagation();

        const tenderBetAction = new TenderBetAction(); VolmaContainer.get<TenderTableActions>(Types.TenderTableActions);
        
        if (tenderBetAction.IsAvailable([tenderId], [props.Data[params.rowIndex]], props.DataHelper)) {
            tenderBetAction.OnActivate(undefined, EEntityType.Tender, [tenderId], [props.Data[params.rowIndex]], props.dispatch, () => { }, props.DataHelper)
        }
    }
}