import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { ComplaintCommentDTO } from '../../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { FileDTO } from '../../../Domain/DTO/FileDTO';

export class ComplaintHelperProps {
    SelectedComplaintTemplate: ComplaintTemplateTableDTO
    SelectedCargoTransporter: CargoTransporterTableDTO

    Comments: Array<ComplaintCommentDTO>
    AdditionalFiles: Array<FileTableDTO>
    UploadedFilesCache: Array<FileDTO>

    ComplaintFile: FileTableDTO
    Author: UserDTO;
    CargoTransporter: CargoTransporterTableDTO;

    IsAcceptedPaymentEditing: boolean;
    SavedAcceptedPaymentValue: number;
    Message: string;

    ShowDummyDeliverySelect: boolean;
    DeliveriesCache: object;
}

export const InitialComplaintHelperProps: ComplaintHelperProps = <ComplaintHelperProps>{
    AllComplaintTemplates: [],
    Comments: [],
    AdditionalFiles: [],
    UploadedFilesCache: [],
    SelectedComplaintTemplate: undefined,
    SelectedCargoTransporter: undefined,

    ComplaintFile: undefined,
    Author: undefined,
    CargoTransporter: undefined,
    Message: "",
    IsAcceptedPaymentEditing: false,
    SavedAcceptedPaymentValue: undefined,

    ShowDummyDeliverySelect: true,
    DeliveriesCache: {}

};