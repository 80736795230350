import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { INPUT_FILE_UPLOAD_SUCCESSFULLY } from '../../Constants/AppConstants';
import { IFileUploadSuccessfullyPayload } from '../VolmaFile/Payloads';
import { DataDTO } from '../../Domain/DTO/DataDTO';
import { FileDTO } from '../../Domain/DTO/FileDTO';
import { EntityReducer } from '../Entity/BaseEntity/EntityReducer';
import { FileExtendedDTO } from './FileExtendedDTO';
import { injectable } from 'inversify';


@injectable()
export class FileReducer extends EntityReducer<FileExtendedDTO, {}> {

    public Reduce(state: IBaseEntityProps<FileExtendedDTO, {}>, action: IActionPayloaded<any>): IBaseEntityProps < FileExtendedDTO, {}> {
        switch (action.type) {
            case INPUT_FILE_UPLOAD_SUCCESSFULLY:
                return this.ReduceFileUploadedSuccessfully(state, action.Payload)

            default:
                return state;
        }
    }

    private ReduceFileUploadedSuccessfully(state: IBaseEntityProps<FileExtendedDTO, {}>, payload: IFileUploadSuccessfullyPayload) {
        return { ...state, DataDTO: { ...state.DataDTO, FileDTO: payload.Value}};
    }
}
