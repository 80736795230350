import { UserDTO } from '../../Domain/DTO/UserDTO';
import { AuthenticationService } from '../Services/AuthService';
import { IVolmaTableAction } from '../../Components/Table/VolmaTable/IVolmaTableProps';
import { IStatus } from './IStatus';
import { EntityDTO } from '../../Domain/DTO/EntityDTO';
import { VolmaContainer } from '../InversifyInject';
import { TimeService } from '../Services/TimeService';
import { Types } from '../Types';

export abstract class StatusDefault<TDTO extends EntityDTO, THelper> implements IStatus<TDTO, THelper>{
    protected _timeService;

    TitleCurrent: string;
    TitleFinished: string;
    TitleFuture: string;
    
    public abstract IsActive(dto: TDTO, dtoHelper: THelper): boolean;
    public abstract IsCurrent(dto: TDTO): boolean;
    public abstract IsFinished(dto: TDTO): boolean;

    public GetTimeLeftInStatus(dto: TDTO, dtoHelper: THelper): Date{
        return undefined;
    }
    
    public GetStatusFinishedDate(dto: TDTO, dtoHelper: THelper): Date{
        return undefined;
    }

    public abstract GetActions(dto:TDTO, currentUser: UserDTO, authService: AuthenticationService): Array<IVolmaTableAction<THelper>>;

    constructor(){
        this._timeService = VolmaContainer.get<TimeService>(Types.TimeService)
    }
}