import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { DeliveryWithdrawDTO } from '../../../../Domain/DTO/DeliveryWithdrawDTO';
import i18next from '../../../i18n';
import { DeliveryWithdrawExtendedDTO } from '../ActionWithdraw/DeliveryWithdrawExtendedDTO';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TableDataDTO } from '../../../../Domain/TableDataDTO';
import { EWithdrawOwner } from '../../../../Domain/Enum/EWithdrawOwner';

export class DeliveryWithdrawAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:DeliveryWithdraw";

    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>) {
        if (this._authService.IsShipper()) {
            if (!PropertyHelper.IsArray(selectedIds) || selectedIds.length < 1)
                return false;
            for (let selectedId of selectedIds) {
                let itm = data.find(x => x.Id === selectedId);
                if (itm === undefined)
                    return false
                if (!(itm.State === EDeliveryState.Assignation ||
                      itm.State === EDeliveryState.NotApproved ||
                      itm.State === EDeliveryState.NotAccepted ||
                      itm.State === EDeliveryState.Assigned ||
                      itm.State === EDeliveryState.Accepted ||
                      itm.State === EDeliveryState.Approved ||
                      itm.State === EDeliveryState.WaitForLoading ||
                      itm.State === EDeliveryState.VehicleArrivedAtLoadingPoint ||
                      itm.State === EDeliveryState.VehicleArrivedAtUnloadingPoint ||
                      itm.State === EDeliveryState.LatenessRegistration ||
                      itm.State === EDeliveryState.LoadingLatenessRegistration ||
                      itm.State === EDeliveryState.LoadingBegin ||
                      itm.State === EDeliveryState.LoadingEnd ||
                      itm.State === EDeliveryState.Delivery))
                    return false
            }
            return true;
        }
        return false;
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        let allStatesAreNew = true;
        let hasStateAssigned = false;
        for (let selectedId of selectedIds) {
            let itm = data.find(x => x.Id === selectedId);
            if (itm !== undefined)
            {
                hasStateAssigned = this.IsAssigned(itm.State);
                if(hasStateAssigned)
                    break;
            }


        }
        if(hasStateAssigned){
            allStatesAreNew = false;
        }
        else {
            for (let selectedId of selectedIds) {
                let itm = data.find(x => x.Id === selectedId);
                if (itm !== undefined)
                {
                    if(!(itm.State === EDeliveryState.New || itm.State === EDeliveryState.Revoked))
                    {
                        allStatesAreNew = false;
                        break;
                    }
                }
            }
        }
        let dto = new DeliveryWithdrawExtendedDTO();
        dto.DeliveryState = allStatesAreNew ? EDeliveryState.New : EDeliveryState.Assignation;
        dto.ShowCargotransporterBlameCheckBox = hasStateAssigned;
        dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryWithdraw, dto, undefined,  (dto) => {
            if(dto.Comment !== undefined && dto.CommentForCargotransporter === undefined && dto.CommentForShipper === undefined){
                dto.CommentForCargotransporter = dto.Comment;
                dto.CommentForShipper = dto.Comment;
            }

            let tableDataDTO = new TableDataDTO<DeliveryStateDTO>();
            tableDataDTO.Items = new Array<DeliveryStateDTO>();
            tableDataDTO.Count = 0;
            for (let selectedId of selectedIds) {
                let stateDTO = new DeliveryStateDTO();
                stateDTO.DeliveryId = selectedId;
                stateDTO.CommentsForCargoTransporter = dto.CommentForCargotransporter;
                stateDTO.CommentsForShipper = dto.CommentForShipper;
                stateDTO.DeliveryState = EDeliveryState.Revoked;
                stateDTO.Assigner = ERequestForDeliveryAssigner.Tender;
                stateDTO.WithdrawOwner = !dto.IsCargotransporterBlame || !this.IsAssigned(data.find(x => x.Id === selectedId).State) ?
                                        EWithdrawOwner.Shipper:
                                        undefined;
                tableDataDTO.Items.push(stateDTO);
                tableDataDTO.Count++;
            }

            dispatch(this._deliveryTableActions.DeliveryAddStates(tableDataDTO, i18next.t("common:Loading"), reloadData));

        }))
    }

    private IsAssigned(deliveryState: EDeliveryState): boolean{
        if(deliveryState === EDeliveryState.Assigned                      ||
            deliveryState === EDeliveryState.Accepted                     ||
            deliveryState === EDeliveryState.Approved                     ||
            deliveryState === EDeliveryState.LoadingLatenessRegistration  ||
            deliveryState === EDeliveryState.WaitForLoading               ||
            deliveryState === EDeliveryState.VehicleArrivedAtLoadingPoint ||
            deliveryState === EDeliveryState.LoadingBegin                 ||
            deliveryState === EDeliveryState.LoadingEnd                   ||
            deliveryState === EDeliveryState.Delivery                     ||
            deliveryState === EDeliveryState.Delivered                    ||
            deliveryState === EDeliveryState.Closed                       ||
            deliveryState === EDeliveryState.ClosedOld){
                return true;
            }
        return false;
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}