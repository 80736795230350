import { IVolmaTableColumnDefinition } from '../../../Table/VolmaTable/IVolmaTableProps';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';

export class DriverColumnTemplates {
    public get Id(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Id)
        }
    }

    public get MultiTransporterName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiTransporterColumn({
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.CargoTransporterName),
        })
    }

    public get CargoTransporterName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.CargoTransporterName),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            Entity: EEntityType.CargoTransporter,
            FilterKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.CargoTransporterId)
        };
    }

    public get Name(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Name),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
        }
    }

    public get PhoneNumber(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.PhoneNumber),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
        }
    }

    public get Details(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Details),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
        }
    }
}