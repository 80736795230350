import { ApiCommandResultsDTO } from '../../Domain/ApiCommandResultsDTO';
import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { FilterParameterDTO } from '../../Domain/DTO/FilterParameterDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ESortDir } from '../../Domain/Enum/ESortDir';
import { VolmaContainer } from '../InversifyInject';
import { connection } from '../SignalR/SignalRMiddleware';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { ESignalRConnectionState } from '../SignalR/ESignalRConnectionState';
import PropertyHelper from '../Services/PropertyHelper';


@injectable()
export class TableSignalRServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;
    private readonly _defaultItemsPerPage: 10000;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);

        this.ProcessResponse = this.ProcessResponse.bind(this);
        this.GetFilter       = this.GetFilter.bind(this);
        this.GetProxy        = this.GetProxy.bind(this);
    }

    public GetTableData(entity: EEntityType, sortBy: string, sortDir: ESortDir, filter: Array<FilterDTO>, itemsPerPage: number, pageNumber: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let filterPrams = this.GetFilter(filter);
                var proxy = this.GetProxy();
                if (proxy.connection.state !== ESignalRConnectionState.Connected) {
                    reject("No signalR connection");
                    return;
                }
                var request = proxy.invoke('GetTableData', EEntityType[entity], filterPrams, sortBy, sortDir || ESortDir[ESortDir.Asc], pageNumber || 0, itemsPerPage || this._defaultItemsPerPage);
                this.ProcessResponse(request, resolve, reject, x => JSON.parse(x));
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    public GetChildTableData(baseEntity: EEntityType, id: string, childEntity: EEntityType, sortBy: string, sortDir: ESortDir, filter: Array<FilterDTO>, itemsPerPage: number, pageNumber: number): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                let filterPrams = this.GetFilter(filter);
                var proxy = this.GetProxy();
                if (proxy.connection.state !== ESignalRConnectionState.Connected){
                    reject("No signalR connection");
                    return;
                }
                var request = proxy.invoke('GetChildTableData', EEntityType[baseEntity], id, EEntityType[childEntity], filterPrams, sortBy, sortDir || ESortDir[ESortDir.Asc], pageNumber || 0, itemsPerPage || this._defaultItemsPerPage);
                this.ProcessResponse(request, resolve, reject, x => JSON.parse(x));
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    public GetEntity(entity: EEntityType, id?: string): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                var proxy = this.GetProxy();
                if (proxy.connection.state !== ESignalRConnectionState.Connected) {
                    reject("No signalR connection");
                    return;
                }
                var request = proxy.invoke('GetTableItem', EEntityType[entity], id)
                this.ProcessResponse(request, resolve, reject, x => JSON.parse(x));
            }
            catch (error) {
                console.error(error);
                reject(error);
            }
        });

    }

    private ProcessResponse(request: any, resolve: any, reject: any, postProcessData: (any) => any) {
        request
            .done((data: ApiCommandResultsDTO) => {
                if (data.Result === 0) {
                    resolve(postProcessData(data.Data));
                }
                else {
                    let error: string = 'Failed to load data. Response is ' + JSON.stringify(data);
                    console.error(error);
                    reject(error);
                }
            })
            .fail((error) => {
                console.log('Error:', error);
                reject(error);
            });

    }

    private GetFilter(filter: Array<FilterDTO>): Array<FilterParameterDTO> {
        let filterPrams = undefined;
        if(!PropertyHelper.IsArray(filter)){
            return new Array();
        }

        try {
            filterPrams = filter.map(x => { return { Key: x.Key, Value: this._urlFabric.GetFilterValue(x) } as FilterParameterDTO }).filter(x => x.Value !== undefined && x.Value.length > 0);
        }
        catch (error) {
            console.error(error);
            filterPrams = new Array();
        }
        return filterPrams;
    }

    private GetProxy(): any {
        return connection.proxies["volmaspahub"];
    }
}