import { EntityUpdatedDTO } from '../../Domain/DTO/EntityUpdatedDTO';
import { injectable } from 'inversify';
import { LiteEvent } from './LiteEvent';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { TenderBiddingStateChangedDTO } from '../../Domain/DTO/TenderBiddingStateChangedDTO';
import { EntityAddedDTO } from '../../Domain/DTO/EntityAddedDTO';
import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintDTO } from '../../Domain/DTO/ComplaintDTO';
import { ComplaintCommentUpdatedDTO } from '../../Domain/DTO/ComplaintCommentUpdatedDTO';
import { DeliveryStateUpdatedDTO } from '../../Domain/DTO/DeliveryStateUpdatedDTO';
import { ComplaintCommentAddedDTO } from '../../Domain/DTO/ComplaintCommentAddedDTO';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { DeliveryLocationUpdateDTO } from '../../Domain/DTO/DeliveryLocationUpdateDTO';

@injectable()
export class SignalRService{
    private readonly _onBiddingBetSetted        = new LiteEvent<BiddingBetDTO>();
    private readonly _onEntityAdded             = new LiteEvent<EntityAddedDTO>();
    private readonly _onEntityUpdated           = new LiteEvent<EntityUpdatedDTO>();
    private readonly _onTenderBiddingStarted    = new LiteEvent<TenderBiddingStateChangedDTO>();
    private readonly _onTenderBiddingEnded      = new LiteEvent<TenderBiddingStateChangedDTO>();
    private readonly _onTenderBetSetted         = new LiteEvent<TenderBetDTO>();
    private readonly _onComplaintAdded          = new LiteEvent<ComplaintDTO>();
    private readonly _onComplaintUpdated        = new LiteEvent<ComplaintDTO>();
    private readonly _onComplaintCommentAdded   = new LiteEvent<ComplaintCommentAddedDTO>();
    private readonly _onComplaintCommentUpdated = new LiteEvent<ComplaintCommentUpdatedDTO>();
    private readonly _onDeliveryLocationUpdated = new LiteEvent<DeliveryLocationUpdateDTO>();
    private readonly _onCargoTransporterChanged = new LiteEvent<void>();

    public get BiddingBetSetted()        { return this._onBiddingBetSetted.Expose();        }
    public get EntityAdded()             { return this._onEntityAdded.Expose();             }
    public get EntityUpdated()           { return this._onEntityUpdated.Expose();           }
    public get TenderStarted()           { return this._onTenderBiddingStarted.Expose();    }
    public get TenderEnded()             { return this._onTenderBiddingEnded.Expose();      }
    public get TenderBetSetted()         { return this._onTenderBetSetted.Expose();         }
    public get ComplaintAdded()          { return this._onComplaintAdded.Expose();          }
    public get ComplaintUpdated()        { return this._onComplaintUpdated.Expose();        }
    public get ComplaintCommentAdded()   { return this._onComplaintCommentAdded.Expose();   }
    public get ComplaintCommentUpdated() { return this._onComplaintCommentUpdated.Expose(); }
    public get DeliveryLocationUpdated() { return this._onDeliveryLocationUpdated.Expose(); }
    public get CargoTransporterChanged() { return this._onCargoTransporterChanged.Expose(); }
}

