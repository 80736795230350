import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';



@injectable()
export class RatingServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public LoadForecatRating(idCargo: string): AxiosPromise {
        return this.Get(this._urlFabric.LoadForecastRating(ECQRSApi.Write, idCargo));
    }
}