import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';

import { injectable } from 'inversify';
import { PayloadHelperProps, InitialPayloadHelperProps } from './IHelperProps';
import VolmaInput from '../../VolmaInput/VolmaInput';
import * as React from 'react';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { PayloadTableDTO } from '../../../Domain/DTO/PayloadTableDTO';
import i18next from '../../i18n';


@injectable()
export class PayloadEntityService extends BaseEntityService<PayloadDTO, PayloadHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Payload]
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Payload,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: PayloadTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: PayloadTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: PayloadTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
            ]
        );
    }

    public GetEntityId(props: IBaseEntityProps<PayloadTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: PayloadTableDTO) => x.Code),
            (x: PayloadTableDTO, id: string) => x.Code.toLowerCase() === id);
    }

    public GetEditorModal(props: IBaseEntityProps<PayloadDTO, PayloadHelperProps>): JSX.Element {
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<PayloadDTO, PayloadHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<PayloadDTO, PayloadHelperProps>): JSX.Element{
        let isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: PayloadDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: PayloadDTO) => val.Name)}
                        Label={i18next.t('payload:Name')}
                        Placeholder={i18next.t('payload:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>

                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: PayloadDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: PayloadDTO) => val.Code)}
                        Label={i18next.t('payload:Code')}
                        Placeholder={i18next.t('payload:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<PayloadDTO, PayloadHelperProps>){
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return undefined;
    }

    public GetInitialDataHelper() {
        return InitialPayloadHelperProps;
    }

    public OnAfterDataLoaded(dto: PayloadDTO): void {
    }
}