import i18next from "i18next";
import * as React from "react";
import { Component } from "react";
import { ListOptionDTO } from "../../../Domain/DTO/ListOptionDTO";
import { EEntityType } from "../../../Domain/Enum/EEntityType";
import PropertyHelper from "../../../Infrastructure/Services/PropertyHelper";
import VolmaSelect from "../../VolmaSelect/index";
import { IVolmaSelectProps } from "../../VolmaSelect/IVolmaSelectProps";

interface IVolmaMultiTransporterSelectProps extends IVolmaSelectProps {}

interface IVolmaMultiTransporterSelectState {}

export class VolmaMultiTransporterSelect extends Component<
    IVolmaMultiTransporterSelectProps,
    IVolmaMultiTransporterSelectState
> {
    render() {
        const labelKey = PropertyHelper.GetPropertyName((x: ListOptionDTO) => x.Name);

        const multiTransporterText = this.props.AllowMultiselect 
            ? { label: i18next.t("multitransporter:MultiTransporters"), placeholder: i18next.t("multitransporter:SelectMultiTransporters"), }            
            : { label: i18next.t("multitransporter:MultiTransporter"), placeholder: i18next.t("multitransporter:SelectMultiTransporter"), };

        const icons = [
            <svg className="table-cont__ico">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cargotransporter"></use>
            </svg>,
            <svg className="table-cont__ico">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-cargotransporter"></use>
            </svg>,
        ];

        return (
            <VolmaSelect
                {...this.props}
                Label={multiTransporterText.label}
                Placeholder={multiTransporterText.placeholder}
                Entity={[EEntityType.CargoTransporter, EEntityType.MultiCargoTransporter]}
                LabelKey={labelKey}
                EntityOptionIcon={icons}
            />
        );
    }
}
