import { injectable } from 'inversify';
import * as moment from 'moment';
import * as React from 'react';

import { history } from '../../../../ConfigureStore';
import { BiddingBetTableDTO } from '../../../../Domain/DTO/BiddingBetTableDTO';
import { ComplaintTableDTO } from '../../../../Domain/DTO/ComplaintTableDTO';
import { DeliveryStateTableDTO } from '../../../../Domain/DTO/DeliveryStateTableDTO';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { RouteSegmentDTO } from '../../../../Domain/DTO/RouteSegmentDTO';
import { TenderRejectedDeliveryTableDTO } from '../../../../Domain/DTO/TenderRejectedDeliveryTableDTO';
import { TimeDTO } from '../../../../Domain/DTO/TimeDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { ERoutePointType } from '../../../../Domain/Enum/ERoutePointType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { DeliveryService } from '../../../../Infrastructure/Services/DeliveryService';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { isNullOrUndefined } from '../../../../Infrastructure/Services/Utils';
import { Types } from '../../../../Infrastructure/Types';
import { IDeliveryStateTableHelperProps } from '../../../Entity/Delivery/DeliveryState/ITableHelperProps';
import { IRouteSegmentTableHelperProps } from '../../../Entity/Delivery/RouteSegment/ITableHelperProps';
import { DeliveryTableActions } from '../../../Entity/Delivery/Table/DeliveryTableActions';
import { ITenderTableHelperProps } from '../../../Entity/Tender/Table/ITableHelperProps';
import i18next from '../../../i18n';
import { VolmaActionLink } from '../../../Shared/VolmaActionLink';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from './VolmaTableCommonCellRenderers';

@injectable()
export class VolmaTableDeliveryCellRenderers {
    public static DeliveryBetCellRenderer(
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        const delivery = props.Data[params.rowIndex] as DeliveryTableDTO;
        const deliveryId = delivery.Id;
        const deliveryCurrency = delivery.Currency;

        const userBet: BiddingBetTableDTO = props.DataHelper.AllUserBets !== undefined ? props.DataHelper.AllUserBets.find((x: BiddingBetTableDTO) => x.DeliveryId === deliveryId) : undefined

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);

        const authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);

        const isActive = VolmaTableCommonCellRenderers.GetTimeService().IsInFuture(delivery.AuctionDeadline);

        if (authService.IsCargoTransporter()) {
            if (userBet !== undefined)
                return (
                    <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, orange: true })).toString()} style={style} key={key}>
                        <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}><b>{i18next.t("delivery:YourBetIs")}:</b> {userBet.Bet}</span>
                        </div>
                        {isActive && <a onClick={(event: any) => VolmaTableDeliveryCellRenderers.AddOrEditBiddingBet(event, deliveryId, userBet.Bet, deliveryCurrency, props)}
                            className={(VolmaTableCommonCellRenderers._setWager({orange: true }).mix([VolmaTableCommonCellRenderers._table("wager")])).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._setWager("ico").mix(["rub-circle-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub-circle"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._setWager("text")).toString()}>{i18next.t("delivery:MakeBet")}</span>
                            <span className={(VolmaTableCommonCellRenderers._setWager("bg")).toString()}>
                                <svg className={(VolmaTableCommonCellRenderers._setWager("bg-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                                </svg>
                            </span>
                        </a>}
                    </div>
                )
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key} >
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("delivery:YouDidNotDoABet")}</span>
                    </div>
                    {isActive && <a onClick={(event: any) => VolmaTableDeliveryCellRenderers.AddOrEditBiddingBet(event, deliveryId, undefined, deliveryCurrency, props)}
                        className={(VolmaTableCommonCellRenderers._setWager.mix([VolmaTableCommonCellRenderers._table("wager")])).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._setWager("ico").mix(["rub-circle-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub-circle"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._setWager("text")).toString()}>{i18next.t("delivery:MakeBet")}</span>
                        <span className={(VolmaTableCommonCellRenderers._setWager("bg")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._setWager("bg-ico")).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                        </span>
                    </a >}
                </div>
            )
        }
        else {
            if (userBet !== undefined)
                return (
                    <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, green: true })).toString()} style={style} key={key}>
                        <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}><b>{i18next.t("delivery:BetIs")}:</b> {userBet.Bet}</span>
                        </div>
                    </div>
                )
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wager: isActive })).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("delivery:NoBets")}</span>
                    </div>
                </div>
            )
        }
    }

    public static DeliveryLeftTimeCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let deliveryTableDTO = props.Data[params.rowIndex] as DeliveryTableDTO;

        // @ts-ignore: This expression is not callable
        if (moment(deliveryTableDTO.NextStateDate).isValid())
        {
            return VolmaTableCommonCellRenderers.GetTimeleftCell(deliveryTableDTO.NextStateDate, params);
        }

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}></span>
                </div>
            </div>
        )
    }

    public static DeliveryIdentifierLinkCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams,
        deliveryService: DeliveryService,
        routeService: RouterService) {

        let delivery = props.Data[params.rowIndex] as DeliveryTableDTO;
        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let entity = deliveryService.GetEntityByDelivery(delivery.State, delivery.AssignType)[0];
        let id = delivery.Id;
        if(delivery.Id === '00000000-0000-0000-0000-000000000000') {
            let rejectedDelivery = props.Data[params.rowIndex] as TenderRejectedDeliveryTableDTO;
            if(!isNullOrUndefined(rejectedDelivery)) {
                id = rejectedDelivery.DeliveryId;
            }
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <VolmaActionLink path={routeService.GetCertainEntityPathname(entity, id)} label={delivery.Identifier} className={(VolmaTableCommonCellRenderers._tableCont("text", {link: true })).toString()}  />
                </div>
            </div>
        )
    }

    public static ComplaintIdentifierLinkCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams,
        routeService: RouterService) {

        let complaint = props.Data[params.rowIndex] as ComplaintTableDTO;
        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        if(complaint.Id === '00000000-0000-0000-0000-000000000000') {
            return VolmaTableCommonCellRenderers.DefaultCellRenderer(props, params);
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <a className={(VolmaTableCommonCellRenderers._tableCont("text", {link: true })).toString()} onClick={() => history.push({ pathname: routeService.GetCertainEntityPathname(EEntityType.Complaint, complaint.Id) })}>{complaint.RegistrationCode}</a>
                </div>
            </div>
        )
    }

    public static DeliveryStateStatusCellRenderer(
        props: IVolmaTableProps<IDeliveryStateTableHelperProps>,
        params: IVolmaTableCellRendererParams,
        enumService: EnumService,
        routeService: RouterService,
        formatService: FormatService,
        deliveryService: DeliveryService,
        authService: AuthenticationService,
    ) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        let dto = props.Data[params.rowIndex] as DeliveryStateTableDTO;
        let state = enumService.GetOptionLocalized(() => EDeliveryState, dto.DeliveryState);
        let assignType = enumService.GetOptionLocalized(() => ERequestForDeliveryAssigner, dto.Assigner);

        let routeSegment: RouteSegmentDTO = undefined;
        if (props.DataHelper.RouteSegments !== undefined && dto.RouteSegment !== undefined) {
            routeSegment = props.DataHelper.RouteSegments.find(x => x.Id === dto.RouteSegment)
        }

        let cargotransporter: string = undefined;
        if (formatService.IsValueNotEmpty(dto.CargoTransporterName))
            cargotransporter = dto.CargoTransporterName;

        let userName: string = undefined;
        if (formatService.IsValueNotEmpty(dto.UserFio))
            userName = dto.UserFio;

        const driverStatuses = [EDeliveryState.VehicleArrivedAtUnloadingPoint, EDeliveryState.LatenessRegistration, EDeliveryState.VehicleArrivedAtLoadingPoint]
        const isDriverStatus = driverStatuses.includes(dto.DeliveryState)

        let rows = new Array<JSX.Element>();
        let  index = 0;
        rows.push(<div key={index++}>{`${i18next.t("deliverystate:DeliveryState")}: ${state}`}</div>);
        if (!isDriverStatus) {
            if (dto.Assigner !== ERequestForDeliveryAssigner.Bidding || !formatService.IsValueNotEmpty(dto.BiddingId) || authService.IsCargoTransporter()) {
                rows.push(<div key={index++}>{`${i18next.t("deliverystate:Assigner")}: ${assignType}`}</div>);
            } else {
                rows.push(
                    <div key={index++}>
                        {`${i18next.t("deliverystate:Assigner")}: ${assignType}, `}

                        <a
                            className={(VolmaTableCommonCellRenderers._tableCont("text", {link: true })).toString()}
                            onClick={() => history.push({
                                pathname: routeService.GetCertainEntityPathname(EEntityType.Bidding, dto.BiddingId),
                                search: `?back=${routeService.GetCertainEntityPathname(deliveryService.GetEntityByDelivery(dto.DeliveryState, dto.Assigner)[0], dto.DeliveryId) }`})}>
                            {`${i18next.t("deliverystate:Goto")}`}
                        </a>
                    </div>);
            }
        }

        if (cargotransporter && !isDriverStatus) {
            rows.push(<div key={index++}>{`${i18next.t("deliverystate:CargoTransporterName")}: ${cargotransporter}`}</div>);
        }
        if (userName) {
            rows.push(<div key={index++}>{`${i18next.t("deliverystate:UserFio")}: ${userName}`}</div>);
        }
        if (routeSegment !== undefined) {
            let pointName: string = undefined;
            let pointCode: string = undefined;

            if (routeSegment.PointType === ERoutePointType.Loading) {
                let loadingPoint = props.DataHelper.LoadingPoints !== undefined ?
                    props.DataHelper.LoadingPoints.find(x => x.Id === routeSegment.LoadingPointId) :
                    undefined;
                pointName = formatService.GetSafeString(PropertyHelper.SafeGetValue(loadingPoint, x => x.Name));
                pointCode = formatService.GetSafeString(PropertyHelper.SafeGetValue(loadingPoint, x => x.Code));
            }
            else {
                let unloadingPoint = props.DataHelper.Localities !== undefined ?
                    props.DataHelper.Localities.find(x => x.Id === routeSegment.UnloadingPointId) :
                    undefined;
                pointName = formatService.GetSafeString(PropertyHelper.SafeGetValue(unloadingPoint, x => x.Name));
                pointCode = formatService.GetSafeString(PropertyHelper.SafeGetValue(unloadingPoint, x => x.Code));
            }

            rows.push(<div key={index++}>{`${i18next.t("deliverystate:RouteSegment")}: ${pointName}(${pointCode})`}</div>);
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text", {link: false })).toString()} >
                        {rows}
                    </span>
                </div>
            </div>
        )
    }

    public static DeliveryDateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams): JSX.Element {

        let data = (props.Data as DeliveryTableDTO[])[params.rowIndex];
        let date = data[params.dataKey] as Date;
        let timezone = data.LoadingPointTimeZone;
        return VolmaTableDeliveryCellRenderers.DateCellRenderer(date, undefined, timezone, params);
    }

    public static DeliveryTableLoadingPointDateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams): JSX.Element {

        let data = (props.Data as DeliveryTableDTO[])[params.rowIndex];
        let timezone = data.LoadingPointTimeZone;
        let dateFrom = data.LoadingDate;
        return VolmaTableDeliveryCellRenderers.DateCellRenderer(dateFrom, undefined, timezone, params);
    }

    public static DeliveryTableStateCellRenderer(
        enumService: EnumService,
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams): JSX.Element {
        let data = (props.Data as DeliveryTableDTO[])[params.rowIndex];
        let value = data.State;
        if(value === EDeliveryState.LoadingLatenessRegistration)
            value = EDeliveryState.Approved;

        if(value === EDeliveryState.LatenessRegistration)
            value = EDeliveryState.Delivery;

        let text = enumService.GetOptionLocalized(() => EDeliveryState, value);

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{text}</span>
                </div>
            </div>
        )
    }    

    public static DeliveryTableUnloadingPointDateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams): JSX.Element {

        let data = (props.Data as DeliveryTableDTO[])[params.rowIndex];
        let timezone = data.LoadingPointTimeZone;
        let dateFrom = data.UnloadingDate;
        let dateTo = data.UnloadingDateEnd;
        return VolmaTableDeliveryCellRenderers.DateCellRenderer(dateFrom, dateTo, timezone, params);
    }

    public static RouteSegmentDateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams): JSX.Element {

        let data = (props.Data as RouteSegmentDTO[])[params.rowIndex];
        let timezone = props.DataHelper[PropertyHelper.GetPropertyName((x: IRouteSegmentTableHelperProps) => x.TimeZone)];//data.Timezone;
        let dateFrom =  VolmaTableDeliveryCellRenderers.GetIsDateValid(data.ArrivalDate) ? data.ArrivalDate : data.ArrivalDateStart;
        let dateTo = VolmaTableDeliveryCellRenderers.GetIsDateValid(data.ArrivalDateEnd) ? data.ArrivalDateEnd : undefined;
        return VolmaTableDeliveryCellRenderers.DateCellRenderer(dateFrom, dateTo, timezone, params);
    }

    private static DateCellRenderer(dateFrom: Date, dateTo: Date, timezone: TimeDTO, params: IVolmaTableCellRendererParams): JSX.Element {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        let isValid = VolmaTableDeliveryCellRenderers.GetIsDateValid(dateFrom) ||
        (VolmaTableDeliveryCellRenderers.GetIsDateValid(dateFrom) && VolmaTableDeliveryCellRenderers.GetIsDateValid(dateTo));

        if(!isValid)
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:NoData")}</span>
                    </div>
                </div>);

        let momentFrom = VolmaTableDeliveryCellRenderers.ConvertToSpecifiedTimezone(dateFrom, timezone);
        let momentTo = VolmaTableDeliveryCellRenderers.ConvertToSpecifiedTimezone(dateTo, timezone);
        if(dateTo === undefined || dateTo === null)
            return VolmaTableDeliveryCellRenderers.GetUnloadingDate(momentFrom, params.selected, style, key);

        return VolmaTableDeliveryCellRenderers.GetUnloadingDateFromTo(momentFrom, momentTo, params.selected, style, key);
    }

    private static GetUnloadingDate(date: moment.Moment, selected: any, style: any, key: any): JSX.Element {

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{date.format("DD.MM.YYYY")}</span>
                        </span>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{date.format("HH.mm")}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    private static GetUnloadingDateFromTo(dateFrom: moment.Moment, dateTo: moment.Moment, selected: any, style: any, key: any): JSX.Element {
        if(dateFrom.date() === dateTo.date())
            return VolmaTableDeliveryCellRenderers.GetArrivalDatesWithDash(selected, dateFrom, dateTo, style, key);
        return VolmaTableDeliveryCellRenderers.GetArrivalDatesWithoutDash(selected, dateFrom, dateTo, style, key);
    }

    private static GetArrivalDatesWithoutDash(selected: any, dateFrom: moment.Moment, dateTo: moment.Moment, style: any, key: any)
    {
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>{i18next.t("routesegment:From")}</span>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateFrom.format("DD.MM.YYYY")}</span>
                        </span>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateFrom.format("HH.mm")}</span>
                        </span>
                    </div>
                        <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>

                            <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>{i18next.t("routesegment:To")}</span>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                                <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                                </svg>
                                <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateTo.format("DD.MM.YYYY")}</span>
                            </span>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                                <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                                </svg>
                                <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateTo.format("HH.mm")}</span>
                            </span>
                        </div>
                </div>
            </div>
        );
    }

    private static GetArrivalDatesWithDash(selected: any, dateFrom: moment.Moment, dateTo: moment.Moment, style: any, key: any)
    {
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateFrom.format("DD.MM.YYYY")}</span>
                        </span>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                            </svg>
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateFrom.format("HH.mm")} - {dateTo.format("HH.mm")}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    private static ConvertToSpecifiedTimezone(date: Date, timezone: TimeDTO): moment.Moment {
        return VolmaTableCommonCellRenderers.GetTimeService().ConvertToSpecifiedTimezone(date, timezone);
    }

    public static GetIsDateValid(date: Date)
    {
        // @ts-ignore: This expression is not callable
        return date !== undefined && moment(date).isValid();
    }

    public static GetDisplayedStatus(status: EDeliveryState): EDeliveryState {
        if(status === EDeliveryState.LatenessRegistration ||
            status === EDeliveryState.VehicleArrivedAtUnloadingPoint)
            status = EDeliveryState.Delivery;

        return  status;
    }

    public static StatusCellRenderer(
        enumService: EnumService,
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams){
        let delivery = props.Data[params.rowIndex] as DeliveryTableDTO;
        let status = delivery.State;

        params.cellData = this.GetDisplayedStatus(status);
        return VolmaTableCommonCellRenderers.EnumCellRenderer(() => EDeliveryState, enumService, props, params)
    }

    public static IconUnloadingPointCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let delivery = props.Data[params.rowIndex] as DeliveryTableDTO;
        let allUnloadingPoints = delivery.AllUnloadingPointNames;
        return VolmaTableDeliveryCellRenderers.CountCellRenderer(delivery.UnloadingPointsCount, allUnloadingPoints, params);
    }

    public static IconLoadingPointCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let delivery = props.Data[params.rowIndex] as DeliveryTableDTO;
        let allLoadingPoints = delivery.AllLoadingPointNames;
        return VolmaTableDeliveryCellRenderers.CountCellRenderer(delivery.LoadingPointsCount, allLoadingPoints, params);
    }

    protected static AddOrEditBiddingBet(
        event: Event,
        deliveryId: string,
        userBet: number,
        currency: string,
        props: IVolmaTableProps<{}>) {

        event.preventDefault();
        event.stopPropagation();

        let deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
        props.dispatch(deliveryTableActions.AddOrEditBet(userBet, currency, deliveryId));
    }

    protected static CountCellRenderer(count: number, points: Array<string>, params: IVolmaTableCellRendererParams) {
        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let text = params.cellData;
        if (PropertyHelper.IsArray(points) && points.length > 1 && points.length === count)
            text = points.join("<br/>");
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    {count > 1 && <span className={(VolmaTableCommonCellRenderers._tableCont("count")).toString()}>{count}</span>}
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()} dangerouslySetInnerHTML={{ __html: text }}></span>
                </div>
            </div>
        )

    }
}