import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import {
    CargoTransporterServerInteraction
} from '../../../../Infrastructure/ServerInteraction/CargoTransporterServerInteraction';
import { Types } from '../../../../Infrastructure/Types';
import { ApiActions } from '../../../Shared/ApiActions';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { CargoTransporterTableActions } from '../Table/CargoTransporterTableActions';
import { AxiosResponse } from 'axios';
import i18next from '../../../i18n';
import { FileExtendedDTO } from '../../../File/FileExtendedDTO';

export class CargoTransporterImportExcelAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions                : VolmaModalActions;
    private _cargoTransporterTableActions: CargoTransporterTableActions;
    private _cargotransporterApi         : CargoTransporterServerInteraction;
    private _apiActions                  : ApiActions;

    public Title: string = "cargotransporter:CargoTransporterImportExcel";

    public IsAvailable(selectedIds: Array<string>) {
        let currentUser = this._authService.GetCurrentUser();
        if (currentUser.Role === EUserRole.Administrator || currentUser.Role === EUserRole.ShipperLogistExtended)
            return true;
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<CargoTransporterTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        
        dispatch(this._modalActions.OpenFileUploadModal(i18next.t('cargotransporter:CargoTransporterImportExcel'), (dto: FileExtendedDTO) => {
            let importPromise = this._cargotransporterApi.ImportExcel(dto.FileId);
            this._apiActions.DoApiRequest(
                dispatch,
                () => importPromise,
                i18next.t("common:Loading"),
                (response: AxiosResponse, dispatch: any) => {
                    reloadData()
                });
            return importPromise;
        }))
    }

    constructor(){
        super();

        this._modalActions                 = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._cargoTransporterTableActions = VolmaContainer.get<CargoTransporterTableActions>(Types.CargoTransporterTableActions);
        this._cargotransporterApi = VolmaContainer.get<CargoTransporterServerInteraction>(Types.CargoTransporterServerInteraction);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);

    }
}