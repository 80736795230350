import * as moment from 'moment';
import i18next from '../../Components/i18n';
import { IVolmaTimeProps } from '../../Components/VolmaTime/IVolmaTimeProps';
import { isNullOrUndefined } from '../Services/Utils';
import ValidationResult from './ValidationResult';
import { VolmaBaseTimeValidator } from "./VolmaBaseTimeValidator";

export class VolmaTimeValidator extends VolmaBaseTimeValidator<IVolmaTimeProps> {

    protected MustBeNonEmptyRule(entity: IVolmaTimeProps): ValidationResult {
        if (entity.Value === undefined) {
            return entity.Required ? ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty")) : ValidationResult.Success();
        }

        return ValidationResult.Success();
    }

    protected HoursAndMinutesRule(entity: IVolmaTimeProps): ValidationResult | undefined {
        if (isNullOrUndefined(entity.Value)) {
            return undefined;
        }

        // @ts-ignore: This expression is not callable
        const hours = moment(entity.Value).hours();
        // @ts-ignore: This expression is not callable
        const minutes = moment(entity.Value).minutes();
        
        if (hours < 0)
            return ValidationResult.Fail(i18next.t("validate:HoursMustBeNonNegative"));
        if (hours > 23)
            return ValidationResult.Fail(i18next.t("validate:HoursMustBeLessThan24"));
        if (minutes < 0)
            return ValidationResult.Fail(i18next.t("validate:MinutesMustBeNonNegative"));
        if (minutes > 59)
            return ValidationResult.Fail(i18next.t("validate:MinutesMustBeLessThan60"));

        return ValidationResult.Success();
    }

}