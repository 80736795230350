import { RepeatRequestAction } from './Actions/RepeatRequestAction';
import { VolmaTableCellHeightMeasurers } from '../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import * as React from 'react';
import VolmaInput from '../../VolmaInput';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn, IVolmaTableCellRendererParams, IVolmaTableProps, IVolmaTableAction } from '../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import i18next from '../../i18n';

import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { ApiMessageLogDTO } from '../../../Domain/DTO/ApiMessageLogDTO';
import { ApiMessageLogTableDTO } from '../../../Domain/DTO/ApiMessageLogTableDTO';
import { EApiRequestDirection } from '../../../Domain/Enum/EApiRequestDirection';
import { REQUEST_OK } from '../../../Constants/AppConstants';

@injectable()
export class RequestLogLogEntityService extends BaseEntityService<ApiMessageLogDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;

    public ItemsPerPage: number = 50;
    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.RequestLog]
    }

    public GetDisableItemEdit(): boolean {
        return true;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.RequestLog,
            [
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Id)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Timestamp),
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
                    Weight: 0.5
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Method),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    Weight: 0.5
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Direction),
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EApiRequestDirection, this._enumService, props, cellRendererProps),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => EApiRequestDirection,
                    Weight: 0.5
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.BaseUrl),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Url),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.JsonBody),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.StatusCode),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    Weight: 0.5
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: ApiMessageLogTableDTO) => val.Response),
                    CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                },
            ]
        );
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<any>> {
        return [new RepeatRequestAction()];
    }
    public GetTableRowClass(rowData: ApiMessageLogTableDTO): string {
        return this._table("row", { body: true, failed: rowData.StatusCode !== REQUEST_OK }).toString();
    }

    public IsEditable(props: IBaseEntityProps<ApiMessageLogDTO, any>): boolean {
        return false;
    }

    public GetTableCanAddEntity(entity: EEntityType): boolean {
        return false;
    }

    public GetEditorModal(props: IBaseEntityProps<ApiMessageLogDTO, any>): JSX.Element {
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<ApiMessageLogDTO, any>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<ApiMessageLogDTO, any>): JSX.Element {
       return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<ApiMessageLogDTO, any>) {
    }

    public GetReducer() {
        return undefined;
    }

    public OnAfterDataLoaded(dto: ApiMessageLogDTO): void {
    }

    public GetInitialDataHelper() {
        return {};
    }

}