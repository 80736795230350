import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumn,
    IVolmaTableProps
} from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { EnumService } from '../../../Infrastructure/Services/EnumService';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { DeliveryService } from '../../../Infrastructure/Services/DeliveryService';
import { VolmaTableDeliveryCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers';

@injectable()
export class TenderDeliveryEntityService {
    private _volmaTableService: VolmaTableService;
    private _enumService      : EnumService;
    private _routerService    : RouterService;
    private _deliveryService  : DeliveryService;

    constructor() {
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._enumService       = VolmaContainer.get<EnumService>(Types.EnumService);
        this._routerService     = VolmaContainer.get<RouterService>(Types.RouterService)
        this._deliveryService   = VolmaContainer.get<DeliveryService>(Types.DeliveryService);

    }

    public GetColumns(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Delivery,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Id)},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Identifier),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellRenderer: (props: IVolmaTableProps<{}>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableDeliveryCellRenderers.DeliveryIdentifierLinkCellRenderer(props, params, this._deliveryService, this._routerService),
                    Weight: 0.5
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingDate),
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
                    CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryTableLoadingPointDateCellRenderer,
                    Weight: 0.7
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingDate),
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
                    CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryTableUnloadingPointDateCellRenderer,
                    Weight: 0.7
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingPointName),
                    FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LocalityId),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    CellRenderer: VolmaTableDeliveryCellRenderers.IconUnloadingPointCellRenderer,
                    Entity: EEntityType.Locality
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.CargoTransporterName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.CargoTransporterId),
                    Entity: EEntityType.CargoTransporter,
                    Weight: 0.7
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.State), 
                    CellRenderer: (props: IVolmaTableProps<{}>, cellRendererParams: IVolmaTableCellRendererParams) => 
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => EDeliveryState, this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => EDeliveryState,
                    FilterKey: "DeliveryState",
                    SortKey: "DeliveryState",
                },
            ]
        );

    }
}