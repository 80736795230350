import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import {
    CHART_BACKGROUND_COLORS, CHART_BORDER_COLORS, REPORT_CHART_ADDED,
    REPORT_CHART_DELETED,
    REPORT_CHART_UPDATED,
    REPORT_EXCEL_FILE_ID_UPDATED, REPORT_REPORT_TYPE_UPDATED
} from '../../../Constants/AppConstants';
import { ReportParametersDTO } from '../../../Domain/DTO/ReportParametersDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../Domain/Enum/EReportType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ReportServerInteraction } from '../../../Infrastructure/ServerInteraction/ReportServerInteraction';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { EntityAction } from '../BaseEntity/EntityAction';
import { IReportService } from './ConcreteReports/IReportService';
import {
    ReportCargoTransportersRatingsExcelService
} from './ConcreteReports/ReportCargoTransportersRatingsExcelService';
import {
    ReportCargoTransportersRatingSummaryExcelService
} from './ConcreteReports/ReportCargoTransportersRatingSummaryExcelService';
import { ReportComplaintsExcelService } from './ConcreteReports/ReportComplaintsExcelService';
import { ReportDeliveriesExcelService } from './ConcreteReports/ReportDeliveriesExcelService';
import { ReportDriverScoreService } from './ConcreteReports/ReportDriverScoreService';
import { ReportRatesExcelService } from './ConcreteReports/ReportRatesExcelService';
import { ReportReceivedDeliveriesService } from './ConcreteReports/ReportReceivedDeliveriesService';
import { ReportTendersExcelService } from './ConcreteReports/ReportTendersExcelService';
import { IReportChartPayload } from './Payloads';
import { ReportParametersExtendedDTO } from './ReportParametersExtendedDTO';

@injectable()
export class ReportActions extends EntityAction {
    private _reportApi  : ReportServerInteraction;

    private _reportTendersExcelService                 : ReportTendersExcelService
    private _reportRatesExcelService                   : ReportRatesExcelService
    private _reportDeliveriesExcelService              : ReportDeliveriesExcelService
    private _reportComplaintsExcelService              : ReportComplaintsExcelService
    private _reportCargoTransportersRatingsExcelService: ReportCargoTransportersRatingsExcelService
    private _reportCargoTransportersRatingSummaryExcelService: ReportCargoTransportersRatingSummaryExcelService
    private _reportReceivedDeliveriesService           : ReportReceivedDeliveriesService
    private _reportDriverScoreService            : ReportDriverScoreService

    constructor() {
        super();

        this._reportApi   = VolmaContainer.get<ReportServerInteraction>(Types.ReportServerInteraction);

        this._reportTendersExcelService                  = VolmaContainer.get<ReportTendersExcelService>(Types.ReportTendersExcelService);
        this._reportRatesExcelService                    = VolmaContainer.get<ReportRatesExcelService>(Types.ReportRatesExcelService);
        this._reportDeliveriesExcelService               = VolmaContainer.get<ReportDeliveriesExcelService>(Types.ReportDeliveriesExcelService);
        this._reportComplaintsExcelService               = VolmaContainer.get<ReportComplaintsExcelService>(Types.ReportComplaintsExcelService);
        this._reportCargoTransportersRatingsExcelService = VolmaContainer.get<ReportCargoTransportersRatingsExcelService>(Types.ReportCargoTransportersRatingsExcelService);
        this._reportCargoTransportersRatingSummaryExcelService = VolmaContainer.get<ReportCargoTransportersRatingSummaryExcelService>(Types.ReportCargoTransportersRatingSummaryExcelService);
        this._reportReceivedDeliveriesService            = VolmaContainer.get<ReportReceivedDeliveriesService>(Types.ReportReceivedDeliveriesService);
        this._reportDriverScoreService                  = VolmaContainer.get<ReportDriverScoreService>(Types.ReportDriverScore);

    }


    public LoadReportCharts(waitingText: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.ReportChart, PropertyHelper.GetPropertyName((x: ReportParametersDTO) => x.OrderNum), ESortDir.Desc, undefined, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    if (PropertyHelper.IsObject(data) && PropertyHelper.IsArray(data.Items)){
                        for (let reportParamsDTO of data.Items) {
                            let params = JSON.parse(reportParamsDTO.Parameters);
                            let dto: ReportParametersExtendedDTO = { ...reportParamsDTO, Items: [], Data: undefined };
                            let reportType = this.GetEReportType(reportParamsDTO.ReportType);
                            let reportService = this.GetReportParamsService(reportType);
                            this.GetChartData(dto, params, reportService, dispatch, waitingText);
                        }
                    }
                });
        }
    }

    public ChangeReportParams(type: EReportType, waitingText: string) {
        return (dispatch) => {        
            let currentReportParamsService = this.GetReportParamsService(type);
            let isExcelReport: boolean = this.GetIsExcelReport(type);
            dispatch({ type: REPORT_REPORT_TYPE_UPDATED, Payload: { ReportService: currentReportParamsService, ParamsDTO: {}, IsExcelReport: isExcelReport, ReportType: type } });
        }
    }

    public DeleteReportParams() {
        return (dispatch) => {
            dispatch(this.UpdateExcelFileId(undefined))
            dispatch({ type: REPORT_REPORT_TYPE_UPDATED, Payload: { ReportService: undefined, ParamsDTO: {}, IsExcelReport: false, ReportType: undefined } });
        }
    }

    public GetExcelReport(reportType: EReportType, reportParams: any, waitingText: string) {
        return (dispatch) => {
            let entity = this.GetEEntityType(reportType);
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._reportApi.PostExcelReportParams(entity, reportParams).then((response) => {
                    let paramsId = response.data;
                    return this._reportApi.GetExcelFile(paramsId);
                }) ,
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }


    public AddChart(reportType: EReportType, name: string, reportParams: any, orderNum: number, reportService: IReportService<any>, waitingText: string) {
        return (dispatch) => {
            let dto: ReportParametersExtendedDTO = new ReportParametersExtendedDTO();
            dto.ReportType = this.GetEEntityType(reportType);
            dto.Parameters = JSON.stringify(reportParams);
            dto.Name = name;
            dto.OrderNum = orderNum;

            this._apiActions.DoApiRequest(
                dispatch,
                () => this._reportApi.PostChartParams(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = response.data;
                    if (data != undefined && data !== null)
                        dto.Id = data.Data;
                    dto.OrderNum = orderNum;
                    this.GetChartData(dto, reportParams, reportService, dispatch, waitingText);
                });
        }
    }

    public DeleteChart(reportParams: ReportParametersExtendedDTO, waitingText: string) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._reportApi.DeleteChart(reportParams.Id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    dispatch(this.ReportChartDeleted(reportParams));
                });

        }
    }

    public ReloadChartData(dto: ReportParametersExtendedDTO){
        let reportType = this.GetEReportType(dto.ReportType);
        let reportService = this.GetReportParamsService(reportType);
        return (dispatch) => {
            this._reportApi.GetReportData(dto.ReportType, JSON.parse(dto.Parameters))
            .then((response) => {
                let chartData = response.data;
                dto.Data = JSON.parse(chartData);
                if (PropertyHelper.IsObject(dto.Data) && PropertyHelper.IsArray(dto.Data.datasets)) {
                    let bgColorIndex = 0;
                    let borderColorIndex = 0;
                    for (let dataset of dto.Data.datasets) {
                        reportService.SetColor(CHART_BACKGROUND_COLORS[bgColorIndex], CHART_BORDER_COLORS[borderColorIndex], dataset, bgColorIndex);
                        bgColorIndex++;
                        borderColorIndex++;
                        if (bgColorIndex >= CHART_BACKGROUND_COLORS.length)
                            bgColorIndex = 0;
                        if (borderColorIndex >= CHART_BORDER_COLORS.length)
                            borderColorIndex = 0
                    }
                }
                dispatch(this.ReportChartUpdated(dto));
            })
        }
    }

    private GetChartData(dto: ReportParametersExtendedDTO, reportParams: any, reportService: IReportService<any>, dispatch, waitingText: string) {
        this._apiActions.DoApiRequest(
            dispatch,
            () => this._reportApi.GetReportData(dto.ReportType, reportParams),
            waitingText,
            (response: AxiosResponse, dispatch: any) => {
                let chartData = response.data;
                dto.Data = JSON.parse(chartData);
                if (PropertyHelper.IsObject(dto.Data) && PropertyHelper.IsArray(dto.Data.datasets)) {
                    let bgColorIndex = 0;
                    let borderColorIndex = 0;
                    for (let dataset of dto.Data.datasets) {
                        reportService.SetColor(CHART_BACKGROUND_COLORS[bgColorIndex], CHART_BORDER_COLORS[borderColorIndex], dataset, bgColorIndex);
                        bgColorIndex++;
                        borderColorIndex++;
                        if (bgColorIndex >= CHART_BACKGROUND_COLORS.length)
                            bgColorIndex = 0;
                        if (borderColorIndex >= CHART_BORDER_COLORS.length)
                            borderColorIndex = 0
                    }
                }
                dispatch(this.ReportChartAdded(dto));
            });
    }

    private ReportChartAdded(dto: ReportParametersExtendedDTO): IActionPayloaded<IReportChartPayload> {
        return { type: REPORT_CHART_ADDED, Payload: { ChartData: dto } };
    }

    private ReportChartUpdated(dto: ReportParametersExtendedDTO): IActionPayloaded<IReportChartPayload> {
        return { type: REPORT_CHART_UPDATED, Payload: { ChartData: dto } };
    }

    private ReportChartDeleted(dto: ReportParametersExtendedDTO): IActionPayloaded<IReportChartPayload> {
        return { type: REPORT_CHART_DELETED, Payload: { ChartData: dto } };
    }

    private UpdateExcelFileId(id: string): IActionPayloaded<string> {
        return { type: REPORT_EXCEL_FILE_ID_UPDATED, Payload:  id};
    }

    private GetEEntityType(type: EReportType): EEntityType {
        switch (type) {
            case EReportType.ComplaintsExcel:
                return EEntityType.ComplaintReportItem;
            case EReportType.DeliveriesExcel:
                return EEntityType.DeliverySummaryReportItem;
            case EReportType.RatesExcel:
                return EEntityType.RateReportItem;
            case EReportType.CargoTransportersRatingsExcel:
                return EEntityType.RatingReportItem;
            case EReportType.TendersExcel:
                return EEntityType.TenderReportItem;
            case EReportType.ReceivedDeliveries:
                return EEntityType.ReceivedDeliveryReportItem;
            case EReportType.CargoTransportersRatingSummaryExcel:
                return EEntityType.RatingSummaryReportItem;
            case EReportType.DriverScore:
                return EEntityType.DriverScore;
        }
        throw new Error('Unexpected report type: ' + type);
    }

    private GetEReportType(type: EEntityType): EReportType {
        switch (type) {
            case EEntityType.ComplaintReportItem:
                return EReportType.ComplaintsExcel;
            case EEntityType.DeliverySummaryReportItem:
                return EReportType.DeliveriesExcel;
            case EEntityType.RateReportItem:
                return EReportType.RatesExcel;
            case EEntityType.RatingReportItem:
                return EReportType.CargoTransportersRatingsExcel;
            case EEntityType.TenderReportItem:
                return EReportType.TendersExcel;
            case EEntityType.ReceivedDeliveryReportItem:
                return EReportType.ReceivedDeliveries;
            case EEntityType.RatingSummaryReportItem:
                return EReportType.CargoTransportersRatingSummaryExcel;
            case EEntityType.DriverScore:
                return EReportType.DriverScore;
        }
        throw new Error('Unexpected report type: ' + type);
    }

    private GetReportParamsService(type: EReportType): IReportService<any>{
        switch (type) {
            case EReportType.TendersExcel:
                return this._reportTendersExcelService;
            case EReportType.RatesExcel:
                return  this._reportRatesExcelService;
            case EReportType.DeliveriesExcel:
                return this._reportDeliveriesExcelService;
            case EReportType.ComplaintsExcel:
                return this._reportComplaintsExcelService;
            case EReportType.CargoTransportersRatingsExcel:
                return this._reportCargoTransportersRatingsExcelService;
            case EReportType.CargoTransportersRatingSummaryExcel:
                return this._reportCargoTransportersRatingSummaryExcelService;
            case EReportType.ReceivedDeliveries:
                return  this._reportReceivedDeliveriesService;
            case EReportType.DriverScore:
                return  this._reportDriverScoreService;
        }
        throw new Error('Unexpected report type: ' + type);
    }

    private GetIsExcelReport(type: EReportType): boolean{
        switch (type) {
            case EReportType.TendersExcel:
            case EReportType.RatesExcel:
            case EReportType.DeliveriesExcel:
            case EReportType.ComplaintsExcel:
            case EReportType.CargoTransportersRatingsExcel:
            case EReportType.DriverScore:
            case EReportType.CargoTransportersRatingSummaryExcel:
                return true;
            case EReportType.ReceivedDeliveries:
                return  false;
            default:
                return false
        }
    }
}