import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as content from './Localization/i18n/index.jsn';

i18next
    .use(initReactI18next)
    .init({
        lng: 'ru',
        resources: content,
        fallbackLng: 'ru',

        defaultNS: 'common',

        debug: false,

        interpolation: {
            escapeValue: false
        },
        react: {
            useSuspense: false
        },
    });
export default i18next;