import { BaseEntityActions } from '../../../BaseEntity/BaseEntityActions';
import { EntityAction } from '../../../BaseEntity/EntityAction';
import { EEntityType } from '../../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { DRIVER_All_CONTRACTS_LOADED } from '../../../../../Constants/AppConstants';
import { ESortDir } from '../../../../../Domain/Enum/ESortDir';
import { ContractTableDTO } from '../../../../../Domain/DTO/ContractTableDTO';

export class SelectContractActions extends EntityAction {

    public LoadAllContracts(
        text: string
    ){
        return super.LoadDataTable<ContractTableDTO>(
            EEntityType.Contract,
            x => x.EndTime,
            ESortDir.Asc,
            undefined,
            text,
            this.AllContractsLoaded);
    }

    private AllContractsLoaded(value: Array<ContractTableDTO>): IActionPayloaded<Array<ContractTableDTO>> {
        return { type: DRIVER_All_CONTRACTS_LOADED, Payload: value };
    }
}