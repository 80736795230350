import { ERestorePasswordResult } from '../../Domain/Enum/ERestorePasswordResult';
import { ResetPasswordDTO } from '../../Domain/DTO/ResetPasswordDTO';
import {
    PASSWORD_RESTORE_CLEAR,
    PASSWORD_RESTORE_FAILED,
    PASSWORD_RESTORE_MOUNTED,
    PASSWORD_RESTORE_SET_USERID_TOKEN,
    PASSWORD_RESTORE_SUCCESSFULLY,
    PASSWORD_RESTORE_UNMOUNTED
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../Shared/VolmaComponentReducer';
import { IPasswordRestoreProps, PasswordRestorePropsInitial } from './IPasswordRestoreProps';
import { SetUserIdTokenPayload, PasswordRestoreFailedPayload } from './Payloads';
import { RestorePasswordDTO } from '../../Domain/DTO/RestorePasswordDTO';
import { IPasswordRestoreHelperProps } from './IHelperProps';

class PasswordRestoreReducer extends VolmaComponentReducer<IPasswordRestoreProps> {

    constructor() {
        super(PASSWORD_RESTORE_MOUNTED, PASSWORD_RESTORE_UNMOUNTED);
    }

    public Reduce(state: IPasswordRestoreProps = PasswordRestorePropsInitial, action: IActionPayloaded<any>): IPasswordRestoreProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case PASSWORD_RESTORE_SET_USERID_TOKEN:
                return this.ReduceSetUserIdToken(state, action.Payload);
            case PASSWORD_RESTORE_SUCCESSFULLY:
                return this.ReducePasswordRestored(state);
            case PASSWORD_RESTORE_FAILED:
                return this.ReducePasswordRestoreFailed(state, action.Payload);
            case PASSWORD_RESTORE_CLEAR:
                return this.ReducePasswordRestoreClear(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceSetUserIdToken(state: IPasswordRestoreProps, payload: SetUserIdTokenPayload): IPasswordRestoreProps {
        return { ...state, DataDTO: <RestorePasswordDTO>{ ...state.DataDTO, UserId: payload.UserId, Token: payload.Token } };
    }

    private ReducePasswordRestored(state: IPasswordRestoreProps): IPasswordRestoreProps {
        return { ...state, DataHelper: <IPasswordRestoreHelperProps>{ ...state.DataHelper, RestoreResult: ERestorePasswordResult.Success } };
    }

    private ReducePasswordRestoreFailed(state: IPasswordRestoreProps, payload: PasswordRestoreFailedPayload): IPasswordRestoreProps {
        return { ...state, DataHelper: <IPasswordRestoreHelperProps>{ ...state.DataHelper, RestoreResult: payload.Result, Errors: payload.Errors } };
    }

    private ReducePasswordRestoreClear(state: IPasswordRestoreProps, payload: PasswordRestoreFailedPayload): IPasswordRestoreProps {
        return { ...state, DataHelper: <IPasswordRestoreHelperProps>{ ...state.DataHelper, RestoreResult: undefined, Errors: [] } };
    }

    public static Initialize(): (state: IPasswordRestoreProps, action: IActionPayloaded<IPasswordRestoreProps>) => IPasswordRestoreProps {
        const reducer = new PasswordRestoreReducer();
        return (state: IPasswordRestoreProps, action: IActionPayloaded<IPasswordRestoreProps>) => reducer.Reduce(state, action);
    }
}

export { PasswordRestoreReducer };