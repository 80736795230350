import { VehicleTableDTO } from '../../../Domain/DTO/VehicleTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityAction } from '../BaseEntity/EntityAction';
import { injectable } from 'inversify';
import { LatLng } from 'leaflet';
import { SET_IS_MAX_PERCENTAGE_AVAILABLE } from '../../../Constants/AppConstants';


@injectable()
export class LoadingPointAction extends EntityAction {

    public setIsMaxPercentageAvailable(state: boolean): IActionPayloaded<boolean> {       
        return { type: SET_IS_MAX_PERCENTAGE_AVAILABLE, Payload: state}
    }


}