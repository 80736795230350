import { injectable } from 'inversify';
import * as React from 'react';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { RouteRateDTO } from '../../../Domain/DTO/RouteRateDTO';
import { RouteRateTableDTO } from '../../../Domain/DTO/RouteRateTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ERating } from '../../../Domain/Enum/ERating';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NumberRangeHelper } from "../../../Infrastructure/Validation/NumberRangeHelper";
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import i18next from '../../i18n';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableRouteRateCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableRouteRateCellRenderers';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { GroupRate } from './GroupRate';
import { InitialRouteRateHelperProps, RouteRateHelperProps } from './IHelperProps';
import { RouteRateActions } from './RouteRateActions';
import { RouteRateReducer } from './RouteRateReducer';

@injectable()
export class RouteRateEntityService extends BaseEntityService<RouteRateDTO, RouteRateHelperProps>{
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: RouteRateReducer;
    private _action: RouteRateActions;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._reducer = VolmaContainer.get<RouteRateReducer>(Types.RouteRateReducer);
        this._action = VolmaContainer.get<RouteRateActions>(Types.RouteRateActions);

        this.GetRateCellText = this.GetRateCellText.bind(this);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.RouteRate]
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.RouteRate,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.Id)},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.LoadingPointName),
                    FilterKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.LoadingPointId),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.LoadingPoint,
                    CellRenderer: VolmaTableRouteRateCellRenderers.RouteLoadingPointCellRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.TargetZoneName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.Zone,
                    FilterKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.ZoneId),
                    CellRenderer: VolmaTableRouteRateCellRenderers.RouteZoneCellRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.Rate),
                    CellTextGetter: this.GetRateCellText,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: LoadingPointTableDTO) => val.MaxPercentageOfBetIncrementation),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined,
                    IsSortable: false,
                    IsFilterable: false
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteRateTableDTO) => val.Score),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },

            ]
        );
    }

    public GetEditorModal(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 0, i18next.t("routerate:MainInfoTitle"), i18next.t("routerate:MainInfoDescription"), this.GetMainInfoBlock(props, isEditable), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetAdditionalInfoBlock(props, isEditable))}
                        {this.GetMainInfoNoTitleItem(false, true, this.GetRateTableBlock(props))}
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>){
        this._dispatch = props.dispatch;        
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialRouteRateHelperProps;
    }

    public OnAfterDataLoaded(dto: RouteRateDTO): void {
        this._dispatch(this._action.LoadSettings(
            i18next.t("common:LoadingSettings"),
            () => this.UpdateGroupRate(dto.Rate))
        );
    }

    private UpdateGroupRate(value: number){
        setTimeout(() => {
            this._dispatch(this._action.RateUpdated(value));
        });
    }

    public GetGroupRateColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.RouteRate,
            [
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: GroupRate) => val.RateGroup),
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererProps)
                },
                { DataKey: PropertyHelper.GetPropertyName((val: GroupRate) => val.RateValue)}
            ]
        );
    }

    private GetMainInfoBlock(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>, isEditable: boolean): JSX.Element {
        const isShipper = this._authService.IsAdministrator();
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteRateDTO) => val.LoadingPointId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RouteRateDTO) => x.LoadingPointId)}
                            Label={i18next.t("routerate:LoadingPointId")}
                            Placeholder={i18next.t("routerate:SelectLoadingPoint")}
                            Entity={EEntityType.LoadingPoint}
                            Value={props.DataDTO.LoadingPointId}
                            Required={true}
                            Readonly={this.IsRouteRateFieldDisableForEdit(props)}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteRateDTO) => val.TargetZoneId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RouteRateDTO) => x.TargetZoneId)}
                            Label={i18next.t("routerate:TargetZoneId")}
                            Placeholder={i18next.t("routerate:SelectZone")}
                            Entity={EEntityType.Zone}
                            Value={props.DataDTO.TargetZoneId}
                            Required={true}
                            Readonly={this.IsRouteRateFieldDisableForEdit(props)}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                {this.GetEditableMaxPercentageOfBetIncrementation(props, isShipper)}
            </div>
        );
    }

    private GetAdditionalInfoBlock(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>, isEditable: boolean): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteRateDTO) => val.PayloadId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RouteRateDTO) => x.PayloadId)}
                            Label={i18next.t("routerate:PayloadId")}
                            Placeholder={i18next.t("routerate:SelectPayload")}
                            Entity={EEntityType.Payload}
                            Value={props.DataDTO.PayloadId}
                            Required={true}
                            Readonly={this.IsRouteRateFieldDisableForEdit(props)}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteRateDTO) => val.Score) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RouteRateDTO) => x.Score)}
                            Label={i18next.t("routerate:Score")}
                            Placeholder={i18next.t("routerate:ScorePlaceholder")}
                            MinValue={NumberRangeHelper.minPositiveValue}
                            MaxValue={NumberRangeHelper.maxAvailableValue}
                            Validator={this._numberValidator}
                            Value={props.DataDTO.Score}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteRateDTO) => val.Rate) }
                            Name={PropertyHelper.GetPropertyName((val: RouteRateDTO) => val.Rate)}
                            Label={i18next.t('routerate:Rate')}
                            Placeholder={i18next.t('routerate:RatePlaceholder')}
                            Value={props.DataDTO.Rate}
                            MinValue={() => 0}
                            Step={1}
                            Validator={this._numberValidator}
                            Required={true}
                            CustomDataUpdate={((state: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>, dtoFieldName: string, fieldValue: any) =>
                                this.UpdateGroupRate(fieldValue)).bind(this)}
                            Readonly={this.IsRouteRateFieldDisableForEdit(props)}
                            Unit={i18next.t("common:rub")}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetRateTableBlock(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("top")).toString()}>
                    <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("routerate:RateTableTitle")}</h2>
                </div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                        <div className={this._mainInfo("line", { full: true }).toString()}>
                            <ExperimentalVolmaTable
                                {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: RouteRateHelperProps) => val.RateList)}
                                Name={PropertyHelper.GetPropertyName((val: RouteRateHelperProps) => val.RateList)}
                                Columns={this.GetGroupRateColumnsList()}
                                Data={props.DataHelper.RateList}
                                DataOriginal={props.DataHelper.RateList}
                                LoadDataFromServer={false}
                                IsTableInsideEntity={true}
                                dispatch={props.dispatch}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetEditableMaxPercentageOfBetIncrementation(props: IBaseEntityProps<RouteRateDTO, any>, isEditable: boolean ): JSX.Element {
        return (
            <div className={this._mainInfo("cell", { full: true, 50: true }).toString()}>
                <div className={this._mainInfo("line", { full: true }).toString()}>
                    <VolmaNumber
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteRateDTO) => val.MaxPercentageOfBetIncrementation) }
                        Name={PropertyHelper.GetPropertyName((val: RouteRateDTO) => val.MaxPercentageOfBetIncrementation)}
                        Label={i18next.t('routerate:MaxPercentageOfBetIncrementation')}
                        Placeholder={i18next.t('routerate:PlaceholderMaxPercentageOfBetIncrementation')}
                        Value={props.DataDTO.MaxPercentageOfBetIncrementation}
                        Validator={this._numberValidator}
                        MinValue={() => 0}
                        MaxValue={() => 100}
                        dispatch={props.dispatch}
                        Required={false}
                        Readonly={!isEditable}
                        CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.OnMaxPercentageOfBetIncrementationUpdate(state, dtoFieldName, fieldVal)}
                    />
                </div>
            </div>
        );
    }

    private OnMaxPercentageOfBetIncrementationUpdate(state: IBaseEntityProps<RouteRateDTO, any>, dtoFieldName: string, fieldValue: any) {
        fieldValue === 0 ?
            state.DataDTO.MaxPercentageOfBetIncrementation = undefined :
            state.DataDTO.MaxPercentageOfBetIncrementation = fieldValue;
    }


    private GetRateCellText(props: IVolmaTableProps<{}>, rowIndex: number) {
        let dto = props.Data[rowIndex] as RouteRateTableDTO;

        return this.GetSafeMoney(dto.Rate);
    }

    private IsRouteRateFieldDisableForEdit(props: IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>): boolean {
        return !this.IsGodMode(props);
    }
}