import { injectable } from 'inversify';
import {
    DELIVERY_CONFIRM_ALL_DRIVERS_LOADED,
    DELIVERY_CONFIRM_ALL_VEHICLES_LOADED, DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED,
    DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER
} from '../../../../Constants/AppConstants';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { MultiCargoTransporterDTO } from '../../../../Domain/DTO/MultiCargoTransporterDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { DeliveryConfirmDTO } from './DeliveryConfirmDTO';
import { DeliveryConfirmHelperProps } from './IHelperProps';

type IState = IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>;
@injectable()
export class DeliveryConfirmReducer extends EntityReducer<DeliveryConfirmDTO, DeliveryConfirmHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case DELIVERY_CONFIRM_ALL_DRIVERS_LOADED:
                return this.ReduceAllDriversLoaded(state, action.Payload);
            case DELIVERY_CONFIRM_ALL_VEHICLES_LOADED:
                return this.ReduceAllVehiclesLoaded(state, action.Payload);
            case DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER:
                return this.ReduceCargoTransporterSelected(state, action.Payload);
            case DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED:
                return this.ReduceMultiCargoTransporterLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceAllDriversLoaded(state: IState, payload: Array<DriverTableDTO>): IState{
        return super.CollectionLoaded<DriverTableDTO>(state, payload, [x => x.AllDrivers]);
    }

    private ReduceAllVehiclesLoaded(state: IState, payload: Array<VehicleTableDTO>): IState{
        return super.CollectionLoaded<VehicleTableDTO>(state, payload, [x => x.AllVehicles]);
    }

    private ReduceCargoTransporterSelected(state: IState, payload: CargoTransporterTableDTO): IState {
        return super.EntityLoaded<CargoTransporterTableDTO>(state, payload, x => x.SelectedCargoTransporter);
    }

    private ReduceMultiCargoTransporterLoaded(state: IState, payload: MultiCargoTransporterDTO): IState {
        return super.EntityLoaded<CargoTransporterTableDTO>(state, payload, x => x.MultiCargoTransporter);
    }
}
