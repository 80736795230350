import { LOGIN_MOUNTED, LOGIN_UNMOUNTED } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../Shared/VolmaComponentReducer';
import { ILoginProps, LoginPropsInitial } from './ILoginProps';

class LoginReducer extends VolmaComponentReducer<ILoginProps> {

    constructor() {
        super(LOGIN_MOUNTED, LOGIN_UNMOUNTED);
    }

    public Reduce(state: ILoginProps = LoginPropsInitial, action: IActionPayloaded<any>): ILoginProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            default:
                return state;
        }
    }

    public static Initialize(): (state: ILoginProps, action: IActionPayloaded<ILoginProps>) => ILoginProps{
        const reducer = new LoginReducer();
        return (state: ILoginProps, action: IActionPayloaded<ILoginProps>) => reducer.Reduce(state, action);
    }
}

export {LoginReducer};