export default class ValidationResult{
    public IsValid: boolean;
    public ErrorMessage: string;

    constructor(isValid: boolean, message: string = undefined) {
        this.IsValid = isValid;
        this.ErrorMessage = message;
    }

    public static Success() {
        return new ValidationResult(true);
    }

    public static Fail(message: string) {
        return new ValidationResult(false, message);
    }
}