import { SelectDriverDTO } from './SelectDriverDialog/SelectDriverDTO';
import * as React from 'react';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import { VehicleReducer } from './VehicleReducer';
import { VehicleActions } from './VehicleActions';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { VehicleDTO } from '../../../../Domain/DTO/VehicleDTO';
import { VehicleHelperProps, InitialVehicleHelperProps } from './IHelperProps';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaInput from '../../../VolmaInput';
import VolmaNumber from '../../../VolmaNumber';
import VolmaSelect from '../../../VolmaSelect';
import { injectable } from 'inversify';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import ExperimentalVolmaTable from '../../../Table/VolmaTable/ExperimentalVolmaTable';
import i18next from '../../../i18n';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { LocalDeleteItemsAction } from '../../../Table/VolmaTable/Actions/LocalDeleteItemsAction';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';
import { VehicleColumnTemplates } from './VehicleColumnTemplates';
import { isNullOrUndefined, isDefined } from '../../../../Infrastructure/Services/Utils';


@injectable()
export class VehicleEntityService extends BaseEntityService<VehicleDTO, VehicleHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _volmaTableService: VolmaTableService;
    private _driverColumns: Array<IVolmaTableColumn>;
    private _reducer: VehicleReducer;
    private _action: VehicleActions;
    private _dispatch: any;
    private _modalActions: VolmaModalActions;
    private _currentCargoTransporterId: string;
    private _vehicleColumnTemplates: VehicleColumnTemplates;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._reducer = VolmaContainer.get<VehicleReducer>(Types.VehicleReducer);
        this._action = VolmaContainer.get<VehicleActions>(Types.VehicleActions);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

        this._driverColumns = this.GetDriverColumnsList();
        this._vehicleColumnTemplates = new VehicleColumnTemplates();
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Vehicle]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        const commonTemplates = [
            { ...this._vehicleColumnTemplates.Name },
            { ...this._vehicleColumnTemplates.VehicleLicensePlateNumber, },
            { ...this._vehicleColumnTemplates.HandlingTypeName, },
            { ...this._vehicleColumnTemplates.PayloadName, },
            { ...this._vehicleColumnTemplates.TrailerModel, },
            { ...this._vehicleColumnTemplates.TrailerLicensePlateNumber, },
        ];

        if (this._authService.IsIncludedCargoTransporter(false)) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Vehicle,
                [
                    { ...this._vehicleColumnTemplates.Id },
                    ...commonTemplates,
                ]
            );
        } 

        if (this._authService.IsIncludedCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Vehicle,
                [
                    { ...this._vehicleColumnTemplates.Id },
                    { ...this._vehicleColumnTemplates.CargoTransporterName },
                    ...commonTemplates,
                ]
            );
        }        

        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Vehicle,
            [
                { ...this._vehicleColumnTemplates.Id },
                { ...this._vehicleColumnTemplates.MultiTransporterName },
                ...commonTemplates,
            ]
        );

    }

    public GetDriverColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Driver,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Id) },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.PhoneNumber), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverTableDTO) => val.Details), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );
    }

    public GetEditorModal(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.GetHeaderBlock(props))}
                    {this.GetMainInfoNoTitleItem(false, true, this.GetMainInfoBlock(props, true))}
                    {this.GetMainInfoNoTitleItem(false, true, this.GetTrailerInfoBlock(props, true))}
                    {this.GetMainInfoNoTitleItem(true, false, this.GetAdditionalInfoBlock(props))}
                    {this.CanViewCargoTransporterInfoBlock && this.GetMainInfoNoTitleItem(false, true, this.GetCargoTransporterInfoBlock(props))}
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        if (this.IsEditable(props))
            return this.GetVehicleEditor(props);
        return this.GetReadonlyVehicleEditor(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.Name)}
                        Label={i18next.t('vehicle:Name')}
                        Placeholder={i18next.t('vehicle:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        IsInHeader={true}
                        Required={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.VehicleLicensePlateNumber) }
                        Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.VehicleLicensePlateNumber)}
                        Label={i18next.t('vehicle:VehicleLicensePlateNumber')}
                        Placeholder={i18next.t('vehicle:VehicleLicensePlateNumberPlaceholder')}
                        Value={props.DataDTO.VehicleLicensePlateNumber}
                        Validator={this._inputValidator}
                        IsInHeader={true}
                        Required={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    private GetHeaderBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.Name) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.Name)}
                            Label={i18next.t('vehicle:Name')}
                            Value={props.DataDTO.Name}
                            Validator={this._inputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.VehicleLicensePlateNumber) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.VehicleLicensePlateNumber)}
                            Label={i18next.t('vehicle:VehicleLicensePlateNumber')}
                            Value={props.DataDTO.VehicleLicensePlateNumber}
                            Validator={this._inputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>) {
        this._dispatch = props.dispatch;

        if (props.match.params !== undefined) {
            const entity = props.match.params.entity;
            const id = props.match.params.id;
            
            if (entity === EEntityType[EEntityType.CargoTransporter].toLowerCase())
                this._currentCargoTransporterId = id;
            else
                this._currentCargoTransporterId = undefined;
        }

        if (this._authService.IsCargoTransporter()) {
            this._currentCargoTransporterId = this._authService.GetCurrentUser().CargoTransporterId;
        }

    }


    public InitializeDefaultDTOValues(dto: VehicleDTO) {
        const isNewItemCreation = !this.IsEntityEditCase(dto.Id);

        if (isDefined(this._currentCargoTransporterId) && this._currentCargoTransporterId !== dto.CargoTransporterId && isNewItemCreation){
            dto.CargoTransporterId = this._currentCargoTransporterId;
        }
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialVehicleHelperProps;
    }

    public OnAfterDataLoaded(dto: VehicleDTO) {
        this._dispatch(this._action.LoadDrivers(dto.Id, i18next.t("common:LoadingDriversTableData")));
    }

    private AddDriver(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>){
        const dto: SelectDriverDTO = new SelectDriverDTO();
        dto.SelectedDriverIds = props.DataDTO.Drivers;
        this._dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.SelectDriver, dto, undefined, (dto) => {
            this._dispatch(this._action.DriverAddedToVehicle(dto.SelectedDriver));
        }))
    }

    private CreateAndAddDriver(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>){
        this._dispatch(this._modalActions.OpenServerEntityModal(EEntityType.Driver, undefined, undefined, (dto) => {
            this._dispatch(this._action.DriverAddedToVehicle(dto));
        }))
    }

    private DeleteDrivers(ids: string[]){
        this._dispatch(this._action.DriverRemovedFromVehicle(ids));
    }

    private GetVehicleEditor(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("vehicle:MainInfoTitle"), i18next.t("vehicle:MainInfoTitleDescription"), this.GetMainInfoBlock(props, false), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetTrailerInfoBlock(props, false))}
                        {this.GetMainInfoNoTitleItem(false, true, this.GetAdditionalInfoBlock(props))}
                        {this.CanViewCargoTransporterInfoBlock && this.GetMainInfoNoTitleItem(true, false, this.GetCargoTransporterInfoBlock(props))}
                    </div>
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("vehicle:DriverListTitle"), i18next.t("vehicle:DriverListDescription"), this.GetDriverTableBlock(props), [this.AddDriverButton(props, 0), this.CreateAndAddDriverButton(props, 1)], props, this._dispatch)}
                </div>
            </div >
        );
    }

    private GetReadonlyVehicleEditor(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("vehicle:MainInfoTitle"), i18next.t("vehicle:MainInfoDescription"), this.GetMainInfoBlock(props, false), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetTrailerInfoBlock(props, false))}
                        {this._authService.IsAdministrator() && this.GetMainInfoNoTitleItem(false, true, this.GetAdditionalInfoBlock(props))}
                    </div>
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("vehicle:DriverListTitle"), i18next.t("vehicle:DriverListDescription"), this.GetDriverTableBlock(props), undefined, props, this._dispatch)}
                </div>
            </div >
        );
    }

    private GetMainInfoBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, isInModal: boolean): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: VehicleDTO) => val.PayloadId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: VehicleDTO) => x.PayloadId)}
                            Label={i18next.t("vehicle:PayloadId")}
                            Placeholder={i18next.t("vehicle:SelectPayload")}
                            Entity={EEntityType.Payload}
                            Value={props.DataDTO.PayloadId}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 50: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: VehicleDTO) => val.BodyTypeId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: VehicleDTO) => x.BodyTypeId)}
                            Label={i18next.t("vehicle:BodyTypeId")}
                            Placeholder={i18next.t("vehicle:SelectBodyType")}
                            Entity={EEntityType.BodyType}
                            Value={props.DataDTO.BodyTypeId}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 50: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: VehicleDTO) => val.HandlingTypeId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: VehicleDTO) => x.HandlingTypeId)}
                            Label={i18next.t("vehicle:HandlingTypeId")}
                            Placeholder={i18next.t("vehicle:SelectHandlingType")}
                            Entity={EEntityType.HandlingType}
                            Value={props.DataDTO.HandlingTypeId}
                            Required={false}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 50: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.AxleCount) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.AxleCount)}
                            Label={i18next.t('vehicle:AxleCount')}
                            Placeholder={i18next.t('vehicle:AxleCountPlaeholder')}
                            Value={props.DataDTO.AxleCount}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={1}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetTrailerInfoBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, isInModal: boolean): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 33: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.TrailerModel) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.TrailerModel)}
                            Label={i18next.t('vehicle:TrailerModel')}
                            Value={props.DataDTO.TrailerModel}
                            Validator={this._inputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 33: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.TrailerLicensePlateNumber) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.TrailerLicensePlateNumber)}
                            Label={i18next.t('vehicle:TrailerLicensePlateNumber')}
                            Value={props.DataDTO.TrailerLicensePlateNumber}
                            Validator={this._inputValidator}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 33: !isInModal }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.TrailerAxleCount) }
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.TrailerAxleCount)}
                            Label={i18next.t('vehicle:TrailerAxleCount')}
                            Value={props.DataDTO.TrailerAxleCount}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={1}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetAdditionalInfoBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: VehicleDTO) => val.AditionalInfo) }
                            Type="textarea"
                            Name={PropertyHelper.GetPropertyName((val: VehicleDTO) => val.AditionalInfo)}
                            Label={i18next.t('vehicle:AditionalInfo')}
                            Value={props.DataDTO.AditionalInfo}
                            Validator={this._inputValidator}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetCargoTransporterInfoBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: VehicleDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: VehicleDTO) => x.CargoTransporterId)}
                            Label={i18next.t("vehicle:CargoTransporterId")}
                            Placeholder={i18next.t("vehicle:SelectCargoTransporter")}
                            Entity={EEntityType.CargoTransporter}
                            Value={props.DataDTO.CargoTransporterId}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private get CanViewCargoTransporterInfoBlock(): boolean {
        return this._authService.IsAdministrator() || this._authService.IsCargoTransporter();
    }

    private GetDriverTableBlock(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>): JSX.Element {
        let actions = [];
        if(this.IsEditable(props))
            actions = [new LocalDeleteItemsAction<VehicleHelperProps>((ids: Array<string>) => { this.DeleteDrivers(ids) })];
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: VehicleDTO) => val.Drivers) }
                            Name={PropertyHelper.GetPropertyName((x: VehicleDTO) => x.Drivers)}
                            Columns={this._driverColumns}
                            Data={props.DataHelper.Drivers}
                            DataOriginal={props.DataHelper.DriversOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            dispatch={props.dispatch}
                            Actions={actions}/>
                    </div>
                </div>
            </div>
        );
    }

    private AddDriverButton(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, key: number): JSX.Element {
        return (
            <a key={key} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}
            onClick={this.AddDriver.bind(this, props)}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("vehicle:AddDriver")}</span>
            </a>
        );
    }

    private CreateAndAddDriverButton(props: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, key: number): JSX.Element {
        return (
            <a key={key} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}
            onClick={this.CreateAndAddDriver.bind(this, props)}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("vehicle:CreateAndAddDriver")}</span>
            </a>
        );
    }
}
