import { ComplaintTableDTO } from '../../../../Domain/DTO/ComplaintTableDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { ComplaintTableActions } from '../Table/ComplaintTableActions';
import i18next from '../../../i18n';
import { EComplaintTemplateDirection } from '../../../../Domain/Enum/EComplaintTemplateDirection';
import { ComplaintCommentDTO } from '../../../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintActions } from '../ComplaintActions';
import { IComplaintHelper } from '../IComplaintHelper';
import { ComplaintStateDTO } from '../../../../Domain/DTO/ComplaintStateDTO';

export class TakeIntoExaminationAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions    : VolmaModalActions;
    private _complaintActions: ComplaintActions;
    private _helper          : IComplaintHelper;

    public Title: string = "complaint:TakeIntoExamination";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<ComplaintTableDTO>): boolean {
        if (selectedIds === undefined || selectedIds.length !== 1)
            return false;

        for(let selectedId of selectedIds){
            let itm = data.find(x => x.Id === selectedId);
            if (itm === undefined)
                return false

            if (this._helper.IsBob(itm) && (itm.ComplaintState === EComplaintState.Billed || itm.ComplaintState === EComplaintState.TakenIntoExamination))
                return true
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ComplaintTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        let identifiers = new Array<string>();
        for (let selectedId of selectedIds) {
            let itm = data.find(x => x.Id === selectedId);
            if (itm !== undefined)
                identifiers.push(itm.RegistrationCode);
        }        
        let text = i18next.t("complaint:TakeIntoExaminationSelectedComplaints", { count: selectedIds.length, identifier: identifiers.join(', ')} as any)
        dispatch(this._modalActions.OpenConfirmationModal(text, () => {
            let state = new ComplaintStateDTO();
            state.State = EComplaintState.Processed;
            state.ComplaintId = id;
            dispatch(this._complaintActions.ChangeState(id, state, reloadData));
        }))
    }

    constructor(){
        super();
        this._modalActions     = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._complaintActions = VolmaContainer.get<ComplaintActions>(Types.ComplaintActions);
        this._helper           = VolmaContainer.get<IComplaintHelper>(Types.ComplaintHelper)
    }
}