import * as moment from 'moment';

import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { TenderBetAction } from '../Actions/TenderBetAction';
import { TenderWithdrawAction } from '../Actions/TenderWithdrawAction';
import { TenderHelperProps } from '../IHelperProps';

export class Bidding extends StatusDefault<TenderDTO, TenderHelperProps>{
    public TitleCurrent : string = "tender:StatusBettingCurrent";
    public TitleFinished: string = "tender:StatusBettingFinished";
    public TitleFuture  : string = "tender:StatusBettingFuture";

    public IsActive(dto: TenderDTO) {
        return true;
    }

    public IsCurrent(dto: TenderDTO){
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isAfter(this._timeService.GetLocalTime(dto.StartBiddingDate)) && moment().isBefore(this._timeService.GetLocalTime(dto.EndBiddingDate));
    }

    public IsFinished(dto: TenderDTO){
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isAfter(this._timeService.GetLocalTime(dto.EndBiddingDate));
    }

    public GetActions(dto: TenderDTO){
        return [new TenderBetAction(), new TenderWithdrawAction()];
    }
}