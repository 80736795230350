import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { AxiosPromise } from 'axios';

@injectable()
export class ServiceServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;
    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public GetServerTime(): AxiosPromise {
        return this.Get(this._urlFabric.GetServerTime(ECQRSApi.Read));
    }

    public GetDifferenceToServerTime(): AxiosPromise {
        return this.Get(this._urlFabric.GetDifferenceFromServerTime(ECQRSApi.Read, new Date()));
    }
}