import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { history } from '../../../ConfigureStore';
import { FORM_SUBMITTED, USER_PROFILE_CHANGED } from '../../../Constants/AppConstants';
import { RegisterShipperUserDTO } from '../../../Domain/DTO/RegisterShipperUserDTO';
import { ShipperUserDTO } from '../../../Domain/DTO/ShipperUserDTO';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import { EntityAction } from '../BaseEntity/EntityAction';


@injectable()
export class ShipperUserActions extends EntityAction {
    private _accountApi: AuthServerInteraction;
    private _authService: AuthenticationService;

    constructor(){
        super();
        this._accountApi = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
    }

    public SaveEntity(entity: EEntityType, dto: ShipperUserDTO | RegisterShipperUserDTO, isFormValid: boolean, waitingText: string, id?: string) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            this._apiActions.DoApiRequest(
                dispatch,
                () => id === undefined ? this._accountApi.RegisterShipperUser(dto) : this._accountApi.UpdateShipperUser(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let entityService = VolmaContainer.get<EntityService>(Types.EntityService);
                    history.push(entityService.GetEntityTableRoute(entity));
                    this._apiActions.DoApiRequest(dispatch, () => this._accountApi.GetUserInfo(), waitingText, (response: AxiosResponse, dispatch: any) => {
                        let userDTO: UserDTO = JSON.parse(response.data);
                        this._authService.SetUser(userDTO);
                        dispatch(this.UserProfileChanged(userDTO));
                    });
                });
        }
    }

    public DeleteEntity(name: string, entity: EEntityType, waitingText: string, id: string, onItemsDeleted: () => void) {
        return (dispatch: any) => {
            dispatch(this.Submit());
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._accountApi.DeleteShipperUser(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    onItemsDeleted();
                });
        }
    }


    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    private UserProfileChanged(user: UserDTO): IActionPayloaded<UserDTO> {
        return { type: USER_PROFILE_CHANGED, Payload: user };
    }
}