import { CargoTransporterUserTableDTO } from "../../../Domain/DTO/CargoTransporterUserTableDTO";
import PropertyHelper from "../../../Infrastructure/Services/PropertyHelper";
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumnDefinition,
    IVolmaTableProps,
} from "../../Table/VolmaTable/IVolmaTableProps";
import { VolmaTableHeaderRenderers } from "../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers";
import { VolmaTableCommonColumnDefinition } from "../../Table/VolmaTableCommonColumnDefinition";
import { CargoTransporterUserTableCellRenderers } from "./CargoTransporterUserTableCellRenderers";
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { EEntityType } from "../../../Domain/Enum/EEntityType";

export class CargoTransporterUserColumnTemplates {
    public get Id(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.Id),
        };
    }

    public get Fio(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.Fio),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get MultiTransporterName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiTransporterColumn({
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.CargoTransporterName),
        });
    }

    public get CargoTransporterName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.CargoTransporterName),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            Entity: EEntityType.CargoTransporter,
            FilterKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.CargoTransporterId)
        };
    }

    public get UserName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.UserName),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get Email(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.Email),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get PhoneNumber(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.PhoneNumber),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get IsBlocked(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterUserTableDTO) => val.IsBlocked),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
            CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                CargoTransporterUserTableCellRenderers.UserStatusRenderer(props, cellRendererParams),
            IsFilterable: false,
            IsSortable: false,
        };
    }
}
