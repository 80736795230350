import { WithTranslationProps } from 'react-i18next/*';

import { RegisterUserDTO } from '../../Domain/DTO/RegisterUserDTO';
import { BaseEntityPropsInitial, IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { InitialRegisterHelperProps, IRegisterHelperProps } from './IHelperProps';

export interface IRegisterProps extends IBaseEntityProps<RegisterUserDTO, IRegisterHelperProps>, WithTranslationProps {
}

export const RegisterPropsInitial: IRegisterProps = { ...BaseEntityPropsInitial, DataHelper: InitialRegisterHelperProps };
