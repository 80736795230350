export var Types = {
    HubProxyActions: "HubProxyActions",
    SignalRService : "SignalRService",

    EmptyValidator                      : "EmptyValidator",
    NonEmptyTextValidator               : "NonEmptyTextValidatorValidator",
    VolmaInputValidator                 : "VolmaInputValidatorValidator",
    VolmaNumberValidator                : "VolmaNumberValidator",
    VolmaFileValidator                  : "VolmaFileValidator",
    VolmaCalendarValidator              : "VolmaCalendarValidator",
    VolmaTimeValidator                  : "VolmaTimeValidator",
    VolmaTimeZoneValidator              : "VolmaTimeZoneValidator",
    VolmaTimeTimeDTOValidator           : "VolmaTimeTimeDTOValidator",
    VolmaSelectValidator                : "VolmaSelectValidator",
    UrlFabric                           : "UrlFabric",
    AuthServerInteraction               : "AuthServerInteraction",
    TableServerInteraction              : "TableServerInteraction",
    TableSignalRServerInteraction       : "TableSignalRServerInteraction",
    FileServerInteraction               : "FileServerInteraction",
    SettingsServerInteraction           : "SettingsServerInteraction",
    ReportServerInteraction             : "ReportServerInteraction",
    DeliveryServerInteraction           : "DeliveryServerInteraction",
    TenderServerInteraction             : "TenderServerInteraction",
    PortalConfigurationServerInteraction: "PortalConfigurationServerInteraction",
    RatingServerInteraction             : "RatingServerInteraction",
    CargoTransporterServerInteraction   : "CargoTransporterServerInteraction",
    RequestServerInteraction            : "RequestServerInteraction",
    ComplaintServerInteraction          : "ComplaintServerInteraction",
    AboutServerInteraction              : "AboutServerInteraction",
    ServiceServerInteraction            : "ServiceServerInteraction",
    SearchServerInteraction             : "SearchServerInteraction",
    BiddingServerInteraction            : "BiddingServerInteraction",

    // TABLE RENDERERS
    VolmaTableHeaderRenderers               : "VolmaTableHeaderRenderers",
    VolmaTableCommonColumnDefinition        : "VolmaTableCommonColumnDefinition",

    // SERVICES
    EnumService           : "EnumService",
    LogService            : "LogService",
    AuthenticationService : "AuthenticationService",
    AuthorizationService  : "AuthorizationService",
    LocalStorageService   : "LocalStorageService",
    InMemoryStorageService: "InMemoryStorageService",
    RouterService         : "RouterService",
    FormatService         : "FormatService",
    ChangelogService      : "ChangelogService",
    TimeService           : "TimeService",

    EntityService                 : "EntityService",
    VolmaTableService             : "VolmaTableService",
    SettingsEntityService         : "SettingsEntityService",
    PayloadEntityService          : "PayloadEntityService",
    ZoneEntityService             : "ZoneEntityService",
    LocalityEntityService         : "LocalityEntityService",
    BodyTypeEntityService         : "BodyTypeEntityService",
    HandlingTypeEntityService     : "HandlingTypeEntityService",
    LoadingPointEntityService     : "LoadingPointEntityService",
    VehicleTypeEntityService      : "VehicleTypeEntityService",
    RouteRateEntityService        : "RouteRateEntityService",
    ComplaintTemplateEntityService: "ComplaintTemplateEntityService",
    MultiFactoryEntityService     : "MultiFactoryEntityService",
    MultiCargoTransporterEntityService    : "MultiCargoTransporterEntityService",
    DriverEntityService                   : "DriverEntityService",
    VehicleEntityService                  : "VehicleEntityService",
    ContractEntityService                 : "ContractEntityService",
    ShipperUserEntityService              : "ShipperUserService",
    CargoTransporterUserEntityService     : "CargoTransporterUserService",
    CargoTransporterEntityService         : "CargoTransporterEntityService",
    DeliveryEntityService                 : "DeliveryEntityService",
    DeliveryFinishedEntityService         : "DeliveryFinishedEntityService",
    DeliveryFutureEntityService           : "DeliveryFutureEntityService",
    DeliveryActiveEntityService           : "DeliveryActiveEntityService",
    DeliveryPerformingEntityService       : "DeliveryPerformingEntityService",
    DeliveryWithClaimEntityService        : "DeliveryWithClaimEntityService",
    DeliveryManualEntityService           : "DeliveryManualEntityService",
    DeliveryAuctionEntityService          : "DeliveryAuctionEntityService",
    DeliveryToAcceptConfirmEntityService  : "DeliveryToAcceptConfirmEntityService",
    DeliveryStateEntityService            : "DeliveryStateEntityService",
    DeliveryManualAssignEntityService     : "DeliveryManualAssignEntityService",
    DeliveryUpdateAssignTypeEntityService : "DeliveryUpdateAssignTypeEntityService",
    DeliveryWithdrawEntityService         : "DeliveryWithdrawEntityService",
    DeliveryMarkAsDeliveredEntityService  : "DeliveryMarkAsDeliveredEntityService",
    DeliveryNotExecutedEntityService      : "DeliveryNotExecutedEntityService",
    DeliveryOneCommentEntityService       : "DeliveryOneCommentEntityService",
    DeliveryComplaintEntityService        : "DeliveryComplaintEntityService",
    BiddingBetEntityService               : "BiddingBetEntityService",
    TenderEntityService                   : "TenderEntityService",
    TenderFinishedEntityService           : "TenderFinishedEntityService",
    TenderActiveEntityService             : "TenderActiveEntityService",
    TenderBettingPerformingEntityService  : "TenderBettingPerformingEntityService",
    TenderFutureEntityService             : "TenderFutureEntityService",
    TenderInactiveEntityService           : "TenderInactiveEntityService",
    TenderDraftEntityService              : "TenderDraftEntityService",
    TenderBetEntityService                : "TenderBetEntityService",
    TenderDeliveryEntityService           : "TenderDeliveryEntityService",
    TenderRejectedDeliveryEntityService   : "TenderRejectedDeliveryEntityService",
    TenderChangelogEntityService          : "TenderChangelogEntityService",
    TenderWithdrawEntityService           : "TenderWithdrawEntityService",
    CargoTransporterRouteRateEntityService: "CargoTransporterRouteRateEntityService",
    CalendarEntryEntityService            : "CalendarEntryEntityService",
    RatingEntityService                   : "RatingEntityService",
    ReportEntityService                   : "ReportEntityService",
    RoutePointEntityService               : "RoutePointEntityService",
    DeliveryConfirmEntityService          : "DeliveryConfirmEntityService",
    UserRoleEntityService                 : "UserRoleEntityService",
    SelectDriverEntityService             : "SelectDriverEntityService",
    SelectVehicleEntityService            : "SelectVehicleEntityService",
    SelectContractEntityService           : "SelectContractEntityService",
    ShipperEntityService                  : "ShipperEntityService",
    PortalConfigurationEntityService      : "PortalConfigurationEntityService",
    UpdatePasswordEntityService           : "UpdatePasswordEntityService",
    ConsignorEntityService                : "ConsignorEntityService",
    ConsigneeEntityService                : "ConsigneeEntityService",
    RequestLogLogEntityService            : "RequestLogLogEntityService",
    BusinessCalendarEntityService         : "BusinessCalendarEntityService",
    FileEntityService                     : "FileEntityService",
    ComplaintEntityService                : "ComplaintEntityService",
    ComplaintCommentEntityService         : "ComplaintCommentEntityService",
    ComplaintDraftEntityService           : "ComplaintDraftEntityService",
    ComplaintActiveEntityService          : "ComplaintActiveEntityService",
    ComplaintClosedEntityService          : "ComplaintClosedEntityService",
    ComplaintWithNewMessagesEntityService : "ComplaintWithNewMessagesEntityService",
    AboutEntityService                    : "AboutEntityService",
    DeliveryService                       : "DeliveryService",
    BiddingEntityService                  : "BiddingEntityService",
    DriverMonitoringEntityService         : "DriverMonitoringEntityService",
    DeliveryForComplaintEntityService     : "DeliveryForComplaintEntityService",
    InstructionEntityService              :"InstructionEntityService",

    // ACTIONS
    EntityAction           : "EntityAction",
    AppActions             : "AppActions",
    HeaderActions          : "HeaderActions",
    ApiActions             : "ApiActions",
    LoginActions           : "LoginActions",
    RegisterActions        : "RegisterActions",
    PasswordRestoreActions : "PasswordRestoreActions",
    PasswordResetActions   : "PasswordResetActions",
    VolmaFormActions       : "VolmaFormActions",
    VolmaInputActions      : "VolmaInputActions",
    VolmaNumberActions     : "VolmaNumberActions",
    VolmaSelectActions     : "VolmaSelectActions",
    VolmaFileActions       : "VolmaFileActions",
    VolmaCalendarActions   : "VolmaCalendarActions",
    VolmaTimeActions       : "VolmaTimeActions",
    VolmaTimeTimeDTOActions: "VolmaTimeTimeDTOActions",
    VolmaTableActions      : "VolmaTableActions",
    VolmaModalActions      : "VolmaModalActions",
    SettingsActions        : "SettingsActions",
    FooterActions          : "FooterActions",
    ConsignorActions       : "ConsignorActions",
    ConsigneeActions       : "ConsigneeActions",
    MainInfoItemActions    : "MainInfoItemActions",
    RequestLogActions      : "RequestLogActions",
    AboutActions           : "AboutActions",
    VolmaCheckBoxActions   : "VolmaCheckBoxActions",

    //entity actions
    BaseEntityActions               : "BaseEntityActions",
    BaseTableActions                : "BaseTableActions",
    ZoneEntityActions               : "ZoneEntityActions",
    RouteRateActions                : "RouteRateActions",
    ComplaintTemplateActions        : "ComplaintTemplateActions",
    DriverActions                   : "DriverActions",
    VehicleActions                  : "VehicleActions",
    ContractActions                 : "ContractActions",
    ShipperUserActions              : "ShipperUserActions",
    CargoTransporterActions         : "CargoTransporterActions",
    CargoTransporterUserActions     : "CargoTransporterUserActions",
    CargoTransporterTableActions    : "CargoTransporterTableActions",
    DeliveryActions                 : "DeliveryActions",
    DeliveryTableActions            : "DeliveryTableActions",
    TenderActions                   : "TenderActions",
    TenderTableActions              : "TenderTableActions",
    TenderRatingActions             : "TenderRatingActions",
    ComplaintActions                : "ComplaintActions",
    ComplaintTableActions           : "ComplaintTableActions",
    CargoTransporterRouteRateActions: "CargoTransporterRouteRateActions",
    CargoRouteRateTableActions      : "CargoRouteRateTableActions",
    CalendarEntryActions            : "CalendarEntryActions",
    RatingActions                   : "RatingActions",
    RatingTableActions              : "RatingTableActions",
    ReportActions                   : "ReportActions",
    ReportComponentActions          : "ReportComponentActions",
    LocalityActions                 : "LocalityActions",
    RoutePointActions               : "RoutePointActions",
    ConfirmActions                  : "ConfirmActions",
    DeliveryManualAssignActions     : "DeliveryManualAssignActions",
    SelectDriverActions             : "SelectDriverActions",
    SelectVehicleActions            : "SelectVehicleActions",
    SelectContractActions           : "SelectContractActions",
    PortalConfigurationActions      : "PortalConfigurationActions",
    BusinessCalendarActions         : "BusinessCalendarActions",
    SearchComponentActions          : "SearchComponentActions",
    BiddingActions                  : "BiddingActions",
    DriverMonitoringActions         : "DriverMonitoringActions",
    LoadingPointAction              : "LoadingPointAction",
    VolmaMapWithSelectDTOAction     : "VolmaMapWithSelectDTOAction",
    MultiCargoTransporterReducer    : "MultiCargoTransporterReducer",

    // REDUCERS
    VolmaFormReducer                : "VolmaFormReducer",
    VolmaInputReducer               : "VolmaInputReducer",
    VolmaNumberReducer              : "VolmaNumberReducer",
    VolmaSelectReducer              : "VolmaSelectReducer",
    VolmaFileReducer                : "VolmaFileReducer",
    VolmaCalendarReducer            : "VolmaCalendarReducer",
    VolmaTimeReducer                : "VolmaTimeReducer",
    VolmaTimeTimeDTOReducer         : "VolmaTimeTimeDTOReducer",
    VolmaMapWithSelectDTOReducer    : "VolmaMapWithSelectDTOReducer",

    VolmaTableReducer               : "VolmaTableReducer",
    ZoneEntityReducer               : "ZoneEntityReducer",
    RouteRateReducer                : "RouteRateReducer",
    ComplaintTemplateReducer        : "ComplaintTemplateReducer",
    DriverReducer                   : "DriverReducer",
    ContractReducer                 : "ContractReducer",
    VehicleReducer                  : "VehicleReducer",
    ShipperUserReducer              : "ShipperUserReducer",
    CargoTransporterUserReducer     : "CargoTransporterUserReducer",
    CargoTransporterReducer         : "CargoTransporterReducer",
    DeliveryReducer                 : "DeliveryReducer",
    DeliveryTableReducer            : "DeliveryTableReducer",
    TenderReducer                   : "TenderReducer",
    TenderTableReducer              : "TenderTableReducer",
    TenderRatingReducer             : "TenderRatingReducer",
    ComplaintReducer                : "ComplaintReducer",
    CargoTransporterRouteRateReducer: "CargoTransporterRouteRate",
    CargoRouteRateTableReducer      : "CargoRouteRateTableReducer",
    SettingsReducer                 : "SettingsReducer",
    CalendarEntryReducer            : "CalendarEntryReducer",
    RatingReducer                   : "RatingReducer",
    RatingTableReducer              : "RatingTableReducer",
    ReportReducer                   : "ReportReducer",
    LocalityReducer                 : "LocalityReducer",
    RoutePointReducer               : "RoutePointReducer",
    ConfirmReducer                  : "ConfirmReducer",
    DeliveryManualAssignReducer     : "DeliveryManualAssignReducer",
    SelectDriverReducer             : "SelectDriverReducer",
    SelectVehicleReducer            : "SelectVehicleReducer",
    SelectContractReducer           : "SelectContractReducer",
    PortalConfigurationReducer      : "PortalConfigurationReducer",
    BusinessCalendarReducer         : "BusinessCalendarReducer",
    FileReducer                     : "FileReducer",
    SearchComponentReducer          : "SearchComponentReducer",
    BiddingReducer                  : "BiddingReducer",
    VolmaCheckBoxReducer            : "VolmaCheckBoxReducer",
    DriverMonitoringReducer         : "DriverMonitoringReducer",
    LoadingPointReducer             : "LoadingPointReducer",
    MultiFactoryReducer             : "MultiFactoryReducer",
    MultiCargoTransporterActions    : "MultiCargoTransporterActions",
    InstructionReducer              : "InstructionReducer",

    // REPORTS
    ReportTendersExcelService                       : "ReportTendersExcelService",
    ReportRatesExcelService                         : "ReportRatesExcelService",
    ReportDeliveriesExcelService                    : "ReportDelivetiesExcelService",
    ReportComplaintsExcelService                    : "ReportComplaintsExcelService",
    ReportCargoTransportersRatingsExcelService      : "ReportCargoTransportersRatingsExcelService",
    ReportCargoTransportersRatingSummaryExcelService: "ReportCargoTransportersRatingSummaryExcelService",
    ReportReceivedDeliveriesService                 : "ReportReceivedDeliveriesService",
    ReportDeliveriesAttachedFilesService            : "ReportDeliveriesAttachedFilesService",

    ReportDriverScore: "ReportDriverScore",
    DriverScoreReportActions: "DriverScoreReportActions",
    ReportDriverScoreReducer: "ReportDriverScoreReducer",

    // HELPERS
    ComplaintHelper    : "ComplaintHelper",
    DeliveryStateHelper: "DeliveryStateHelper",

};

