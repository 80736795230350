import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { BemItem } from 'bem-cn';

export type DivMouseEventHandler = React.MouseEventHandler<HTMLDivElement>;

interface IInputControlProps {
    type: string;
    placeholder: string;
    value: string | number;
    onChange: (event) => void;
    onBlur: () => void;
    className: BemItem & string;
}

export interface IVolmaTimeInputProps {
    className: string;
    inputControl: IInputControlProps;
    onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
    onInputKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
    onUpValueStart: DivMouseEventHandler;
    onUpValueEnd: DivMouseEventHandler;
    onDownValueStart: DivMouseEventHandler;
    onDownValueEnd: DivMouseEventHandler;
    onSkipEvent: DivMouseEventHandler;
};
export interface IVolmaTimeProps extends IBaseInputProps<IVolmaTimeProps> {
    Value: Date;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
}

export const VolmaTimePropsInitial: IVolmaTimeProps = <IVolmaTimeProps>{
    ...VolmaBaseInputPropsInitial,
};