export enum EComplaintState {    
    Saved,
    Billed,
    TakenIntoExamination,
    Rejected,
    Processed,
    HarmonizationOfConditions,
    DecisionAccepted,
    Satisfied,
    Closed,
    Cancelled
}