import i18next from "i18next";
import * as React from "react";
import { Component } from "react";
import { ListOptionDTO } from "../../../Domain/DTO/ListOptionDTO";
import { EEntityType } from "../../../Domain/Enum/EEntityType";
import PropertyHelper from "../../../Infrastructure/Services/PropertyHelper";
import VolmaSelect from "../../VolmaSelect/index";
import { IVolmaSelectProps } from "../../VolmaSelect/IVolmaSelectProps";

interface IVolmaMultiPointSelectProps extends IVolmaSelectProps {}

interface IVolmaMultiPointSelectState {}

export class VolmaMultiPointSelect extends Component<IVolmaMultiPointSelectProps, IVolmaMultiPointSelectState> {

    render() {
        const labelKey = PropertyHelper.GetPropertyName((x: ListOptionDTO) => x.Name);

        const multiPointText = this.props.AllowMultiselect 
            ? { label: i18next.t("multipoint:MultiLoadingPoints"), placeholder: i18next.t("multipoint:SelectMultiLoadingPoints")} 
            : { label: i18next.t("multipoint:MultiLoadingPoint"), placeholder: i18next.t("multipoint:SelectMultiLoadingPoint")};

        const icons = [
            <svg className="table-cont__ico"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading-point"></use></svg>,
            <svg className="table-cont__ico"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-factory"></use></svg>,
        ];
        
        return (          
            <VolmaSelect
                {...this.props}
                Label={multiPointText.label}
                Placeholder={multiPointText.placeholder}
                Entity={[EEntityType.LoadingPoint, EEntityType.MultiFactory]}
                LabelKey={labelKey}
                EntityOptionIcon={icons}
            />            
        );
    }
}
