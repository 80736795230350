import { injectable } from 'inversify';
import { MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED, MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED, MULTI_CARGO_TRANSPORTER_RATING_EDITED, MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS } from '../../../../Constants/AppConstants';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { FilterHelper } from '../../../../Infrastructure/Services/FilterHelper';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { EntityAction } from '../../BaseEntity/EntityAction';

@injectable()
export class MultiCargoTransporterActions extends EntityAction {
    public LoadCurrentRatings(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.TopCurrentRating,
            (x: RatingTableDTO) => x.LoadingPointName,
            ESortDir.Asc,
            waitingText,
            this.CurrentRatingsLoaded
        );
    }

    public LoadForecastRatings(id: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.ForecastRating,
            (x: RatingTableDTO) => x.Timestamp,
            ESortDir.Desc,
            waitingText,
            this.ForecastRatingsLoaded
        );
    }

    public LoadCargoTransportersByIds(ids: string[], waitingText: string) {
        const filter = FilterHelper.CreateFilterByIds(
            ids, 
            PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Id)
        );

        return super.LoadDataTable(
            EEntityType.CargoTransporter,
            (x: CargoTransporterTableDTO) => x.Name,
            ESortDir.Asc,
            [filter],
            waitingText,
            this.SetSelectedCargoTransporters);
    }

    public SetSelectedCargoTransporters(value: Array<CargoTransporterTableDTO>): IActionPayloaded<Array<CargoTransporterTableDTO>> {
        return { type: MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS, Payload: value };
    }

    public RatingEdited(dto: RatingTableDTO): IActionPayloaded<RatingTableDTO> {
        return { type: MULTI_CARGO_TRANSPORTER_RATING_EDITED, Payload: dto }
    }

    private CurrentRatingsLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED, Payload: value };
    }

    private ForecastRatingsLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED, Payload: value };
    }
}