import ValidationResult from "../../Infrastructure/Validation/ValidationResult";
import {IVolmaInputProps} from "../VolmaInput/IVolmaInputProps";
import {ICommonProps} from "../Shared/ICommonProps";
import { IVolmaTableProps } from '../Table/VolmaTable/IVolmaTableProps';
import { IVolmaSelectProps } from '../VolmaSelect/IVolmaSelectProps';

export interface ErrorItem {
    Id: number;
    ValidationResult: ValidationResult;
}

export interface IVolmaFormProps extends ICommonProps {
    Ref?: (el:any) => void;
    IsMounted?: boolean;
    IsMuted?: boolean;
    IsTouched?: boolean;
    IsSubmitted?: boolean;
    IsValid?: boolean;
    Inputs?: Array<IVolmaInputProps>;
    Selects?: Array<IVolmaSelectProps>;
    Tables?: Array<IVolmaTableProps<any>>;
    ClassName?: string; 
    NoopObject?: {};
}


export const VolmaFormPropsInitial: IVolmaFormProps = <IVolmaFormProps>{
    Ref: () => null,
    Inputs: [],
    Selects: [],
    Tables: [],
    IsSubmitted: false,
    IsTouched: false,
    IsValid: true,
    IsMuted: false,
    ClassName: undefined
};

