import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { DeliveryRestartAssignationAction } from '../Actions/DeliveryRestartAssignationAction';
import { DeliveryWithdrawAction } from '../Actions/DeliveryWithdrawAction';
import { ManualAssignDeliveriesAction } from '../Actions/ManualAssignDeliveryAction';
import { SynchronizeDeliveryAction } from '../Actions/SynchronizeDeliveryAction';
import { UpdateAssignTypeDeliveriesAction } from '../Actions/UpdateAssignTypeAction';
import { DeliveryHelperProps } from '../IHelperProps';


export class Waiting extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusWaitingCurrent";
    public TitleFinished: string = "delivery:StatusWaitingFinished";
    public TitleFuture  : string = "delivery:StatusWaitingFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.New ||
        dto.State === EDeliveryState.Revoked
    }

    public IsFinished(dto: DeliveryDTO){
        return dto.State !== EDeliveryState.New &&
        dto.State !== EDeliveryState.Revoked
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [new DeliveryWithdrawAction(), new ManualAssignDeliveriesAction(), new UpdateAssignTypeDeliveriesAction(), new SynchronizeDeliveryAction(), new DeliveryRestartAssignationAction()];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;
        return undefined;
    }

}
