import { injectable } from 'inversify';

import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ABSTRACTENTITY, ABSTRACTLIST, USERPROFILE } from './RouterConstants';

@injectable()
export class RouterService{
    public GetPathname(target: string): string{
        switch(target){
            default:
            return "/" + target;
        }
    }

    public GetCertainEntityPathname(entity: EEntityType, id: string): string{
        return "/" + ABSTRACTENTITY + "/" + EEntityType[entity].toLowerCase() + "/" + id;
    }

    public GetProfilePathname(entity: EEntityType): string{
        return "/" + ABSTRACTENTITY + "/" + EEntityType[entity].toLowerCase() + "/" + USERPROFILE;
    }


    public GetEntityPathname(target: string): string{
        switch(target){
            default:
            return "/" + ABSTRACTENTITY + "/" + target;
        }
    }

    public GetTablePathname(entity: EEntityType): string{
        return "/" + ABSTRACTLIST + "/" + EEntityType[entity].toLowerCase();
    }

    public GetHome() : string{
        return "/"
    }
}