import { INPUT_SET_VALIDATOR, INPUT_TOUCHED, INPUT_VALIDATE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { IErrorPayload, ISetValidatorPayload, IValidatePayload } from '../VolmaInput/Payloads';
import { IBaseInputProps } from './IBaseInputProps';
import { injectable } from 'inversify';

@injectable()
export class BaseInputReducer<T extends IBaseInputProps<any>> implements IReducePayloaded<T> {

    public Reduce(state: T, action: IActionPayloaded<any>): T {
        switch (action.type) {
            case INPUT_TOUCHED:
                return this.ReduceTouched(state);
            case INPUT_VALIDATE:
                return this.ReduceValidate(state, action.Payload);
            case INPUT_SET_VALIDATOR:
                return this.ReduceSetValidator(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceTouched(state: T) {
        let newState: T = <any>{ ...<IBaseInputProps<any>>state, IsTouched: true };
        return newState;
    }

    private ReduceValidate(state: T, payload: IValidatePayload) {
        let validationResult = state.Validator.Validate(state);
        if (validationResult.IsValid === state.IsValid && validationResult.ErrorMessage === state.ErrorMessage)
            return state;

        let newState: T = <any>{ ...<IBaseInputProps<any>>state, ErrorMessage: validationResult.ErrorMessage, IsValid: validationResult.IsValid };
        return newState;
    }

    private ReduceSetValidator(state: T, payload: ISetValidatorPayload) {
        let newState: T = <T>{ ...<IBaseInputProps<any>>state, Validator: payload.Validator };
        return newState;
    }
}