import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { IVolmaFormProps } from './IVolmaFormProps';
import { Component } from 'react';
import * as React from 'react';

class VolmaForm extends Component<IVolmaFormProps, {}> {
    private _volmaForm = volmaBlock("volma-form");

    render() {
        // let text = this.props.IsValid ? "form is valid" : "form is not valid";
        // let inputErrors = this.props.IsValid ? "" : JSON.stringify(this.props.Inputs.map((x: IVolmaInputProps) => { return { IsValid: x.IsValid, ErrorMessage: x.ErrorMessage } }));
        // let selectErrors = this.props.IsValid ? "" : JSON.stringify(this.props.Selects.map((x: IVolmaSelectProps) => { return { IsValid: x.IsValid, ErrorMessage: x.ErrorMessage } }));
        return <form className={([this._volmaForm(), this.props.ClassName].join(" ")).toString()} ref={this.props.Ref}>
            {this.props.children}
        </form>;
    }

}

export default VolmaForm;