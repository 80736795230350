import { TimeOffsetDTO } from './../../Domain/DTO/TimeOffsetDTO';
import { injectable } from "inversify";
import {LogService} from "./LogService";
import {Types} from "../Types";
import { VolmaContainer } from '../InversifyInject';
import { ApiActions } from '../../Components/Shared/ApiActions';
import { AuthServerInteraction } from '../ServerInteraction/AuthServerInteraction';
import { ServiceServerInteraction } from '../ServerInteraction/ServiceServerInteraction';
import { AxiosResponse } from 'axios';
import * as moment from 'moment';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';

@injectable()
export class TimeService {
    private _logger: LogService;
    private _diffToServer: number;
    private _serverTimeZoneOffsetInMinutes: number;
    private _lastSyncTime: Date;
    private _requestInProgress: boolean;
    private _serverInteraction: ServiceServerInteraction;

    constructor() {
        this._logger = VolmaContainer.get<LogService>(Types.LogService);
        this._serverInteraction = VolmaContainer.get<ServiceServerInteraction>(Types.ServiceServerInteraction);
        this._diffToServer = 0;
        this._serverTimeZoneOffsetInMinutes = 0;
        this._requestInProgress = false;
        this._lastSyncTime = undefined;
    }

    public static GetTimeWithSpecifiedTimeZone(date: Date, timezone: TimeDTO): moment.Moment{
        let timeZoneString= timezone.IsNegative ? "-" : "+";
        timeZoneString += timezone.Hours < 10 ? "0" + timezone.Hours : timezone.Hours;
        timeZoneString += timezone.Minutes < 10 ? "0" + timezone.Minutes : timezone.Minutes;
        // @ts-ignore: This expression is not callable
        let dateMoment = moment(date).utcOffset(timeZoneString);
        return dateMoment;
    }

    public Initialize(){
        this.UpdateDiffToServer();
    }

    public GetTimeZoneOffsetInMinutes() : number {
        return this._serverTimeZoneOffsetInMinutes;
    }

    public GetLocalTime(serverDate: Date): moment.Moment{
        // @ts-ignore: This expression is not callable
        return moment(serverDate).add(this.GetDiffToServerInSeconds(), 'seconds');
    }

    public IsInFuture(serverDate: Date) : boolean{
        // @ts-ignore: This expression is not callable
        return moment(serverDate).add(this.GetDiffToServerInSeconds(), 'seconds').isAfter(moment());
    }

    public DiffToNow(serverDate: Date): moment.Duration{
        // @ts-ignore: This expression is not callable
        return moment.duration(moment(serverDate).add(this.GetDiffToServerInSeconds(), 'seconds').diff(moment()))
    }

    public GetDiffToServerInSeconds(): number {
        // @ts-ignore: This expression is not callable
        if ((this._lastSyncTime === undefined || moment.duration(moment().diff(moment(this._lastSyncTime))).asMinutes() > 20) && !this._requestInProgress){
            this.UpdateDiffToServer();
        }
        return this._diffToServer;
    }

    public GetCorrectedTime(time: any){
        // @ts-ignore: This expression is not callable
        if ((this._lastSyncTime === undefined || moment.duration(moment().diff(moment(this._lastSyncTime))).asMinutes() > 20) && !this._requestInProgress) {
            this.UpdateDiffToServer();
        }
        // @ts-ignore: This expression is not callable
        return moment(time).add(moment().utcOffset() - this._serverTimeZoneOffsetInMinutes, "minutes").toDate();
    }

    public ConvertToSpecifiedTimezone(date: Date, timezone: TimeDTO): moment.Moment {
        if(!this.GetIsDateValid(date))
            return undefined;
        return timezone === null || timezone === undefined ?
            this.GetLocalTime(date):
            TimeService.GetTimeWithSpecifiedTimeZone(date, timezone);
    }

    public GetIsDateValid(date: Date)
    {
        // @ts-ignore: This expression is not callable
        return date !== undefined && moment(date).isValid();
    }

    private UpdateDiffToServer(){
        this._requestInProgress = true;
        this._serverInteraction.GetDifferenceToServerTime()
            .then((response: AxiosResponse) => {
                const dto: TimeOffsetDTO = response.data;
                this._diffToServer = dto.Offset; //если dto.Offset отрицательное, значит на сервере времени больше
                this._serverTimeZoneOffsetInMinutes = (new Date(dto.ServerTime)).getTimezoneOffset();
                this._requestInProgress = false;
                this._lastSyncTime = new Date();
            })
            .catch((response: any) => {
                this._requestInProgress = false;
            });
    }
}