import * as React from 'react';
import VolmaInput from '../../VolmaInput';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { ShipperDTO } from '../../../Domain/DTO/ShipperDTO';
import { ShipperTableDTO } from '../../../Domain/DTO/ShipperTableDTO';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import i18next from '../../i18n';

@injectable()
export class ShipperEntityService extends BaseEntityService<ShipperDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Shipper]
    }


    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Shipper,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: ShipperTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer}
            ]
        );
    }

    public GetHeaderEditor(props: IBaseEntityProps<ShipperDTO, any>): JSX.Element{
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ShipperDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: ShipperDTO) => val.Name)}
                        Label={i18next.t('shipper:Name')}
                        Placeholder={i18next.t('shipper:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ShipperDTO, any>): JSX.Element {
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<ShipperDTO, any>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<ShipperDTO, any>){
    }

    public GetInitialDataHelper() {
        return {};
    }

    public OnAfterDataLoaded(dto: ShipperDTO): void {
    }

    public GetReducer(){
        return undefined;
    }
}