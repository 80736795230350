import { ERestorePasswordResult } from '../../Domain/Enum/ERestorePasswordResult';
import { RestorePasswordResultDTO } from '../../Domain/DTO/RestorePasswordResultDTO';
import {
    PASSWORD_RESTORE_CLEAR,
    PASSWORD_RESTORE_FAILED,
    PASSWORD_RESTORE_MOUNTED,
    PASSWORD_RESTORE_SET_USERID_TOKEN,
    PASSWORD_RESTORE_SUCCESSFULLY,
    PASSWORD_RESTORE_UNMOUNTED
} from '../../Constants/AppConstants';

import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { Types } from '../../Infrastructure/Types';
import { ApiActions } from '../Shared/ApiActions';
import { ComponentActions } from '../Shared/ComponentActions';
import { injectable } from 'inversify';
import { SetUserIdTokenPayload, PasswordRestoreFailedPayload } from './Payloads';
import { VolmaModalActions } from '../VolmaModal/VolmaModalActions';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import { RestorePasswordDTO } from '../../Domain/DTO/RestorePasswordDTO';
import { AxiosResponse } from 'axios';

@injectable()
class PasswordRestoreActions extends ComponentActions {
    private _api         : AuthServerInteraction;
    private _apiActions  : ApiActions;
    private _modalActions: VolmaModalActions;

    private _routerService: RouterService;

    constructor() {
        super();
        super.Initialize(PASSWORD_RESTORE_MOUNTED, PASSWORD_RESTORE_UNMOUNTED);

        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this._api          = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._apiActions   = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

    }

    public DoPasswordRestore(PasswordRestoreCommand: RestorePasswordDTO, text: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.PasswordRestore(PasswordRestoreCommand),
                text,
                (response: AxiosResponse, dispatch: any) => {
                    let data: RestorePasswordResultDTO = response.data;
                    if (data.Result === ERestorePasswordResult.Success){
                        dispatch(this.PasswordRestoreSuccesful());
                    }
                    else{
                        dispatch(this.PasswordRestoreFailed(data.Result, data.Errors));
                    }
                });
        }
    }

    public Clear(): IAction {
        return { type: PASSWORD_RESTORE_CLEAR };
    }

    public SetUserIdToken(userId: string, token: string): IActionPayloaded<SetUserIdTokenPayload> {
        return { type: PASSWORD_RESTORE_SET_USERID_TOKEN, Payload: { UserId: userId, Token: token } };
    }

    private PasswordRestoreSuccesful(): IAction {
        return { type: PASSWORD_RESTORE_SUCCESSFULLY };
    }

    private PasswordRestoreFailed(result: ERestorePasswordResult, errors: Array<string>): IActionPayloaded<PasswordRestoreFailedPayload> {
        return { type: PASSWORD_RESTORE_FAILED, Payload: { Result: result, Errors: errors } };
    }
}


export { PasswordRestoreActions }