import { WithTranslationProps } from 'react-i18next/*';

import { DataDTO } from '../../Domain/DTO/DataDTO';
import { ResetPasswordDTO } from '../../Domain/DTO/ResetPasswordDTO';
import { BaseEntityPropsInitial, IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { InitialPasswordResetHelperProps, IPasswordResetHelperProps } from './IHelperProps';

export interface IPasswordResetProps extends IBaseEntityProps<ResetPasswordDTO, IPasswordResetHelperProps>, WithTranslationProps {
}

export const PasswordResetPropsInitial: IPasswordResetProps = { ...BaseEntityPropsInitial,
    DataHelper: InitialPasswordResetHelperProps,
    DataDTO: {}
};
