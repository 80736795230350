import * as React from 'react';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { CargoTransporterUserDTO } from '../../../Domain/DTO/CargoTransporterUserDTO';
import { CargoTransporterUserTableDTO } from '../../../Domain/DTO/CargoTransporterUserTableDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn, IVolmaTableAction, IVolmaTableProps, IVolmaTableCellRendererParams, IVolmaTableColumnDefinition } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaSelect from '../../VolmaSelect/index';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { CargoTransporterUserActions } from './CargoTransporterUserActions';
import { CargoTransporterUserReducer } from './CargoTransporterUserReducer';
import { CargoTransporterUserHelperProps, InitialCargoTransporterUserHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import i18next from '../../i18n';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { RegisterCargoTransporterUserDTO } from '../../../Domain/DTO/RegisterCargoTransporterUserDTO';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { DeleteCargoTransporterUsersAction } from './Actions/DeleteCargoTransporterUsersAction';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { BlockCargotransporterUserAccountStatusAction } from './Actions/BlockCargotransporterUserAccountStatusAction';
import { UnblockCargotransporterUserAccountStatusAction } from './Actions/UnblockCargotransporterUserAccountStatusAction';
import { CargoTransporterUserTableCellRenderers } from './CargoTransporterUserTableCellRenderers';
import { ECargoTransporterUserState } from '../../../Domain/Enum/ECargoTransporterUserState';
import { VolmaTableCommonColumnDefinition } from '../../Table/VolmaTableCommonColumnDefinition';
import { CargoTransporterUserColumnTemplates } from './CargoTransporterUserColumnTemplates';

@injectable()
export class CargoTransporterUserEntityService extends BaseEntityService<CargoTransporterUserDTO, CargoTransporterUserHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: CargoTransporterUserReducer;
    private _action: CargoTransporterUserActions;
    private _dispatch: any;
    private _availableRoles: Array<ListIntOptionDTO>;
    private _routerService: RouterService;
    private _cargoTransporterUserColumnTemplates: CargoTransporterUserColumnTemplates;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer = VolmaContainer.get<CargoTransporterUserReducer>(Types.CargoTransporterUserReducer);
        this._action = VolmaContainer.get<CargoTransporterUserActions>(Types.CargoTransporterUserActions);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this._cargoTransporterUserColumnTemplates = new CargoTransporterUserColumnTemplates()

        this._availableRoles = new Array<ListIntOptionDTO>();

        const logistRole = new ListIntOptionDTO();
        logistRole.Id = EUserRole.CargoTransporterLogist;
        logistRole.Name = EUserRole[logistRole.Id];

        this._availableRoles.push(logistRole);

        const logistExtendedRole = new ListIntOptionDTO();
        logistExtendedRole.Id = EUserRole.CargoTransporterLogistExtended;
        logistExtendedRole.Name = EUserRole[logistExtendedRole.Id];

        this._availableRoles.push(logistExtendedRole);
    }

    public GetEntityId(props: IBaseEntityProps<CargoTransporterUserDTO, {}>): string {
        let path: string = props.location.pathname;
        let id: string;

        if (path === this._routerService.GetProfilePathname(EEntityType.CargoTransporterUser))
            id = this._authService.GetCurrentUser().Id;
        else if (props.match.params.id !== undefined && props.match.params.id !== null && props.match.params.id !== "")
            id = props.match.params.id;

        return id;
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<CargoTransporterUserHelperProps>> {
        if (this.GetTableCanAddEntity(entity))
            return [new DeleteCargoTransporterUsersAction<CargoTransporterUserHelperProps>(), new BlockCargotransporterUserAccountStatusAction<CargoTransporterUserHelperProps>(),
                new UnblockCargotransporterUserAccountStatusAction<CargoTransporterUserHelperProps>()];
        return [];
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.CargoTransporterUser]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        const initialTemplates = [
            { ...this._cargoTransporterUserColumnTemplates.Id, },
            { ...this._cargoTransporterUserColumnTemplates.Fio, },
        ];
        const userDataCommonTemplates = [
            { ...this._cargoTransporterUserColumnTemplates.UserName, },
            { ...this._cargoTransporterUserColumnTemplates.Email, },
            { ...this._cargoTransporterUserColumnTemplates.PhoneNumber, },
        ];

        if (this._authService.IsIncludedCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.CargoTransporterUser,
                [ 
                    ...initialTemplates,
                    { ...this._cargoTransporterUserColumnTemplates.CargoTransporterName, },            
                    ...userDataCommonTemplates,
                ]
            );
        }

        const shipperColumns = [
            ...initialTemplates,
            { ...this._cargoTransporterUserColumnTemplates.MultiTransporterName, },            
            ...userDataCommonTemplates,
        ]

        if (this._authService.IsShipper()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.CargoTransporterUser,
                [
                    ...shipperColumns,
                    { ...this._cargoTransporterUserColumnTemplates.IsBlocked, },
                ]
            );
        }

        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CargoTransporterUser,
            [
                ...initialTemplates,           
                ...userDataCommonTemplates,
            ]
        );

    }

    public GetHeaderEditor(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterUserDTO) => val.UserName) }
                        Name={PropertyHelper.GetPropertyName((val: CargoTransporterUserDTO) => val.UserName)}
                        Label={i18next.t('cargotransporteruser:UserName')}
                        Placeholder={i18next.t('cargotransporteruser:UserNamePlaceholder')}
                        Value={props.DataDTO.UserName}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable || (props.DataDTO !== undefined && props.DataDTO.Id !== undefined)}
                        dispatch={props.dispatch} />
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterUserDTO) => val.Fio) }
                        Name={PropertyHelper.GetPropertyName((val: CargoTransporterUserDTO) => val.Fio)}
                        Label={i18next.t('cargotransporteruser:Fio')}
                        Placeholder={i18next.t('cargotransporteruser:FioPlaceholder')}
                        Value={props.DataDTO.Fio}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element {
        if((props.DataDTO === undefined || props.DataDTO.Id === undefined) && this.IsEditable(props))
            return this.NewUserEditor(props);
        return this.ExistingUserEditor(props)
    }

    public CustomEntitySaveAction(entity: EEntityType, dto: IEntityDTO, isFormValid: boolean, waitingText: string, id?: string){
        return this._action.SaveEntity(entity, dto, isFormValid, waitingText, id);
    }

    public GetEditor(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>) {
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialCargoTransporterUserHelperProps;
    }

    public OnAfterDataLoaded(dto: CargoTransporterUserDTO): void {
    }

    

    private NewUserEditor(props: IBaseEntityProps<RegisterCargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporteruser:MainInfoTitle"), i18next.t("cargotransporteruser:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.PasswordPart(props))}
                        {this.CanViewCargoTransporterPart && this.GetMainInfoNoTitleItem(true, false, this.CargoTransporterPart(props))}
                    </div>
                </div>
            </div>
        );
    }

    private ExistingUserEditor(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporteruser:MainInfoTitle"), i18next.t("cargotransporteruser:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.CanViewCargoTransporterPart && this.GetMainInfoNoTitleItem(true, false, this.CargoTransporterPart(props))}
                    </div>
                </div>
            </div>
        );
    }

    protected OnChangeRoleRequired(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>) {
        if (props.DataDTO !== undefined)
            this._dispatch(this._action.RoleChangeRequired(props.DataDTO.Id, props.DataDTO.Role, this._availableRoles))
    }


    private PasswordPart(props: IBaseEntityProps<RegisterCargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RegisterCargoTransporterUserDTO) => val.Password) as any) }
                            Name={PropertyHelper.GetPropertyName((val: RegisterCargoTransporterUserDTO) => val.Password)}
                            Type={"password"}
                            Label={i18next.t('cargotransporteruser:Password')}
                            Required={true}
                            Value={props.DataDTO.Password}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private CargoTransporterPart(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);

        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransporterUserDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: CargoTransporterUserDTO) => x.CargoTransporterId)}
                            Label={i18next.t("cargotransporteruser:CargoTransporterId")}
                            Placeholder={i18next.t("cargotransporteruser:SelectCargoTransporter")}
                            Readonly={!isEditable}
                            Required={true}
                            Entity={EEntityType.CargoTransporter}
                            Value={props.DataDTO.CargoTransporterId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private get CanViewCargoTransporterPart(): boolean {
        return this._authService.IsAdministrator() || this._authService.IsCargoTransporter();
    }

    private MainInfoPart(props: IBaseEntityProps<CargoTransporterUserDTO, CargoTransporterUserHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);
        const showCargoTransporterUserState = this._authService.IsAdministrator() && props.DataDTO.IsBlocked !== undefined;
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterUserDTO) => val.Email) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterUserDTO) => val.Email)}
                            Label={i18next.t('cargotransporteruser:Email')}
                            Required={true}
                            Value={props.DataDTO.Email}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterUserDTO) => val.PhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterUserDTO) => val.PhoneNumber)}
                            Label={i18next.t('cargotransporteruser:PhoneNumber')}
                            Required={true}
                            Value={props.DataDTO.PhoneNumber}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                {showCargoTransporterUserState && <div className={(this._mainInfo("cell")).toString()} >
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                         <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: CargoTransporterUserDTO) => val.IsBlocked) as any) }
                            Name={PropertyHelper.GetPropertyName((x: CargoTransporterUserDTO) => x.IsBlocked)}
                            Label={i18next.t('cargotransporteruser:IsBlocked')}
                            Placeholder={i18next.t('cargotransporteruser:SelectIsBlocked')}
                            Readonly={true}
                            EnumGetter={() => ECargoTransporterUserState}
                            OptionsFilter={(x: ListIntOptionDTO) => x.Id === ECargoTransporterUserState.Blocked || x.Id === ECargoTransporterUserState.Unblocked}
                            Required={true}
                            Value={props.DataDTO.IsBlocked}
                            dispatch={props.dispatch} />
                    </div>
                </div>}
            </div>
        );
    }
}
