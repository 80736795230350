import { LocalStorageService } from '../../../Infrastructure/Services/LocalStorageService';
import { VolmaComponentReducer } from '../../Shared/VolmaComponentReducer';
import { SETTINGS_MOUNTED, SETTINGS_UNMOUNTED, VOLMA_ENTITY_INFO_LINE_TOGGLED, VOLMA_ENTITY_FOOTER_NAV_ADDED, VOLMA_ENTITY_FOOTER_CLOSED, VOLMA_ENTITY_CLEAR_FOOTER, VOLMA_ENTITY_SET_ENTITY, VOLMA_INFO_LINE_STATES, VOLMA_ENTITY_DTO_STRINGIFY_UPDATED } from '../../../Constants/AppConstants';
import { ISettingsProps } from '../Settings/ISettingsProps';
import { IBaseEntityProps, BaseEntityPropsInitial } from '../BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { FooterNavLink } from '../../Footer/IFooterProps';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';


export class BaseCustomLoadSaveReducer extends VolmaComponentReducer<IBaseEntityProps<{}, any>> {
    protected _localStorageService: LocalStorageService;

    constructor(mounted: string, unmounted: string) {
        super(mounted, unmounted);

        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public Reduce(state: IBaseEntityProps<{}, any> = BaseEntityPropsInitial, action: IActionPayloaded<any>): IBaseEntityProps<{}, any> {
        state = super.Reduce(state, action);        
        if (!state.IsMounted)
            return state;
        switch (action.type) {
            case VOLMA_ENTITY_INFO_LINE_TOGGLED:
                return this.ReduceInfoLineToggled(state, action.Payload);
            case VOLMA_ENTITY_FOOTER_NAV_ADDED:
                return this.ReduceFooterNavAdded(state, action.Payload); 
            case VOLMA_ENTITY_FOOTER_CLOSED:
                return this.ReduceFooterClosed(state);
            case VOLMA_ENTITY_CLEAR_FOOTER:
                return this.ReduceClearFooter(state);
            case VOLMA_ENTITY_SET_ENTITY: 
                return this.ReduceSetEntity(state, action.Payload);
            case VOLMA_ENTITY_DTO_STRINGIFY_UPDATED:
                return this.ReduceDTOStringifyUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceInfoLineToggled(state: IBaseEntityProps<IEntityDTO, any>, payload: number): IBaseEntityProps<IEntityDTO, any> {
        let newState = { ...state, ClosedCardParts: state.ClosedCardParts.slice()};
        newState.ClosedCardParts[payload] = state.ClosedCardParts[payload] === true ? false : true;

        let currentInfoLinesStates = {};
        let savedCurrentInfoLinesStates = this._localStorageService.GetKey(VOLMA_INFO_LINE_STATES);
        if (savedCurrentInfoLinesStates !== undefined)
            currentInfoLinesStates = JSON.parse(savedCurrentInfoLinesStates);
        currentInfoLinesStates[state.EntityType] = newState.ClosedCardParts;
        this._localStorageService.SetKey(VOLMA_INFO_LINE_STATES, JSON.stringify(currentInfoLinesStates));
        
        return newState;
    }

    private ReduceFooterNavAdded(state: IBaseEntityProps<IEntityDTO, any>, payload: FooterNavLink): IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state, FooterNavList: state.FooterNavList.slice()};

        newState.FooterNavList[payload.Index] = payload;
        newState.FooterOpened = true;

        return newState;
    }

    private ReduceFooterClosed(state: IBaseEntityProps<IEntityDTO, any>) : IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state };

        newState.FooterOpened = false;
        
        return newState;
    }

    private ReduceClearFooter(state: IBaseEntityProps<IEntityDTO, any>): IBaseEntityProps<IEntityDTO, any>{
        let newState = { ...state};
        newState.FooterNavList = [];

        return newState;
    }

    private ReduceSetEntity(state: IBaseEntityProps<IEntityDTO, any>, payload: EEntityType): IBaseEntityProps<IEntityDTO, any> {
        return { ...state, EntityType: payload };
    }

    private ReduceDTOStringifyUpdated(state: IBaseEntityProps<IEntityDTO, any>, payload: IEntityDTO): IBaseEntityProps<IEntityDTO, any> {        
        return { ...state, DataDTOStringify: JSON.stringify(payload)};
    }

    protected GetInfoPartStatesFromLocalStorage(entityType: EEntityType){
        let currentInfoLinesStates = {};
        let savedCurrentInfoLinesStates = this._localStorageService.GetKey(VOLMA_INFO_LINE_STATES);
        if (savedCurrentInfoLinesStates !== undefined)
            currentInfoLinesStates = JSON.parse(savedCurrentInfoLinesStates);
        let savedClosedCardParts = [];
        if (currentInfoLinesStates[entityType] !== undefined)
            savedClosedCardParts = currentInfoLinesStates[entityType];

        return savedClosedCardParts;
    }
}