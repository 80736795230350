import { DeliveriesReportParametersDTO } from '../../../../Domain/DTO/DeliveriesReportParametersDTO';
import { ReportDTO } from '../ReportDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar/VolmaCalendar';
import VolmaSelect from '../../../VolmaSelect';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IReportHelperProps } from '../IHelperProps';
import { ReportService } from './ReportService';
import { injectable } from 'inversify';
import * as React from 'react';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import { Bar } from 'react-chartjs-2';
import { IChartDataset } from '../IChart';
import { CHART_BORDER_WIDTH } from '../../../../Constants/AppConstants';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { SafeMergeArrays } from '../../../../Infrastructure/Services/Utils';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { VolmaMultiTransporterSelect } from '../../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect';

@injectable()
export class ReportDeliveriesExcelService extends ReportService<DeliveriesReportParametersDTO>{
    public Title: string = "ReportDeliveriesExcelService";

    private _options: {
        scales: {
            xAxes: [{
                stacked: true,
                gridLines: { display: false },
            }],
            yAxes: [{
                stacked: true,
            }],
        },
        legend: { display: true }
    }

    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<DeliveriesReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesReportParametersDTO) => val.UnloadingZoneId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.UnloadingZoneId)}
                                Label={i18next.t('report:Zones')}
                                Placeholder={i18next.t('report:SelectZones')}
                                Entity={EEntityType.Zone}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.UnloadingZoneId}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesReportParametersDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.LoadingPointId, props.DataHelper.ReportParams.MultiFactoryId])}
                                AllowMultiselect={true}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    {this._authService.IsShipper() && <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiTransporterSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesReportParametersDTO) => val.CargoTransporterId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.CargoTransporterId)}
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.CargoTransporterId), 
                                    PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.MultiCargoTransporterId),
                                ]}
                                OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.CargoTransporterId, props.DataHelper.ReportParams.MultiCargoTransporterId])}
                                AllowMultiselect
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} /> 
                        </div>
                    </div>}
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesReportParametersDTO) => val.Assigner) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesReportParametersDTO) => x.Assigner)}
                                Label={i18next.t('report:AssignType')}
                                Placeholder={i18next.t('report:SelectAssignType')}
                                EnumGetter={() => ERequestForDeliveryAssigner}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.Assigner}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveriesReportParametersDTO) => val.DateFrom) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DeliveriesReportParametersDTO) => val.DateFrom)}
                                Label={i18next.t('report:LabelDateFrom')}
                                Value={props.DataHelper.ReportParams.DateFrom}
                                DefaultHours={0}
                                DefaultMinutes={0}
                                DefaultSeconds={0}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveriesReportParametersDTO) => val.DateTo) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DeliveriesReportParametersDTO) => val.DateTo)}
                                Label={i18next.t('report:LabelDateTo')}
                                Value={props.DataHelper.ReportParams.DateTo}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public HasChart(): boolean {
        return true;
    }

    public GetChart(data: any) {
        return <Bar data={data} options={this._options} />
    }

    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset, index: number) {
        dataset.backgroundColor = [];
        dataset.borderColor = [];
        dataset.borderWidth = CHART_BORDER_WIDTH;
        if(index === 0 || index === 1)
            dataset.stack = index + 1;
        else
            dataset.stack = 3;
        dataset.backgroundColor = backgroundColor;
        dataset.borderColor = borderColor;
    }

}