import { injectable } from "inversify";
import { DeliveryTableDTO } from "../../../../Domain/DTO/DeliveryTableDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IReducePayloaded } from "../../../../Infrastructure/Reducer/IReducer";
import { IVolmaTableColumn, IVolmaTableProps } from "../../../Table/VolmaTable/IVolmaTableProps";
import { DeliveryEntityService } from "../DeliveryEntityService";

@injectable()
export class DeliveryFutureEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
            { ...this._deliveryColumnTemplates.Id },
            { ...this._deliveryColumnTemplates.Identifier },
            { ...this._deliveryColumnTemplates.LoadingDate },
            { ...this._deliveryColumnTemplates.MultiPointName },
            { ...this._deliveryColumnTemplates.UnloadingDate },
            { ...this._deliveryColumnTemplates.UnloadingPointName },
            { ...this._deliveryColumnTemplates.AssignType },
            { ...this._deliveryColumnTemplates.SalesDepartment },
            { ...this._deliveryColumnTemplates.NextStateDate },
        ]);
    }

    public GetTableReducer(): IReducePayloaded<IVolmaTableProps<any>> {
        return this._tableReducer;
    }

    public GetTableRowClass(rowData: DeliveryTableDTO): string {
        return this._table("row", {
            body: true,
            "bet-contains": rowData.ContainsBet && this._authService.IsShipper(),
        }).toString();
    }
}
