import { WithTranslationProps } from 'react-i18next/*';

import { UserDTO } from '../../Domain/DTO/UserDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IRouteProps } from '../../Infrastructure/Router/RouteProps';
import { ICommonProps } from '../Shared/ICommonProps';

export interface IHeaderProps extends ICommonProps, WithTranslationProps, IRouteProps {
    IsUserMenuOpened?: boolean;
    OpenedNav?: number;
    CurrentEntity?: EEntityType;
    CurrentUser?: UserDTO;
    SearchString?: string;
}

export const HeaderPropsInitial: IHeaderProps = <IHeaderProps>{
    IsUserMenuOpened: false,
    CurrentUser: {},
    SearchString: "",
}
