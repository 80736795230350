import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../../VolmaInput/index';
import VolmaNumber from '../../../VolmaNumber';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { DeliveryWithdrawExtendedDTO } from './DeliveryWithdrawExtendedDTO';
import { injectable } from 'inversify';
import * as React from 'react';
import i18next from '../../../i18n';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import VolmaCheckBox from '../../../VolmaCheckBox/VolmaCheckBox';
import { EmptyValidator } from '../../../../Infrastructure/Validation/EmptyValidator';


@injectable()
export class DeliveryWithdrawEntityService extends BaseEntityService<DeliveryWithdrawExtendedDTO, {}>{
    private _volmaTableService: VolmaTableService;
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _emptyValidator: EmptyValidator;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._emptyValidator = VolmaContainer.get<EmptyValidator>(Types.EmptyValidator);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element {
        if(props.DataDTO.DeliveryState === EDeliveryState.New ||
            props.DataDTO.DeliveryState === EDeliveryState.Revoked)
            return this.GetOneCommentEditor(props, props.DataDTO.ShowCargotransporterBlameCheckBox);
        else if (props.DataDTO.DeliveryState !== undefined)
            return this.GetTwoCommentsEditor(props, props.DataDTO.ShowCargotransporterBlameCheckBox);
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: DeliveryWithdrawExtendedDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<DeliveryWithdrawExtendedDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private GetOneCommentEditor(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>, ShowCargotransporterBlameCheckBox: boolean): JSX.Element{
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {ShowCargotransporterBlameCheckBox && this.GetMainInfoNoTitleItem(false, true, this.IsShipperInitiativePart(props))}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.OneCommentPart(props))}
                    </div>
                </div>
            </div>
        );
    }

    private GetTwoCommentsEditor(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>, ShowCargotransporterBlameCheckBox: boolean): JSX.Element{
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {ShowCargotransporterBlameCheckBox && this.GetMainInfoNoTitleItem(false, true, this.IsShipperInitiativePart(props))}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.CommentsForCargotransporterPart(props))}
                    </div>
                    {this.GetMainInfoNoTitleItem(false, true, this.CommentsForShipperPart(props))}

                </div>
            </div>
        );
    }

    private CommentsForCargotransporterPart(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryWithdrawExtendedDTO) => val.CommentForCargotransporter) }
                            Name={PropertyHelper.GetPropertyName((val: DeliveryWithdrawExtendedDTO) => val.CommentForCargotransporter)}
                            Type="textarea"
                            Label={i18next.t('deliverywithdraw:CommentForCargotransporter')}
                            Value={props.DataDTO.CommentForCargotransporter}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private IsShipperInitiativePart(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCheckBox
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryWithdrawExtendedDTO) => val.IsCargotransporterBlame) }
                        Name={PropertyHelper.GetPropertyName((val: DeliveryWithdrawExtendedDTO) => val.IsCargotransporterBlame)}
                        Label={i18next.t('deliverywithdraw:WithdrawOwner')}
                        Checked={props.DataDTO.IsCargotransporterBlame}
                        Required={true}
                        Validator={this._emptyValidator}
                        dispatch={props.dispatch}
                        Id = "volma-checkbox-delivery-withdraw"/>
                    </div>
                </div>
            </div>
        );
    }

    private CommentsForShipperPart(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryWithdrawExtendedDTO) => val.CommentForShipper) }
                            Name={PropertyHelper.GetPropertyName((val: DeliveryWithdrawExtendedDTO) => val.CommentForShipper)}
                            Type="textarea"
                            Label={i18next.t('deliverywithdraw:CommentForShipper')}
                            Value={props.DataDTO.CommentForShipper}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private OneCommentPart(props: IBaseEntityProps<DeliveryWithdrawExtendedDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryWithdrawExtendedDTO) => val.Comment) }
                            Name={PropertyHelper.GetPropertyName((val: DeliveryWithdrawExtendedDTO) => val.Comment)}
                            Type="textarea"
                            Label={i18next.t('deliverywithdraw:Comment')}
                            Value={props.DataDTO.Comment}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}