import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaNumberProps } from '../VolmaNumber/IVolmaNumberProps';
import { IBooleanValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';
import { IVolmaCheckBoxProps, VolmaCheckBoxPropsInitial } from './IVolmaCheckBoxProps';

@injectable()
export class VolmaCheckBoxReducer extends BaseInputReducer<IVolmaCheckBoxProps> {

    public Reduce(state: IVolmaCheckBoxProps = VolmaCheckBoxPropsInitial, action: IActionPayloaded<any>): IVolmaCheckBoxProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaCheckBoxProps, payload: IBooleanValueChangedPayload) {
        let newState: IVolmaCheckBoxProps = {...state, Checked: payload.Value};
        return newState;
    }
}