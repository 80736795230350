import { TenderCancelDTO } from '../../../../Domain/DTO/TenderCancelDTO';
import i18next from '../../../i18n';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../../VolmaInput/index';
import VolmaNumber from '../../../VolmaNumber';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { injectable } from 'inversify';
import * as React from 'react';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';



@injectable()
export class TenderWithdrawEntityService extends BaseEntityService<TenderCancelDTO, {}>{

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element {
        return this.GetTwoCommentsEditor(props);
    }

    public GetEditor(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<TenderCancelDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: TenderCancelDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<TenderCancelDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private GetTwoCommentsEditor(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.CommentsForCargotransporterPart(props))}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.CommentsForShipperPart(props))}
                    </div>
                </div>
            </div>
        );
    }

    private CommentsForCargotransporterPart(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderCancelDTO) => val.CommentForCargoTransporter) }
                            Name={PropertyHelper.GetPropertyName((val: TenderCancelDTO) => val.CommentForCargoTransporter)}
                            Type="textarea"
                            Required={true}
                            Label={i18next.t('tenderwithdraw:CommentForCargotransporter')}
                            Value={props.DataDTO.CommentForCargoTransporter}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
    private CommentsForShipperPart(props: IBaseEntityProps<TenderCancelDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderCancelDTO) => val.CommentForShipper) }
                            Name={PropertyHelper.GetPropertyName((val: TenderCancelDTO) => val.CommentForShipper)}
                            Type="textarea"
                            Required={true}
                            Label={i18next.t('tenderwithdraw:CommentForShipper')}
                            Value={props.DataDTO.CommentForShipper}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}