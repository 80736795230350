import { injectable } from "inversify";
import { DeliveryDTO } from "../../../../Domain/DTO/DeliveryDTO";
import { DeliveryTableDTO } from "../../../../Domain/DTO/DeliveryTableDTO";
import { EntityUpdatedDTO } from "../../../../Domain/DTO/EntityUpdatedDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IReducePayloaded } from "../../../../Infrastructure/Reducer/IReducer";
import { IBaseTableProps } from "../../../Table/BaseTable/IBaseTableProps";
import { IVolmaTableColumn, IVolmaTableProps } from "../../../Table/VolmaTable/IVolmaTableProps";
import { DeliveryEntityService } from "../DeliveryEntityService";
import { IDeliveryTableHelperProps } from "../Table/ITableHelperProps";

@injectable()
export class DeliveryAuctionEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if (this._authService.IsCargoTransporter() || this._authService.IsAdministrator()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
                ...this.getShipperColumns(),
                { ...this._deliveryColumnTemplates.Bet },
            ]);
        }

        return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, this.getShipperColumns());
    }

    public InitializeTable(props: IBaseTableProps<IDeliveryTableHelperProps>): void {
        this._signalRService.BiddingBetSetted.On(this.BiddingBetAdded);
    }

    public DisposeTable(): void {
        this._signalRService.BiddingBetSetted.Off(this.BiddingBetAdded);
    }

    public OnBeforeTableDataLoaded(props: IBaseTableProps<IDeliveryTableHelperProps>): void {
        this._dispatch = props.dispatch;
        props.dispatch(this._tableAction.LoadUserBets());
    }

    public GetTableReducer(): IReducePayloaded<IVolmaTableProps<any>> {
        return this._tableReducer;
    }

    public GetTableRowClass(rowData: DeliveryTableDTO): string {
        return this._table("row", {
            body: true,
            finished: !this._timeService.IsInFuture(rowData.AuctionDeadline),
            "bet-contains": rowData.ContainsBet && this._authService.IsShipper(),
        }).toString();
    }

    private getShipperColumns() {
        return [
            { ...this._deliveryColumnTemplates.Id },
            { ...this._deliveryColumnTemplates.Identifier },
            { ...this._deliveryColumnTemplates.LoadingDate },
            { ...this._deliveryColumnTemplates.UnloadingDate },
            { ...this._deliveryColumnTemplates.MultiPointName, Weight: 0.7 },
            { ...this._deliveryColumnTemplates.UnloadingPointName },
            { ...this._deliveryColumnTemplates.SalesDepartment },
            { ...this._deliveryColumnTemplates.NextStateDate },
        ];
    }

    public GetOnEntityUpdated(): (
        dto: EntityUpdatedDTO,
        debounce: () => void,
        dispatchFilteredData: () => void
    ) => void {
        return this.OnEntityUpdated;
    }

    protected OnEntityUpdated(dto: EntityUpdatedDTO, debounce: () => void, dispatchFilteredData: () => void) {
        super.OnEntityUpdated(dto, debounce, dispatchFilteredData);
        const currentDeliveryTableEntities = this._deliveryService.GetEntityByDelivery(
            (dto.DTO as DeliveryDTO).State,
            (dto.DTO as DeliveryDTO).AssignType
        );
        if (currentDeliveryTableEntities.findIndex((x) => x === this._entity) <= -1 && dto.Id !== undefined) {
            this._dispatch(this._tableAction.FlushBiddingBetsByDelivery(dto.Id));
        }
    }
}
