import { IVolmaFormProps, VolmaFormPropsInitial } from '../../VolmaForm/IVolmaFormProps';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IEntityService } from './IEntityService';
import { FooterNavLink } from '../../Footer/IFooterProps';
import { IRequestBeginPayload } from '../../App/Payloads';
import { WithTranslationProps } from 'react-i18next/*';
import { match, RouteComponentProps } from 'react-router';
import { History, Location } from 'history';
import { IRouteProps } from '../../../Infrastructure/Router/RouteProps';

export interface IBaseEntityProps<T extends {}, THelper> extends IVolmaFormProps, WithTranslationProps, IRouteProps {
    EntityService?: IEntityService<T, THelper>;
    EntityType?: EEntityType;
    DataDTO?: T;
    DataDTOStringify?: string;
    DataHelper?: THelper
    OpenedStatusIndex?: number;
    ClosedCardParts?: Array<boolean>;
    FooterNavList?: Array<FooterNavLink>;
    FooterOpened?: boolean;

    IsSending?: boolean;
    RequestsInProgress?: Array<IRequestBeginPayload>;
}

export const BaseEntityPropsInitial: IBaseEntityProps<any, any> = {
    ...VolmaFormPropsInitial,
    EntityType: undefined,
    EntityService: undefined,
    DataDTO: undefined,
    DataDTOStringify: JSON.stringify({}),
    DataHelper: {},
    ClosedCardParts: [],
    FooterNavList: [],
    FooterOpened: false,
    IsSending: false,
    RequestsInProgress: [],

    history: undefined,
    location: undefined,
    match: undefined,
    staticContext: undefined
};