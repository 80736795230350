import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { ITableDTO } from '../../Domain/ITableDTO';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';

export interface OptionCreatorParams{
    label: string,
    labelKey: string,
    valueKey: string,
}

export interface SelectAsyncResult { options: Array<VolmaSelectExtendedTableDTO>, complete: boolean }

export interface VolmaSelectExtendedTableDTO extends ITableDTO {
    Icon: JSX.Element;
    EntityIdx: number;
}

export interface IVolmaSelectProps extends IBaseInputProps<IVolmaSelectProps> {
    Value: any;
    Options?: Array<any>;
    AllowMultiselect?: boolean;
    LabelKey?: string;
    LabelKeyGetter? : (x: ITableDTO) => string;
    ValueKey?: string;
    MatchProp?: string;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, helperFieldName: string, fieldValue: any, helperValue: any) => void;
    DTOFieldName?: string;
    HelperFieldName?: string;
    IsInHeader?: boolean;
    HelperFieldToDTOFieldTranslator?: <THelperField, TDTOField>(helperField: THelperField) => TDTOField
    OnValueChanged?: (value: Array<ITableDTO> | ITableDTO) => void;
    AllowItemCreation?: boolean;
    OptionCreator?: (params: OptionCreatorParams) => any;
    OptionCreatorPromtText?: string;
    Entity?: EEntityType | EEntityType[];
    OptionsFilter?: ((x: any) => boolean) | ((x: any) => boolean)[]
    EntityDtoField?: string | string[];
    EntityOptionIcon?: JSX.Element | JSX.Element[];
    EntityMultiValue?: Array<any | any[]>;
    EnumGetter?: () => any;
    ServerRequestFilter?: (input: string) => Array<FilterDTO>;
    ServerRequestLimit?: number;
    InputClassName?: string;
    Cache?: object;
}

export const VolmaSelectPropsInitial: IVolmaSelectProps = <IVolmaSelectProps>{
    ...VolmaBaseInputPropsInitial,
    Value: undefined,
    Options: [],
    Entity: undefined,
    EntityDtoField: [],
    EntityOptionIcon: [],
    EntityMultiValue: [],
    AllowMultiselect: false,
    LabelKey: undefined,
    ValueKey: undefined,
    MatchProp: undefined,
    CustomDataUpdate: undefined,
    DTOFieldName: undefined,
    IsInHeader: false,
    HelperFieldName: undefined,
    HelperFieldToDTOFieldTranslator: x => null,
    AllowItemCreation: false,
    OptionCreatorPromtText: "common:CreateOption",
    AsyncLoad: false
};