import { DRIVER_COORDINATES_LOADED, DRIVER_SCORE_LOADED, DRIVER_VEHICLES_LOADED, DRIVER_VEHICLE_ADDED_TO_DRIVER, DRIVER_VEHICLE_REMOVED_FROM_DRIVER } from '../../../../Constants/AppConstants';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { injectable } from 'inversify';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { DriverLocationTableDTO } from '../../../../Domain/DTO/DriverLocationTableDTO';
import { EFilterType } from '../../../../Domain/Enum/EFilterType';
import { FilterDTO } from '../../../../Domain/DTO/FilterDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { DriverScoreTableDTO } from '../../../../Domain/DTO/DriverScoreTableDTO';
import { AxiosResponse } from 'axios';
import classnames from "classnames"
import { TableDTO } from '../../../../Domain/DTO/TableDTO';

@injectable()
export class DriverActions extends EntityAction{


    public LoadVehicles(id: string, waitingText: string){
        return super.LoadChildDataTableItems(
            EEntityType.Driver,
            id,
            EEntityType.Vehicle,
            (x: VehicleTableDTO) => x.Name,
            ESortDir.Asc,
            waitingText,
            this.VehiclesLoaded);
    }


    public LoadCoordinates(id: string, waitingText: string){
        return (dispatch: any) => {
            const page = 0; // по ТЗ нам нужно 10 элементов только
            const pageSize = 10;

            const filters = [];

            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetChildTableData(
                    EEntityType.Driver, 
                    id, 
                    EEntityType.DriverLocation, 
                    PropertyHelper.GetPropertyName((x: DriverLocationTableDTO) => x.Timestamp),
                    ESortDir.Desc, 
                    filters, 
                    pageSize, 
                    page),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    const data: TableDTO = JSON.parse(response.data);
                    dispatch(this.CoordinatesLoaded(data.Items as any))
                },
            );
        }

    }

    public LoadDriverScore(id: string | undefined, waitingText: string){
        return (dispatch: any) => {
            const driverFilter = new FilterDTO();
            driverFilter.Type = EFilterType.Text;
            driverFilter.TextValue = id;
            driverFilter.Key = 'UserId';

            const scoreGainedFilter = new FilterDTO();
            scoreGainedFilter.Type = EFilterType.Text;
            scoreGainedFilter.TextValue = "true";
            scoreGainedFilter.Key = 'ScoreGained';

            if (id === undefined) {
                // * VD-211 Если id не найден, то у нас пользователь не зарегистрирован в мобильном приложении, и ему не нужно выводить таблицу
                // Если не выходить тут, то undefined фильтр не будет учитываться, и будут запрошены все бонусы от всех юзеров    
                return
            }

            const filters = [driverFilter, scoreGainedFilter];

            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.DriverScore, PropertyHelper.GetPropertyName((x: DriverScoreTableDTO) => x.Timestamp),
                    ESortDir.Desc, filters, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    const data: TableDTO = JSON.parse(response.data);
                    dispatch(this.DriverScoreLoaded(data.Items as any))
                },
            );
        }
    }

    public VehicleAddedToDriver(value: VehicleTableDTO): IActionPayloaded<VehicleTableDTO>{
        return { type: DRIVER_VEHICLE_ADDED_TO_DRIVER, Payload: value};
    }

    public VehicleRemovedFromDriver(value: string[]): IActionPayloaded<Array<string>>{
        return { type: DRIVER_VEHICLE_REMOVED_FROM_DRIVER, Payload: value};
    }

    private VehiclesLoaded(value: Array<VehicleTableDTO>): IActionPayloaded<Array<VehicleTableDTO>> {
        return { type: DRIVER_VEHICLES_LOADED, Payload: value };
    }

    private CoordinatesLoaded(value: Array<DriverLocationTableDTO>): IActionPayloaded<Array<DriverLocationTableDTO>> {
        return { type: DRIVER_COORDINATES_LOADED, Payload: value };
    }

    private DriverScoreLoaded(value: Array<DriverLocationTableDTO>): IActionPayloaded<Array<DriverLocationTableDTO>> {
        return { type: DRIVER_SCORE_LOADED, Payload: value };
    }
}