import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import * as React from 'react';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { ConfigurationDTO } from '../../../Domain/DTO/ConfigurationDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableCellRendererParams } from '../../Table/VolmaTable/IVolmaTableProps';
import { IPortalConfigurationProps } from './IPortalConfigurationProps';
import { PortalConfigurationReducer } from './PortalConfigurationReducer';
import { PortalConfigurationActions } from './PortalConfigurationActions';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import VolmaInput from '../../VolmaInput/index';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../i18n';
import { injectable } from 'inversify';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';

@injectable()
export class PortalConfigurationEntityService extends BaseEntityService<ConfigurationDTO, any>{
    private _actions: PortalConfigurationActions;

    private _inputValidator: VolmaInputValidator;
    private _volmaTableService: VolmaTableService;

    private _dispatch: any;

    constructor(){
        super();
        this._actions = VolmaContainer.get<PortalConfigurationActions>(Types.PortalConfigurationActions);
        this._actions = VolmaContainer.get<PortalConfigurationActions>(Types.PortalConfigurationActions);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);

        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
    }

    public InitializeEntity(props: IPortalConfigurationProps){
        this._dispatch = props.dispatch;
        this._dispatch(this._actions.SetEntityType(EEntityType.Configuration));
        this._dispatch(this._actions.ClearFooter());
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return [];
    }

    public GetReducer(){
        return undefined;
    }

    public OnAfterDataLoaded(dto: ConfigurationDTO){

    }

    public GetInitialDataHelper(){
        return {};
    }

    public IsEditable(props: IPortalConfigurationProps): boolean {
        return this._authorizationService.IsEditable(EEntityType.Configuration);
    }

    public GetHeaderEditor(props: IPortalConfigurationProps): JSX.Element {
        return (
            <div className={(this._createCard("header")).toString()}>
                <span className={this._createCard("title-icon").toString()}>
                    <svg className={this._createCard("title-ico").mix(["info-block-ico"]).toString()}>
                        <use xlinkHref="#info-block"></use>
                    </svg>
                    <svg className={this._createCard("title-ico-bg").mix(["info-bg-ico"]).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#info-block"></use>
                    </svg>
                </span>
                <span className={this._createCard("header", { text: true }).toString()}>{i18next.t("portalconfiguration:PortalConfigurationTitle")}</span>
            </div>
        );
    }

    public GetEditor(props: IPortalConfigurationProps): JSX.Element {
        if (props.DataDTO === undefined)
            return;

        let isEditable = this.IsEditable(props);
        if (isEditable)
            return this.GetEditableEditor(props);
        return this.GetReadonlyEditor(props);
    }

    public GetEditorModal(props: IPortalConfigurationProps): JSX.Element {
        return undefined;
    }

    public GetReadonlyEditor(props: IPortalConfigurationProps): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("portalconfiguration:MainSiteInfoTitle"), i18next.t("portalconfiguration:MainSiteInfoDescription"), this.GetReadonlyMainSiteInfoBlock(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    public GetEditableEditor(props: IPortalConfigurationProps): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("portalconfiguration:MainSiteInfoTitle"), i18next.t("portalconfiguration:MainSiteInfoDescription"), this.GetMainSiteInfoBlock(props), undefined, props, this._dispatch)}
                </div>
                <div>
                    <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                        {this.GetMainInfoItem(false, true, true, 1, i18next.t("portalconfiguration:ErpSettingsTitle"), i18next.t("portalconfiguration:ErpSettingsDescription"), this.GetErpInfoBlock(props), undefined, props, this._dispatch)}
                    </div>
                    <div className={this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""]).toString()}>
                        {this.GetMainInfoItem(true, false, true, 2, i18next.t("portalconfiguration:ApiSettingsTitle"), i18next.t("portalconfiguration:ApiSettingsDescription"), this.GetApiInfoBlock(props), undefined, props, this._dispatch)}
                    </div>
                </div>
            </div>
        );
    }

    private GetMainSiteInfoBlock(props: IPortalConfigurationProps): JSX.Element{
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", {fill: true}).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConfigurationDTO) => val.WebSiteInfo) }
                                Name={PropertyHelper.GetPropertyName((val: ConfigurationDTO) => val.WebSiteInfo)}
                                Label={i18next.t('portalconfiguration:WebSiteInfo')}
                                Value={props.DataDTO.WebSiteInfo}
                                Validator={this._inputValidator}
                                Required={true}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetErpInfoBlock(props: IPortalConfigurationProps): JSX.Element{
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", {fill: true}).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConfigurationDTO) => val.ErpUserName) }
                                Name={PropertyHelper.GetPropertyName((val: ConfigurationDTO) => val.ErpUserName)}
                                Label={i18next.t('portalconfiguration:ErpUserName')}
                                Value={props.DataDTO.ErpUserName}
                                Validator={this._inputValidator}
                                Required={true}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", {fill: true}).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConfigurationDTO) => val.ErpPassword) }
                                Name={PropertyHelper.GetPropertyName((val: ConfigurationDTO) => val.ErpPassword)}
                                Type="password"
                                Label={i18next.t('portalconfiguration:ErpPassword')}
                                Value={props.DataDTO.ErpPassword}
                                Validator={this._inputValidator}
                                Required={true}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetApiInfoBlock(props: IPortalConfigurationProps): JSX.Element{
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", {fill: true}).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConfigurationDTO) => val.ApiUserName) }
                                Name={PropertyHelper.GetPropertyName((val: ConfigurationDTO) => val.ApiUserName)}
                                Label={i18next.t('portalconfiguration:ApiUserName')}
                                Value={props.DataDTO.ApiUserName}
                                Validator={this._inputValidator}
                                Required={true}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", {fill: true}).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConfigurationDTO) => val.ApiPassword) }
                                Name={PropertyHelper.GetPropertyName((val: ConfigurationDTO) => val.ApiPassword)}
                                Type="password"
                                Label={i18next.t('portalconfiguration:ApiPassword')}
                                Value={props.DataDTO.ApiPassword}
                                Validator={this._inputValidator}
                                Required={true}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetReadonlyMainSiteInfoBlock(props: IPortalConfigurationProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("portalconfiguration:WebSiteInfo")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.WebSiteInfo)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        );
    }
}