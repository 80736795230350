import { EntityReducer } from '../../../BaseEntity/EntityReducer';
import { SelectVehicleHelperProps } from './HelperProps';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { IBaseEntityProps } from '../../../BaseEntity/IBaseEntityProps';
import { SelectVehicleDTO } from './SelectVehicleDTO';
import { VehicleTableDTO } from '../../../../../Domain/DTO/VehicleTableDTO';
import { DRIVER_All_VEHICLES_LOADED } from '../../../../../Constants/AppConstants';

export class SelectVehicleReducer extends EntityReducer<SelectVehicleDTO, SelectVehicleHelperProps>{

    public Reduce(state: IBaseEntityProps<SelectVehicleDTO, SelectVehicleHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<SelectVehicleDTO, SelectVehicleHelperProps> {
        switch (action.type) {
             case DRIVER_All_VEHICLES_LOADED:
                return this.ReduceAllVehicledLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceAllVehicledLoaded(state: IBaseEntityProps<SelectVehicleDTO, SelectVehicleHelperProps>, payload: Array<VehicleTableDTO>): IBaseEntityProps<SelectVehicleDTO, SelectVehicleHelperProps>{
        if (state.DataDTO.SelectedVehiclesIds === undefined){
            state.DataDTO.SelectedVehiclesIds = new Array();
        }
        let filtered = payload.filter(x => state.DataDTO.SelectedVehiclesIds.indexOf(x.Id) == -1);

        return super.CollectionLoaded<VehicleTableDTO>(state, filtered, [x => x.VehicleList]);
    }
}