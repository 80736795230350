export const ABSTRACTLIST="list";
export const ABSTRACTENTITY="entity";

export const LOGIN                = "login";
export const REGISTER             = "register";
export const PASSWORD_RESET       = "reset";
export const PASSWORD_RESTORE     = "restore";
export const SETTINGS             = "settings";
export const ABOUT                = "about";
export const PORTAL_CONFIGURATION = "portalConfiguration";
export const RATING               = "rating";
export const REPORTS              = "reports";
export const SEARCH               = "search";
export const USERPROFILE          = "profile";
export const DRIVER_MONITORING    = "driverMonitoring"

