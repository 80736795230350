import { GIS_COORDINATES_CHANGED, INPUT_VALUE_CHANGE, LAT_COORDINATES_CHANGED, LON_COORDINATES_CHANGED, } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaTimeTimeDTOValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';
import { IVolmaMapWithSelectDTOProps, VolmaMapWithSelectPropsInitial } from './IVolmaMapWithSelectDTOProps';
import { GeoCoordinateDTO } from '../../Domain/DTO/GeoCoordinateDTO';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { LatLng } from 'leaflet';
import { EntityReducer } from '../Entity/BaseEntity/EntityReducer';

@injectable()
export class VolmaMapWithSelectDTOReducer extends BaseInputReducer<IVolmaMapWithSelectDTOProps> {
    public Reduce(state: IVolmaMapWithSelectDTOProps = VolmaMapWithSelectPropsInitial, action: IActionPayloaded<any>): IVolmaMapWithSelectDTOProps {
        state = super.Reduce(state, action);
        // Unused
        switch (action.type) {
            default:
                return state;
        }
    }

    


}

