import {
    PASSWORD_RESET_CLEAR,
    PASSWORD_RESET_MOUNTED,
    PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE,
    PASSWORD_RESET_UNMOUNTED
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../Shared/VolmaComponentReducer';
import { IPasswordResetProps, PasswordResetPropsInitial } from './IPasswordResetProps';

class PasswordResetReducer extends VolmaComponentReducer<IPasswordResetProps> {

    constructor() {
        super(PASSWORD_RESET_MOUNTED, PASSWORD_RESET_UNMOUNTED);
    }

    public Reduce(state: IPasswordResetProps = PasswordResetPropsInitial, action: IActionPayloaded<any>): IPasswordResetProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE:
                return this.ReducePasswordRestored(state);
            case PASSWORD_RESET_CLEAR:
                return this.ReduceClear(state);
            default:
                return state;
        }
    }

    private ReducePasswordRestored(state: IPasswordResetProps): IPasswordResetProps {
        return { ...state, DataHelper: { ...state.DataHelper, IsEmailSentSuccessfully: true } };
    }

    private ReduceClear(state: IPasswordResetProps): IPasswordResetProps {
        return { ...state, DataHelper: { ...state.DataHelper, IsEmailSentSuccessfully: false } };
    }

    public static Initialize(): (state: IPasswordResetProps, action: IActionPayloaded<IPasswordResetProps>) => IPasswordResetProps{
        const reducer = new PasswordResetReducer();
        return (state: IPasswordResetProps, action: IActionPayloaded<IPasswordResetProps>) => reducer.Reduce(state, action);
    }
}

export {PasswordResetReducer};