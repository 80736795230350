import { CommonServerInteraction } from './CommonServerInteraction';
import { UrlFabric } from "./UrlFabric";
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { AxiosPromise } from 'axios';
import { ECQRSApi } from './ECQRSApi';
import { ConfigurationDTO } from '../../Domain/DTO/ConfigurationDTO';
import { injectable } from 'inversify';

@injectable()
export class PortalConfigurationServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public PutConfiguration(dto: ConfigurationDTO): AxiosPromise {
        return this.Put(this._urlFabric.CreateUpdatePortalConfiguration(ECQRSApi.Write), dto);
    }

    public CreateConfiguration(dto: ConfigurationDTO): AxiosPromise {
        return this.Post(this._urlFabric.CreateUpdatePortalConfiguration(ECQRSApi.Write), dto);
    }
}