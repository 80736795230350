import { SetRoleDTO } from '../../Domain/DTO/SetRoleDTO';
import { LoginDTO } from '../../Domain/DTO/LoginDTO';
import { RegisterUserDTO } from '../../Domain/DTO/RegisterUserDTO';
import { ResetPasswordDTO } from '../../Domain/DTO/ResetPasswordDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { RestorePasswordDTO } from '../../Domain/DTO/RestorePasswordDTO';
import { RegisterCargoTransporterUserDTO } from '../../Domain/DTO/RegisterCargoTransporterUserDTO';
import { RegisterShipperUserDTO } from '../../Domain/DTO/RegisterShipperUserDTO';
import { UpdatePasswordDTO } from '../../Domain/DTO/UpdatePasswordDTO';
import { ECQRSApi } from './ECQRSApi';
import { AxiosPromise } from 'axios';

@injectable()
export class AuthServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;
    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public Login(command: LoginDTO): AxiosPromise {
        return this.Post(this._urlFabric.Login(), command);
    }

    public PasswordUpdate(command: UpdatePasswordDTO): AxiosPromise {
        return this.Post(this._urlFabric.UpdatePassword(), command);
    }

    public PasswordReset(command: ResetPasswordDTO): AxiosPromise {
        return this.Post(this._urlFabric.Reset(), command);
    }

    public PasswordRestore(command: RestorePasswordDTO): AxiosPromise {
        return this.Post(this._urlFabric.Restore(), command);
    }

    public Register(command: RegisterUserDTO): AxiosPromise {
        return this.Post(this._urlFabric.Register(), command);
    }

    public RegisterCargoTransporterUser(command: RegisterCargoTransporterUserDTO): AxiosPromise {
        return this.Post(this._urlFabric.RegisterCargoTransporterUser(), command);
    }

    public RegisterShipperUser(command: RegisterShipperUserDTO): AxiosPromise {
        return this.Post(this._urlFabric.RegisterShipperUser(), command);
    }

    public UpdateCargoTransporterUser(dto: RegisterCargoTransporterUserDTO): AxiosPromise {
        return this.Put(this._urlFabric.UpdateCargoTransporterUser(), dto);
    }

    public UpdateShipperUser(dto: RegisterShipperUserDTO): AxiosPromise {
        return this.Put(this._urlFabric.UpdateShipperUser(), dto);
    }

    public DeleteCargoTransporterUser(id: string): AxiosPromise {
        return this.Delete(this._urlFabric.DeleteCargoTransporterUser(id));
    }

    public DeleteShipperUser(id: string): AxiosPromise {
        return this.Delete(this._urlFabric.DeleteShipperUser(id));
    }

    public SetRole(dto: SetRoleDTO): AxiosPromise {
        return this.Put(this._urlFabric.SetRole(), dto);
    }

    public Logout(): AxiosPromise {
        return this.Post(this._urlFabric.Logout());
    }

    public GetUserInfo(): AxiosPromise {
        return this.Get(this._urlFabric.GetUserInfo());
    }

    public BlockSelectedUsers(itemId: string): AxiosPromise {
        return this.Post(this._urlFabric.BlockSelectedCargotransporterUsers(ECQRSApi.Write, itemId));
    }

    public UnblockSelectedUsers(itemId: string): AxiosPromise {
        return this.Post(this._urlFabric.UnblockSelectedCargotransporterUsers(ECQRSApi.Write, itemId));
    }
}