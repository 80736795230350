import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { CloseAction } from '../Action/CloseAction';
import { MarkAsSatisfiedAction } from '../Action/MarkAsSatisfiedAction';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { HarmonizationOfConditions } from './HarmonizationOfConditions';
import { CancelAction } from '../Action/CancelAction';

export class DecisionAccepted extends ComplaintStatusDefault{

    public TitleCurrent : string = "complaint:StatusDecisionAcceptedCurrent";
    public TitleFinished: string = "complaint:StatusDecisionAcceptedFinished";
    public TitleFuture  : string = "complaint:StatusDecisionAcceptedFuture";

    public GetActions(dto: ComplaintDTO){
        return [new MarkAsSatisfiedAction(), new CancelAction()];
    }

    constructor(){
        super();
        this._prevState = new HarmonizationOfConditions();
        this._currentStatus = EComplaintState.DecisionAccepted;
    }
}