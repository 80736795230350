import {
    PORTAL_CONFIGURATION_LOADED,
    PORTAL_CONFIGURATION_MOUNTED,
    PORTAL_CONFIGURATION_UNMOUNTED
} from '../../../Constants/AppConstants';
import { ConfigurationDTO } from '../../../Domain/DTO/ConfigurationDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import { BaseCustomLoadSaveReducer } from '../BaseCustomLoadSave/BaseCustomLoadSaveReducer';
import { PortalConfigurationHelperPropsInitial } from './IHelperProps';
import { IPortalConfigurationProps, PortalConfigurationPropsInitial } from './IPortalConfigurationProps';
import { injectable } from 'inversify';

@injectable()
export class PortalConfigurationReducer extends BaseCustomLoadSaveReducer{//VolmaComponentReducer<IPortalConfigurationProps> {
    private _entityService: EntityService;

    constructor() {
        super(PORTAL_CONFIGURATION_MOUNTED, PORTAL_CONFIGURATION_UNMOUNTED);

        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
    }

    public Reduce(state: IPortalConfigurationProps = PortalConfigurationPropsInitial, action: IActionPayloaded<any>): IPortalConfigurationProps {
        state = super.Reduce(state, action);

        switch (action.type) {
            case PORTAL_CONFIGURATION_LOADED:
                return this.ReducePortalConfigurationLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    public static Initialize(): (state: IPortalConfigurationProps, action: IActionPayloaded<IPortalConfigurationProps>) => IPortalConfigurationProps {
        const reducer = new PortalConfigurationReducer();
        return (state: IPortalConfigurationProps, action: IActionPayloaded<IPortalConfigurationProps>) => reducer.Reduce(state, action);
    }

    private ReducePortalConfigurationLoaded(state: IPortalConfigurationProps, payload: ConfigurationDTO):IPortalConfigurationProps{
        let newState = <IPortalConfigurationProps>{ ...state,
            DataDTO: payload,
            DataHelper: JSON.parse(JSON.stringify(PortalConfigurationHelperPropsInitial)),
            DataDTOStringify: JSON.stringify(payload),
            ClosedCardParts: this.GetInfoPartStatesFromLocalStorage(state.EntityType)};
        return newState;
    }
}