import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { MouseEvent } from 'react';

export interface IVolmaInputProps extends IBaseInputProps<IVolmaInputProps> {
    CharCounter?: boolean
    Type?: string,
    MaxLength?: number,
    Autofocus?: boolean,
    Value: string;
    Mask?: string;
    IsInHeader?: boolean;
    IsActive?: boolean;
    DefaultValue?: string;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
    OnValueChange?: (event: any) => void;

    ButtonClass?: string;
    ButtonIcoClass?: string;
    ButtonSvg?: string;
    OnButtonClicked?: (event: MouseEvent<HTMLButtonElement>) => void;
    InputClassName?: string;
    PlaceholderClassName?: string;
}

export const VolmaInputPropsInitial: IVolmaInputProps = <IVolmaInputProps>{
    ...VolmaBaseInputPropsInitial,
    Type: "text",
    Value: "",
    MaxLength: 255,
    IsInHeader: false,
    IsActive: false,

    ButtonClass: undefined,
    ButtonIcoClass: undefined,
    ButtonSvg: undefined
};