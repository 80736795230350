import { injectable } from "inversify";
import {LogService} from "./LogService";
import {Types} from "../Types";
import {VolmaContainer} from "../InversifyInject";

@injectable()
export class InMemoryStorageService {
    private _logger: LogService;
    private _cache: any;

    constructor() {
        this._logger = VolmaContainer.get<LogService>(Types.LogService);
        this._cache = {};
    }

    public IsKeyExist(name: string): boolean {
        return this._cache[name] !== undefined;
    }

    public GetKey(name: string): any {
        if (this.IsKeyExist(name))
            return this._cache[name];
        return undefined;
    }

    public SetKey(name: string, value: any): void {
        if(value !== null && value !== undefined)
            this._cache[name] = JSON.parse(JSON.stringify(value));
    }

    public RemoveKey(name: string): void {
        if (this.IsKeyExist(name)) {
            this._cache[name] = undefined;
        }
    }
}