import * as moment from 'moment';

import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { TenderWithdrawAction } from '../Actions/TenderWithdrawAction';
import { TenderHelperProps } from '../IHelperProps';

export class Waiting extends StatusDefault<TenderDTO, TenderHelperProps>{
    public TitleCurrent : string = "tender:StatusWaitingCurrent";
    public TitleFinished: string = "tender:StatusWaitingFinished";
    public TitleFuture  : string = "tender:StatusWaitingFuture";

    public IsActive(dto: TenderDTO) {
        return true;
    }

    public IsCurrent(dto: TenderDTO){
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isBefore(this._timeService.GetLocalTime(dto.StartBiddingDate));// now < start bidding
    }

    public IsFinished(dto: TenderDTO){
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isAfter(this._timeService.GetLocalTime(dto.StartBiddingDate));// now > start bidding
    }

    public GetActions(dto: TenderDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [new TenderWithdrawAction()];
    }
}
