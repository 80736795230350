import { EReportType } from '../../../Domain/Enum/EReportType';
import { IReportService } from './ConcreteReports/IReportService';
import { ReportParametersExtendedDTO } from './ReportParametersExtendedDTO';

export interface IReportHelperProps<TReportParams>{
    ReportCharts          : Array<ReportParametersExtendedDTO>

    SelectedReportType         : EReportType

    ReportService: IReportService<TReportParams>;
    ReportParams : TReportParams;
    IsExcelReport: boolean;

    ExcelFileId: string;

    ReportGeneratedLastDate: Date | null
}

export const InitialReportHelperProps: IReportHelperProps<any> = {
    ReportCharts          : [],

    SelectedReportType         : undefined,

    ReportService: undefined,
    ReportParams: {},
    IsExcelReport: true,

    ExcelFileId: undefined,

    ReportGeneratedLastDate: null
};