import { IEntityReducer } from './IEntityReducer';
import { IBaseEntityProps } from './IBaseEntityProps';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { injectable } from 'inversify';
import { ITableDTO } from '../../../Domain/ITableDTO';

@injectable()
export abstract class EntityReducer<TDTO extends {}, THelper> implements IEntityReducer<TDTO, THelper>{

    public abstract Reduce(state: IBaseEntityProps<TDTO, THelper>, action: IActionPayloaded<any>): IBaseEntityProps<TDTO, THelper>;
    protected UpdateDTO(state: IBaseEntityProps<TDTO, THelper>): IBaseEntityProps<TDTO, THelper>{
        return state;
    }


    protected UpdateDTOField<TDTOField, THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        dtoFieldGetter: (dto: TDTO) => TDTOField,
        helperCollectionGetter: (helper: THelper) => Array<THelperField>,
        helperFieldGetter: (helper: THelper) => THelperField,
        helperFieldToDTOFieldTranslator: (helperField: THelperField) => TDTOField): IBaseEntityProps<TDTO, THelper> {
        let newState = state;

        let dtoFieldName = PropertyHelper.GetPropertyName(dtoFieldGetter);
        let helperFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let collectionValue = helperCollectionGetter(state.DataHelper);
        let dtoFieldValue = dtoFieldGetter(state.DataDTO);

        if (dtoFieldValue !== undefined && collectionValue != undefined && collectionValue.length > 0) {

            let selectedItem = collectionValue.find(x => dtoFieldValue === helperFieldToDTOFieldTranslator(x));

            newState = {...state, DataDTO: {...<any>state.DataDTO}, DataHelper: {...<any>state.DataHelper}};

            newState.DataDTO[dtoFieldName] = helperFieldToDTOFieldTranslator(selectedItem);
            newState.DataHelper[helperFieldName] = selectedItem;
        }
        else if (dtoFieldValue === undefined)
        {
            newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

            newState.DataDTO[dtoFieldName] = undefined;
            newState.DataHelper[helperFieldName] = undefined;
        }
        return newState;
    }

    protected UpdateDTOFieldList<TDTOField, THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        dtoFieldGetter: (dto: TDTO) => Array<TDTOField>,
        helperCollectionGetter: (helper: THelper) => Array<THelperField>,
        helperFieldGetter: (helper: THelper) => Array<THelperField>,
        helperFieldToDTOFieldTranslator: (helperField: THelperField) => TDTOField): IBaseEntityProps<TDTO, THelper> {
        let newState = state;

        let dtoFieldName = PropertyHelper.GetPropertyName(dtoFieldGetter);
        let helperFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let collectionValue = helperCollectionGetter(state.DataHelper);
        let dtoFieldValue = dtoFieldGetter(state.DataDTO);

        if (dtoFieldValue !== undefined && collectionValue != undefined && collectionValue.length > 0) {

            let selectedItems = collectionValue.filter(x => dtoFieldValue.indexOf(helperFieldToDTOFieldTranslator(x)) >= 0);

            newState = {...state, DataDTO: {...<any>state.DataDTO}, DataHelper: {...<any>state.DataHelper}};

            newState.DataDTO[dtoFieldName] = selectedItems.map(x => helperFieldToDTOFieldTranslator(x));
            newState.DataHelper[helperFieldName] = selectedItems;
        }
        return newState;
    }

    protected UpdatedSelected<TDTOField, THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        payload: TDTOField,
        dtoFieldGetter: (dto: TDTO) => TDTOField,
        helperFieldGetter: (helper: THelper) => THelperField,
        helperFieldToDTOFieldTranslator: (helperField: THelperField) => TDTOField): IBaseEntityProps<TDTO, THelper> {

        if (payload === null)
            payload = undefined;
        let fieldValue = undefined;
        if (payload !== undefined)
            fieldValue = helperFieldToDTOFieldTranslator(payload as any);

        let dtoFieldName = PropertyHelper.GetPropertyName(dtoFieldGetter);
        let helperFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };
        newState.DataDTO[dtoFieldName] = fieldValue;
        newState.DataHelper[helperFieldName] = payload;

        return newState;
    }

    protected DeletedSelected<TDTOField, THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        dtoFieldGetter: (dto: TDTO) => TDTOField,
        helperFieldGetter: (helper: THelper) => THelperField): IBaseEntityProps<TDTO, THelper> {

        let dtoFieldName = PropertyHelper.GetPropertyName(dtoFieldGetter);
        let helperFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };
        newState.DataDTO[dtoFieldName] = undefined;
        newState.DataHelper[helperFieldName] = undefined;

        return newState;
    }

    protected UpdatedSelectedList<TDTOField, THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        payload: Array<THelperField>,
        dtoFieldGetter: (dto: TDTO) => Array<TDTOField>,
        helperFieldGetter: (helper: THelper) => Array<THelperField>,
        helperFieldToDTOFieldTranslator: (helperField: THelperField) => TDTOField): IBaseEntityProps<TDTO, THelper> {

        let fieldValues = [];
        if (payload !== undefined)
            fieldValues = payload.map(x => helperFieldToDTOFieldTranslator(x));

        let dtoFieldName = PropertyHelper.GetPropertyName(dtoFieldGetter);
        let helperFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };
        newState.DataDTO[dtoFieldName] = fieldValues;
        newState.DataHelper[helperFieldName] = payload;

        return newState;
    }

    protected CollectionLoaded<THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        payload: Array<THelperField>,
        helperCollectionGetters: Array<(helper: THelper) => Array<THelperField>>): IBaseEntityProps<TDTO, THelper>
    {
        let newState = { ...state, DataHelper: { ...<any>state.DataHelper } };

        for (let helperCollectionGetter of helperCollectionGetters){
            let helperCollectionFieldName = PropertyHelper.GetPropertyName(helperCollectionGetter);

            newState.DataHelper[helperCollectionFieldName] = payload;
        }

        return this.UpdateDTO(newState);
    }

    protected EntityLoaded<THelperField>(
        state: IBaseEntityProps<TDTO, THelper>,
        payload: THelperField,
        helperFieldGetter: (helper: THelper) => THelperField): IBaseEntityProps<TDTO, THelper>
    {

        let helperentityFieldName = PropertyHelper.GetPropertyName(helperFieldGetter);

        let newState = { ...state, DataHelper: { ...<any>state.DataHelper }};
        newState.DataHelper[helperentityFieldName] = payload;

        return newState;
    }

    protected DataLoaded(
        state: IBaseEntityProps<TDTO, THelper>,
        payload: TDTO): IBaseEntityProps<TDTO, THelper>
    {
        let newState = { ...state, DataDTO: payload};

        return this.UpdateDTO(newState);;
    }

    protected CopyCollectionObject(collection: Array<any>) : Array<any> {
        if (collection === undefined)
            return new Array();
        else
            return collection.slice(0);
    }

    protected ItemAddedToCollection<THelper, TData>(baseObj: THelper, payload: TData, helperCollectionGetters: Array<(helper: THelper) => Array<TData>>): void {
        for (let helperCollectionGetter of helperCollectionGetters) {
            let helperCollectionFieldName = PropertyHelper.GetPropertyName(helperCollectionGetter);

            baseObj[helperCollectionFieldName] = this.CopyCollectionObject(baseObj[helperCollectionFieldName]);
            baseObj[helperCollectionFieldName].push(payload);

        }
    }

    protected ItemDeletedFromCollection<THelper, TData>(baseObj: THelper, payload: TData, areEqual: (a: TData, b: TData) => boolean, helperCollectionGetters: Array<(helper: THelper) => Array<TData>>): void {
        for (let helperCollectionGetter of helperCollectionGetters) {
            let helperCollectionFieldName = PropertyHelper.GetPropertyName(helperCollectionGetter);

            let index = baseObj[helperCollectionFieldName].findIndex(x => areEqual(x, payload));
            if (index >= 0) {
                baseObj[helperCollectionFieldName] = this.CopyCollectionObject(baseObj[helperCollectionFieldName]);
                baseObj[helperCollectionFieldName].splice(index, 1);
            }
        }
    }

    protected ItemEditedInCollection<THelper extends any, TData extends ITableDTO>(baseObj: THelper, payload: TData, helperCollectionGetters: Array<(helper: THelper) => Array<TData>>): void {
        for (let helperCollectionGetter of helperCollectionGetters) {
            let helperCollectionFieldName = PropertyHelper.GetPropertyName(helperCollectionGetter);

            baseObj[helperCollectionFieldName] = this.CopyCollectionObject(baseObj[helperCollectionFieldName]);
            let index = baseObj[helperCollectionFieldName].findIndex(x => x.Id === payload.Id);
            baseObj[helperCollectionFieldName].splice(index, 1, payload);
        }
    }
}