import { EEntityType } from '../../../Domain/Enum/EEntityType';
import {
    VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED,
    VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE,
    VOLMA_TABLE_CLEAR_DATA,
    VOLMA_TABLE_CLOSE_FILTER,
    VOLMA_TABLE_DATA_LOADED,
    VOLMA_TABLE_DATA_UPDATED,
    VOLMA_TABLE_OPEN_FILTER,
    VOLMA_TABLE_REMOVE_COLUMN_FILTER,
    VOLMA_TABLE_RESET_PAGING,
    VOLMA_TABLE_SORT,
    VOLMA_TABLE_TOGGLE_ROW_SELECTION
} from '../../../Constants/AppConstants';
import { VOLMA_TABLE_CLEAR_SELECTION, VOLMA_TABLE_FILTER_OPTIONS, VOLMA_TABLE_SORT_OPTIONS, VOLMA_TABLE_UPDATE_FILTERS, VOLMA_TABLE_SCROLLED } from '../../../Constants/AppConstants';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EFilterType } from '../../../Domain/Enum/EFilterType';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { IReducePayloaded } from '../../../Infrastructure/Reducer/IReducer';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { LogService } from '../../../Infrastructure/Services/LogService';
import { Types } from '../../../Infrastructure/Types';
import { VolmaFormPropsInitial } from '../../VolmaForm/IVolmaFormProps';
import { IVolmaTableProps, IVolmaTableSortProps } from './IVolmaTableProps';
import { IVolmaTableCalendarToggledPayload, IVolmaTableDataPayload, IVolmaTableUpdateFilterPayload, IVolmaTableResetPagingPayload } from './Payload';
import {
    IVolmaTableChangeFilterPayload,
    IVolmaTableCloseFilterPayload,
    IVolmaTableOpenFilterPayload,
    IVolmaTableRemoveColumnFilterPayload,
    IVolmaTableSortPayload,
    IVolmaTableToggleSelectionPayload
} from './Payload';
import { injectable } from 'inversify';
import { LocalStorageService } from '../../../Infrastructure/Services/LocalStorageService';

@injectable()
export class VolmaTableReducer<T extends IVolmaTableProps<any>> implements IReducePayloaded<T> {
    private _logService: LogService;
    private _entityService: EntityService;
    private _localStorageService: LocalStorageService;
    private _filterOptions: string = "filterOptions";
    private _sortOptions: string = "sortOptions";

    constructor() {
        this._logService = VolmaContainer.get<LogService>(Types.LogService);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public Reduce(state: T = <T>VolmaFormPropsInitial, action: IActionPayloaded<any>): T {

        switch (action.type) {
            case VOLMA_TABLE_OPEN_FILTER:
                return this.ReduceOpenFilter(state, action.Payload);
            case VOLMA_TABLE_CLOSE_FILTER:
                return this.ReduceCloseFilter(state, action.Payload);
            case VOLMA_TABLE_CHANGE_COLUMN_FILTER_VALUE:
                return this.ReduceChangeColumnFilterValue(state, action.Payload);
            case VOLMA_TABLE_CALENDAR_OVERLAY_TOGGLED:
                return this.ReduceCalendarToggled(state, action.Payload);
            case VOLMA_TABLE_REMOVE_COLUMN_FILTER:
                return this.ReduceRemoveColumnFilter(state, action.Payload);
            case VOLMA_TABLE_SORT:
                return this.ReduceSort(state, action.Payload);
            case VOLMA_TABLE_TOGGLE_ROW_SELECTION:
                return this.ReduceToggleRowSelection(state, action.Payload);
            case VOLMA_TABLE_CLEAR_SELECTION:
                return this.ReduceClearSelection(state, action.Payload);
            case VOLMA_TABLE_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case VOLMA_TABLE_CLEAR_DATA:
                return this.ReduceClearData(state);
            case VOLMA_TABLE_DATA_UPDATED:
                return this.ReduceDataUpdated(state, action.Payload);
            case VOLMA_TABLE_UPDATE_FILTERS:
                return this.UpdateFilters(state, action.Payload);
            case VOLMA_TABLE_RESET_PAGING:
                return this.ResetPaging(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceOpenFilter(state: IVolmaTableProps<{}>, payload: IVolmaTableOpenFilterPayload): T {
        if (state.IsFilterOpened) {
            this._logService.Warn("Attempt to open already opened filter");
            return <T>state;
        }
        
        let newState: T = <T>{ ...state, IsFilterOpened: true, OpenedFilter: this.GetOrAddStoredFilter(state, payload.FilterColumnName, payload.FilterType) };
        return newState;
    }

    private ReduceCloseFilter(state: IVolmaTableProps<{}>, payload: IVolmaTableCloseFilterPayload): T {
        if (!state.IsFilterOpened) {
            this._logService.Warn("Attempt to close already closed filter");
            return <T>state;
        }
        let newState: T = <T>{ ...state, IsFilterOpened: false, OpenedFilter: undefined };
        return newState;
    }

    private ReduceChangeColumnFilterValue(state: IVolmaTableProps<{}>, payload: IVolmaTableChangeFilterPayload): T {
        if (!state.IsFilterOpened) {
            this._logService.Warn("Attempt to update closed filter");
            return <T>state;
        }
        if (state.OpenedFilter === undefined) {
            this._logService.Warn("Invalid state: filter is opened but opened filter is undefined");
            return <T>state;
        }

        let newState: T = <T>{ ...state };

        const filterColumnName = payload.FilterColumnName || newState.OpenedFilter.Key;
        let storedFilter = this.GetOrAddStoredFilter(newState, filterColumnName, payload.FilterType);
        
        switch (payload.FilterType) {
            case EFilterType.Text:
                storedFilter.TextValue = payload.Value;
                break;
            case EFilterType.DateMin:
                storedFilter.DateMinValue = payload.Value;
                break;
            case EFilterType.DateMax:
                storedFilter.DateMaxValue = payload.Value;
                break;
            case EFilterType.TimeMin:
                storedFilter.TimeMinValue = payload.Value;
                break;
            case EFilterType.TimeMax:
                storedFilter.TimeMaxValue = payload.Value;
                break;
            case EFilterType.Select:
                storedFilter.SelectSelectedValues = payload.Value;
                break;
        }

        newState.OpenedFilter = <FilterDTO>{...storedFilter};
        if (newState.Paging !== undefined){
            newState.Paging = { ...newState.Paging};
            newState.Paging.PageNumber = 1;
        }
        else{
            newState.Paging = {} as any;
        }

        if (!newState.IsTableInsideEntity){
            let savedFilters = this._localStorageService.GetKey(VOLMA_TABLE_FILTER_OPTIONS);
            let currentFilters = {};

            if (savedFilters !== undefined){
                currentFilters = JSON.parse(savedFilters);
            }
            currentFilters[newState.Entity] = newState.Filter;
            this._localStorageService.SetKey(VOLMA_TABLE_FILTER_OPTIONS, JSON.stringify(currentFilters));
        }

        return newState;
    }

    private ReduceCalendarToggled(state: IVolmaTableProps<{}>, payload: IVolmaTableCalendarToggledPayload): T {
        if (!state.IsFilterOpened) {
            this._logService.Warn("Attempt to update closed filter");
            return <T>state;
        }
        if (state.OpenedFilter === undefined) {
            this._logService.Warn("Invalid state: filter is opened but opened filter is undefined");
            return <T>state;
        }

        let newState: T = <T>{ ...state };

        let storedFilter = this.GetOrAddStoredFilter(newState, newState.OpenedFilter.Key, payload.FilterType);
        switch (payload.FilterType) {
            case EFilterType.Text:
                break;
            case EFilterType.DateMin:
                storedFilter.DateMinOverlayShown = payload.Value;
                break;
            case EFilterType.DateMax:
                storedFilter.DateMaxOverlayShown = payload.Value;
                break;
            case EFilterType.TimeMin:
                break;
            case EFilterType.TimeMax:
                break;
            case EFilterType.Select:
                break;
        }

        newState.OpenedFilter = <FilterDTO>{...storedFilter};
        return newState;
    }

    private ReduceRemoveColumnFilter(state: IVolmaTableProps<{}>, payload: IVolmaTableRemoveColumnFilterPayload): T {
        let newState: T = <T>{ ...state, Filter: this.RemoveColumnFilter(state, payload.FilterColumnName) };
        return newState;
    }

    private ReduceSort(state: IVolmaTableProps<{}>, payload: IVolmaTableSortPayload): T {
        let sortOptions = {sortBy: payload.SortBy, sortDirection: payload.SortDirection};
        let newState = <T>{ ...state, SortOptions: sortOptions};

        if (!newState.IsTableInsideEntity){
            let savedSortOptions = this._localStorageService.GetKey(VOLMA_TABLE_SORT_OPTIONS);
            let currentSortOptions = {};

            if (savedSortOptions !== undefined){
                currentSortOptions = JSON.parse(savedSortOptions);
            }
            currentSortOptions[newState.Entity] = sortOptions;
            this._localStorageService.SetKey(VOLMA_TABLE_SORT_OPTIONS, JSON.stringify(currentSortOptions));
        }

        return newState;
    }

    private ReduceToggleRowSelection(state: IVolmaTableProps<{}>, payload: IVolmaTableToggleSelectionPayload): T {
        let newState = { ...state, SelectedIds: state.SelectedIds.slice(0) } as T;

        if (newState.SelectedIds.length === 0 || (!payload.CtrlPressed && !payload.ShiftPressed))
        {
            newState.SelectedIds = [];
            if (payload.Id !== undefined)
                newState.SelectedIds.push(payload.Id);
        }
        else if (payload.CtrlPressed){
            let idx = newState.SelectedIds.findIndex(x => x == payload.Id);
            if (idx < 0)
                newState.SelectedIds.push(payload.Id);
            else {
                newState.SelectedIds.splice(idx, 1);
            }
        }
        else if (payload.ShiftPressed){
            let lastSelectedId = newState.SelectedIds[newState.SelectedIds.length - 1];
            let firstIndex = newState.Data.findIndex((x: ITableDTO) => x.Id === lastSelectedId);
            let lastIndex = newState.Data.findIndex((y: ITableDTO) => y.Id === payload.Id);
            if (firstIndex < 0 || lastIndex < 0)
                return state as T;
            if (lastIndex < firstIndex)
            {
                let tmp = lastIndex;
                lastIndex = firstIndex;
                firstIndex = tmp;
            }

            for (let i = firstIndex; i <= lastIndex; i++){
                newState.SelectedIds.push(newState.Data[i].Id);
            }
            
            newState.SelectedIds = newState.SelectedIds.filter((x: string, i: number) => newState.SelectedIds.indexOf(x) === i);
        }
        return newState;
    }

    private ReduceClearSelection(state: IVolmaTableProps<{}>, payload: IVolmaTableProps<{}>): T {
        let newState = { ...state, SelectedIds: new Array<string>() } as T;
        
        return newState;
    }

    private ReduceDataLoaded(state: IVolmaTableProps<{}>, payload: IVolmaTableDataPayload): T {
        let data = payload.Items;
        let dataOrig = data.slice(0);
        return { ...state, 
            Data: data, 
            DataOriginal: dataOrig, 
            Paging : {ItemsPerPage: payload.ItemsPerPage, PageNumber: payload.PageNumber, TotalItems: payload.TotalItems, TotalPages: payload.TotalPages}
        } as T;
    }

    private ReduceClearData(state: IVolmaTableProps<{}>): T {
        let data = [];
        let dataOrig = [];
        return { ...state, 
            Data: data, 
            DataOriginal: dataOrig, 
        } as T;
    }

    private ReduceDataUpdated(state: IVolmaTableProps<{}>, payload: IVolmaTableDataPayload): T {
        let items = payload.Items || [];
        return { ...state, Data: items, DataOriginal: items.slice(0)} as T;
    }

    private GetOrAddStoredFilter(state: IVolmaTableProps<{}>, filterColumnName: string, filterType: EFilterType): FilterDTO {
        let storedFilter = state.Filter.find((val: FilterDTO) => val.Key === filterColumnName);

        if (storedFilter === undefined) {
            storedFilter = new FilterDTO();
            storedFilter.Key = filterColumnName;
            storedFilter.Type = filterType;

            state.Filter.push(storedFilter);
        }

        return storedFilter;
    }

    private RemoveColumnFilter(state: IVolmaTableProps<{}>, filterColumnName: string): Array<FilterDTO> {
        let filters = state.Filter.splice(0);
        let filterIndex = filters.findIndex((val: FilterDTO) => val.Key === filterColumnName);

        if (filterIndex > -1) {
            filters.splice(filterIndex, 1);
        }
        else {
            this._logService.Warn("Attempt to remove filter that is not presented in the filters array, filterColumnName: " + filterColumnName + " filters: " + JSON.stringify(state.Filter));
        }
        return filters;
    }

    private ResetPaging(state: IVolmaTableProps<{}>, payload: IVolmaTableResetPagingPayload): T {
        let newState = <T>{ ...state};
        newState.Paging = {} as any;

        return newState;
    }

    private UpdateFilters(state: IVolmaTableProps<{}>, payload: IVolmaTableUpdateFilterPayload): T {
        let newState = <T>{ ...state, SortOptions: {}, Filter: new Array()};
        //todo потому что тип энтити назначается только при регистрации компоненты...
        newState.Entity = payload.Type;

        if (!newState.IsTableInsideEntity){
            let savedFilters = this._localStorageService.GetKey(VOLMA_TABLE_FILTER_OPTIONS);
            let filters = {};
            if (savedFilters !== undefined){
                filters = JSON.parse(savedFilters);
            }
            if (filters[payload.Type] !== undefined)
                newState.Filter = filters[payload.Type];

            let savedSortOptions = this._localStorageService.GetKey(VOLMA_TABLE_SORT_OPTIONS);
            let sortOptions = {};
            if (savedSortOptions !== undefined){
                sortOptions = JSON.parse(savedSortOptions);
            }
            if (sortOptions[payload.Type] !== undefined)
                newState.SortOptions = sortOptions[payload.Type];
        }
        newState.Paging = {} as any;

        return newState;
    }
}