import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';
import { Types } from '../../../../Infrastructure/Types';
import i18next from '../../../i18n';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryConfirmDTO } from '../ActionConfirm/DeliveryConfirmDTO';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';

export class UpdateVehicleDriverAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;
    private _formatService: FormatService;

    public Title: string = "delivery:UpdateVehicleDriver";

    constructor(){
        super();

        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
        this._formatService        = VolmaContainer.get<FormatService>(Types.FormatService);
    }
    
    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean {
        if (this._authService.IsCargoTransporter()) {
            if (selectedIds === undefined || selectedIds.length !== 1) {
                return false;
            }

            const itm = data.find(x => x.Id === selectedIds[0]);

            if (itm === undefined){
                return false;
            }

            return itm.State === EDeliveryState.Approved || itm.State === EDeliveryState.WaitForLoading || itm.State === EDeliveryState.LoadingLatenessRegistration;
        }

        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        const id = selectedIds[0];        
        const deliveryDTO = data.find(x => x.Id === id);

        if (deliveryDTO === undefined)
            return;

        const multiCargoTransporterDefined = this._formatService.IsValueNotEmpty(deliveryDTO.MultiCargoTransporterId);

        const dto = new DeliveryConfirmDTO();
        dto.DriverId = deliveryDTO.AssignedDriverId || (deliveryDTO as DeliveryDTO).DriverId;
        dto.VehicleId = deliveryDTO.AssignedVehicleId || (deliveryDTO as DeliveryDTO).VehicleId;
        dto.ContainerId = (deliveryDTO as DeliveryDTO).ContainerId;

        if (multiCargoTransporterDefined) {
            dto.CargoTransporterId = deliveryDTO.CargoTransporterId;
        }

        dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryConfirm, dto, undefined, (dto) => {
            let stateDTO = new DeliveryStateDTO();

            stateDTO.DeliveryId = id;
            stateDTO.DeliveryState = deliveryDTO.State;

            stateDTO.DriverId = dto.DriverId;
            stateDTO.VehicleId = dto.VehicleId;
            stateDTO.ContainerId = dto.ContainerId;

            if (multiCargoTransporterDefined) {
                stateDTO.CargoTransporterId = dto.CargoTransporterId;
            }            

            dispatch(this._deliveryTableActions.DeliveryAddState(stateDTO, i18next.t("common:Loading"), reloadData));
        }))
    }

    
}
