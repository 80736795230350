import { VolmaTableCommonCellRenderers } from './VolmaTableCommonCellRenderers';
import { CargoTransporterRouteRateDTO } from '../../../../Domain/DTO/CargoTransporterRouteRateDTO';
import { RouteRateTableDTO } from '../../../../Domain/DTO/RouteRateTableDTO';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import {
    ECargoTransporterRouteRateEditingEntity
} from '../../../Entity/CargoTransporter/CargoTransporterRoteRate/Table/EEditingEntity';
import {
    ICargoRouteRateTableHelperProps
} from '../../../Entity/CargoTransporter/CargoTransporterRoteRate/Table/ITableHelperProps';
import VolmaNumber from '../../../VolmaNumber/index';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';
import { injectable } from 'inversify';
import i18next from '../../../i18n';
import * as React from 'react';



@injectable()
export class VolmaTableRouteRateCellRenderers {

    public static RouteLoadingPointCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, route:true })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-building-ico"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#building"></use>
                    </svg>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{params.cellData}</span>
                </div>
            </div>
        )
    }

    public static RouteZoneCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected, route:true })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-tie-ico"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tie"></use>
                    </svg>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{params.cellData}</span>
                </div>
            </div>
        )
    }

    public static EditableCargoRateCellRenderer(
        props: IVolmaTableProps<ICargoRouteRateTableHelperProps>,
        params: IVolmaTableCellRendererParams,
        entity: ECargoTransporterRouteRateEditingEntity,
        cargoTransporterId: string,
        startEditing: (tableDTO: CargoTransporterRouteRateDTO) => void,
        endEditing: (dto: CargoTransporterRouteRateDTO, isValid: boolean) => void) {

        let cargoRouteRate = props.Data[params.rowIndex] as RouteRateTableDTO;
        let isEditing = props.DataHelper !== undefined &&
            props.DataHelper.EditingEntity === entity &&
            props.DataHelper.EditingDTO !== undefined &&
            props.DataHelper.EditingDTO.LoadingPointId === cargoRouteRate.LoadingPointId &&
            props.DataHelper.EditingDTO.TargetZoneId === cargoRouteRate.ZoneId;

        let cargoDTO: CargoTransporterRouteRateDTO;
        if(props.DataHelper.RouteRates !== undefined)
            cargoDTO = props.DataHelper.RouteRates.find(x => x.LoadingPointId === cargoRouteRate.LoadingPointId && x.TargetZoneId === cargoRouteRate.ZoneId);
        if(cargoDTO === undefined){
            cargoDTO = new CargoTransporterRouteRateDTO();
            cargoDTO.LoadingPointId = cargoRouteRate.LoadingPointId;
            cargoDTO.TargetZoneId = cargoRouteRate.ZoneId;
            cargoDTO.CargoTransporterId = cargoTransporterId;
            cargoDTO.Rate = 0;
            cargoDTO.VehicleCount = 0;
        }

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        let unit = entity === ECargoTransporterRouteRateEditingEntity.Rate ? i18next.t('common:rub') : i18next.t('common:pcs');

        let value = undefined;
        if (isEditing)
             value = entity === ECargoTransporterRouteRateEditingEntity.Rate ? props.DataHelper.EditingDTO.Rate : props.DataHelper.EditingDTO.VehicleCount

        let numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected && !isEditing, white: isEditing, hiddenOverflow: isEditing })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    {entity === ECargoTransporterRouteRateEditingEntity.Rate && !isEditing && <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                    </svg>}
                    {isEditing && <VolmaNumber
                        {...PropertyHelper.GetInputPropertyByNameString(props.Inputs, key) }
                        Name={key}
                        Value={value}
                        MinValue={() => 0}
                        Step={1}
                        Autofocus={true}
                        HideUpDownBtns={true}
                        OnLostFocus={(cancelled: boolean) => endEditing(props.DataHelper.EditingDTO, !cancelled && PropertyHelper.GetInputPropertyByNameString(props.Inputs, key).IsValid)}
                        Unit={unit}
                        Validator={numberValidator}
                        dispatch={props.dispatch} />}
                    {!isEditing && <span className={(VolmaTableCommonCellRenderers._tableCont("text", {hasIcon: entity === ECargoTransporterRouteRateEditingEntity.Rate})).toString()}>{params.cellData}</span>}
                    {!isEditing && <a onClick={() => startEditing(cargoDTO)} className={(VolmaTableCommonCellRenderers._btnEdit({static: true}).mix(["btn"])).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._btnEdit("ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#edit-right"></use>
                        </svg>
                    </a>}
                </div>
            </div>
        )
    }
}