import { injectable } from 'inversify';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import { IEntityDTO } from '../../../../Domain/IEntityDTO';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { volmaBlock } from '../../../../Infrastructure/Services/BEM';
import { Types } from '../../../../Infrastructure/Types';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IChartDataset } from '../IChart';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { IReportService } from './IReportService';

@injectable()
export abstract class ReportService<TReportProps> implements IReportService<TReportProps>{
    public abstract Title: string;
    protected _mainInfo = volmaBlock('main-info');
    protected _authService: AuthenticationService;
    constructor() {
        this.UpdateReportParamsSelectField = this.UpdateReportParamsSelectField.bind(this);
        this.UpdateReportParamsCalendarField = this.UpdateReportParamsCalendarField.bind(this);

        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService)
    }

    public abstract GetParamsEditor(props: IBaseEntityProps<IEntityDTO, IReportHelperProps<TReportProps>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element;
    public FormatDataForChart(items: Array <TReportProps>): any{
        return items;
    }
    public GetChart(data: any){
        return <Doughnut data={ data } />
    }
    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset, index: number){
    }
    public HasChart(): boolean{
        return false;
    }

    protected UpdateReportParamsSelectField(
        state: IBaseEntityProps<IEntityDTO, IReportHelperProps<TReportProps>>, 
        dtoFieldName: string, 
        helperFieldName: string, 
        fieldVal: any, 
        helperVal: any, 
        onReportParamsChanged: (reportType: EReportType, reportParams: any) => void) {
        state.DataHelper.ReportParams[dtoFieldName] = fieldVal;
        onReportParamsChanged(state.DataHelper.SelectedReportType, state.DataHelper.ReportParams);
    }

    protected UpdateReportParamsField(
        state: IBaseEntityProps<IEntityDTO, IReportHelperProps<TReportProps>>, 
        dtoFieldName: string, 
        fieldVal: any, 
        onReportParamsChanged: (reportType: EReportType, reportParams: any) => void) {
        state.DataHelper.ReportParams[dtoFieldName] = fieldVal;
        onReportParamsChanged(state.DataHelper.SelectedReportType, state.DataHelper.ReportParams);
    }

    protected UpdateReportParamsCalendarField(
        state: IBaseEntityProps<IEntityDTO, IReportHelperProps<TReportProps>>, 
        dtoFieldName: string, 
        fieldVal: any,
        onReportParamsChanged: (reportType: EReportType, reportParams: any) => void) {
        state.DataHelper.ReportParams[dtoFieldName] = fieldVal;
        onReportParamsChanged(state.DataHelper.SelectedReportType, state.DataHelper.ReportParams);
    }

    public InitializeParams(props: IBaseEntityProps<ReportDTO, IReportHelperProps<TReportProps>>) : void
    {
        return undefined;
    }
}