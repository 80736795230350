import { injectable } from 'inversify';
import { ROUTE_RATE_RATE_UPDATED, ROUTE_RATE_SETTINGD_LOADED } from '../../../Constants/AppConstants';
import { RouteRateDTO } from '../../../Domain/DTO/RouteRateDTO';
import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { ERateChangeType } from '../../../Domain/Enum/ERateChangeType';
import { ERating } from '../../../Domain/Enum/ERating';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { RouteRateHelperProps } from './IHelperProps';

type IState = IBaseEntityProps<RouteRateDTO, RouteRateHelperProps>;

@injectable()
export class RouteRateReducer extends EntityReducer<RouteRateDTO, RouteRateHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case ROUTE_RATE_SETTINGD_LOADED:
                return this.ReduceSettingsLoaded(state, action.Payload);
            case ROUTE_RATE_RATE_UPDATED:
                return this.ReduceRateUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceSettingsLoaded(state: IState, payload: SettingsDTO): IState {
        return super.EntityLoaded<SettingsDTO>(state, payload, x => x.Settings);
    }

    private ReduceRateUpdated(state: IState, payload: number): IState {
        const newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataDTO.Rate = payload;
        newState.DataHelper.RateList = [
            {
                RateGroup: ERating.A,
                RateValue: state.DataHelper.Settings.RateChangeTypeForAGroup === ERateChangeType.Percent ? 
                    state.DataHelper.Settings.RateChangeForAGroup * payload / 100 + payload : state.DataHelper.Settings.RateChangeForAGroup + payload
            }, 
            {
                RateGroup: ERating.B, 
                RateValue: payload
            }, 
            {
                RateGroup: ERating.C, 
                RateValue: state.DataHelper.Settings.RateChangeTypeForCGroup === ERateChangeType.Percent ? 
                    payload - state.DataHelper.Settings.RateChangeForCGroup * payload / 100 : payload - state.DataHelper.Settings.RateChangeForCGroup
            },
            {
                RateGroup: ERating.Outsiders, 
                RateValue: 0
            }
        ];

        return newState;
    }
}
