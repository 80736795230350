import * as React from 'react';
import { MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { history } from '../../ConfigureStore';
import { ResetPasswordDTO } from '../../Domain/DTO/ResetPasswordDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { IGlobalState } from '../../Reducers/IGlobalState';
import i18next from '../i18n';
import { VolmaComponent } from '../Shared/VolmaComponent';
import VolmaForm from '../VolmaForm/VolmaForm';
import VolmaInput from '../VolmaInput';
import { IVolmaInputProps } from '../VolmaInput/IVolmaInputProps';
import { IPasswordResetProps } from './IPasswordResetProps';
import { PasswordResetActions } from './PasswordResetActions';
import {withRouter } from 'react-router-dom';


class PasswordReset extends VolmaComponent<IPasswordResetProps> {
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _PasswordResetActions: PasswordResetActions;
    private _routerService: RouterService;

    private _modal        = volmaBlock("modal");
    private _modalWrap    = volmaBlock("modal-wrap");
    private _modalCont    = volmaBlock("modal-cont");
    private _modalLogin   = volmaBlock("modal-login");
    private _modalChange  = volmaBlock("modal-change");
    private _lightInput   = volmaBlock("light-input");
    private _modalConfirm = volmaBlock("modal-confirm");
    private _btnModal     = volmaBlock("btn-modal");

    constructor(props: IPasswordResetProps, context: any) {
        super(props, context);

        this._inputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._PasswordResetActions = VolmaContainer.get<PasswordResetActions>(Types.PasswordResetActions);

        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        super.SetActions(this._PasswordResetActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.props.dispatch(this._actions.LoadData(new ResetPasswordDTO()))
    }


    render() {
        if (this.props.DataDTO === undefined)
            return <span></span>;

        return (
            <div className={(this._modal({login: true }).mix("active")).toString()}>
                <a className={(this._modal("logo")).toString()}>
                    <div className={(this._modal("logo-img")).toString()} ></div>
                </a>
                <div className={(this._modalWrap({cont: true })).toString()}>
                    <div className={(this._modalCont).toString()}>
                        <span className={(this._modalCont("title")).toString()}>{i18next.t("passwordreset:Title")}</span>
                        {this.props.DataHelper.IsEmailSentSuccessfully &&
                            <div className={(this._modalConfirm).toString()}>
                                <form className={(this._modalConfirm("form")).toString()}>
                                    <div className={(this._modalConfirm("form-row")).toString()}>
                                        <div className={(this._modalConfirm("form-cell", {full: true })).toString()}>
                                            <div className={(this._modalConfirm("form-text")).toString()}>
                                                {i18next.t('passwordreset:EmailSent')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={(this._modalConfirm("form-row", {center: true })).toString()}>
                                        <div className={(this._modalConfirm("form-cell")).toString()}>
                                            <button onClick={this.onClose} className={(this._btnModal({large: true, green: true }).mix(["btn"])).toString()}>{i18next.t('common:ButtonCancel')}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>}

                        {!this.props.DataHelper.IsEmailSentSuccessfully && <VolmaForm {...this.props as any} ClassName={(this._modalLogin()).toString()}>
                            <div className={(this._modalLogin("row")).toString()}>

                                <VolmaInput
                                    {...PropertyHelper.GetInputPropertyByName(this.props.Inputs, (val: ResetPasswordDTO) => val.Email) }
                                    Name={PropertyHelper.GetPropertyName((val: ResetPasswordDTO) => val.Email)}
                                    Label={i18next.t('passwordreset:Email')}
                                    Value={this.props.DataDTO.Email}
                                    Validator={this._inputValidator}
                                    dispatch={this.props.dispatch} />
                            </div>
                            <div className={(this._modalLogin("row")).toString()}>
                                <button onClick={this.onSubmit} className={(this._btnModal({large: true, orange: true }).mix(["btn"])).toString()}>{i18next.t("passwordreset:RestorePassword")}</button>
                            </div>
                        </VolmaForm>}
                        <div className={(this._modalChange).toString()}>
                            <a onClick={this.OpenLogin.bind(this)} className={(this._modalChange("link")).toString()}>
                                <svg className={(this._modalChange("link-ico").mix("modal-key-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#key"></use>
                                </svg>
                                <span className={(this._modalChange("link-text")).toString()}>{i18next.t("passwordreset:ReturnToLogin")}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected RouterWillLeave() {
        return  true;
    }

    private onSubmit(evt: MouseEvent<HTMLButtonElement>) {
        super.OnSubmit(evt);
        if (this.props.IsValid) {
            this.props.dispatch(this._PasswordResetActions.DoPasswordReset(this.props.DataDTO, i18next.t('common:WaitingForTheServer')))
        }
    }

    private onClose(evt: MouseEvent<HTMLButtonElement>) {
        this.props.dispatch(this._PasswordResetActions.Clear());
        history.push(this._routerService.GetHome());
    }

    private OpenLogin() {
        history.push(this._routerService.GetHome());
    }

}

const translated = withTranslation(['common'])(withRouter(PasswordReset));

function select(state: IGlobalState, ownProps: IPasswordResetProps): IPasswordResetProps {
    return state.PasswordReset;
}

let connectedPasswordReset = connect<IPasswordResetProps, {}, {}>(select)(translated);
export default connectedPasswordReset;