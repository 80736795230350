import { injectable } from 'inversify';
import * as React from 'react';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { MultiFactoryDTO } from '../../../Domain/DTO/MultiFactoryDTO';
import { MultiFactoryTableDTO } from '../../../Domain/DTO/MultiFactoryTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import i18next from '../../i18n';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { InitialMultiFactoryHelperProps, MultiFactoryHelperProps } from './IHelperProps';
import { MultiFactoryReducer } from './MultiFactoryReducer';


@injectable()
export class MultiFactoryEntityService extends BaseEntityService<MultiFactoryDTO, MultiFactoryHelperProps>{
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: MultiFactoryReducer;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._reducer = VolmaContainer.get<MultiFactoryReducer>(Types.MultiFactoryReducer);
    }

    public InitializeEntity(props: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>){
        this._dispatch = props.dispatch;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialMultiFactoryHelperProps;
    }

    public OnAfterDataLoaded(dto: MultiFactoryDTO): void {}


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.MultiFactory]
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.MultiFactory,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: MultiFactoryTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: MultiFactoryTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: MultiFactoryTableDTO) => val.LoadingPoints), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, IsSortable: false, FilterKey: "LoadingPointNames"},
                
            ]
        );
    }

    public GetEditor(props: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetEditorModal(props: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("multifactory:MainInfoTitle"), i18next.t("multifactory:MainInfoTitleDescription"), this.MainInfoItem(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }    

    public GetHeaderEditor(props: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>): JSX.Element{
        const isEditable = this.IsEditable(props);
        return (
            <div className={(this._createCard("cell")).toString()}>
                <VolmaInput
                    {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: MultiFactoryDTO) => val.Name) }
                    Name={PropertyHelper.GetPropertyName((val: MultiFactoryDTO) => val.Name)}
                    Label={i18next.t('multifactory:Name')}
                    Placeholder={i18next.t('multifactory:NamePlaceholder')}
                    Value={props.DataDTO.Name}
                    Validator={this._nonEmptyInputValidator}
                    Required={true}
                    IsInHeader={true}
                    Readonly={!isEditable}
                    dispatch={props.dispatch} />
            </div>
        );
    }
    
    private MainInfoItem(props: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>): JSX.Element {      
        const isEditable = this.IsEditable(props);  
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: MultiFactoryDTO) => val.LoadingPointIds) as any) }
                            Name={PropertyHelper.GetPropertyName((x: MultiFactoryDTO) => x.LoadingPointIds)}
                            Label={i18next.t('multifactory:LoadingPoints')}
                            Placeholder={i18next.t('multifactory:LoadingPointsPlaceholder')}
                            Required={true}
                            AllowMultiselect={true}
                            Entity={EEntityType.LoadingPoint}
                            OptionsFilter={(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory || x.MultiFactoryId === props.DataDTO.Id}
                            Value={props.DataDTO.LoadingPointIds}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}
