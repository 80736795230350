import * as React from 'react';
import { SelectContractHelperProps, InitialSelectContractHelperProps } from './HelperProps';
import { BaseEntityService } from '../../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../../BaseEntity/IBaseEntityProps';
import i18next from '../../../../i18n';
import { IEntityReducer } from '../../../BaseEntity/IEntityReducer';
import { IVolmaTableColumn } from '../../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaContainer } from '../../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../../Infrastructure/Types';
import { volmaBlock } from '../../../../../Infrastructure/Services/BEM';
import VolmaSelect from '../../../../VolmaSelect/index';
import PropertyHelper from '../../../../../Infrastructure/Services/PropertyHelper';
import { SelectContractDTO } from './SelectContractDTO';
import { SelectContractReducer } from './SelectContractReducer';
import { SelectContractActions } from './SelectContractActions';
import { ContractTableDTO } from '../../../../../Domain/DTO/ContractTableDTO';

export class SelectContractEntityService extends BaseEntityService<SelectContractDTO, SelectContractHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _dispatch: any;

    private _reducer: SelectContractReducer;
    private _actions: SelectContractActions;

    private _moreInfo = volmaBlock("more-info");

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._reducer = VolmaContainer.get<SelectContractReducer>(Types.SelectVehicleReducer);
        this._actions = VolmaContainer.get<SelectContractActions>(Types.SelectVehicleActions);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetEditorModal(props: IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SelectContractDTO) => val.SelectedContract) as any) }
                                        Name={PropertyHelper.GetPropertyName((x: SelectContractDTO) => x.SelectedContract)}
                                        Label={i18next.t("selectvehicle:Vehicle")}
                                        Placeholder={i18next.t("selectvehicle:SelectVehicle")}
                                        Options={props.DataHelper.ContractList}
                                        LabelKey={PropertyHelper.GetPropertyName((x: ContractTableDTO) => x.ContractNumber)}
                                        ValueKey={PropertyHelper.GetPropertyName((x: ContractTableDTO) => x.Id)}
                                        Value={props.DataHelper.SelectedContract}
                                        HelperFieldName={PropertyHelper.GetPropertyName((val: SelectContractHelperProps) => val.SelectedContract)}
                                        HelperFieldToDTOFieldTranslator={(x: ContractTableDTO) => x}
                                        Required={true}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>): JSX.Element {
        return (
            <span>
            </span>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<SelectContractDTO, SelectContractHelperProps>): void {
        this._dispatch = props.dispatch;
    }

    public OnAfterDataLoaded(dto: any): void {
    }

    public GetReducer(): IEntityReducer<SelectContractDTO, SelectContractHelperProps> {
        return this._reducer;
    }

    public GetInitialDataHelper(): SelectContractHelperProps {
        return InitialSelectContractHelperProps;
    }
}