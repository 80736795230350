import { EnumService } from './EnumService';
import { DeliveryTableDTO } from '../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ERequestForDeliveryAssigner } from '../../Domain/Enum/ERequestForDeliveryAssigner';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { AuthenticationService } from './AuthService';
import { injectable } from 'inversify';
import { DeliveryStateUpdatedDTO } from '../../Domain/DTO/DeliveryStateUpdatedDTO';
import i18next from '../../Components/i18n';
import { UserDTO } from '../../Domain/DTO/UserDTO';
import { DeliveryStateHelper } from '../../Components/Entity/Delivery/DeliveryState/DeliveryStateHelper';
import PropertyHelper from './PropertyHelper';

@injectable()
export class DeliveryService {
    private _authService   : AuthenticationService;
    private _enumService   : EnumService;
    private _deliveryHelper: DeliveryStateHelper;

    constructor() {
        this._authService    = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._enumService    = VolmaContainer.get<EnumService>(Types.EnumService);
        this._deliveryHelper = VolmaContainer.get<DeliveryStateHelper>(Types.DeliveryStateHelper);
    }

    public GetEntityByDelivery(state: EDeliveryState, assignType: ERequestForDeliveryAssigner): EEntityType[]{
        //[EEntityType.DeliveryFuture, EEntityType.DeliveryActive/*, EEntityType.DeliveryAuction*/, EEntityType.DeliveryPerforming, EEntityType.DeliveryManual, EEntityType.DeliveryFinished, EEntityType.DeliveryWithClaim]
        var entityTypes = new Array();
        if(this._authService.IsShipper()){
            if (state === EDeliveryState.New ||
                state === EDeliveryState.Revoked)
                entityTypes.push(EEntityType.DeliveryFuture);

            if (state === EDeliveryState.Closed ||
                state === EDeliveryState.ClosedOld ||
                state === EDeliveryState.Cancelled ||
                state === EDeliveryState.NotExecuted)
                entityTypes.push(EEntityType.DeliveryFinished);

            if ((state === EDeliveryState.Assignation ||
                state === EDeliveryState.Assigned   ||
                state === EDeliveryState.Accepted) && 
                (assignType === ERequestForDeliveryAssigner.Rating ||
                assignType === ERequestForDeliveryAssigner.Bidding ||
                assignType === ERequestForDeliveryAssigner.Tender))
                entityTypes.push(EEntityType.DeliveryActive);

            if ((state === EDeliveryState.Assigned   ||
                state === EDeliveryState.Accepted) && 
                (assignType === ERequestForDeliveryAssigner.Manual))
                entityTypes.push(EEntityType.DeliveryActive);

            if (state === EDeliveryState.Approved ||
                state === EDeliveryState.VehicleArrivedAtLoadingPoint ||
                state === EDeliveryState.VehicleArrivedAtUnloadingPoint ||
                state === EDeliveryState.LatenessRegistration ||
                state === EDeliveryState.LoadingLatenessRegistration ||
                state === EDeliveryState.LoadingBegin ||
                state === EDeliveryState.LoadingEnd ||
                state === EDeliveryState.Delivery ||
                state === EDeliveryState.Delivered)
                entityTypes.push(EEntityType.DeliveryPerforming);

            // if (state === EDeliveryState.ClaimCreated)
            //     return EEntityType.DeliveryWithClaim;

            if (state === EDeliveryState.Assignation &&
                assignType === ERequestForDeliveryAssigner.Manual)
                entityTypes.push(EEntityType.DeliveryManual);

            if (state === EDeliveryState.Assignation && assignType === ERequestForDeliveryAssigner.Bidding)
                entityTypes.push(EEntityType.DeliveryAuction);

        }
        //[EEntityType.DeliveryToAcceptConfirm, EEntityType.DeliveryAuction, EEntityType.DeliveryPerforming, EEntityType.DeliveryFinished, EEntityType.DeliveryWithClaim]
        else{
            if (state === EDeliveryState.Closed || state === EDeliveryState.ClosedOld)
                entityTypes.push(EEntityType.DeliveryFinished);

            if (state === EDeliveryState.Approved ||
                state === EDeliveryState.VehicleArrivedAtLoadingPoint ||
                state === EDeliveryState.VehicleArrivedAtUnloadingPoint ||
                state === EDeliveryState.LatenessRegistration ||
                state === EDeliveryState.LoadingLatenessRegistration ||
                state === EDeliveryState.LoadingBegin ||
                state === EDeliveryState.LoadingEnd ||
                state === EDeliveryState.Delivery ||
                state === EDeliveryState.Delivered)
                entityTypes.push(EEntityType.DeliveryPerforming);

            // if (state === EDeliveryState.ClaimCreated)
                // return EEntityType.DeliveryWithClaim;

            if (state === EDeliveryState.Assignation && assignType === ERequestForDeliveryAssigner.Bidding)
                entityTypes.push(EEntityType.DeliveryAuction);

            if (state === EDeliveryState.Assigned ||
                state === EDeliveryState.Accepted)
                entityTypes.push(EEntityType.DeliveryToAcceptConfirm);
        }

        if (entityTypes.length === 0)
            entityTypes.push(EEntityType.DeliveryPerforming);
            
        entityTypes.push(EEntityType.DeliveryWithClaim);
        return entityTypes;
    }

    public GetTextForNotification(deliveryState: DeliveryStateUpdatedDTO, currentUser: UserDTO): {Text: string, IsRed: boolean, IsGreen: boolean} {
        let text = undefined;
        let comments = undefined;
        let isRed: boolean = false;
        let isGreen: boolean = false;

        if (this._authService.IsCargoTransporter())
            comments = deliveryState.CommentsForCargotransporter;
        if (this._authService.IsShipper())
            comments = deliveryState.CommentsForShipper

        if (this._authService.IsCargoTransporter() &&
            (deliveryState.DeliveryState === EDeliveryState.Revoked ||
                deliveryState.DeliveryState === EDeliveryState.Cancelled) &&
            deliveryState.PrevCargoTransporterId === currentUser.CargoTransporterId &&
            deliveryState.CargoTransporterId !== currentUser.CargoTransporterId) {
            text = i18next.t("common:DeliveryWasWithdrawn", { identifier: deliveryState.DeliveryIdentifier } as any);
            isRed = true;
        }
        else if (this._authService.IsCargoTransporter() &&
                deliveryState.DeliveryState == EDeliveryState.VehicleArrivedAtLoadingPoint){
            let stateVal = this._enumService.GetOptionLocalized(() => EDeliveryState, deliveryState.DeliveryState);
            text = i18next.t("common:DeliveryUpdatedNoComment", { identifier: deliveryState.DeliveryIdentifier, state: stateVal } as any)
        }
        // else if (this._authService.IsCargoTransporter() &&
        //     deliveryState.DeliveryState == EDeliveryState.Assignation && deliveryState.Assigner == ERequestForDeliveryAssigner.Bidding) {
        //     text = i18next.t("common:BiddingStarted", { identifier: deliveryState.DeliveryIdentifier } as any);
        // }
        else if (deliveryState.IsChangelog) {
            text = i18next.t("common:DeliveryContentUpdated", { identifier: deliveryState.DeliveryIdentifier } as any);
            isRed = true;
        }
        else if (deliveryState.DeliveryState === EDeliveryState.Assigned && this._authService.IsCargoTransporter()) {
            text = i18next.t("common:DeliveryAssigned", { identifier: deliveryState.DeliveryIdentifier } as any)
            isGreen = true;
        }
        else if (deliveryState.DeliveryState === EDeliveryState.Assigned && this._authService.IsShipper()) {
            text = i18next.t("common:DeliveryAssignedShipper", { identifier: deliveryState.DeliveryIdentifier, cargotransporter: deliveryState.CargoTransporterName } as any)
        }

        else if (deliveryState.DeliveryState === EDeliveryState.Accepted && this._authService.IsCargoTransporter()) {
            text = i18next.t("common:DeliveryAccepted", { identifier: deliveryState.DeliveryIdentifier } as any)
            isGreen = true;
        }
        else if (deliveryState.DeliveryState === EDeliveryState.Accepted && this._authService.IsShipper()) {
            text = i18next.t("common:DeliveryAcceptedShipper", { identifier: deliveryState.DeliveryIdentifier } as any)
        }

        else if (deliveryState.DeliveryState === EDeliveryState.Approved && this._authService.IsCargoTransporter()) {
            text = i18next.t("common:DeliveryConfirmed", { identifier: deliveryState.DeliveryIdentifier } as any)
        }
        else if (deliveryState.DeliveryState === EDeliveryState.Approved && this._authService.IsShipper()) {
            text = i18next.t("common:DeliveryConfirmedShipper", { identifier: deliveryState.DeliveryIdentifier } as any)
        }

        else if (deliveryState.DeliveryState === EDeliveryState.Assignation && deliveryState.Assigner === ERequestForDeliveryAssigner.Manual && this._authService.IsShipper()) {
            let enumVal = this._enumService.GetOptionLocalized(() => ERequestForDeliveryAssigner, deliveryState.Assigner);
            text = i18next.t("common:DeliveryAssignationChanged", { identifier: deliveryState.DeliveryIdentifier, assigntype: enumVal } as any)
            isGreen = true;
        }
        else if (deliveryState.DeliveryState === EDeliveryState.NotAccepted || deliveryState.DeliveryState === EDeliveryState.NotApproved) {
            let stateVal = this._enumService.GetOptionLocalized(() => EDeliveryState, deliveryState.DeliveryState);
            text = i18next.t("common:DeliveryUpdatedNoComment", { identifier: deliveryState.DeliveryIdentifier, state: stateVal } as any)
            isRed = true;
        }
        else if (this._deliveryHelper.IsCommentCameFromDeliveryUpdate(comments)) {
            let commentsFormatted = this._deliveryHelper.GetComment(comments, "; ");
            let stateVal = this._enumService.GetOptionLocalized(() => EDeliveryState, deliveryState.DeliveryState);
            text = i18next.t("common:DeliveryUpdated", { identifier: deliveryState.DeliveryIdentifier, state: stateVal, comments: commentsFormatted } as any)
            isRed = true;
        }

        return {Text: text, IsRed: isRed, IsGreen: isGreen};
    }
}