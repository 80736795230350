import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import * as React from 'react';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { NonEmptyTextValidator } from '../../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { DriverReducer } from './DriverReducer';
import { DriverActions } from './DriverActions';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { DriverDTO } from '../../../../Domain/DTO/DriverDTO';
import { DriverLocationTableDTO } from '../../../../Domain/DTO/DriverLocationTableDTO';
import { DriverLocationDTO } from '../../../../Domain/DTO/DriverLocationDTO';
import { DriverProps, InitialDriverHelperProps } from './IHelperProps';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../../VolmaInput/IVolmaInputProps';
import VolmaInput from '../../../VolmaInput';
import VolmaSelect from '../../../VolmaSelect';
import { injectable } from 'inversify';
import { VehicleEntityService } from '../Vehicle/VehicleEntityService';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import ExperimentalVolmaTable from '../../../Table/VolmaTable/ExperimentalVolmaTable';
import i18next from '../../../i18n';
import { SelectVehicleDTO } from './SelectVehicleDialog/SelectVehicleDTO';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { LocalDeleteItemsAction } from '../../../Table/VolmaTable/Actions/LocalDeleteItemsAction';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';
import {VolmaPhoneInput} from '../../../VolmaPhoneNumber/VolmaPhoneNumber'
import { DriverScoreTableDTO } from '../../../../Domain/DTO/DriverScoreTableDTO';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { EDriverScoreKind } from '../../../../Domain/Enum/EDriverScoreKind';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { Link } from 'react-router-dom';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';
import { isDefined, isNullOrUndefined } from '../../../../Infrastructure/Services/Utils';
import { DriverColumnTemplates } from './DriverColumnTemplates';


@injectable()
export class DriverEntityService extends BaseEntityService<DriverDTO, DriverProps>{
    private readonly _inputValidator: BaseValidator<IVolmaInputProps>;
    private readonly _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private readonly _volmaTableService: VolmaTableService;
    private readonly _vehicleService: VehicleEntityService;
    private readonly _lastCoordinateColumns: Array<IVolmaTableColumn>;
    private readonly _driverScoreColumns: Array<IVolmaTableColumn>;

    private readonly _vehicleColumns: Array<IVolmaTableColumn>;
    private readonly _reducer: DriverReducer;
    private readonly _action: DriverActions;
    private readonly _modalActions: VolmaModalActions;

    private _dispatch: any;
    private _currentCargoTransporterId: string;
    private _routerService: RouterService;
    private _driverColumnTemplates: DriverColumnTemplates;

    constructor() {
        super();

        this._formatService = VolmaContainer.get<FormatService>(Types.FormatService);
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer = VolmaContainer.get<DriverReducer>(Types.DriverReducer);
        this._action = VolmaContainer.get<DriverActions>(Types.DriverActions);

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._routerService  = VolmaContainer.get<RouterService>(Types.RouterService);

        this._vehicleService = VolmaContainer.get<VehicleEntityService>(Types.VehicleEntityService);
        this._vehicleColumns = this._vehicleService.GetColumnsList();
        this._lastCoordinateColumns = this.GetLastCoordinateColumnsList();
        this._driverScoreColumns = this.GetDriverScoreColumnsList();
        this._driverColumnTemplates = new DriverColumnTemplates();

    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Driver]
    }

    public GetDriverScoreColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Driver,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DriverScoreTableDTO) => val.Id) },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverScoreTableDTO) => val.Timestamp), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverScoreTableDTO) => val.Kind), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, CellRenderer: this.DriverKindRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: DriverScoreTableDTO) => val.Score), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );
    }

    public GetLastCoordinateColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.DriverLocation,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DriverLocationTableDTO) => val.Id) },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverLocationTableDTO) => val.Timestamp), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverLocationTableDTO) => val.Latitude), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DriverLocationTableDTO) => val.Longitude), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        const commonTemplates = [
            { ...this._driverColumnTemplates.Name },
            { ...this._driverColumnTemplates.PhoneNumber },
            { ...this._driverColumnTemplates.Details },
        ];

        if (this._authService.IsIncludedCargoTransporter(false)) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Driver,
                [
                    { ...this._driverColumnTemplates.Id },
                    ...commonTemplates,
                ]
            );
        }

        if (this._authService.IsIncludedCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Driver,
                [
                    { ...this._driverColumnTemplates.Id },
                    { ...this._driverColumnTemplates.CargoTransporterName },                    
                    ...commonTemplates,
                ]
            );
        }

        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Driver,
            [
                { ...this._driverColumnTemplates.Id },
                { ...this._driverColumnTemplates.MultiTransporterName },
                ...commonTemplates,
            ]
        );

    }

    public DriverKindRenderer = (props: IVolmaTableProps<any>, params: IVolmaTableCellRendererParams) => {
        const dto = params.rowData as DriverScoreTableDTO
        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        const kindTranslate = i18next.t(`enum:EDriverScoreKind_${EDriverScoreKind[dto.Kind]}`)
        return  <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected }).toString()}
                    style={style}
                    key={key} >
        <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>
                {kindTranslate}
                { dto.Kind !== EDriverScoreKind.ProfileFilled &&
                    <span> (
                        <Link to={this._routerService.GetCertainEntityPathname(EEntityType.DeliveryPerforming, dto.DeliveryId)}>{dto.DeliveryIdentifier}</Link>
                        )</span>
                }
            </span>
        </div>
    </div>
    }

    public GetEditorModal(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.GetHeaderBlock(props))}
                    {this.GetMainInfoNoTitleItem(false, true, this.DetailsPart(props))}
                    {this.CanViewCargoTransporterPart && this.GetMainInfoNoTitleItem(true, false, this.CargoTransporterPart(props))}
                </div>
            </div>
        );
    }   

    public GetEditor(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        if (this.IsEditable(props))
            return this.GetDriverEditor(props);
        return this.GetReadonlyDriverEditor(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        let isEditable = this.IsEditable(props);

        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.Name)}
                        Label={i18next.t('driver:Name')}
                        Placeholder={i18next.t('driver:NamePlaceholder')}
                        Required={true}
                        IsInHeader={true}
                        Value={props.DataDTO.Name}
                        Validator={this._nonEmptyInputValidator}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
                <div className={(this._createCard("cell")).toString()}>
                        <VolmaPhoneInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.PhoneNumber) }
                            Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.PhoneNumber)}
                            Label={i18next.t('driver:PhoneNumber')}
                            Value={props.DataDTO.PhoneNumber}
                            Validator={this._nonEmptyInputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />

                </div>
            </div>
        );
    }

    private GetHeaderBlock(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.Name) }
                            Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.Name)}
                            Label={i18next.t('driver:Name')}
                            Value={props.DataDTO.Name}
                            Validator={this._nonEmptyInputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaPhoneInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.PhoneNumber) }
                            Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.PhoneNumber)}
                            Label={i18next.t('driver:PhoneNumber')}
                            Value={props.DataDTO.PhoneNumber}
                            Validator={this._nonEmptyInputValidator}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<DriverDTO, DriverProps>) {
        this._dispatch = props.dispatch;

        if (props.match.params !== undefined) {
            const entity = props.match.params.entity;
            const id = props.match.params.id;

            if (entity === EEntityType[EEntityType.CargoTransporter].toLowerCase())
                this._currentCargoTransporterId = id;
            else
                this._currentCargoTransporterId = undefined;
        }

        if (this._authService.IsCargoTransporter()) {
            this._currentCargoTransporterId = this._authService.GetCurrentUser().CargoTransporterId;
        }

    }

    public InitializeDefaultDTOValues(dto: DriverDTO) {        
        const isNewItemCreation = !this.IsEntityEditCase(dto.Id);
         
        if (isDefined(this._currentCargoTransporterId) && this._currentCargoTransporterId !== dto.CargoTransporterId && isNewItemCreation){
            dto.CargoTransporterId = this._currentCargoTransporterId;          
        }
    }

    public GetInitialDataHelper() {
        return InitialDriverHelperProps;
    }

    public OnAfterDataLoaded(dto: DriverDTO) {
        this._dispatch(this._action.LoadVehicles(dto.Id, i18next.t("common:LoadingVehiclesTableData")));
        this._dispatch(this._action.LoadCoordinates(dto.Id, i18next.t("common:LoadingCoordinatesTableData")))
        this._dispatch(this._action.LoadDriverScore(dto.UserId, i18next.t("common:LoadingCoordinatesTableData")))

    }

    public GetReducer() {
        return this._reducer;
    }

    public AddVehicle(props: IBaseEntityProps<DriverDTO, DriverProps>) {
        let dto = new SelectVehicleDTO();
        dto.SelectedVehiclesIds = props.DataDTO.Vehicles;
        this._dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.SelectVehicle, dto, undefined, (dto) => {
            this._dispatch(this._action.VehicleAddedToDriver(dto.SelectedVehicle));
        }))
    }

    public CreateAndAddVehicle() {
        this._dispatch(this._modalActions.OpenServerEntityModal(EEntityType.Vehicle, undefined, undefined, (dto) => {
            this._dispatch(this._action.VehicleAddedToDriver(dto));
        }))
    }

    private DeleteVehicles(ids: string[]) {
        this._dispatch(this._action.VehicleRemovedFromDriver(ids));
    }


    private GetDriverEditor(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("driver:MainInfoTitle"), i18next.t("driver:MainInfoTitleDescription"), this.DetailsPart(props), undefined, props, this._dispatch)}


                    {this.CanViewCargoTransporterPart && <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.CargoTransporterPart(props))}
                    </div>}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("driver:VehiclesTitle"), i18next.t("driver:VehiclesTitleDescription"), this.VehiclesPart(props), [this.AddVehicleButton(props, 0), this.CreateAndAddVehicleButton(props, 1)], props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 2, i18next.t("driver:VehiclesCoordinatesTitle"), i18next.t("driver:VehiclesCoordinatesDescription"), this.LastCoordinatesTable(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 3, i18next.t("driver:DriverScoreTitle"), i18next.t("driver:DriverScoreDescription"), this.ScoreHistoryTable(props), undefined, props, this._dispatch)}
                </div>

            </div>
        );
    }

    private GetReadonlyDriverEditor(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("driver:MainInfoTitle"), i18next.t("driver:MainInfoTitleDescription"), this.DetailsPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("driver:VehiclesTitle"), i18next.t("driver:VehiclesTitleDescription"), this.VehiclesPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 2, i18next.t("driver:VehiclesCoordinatesTitle"), i18next.t("driver:VehiclesCoordinatesDescription"), this.LastCoordinatesTable(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 3, i18next.t("driver:DriverScoreTitle"), i18next.t("driver:DriverScoreDescription"), this.ScoreHistoryTable(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    private DetailsPart(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.Details) }
                            Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.Details)}
                            Type="textarea"
                            Label={i18next.t('driver:Details')}
                            Value={props.DataDTO.Details}
                            Validator={this._inputValidator}
                            Required={false}
                            MaxLength={4000}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>

                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverDTO) => val.Contacts) }
                            Name={PropertyHelper.GetPropertyName((val: DriverDTO) => val.Contacts)}
                            Type="textarea"
                            Label={i18next.t('driver:DriverContacts')}
                            Value={props.DataDTO.Contacts}
                            Validator={this._inputValidator}
                            Required={false}
                            MaxLength={4000}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private CargoTransporterPart(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 50: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DriverDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: DriverDTO) => x.CargoTransporterId)}
                            Label={i18next.t("driver:CargoTransporterId")}
                            Placeholder={i18next.t("driver:SelectCargoTransporter")}
                            Entity={EEntityType.CargoTransporter}
                            Value={props.DataDTO.CargoTransporterId}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private get CanViewCargoTransporterPart(): boolean {
        return this._authService.IsAdministrator() || this._authService.IsCargoTransporter();
    }

    private ScoreHistoryTable(props: IBaseEntityProps<DriverScoreTableDTO, DriverProps>) {
        return (
        <div className={(this._mainInfo("row")).toString()}>
            <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                <div className={this._mainInfo("line", { fill: true }).toString()}>
                    <ExperimentalVolmaTable
                        {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DriverScoreTableDTO) => val.Id) }
                        Name={PropertyHelper.GetPropertyName((x: DriverScoreTableDTO) => x.Id)}
                        Columns={this._driverScoreColumns}
                        Data={props.DataHelper.DriverScore}
                        DataOriginal={props.DataHelper.DriverScoreOriginal}
                        LoadDataFromServer={false}
                        IsTableInsideEntity={true}
                        dispatch={props.dispatch}
                         />
                </div>
            </div>
        </div>
        )
    }

    private LastCoordinatesTable(props: IBaseEntityProps<DriverLocationDTO, DriverProps>) {
        const data = props.DataHelper.LastCoordinates.map((el) => ({...el, Timestamp: this._formatService.GetSafeDateTimeDashed(el.Timestamp)}))

        return (
        <div className={(this._mainInfo("row")).toString()}>
            <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                <div className={this._mainInfo("line", { fill: true }).toString()}>
                    <ExperimentalVolmaTable
                        {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DriverLocationDTO) => val.DriverId) }
                        Name={PropertyHelper.GetPropertyName((x: DriverLocationDTO) => x.DriverId)}
                        Columns={this._lastCoordinateColumns}
                        Data={data}
                        DataOriginal={props.DataHelper.LastCoordinatesOriginal}
                        LoadDataFromServer={false}
                        IsTableInsideEntity={true}
                        dispatch={props.dispatch}
                         />
                </div>
            </div>
        </div>
        )
    }

    private VehiclesPart(props: IBaseEntityProps<DriverDTO, DriverProps>): JSX.Element {
        let actions = [];
        if(this.IsEditable(props))
            actions = [new LocalDeleteItemsAction<DriverProps>((ids: Array<string>) => { this.DeleteVehicles(ids) })];
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DriverDTO) => val.Vehicles) }
                            Name={PropertyHelper.GetPropertyName((x: DriverDTO) => x.Vehicles)}
                            Columns={this._vehicleColumns}
                            Data={props.DataHelper.Vehicles}
                            DataOriginal={props.DataHelper.VehiclesOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            dispatch={props.dispatch}
                            Actions={actions}/>
                    </div>
                </div>
            </div>
        );
    }

    private AddVehicleButton(props: IBaseEntityProps<DriverDTO, DriverProps>, key: number): JSX.Element {
        return (
            <a key={key} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}
            onClick={this.AddVehicle.bind(this, props)}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("driver:AddVehicle")}</span>
            </a>
        );
    }
    private CreateAndAddVehicleButton(props: IBaseEntityProps<DriverDTO, DriverProps>, key: number): JSX.Element {
        return (
            <a key={key} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()} onClick={this.CreateAndAddVehicle.bind(this, props)}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("driver:CreateAndAddVehicle")}</span>
            </a>
        );
    }
}
