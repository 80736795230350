import { parse } from 'query-string';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { DriverLocationDTO } from '../../../Domain/DTO/DriverLocationDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { MultiCargoTransporterTableDTO } from '../../../Domain/DTO/MultiCargoTransporterTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { TableDataDTO } from '../../../Domain/TableDataDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { FilterHelper } from '../../../Infrastructure/Services/FilterHelper';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import i18next from '../../i18n';
import { BaseCustomLoadSaveComponent } from '../BaseCustomLoadSave/BaseCustomLoadSaveComponent';
import { DriverMonitoringActions } from './DriverMonitoringActions';
import { DriverMonitoringEntityService } from './DriverMonitoringEntityService';
import { IDriverMonitoringProps } from './IDriverMonitoringProps';
import { DeliveryDriverMonitoringDTO, DeliveryMonitoringQuery, MultiTransporterDtoFields } from './IHelperProps';

class DriverMonitoring extends BaseCustomLoadSaveComponent<IDriverMonitoringProps> {
    private _driverMonitoringEntityService: DriverMonitoringEntityService;
    private _driverMonitoringActions : DriverMonitoringActions;

    constructor(props: IDriverMonitoringProps, context: any) {
        super(props, context, EEntityType.DriverMonitoring);

        this._key = "driverMonitoring";
        this._driverMonitoringActions = VolmaContainer.get<DriverMonitoringActions>(Types.DriverMonitoringActions);
        this._driverMonitoringEntityService = VolmaContainer.get<DriverMonitoringEntityService>(Types.DriverMonitoringEntityService);

        super.SetActions(this._driverMonitoringActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        this._driverMonitoringEntityService.DisposeEntity();
    }

    public componentDidMount() {
        super.componentDidMount();
    }

    protected RouterWillLeave() {
        return true;
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }   

    public LoadData() {
        const query = parse(this.props.location.search) as DeliveryMonitoringQuery;

        this.props.dispatch(this._driverMonitoringActions.LoadDeliveriesByQuery(i18next.t('common:LoadingTableData'), query));

        if (query.cargoTransporter || query.multiCargoTransporter) {
            this.props.dispatch(this._driverMonitoringActions.LoadDriversByQuery(i18next.t('common:LoadingDriversTable'), query));
        }        

        this.LoadSelectedMultiTransportersByQuery(query);
        this.LoadSelectedDriversByQuery(query);
        
        this._driverMonitoringEntityService.InitializeEntity(this.props);
        this.props.dispatch(this._driverMonitoringActions.SetEntityType(EEntityType.DriverMonitoring));
    }

    public IsEditable(): boolean {
        return false
    }

    private LoadSelectedMultiTransportersByQuery(query: DeliveryMonitoringQuery): void {
        const { cargoTransporter, multiCargoTransporter } = query;
        const id = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Id);
        const cargoTransporterId = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Id);
        const multiCargoTransporterId = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Id);

        if (cargoTransporter) {            
            this.CreateFilterAndLoadEntities(
                EEntityType.CargoTransporter, 
                cargoTransporter, 
                id,
                (entity: TableDataDTO<CargoTransporterTableDTO>) => this.OnLoadMultiTransporters(entity, cargoTransporterId)
            );
        }

        if (multiCargoTransporter) {
            this.CreateFilterAndLoadEntities(
                EEntityType.MultiCargoTransporter, 
                multiCargoTransporter,
                id,
                (entity: TableDataDTO<MultiCargoTransporterTableDTO>) => this.OnLoadMultiTransporters(entity, multiCargoTransporterId)                    
            );
        }
    }

    private LoadSelectedDriversByQuery(query: DeliveryMonitoringQuery): void {
        const { driver } = query;

        if (driver) {
            this.CreateFilterAndLoadEntities(
                EEntityType.Driver, 
                driver, 
                PropertyHelper.GetPropertyName((x: DriverLocationDTO) => x.Id), 
                (entity: TableDataDTO<DriverTableDTO>) => 
                    this.props.dispatch(this._driverMonitoringActions.DriversSelected([...entity.Items])));
        }
    }

    private OnLoadMultiTransporters(entity: TableDataDTO<CargoTransporterTableDTO | MultiCargoTransporterTableDTO>, key: string) {
        const idx = MultiTransporterDtoFields.indexOf(key);
        const multiCargoTransporters = entity.Items.map(i => { return { ...i, EntityIdx: idx } });

        return this.props.dispatch(this._driverMonitoringActions.MultiTransportersSelected([
            ...this.props.DataHelper?.SelectedMultiTransporters, 
            ...multiCargoTransporters
        ]));
    }

    private CreateFilterAndLoadEntities<T>(entity: EEntityType, filterQuery: string, filterKey: string, callback: (entity: TableDataDTO<T>) => void) {
        const filter = FilterHelper.CreateFilterByInputQuery(filterQuery, filterKey);
            
        this.props.dispatch(this._driverMonitoringActions.LoadEntities(
            entity,                 
            i18next.t('common:Loading'),
            [filter],
            (entity: TableDataDTO<T>) => callback(entity)
        ));
    }
}

const translated = withTranslation(['common'])(withRouter(DriverMonitoring));

function select(state: IGlobalState, ownProps: IDriverMonitoringProps): IDriverMonitoringProps {
    return state.DriverMonitoring;
}

const connectedDriverMonitoringEntity = connect<IDriverMonitoringProps, {}, {}>(select)(translated);
export default connectedDriverMonitoringEntity;
