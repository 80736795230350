import i18next from '../../Components/i18n';
import { IVolmaTimeTimeDTOProps } from '../../Components/VolmaTimeTimeDTO/IVolmaTimeTimeDTOProps';
import ValidationResult from "./ValidationResult";
import { VolmaBaseTimeValidator } from "./VolmaBaseTimeValidator";
import { isNullOrUndefined } from '../Services/Utils';

export class VolmaTimeTimeDTOValidator extends VolmaBaseTimeValidator<IVolmaTimeTimeDTOProps> {

    protected MustBeNonEmptyRule(entity: IVolmaTimeTimeDTOProps): ValidationResult {
        const entityValueIsNotDefined = entity.Value === undefined || entity.Value.Hours === undefined || entity.Value.Minutes === undefined;

        if (entityValueIsNotDefined) {
            return entity.Required ? ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty")) : ValidationResult.Success();
        }

        return ValidationResult.Success();
    }

    protected HoursAndMinutesRule(entity: IVolmaTimeTimeDTOProps): ValidationResult | undefined {
        if (isNullOrUndefined(entity.Value)) {
            return undefined;
        }

        const { Hours: hours, Minutes: minutes, IsNegative: isNegative } = entity.Value;

        if (hours > 0 && isNegative)
            return ValidationResult.Fail(i18next.t("validate:HoursMustBeNonNegative"));
        if (hours > 23)
            return ValidationResult.Fail(i18next.t("validate:HoursMustBeLessThan24"));
        if (minutes < 0)
            return ValidationResult.Fail(i18next.t("validate:MinutesMustBeNonNegative"));
        if (minutes > 59)
            return ValidationResult.Fail(i18next.t("validate:MinutesMustBeLessThan60"));

        return ValidationResult.Success();
    }
}