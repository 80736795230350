import { DeliveryTableDTO } from "../../../../Domain/DTO/DeliveryTableDTO";
import { ListIntOptionDTO } from "../../../../Domain/DTO/ListIntOptionDTO";
import { EDeliveryState } from "../../../../Domain/Enum/EDeliveryState";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { ERequestForDeliveryAssigner } from "../../../../Domain/Enum/ERequestForDeliveryAssigner";
import { VolmaContainer } from "../../../../Infrastructure/InversifyInject";
import { RouterService } from "../../../../Infrastructure/Router/RouterService";
import { DeliveryService } from "../../../../Infrastructure/Services/DeliveryService";
import { EnumService } from "../../../../Infrastructure/Services/EnumService";
import PropertyHelper from "../../../../Infrastructure/Services/PropertyHelper";
import { Types } from "../../../../Infrastructure/Types";
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumnDefinition,
    IVolmaTableProps,
} from "../../../Table/VolmaTable/IVolmaTableProps";
import { VolmaTableCommonCellRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers";
import { VolmaTableDeliveryCellRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers";
import { VolmaTableHeaderRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers";
import { VolmaTableCommonColumnDefinition } from "../../../Table/VolmaTableCommonColumnDefinition";

export class DeliveryColumnTemplates {
    private _deliveryService: DeliveryService;
    private _routerService: RouterService;
    private _enumService: EnumService;

    constructor() {
        this._deliveryService = VolmaContainer.get<DeliveryService>(Types.DeliveryService);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
        this._enumService = VolmaContainer.get<EnumService>(Types.EnumService);
    }

    public get Id(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Id),
            HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer,
            Weight: 0.5,
        };
    }

    public get Identifier(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Identifier),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
            CellRenderer: (props, params) =>
                VolmaTableDeliveryCellRenderers.DeliveryIdentifierLinkCellRenderer(
                    props,
                    params,
                    this._deliveryService,
                    this._routerService
                ),
            Weight: 0.5,
        };
    }

    public get LoadingDate(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingDate),
            HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryTableLoadingPointDateCellRenderer,
            FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingDateForFiltering),
            SortKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingDateForFiltering),
            Weight: 0.7,
        };
    }

    public get UnloadingDate(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingDate),
            HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryTableUnloadingPointDateCellRenderer,
            FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingDateForFiltering),
            SortKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingDateForFiltering),
            Weight: 0.7,
        };
    }

    public get UnloadingPointName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingPointName),
            FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LocalityId),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.IconUnloadingPointCellRenderer,
            Entity: EEntityType.Locality,
            Weight: 0.9,
        };
    }

    public get AssignType(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.AssignType),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            CellRenderer: (props: IVolmaTableProps<{}>, cellRendererParams: IVolmaTableCellRendererParams) =>
                VolmaTableCommonCellRenderers.EnumCellRenderer(
                    () => ERequestForDeliveryAssigner,
                    this._enumService,
                    props,
                    cellRendererParams),            
            FilterEnum: () => ERequestForDeliveryAssigner,
            OptionsFilter: (x: ListIntOptionDTO) =>
                x.Id === ERequestForDeliveryAssigner.Tender ||
                x.Id === ERequestForDeliveryAssigner.Rating ||
                x.Id === ERequestForDeliveryAssigner.Bidding,
            FilterKey: "Assigner",
            SortKey: "Assigner",
            Weight: 0.7,
        };
    }

    public get State(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.State),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            CellRenderer: (props: IVolmaTableProps<{}>, cellRendererParams: IVolmaTableCellRendererParams) =>
                VolmaTableDeliveryCellRenderers.DeliveryTableStateCellRenderer(
                    this._enumService,
                    props,
                    cellRendererParams),
            FilterEnum: () => EDeliveryState,
            FilterKey: "DeliveryState",
            SortKey: "DeliveryState",
            OptionsFilter: (x: ListIntOptionDTO) =>
                x.Id === EDeliveryState.Assignation ||
                x.Id === EDeliveryState.Assigned ||
                x.Id === EDeliveryState.Accepted,
            Weight: 0.7,
        };
    }

    public get SalesDepartment(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.SalesDepartment),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
            CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined,
            Weight: 0.7,
        };
    }

    public get NextStateDate(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.NextStateDate),
            HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryLeftTimeCellRenderer,
            Weight: 0.7,
        };
    }

    public get Bet(): IVolmaTableColumnDefinition {
        return {
            DataKey: "Bet",
            CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryBetCellRenderer,
            IsSortable: false,
            Weight: 1.5,
        };
    }
    public get ActualRate(): IVolmaTableColumnDefinition {
        return { 
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.ActualRate), 
            HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer, 
            CellRenderer: VolmaTableCommonCellRenderers.DefaultCellRenderer,
            Weight: 0.7
        };
    }

    public get StateTimestamp(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.StateTimestamp),
            HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.DeliveryDateCellRenderer,
            FilterKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.StateTimestampForFiltering),
            SortKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.StateTimestampForFiltering),
            Weight: 0.7,
        };
    }

    public get MultiPointName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiPointColumn({
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingPointName),
            Weight: 0.9,
        });
    }

    public get MultiTransporterName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiTransporterColumn({
            DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.CargoTransporterName),
            Weight: 0.7,
        });
    }
}
