import { injectable } from "inversify";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IVolmaTableColumn } from "../../../Table/VolmaTable/IVolmaTableProps";
import { DeliveryEntityService } from "../DeliveryEntityService";

@injectable()
export class DeliveryWithClaimEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if (this._authService.IsCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
                { ...this._deliveryColumnTemplates.Id },
                { ...this._deliveryColumnTemplates.Identifier },
                { ...this._deliveryColumnTemplates.LoadingDate },
                { ...this._deliveryColumnTemplates.MultiPointName },
                { ...this._deliveryColumnTemplates.UnloadingDate },
                { ...this._deliveryColumnTemplates.UnloadingPointName },
            ]);
        }

        return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
            { ...this._deliveryColumnTemplates.Id },
            { ...this._deliveryColumnTemplates.Identifier },
            { ...this._deliveryColumnTemplates.LoadingDate },
            { ...this._deliveryColumnTemplates.MultiPointName },
            { ...this._deliveryColumnTemplates.UnloadingDate },
            { ...this._deliveryColumnTemplates.UnloadingPointName },
            { ...this._deliveryColumnTemplates.MultiTransporterName, Weight: 0.8 },
        ]);
    }
}
