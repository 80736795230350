import { injectable } from 'inversify';
import * as React from 'react';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import i18next from '../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaInput from '../../VolmaInput/VolmaInput';
import VolmaMapWithSelect from "../../VolmaMapWithSelect";
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ILocalityHelperProps, InitialLocalityHelperProps } from './IHelperProps';
import { LocalityActions } from './LocalityActions';
import { LocalityReducer } from './LocalityReducer';


@injectable()
export class LocalityEntityService extends BaseEntityService<LocalityDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _actions: LocalityActions;
    private _reducer: LocalityReducer;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);

        this._actions = VolmaContainer.get<LocalityActions>(Types.LocalityActions);
        this._reducer = VolmaContainer.get<LocalityReducer>(Types.LocalityReducer);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Locality]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Locality,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.ZoneName), 
                    FilterKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.ZoneId), 
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer, 
                    Entity: EEntityType.Zone},
                    
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: LocalityTableDTO) => val.HasGeoCoordinates), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, 
                    CellRenderer: this.GeoCoordinateRenderer,
                    IsFilterable: false,
                    IsSortable: true
                },
            ]
        );
    }

    public GeoCoordinateRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        const cellData = params.cellData as LocalityTableDTO['HasGeoCoordinates'];

        let result: string = ""
        if(cellData === undefined || cellData === null || cellData === false) {
            result = i18next.t("common:NoData");
        } else {
            result =  i18next.t("common:FullData");
        }
        

        return (
            <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected })} style={style} key={key}>
                <div className={VolmaTableCommonCellRenderers._tableCont()}>
                    <span className={VolmaTableCommonCellRenderers._tableCont("text")}>{result}</span>
                </div>

            </div>
        )
    }



    public GetEntityId(props: IBaseEntityProps<LocalityTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: LocalityTableDTO) => x.Code),
            (x: LocalityTableDTO, id: string) => x.Code.toLowerCase() === id);
    }

    public GetEditorModal(props: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={this._mainInfo()}>
                {this.GetMainInfoNoDropItem(false, true, i18next.t("volmamap:Map"), 
                        <VolmaMapWithSelect
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LocalityDTO) => val.GeoCoordinate) }
                            Name={PropertyHelper.GetPropertyName((val: LocalityDTO) => val.GeoCoordinate)}
                            Label={i18next.t('locality:MainInfoTitle')}
                            Placeholder={i18next.t('locality:MainInfoTitle')}
                            Value={props.DataDTO.GeoCoordinate}
                            Validator={this._nonEmptyInputValidator}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />)}

                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("locality:MainInfoTitle"), i18next.t("locality:MainInfoDescription"), this.GetMainInfoBlock(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }


    public GetHeaderEditor(props: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>): JSX.Element {
        const isEditable = this.IsGodMode(props);        
        return (
            <div>
                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LocalityDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: LocalityDTO) => val.Code)}
                        Label={i18next.t('locality:Code')}
                        Placeholder={i18next.t('locality:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>

                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: LocalityDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: LocalityDTO) => val.Name)}
                        Label={i18next.t('locality:Name')}
                        Placeholder={i18next.t('locality:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>) {
        this._dispatch = props.dispatch;
        this._dispatch(this._actions.LoadAllZones(i18next.t("common:Loading")))
    }

    public GetReducer() {
        return this._reducer;
    }

    public OnAfterDataLoaded(dto: LocalityDTO): void {
    }

    public GetInitialDataHelper() {
        return InitialLocalityHelperProps;
    }

    private GetMainInfoBlock(props: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={this._mainInfo("row")}>
                <div className={this._mainInfo("cell")}>
                    <div className={this._mainInfo("line", { full: true })}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: LocalityDTO) => val.ZoneId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: LocalityDTO) => x.ZoneId)}
                            Label={i18next.t("locality:ZoneId")}
                            Placeholder={i18next.t("locality:SelectZone")}
                            Entity={EEntityType.Zone}
                            Value={props.DataDTO.ZoneId}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}