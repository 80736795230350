import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { DriverLocationTableDTO } from '../../../../Domain/DTO/DriverLocationTableDTO';
import { DriverScoreDTO } from '../../../../Domain/DTO/DriverScoreDTO';

export class DriverHelperProps{
    Vehicles: Array<VehicleTableDTO>
    VehiclesOriginal: Array<VehicleTableDTO>
}

export class LastCoordinatesHelperProps {
    LastCoordinates: Array<DriverLocationTableDTO>
    LastCoordinatesOriginal: Array<DriverLocationTableDTO>
} 

export class DriverScoreProps {
    DriverScore: Array<DriverScoreDTO>
    DriverScoreOriginal: Array<DriverScoreDTO>
}

export type DriverProps = DriverHelperProps & LastCoordinatesHelperProps & DriverScoreProps

export const InitialDriverHelperProps: DriverProps = <DriverProps>{
     Vehicles: [], VehiclesOriginal: [], LastCoordinates: [], LastCoordinatesOriginal: [], DriverScore: [], DriverScoreOriginal: []
};