import { EEntityType } from './Domain/Enum/EEntityType';
import { VolmaContainer } from './Infrastructure/InversifyInject';
import * as route from './Infrastructure/Router/RouterConstants';
import { RouterService } from './Infrastructure/Router/RouterService';
import { AuthenticationService } from './Infrastructure/Services/AuthService';
import { EntityService } from './Infrastructure/Services/EntityService';
import { TimeService } from './Infrastructure/Services/TimeService';
import { Types } from './Infrastructure/Types';

// так было надо
const entityService = VolmaContainer.get<EntityService>(Types.EntityService);
const routerService = VolmaContainer.get<RouterService>(Types.RouterService);

export const authService   = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
export const defaultUnauth = routerService.GetPathname(route.LOGIN);
export const defaultAuth   = entityService.GetEntityTableRoute(EEntityType.DeliveryPerforming);
export const timeService = VolmaContainer.get<TimeService>(Types.TimeService);