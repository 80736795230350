import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { history } from '../../../ConfigureStore';
import { EntityUpdatedDTO } from '../../../Domain/DTO/EntityUpdatedDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import { IEntityService } from '../../Entity/BaseEntity/IEntityService';
import Header from '../../Header';
import i18next from '../../i18n';
import { VolmaComponent } from '../../Shared/VolmaComponent';
import ExperimentalVolmaTable from '../VolmaTable/ExperimentalVolmaTable';
import { IVolmaTableAction, IVolmaTableColumn, IVolmaTableProps } from '../VolmaTable/IVolmaTableProps';
import { BaseTableActions } from './BaseTableActions';
import { IBaseTableProps } from './IBaseTableProps';

class BaseTable extends VolmaComponent<IBaseTableProps<any>>{
    private _entityService: EntityService;
    private _entity: EEntityType;
    private _tableEntity: EEntityType;
    private _baseEntity: EEntityType;
    private _disableItemEdit: boolean;
    private _columns: Array<IVolmaTableColumn>;
    private _onEntityUpdated: (dto: EntityUpdatedDTO, debounce:() => void, dispatchFilteredData:() => void) => void;
    private _headerTitle: string;
    private _addEntity: string;
    private _canAddEntity: boolean;
    private _tableActions: Array<IVolmaTableAction<any>>;
    private _subsidiaryLinks: Array<JSX.Element>;
    private _baseActions: BaseTableActions;
    private _currentEntityService: IEntityService<any, any>;
    private _beforeTableDataLoaded: (props: IVolmaTableProps<any>) => void;
    private _afterTableDataLoaded: (data: Array<ITableDTO>) => Array<ITableDTO> | undefined;
    private _alternateRowClass: (rowData: any) => string;
    private _itemsPerPage: number;

    private _header      = volmaBlock('header');
    private _headerMulti = volmaBlock('header-multi');
    private _btn         = volmaBlock("btn");
    private _btnGreen    = volmaBlock("btn-green");
    private _btnTable    = volmaBlock("btn-table");

    constructor(props: IBaseTableProps<any>, context: any) {
        super(props, context);

        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._baseActions = VolmaContainer.get<BaseTableActions>(Types.BaseTableActions);

        super.SetActions(this._baseActions);
    }

    public UNSAFE_componentWillMount() {
        this.InitializeTable();
        super.UNSAFE_componentWillMount();
    }

    public componentWillUnmount() {
        if (this._currentEntityService !== undefined) {
            this._currentEntityService.DisposeTable();
        }
        super.componentWillUnmount();
    }

    public componentDidUpdate(prevProps: IBaseTableProps<any>) {
        let oldEntity = prevProps.match.params.entity
        let newEntity = this.props.match.params.entity
        if (newEntity !== oldEntity) {
            this.InitializeTable();
        }
    }


    public render() {
        return (
            <div className="wrapper">
                <header className={(this._header()).toString()}>
                    <Header />

                    <div className={this._headerMulti({ visible: (this._subsidiaryLinks !== undefined && this._subsidiaryLinks.length >  0) || this._canAddEntity})}>
                        <div className={(this._headerMulti("box")).toString()}>
                            <ul className={(this._headerMulti("list")).toString()}>
                                {this._canAddEntity && <li className={(this._headerMulti("list-item", {active: true, add: true })).toString()}>
                                    <a onClick={this.OnAddEntityClick.bind(this)} className={this._headerMulti("list-val", {flex: true}).mix([this._btnGreen({ bg: true, green: true })])}>
                                        <svg className="bg-icon bg-plus-ico">
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                                        </svg>
                                        <span className="btn__text">{i18next.t(this._addEntity)}</span>
                                    </a>
                                </li>}
                                {this._subsidiaryLinks}
                            </ul >
                        </div >
                        {/*<a className={(this._headerMulti("add").mix(["btn", "btn-green", "btn-add"])).toString()}>
                            <svg className={(this._btn("ico").mix(["btn-plus-ico"])).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                            </svg>
                        </a >*/}
                    </div >
                </header>

                <main className="content">
                    <div className='content-wrap' style={this.props.style}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByNameString(this.props.Tables, "table") }
                            Name={"table"}
                            {...this.props}
                            ItemsPerPage={this._itemsPerPage}
                            LoadDataFromServer={true}
                            Columns={this._columns}
                            Actions={this._tableActions}
                            Entity={this._tableEntity}
                            BaseEntity={this._baseEntity}
                            ReloadOnSignalREvent={true}
                            DisableItemEdit={this._disableItemEdit}
                            OnBeforeDataLoaded={this._beforeTableDataLoaded}
                            OnAfterDataLoaded={this._afterTableDataLoaded}
                            AlternateRowClass={this._alternateRowClass}
                            IsFilterEnabled={true}
                            OnEntityUpdated = {this._onEntityUpdated}>
                        </ExperimentalVolmaTable>
                    </div>
                </main>
            </div>

        );
    }

    protected RouterWillLeave() {
        return true;
    }

    private OnAddEntityClick() {
        history.push(this._entityService.GetEntityRoute(this._entity));
    }

    private InitializeTable() {
        this._entity = this._entityService.GetEntityType(this.props.match.params.entity);
        this._columns = this._entityService.GetEntityTableColumns(this._entity);
        this._headerTitle = this._entityService.GetEntityTableHeader(this._entity);
        this._addEntity = this._entityService.GetAddEntity(this.GetBaseEntity());
        this._onEntityUpdated = this._entityService.GetOnEntityUpdated(this._entity);

        if (this._currentEntityService !== undefined) {
            this._currentEntityService.DisposeTable();
        }

        this._currentEntityService = this._entityService.GetEntityService(this._entity);
        this._itemsPerPage = this._currentEntityService.ItemsPerPage;
        this._baseEntity = this._currentEntityService.GetBaseEntity();
        this._tableEntity = this._currentEntityService.GetTableEntity() !== undefined ? this._currentEntityService.GetTableEntity() : this._entity;
        this._disableItemEdit = this._currentEntityService.GetDisableItemEdit();
        this._canAddEntity = this._currentEntityService.GetTableCanAddEntity(this._entity);
        this._tableActions = this._currentEntityService.GetTableActions(this._entity);
        this._beforeTableDataLoaded = ((props: IVolmaTableProps<any>) => this._currentEntityService.OnBeforeTableDataLoaded(props)).bind(this._currentEntityService);
        this._afterTableDataLoaded = ((data: Array<ITableDTO>) => this._currentEntityService.OnAfterTableDataLoaded(data)).bind(this._currentEntityService);
        this._alternateRowClass = ((rowData: any) => this._currentEntityService.GetTableRowClass(rowData)).bind(this._currentEntityService);

        this._subsidiaryLinks = this.GetSubsidiaryLinks(this._currentEntityService.GetTableSubsidiaryEntities());
        this._currentEntityService.InitializeTable(this.props);
        this.props.dispatch(this._baseActions.SetEntityType(this._entity));
        this.props.dispatch(this._baseActions.SetEntityService(this._entityService.GetEntityService(this._entity)));
        this.props.dispatch(this._baseActions.ClearFiltersAndSelection());
        this.props.dispatch(this._baseActions.CloseNav());
    }

    private GetSubsidiaryLinks(entities: Array<EEntityType>): Array<JSX.Element> {
        let links = new Array<JSX.Element>();
        let index = 0;
        for (let entity of entities) {
            let entityService = this._entityService.GetEntityService(entity);
            links.push(
                <NavLink
                    key={index}
                    className={this._headerMulti("list-item").toString()}
                    activeClassName={("active").toString()}
                    to={this._entityService.GetEntityTableRoute(entity)}>
                    <span className={(this._headerMulti("list-val")).toString()}>{i18next.t(this._entityService.GetEntityTableHeader(entity))}</span>
                    {/*<span className={(this._headerMulti("list-close")).toString()}>
                        <svg className="header-multi__list-close-ico">
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                        </svg>
                    </span>*/}
                </NavLink>
            );
            index++;
        }
        return links
    }

    private GetBaseEntity(): EEntityType {
        let entityService = this._entityService.GetEntityService(this._entity);
        let baseEntity = this._entity;
        if (entityService.GetBaseEntity() !== undefined)
            baseEntity = entityService.GetBaseEntity();
        return baseEntity;
    }

}

const translated = withTranslation(['common'])(withRouter(BaseTable));

function select(state: IGlobalState, ownProps: IBaseTableProps<any>): IBaseTableProps<any> {
    return state.BaseTable;
}

let connectedListZone = connect<IBaseTableProps<any>, {}, {}>(select)(translated);
export default connectedListZone;
