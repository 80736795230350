import { injectable } from 'inversify';
import { IVolmaTableColumn, IVolmaTableColumnDefinition } from '../../Components/Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCellHeightMeasurers } from '../../Components/Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import { VolmaTableCommonCellRenderers } from '../../Components/Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Components/Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableCommonColumnDefinition } from '../../Components/Table/VolmaTableCommonColumnDefinition';
import { DEBUG, ID_COLUMN } from '../../Constants/AppConstants';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IDeserializable } from '../../Domain/IDeserializable';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { LogService } from './LogService';
import PropertyHelper from './PropertyHelper';

@injectable()
export class VolmaTableService {
    private _logger: LogService;
    private _excluded: Array<string>;
    private _headerRenderers: VolmaTableHeaderRenderers;
    private _commonColumnDefinition: VolmaTableCommonColumnDefinition;

    constructor() {
        this._logger = VolmaContainer.get<LogService>(Types.LogService);
        this._excluded = [
            PropertyHelper.GetPropertyName((val: IDeserializable) => val.GetTypes).toLowerCase(),
            "constructor"
        ]

        this._headerRenderers = VolmaContainer.get<VolmaTableHeaderRenderers>(Types.VolmaTableHeaderRenderers);
        this._commonColumnDefinition = VolmaContainer.get<VolmaTableCommonColumnDefinition>(Types.VolmaTableCommonColumnDefinition);
    }

    public GetColumnsByKeys(entity: EEntityType, columnsDefinitions: Array<IVolmaTableColumnDefinition>): Array<IVolmaTableColumn> {
        let self = this;
        let colDef: IVolmaTableColumn;
        let res = new Array<IVolmaTableColumn>();
        for (let columnDefinition of columnsDefinitions) {

            if (columnDefinition.DataKey === ID_COLUMN && !DEBUG){
                continue;
            }
            let cellRenderer = columnDefinition.CellRenderer || VolmaTableCommonCellRenderers.DefaultCellRenderer;
            let headerRenderer = columnDefinition.HeaderRenderer || VolmaTableHeaderRenderers.DefaultHeaderRenderer;
            let cellMeasurer = columnDefinition.CellHeightMeasurer || VolmaTableCellHeightMeasurers.SkipHeightMeasurer;

            colDef = {
                DataKey: columnDefinition.DataKey,
                FilterKey: columnDefinition.FilterKey !== undefined ? columnDefinition.FilterKey : columnDefinition.DataKey,
                SortKey: columnDefinition.SortKey !== undefined ? columnDefinition.SortKey : columnDefinition.DataKey,
                Label: EEntityType[entity].toLowerCase() + ":" +  columnDefinition.DataKey,
                Description: EEntityType[entity].toLowerCase() + ":" + columnDefinition.DataKey + "Placeholder",
                IsSortable: columnDefinition.IsSortable === false ? false : true,
                Icon: columnDefinition.Icon,
                Weight: columnDefinition.Weight || 1,
                CellRenderer: cellRenderer,
                FilterEnum: columnDefinition.FilterEnum,
                FilterEntity: columnDefinition.Entity,
                EntityOptionIcon: columnDefinition.EntityOptionIcon,
                HeaderRenderer: headerRenderer,
                Options: columnDefinition.Options,
                OptionsFilter: columnDefinition.OptionsFilter,
                CellHeightMeasurer: cellMeasurer,
                CellTextGetter: columnDefinition.CellTextGetter,
                IsFilterable: columnDefinition.IsFilterable === false ? false : true
            };
            
            res.push(colDef);
        }
        return res;
    }
}

