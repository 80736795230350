import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { CloseDeliveryAction } from '../Actions/CloseDeliveryAction';
import { DeliveryHelperProps } from '../IHelperProps';
import { Delivery } from './Delivery';


export class Finished extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusFinishCurrent";
    public TitleFinished: string = "delivery:StatusFinishFinished";
    public TitleFuture  : string = "delivery:StatusFinishFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.Delivered //||
            //dto.State === EDeliveryState.ClaimCreated;
    }

    public IsFinished(dto: DeliveryDTO){
        return !this.IsCurrent(dto) && new Delivery().IsFinished(dto) ||
        dto.State === EDeliveryState.NotExecuted ||
        dto.State === EDeliveryState.Cancelled ||
        dto.State === EDeliveryState.Closed ||
        dto.State === EDeliveryState.ClosedOld;
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [new CloseDeliveryAction()];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;
        return undefined;
    }

}
