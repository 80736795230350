import { injectable } from 'inversify';
import { DELIVERY_TABLE_BETS_LOADED, DELIVERY_TABLE_BET_ADDED_OR_UPDATED, DELIVERY_TABLE_WHITH_BETS_REMOVED, NOOP } from '../../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../../Domain/DTO/BiddingBetTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { IReducePayloaded } from '../../../../Infrastructure/Reducer/IReducer';
import { IBaseTableProps } from '../../../Table/BaseTable/IBaseTableProps';
import { IDeliveryTableHelperProps } from './ITableHelperProps';

@injectable()
export class DeliveryTableReducer implements IReducePayloaded<IBaseTableProps<IDeliveryTableHelperProps>>{

    public Reduce(state: IBaseTableProps<IDeliveryTableHelperProps>, action: IActionPayloaded<any>): IBaseTableProps<IDeliveryTableHelperProps> {

        switch (action.type) {
            case NOOP: 
                return this.ReduceUpdateTable(state);
            case DELIVERY_TABLE_BETS_LOADED:
                return this.ReduceBetsLoaded(state, action.Payload);
            case DELIVERY_TABLE_BET_ADDED_OR_UPDATED:
                return this.ReduceBetAddedOrUpdatedLoaded(state, action.Payload);
            case DELIVERY_TABLE_WHITH_BETS_REMOVED:
                return this.ReduceDeliveryWithBetsRemoved(state, action.Payload);

            default:
                return state;
        }
    }

    private ReduceUpdateTable(state: IBaseTableProps<IDeliveryTableHelperProps>){
        let newState = { ...state };
        newState.NoopObject = new Object();
        if (newState.Tables !== undefined && newState.Tables.length > 0){
            for (let table of newState.Tables){
                table.NoopObject = new Object();
            }
        }

        return newState;
    }

    private ReduceBetsLoaded(state: IBaseTableProps<IDeliveryTableHelperProps>, payload: Array<BiddingBetTableDTO>): IBaseTableProps<IDeliveryTableHelperProps> {
        let newState = { ...state, DataHelper: {...state.DataHelper}};

        newState.DataHelper.AllUserBets = payload

        return newState;
    }

    private ReduceBetAddedOrUpdatedLoaded(state: IBaseTableProps<IDeliveryTableHelperProps>, payload: BiddingBetTableDTO): IBaseTableProps<IDeliveryTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.AllUserBets = newState.DataHelper.AllUserBets.slice(0);
        
        let betIndex = newState.DataHelper.AllUserBets.findIndex(x => x.DeliveryId === payload.DeliveryId);
        if (betIndex < 0)
            newState.DataHelper.AllUserBets.push(payload);
        else
            newState.DataHelper.AllUserBets.splice(betIndex, 1, payload);

        return newState;
    }

    private ReduceDeliveryWithBetsRemoved(state: IBaseTableProps<IDeliveryTableHelperProps>, payload: string): IBaseTableProps<IDeliveryTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.AllUserBets = newState.DataHelper.AllUserBets.slice(0);
        newState.DataHelper.AllUserBets = newState.DataHelper.AllUserBets.filter(x => x.DeliveryId != payload);

        return newState;
    }
}