import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { ChangeCargoTransporterAndConfirmAction } from '../Actions/ChangeCargoTransporterAndConfirmAction';
import { DeliveryWithdrawAction } from '../Actions/DeliveryWithdrawAction';
import { LoadingEndAction } from '../Actions/LoadingEndAction';
import { MarkAsDeliveredAction } from '../Actions/MarkAsDeliveredAction';
import { RejectApprovedDeliveryAction } from '../Actions/RejectApprovedDeliveryAction';
import { SynchronizeDeliveryAction } from '../Actions/SynchronizeDeliveryAction';
import { UpdateVehicleDriverAction } from '../Actions/UpdateVehicleDriverAction';
import { DeliveryHelperProps } from '../IHelperProps';
import { Confirmation } from './Confirmation';


export class WaitingDelivery extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusWaitingDeliveryCurrent";
    public TitleFinished: string = "delivery:StatusWaitingDeliveryFinished";
    public TitleFuture  : string = "delivery:StatusWaitingDeliveryFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.Approved || dto.State == EDeliveryState.LoadingLatenessRegistration; 
    }

    public IsFinished(dto: DeliveryDTO){
        return !this.IsCurrent(dto) && new Confirmation().IsFinished(dto);
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService) {
        return [
            currentUser.IsIncludeInMultiCargoTransporter ? new ChangeCargoTransporterAndConfirmAction() : new UpdateVehicleDriverAction<DeliveryHelperProps>(), 
            new RejectApprovedDeliveryAction<DeliveryHelperProps>(), new DeliveryWithdrawAction<DeliveryHelperProps>(), 
            new MarkAsDeliveredAction<DeliveryHelperProps>(), new SynchronizeDeliveryAction<DeliveryHelperProps>(), 
            new LoadingEndAction<DeliveryHelperProps>()
        ];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;
        return undefined;
    }
}
