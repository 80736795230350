import { injectable } from 'inversify';
import {
    DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED, DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER
} from '../../../../Constants/AppConstants';
import { DeliveryManualAssignDTO } from '../../../../Domain/DTO/DeliveryManualAssignDTO';
import { MultiCargoTransporterTableDTO } from '../../../../Domain/DTO/MultiCargoTransporterTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { MultiTransporterTableDTO } from '../../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { DeliveryManualAssignHelperProps } from './IHelperProps';

type IState = IBaseEntityProps<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>;

@injectable()
export class DeliveryManualAssignReducer extends EntityReducer<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED:
                return this.ReduceMultiCargoTransportersLoaded(state, action.Payload);
            case DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER:
                return this.ReduceMultiTransporterSelected(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceMultiCargoTransportersLoaded(state: IState, payload: Array<MultiCargoTransporterTableDTO>): IState {
        return super.CollectionLoaded<MultiCargoTransporterTableDTO>(state, payload, [x => x.AllMultiCargoTransporters]);
    }

    private ReduceMultiTransporterSelected(state: IState, payload: MultiTransporterTableDTO): IState {
        return super.EntityLoaded<MultiTransporterTableDTO>(state, payload, x => x.SelectedMultiTransporter);
    }
}
