import { TenderServerInteraction } from '../../../../Infrastructure/ServerInteraction/TenderServerInteraction';
import { TenderCancelDTO } from '../../../../Domain/DTO/TenderCancelDTO';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { TenderTableActions } from '../Table/TenderTableActions';
import i18next from '../../../i18n';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { EUserRole } from '../../../../Domain/Enum/EUserRole';
import { ApiActions } from '../../../Shared/ApiActions';
import { AxiosResponse } from 'axios';
import { FileExtendedDTO } from '../../../File/FileExtendedDTO';

export class TenderImportAction<THelper> extends VolmaTableAction<THelper> {
    private _modalActions      : VolmaModalActions;
    private _tenderTableActions: TenderTableActions;
    private _tenderApi         : TenderServerInteraction;
    private _apiActions        : ApiActions;

    public Title: string = "tender:TenderImport";

    public IsAvailable(selectedIds: Array<string>) {
        return this._authService.IsShipper();
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<TenderTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        
        dispatch(this._modalActions.OpenFileUploadModal(i18next.t('tender:TenderImport'), (dto: FileExtendedDTO) => {
            let importPromise = this._tenderApi.Import(dto.FileId);
            this._apiActions.DoApiRequest(
                dispatch,
                () => importPromise,
                i18next.t("common:Loading"),
                (response: AxiosResponse, dispatch: any) => {
                    reloadData()
                });
            return importPromise;
        }))
    }

    constructor() {
        super();
        this._modalActions       = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tenderTableActions = VolmaContainer.get<TenderTableActions>(Types.TenderTableActions);
        this._tenderApi          = VolmaContainer.get<TenderServerInteraction>(Types.TenderServerInteraction);
        this._apiActions         = VolmaContainer.get<ApiActions>(Types.ApiActions);
    }
}