import {
    DELIVERY_BIDDING_BETS_LOADED,
    DELIVERY_BIDDING_WINNER_BETS_LOADED,
    DELIVERY_CARGOTRANSPORTER_LOADED,
    DELIVERY_COMPLAINTS_LOADED,
    DELIVERY_CONSIGNOR_LOADED,
    DELIVERY_DELIVERY_STATES_LOADED,
    DELIVERY_DRIVER_LOADED,
    DELIVERY_ADDITION_FILES_LOADED,
    DELIVERY_HANDLING_TYPE_LOADED,
    DELIVERY_LOADING_POINT_LOADED,
    DELIVERY_LOCALITY_LOADED,
    DELIVERY_PAYLOAD_LOADED,
    DELIVERY_ROUTE_POINT_ADDED,
    DELIVERY_ROUTE_POINT_UPDATED,
    DELIVERY_ROUTE_POINTS_DELETED,
    DELIVERY_ROUTE_POINTS_LOADED,
    DELIVERY_VEHICLE_LOADED,
    NOOP,
    VOLMA_ENTITY_STATUS_TOGGLED,
    VOLMA_SOMEWHERE_CLICKED,
    DELIVERY_PENDING_CHANGES_LOADED,
    DELIVERY_ZONE_LOADED
} from '../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { ComplaintTableDTO } from '../../../Domain/DTO/ComplaintTableDTO';
import { DataDTO } from '../../../Domain/DTO/DataDTO';
import { DeliveryDTO } from '../../../Domain/DTO/DeliveryDTO';
import { DeliveryStateTableDTO } from '../../../Domain/DTO/DeliveryStateTableDTO';
import { DriverDTO } from '../../../Domain/DTO/DriverDTO';
import { VehicleDTO } from '../../../Domain/DTO/VehicleDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { DeliveryHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import { RouteSegmentDTO } from '../../../Domain/DTO/RouteSegmentDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { VOLMA_ENTITY_DATA_LOADED, DELIVERY_FIRST_LOADING_POINT_LOADED } from '../../../Constants/AppConstants';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { ERoutePointType } from '../../../Domain/Enum/ERoutePointType';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { HandlingTypeDTO } from '../../../Domain/DTO/HandlingTypeDTO';
import { FileDTO } from '../../../Domain/DTO/FileDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { TimeDTO } from '../../../Domain/DTO/TimeDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { TimeService } from '../../../Infrastructure/Services/TimeService';
import { Types } from '../../../Infrastructure/Types';
import { DeliveryPendingChangesItemDTO } from '../../../Domain/DTO/DeliveryPendingChangesItemDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';



@injectable()
export class DeliveryReducer extends EntityReducer<DeliveryDTO, DeliveryHelperProps> {

    public Reduce(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        switch (action.type) {
            case NOOP:
                return this.ReduceUpdateStatuses(state);
            case DELIVERY_ROUTE_POINTS_LOADED:
                return this.ReduceRouteSegmentsLoaded(state, action.Payload);
            case DELIVERY_PENDING_CHANGES_LOADED:
                return this.ReducePendingChangesLoaded(state, action.Payload);
            case DELIVERY_DELIVERY_STATES_LOADED:
                return this.ReduceDeliveryStatesLoaded(state, action.Payload);
            case DELIVERY_COMPLAINTS_LOADED:
                return this.ReduceComplaintsLoaded(state, action.Payload);
            case DELIVERY_BIDDING_BETS_LOADED:
                return this.ReduceBiddingBetsLoaded(state, action.Payload);
            case DELIVERY_BIDDING_WINNER_BETS_LOADED:
                return this.ReduceBiddingWinnersBetsLoaded(state, action.Payload);
            case DELIVERY_VEHICLE_LOADED:
                return this.ReduceVehicleLoaded(state, action.Payload);
            case DELIVERY_DRIVER_LOADED:
                return this.ReduceDriverLoaded(state, action.Payload);
            case DELIVERY_LOADING_POINT_LOADED:
                return this.ReduceLoadingPointLoaded(state, action.Payload);
            case DELIVERY_LOCALITY_LOADED:
                return this.ReduceLocalityLoaded(state, action.Payload);
            case DELIVERY_HANDLING_TYPE_LOADED:
                return this.ReduceHandlingTypeLoaded(state, action.Payload);
            case DELIVERY_PAYLOAD_LOADED:
                return this.ReducePayloadLoaded(state, action.Payload);
            case DELIVERY_ZONE_LOADED:
                return this.ReduceZoneLoaded(state, action.Payload);
            case DELIVERY_CARGOTRANSPORTER_LOADED:
                return this.ReduceCargoTransporterLoaded(state, action.Payload);
            case DELIVERY_CONSIGNOR_LOADED:
                return this.ReduceConsignorLoaded(state, action.Payload);
            case DELIVERY_ADDITION_FILES_LOADED:
                return this.ReduceAdditionFilesLoaded(state, action.Payload);
            case DELIVERY_ROUTE_POINT_ADDED:
                return this.ReduceRoutePointAdded(state, action.Payload);
            case DELIVERY_ROUTE_POINT_UPDATED:
                return this.ReduceRoutePointUpdated(state, action.Payload);
            case DELIVERY_ROUTE_POINTS_DELETED:
                return this.ReduceRoutePointsDeleted(state, action.Payload);
            case VOLMA_ENTITY_STATUS_TOGGLED:
                return this.ReduceStatusToggled(state, action.Payload);
            case VOLMA_SOMEWHERE_CLICKED:
                return this.ReduceStatusClose(state);
            case DELIVERY_FIRST_LOADING_POINT_LOADED:
                return this.ReduceFirstLoadingPointLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceUpdateStatuses(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return { ...state, NoopObject: {}};
    }

    private ReduceRouteSegmentsLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<DeliveryDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = {...state, DataHelper: {...state.DataHelper}};

        newState.DataHelper.RouteSegments = payload;
        newState.DataHelper.ChangelogTableDataHelper = { ...newState.DataHelper.ChangelogTableDataHelper, RouteSegments: newState.DataHelper.RouteSegments };

        return newState;
    }

    private ReducePendingChangesLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<DeliveryPendingChangesItemDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<DeliveryPendingChangesItemDTO>(state, payload, [x => x.PendingChanges, x => x.PendingChangesOriginal]);
    }

    private ReduceDeliveryStatesLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<DeliveryStateTableDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<DeliveryStateTableDTO>(state, payload, [x => x.DeliveryStates, x => x.DeliveryStatesOriginal]);
    }

    private ReduceComplaintsLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<ComplaintTableDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<ComplaintTableDTO>(state, payload, [x => x.Complaints, x => x.ComplaintsOriginal]);
    }

    private ReduceBiddingBetsLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<BiddingBetTableDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<BiddingBetTableDTO>(state, payload, [x => x.Bets, x => x.BetsOriginal]);
    }

    private ReduceBiddingWinnersBetsLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<BiddingBetTableDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<BiddingBetTableDTO>(state, payload, [x => x.WinnersBets]);
    }

    private ReduceDriverLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: DriverDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.EntityLoaded<DriverDTO>(state, payload, x => x.Driver);
    }

    private ReduceVehicleLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: VehicleDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.EntityLoaded<VehicleDTO>(state, payload, x => x.Vehicle);
    }

    private ReduceLoadingPointLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: LoadingPointDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = <IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>>{ ...state, DataHelper: { ...<any>state.DataHelper } };
        newState.DataHelper.LoadingPoints = this.CopyCollectionObject(newState.DataHelper.LoadingPoints);
        newState.DataHelper.LoadingPoints.push(payload);
        newState.DataHelper.ChangelogTableDataHelper = { ...newState.DataHelper.ChangelogTableDataHelper, LoadingPoints: newState.DataHelper.LoadingPoints}
        newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, LoadingPoints: newState.DataHelper.LoadingPoints}
        return newState;
    }

    private ReduceFirstLoadingPointLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: LoadingPointDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = <IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>>{ ...state, DataHelper: { ...<any>state.DataHelper } };
        if (payload.Timezone !== undefined) {
            newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, TimeZone: payload.Timezone }
        } else {
            let _timeService = VolmaContainer.get<TimeService>(Types.TimeService);
            let timezoneNumber = _timeService.GetTimeZoneOffsetInMinutes();
            let timezone:TimeDTO = {IsNegative: timezoneNumber < 0, Seconds: 0};
            timezone.Hours = Math.trunc(timezoneNumber/60);
            timezone.Minutes = Math.trunc((timezoneNumber - timezone.Hours * 60) / 60);
            newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, TimeZone: timezone}
        }
        return newState;
    }

    private ReduceLocalityLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: LocalityDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = <IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>>{ ...state, DataHelper: { ...<any>state.DataHelper } };
        newState.DataHelper.Localities = this.CopyCollectionObject(newState.DataHelper.Localities);
        newState.DataHelper.Localities.push(payload);
        newState.DataHelper.ChangelogTableDataHelper = { ...newState.DataHelper.ChangelogTableDataHelper, Localities: newState.DataHelper.Localities }
        newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, Localities: newState.DataHelper.Localities }
        return newState;
    }

    private ReduceHandlingTypeLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: HandlingTypeDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = <IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>>{ ...state, DataHelper: { ...<any>state.DataHelper } };
        newState.DataHelper.HandlingTypes = this.CopyCollectionObject(newState.DataHelper.HandlingTypes);
        newState.DataHelper.HandlingTypes.push(payload);
        newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, HandlingTypes: newState.DataHelper.HandlingTypes }
        return newState;
    }

    private ReducePayloadLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: PayloadDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        let newState = <IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>>{ ...state, DataHelper: { ...<any>state.DataHelper } };
        newState.DataHelper.Payloads = this.CopyCollectionObject(newState.DataHelper.Payloads);
        newState.DataHelper.Payloads.push(payload);
        newState.DataHelper.RoutePointsTableDataHelper = { ...newState.DataHelper.RoutePointsTableDataHelper, Payloads: newState.DataHelper.Payloads }
        return newState;
    }

    private ReduceCargoTransporterLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: CargoTransporterDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.EntityLoaded<CargoTransporterDTO>(state, payload, x => x.CargoTransporter);
    }

    private ReduceConsignorLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: ConsignorDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.EntityLoaded<ConsignorDTO>(state, payload, x => x.Consignor);
    }

    private ReduceAdditionFilesLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<FileTableDTO>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.CollectionLoaded<DeliveryStateTableDTO>(state, payload, [x => x.AdditionalFiles]);
    }

    private ReduceRoutePointAdded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: RouteSegmentDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>{
        let newState = {...state, DataDTO: {...state.DataDTO}};

        newState.DataDTO.RouteSegments = super.CopyCollectionObject(newState.DataDTO.RouteSegments);
        newState.DataHelper.RouteSegments = super.CopyCollectionObject(newState.DataHelper.RouteSegments);

        payload.OrderNum = newState.DataDTO.RouteSegments.length;
        newState.DataDTO.RouteSegments.push(payload);
        newState.DataHelper.RouteSegments.push(payload);

        return newState;
    }

    private ReduceRoutePointUpdated(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: RouteSegmentDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>{
        let newState = {...state, DataDTO: {...state.DataDTO}};

        newState.DataDTO.RouteSegments = super.CopyCollectionObject(newState.DataDTO.RouteSegments);
        newState.DataHelper.RouteSegments = super.CopyCollectionObject(newState.DataDTO.RouteSegments);
            
        let index = newState.DataDTO.RouteSegments.findIndex(x => x.OrderNum === payload.OrderNum)
        if (index >= 0){
            newState.DataDTO.RouteSegments.splice(index, 1, payload);
            newState.DataHelper.RouteSegments.splice(index, 1, payload);
        }

        return newState;
    }

    private ReduceRoutePointsDeleted(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: Array<string>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>{
        let newState = {...state, DataDTO: {...state.DataDTO}};

        newState.DataDTO.RouteSegments = super.CopyCollectionObject(newState.DataDTO.RouteSegments);
        newState.DataHelper.RouteSegments = super.CopyCollectionObject(newState.DataDTO.RouteSegments);

        for(let id of payload){
            let index = newState.DataDTO.RouteSegments.findIndex(x => x.Id === id)
            if (index >= 0){
                newState.DataDTO.RouteSegments.splice(index, 1);
                newState.DataHelper.RouteSegments.splice(index, 1);
            }
        }

        return newState;
    }

    private ReduceStatusToggled(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: number): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return { ...state, OpenedStatusIndex: payload }
    }

    private ReduceStatusClose(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return { ...state, OpenedStatusIndex: -1 }
    }

    private ReduceZoneLoaded(state: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, payload: ZoneDTO): IBaseEntityProps<DeliveryDTO, DeliveryHelperProps> {
        return super.EntityLoaded<ZoneDTO>(state, payload, x => x.Zone);
    }

}
