import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { history } from '../../ConfigureStore';
import {
    APP_LOGOUT,
    HEADER_SEARCH_UPDATED,
    HEADER_TOGGLE_NAV,
    HEADER_TOGGLE_USER_MENU,
    USER_PROFILE_CHANGED,
} from '../../Constants/AppConstants';
import { UserDTO } from '../../Domain/DTO/UserDTO';
import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { Types } from '../../Infrastructure/Types';
import { ApiActions } from '../Shared/ApiActions';
import { RouterService } from '../../Infrastructure/Router/RouterService';

@injectable()
export class HeaderActions{

    private _api: AuthServerInteraction;
    private _authService: AuthenticationService;
    private _apiActions: ApiActions;
    private _routerService: RouterService;

    constructor() {
        this._api = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
    }

    public Logout(text: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.Logout(),
                text,
                (response: AxiosResponse, dispatch: any) => {
                    this._authService.ClearAuthenticated();
                    dispatch(this.LogoutSuccesful());
                    history.push(this._routerService.GetHome());
                });
        }
    }

    public SetCurrentUserInfo(user: UserDTO): IActionPayloaded<UserDTO> {
        return { type: USER_PROFILE_CHANGED, Payload: user };
    }

    private LogoutSuccesful(): IAction {
        return { type: APP_LOGOUT };
    }

    public ToggleUserMenu(): IAction{
        return {type: HEADER_TOGGLE_USER_MENU }
    }

    public ToggleNav(index: number): IActionPayloaded<number>{
        return {type: HEADER_TOGGLE_NAV, Payload: index }
    }

    public SearchUpdated(val: string): IActionPayloaded<string>{
        return {type: HEADER_SEARCH_UPDATED, Payload: val};
    }
}