import { IVolmaTableProps } from '../IVolmaTableProps';
import { ScrollBarSize } from '../../../../Infrastructure/Utils/ScrollBarSize';
import { HTML_BR } from '../../../../Constants/AppConstants';

export class VolmaTableCellHeightMeasurers{
    private static _context: any;
    private static _lineHeigth: number = 16; // actually it is 14px*1.2 which is equal to 16.8px, but it seems that browser floor to 16px
    private static _cellTopBottomPadding: number = 28; // because table__cell has padding: 28px 39px 28px;
    private static _cellRightLeftPadding: number = 39; // because table__cell has padding: 28px 39px 28px;
    private static _scrollBarSize: number = ScrollBarSize();
    private static _widthCorrection: number = 35; // because table-cont__text is defined as calc(100% - 35px)
    private static _maxLinesAvailable: number = 15; // limit max height

    public static SkipHeightMeasurer(cellText: string, width: number): number {
        return 0;
    }

    public static SearchResultHeightMeasurer(cellText: string, width: number): number {
        let linesCount = VolmaTableCellHeightMeasurers.GetLinesCount(cellText, width);
        // 4px - bottom margin for highlight
        return Math.min(VolmaTableCellHeightMeasurers._maxLinesAvailable, linesCount) * (VolmaTableCellHeightMeasurers._lineHeigth + 4) + VolmaTableCellHeightMeasurers._cellTopBottomPadding * 2 + 2;
    }

    public static DefaultHeightMeasurer(cellText: string, width: number): number{
        let linesCount = VolmaTableCellHeightMeasurers.GetLinesCount(cellText, width);
        return Math.min(VolmaTableCellHeightMeasurers._maxLinesAvailable, linesCount) * VolmaTableCellHeightMeasurers._lineHeigth + VolmaTableCellHeightMeasurers._cellTopBottomPadding * 2 + 2;
    }

    private static GetLinesCount(cellText: string, width: number): number{
        width -= VolmaTableCellHeightMeasurers._cellRightLeftPadding * 2 + VolmaTableCellHeightMeasurers._widthCorrection;

        if (cellText === undefined || cellText === null)
            return 0;

        let linesCount = 0; // reserve one line for "just in case"
        let spaceLength = VolmaTableCellHeightMeasurers.GetTextLength(" ");
        let lines = cellText.toString().split(HTML_BR);
        for (let line of lines) {
            linesCount++;
            let lineLength = 0;
            let words = line.toString().split(/[\s]+/);
            for (let i = 0; i < words.length; i++) {
                let wordLength = VolmaTableCellHeightMeasurers.GetTextLength(words[i]);
                if (lineLength + wordLength < width) {
                    lineLength += wordLength + spaceLength;
                }
                else if (lineLength > 0) {
                    linesCount++;
                    lineLength = wordLength;
                }
                else {
                    lineLength = wordLength;
                }
            }
        }
        return linesCount;
    }
    
    private static GetTextLength(text: string) {
        if (VolmaTableCellHeightMeasurers._context === undefined){
            let element = document.createElement('canvas');
            VolmaTableCellHeightMeasurers._context = element.getContext("2d");
            VolmaTableCellHeightMeasurers._context.font = "400 14px/1.2 \"PFDinTextPro\"";
        }
        return VolmaTableCellHeightMeasurers._context.measureText(text).width;
    }
}