import * as moment from 'moment';

import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { TenderHelperProps } from '../IHelperProps';

export class Finished extends StatusDefault<TenderDTO, TenderHelperProps>{
    public TitleCurrent : string = "tender:StatusFinishedCurrent";
    public TitleFinished: string = "tender:StatusFinishedFinished";
    public TitleFuture  : string = "tender:StatusFinishedFuture";

    public IsActive(dto: TenderDTO){
        return true;
    }

    public IsCurrent(dto: TenderDTO){
        return false;
    }

    public IsFinished(dto: TenderDTO){
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && this._timeService.GetLocalTime(dto.EndDate).isBefore(moment());
    }

    public GetActions(dto: TenderDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [];
    }
}