import { DRIVER_COORDINATES_LOADED, DRIVER_SCORE_LOADED, DRIVER_VEHICLES_LOADED, DRIVER_VEHICLE_ADDED_TO_DRIVER, DRIVER_VEHICLE_REMOVED_FROM_DRIVER } from '../../../../Constants/AppConstants';
import { DriverDTO } from '../../../../Domain/DTO/DriverDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { DriverProps } from './IHelperProps';
import { injectable } from 'inversify';
import { DriverLocationTableDTO } from '../../../../Domain/DTO/DriverLocationTableDTO';
import { DriverScoreDTO } from '../../../../Domain/DTO/DriverScoreDTO';
import {EDriverScoreKind} from "../../../../Domain/Enum/EDriverScoreKind"
import i18next from '../../../i18n';

@injectable()
export class DriverReducer extends EntityReducer<DriverDTO, DriverProps> {

    public Reduce(state: IBaseEntityProps<DriverDTO, DriverProps>, action: IActionPayloaded<any>): IBaseEntityProps<DriverDTO, DriverProps>{
        switch (action.type) {
            case DRIVER_VEHICLES_LOADED:
                return this.ReduceVehiclesLoaded(state, action.Payload);
            case DRIVER_COORDINATES_LOADED:
                return this.ReduceLastCoordinatesLoaded(state, action.Payload);
            case DRIVER_SCORE_LOADED:
                return this.ReduceDriverScoreLoaded(state, action.Payload);
            case DRIVER_VEHICLE_ADDED_TO_DRIVER:
                return this.ReduceVehicleAddedToDriver(state, action.Payload);
            case DRIVER_VEHICLE_REMOVED_FROM_DRIVER:
                return this.ReduceVehicleRemovedFromDriver(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceDriverScoreLoaded(state: IBaseEntityProps<DriverDTO, DriverProps>, payload: Array<DriverLocationTableDTO>): IBaseEntityProps<DriverDTO, DriverProps>{
        const newState = super.CollectionLoaded<DriverScoreDTO>(state, payload, [x => x.DriverScore, x=> x.DriverScoreOriginal]);
        newState.DataHelper = {
            ...newState.DataHelper,
            DriverScore: newState.DataHelper.DriverScore.map((el) => ({
                ...el,
                Timestamp: new Intl.DateTimeFormat('ru-RU', {dateStyle: 'short', timeStyle: 'short'} as any).format(new Date(el.Timestamp)) as any, // TODO
            }))
        }
        return newState
    }

    private ReduceLastCoordinatesLoaded(state: IBaseEntityProps<DriverDTO, DriverProps>, payload: Array<DriverLocationTableDTO>): IBaseEntityProps<DriverDTO, DriverProps>{
        return super.CollectionLoaded<DriverLocationTableDTO>(state, payload, [x => x.LastCoordinates, x=> x.LastCoordinatesOriginal]);
    }

    private ReduceVehiclesLoaded(state: IBaseEntityProps<DriverDTO, DriverProps>, payload: Array<VehicleTableDTO>): IBaseEntityProps<DriverDTO, DriverProps>{
        return super.CollectionLoaded<VehicleTableDTO>(state, payload, [x => x.Vehicles, x=> x.VehiclesOriginal]);
    }

    private ReduceVehicleAddedToDriver(state: IBaseEntityProps<DriverDTO, DriverProps>, payload: VehicleTableDTO) : IBaseEntityProps<DriverDTO, DriverProps>{
        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Vehicles = this.CopyCollectionObject(state.DataHelper.Vehicles);
        newState.DataDTO.Vehicles = this.CopyCollectionObject(state.DataDTO.Vehicles);

        newState.DataHelper.Vehicles.push(payload);
        newState.DataDTO.Vehicles.push(payload.Id);

        return newState;
    }

    private ReduceVehicleRemovedFromDriver(state: IBaseEntityProps<DriverDTO, DriverProps>, payload: string[]) : IBaseEntityProps<DriverDTO, DriverProps>{
        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Vehicles = this.CopyCollectionObject(state.DataHelper.Vehicles);
        newState.DataDTO.Vehicles = this.CopyCollectionObject(state.DataDTO.Vehicles);

        for(let id of payload){
            let index = newState.DataHelper.Vehicles.findIndex(x => x.Id === id)
            if (index >= 0)
                newState.DataHelper.Vehicles.splice(index, 1);
        }

        for(let id of payload){
            let index = newState.DataDTO.Vehicles.findIndex(x => x === id)
            if (index >= 0)
                newState.DataDTO.Vehicles.splice(index, 1);
        }

        return newState;
    }
}
