import {
    INPUT_VALUE_CHANGE,
    SELECT_VALUE_CHANGE,
    VOLMA_COMPONENT_INITIALIZE,
    VOLMA_MODAL_CLOSE,
    VOLMA_MODAL_OPEN,
    VOLMA_TABLE_DATA_UPDATED,
} from '../../Constants/AppConstants';
import { DataDTO } from '../../Domain/DTO/DataDTO';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { Types } from '../../Infrastructure/Types';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IVolmaTableDataPayload } from '../Table/VolmaTable/Payload';
import { IVolmaFormProps } from '../VolmaForm/IVolmaFormProps';
import { VolmaFormReducer } from '../VolmaForm/VolmaFormReducer';
import { IBaseValueChangedPayload } from '../VolmaInput/Payloads';
import { ISelectValueChangedPayload } from '../VolmaSelect/Payloads';

export class VolmaComponentReducer<T extends IVolmaFormProps> implements IReducePayloaded<T> {
    private _componentMountedConstant: string;
    private _componentUnmountedConstant: string;

    private _formReducer: VolmaFormReducer;
    constructor(mountedConstant: string, unmountedConstant: string) {

        this._formReducer = VolmaContainer.get<VolmaFormReducer>(Types.VolmaFormReducer);

        this._componentMountedConstant = mountedConstant;
        this._componentUnmountedConstant = unmountedConstant;
    }

    public Reduce(state: T, action: IActionPayloaded<any>): T {
        if (state.IsMounted && !state.IsMuted)
            state = this._formReducer.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                if (state.IsMounted && !state.IsMuted)
                    return <T>this.UpdateData(state, action.Payload);
                return state;
            case SELECT_VALUE_CHANGE:
                if (state.IsMounted && !state.IsMuted)
                    return <T>this.SelectUpdateData(state, action.Payload);
                return state;
            case VOLMA_TABLE_DATA_UPDATED:
                if (state.IsMounted && !state.IsMuted)
                    return <T>this.UpdateTableData(state, action.Payload);
                return state;
            case this._componentMountedConstant:
                return <T>this.Mounted(state);
            case this._componentUnmountedConstant:
                return <T>this.Unmounted(state);
            case VOLMA_MODAL_OPEN:
                if (state.IsMounted)
                    return <T>this.Mute(state);
                return state;
            case VOLMA_MODAL_CLOSE:
                if (state.IsMounted)
                    return <T>this.Unmute(state);
                return state;
            case VOLMA_COMPONENT_INITIALIZE:
                if (state.IsMounted && !state.IsMuted)
                    return <T>this.ComponentInitialize(state, action.Payload);
                return state;
            default:
                return state;
        }
    }

    private UpdateData(state: IBaseEntityProps<IEntityDTO, any>, payload: IBaseValueChangedPayload<any>): IVolmaFormProps {
        const newState: IBaseEntityProps<IEntityDTO, any> = { ...state, DataDTO: { ...state.DataDTO } };
        const inputState = state.Inputs.find(x => x.Name === payload.InputName);
        if (inputState.CustomDataUpdate === undefined){
            if (newState.DataHelper && newState.DataHelper.hasOwnProperty(payload.InputName))
                newState.DataHelper[payload.InputName] = payload.Value;
            else
                newState.DataDTO[payload.InputName] = payload.Value;
        }
        else
            inputState.CustomDataUpdate(newState, payload.InputName, payload.Value);
        return newState;
    }

    private SelectUpdateData(state: IBaseEntityProps<IEntityDTO, any>, payload: ISelectValueChangedPayload): IVolmaFormProps {
        const selectState = state.Selects.find(x => x.Name === payload.SelectName);

        if (selectState === undefined) {
            throw new Error("Unregistered select name '" + payload.SelectName + "'");
        }

        if (payload.Value === null) {
            payload.Value = undefined;
        }

        let fieldValue = undefined;
        if (payload.Value !== undefined) {
            if (Object.prototype.toString.call(payload.Value) === '[object Array]') {
                fieldValue = payload.Value.map(x => selectState.HelperFieldToDTOFieldTranslator(x));
            } else {
                fieldValue = selectState.HelperFieldToDTOFieldTranslator(payload.Value);
            }
        }

        const newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };
        const fieldName = payload.EntityData?.DTOFieldName || selectState.DTOFieldName;

        if (selectState.CustomDataUpdate === undefined) {
            newState.DataDTO[fieldName] = fieldValue;                       

            if (selectState.HelperFieldName !== undefined) {
                newState.DataHelper[selectState.HelperFieldName] = payload.Value;
            }
        } else {
            selectState.CustomDataUpdate(newState, fieldName, selectState.HelperFieldName, fieldValue, payload.Value);
        }
        
        return newState;
    }

    private UpdateTableData(state: IBaseEntityProps<IEntityDTO, any>, payload: IVolmaTableDataPayload): IVolmaFormProps {
        const newState: IBaseEntityProps<IEntityDTO, any> = { ...state, DataHelper: { ...state.DataHelper } };
        newState.DataHelper[payload.TableName] = payload.Items;
        return newState;
    }

    private Mounted(state: IVolmaFormProps): IVolmaFormProps {
        const newState: IVolmaFormProps = { ...state, IsMounted: true, IsMuted: false };
        return newState;
    }

    private Unmounted(state: IVolmaFormProps): IVolmaFormProps {
        const newState = { ...state, IsMounted: false, DataDTO: undefined, DataHelper: {}, Inputs: [], Selects: [], Tables: [], IsSubmitted: false, IsTouched: false, IsValid: false };

        return newState;
    }

    private Mute(state: IVolmaFormProps): IVolmaFormProps {
        const newState: IVolmaFormProps = { ...state, IsMuted: true };
        return newState;
    }

    private Unmute(state: IVolmaFormProps): IVolmaFormProps {
        const newState: IVolmaFormProps = { ...state, IsMuted: false };

        return newState;
    }

    private ComponentInitialize(state: IVolmaFormProps, payload: any): IVolmaFormProps {
        const newState = { ...state, DataDTO: payload };

        return newState;
    }
}