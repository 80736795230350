import * as React from 'react';
import { TenderBetTableDTO } from '../../../Domain/DTO/TenderBetTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../BaseEntity/IEntityReducer';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import VolmaNumber from '../../VolmaNumber/index';
import i18next from '../../i18n';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import { TenderBetExtendedDTO } from './Table/TenderBetExtendedDTO';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { ECargoTransporterType } from '../../../Domain/Enum/ECargoTransporterType';

@injectable()
export class TenderBetEntityService extends BaseEntityService<TenderBetExtendedDTO, {}>{
    private _volmaTableService: VolmaTableService;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _minValue: number;
    private _maxValue: number;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this.MinValueGetter = this.MinValueGetter.bind(this);
        this.MaxValueGetter = this.MaxValueGetter.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if(this._authService.IsShipper()){
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.TenderBet,
                [
                    { 
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Id)
                    },
                    //todo тут по идее должен быть фильтр по времени
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Timestamp),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.CargoTransporterName),
                        CellTextGetter: this.CargoTransporterCellTextGetter,                        
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.CargoTransporterUserName),
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                        CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Bet),
                        CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    }
                ]
            );
        }
        else {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.TenderBet,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Id) },
                    //todo тут по идее должен быть фильтр по времени
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Timestamp),
                        CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: TenderBetTableDTO) => val.Bet),
                        CellRenderer: VolmaTableCommonCellRenderers.MoneyCellRenderer,
                        HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                    },
                ]
            );
        }
    }

    public GetHeaderEditor(props: IBaseEntityProps<TenderBetExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<TenderBetExtendedDTO, {}>): JSX.Element {
        this._minValue = props.DataDTO.MinBetValue;
        this._maxValue = props.DataDTO.MaxBetValue;
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaNumber
                                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderBetExtendedDTO) => val.Bet) }
                                        Name={PropertyHelper.GetPropertyName((val: TenderBetExtendedDTO) => val.Bet)}
                                        Label={i18next.t('tenderbet:Bet')}
                                        Placeholder={i18next.t('tenderbet:Bet')}
                                        Value={props.DataDTO.Bet}
                                        Validator={this._numberValidator}
                                        MinValue={this.MinValueGetter}
                                        MaxValue={this.MaxValueGetter}
                                        Required={true}
                                        Step={100}
                                        Unit={i18next.t('common:rub')}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<TenderBetExtendedDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<TenderBetExtendedDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: TenderBetExtendedDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<TenderBetExtendedDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private MinValueGetter(){
        return this._minValue;
    }

    private MaxValueGetter(){
        return this._maxValue;
    }

    private CargoTransporterCellTextGetter(props: IVolmaTableProps<{}>, rowIndex: number) {
        const dto = props.Data[rowIndex] as TenderBetTableDTO;
        return dto.CargoTransporterType === ECargoTransporterType.MultiCargoTransporter ? dto.MultiCargoTransporterName : dto.CargoTransporterName;
    }
}