import { injectable } from 'inversify';
import * as React from 'react';
import * as uuid from 'uuid';
import { history } from '../../../ConfigureStore';
import { EMPTY_GUID } from '../../../Constants/AppConstants';
import { BiddingBetDTO } from '../../../Domain/DTO/BiddingBetDTO';
import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';
import { DeliveryAdditionalFilesDTO } from '../../../Domain/DTO/DeliveryAdditionalFilesDTO';
import { DeliveryDTO } from '../../../Domain/DTO/DeliveryDTO';
import { DeliveryPendingChangesItemDTO } from '../../../Domain/DTO/DeliveryPendingChangesItemDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { EntityUpdatedDTO } from '../../../Domain/DTO/EntityUpdatedDTO';
import { RouteSegmentDTO } from '../../../Domain/DTO/RouteSegmentDTO';
import { EDeliveryState } from '../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ERequestForDeliveryAssigner } from '../../../Domain/Enum/ERequestForDeliveryAssigner';
import { ERoutePointType } from '../../../Domain/Enum/ERoutePointType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { UrlFabric } from '../../../Infrastructure/ServerInteraction/UrlFabric';
import { DeliveryService } from '../../../Infrastructure/Services/DeliveryService';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { IStatus } from '../../../Infrastructure/Status/IStatus';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { VolmaTimeValidator } from '../../../Infrastructure/Validation/VolmaTimeValidator';
import { FileExtendedDTO } from '../../File/FileExtendedDTO';
import i18next from '../../i18n';
import VolmaTable from '../../Table/VolmaTable';
import { LocalDeleteItemsAction } from '../../Table/VolmaTable/Actions/LocalDeleteItemsAction';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { IVolmaTableAction, IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableDeliveryCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaCalendar from '../../VolmaCalendar';
import VolmaCheckBox from '../../VolmaCheckBox/VolmaCheckBox';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import VolmaNumber from '../../VolmaNumber';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaSelect from '../../VolmaSelect';
import VolmaTime from '../../VolmaTime';
import { IVolmaTimeProps } from '../../VolmaTime/IVolmaTimeProps';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { BiddingBetEntityService } from './ActionBet/BiddingBetEntityService';
import { AcceptDeliveriesAction } from './Actions/AcceptDeliveriesAction';
import { BiddingBetAction } from './Actions/BiddingBetAction';
import { CloseDeliveryAction } from './Actions/CloseDeliveryAction';
import { DeliveryRestartAssignationAction } from './Actions/DeliveryRestartAssignationAction';
import { DeliveryWithdrawAction } from './Actions/DeliveryWithdrawAction';
import { GetAccountAction } from './Actions/GetAccountAction';
import { GetInvoiceAction } from './Actions/GetInvoiceAction';
import { GetStatementAction } from './Actions/GetStatementAction';
import { LoadingEndAction } from './Actions/LoadingEndAction';
import { ManualAssignDeliveriesAction } from './Actions/ManualAssignDeliveryAction';
import { MarkAsDeliveredAction } from './Actions/MarkAsDeliveredAction';
import { MarkAsNotExecutedAction } from './Actions/MarkAsNotExecutedAction';
import { MassAuctionAction } from './Actions/MassAuctionAction';
import { NotAcceptDeliveriesAction } from './Actions/NotAcceptDeliveriesAction';
import { NotConfirmDeliveryAction } from './Actions/NotConfirmDeliveryAction';
import { RejectApprovedDeliveryAction } from './Actions/RejectApprovedDeliveryAction';
import { UpdateAssignTypeDeliveriesAction } from './Actions/UpdateAssignTypeAction';
import { UpdateVehicleDriverAction } from './Actions/UpdateVehicleDriverAction';
import { DeliveryComplaintEntityService } from './Complaint/DeliveryComplaintEntityService';
import { DeliveryActions } from './DeliveryActions';
import { DeliveryReducer } from './DeliveryReducer';
import { DeliveryStateEntityService } from './DeliveryState/DeliveryStateEntityService';
import { DeliveryHelperProps, InitialDeliveryHelperProps } from './IHelperProps';
import { InitialRouteSegmentTableHelperProps, IRouteSegmentTableHelperProps } from './RouteSegment/ITableHelperProps';
import { RoutePointEntityService } from './RouteSegment/RoutePointEntityService';
import { Acceptance } from './Status/Acceptance';
import { Assignation } from './Status/Assignation';
import { Confirmation } from './Status/Confirmation';
import { Created } from './Status/Created';
import { Delivery } from './Status/Delivery';
import { Finished } from './Status/Finished';
import { Waiting } from './Status/Waiting';
import { WaitingDelivery } from './Status/WaitingDelivery';
import { DeliveryTableActions } from './Table/DeliveryTableActions';
import { DeliveryTableReducer } from './Table/DeliveryTableReducer';
import { IDeliveryTableHelperProps } from './Table/ITableHelperProps';
import { DeliveryColumnTemplates } from './Subsidiary/DeliveryColumnTemplates';
import { isDefined } from '../../../Infrastructure/Services/Utils';
import { ChangeCargoTransporterAndConfirmAction } from './Actions/ChangeCargoTransporterAndConfirmAction';

@injectable()
export class DeliveryEntityService extends BaseEntityService<DeliveryDTO, DeliveryHelperProps>{
    protected _inputValidator: BaseValidator<IVolmaInputProps>;
    protected _numberValidator: BaseValidator<IVolmaNumberProps>;
    protected _timeValidator: BaseValidator<IVolmaTimeProps>;
    protected _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    protected _volmaTableService: VolmaTableService;
    protected _deliveryStateService: DeliveryStateEntityService;
    protected _complaintService: DeliveryComplaintEntityService;
    protected _routePointService: RoutePointEntityService;
    protected _biddingBetService: BiddingBetEntityService;
    protected _urlFabric: UrlFabric;
    protected _deliveryService: DeliveryService;
    protected _routerService: RouterService;
    protected _deliveryColumnTemplates: DeliveryColumnTemplates;

    protected _modalActions: VolmaModalActions
    protected _reducer: DeliveryReducer;
    protected _tableReducer: DeliveryTableReducer;
    protected _action: DeliveryActions;
    protected _tableAction: DeliveryTableActions;
    protected _dispatch: any;
    private _volmaModalAction: VolmaModalActions;

    private _id: string;
    private _dto: DeliveryDTO;
    private _state: EDeliveryState;

    private _routePointsCacheMarks: IRouteSegmentTableHelperProps;

    private _statesColumns: Array<IVolmaTableColumn>;
    private _complaintColumns: Array<IVolmaTableColumn>;
    private _routePointColumns: Array<IVolmaTableColumn>;
    private _biddingBetColumns: Array<IVolmaTableColumn>;

    private _prevNoopObject: any;
    private _cachedComponents: Array<JSX.Element>;

    constructor() {
        super();

        this._volmaTableService    = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._deliveryStateService = VolmaContainer.get<DeliveryStateEntityService>(Types.DeliveryStateEntityService);
        this._complaintService     = VolmaContainer.get<DeliveryComplaintEntityService>(Types.DeliveryComplaintEntityService);
        this._routePointService    = VolmaContainer.get<RoutePointEntityService>(Types.RoutePointEntityService);
        this._biddingBetService    = VolmaContainer.get<BiddingBetEntityService>(Types.BiddingBetEntityService);
        this._urlFabric            = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
        this._deliveryService      = VolmaContainer.get<DeliveryService>(Types.DeliveryService);
        this._routerService        = VolmaContainer.get<RouterService>(Types.RouterService);        

        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator         = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator        = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._timeValidator          = VolmaContainer.get<VolmaTimeValidator>(Types.VolmaTimeValidator);

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._reducer      = VolmaContainer.get<DeliveryReducer>(Types.DeliveryReducer);
        this._tableReducer = VolmaContainer.get<DeliveryTableReducer>(Types.DeliveryTableReducer);
        this._action       = VolmaContainer.get<DeliveryActions>(Types.DeliveryActions);
        this._tableAction  = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);

        this._deliveryColumnTemplates = new DeliveryColumnTemplates();

        this._statesColumns     = this._deliveryStateService.GetColumnsList();
        this._complaintColumns  = this._complaintService.GetColumnsList();
        this._routePointColumns = this._routePointService.GetColumnsList();
        this._biddingBetColumns = this._biddingBetService.GetColumnsList();

        this.AddLoadingPoint      = this.AddLoadingPoint.bind(this);
        this.AddUnloadingPoint    = this.AddUnloadingPoint.bind(this);
        this.EditRoutePoint       = this.EditRoutePoint.bind(this);
        this.OnAfterDataLoaded    = this.OnAfterDataLoaded.bind(this);

        this.DeliveryUpdated            = this.DeliveryUpdated.bind(this);        
        this.BiddingBetAdded            = this.BiddingBetAdded.bind(this);
        this.OnEntityUpdated            = this.OnEntityUpdated.bind(this);
        this.ReloadDeliveryAndChildTables = this.ReloadDeliveryAndChildTables.bind(this);

        this.AcceptPendingChanges = this.AcceptPendingChanges.bind(this);
        this.RejectPendingChanges = this.RejectPendingChanges.bind(this);

        this.ReloadAdditionalFiles       = this.ReloadAdditionalFiles.bind(this);
    }

    private DeliveryUpdated(dto: EntityUpdatedDTO){
        console.log('Delivery updated', JSON.stringify(dto));

        if (dto !== undefined && this._id !== undefined) {
            if (dto.Type === EEntityType.Delivery && dto.Id === this._id) {
                console.log('current delivery was updated');
                this.ReloadDeliveryAndChildTables();
            }
        }
    }

    private ReloadDeliveryAndChildTables(): void {
        if (isDefined(this._dispatch) && isDefined(this._id)) {
            this._action.ReloadEntity(this._dispatch, EEntityType.Delivery, this._id, i18next.t("common:Loading"), (dto) => {
                this.OnAfterDataLoaded(dto); 
                this.LoadChildTables(this._id)
            });
        }  
    }

    protected BiddingBetAdded(dto: BiddingBetDTO) {
        if (isDefined(this._dispatch)) {
            this._dispatch(this._tableAction.LoadUserBets());
        }
    }

    protected IsEntityStatusChangingAvailable(dto: DeliveryDTO, dataHelper: DeliveryHelperProps) {
        if (this._authService.IsCargoTransporter()) {
            return !this.HasPendingChanges(dto, dataHelper);
        }
        
        return true;
    }

    public IsEditable(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): boolean {
        if (this._dto !== undefined && this._dto !== null && this._dto.FromErp)
            return false;

        if (super.IsEditable(props)){
            return this._id === undefined || this._state === EDeliveryState.New || this._state === EDeliveryState.Revoked;
        }
        return false;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Delivery,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Id), HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.Identifier), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.LoadingDate), HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryTableDTO) => val.UnloadingDate), HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer },
            ]
        );
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<DeliveryHelperProps>> {
        return [
            new MassAuctionAction<DeliveryHelperProps>(),                   new AcceptDeliveriesAction<DeliveryHelperProps>(),
            new NotAcceptDeliveriesAction<DeliveryHelperProps>(),           new NotConfirmDeliveryAction<DeliveryHelperProps>(), 
            new UpdateAssignTypeDeliveriesAction<DeliveryHelperProps>(),    new MarkAsDeliveredAction<DeliveryHelperProps>(), 
            new ManualAssignDeliveriesAction<DeliveryHelperProps>(),        new DeliveryWithdrawAction<DeliveryHelperProps>(), 
            new BiddingBetAction<DeliveryHelperProps>(),                    new MarkAsNotExecutedAction<DeliveryHelperProps>(), 
            new UpdateVehicleDriverAction(),                                new DeliveryRestartAssignationAction<DeliveryHelperProps>(), 
            new CloseDeliveryAction<DeliveryHelperProps>(),                 new RejectApprovedDeliveryAction<DeliveryHelperProps>(), 
            new GetInvoiceAction<DeliveryHelperProps>(),                    new GetAccountAction<DeliveryHelperProps>(), 
            new GetStatementAction<DeliveryHelperProps>(),                  new LoadingEndAction<DeliveryHelperProps>(),
            new ChangeCargoTransporterAndConfirmAction<DeliveryHelperProps>(),
        ];
    }


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        if (this._authService.IsShipper())
            return [
                EEntityType.DeliveryFuture, 
                EEntityType.DeliveryActive, 
                EEntityType.DeliveryAuction, 
                EEntityType.DeliveryPerforming, 
                EEntityType.DeliveryManual, 
                EEntityType.DeliveryFinished, 
                EEntityType.DeliveryWithClaim
            ]
        else if (this._authService.IsCargoTransporter())
            return [
                EEntityType.DeliveryToAcceptConfirm, 
                EEntityType.DeliveryAuction, 
                EEntityType.DeliveryPerforming, 
                EEntityType.DeliveryFinished, 
                EEntityType.DeliveryWithClaim
            ]
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        if (this.IsEditable(props))
            return this.GetNewDeliveryEditor(props);

        return this.GetReadonlyEditor(props);
    }

    public GetStatusList(): Array<IStatus<DeliveryDTO, DeliveryHelperProps>> {
        return [new Created(), new Waiting(), new Assignation(), new Acceptance(), new Confirmation(), new WaitingDelivery(), new Delivery(), new Finished()]
    }

    public GetEntityId(props: IBaseEntityProps<DeliveryDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Identifier),
            (x: DeliveryTableDTO, id: string) => x.Identifier.toLowerCase() === id);
    }

    public GetHeaderEditor(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        const showName = (!isEditable && this.IsValueNotEmpty(props.DataDTO.Name)) || isEditable;
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Identifier) }
                        key={this._id}
                        Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Identifier)}
                        Label={i18next.t('delivery:Identifier')}
                        Placeholder={i18next.t('delivery:IdentifierPlaceholder')}
                        Value={props.DataDTO.Identifier}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>

                {showName && <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Name) }
                        key={this._id}
                        Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Name)}
                        Label={i18next.t('delivery:Name')}
                        Placeholder={i18next.t('delivery:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={false}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>}
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, id?: string) {
        this._dispatch = props.dispatch;
        this._id = undefined;
        this._dto = undefined;
        this._routePointsCacheMarks = JSON.parse(JSON.stringify(InitialRouteSegmentTableHelperProps));
        this._signalRService.EntityUpdated.On(this.DeliveryUpdated);
        this._signalRService.CargoTransporterChanged.On(this.ReloadDeliveryAndChildTables);
        this.LoadChildTables(id);
    }

    public DisposeEntity(){
        this._signalRService.EntityUpdated.Off(this.DeliveryUpdated);
        this._signalRService.CargoTransporterChanged.Off(this.ReloadDeliveryAndChildTables);
    }

    public GetReducer() {
        return this._reducer;
    }

    public InitializeDefaultDTOValues(dto: DeliveryDTO): void {
        if (!this.IsEntityEditCase(dto.Id)) {
            dto.CurrencyMultiplicity = 1;
        }
    }

    public GetInitialDataHelper() {
        return InitialDeliveryHelperProps;
    }

    private LoadChildTables(id: string){
        if (id !== undefined) {
            this._dispatch(this._action.LoadFile(id, i18next.t("common:Loading")));

            this._dispatch(this._action.LoadDeliveryStates(id, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadComplaints(id, i18next.t("common:Loading")));
        }
    }

    public OnAfterDataLoaded(dto: DeliveryDTO): void {
        this._id = dto.Id;
        this._dto = dto;
        this._state = dto.State;
        this._prevNoopObject = undefined;
        if(dto.Id !== undefined){
            if(dto.RouteSegments != undefined && dto.RouteSegments.length > 0){
                this._dispatch(this._action.RouteSegmentsLoaded(JSON.parse(JSON.stringify(dto.RouteSegments))));
                let isFirstPoint = true;
                for (const segment of dto.RouteSegments.sort((a, b) => (a.OrderNum > b.OrderNum) ? 1 : -1 )){
                    if (segment.PointType === ERoutePointType.Loading && this.IsValueNotEmpty(segment.LoadingPointId))
                    {
                        this.EnsureLoadingPointCached(segment.LoadingPointId);
                        if(isFirstPoint)
                        {
                            this.SetTimezone(segment.LoadingPointId);
                            isFirstPoint = false;
                        }
                    }
                    if (segment.PointType === ERoutePointType.Unloading && this.IsValueNotEmpty(segment.UnloadingPointId))
                        this.EnsureLocalityCached(segment.UnloadingPointId);
                    if (this.IsValueNotEmpty(segment.HandlingTypeId))
                        this.EnsureHandlingTypeCached(segment.HandlingTypeId);
                    if (this.IsValueNotEmpty(segment.PayloadId))
                        this.EnsurePayloadCached(segment.PayloadId);
                }
            }

            const pendingChanges = dto.PendingChanges && dto.PendingChanges.length ? dto.PendingChanges : [];
            this._dispatch(this._action.PendingChangesLoaded(JSON.parse(JSON.stringify(pendingChanges))));  

            if(this._dto.AssignType === ERequestForDeliveryAssigner.Bidding){
                this._dispatch(this._action.LoadBets(dto.Id, i18next.t("common:Loading")));
                this._dispatch(this._action.LoadWinnersBets(dto.Id, i18next.t("common:Loading")));
            }
            this._dispatch(this._action.LoadVehicle(dto.VehicleId, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadDriver(dto.DriverId, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadCargoTransporter(dto.CargoTransporterId, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadConsignor(dto.ConsignorId, i18next.t("common:Loading")));

            if (dto.ZoneId) {
                this._dispatch(this._action.LoadZone(dto.ZoneId, i18next.t("common:Loading")));
            }            
        }
    }

    private AddLoadingPoint(){
        const dto: RouteSegmentDTO = new RouteSegmentDTO();
        dto.Id = uuid.v4();
        dto.PointType = ERoutePointType.Loading;
        this._dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.RouteSegment, dto, undefined, (dto) => {
            this._dispatch(this._action.RoutePointAdded(dto));
            this.EnsureLoadingPointCached(dto.LoadingPointId);
            this.EnsureHandlingTypeCached(dto.HandlingTypeId);
            this.EnsurePayloadCached(dto.PayloadId);
        }))
    }

    private AddUnloadingPoint(){
        const dto: RouteSegmentDTO = new RouteSegmentDTO();
        dto.Id = uuid.v4();
        dto.PointType = ERoutePointType.Unloading;
        this._dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.RouteSegment, dto, undefined, (dto) => {
            this._dispatch(this._action.RoutePointAdded(dto));
            this.EnsureLocalityCached(dto.UnloadingPointId);
            this.EnsureHandlingTypeCached(dto.HandlingTypeId);
            this.EnsurePayloadCached(dto.PayloadId);
        }))
    }

    private EditRoutePoint(entity: RouteSegmentDTO){
        const dto = {...entity};
        this._dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.RouteSegment, dto, undefined, (dto) => {
            this._dispatch(this._action.RoutePointUpdated(dto));
            if(dto.PointType === ERoutePointType.Loading)
                this.EnsureLoadingPointCached(dto.LoadingPointId);
            else
                this.EnsureLocalityCached(dto.UnloadingPointId);
            this.EnsureHandlingTypeCached(dto.HandlingTypeId);
            this.EnsurePayloadCached(dto.PayloadId);

        }))
    }

    private DeleteRoutePoints(ids: Array<string>){
        this._dispatch(this._action.RoutePointsDeleted(ids));
    }

    private AddComplaint(){
        const entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        history.push(entityService.GetEntityRoute(EEntityType.ComplaintDraft, undefined, [{Key:"delivery", Value: this._id}]));
    }

    private SetTimezone(loadingPointId: string) {
        if(this.IsValueNotEmpty(loadingPointId))
            this._dispatch(this._action.LoadFirstLoadingPointAndSetTimeZone(loadingPointId, i18next.t("common:Loading")));
    }

    private EnsureLoadingPointCached(id: string) {
        if(this.IsValueNotEmpty(id)){
            if (this._routePointsCacheMarks.LoadingPoints.find(x => x.Id === id) === undefined) {
                this._routePointsCacheMarks.LoadingPoints.push({ Id: id });
                this._dispatch(this._action.LoadLoadingPoint(id, i18next.t("common:Loading")))
            }
        }
    }

    private EnsureLocalityCached(id: string) {
        if (this.IsValueNotEmpty(id)) {
            if (this._routePointsCacheMarks.Localities.find(x => x.Id === id) === undefined) {
                this._routePointsCacheMarks.Localities.push({ Id: id });
                this._dispatch(this._action.LoadLocality(id, i18next.t("common:Loading")))
            }
        }
    }

    private EnsureHandlingTypeCached(id: string) {
        if (this.IsValueNotEmpty(id)) {
            if (this._routePointsCacheMarks.HandlingTypes.find(x => x.Id === id) === undefined) {
                this._routePointsCacheMarks.HandlingTypes.push({ Id: id });
                this._dispatch(this._action.LoadHandlingType(id, i18next.t("common:Loading")))
            }
        }
    }

    private EnsurePayloadCached(id: string) {
        if (this.IsValueNotEmpty(id)) {
            if (this._routePointsCacheMarks.Payloads.find(x => x.Id === id) === undefined) {
                this._routePointsCacheMarks.Payloads.push({ Id: id });
                this._dispatch(this._action.LoadPayload(id, i18next.t("common:Loading")))
            }
        }
    }

    private AcceptPendingChanges(): void {
        if (this.IsValueNotEmpty(this._id)) {
            this._dispatch(this._modalActions.OpenConfirmationModal('delivery:AcceptPendingChanges', () => {
                this._dispatch(this._action.AcceptPendingChanges(this._id, i18next.t("common:Loading"))); 
            }));             
        }        
    }

    private RejectPendingChanges(): void {
        if (this.IsValueNotEmpty(this._id)) {
            this._dispatch(this._modalActions.OpenConfirmationModal('delivery:RejectPendingChanges', () => {
                this._dispatch(this._action.RejectPendingChanges(this._id, i18next.t("common:Loading")));
            }));            
        }
    }

    private GetNewDeliveryEditor(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                {this._id !== undefined && this.GetStatusPart(props)}

                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("delivery:CargoInfoTitle"), i18next.t("delivery:CargoInfoTitleDescription"), this.CargoInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.DescriptionPart(props))}
                        {this.GetMainInfoNoTitleItem(false, true, this.ConsignorPart(props))}
                    </div>
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("delivery:AssignInfoTitle"), i18next.t("delivery:AssignInfoTitleDescription"), this.AssignPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 2, i18next.t("delivery:RateInfoTitle"), i18next.t("delivery:RateInfoTitleDescription"), this.RateInfoPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 3, i18next.t("delivery:RoutePointTitle"), i18next.t("delivery:RoutePointTitleDescription"), this.RoutePart(props), [this.AddLoadingPointButton(props, 0), this.AddUnloadingPointButton(props, 1)], props, this._dispatch)}
                </div>

                {this.NonEmptyCollection(props.DataHelper.DeliveryStates) && <div className={this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 4, i18next.t("delivery:ChangelogTitle"), i18next.t("delivery:ChangelogTitleDescription"), this.ChangelogPart(props), undefined, props, this._dispatch)}
                </div>}

                {this.NonEmptyCollection(props.DataHelper.Complaints) && <div className={this._titleDropParent.mix([props.ClosedCardParts[5] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 5, i18next.t("delivery:ComplaintsTitle"), i18next.t("delivery:ComplaintsTitleDescription"), this.ComplaintsPart(props), [this.AddComplaintButton(props, 0)], props, this._dispatch)}
                </div>}
            </div>
        );
    }

    private GetReadonlyEditor(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        if (this._prevNoopObject !== undefined && this._prevNoopObject !== props.NoopObject){
            this._prevNoopObject = props.NoopObject;
            return (
                <div className={(this._mainInfo()).toString()}>
                    {this.GetStatusPart(props)}
                    {this._cachedComponents}
                </div>
            )
        }

        const showRequestForShipment = this.IsValueNotEmpty(this._dto.CargoTransporterId) && this.IsValueNotEmpty(this._dto.DriverId) && this.IsValueNotEmpty(this._dto.VehicleId);
        const showPendingChanges = this.HasPendingChanges(props.DataDTO, props.DataHelper);

        const components = new Array<JSX.Element>();

        let isDark = true;
        let index = 0;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("delivery:ReadonlyMainInfoTitle"), i18next.t("delivery:ReadonlyMainInfoTitleDescription"), this.ReadonlyMainInfoInfoPart(props), undefined, props, this._dispatch)}
            </div>
        );

        if (showPendingChanges)
        {
            index++;
            isDark = !isDark;
            const tableButtons = this._authService.IsCargoTransporter() ? [this.AddRejectPendingChangesButton(props, 0), this.AddAcceptPendingChangesButton(props, 1)] : undefined;
            components.push(
                <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("delivery:PendingChangesTitle"), i18next.t("delivery:PendingChangesTitleDescription"), this.PendingChangesPart(props), tableButtons, props, this._dispatch)}
                </div>
            );
        }
        

        if (showRequestForShipment)
        {
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("delivery:RequestForShipmentTitle"), i18next.t("delivery:RequestForShipmentTitleDescription"), this.RequestForShipmentDownload(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        index++;
        isDark = !isDark;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("delivery:RoutePointTitle"), i18next.t("delivery:RoutePointTitleDescription"), this.RoutePart(props), undefined, props, this._dispatch)}
            </div>
        );

        if (this.NonEmptyCollection(props.DataHelper.Bets) && PropertyHelper.SafeGetValue(props.DataDTO, x => x.AssignType) === ERequestForDeliveryAssigner.Bidding){
            index++;
            if(this._authService.IsShipper()){
                components.push(
                    <div key={index} className={this._rates.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                        {this.GetBetPart(props, index)}
                    </div>
                );
            }
            else{
                components.push(
                    <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                        {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("delivery:BetTitle"), i18next.t("delivery:BetTitleDescription"), this.GetBetPartForCargoTransporter(props), undefined, props, this._dispatch)}
                    </div>
                );
            }
        }

        if (this.NonEmptyCollection(props.DataHelper.DeliveryStates)){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, index, i18next.t("delivery:ChangelogTitle"), i18next.t("delivery:ChangelogTitleDescription"), this.ChangelogPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        if (this.NonEmptyCollection(props.DataHelper.Complaints)){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, index, i18next.t("delivery:ComplaintsTitle"), i18next.t("delivery:ComplaintsTitleDescription"), this.ComplaintsPart(props), [this.AddComplaintButton(props, 0)], props, this._dispatch)}
                </div>
            );
        }

        this._prevNoopObject = props.NoopObject
        this._cachedComponents = components;

        return (
            <div className={(this._mainInfo()).toString()}>
                {this.GetStatusPart(props)}

                {components}

            </div>
        );
    }

    private GetStatusPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>){
        const tableHelper: IDeliveryTableHelperProps = { AllUserBets: new Array<BiddingBetTableDTO>() };
        if (props.DataHelper.BetsOriginal !== undefined){
            const userBet = props.DataHelper.BetsOriginal.find(x => x.DeliveryId === this._id);
            if (userBet !== undefined)
                tableHelper.AllUserBets.push({ Bet: userBet.Bet, TenderId: this._id } as BiddingBetTableDTO)
            else
                tableHelper.AllUserBets.push({ TenderId: this._id } as BiddingBetTableDTO)
        }

        return this.GetStatusLine(
                this._id,
                this._dto,
                props.DataHelper,
                EEntityType.Tender,
                this._dispatch,
                this._action,
                props.OpenedStatusIndex,
                this._authService,
                () => {
                    setTimeout(() => {
                        this._action.ReloadEntity(props.dispatch, EEntityType.Delivery, this._id, i18next.t("common:Loading"), this.OnAfterDataLoaded)
                    }, 0);
                },
                tableHelper)
    }

    protected GetState(value: any): string{
        value = VolmaTableDeliveryCellRenderers.GetDisplayedStatus(value);
        return this._enumService.GetOptionLocalized(() => EDeliveryState, value);
    }

    private ReadonlyMainInfoInfoPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        const showRequestForShipment = this._dto.CargoTransporterId !== EMPTY_GUID;
        const showCargoTransporter = this.IsValueNotEmpty(this._dto.CargoTransporterId) && this._dto.State != EDeliveryState.Revoked;
        const showDriver = this.IsValueNotEmpty(this._dto.DriverId);
        const showVehicle = this.IsValueNotEmpty(this._dto.VehicleId);
        const showState = this.IsValueNotEmpty(this._dto.State);
        const showAssigner = this.IsValueNotEmpty(this._dto.AssignType);
        const showAllowance = this.IsValueNotEmpty(this._dto.Allowance) && this._authService.IsShipper();
        const showAuction = this.IsValueNotEmpty(this._dto.StartBiddingDate) && this.IsValueNotEmpty(this._dto.EndBiddingDate) && this._dto.AssignType === ERequestForDeliveryAssigner.Bidding;
        const showVolmaRecipientFlag = this.IsValueNotEmpty(this._dto.VolmaRecipient) && this._dto.VolmaRecipient;
        const showZone = this.IsValueNotEmpty(this._dto.ZoneId);

        let biddingStartTime;
        let biddingEndTime;
        if(showAuction) {
            biddingStartTime = this._timeService.ConvertToSpecifiedTimezone(PropertyHelper.SafeGetValue(props.DataDTO, x => x.StartBiddingDate), props.DataHelper.RoutePointsTableDataHelper.TimeZone);
            biddingEndTime = this._timeService.ConvertToSpecifiedTimezone(PropertyHelper.SafeGetValue(props.DataDTO, x => x.EndBiddingDate), props.DataHelper.RoutePointsTableDataHelper.TimeZone);
        }

        const baseRate = PropertyHelper.SafeGetValue(props.DataDTO, x => x.ActualRate);
        const currency = this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Currency));
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", {100: true}).toString()}>
                    <div className={this._mainInfo("line", { flex50: true }).toString()}>
                        <div className={(this._mainInfoSpecification).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:ConsignorId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Consignor, x => x.Name))}</dd>
                                </div>
                                {showVolmaRecipientFlag && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:VolmaRecipient")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{i18next.t("common:Volma")}</dd>
                                </div> }
                                <div className={(this._mainInfoDescription).toString()}>
                                    <p className={(this._mainInfoDescription("text")).toString()}>
                                        <span className={(this._mainInfoDescription("title")).toString()}>{i18next.t("delivery:SalesDepartment")}:{" "}</span>
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.SalesDepartment))}</p>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:Weight")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <svg className="wheel"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#wheel1"></use></svg>
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Weight), i18next.t("common:kg"))}
                                    </dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:Volume")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <svg className="size-vertical"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#size-vertical"></use></svg>
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Volume), i18next.t("common:m3"))}
                                    </dd>
                                </div>
                                {!this._dto.FromErp && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:Dimensions")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <svg className="size-horizontal"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#size-horizontal"></use></svg>
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Width), i18next.t("common:m"))}{" "}x{" "}
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Height), i18next.t("common:m"))}{" "}x{" "}
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Depth), i18next.t("common:m"))}
                                    </dd>
                                </div>}
                                <div className={(this._mainInfoDescription).toString()}>
                                    <p className={(this._mainInfoDescription("text")).toString()}>
                                        <span className={(this._mainInfoDescription("title")).toString()}>{i18next.t("delivery:Description")}:{" "}</span>
                                        {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Description))}</p>
                                </div>
                            </dl>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:BaseRate")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <span>{this.GetSafeMoney(baseRate)}{' '}{currency}</span>
                                    </dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:Currency")}:{' '}</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <span>{currency}</span>
                                    </dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:ExchangeRate")}:{' '}</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <span>{this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ExchangeRate))}{' '}</span>
                                        <svg className="rub"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use></svg>
                                    </dd>
                                </div>
                                {showAllowance && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:Allowance")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <svg className="rub"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use></svg>
                                        {this.GetSafeMoney(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Allowance))}
                                    </dd>
                                </div> }
                            </dl>
                        </div>
                    </div>
                    <div className={this._mainInfo("line", { flex50: true }).toString()}>
                        <div className={(this._mainInfoSpecification).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                {showState && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:State")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetState(PropertyHelper.SafeGetValue(props.DataDTO, x => x.State))}</dd>
                                </div>}
                                {showAssigner && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:AssignType")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this._enumService.GetOptionLocalized(() => ERequestForDeliveryAssigner, PropertyHelper.SafeGetValue(props.DataDTO, x => x.AssignType))}</dd>
                                </div>}
                            </dl>
                            {showAuction && <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:StartBiddingDate")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(biddingStartTime)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:EndBiddingDate")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(biddingEndTime)}</dd>
                                </div>
                            </dl>}
                            {<dl className={(this._mainInfoSpecification("box")).toString()}>
                                {showCargoTransporter && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:CargoTransporterId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.CargoTransporter, x => x.Name)}</dd>
                                </div>}
                                {showZone && <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:ZoneId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Zone, x => x.Name))}</dd>
                                </div>}
                            </dl>}
                            
                            {showDriver && showVehicle && <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:VehicleId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Vehicle, x => x.Name)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:DriverId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Driver, x => x.Name)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("delivery:ContainerId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.ContainerId) || "-"}</dd>
                                </div>
                            </dl>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private RequestForShipmentDownload(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { flex50: true, indent: true, "no-right-padding": true, full: true }).toString()}>
                        <div className={this._volmaInput({"no-padding":true}).toString()}>
                            <span className={this._volmaInput("input", { text: true }).mix("input").toString()}>{i18next.t("delivery:RequestForShipment")}:</span>
                        </div>
                    </div>
                    <div className={this._mainInfo("line", { flex50: true, indent: true, "no": "right-padding", full: true }).toString()}>
                        <div className={(this._volmaFile).toString()}>
                            <a className={this._volmaFile("input", { clear: true, "no": "border" }).mix("input").toString()} href={this._urlFabric.RequestForShipment(this._id)} target="_blank">
                                <div className={(this._volmaFile("clear")).toString()}>
                                    <svg className={this._volmaFile("clear-ico").toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                    </svg>
                                    <span className={this._volmaFile("clear-text").toString()}>{i18next.t("delivery:RequestForShipmentDownload")}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>                
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        {this.GetAdditionalFilesCoponent(props)}
                    </div>
                </div>
            </div>
        );
    }

    private GetAdditionalFilesCoponent(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        const files = [];
        const showUploadButton = props.DataDTO.State == EDeliveryState.Delivered || props.DataDTO.State == EDeliveryState.Closed || props.DataDTO.State == EDeliveryState.ClosedOld;
        if (props.DataHelper != undefined && props.DataHelper.AdditionalFiles !== undefined && props.DataHelper.AdditionalFiles.length > 0) {
            for (const file of props.DataHelper.AdditionalFiles) {
                files.push(<div className={(this._volmaMultifile("row")).toString()}>
                    <div className={this._volmaMultifile("row-center", {"delivery": true}).toString()}>
                        <span className={(this._volmaMultifile("name")).toString()}>{file.Name}</span>
                        <span className={(this._volmaMultifile("info")).toString()}>{i18next.t("complaint:FileLoaded", { timestamp: this.GetSafeDateTime(file.Timestamp), user: this.GetSafeString(file.UserFio) } as any)}</span>
                    </div>
                    <div className={this._volmaMultifile("row-right").toString()}>
                        <a href={this._urlFabric.FileDownload(file.Id)} target="_blank">
                            <svg className={this._volmaMultifile("row-right-ico").mix(["file-upload-ico"]).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                )
            }
        }

        const noFiles = (
            <div className={(this._volmaMultifile("row")).toString()}>
                <div className={this._volmaMultifile("row-full").toString()}>
                    <span className={(this._volmaMultifile("name")).toString()}>{i18next.t("delivery:NoAdditionalFiles")}</span>
                </div>
            </div>
        );
        return (
            <div className={this._volmaMultifile({"bottom-padding": true}).toString()}>
                <div className={(this._volmaMultifile("left")).toString()}>
                    <span className={(this._volmaMultifile("title")).toString()}>{i18next.t('delivery:AdditionalFiles')}</span>
                </div>
                <div className={this._volmaMultifile("center", {"right-border" : !showUploadButton}).toString()}>
                    {files.length > 0 && files}
                    {files.length === 0 && noFiles}
                </div>
                {showUploadButton && <div className={(this._volmaMultifile("right")).toString()}>
                    <a onClick={(() => this.OnAddAdditionalFileClicked()).bind(this)} className={(this._volmaMultifile("add")).toString()}>
                        <svg className={this._volmaMultifile("add-ico").toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                        </svg>
                    </a>
                </div>}
            </div>
        );
    }

    private OnAddAdditionalFileClicked() {
        this._dispatch(this._volmaModalAction.OpenFileUploadModal(i18next.t("delivery:AttachedFiles"), (fileDTO: FileExtendedDTO) => {
            const dto = new DeliveryAdditionalFilesDTO();
            dto.FilesIds = this._dto.AttachedFiles !== undefined ? this._dto.AttachedFiles.slice(0) : [];
            dto.FilesIds.push(fileDTO.FileId);
            this._dispatch(this._action.AddAdditionalFiles(this._id, dto, this.ReloadAdditionalFiles));
        }))
    }

    private ReloadAdditionalFiles(): void {
        if(this._id !== undefined)
            this._dispatch(this._action.LoadFile(this._id, i18next.t("common:Loading")));
    }

    private CargoInfoPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>) : JSX.Element{
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 25: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Weight) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Weight)}
                            Label={i18next.t('delivery:Weight')}
                            Placeholder={i18next.t('delivery:Weight')}
                            Value={props.DataDTO.Weight}
                            MinValue={() => 1}
                            Step={1}
                            Required={true}
                            Unit={i18next.t('common:kg')}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 25: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Volume) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Volume)}
                            Label={i18next.t('delivery:Volume')}
                            Placeholder={i18next.t('delivery:Volume')}
                            Value={props.DataDTO.Volume}
                            MinValue={() => 0.1}
                            Step={0.1}
                            Required={true}
                            Unit={i18next.t('common:m3')}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>

                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Width) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Width)}
                            Label={i18next.t('delivery:Width')}
                            Placeholder={i18next.t('delivery:Width')}
                            Value={props.DataDTO.Width}
                            MinValue={() => 1}
                            Step={1}
                            Required={true}
                            Unit={i18next.t('common:m')}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Height) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Height)}
                            Label={i18next.t('delivery:Height')}
                            Placeholder={i18next.t('delivery:Height')}
                            Value={props.DataDTO.Height}
                            MinValue={() => 1}
                            Step={1}
                            Required={true}
                            Unit={i18next.t('common:m')}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Depth) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Depth)}
                            Label={i18next.t('delivery:Depth')}
                            Placeholder={i18next.t('delivery:Depth')}
                            Value={props.DataDTO.Depth}
                            MinValue={() => 1}
                            Step={1}
                            Required={true}
                            Unit={i18next.t('common:m')}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private DescriptionPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Description) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Description)}
                            Type="textarea"
                            Label={i18next.t('delivery:Description')}
                            Value={props.DataDTO.Description}
                            Validator={this._inputValidator}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ConsignorPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryDTO) => val.ConsignorId) as any) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.ConsignorId)}
                            Label={i18next.t('delivery:ConsignorId')}
                            Placeholder={i18next.t('delivery:SelectConsignor')}
                            Required={true}
                            Entity={EEntityType.Consignor}
                            Value={props.DataDTO.ConsignorId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCheckBox
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.VolmaRecipient) }
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.VolmaRecipient)}
                            Label={i18next.t('delivery:VolmaRecipientSelect')}
                            Checked={props.DataDTO.VolmaRecipient}
                            Required={true}
                            dispatch={props.dispatch}
                            Id = "volma-checkbox-delivery-withdraw"/>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryDTO) => val.ZoneId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.ZoneId)}
                            Label={i18next.t('delivery:ZoneId')}
                            Placeholder={i18next.t('delivery:SelectZone')}
                            Required
                            Entity={EEntityType.Zone}
                            Value={props.DataDTO.ZoneId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }



    private AssignPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryDTO) => val.AssignType) as any) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.AssignType)}
                            Label={i18next.t('delivery:AssignType')}
                            Placeholder={i18next.t('delivery:SelectAssignType')}
                            EnumGetter={() => ERequestForDeliveryAssigner}
                            Value={props.DataDTO.AssignType}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCalendar
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.StartingDate) as any) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.StartingDate)}
                            Label={i18next.t('delivery:StartingDateDate')}
                            Required={true}
                            Value={props.DataDTO.StartingDate}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTime
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.StartingDate) as any) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.StartingDate)}
                            Label={i18next.t('delivery:StartingDateTime')}
                            Required={true}
                            Value={props.DataDTO.StartingDate}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private RateInfoPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.BaseRate) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.BaseRate)}
                            Label={i18next.t('delivery:BaseRate')}
                            Placeholder={i18next.t('delivery:BaseRate')}
                            Value={props.DataDTO.BaseRate}
                            MinValue={() => 0}
                            Step={1}
                            Required={true}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={(this._mainInfo('line', { fill: true })).toString()}>
                        <VolmaInput 
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.Currency) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Currency)}
                            Label={i18next.t('delivery:Currency')}
                            Value={props.DataDTO.Currency}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo('cell')).toString()}>
                    <div className={(this._mainInfo('line', { fill: true })).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.ExchangeRate) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.ExchangeRate)}
                            Label={i18next.t('delivery:ExchangeRate')}
                            Placeholder={i18next.t('delivery:ExchangeRate')}
                            Value={props.DataDTO.ExchangeRate}
                            MinValue={() => 0}
                            Step={0.0001}
                            Unit={i18next.t('common:rub')}
                            Required={true}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={(this._mainInfo('line', { fill: true })).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDTO) => val.CurrencyMultiplicity) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.CurrencyMultiplicity)}
                            Label={i18next.t('delivery:CurrencyMultiplicity')}
                            Placeholder={i18next.t('delivery:CurrencyMultiplicity')}
                            Value={props.DataDTO.CurrencyMultiplicity}
                            MinValue={() => 0}
                            Step={1}
                            Required={true}
                            Validator={this._numberValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private RoutePart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        let actions = [];
        let onRowEdit = undefined;
        if(this.IsEditable(props)){
            actions = [new LocalDeleteItemsAction<DeliveryHelperProps>((ids: Array<string>) => { this.DeleteRoutePoints(ids) })];
            onRowEdit = this.EditRoutePoint
        }
        return (            
            <div className={(this._mainInfo("row")).toString()}>             
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryDTO) => val.RouteSegments) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.RouteSegments)}
                            Columns={this._routePointColumns}
                            Data={props.DataHelper.RouteSegments}
                            DataOriginal={props.DataHelper.RouteSegments}
                            DataHelper={props.DataHelper.RoutePointsTableDataHelper}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={onRowEdit}
                            Actions={actions}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>              
        );
    }

    private PendingChangesPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (  
            <>
                {this.GetSAPChangesAwaitingWarning(props)}
                <div className={(this._mainInfo("row")).toString()}>              
                    <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <ExperimentalVolmaTable
                                {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryDTO) => val.PendingChanges) }
                                key={this._id}
                                Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.PendingChanges)}
                                Columns={this.GetPendingChangesColumnsColumnsList()}
                                Data={props.DataHelper.PendingChanges.filter(x => x.NeedConfirm)}
                                DataOriginal={props.DataHelper.PendingChangesOriginal}
                                LoadDataFromServer={false}
                                IsTableInsideEntity={true}
                                Actions={[]}
                                HideActions
                                dispatch={props.dispatch}/>
                        </div>
                    </div>
                </div> 
            </>                        
        );
    }

    private GetSAPChangesAwaitingWarning(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        const showWarn = this._authService.IsCargoTransporter() && new WaitingDelivery().IsCurrent(props.DataDTO);

        if (!showWarn) {
            return undefined;
        }

        return <>
            <div className={(this._mainInfo("warn")).toString()}>
                <div className={(this._mainInfo("warn", { text: true })).toString()}>       
                    <h3>{i18next.t("delivery:PendingChangesAttention")}</h3>
                    <p>{i18next.t("delivery:PendingChangesWarning_Part1")}</p>
                    <p>{i18next.t("delivery:PendingChangesWarning_Part2")}</p>
                </div>
            </div>
        </>
    }

    private HasPendingChanges(dto: DeliveryDTO, dataHelper: DeliveryHelperProps) {
        return dataHelper.PendingChanges && dataHelper.PendingChanges.length && dto.IsPending;
    }

    private GetPendingChangesColumnsColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Delivery,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryPendingChangesItemDTO) => val.FieldName), HeaderRenderer: VolmaTableHeaderRenderers.DefaultHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryPendingChangesItemDTO) => val.OldValue), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryPendingChangesItemDTO) => val.NewValue), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );
    }


    private GetBetPartForCargoTransporter(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryHelperProps) => val.Bets) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryHelperProps) => x.Bets)}
                            Columns={this._biddingBetColumns}
                            Data={props.DataHelper.Bets}
                            DataOriginal={props.DataHelper.BetsOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetBetPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, index: number): JSX.Element{
        const firstPlace: BiddingBetTableDTO  = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[0] : undefined;
        const secondPlace: BiddingBetTableDTO = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[1] : undefined;
        const thirdPlace: BiddingBetTableDTO  = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[2] : undefined;
        return (
            <div>
                <div className={this._ratesWinner({drop: true, info: true}).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._titleDrop).toString()}>
                            <div className={(this._titleDrop("left")).toString()}>
                                <h2 className={this._titleDrop("title").mix([this._mainInfo("title")]).toString()}>{i18next.t("tenderbet:BetsTitle")}</h2>
                                <span className={(this._titleDrop("text")).toString()}>{i18next.t("tenderbet:BetsTitleDescription")}</span>
                            </div>
                            <div className={(this._titleDrop("right")).toString()}>
                                <span onClick={(e: any) => {props.dispatch(this._action.ToggleInfoLine(index))}} className={this._titleDrop("link").mix([props.ClosedCardParts[index] ? "active" : ""]).toString()}> </span>
                            </div>
                        </div>
                        <div className={(this._infoDrop).toString()}>
                            <div className={(this._ratesWinner("flex")).toString()}>
                                {firstPlace &&
                                <div className={this._ratesWinner("item", {first: true}).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:FirstPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={this._ratesWinner("company-title").toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={this._ratesWinner("company-name").toString()}>{this.GetSafeString(firstPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={this._ratesWinner("stake-title").toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={this._ratesWinner("stake-val").toString()}>{this.GetSafeMoney(firstPlace.Bet)}{" "}{props.DataDTO.Currency}</div>
                                    </div>
                                </div>}
                                {secondPlace &&
                                <div className={this._ratesWinner("item", {second: true}).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:SecondPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={this._ratesWinner("company-title").toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={this._ratesWinner("company-name").toString()}>{this.GetSafeString(secondPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={this._ratesWinner("stake-title").toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={this._ratesWinner("stake-val").toString()}>{this.GetSafeMoney(secondPlace.Bet)}{" "}{props.DataDTO.Currency}</div>
                                    </div>
                                </div>}
                                {thirdPlace &&
                                <div className={this._ratesWinner("item", {third: true}).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:ThirdPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={this._ratesWinner("company-title").toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={this._ratesWinner("company-name").toString()}>{this.GetSafeString(thirdPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={this._ratesWinner("stake-title").toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={this._ratesWinner("stake-val").toString()}>{this.GetSafeMoney(thirdPlace.Bet)}{" "}{props.DataDTO.Currency}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this._infoDrop).toString()}>
                    <VolmaTable
                        {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryHelperProps) => val.Bets) }
                        key={this._id}
                        Name={PropertyHelper.GetPropertyName((x: DeliveryHelperProps) => x.Bets)}
                        Columns={this._biddingBetColumns}
                        Data={props.DataHelper.Bets}
                        DataOriginal={props.DataHelper.BetsOriginal}
                        LoadDataFromServer={false}
                        IsTableInsideEntity={true}
                        Actions={[]}
                        dispatch={props.dispatch}/>
                </div>
            </div>
        );
    }

    private ChangelogPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryDTO) => val.States) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.States)}
                            Columns={this._statesColumns}
                            Data={props.DataHelper.DeliveryStates}
                            DataOriginal={props.DataHelper.DeliveryStatesOriginal}
                            DataHelper={props.DataHelper.ChangelogTableDataHelper}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            AlternateRowClass={this._deliveryStateService.GetTableRowClass}
                            Actions={[]}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    private ComplaintsPart(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryDTO) => val.Complaints) }
                            key={this._id}
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.Complaints)}
                            Columns={this._complaintColumns}
                            Data={props.DataHelper.Complaints}
                            DataOriginal={props.DataHelper.ComplaintsOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    private AddLoadingPointButton(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, key: any): JSX.Element{
        return (
            <a key={key} onClick={this.AddLoadingPoint}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("delivery:AddLoadingPoint")}</span>
            </a>
        );
    }

    private AddUnloadingPointButton(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, key: any): JSX.Element{
        return (
            <a key={key} onClick={this.AddUnloadingPoint}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("delivery:AddUnloadingPoint")}</span>
            </a>
        );
    }

    private AddComplaintButton(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, key: any): JSX.Element{
        return (
            <a key={key} onClick={this.AddComplaint.bind(this)}
             className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("delivery:AddComplaint")}</span>
            </a>
        );
    }

    private AddAcceptPendingChangesButton(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, key: any): JSX.Element{
        return (
            <a key={key} onClick={this.AcceptPendingChanges}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <span className={(this._btn("text")).toString()}>{i18next.t("delivery:AddAcceptPendingChanges")}</span>
            </a>
        );
    }

    private AddRejectPendingChangesButton(props: IBaseEntityProps<DeliveryDTO, DeliveryHelperProps>, key: any): JSX.Element{
        return (
            <a key={key} onClick={this.RejectPendingChanges}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, red: true })]).toString()}>
                <span className={(this._btn("text")).toString()}>{i18next.t("delivery:AddRejectPendingChanges")}</span>
            </a>
        );
    }

    public GetOnEntityUpdated(): (dto: EntityUpdatedDTO, debounce:() => void, dispatchFilteredData:() => void) => void
    {
        return this.OnEntityUpdated;
    }

    protected OnEntityUpdated(dto: EntityUpdatedDTO, debounce:() => void, dispatchFilteredData:() => void) {
        const currentDeliveryTableEntities = this._deliveryService.GetEntityByDelivery((dto.DTO as DeliveryDTO).State, (dto.DTO as DeliveryDTO).AssignType);
        if (currentDeliveryTableEntities.findIndex(x => x === this._entity) > -1)
            debounce();
        else{
            const prevDeliveryTableEntities = new Array<EEntityType>();
            if(PropertyHelper.IsString(dto.PrevObject) && dto.PrevObject.length > 0){
                try{
                    const prevDTO: DeliveryDTO = JSON.parse(dto.PrevObject);
                    const prevDeliveryTableEntities = this._deliveryService.GetEntityByDelivery(prevDTO.State, prevDTO.AssignType);
                    if (prevDeliveryTableEntities.findIndex(x => x === this._entity) > -1){
                        dispatchFilteredData();
                    }
                }
                catch(error){}
            }
        }
    }
}