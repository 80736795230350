import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryCloseDTO } from '../ActionClose/DeliveryCloseDTO';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import i18next from '../../../i18n';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TableDataDTO } from '../../../../Domain/TableDataDTO';

export class CloseDeliveryAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:CloseDelivery";

    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean{
        if (this._authService.IsAdministrator()) {
            if (!PropertyHelper.IsArray(selectedIds) || selectedIds.length < 1)
                return false;

            for (let selectedId of selectedIds) {
                let itm = data.find(x => x.Id === selectedId);
                if (itm === undefined)
                    return false
                if (itm.State !== EDeliveryState.Delivered)
                    return false
            }
            return true;
        }
        return false;
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {

        let dto = new DeliveryCloseDTO();
        let header = i18next.t("delivery:CloseActionHeader");
        dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryOneComment, dto, header, (dto) => {
            let tableDataDTO = new TableDataDTO<DeliveryStateDTO>();
            tableDataDTO.Items = new Array<DeliveryStateDTO>();
            tableDataDTO.Count = 0;
            for (let selectedId of selectedIds) {
                let stateDTO = new DeliveryStateDTO();
                stateDTO.DeliveryId = selectedId;
                stateDTO.DeliveryState = EDeliveryState.Closed;
                stateDTO.CommentsForCargoTransporter = dto.Comment;
                stateDTO.CommentsForShipper = dto.Comment;
                tableDataDTO.Items.push(stateDTO);
                tableDataDTO.Count++;
            }

            dispatch(this._deliveryTableActions.DeliveryAddStates(tableDataDTO, i18next.t("common:Loading"), reloadData));
        }))
    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}