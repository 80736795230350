import * as React from 'react';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { IEntityDTO } from '../../Domain/IEntityDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { IGlobalState } from '../../Reducers/IGlobalState';
import i18next from '../i18n';
import { IVolmaModalProps } from './IVolmaModalProps';
import { IVolmaModalNotificationPayload } from './Payloads';
import { VolmaModalActions } from './VolmaModalActions';

class VolmaModalNotification extends Component<IVolmaModalProps<IEntityDTO, any>, {}>{
    private _modalActions: VolmaModalActions;
    private _text: string;
    private _header: string;
    private _onClosed: () => void;

    private _modal        = volmaBlock("modal");
    private _modalWrap    = volmaBlock("modal-wrap");
    private _modalConfirm = volmaBlock("modal-confirm");
    private _lightInput   = volmaBlock("light-input");
    private _btnModal     = volmaBlock("btn-modal");
    private _modalCont    = volmaBlock("modal-cont");

    constructor(props: IVolmaModalProps<any, any>, context: any) {
        super(props, context);

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

        const state = this.props.Props as IVolmaModalNotificationPayload;
        this._text = state.Text;
        this._onClosed = state.OnClosed;
    }

    public render() {
        return (
            <div className={(this._modal.mix(["active"])).toString()}>
                <div className={(this._modalWrap).toString()}>
                    <div className={(this._modalConfirm).toString()}>
                        <form className={(this._modalConfirm("form")).toString()}>
                            <div className={(this._modalConfirm("form-row")).toString()}>
                                <div className={(this._modalConfirm("form-cell", {full: true })).toString()}>
                                    <div className={(this._modalConfirm("form-text")).toString()}>
                                        {i18next.t(this._text)}
                                    </div>
                                </div>
                            </div>

                            <div className={(this._modalConfirm("form-row", {center: true})).toString()}>
                                <div className={(this._modalConfirm("form-cell")).toString()}>
                                    <button onClick={this.OnCancelClick.bind(this)} className={(this._btnModal({large: true, green: true }).mix(["btn"])).toString()}>{i18next.t('common:ButtonCancel')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        )
    }

    private OnCancelClick() {
        this._onClosed();
        this.props.dispatch(this._modalActions.CloseModal());
    }

}

const translated = withTranslation(['common'])(withRouter(VolmaModalNotification));

function select(state: IGlobalState, ownProps: IVolmaModalProps<any, any>): IVolmaModalProps<any, any> {
    return state.ModalEntity;
}

export const ConnectedVolmaModalNotification = connect<IVolmaModalProps<any, any>, {}, {}>(select)(translated);