import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';

export class MultiCargoTransporterHelperProps {
    SelectedCargoTransporters: Array<CargoTransporterTableDTO>

    CurrentRating: Array<RatingTableDTO>
    CurrentRatingOriginal: Array<RatingTableDTO>
    ForecastRating: Array<RatingTableDTO>
}

export const InitialMultiCargoTransporterHelperProps: MultiCargoTransporterHelperProps = <MultiCargoTransporterHelperProps>{
    SelectedCargoTransporters: [],

    CurrentRating: [],
    CurrentRatingOriginal: [],
    ForecastRating: [],
};