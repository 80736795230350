import * as uuid from 'uuid';
import { CargoTransporterRouteRateTableDTO } from '../../../../../Domain/DTO/CargoTransporterRouteRateTableDTO';
import {
    CARGO_TRANSPORTER_ROUTE_RATE_ADDED,
    CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT,
    CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT,
    CARGO_TRANSPORTER_ROUTE_RATE_UPDATED,
    CARGO_TRANSPORTER_ROUTE_RATES_LOADED,
    RATING_TABLE_FORECAST_LOADED
} from '../../../../../Constants/AppConstants';
import { RatingTableDTO } from '../../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../../Domain/Enum/EEntityType';
import { IActionPayloaded, IAction } from '../../../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../../../Infrastructure/InversifyInject';
import { RatingServerInteraction } from '../../../../../Infrastructure/ServerInteraction/RatingServerInteraction';
import { Types } from '../../../../../Infrastructure/Types';
import { EntityAction } from '../../../BaseEntity/EntityAction';
import i18next from '../../../../i18n';
import { CargoTransporterRouteRateDTO } from '../../../../../Domain/DTO/CargoTransporterRouteRateDTO';
import { ICargoRouteRateEditRequired } from './Payload';
import { ECargoTransporterRouteRateEditingEntity } from './EEditingEntity';
import { injectable } from 'inversify';
import { RouteRateTableDTO } from '../../../../../Domain/DTO/RouteRateTableDTO';
import { ESortDir } from '../../../../../Domain/Enum/ESortDir';

@injectable()
export class CargoRouteRateTableActions extends EntityAction{

    constructor() {
        super();
    }

    public EditVehicleCount(tableDTO: CargoTransporterRouteRateTableDTO): IActionPayloaded<ICargoRouteRateEditRequired>{
        let dto = {...tableDTO};
        return { type: CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT, Payload: {EditingEntity: ECargoTransporterRouteRateEditingEntity.VehicleCount, EditingDTO: dto}}
    }

    public EditRate(tableDTO: CargoTransporterRouteRateTableDTO): IActionPayloaded<ICargoRouteRateEditRequired>{
        let dto = {...tableDTO};
        return { type: CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT, Payload: { EditingEntity: ECargoTransporterRouteRateEditingEntity.Rate, EditingDTO: dto } }
    }

    public EndEditing(): IAction{
        return { type: CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT }
    }

    public SaveData(dto: CargoTransporterRouteRateDTO){
        // console.log("saving entity", JSON.stringify(dto))
        // if(dto.Id === undefined){
        //     dto.Id = uuid.v4();
        //     return this.CargoTransporterRouteRateAdded(dto);
        // }
        // else{
        //     return this.CargoTransporterRouteRateUpdated(dto);
        // }
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => dto.Id === undefined ? this._apiTable.PostEntity(EEntityType.CargoTransporterRouteRate, dto) : this._apiTable.PutEntity(EEntityType.CargoTransporterRouteRate, dto.Id, dto),
                i18next.t("common:Loading"),
                (response) => {
                    let data = response.data;
                    let savedId = data.Data;
                    if(dto.Id === undefined){
                        dto.Id = savedId;
                        dispatch(this.CargoTransporterRouteRateAdded(dto));
                    }
                    else{
                        dispatch(this.CargoTransporterRouteRateUpdated(dto));
                    }

                })
        }
    }

    public LoadCargoTransporterRouteRates() {
        return super.LoadDataTable<CargoTransporterRouteRateTableDTO>(
            EEntityType.CargoTransporterRouteRate,
            x => x.Rate,
            ESortDir.Asc,
            undefined,
            i18next.t("common:Loading"),
            this.CargoTransporterRouteRatesLoaded);
    }

    private CargoTransporterRouteRatesLoaded(value: Array<CargoTransporterRouteRateTableDTO>): IActionPayloaded<Array<CargoTransporterRouteRateTableDTO>> {
        return { type: CARGO_TRANSPORTER_ROUTE_RATES_LOADED, Payload: value };
    }

    private CargoTransporterRouteRateAdded(dto: CargoTransporterRouteRateTableDTO): IActionPayloaded<CargoTransporterRouteRateTableDTO> {
        return { type: CARGO_TRANSPORTER_ROUTE_RATE_ADDED, Payload: dto };
    }

    private CargoTransporterRouteRateUpdated(dto: CargoTransporterRouteRateTableDTO): IActionPayloaded<CargoTransporterRouteRateTableDTO> {
        return { type: CARGO_TRANSPORTER_ROUTE_RATE_UPDATED, Payload: dto };
    }
}