import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import { BaseCustomLoadSaveComponent } from '../BaseCustomLoadSave/BaseCustomLoadSaveComponent';
import { IReportProps } from './IReportProps';
import { ReportComponentActions } from './ReportComponentActions';
import { ReportEntityService } from './ReportEntityService';


class Report extends BaseCustomLoadSaveComponent<IReportProps>{
    private _reportComponentActions: ReportComponentActions;
    private _reportEntityService: ReportEntityService;

    constructor(props: IReportProps, context: any) {
        super(props, context, EEntityType.Report);

        this._key = "report";
        this._formClassName = "volma-form--report";
        this._createCardModifiers = { "no-bg": true };
        this._reportComponentActions = VolmaContainer.get<ReportComponentActions>(Types.ReportComponentActions);
        this._reportEntityService = VolmaContainer.get<ReportEntityService>(Types.ReportEntityService);
        super.SetActions(this._reportComponentActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        this._reportEntityService.DisposeEntity();
    }

    public componentDidMount() {
        super.componentDidMount();
        this.props.dispatch(this._reportComponentActions.Submit());
    }

    protected RouterWillLeave() {
        return true;
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public LoadData() {
        this._reportEntityService.InitializeEntity(this.props);
        this.props.dispatch(this._reportComponentActions.FakeLoadData());
        this.props.dispatch(this._reportComponentActions.SetEntityType(EEntityType.Report));

    }

    public IsEditable(): boolean {
        return false
    }
}

const translated = withTranslation(['common'])(withRouter(Report));

function select(state: IGlobalState, ownProps: IReportProps): IReportProps {
    return state.Report;
}

let connectedReportEntity = connect<IReportProps, {}, {}>(select)(translated);
export default connectedReportEntity;
