import { EntityService } from '../../../../Infrastructure/Services/EntityService';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import i18next from '../../../i18n';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { Types } from '../../../../Infrastructure/Types';
import { ITableDTO } from '../../../../Domain/ITableDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { Saved } from '../Statuses/Saved';
import { VolmaTableActions } from '../../../Table/VolmaTable/VolmaTableActions';
import { ComplaintTableDTO } from '../../../../Domain/DTO/ComplaintTableDTO';
import { IVolmaTableAction } from '../../../Table/VolmaTable/IVolmaTableProps';

export class DeleteComplaintsAction<THelper> implements IVolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _tableActions: VolmaTableActions;
    private _entityService: EntityService;

    public Title: string = "common:DeleteSelectedItems";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<ComplaintTableDTO>){
        if (selectedIds === undefined || selectedIds.length !== 1)
            return false;

        for (let selectedId of selectedIds) {
            let itm = data.find(x => x.Id === selectedId);
            if (itm === undefined)
                return false

            return itm.ComplaintState === EComplaintState.Saved;
        }
        return false;
    }
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ITableDTO>, dispatch: any, reloadData: () => void) {
        let service = this._entityService.GetEntityService(entity);
        if(service.GetBaseEntity() !== undefined){
            entity = service.GetBaseEntity();
        }
        dispatch(this._modalActions.OpenConfirmationModal("common:AreYouSureYouWantToDeleteSelectedItems", () => {
            dispatch(this._tableActions.DeleteItems(name, entity, i18next.t("common:Loading"), selectedIds, reloadData));
        }));
    }

    constructor(){
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tableActions = VolmaContainer.get<VolmaTableActions>(Types.VolmaTableActions);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
    }
}