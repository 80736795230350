import { IBaseTableProps } from '../../../../Table/BaseTable/IBaseTableProps';
import { IRatingTableHelperProps } from '../../Rating/Table/ITableHelperProps';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { RatingTableDTO } from '../../../../../Domain/DTO/RatingTableDTO';
import { IReducePayloaded } from '../../../../../Infrastructure/Reducer/IReducer';
import { ICargoRouteRateTableHelperProps } from './ITableHelperProps';
import { injectable } from 'inversify';
import { VolmaContainer } from '../../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../../Infrastructure/Types';
import { ApiActions } from '../../../../Shared/ApiActions';
import { CargoTransporterRouteRateTableDTO } from '../../../../../Domain/DTO/CargoTransporterRouteRateTableDTO';
import { ICargoRouteRateEditRequired } from './Payload';
import { ECargoTransporterRouteRateEditingEntity } from './EEditingEntity';
import { IInputPayload } from '../../../../VolmaInput/Payloads';
import { INumberValueChangedPayload } from '../../../../VolmaNumber/Payloads';
import { CARGO_TRANSPORTER_ROUTE_RATE_ADDED, CARGO_TRANSPORTER_ROUTE_RATE_UPDATED } from '../../../../../Constants/AppConstants';
import {
    CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT,
    CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT,
    CARGO_TRANSPORTER_ROUTE_RATES_LOADED,
    INPUT_VALUE_CHANGE
} from '../../../../../Constants/AppConstants';

@injectable()
export class CargoRouteRateTableReducer implements IReducePayloaded<IBaseTableProps<ICargoRouteRateTableHelperProps>>{

    constructor(){
        //this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
    }

    public Reduce(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, action: IActionPayloaded<any>): IBaseTableProps<ICargoRouteRateTableHelperProps> {

        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValueChanged(state, action.Payload);
            case CARGO_TRANSPORTER_ROUTE_RATES_LOADED:
                return this.ReduceRouteRatesLoaded(state, action.Payload);
            case CARGO_TRANSPORTER_ROUTE_RATE_ADDED:
                return this.ReduceRouteRateAdded(state, action.Payload);
            case CARGO_TRANSPORTER_ROUTE_RATE_UPDATED:
                return this.ReduceRouteRateUpdated(state, action.Payload);
            case CARGO_TRANSPORTER_ROUTE_RATE_START_EDIT:
                return this.ReduceStartEdit(state, action.Payload);
            case CARGO_TRANSPORTER_ROUTE_RATE_END_EDIT:
                return this.ReduceEndEdit(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValueChanged(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: INumberValueChangedPayload): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: {...state.DataHelper}};
        if (newState.DataHelper.EditingEntity === ECargoTransporterRouteRateEditingEntity.Rate)
            newState.DataHelper.EditingDTO.Rate = payload.Value;
        if (newState.DataHelper.EditingEntity === ECargoTransporterRouteRateEditingEntity.VehicleCount)
            newState.DataHelper.EditingDTO.VehicleCount = payload.Value;
        return newState;
    }

    private ReduceRouteRatesLoaded(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: Array<CargoTransporterRouteRateTableDTO>): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.RouteRates = payload

        return newState;
    }

    private ReduceRouteRateAdded(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: CargoTransporterRouteRateTableDTO): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        if(newState.DataHelper.RouteRates === undefined)
            newState.DataHelper.RouteRates = new Array();
        else
            newState.DataHelper.RouteRates = newState.DataHelper.RouteRates.slice(0);
            
        newState.DataHelper.RouteRates.push(payload)

        return newState;
    }

    private ReduceRouteRateUpdated(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: CargoTransporterRouteRateTableDTO): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        if(newState.DataHelper.RouteRates === undefined)
            newState.DataHelper.RouteRates = new Array();
        else
            newState.DataHelper.RouteRates = newState.DataHelper.RouteRates.slice(0);

        let idx = newState.DataHelper.RouteRates.findIndex(x => x.Id === payload.Id);
        if (idx >= 0)
            newState.DataHelper.RouteRates.splice(idx, 1, payload);
        else
            newState.DataHelper.RouteRates.push(payload);

        return newState;
    }

    private ReduceStartEdit(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: ICargoRouteRateEditRequired): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: {...state.DataHelper}};
        newState.DataHelper.EditingDTO = payload.EditingDTO;
        newState.DataHelper.EditingEntity = payload.EditingEntity;
        return newState;
    }

    private ReduceEndEdit(state: IBaseTableProps<ICargoRouteRateTableHelperProps>, payload: ICargoRouteRateEditRequired): IBaseTableProps<ICargoRouteRateTableHelperProps> {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };
        newState.DataHelper.EditingDTO = undefined;
        newState.DataHelper.EditingEntity = ECargoTransporterRouteRateEditingEntity.None;
        return newState;
    }

}