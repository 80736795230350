import 'reflect-metadata';
import * as moment from 'moment';
import { Component } from 'react';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    INFO_BLOCK_COLLAPSED,
    REQUEST_FORBIDDEN,
    REQUEST_OK,
    REQUEST_OVERLAY_DEBOUNCE,
    UPSTREAM_SERVER_FAILED,
} from '../../Constants/AppConstants';
import { ApiMessageLogDTO } from '../../Domain/DTO/ApiMessageLogDTO';
import { ObjectValidationResultDTO } from '../../Domain/DTO/ObjectValidationResultDTO';
import { EApiRequestDirection } from '../../Domain/Enum/EApiRequestDirection';
import { EComplaintState } from '../../Domain/Enum/EComplaintState';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { DeliveryService } from '../../Infrastructure/Services/DeliveryService';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import { EnumService } from '../../Infrastructure/Services/EnumService';
import { LocalStorageService } from '../../Infrastructure/Services/LocalStorageService';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { IGlobalState } from '../../Reducers/IGlobalState';
import { AboutEntityService } from '../Entity/About/AboutEntityService';
import { IAboutProps } from '../Entity/About/IAboutProps';
import i18next from '../i18n';
import { IVolmaModalProps } from '../VolmaModal/IVolmaModalProps';
import { AppActions } from './AppActions';
import { IAppProps } from './IAppProps';
import { SignalRService } from '../../Infrastructure/Services/SignalRService';

class App extends Component<IAppProps, {}>{
    private _authService: AuthenticationService;
    private _appActions: AppActions;
    private _entityService: EntityService;
    private _enumService: EnumService;
    private _localStorageService: LocalStorageService;
    private _serviceInfoCache: JSX.Element;
    private _aboutEntityService: AboutEntityService;
    private _deliveryService: DeliveryService;
    protected _signalRService: SignalRService;

    private _overlay      = volmaBlock("overlay");
    private _modal        = volmaBlock("modal");
    private _modalWrap    = volmaBlock("modal-wrap");
    private _modalConfirm = volmaBlock("modal-confirm");
    private _inform     = volmaBlock("inform");
    private _specificationWrapper = volmaBlock("specification-wrapper");

    constructor(props: IAppProps, context: any) {
        super(props, context);

        this._authService         = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._appActions          = VolmaContainer.get<AppActions>(Types.AppActions);
        this._entityService       = VolmaContainer.get<EntityService>(Types.EntityService);
        this._enumService         = VolmaContainer.get<EnumService>(Types.EnumService);
        this._deliveryService     = VolmaContainer.get<DeliveryService>(Types.DeliveryService);
        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
        this._aboutEntityService  = VolmaContainer.get<AboutEntityService>(Types.AboutEntityService);
        this._signalRService  = VolmaContainer.get<SignalRService>(Types.SignalRService);

        this.CloseValidationErrors      = this.CloseValidationErrors.bind(this);
        this.CloseServerErrors          = this.CloseServerErrors.bind(this);
        this.CloseInfoMessage           = this.CloseInfoMessage.bind(this);
        this.CargoTransporterChanged    = this.CargoTransporterChanged.bind(this);
    }

    public componentDidMount() {
        // redirect in case of root path
        if (this._authService.IsAuthenticated()){
            this.props.dispatch(this._appActions.StartConnection());
            this.props.dispatch(this._appActions.LoadServiceInfo(i18next.t("common:Loading")));

            if (this._localStorageService.IsKeyExist(INFO_BLOCK_COLLAPSED)){
                try{
                    this.props.dispatch(this._appActions.SetInfoBlockCollapsed(JSON.parse(this._localStorageService.GetKey(INFO_BLOCK_COLLAPSED))));
                }
                catch (error) {
                    console.warn("failed to decode INFO_BLOCK_COLLAPSED value", this._localStorageService.GetKey(INFO_BLOCK_COLLAPSED), error)
                    this._localStorageService.RemoveKey(INFO_BLOCK_COLLAPSED);
                }
            }

            this._signalRService.CargoTransporterChanged.On(this.CargoTransporterChanged);
        }
    }

    public componentWillUnmount() {
        this._signalRService.CargoTransporterChanged.Off(this.CargoTransporterChanged);
        this.props.dispatch(this._appActions.StopConnection());
    }


    render() {
        let isAuthenticated = this._authService.IsAuthenticated();

        const modal = this.props.IsModalOpened ? React.createElement(this.props.ModalContent as any, { Props: this.props.ModalProps, params: this.props.ModalProps.Params } as IVolmaModalProps<IEntityDTO, any>) : <span />;
        const overlay = this.props.IsModalOpened || !isAuthenticated ? <div className={this._overlay({ pattern: !isAuthenticated }).mix("active").toString()}></div> : <span />;
        // @ts-ignore: This expression is not callable
        const isNewYear = moment().dayOfYear() < 32  || moment().dayOfYear() > 340;// c 7(6) декабря до конца января НГ

        const showRequestOverlay = this.ShowRequestOverlay();
        const requestOverlay = showRequestOverlay ? this.GetRequestOverlay()     : undefined;
        const waiter         = showRequestOverlay ? this.GetServerRequestWaiter(): undefined;

        if (this.props.IsModalOpened)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = null;

            return (
            <div className={(isNewYear ? "new-year" : "").toString()}>
                {this.GetServerError()}
                {this.GetValidationError()}
                {this.GetUpdateDataNotifier()}
                {this.props.children}
                {modal}
                {overlay}
                {waiter}
                {requestOverlay}
                {this.GetServicesInfo()}
            </div >
        )
    }

    private ShowRequestOverlay(){
        let showRequestOverlay = false;
        let now: Date = new Date();
        for (let requestInProgress of this.props.RequestsInProgress) {
            if (now.getTime() - requestInProgress.Date.getTime() > REQUEST_OVERLAY_DEBOUNCE) {
                showRequestOverlay = true;
            }
        }

        return showRequestOverlay;
    }

    private GetServerRequestWaiter(){
        return (
        <div className={this._modal({ topmost: true }).mix(["active"]).toString()}>
            <div className={(this._modalWrap).toString()}>
                <div className={(this._modalConfirm).toString()}>
                    <form className={(this._modalConfirm("form")).toString()}>
                        <div className={this._modalConfirm("form-row").toString()}>
                            <div className={this._modalConfirm("form-cell", { full: true }).toString()}>
                                <div className={this._modalConfirm("form-text").toString()}>
                                    {i18next.t(this.props.WaitingText)}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
        )
    }

    private GetRequestOverlay(){
        return <div className={this._overlay({ topmost: true }).mix("active").toString()}></div>
    }

    private GetServerError(){
        let serverError: ServerErrorDTO = new ServerErrorDTO();
        if (this.props.ServerError !== undefined)
            serverError = this.props.ServerError;

        let message: JSX.Element;
        if (serverError.Status === REQUEST_FORBIDDEN){
            message = (
            <div className={(this._inform("left")).toString()}>
                <div className={(this._inform("top")).toString()}>
                    <span className={(this._inform("title")).toString()}>{i18next.t("common:ForbiddenErrorOccured")}</span>
                </div>
                <ul className={(this._inform("list")).toString()}>
                    <li className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{serverError.Data}</span>
                    </li>
                </ul>
            </div>);
        }

        else if (serverError.Status === UPSTREAM_SERVER_FAILED){
            message = (
            <div className={(this._inform("left")).toString()}>
                <div className={(this._inform("top")).toString()}>
                        <span className={(this._inform("title")).toString()}>{i18next.t("common:UpstreamServerFailed")}</span>
                </div>
                <ul className={(this._inform("list")).toString()}>
                    <li className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{serverError.Data}</span>
                    </li>
                </ul>
            </div>);
        }
        else{
            message = (
                <div className={(this._inform("left")).toString()}>
                    <div className={(this._inform("top")).toString()}>
                        <span className={(this._inform("title")).toString()}>{i18next.t("common:ServerErrorOccured", { status: serverError.Status, statusText: serverError.StatusText } as any)}</span>
                    </div>
                    <ul className={(this._inform("list")).toString()}>
                        <li className={this._inform("list-item").toString()}>
                            <span className={this._inform("list-val").toString()}>{serverError.Message + " (" + serverError.Url + "): " + serverError.Data}</span>
                        </li>
                    </ul>
                </div>);
        }
        return (
            <div className={this._inform({ error: true }).mix([this.props.ServerError !== undefined ? "active" : ""]).toString()}>
                {message}
                <div className={(this._inform("right")).toString()}>
                    <span className={(this._inform("close")).toString()} onClick={this.CloseServerErrors}>
                        <svg className={this._inform("close-ico").toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                        </svg>
                    </span>
                </div >
                <svg className={this._inform("bg-ico").toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tele"></use>
                </svg >
            </div >
        );
    }

    private GetValidationError(){
        let validationError: ObjectValidationResultDTO = new ObjectValidationResultDTO();
        if (this.props.ValidationError !== undefined)
            validationError = this.props.ValidationError;

        let prefix = "";
        let entity = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.entity);
        if (entity !== undefined){
            let baseEntity = this.GetBaseEntity(entity);
            prefix = EEntityType[baseEntity].toLowerCase() + ":";
        }
        else if (this.props.CurrentEntity !== undefined) {
            prefix = EEntityType[this.props.CurrentEntity].toLowerCase() + ":";
        }
        let errors = [];
        if (validationError.Errors !== undefined){
            for (let error of validationError.Errors){
                let parentFields = error.ParentFields.slice(0)
                parentFields.push(error.DisplayFieldName);
                parentFields = parentFields.filter(x => PropertyHelper.IsString(x) && x.length > 0);
                parentFields = parentFields.map(x => i18next.t(prefix + x));
                errors.push(
                    <li key={error.Field} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{(parentFields.join(": ").length > 0 ? (parentFields.join(": ") + ": ") : "") + error.Message}</span>
                    </li>
                );
            }
        }

        return (
            <div className={this._inform({ validation: true }).mix([this.props.ValidationError !== undefined ? "active" : ""]).toString()}>
                <div className={(this._inform("left")).toString()}>
                    <div className={(this._inform("top")).toString()}>
                        <span className={(this._inform("title")).toString()}>{i18next.t("common:ValidationErrorOccured")}</span>
                    </div>
                    <ul className={(this._inform("list")).toString()}>
                        {errors}
                    </ul>
                </div>
                <div className={(this._inform("right")).toString()}>
                    <span className={(this._inform("close")).toString()} onClick={this.CloseValidationErrors}>
                        <svg className={this._inform("close-ico").toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                        </svg>
                    </span>
                </div >
                <svg className={this._inform("bg-ico").toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tele"></use>
                </svg >
            </div>
        );
    }

    private GetUpdateDataNotifier(){

        let addedObjects          = [];//this.GetAddedObjectsNotifications();
        let updatedObjects        = [];//this.GetUpdatedObjectsNotifications();
        let addedTenderBets       = [];//this.GetAddedTenderBetsNotifications();
        let addedBiddingBets      = [];//this.GetAddedBiddingBetsNotifications();
        let updatedDeliveryStates = this.GetDeliveriesStatesNotifications();
        let tendersStarted        = this.GetTendersStartedNotifications();
        let tendersEnded          = this.GetTendersEndedNotifications();
        let complaintAdded        = this.GetComplaintAddedNotifications();
        let complaintCommentAdded = this.GetComplaintCommentAddedNotifications();

        let isActive =  addedObjects.length !== 0 || updatedObjects.length !== 0 ||
                        addedTenderBets.length !== 0 || addedBiddingBets.length !== 0 ||
                        updatedDeliveryStates.length !== 0 ||
                        tendersStarted.length !== 0 || tendersEnded.length !== 0 ||
                        complaintAdded.length !== 0 || complaintCommentAdded.length !== 0;
        return (
            <div className={this._inform({ new: true }).mix([isActive ? "active" : "", isActive && this.props.InfoCollapsed ? "collapsed" : ""]).toString()}>
                <div className={(this._inform("left")).toString()}>
                    <div className={(this._inform("top")).toString()}>
                        <span className={(this._inform("title")).toString()}>{i18next.t("common:UpdatesOccured")}</span>
                    </div>
                    <ul className={(this._inform("list")).toString()}>
                        {addedObjects}
                        {updatedObjects}
                        {addedTenderBets}
                        {addedBiddingBets}
                        {updatedDeliveryStates}
                        {tendersStarted}
                        {tendersEnded}
                        {complaintAdded}
                        {complaintCommentAdded}
                    </ul>
                </div>
                <div className={(this._inform("right")).toString()}>
                    <span className={(this._inform("close")).toString()} onClick={this.CloseInfoMessage}>
                        <svg className={this._inform("close-ico").toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                        </svg>
                    </span>
                </div >
                <svg className={this._inform("bg-ico").toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#tele"></use>
                </svg >
                <div className={(this._inform("collapse")).toString()}>
                    <a onClick={(() =>
                        this.props.dispatch(this._appActions.ToggleInfo())).bind(this)}
                        className={this._inform("list-link").toString()}>
                        {this.props.InfoCollapsed ? i18next.t("common:OpenInfo") : i18next.t("common:CollapseInfo")}</a>

                </div >
            </div >
        );
    }

    private GetAddedObjectsNotifications(){
        let addedObjects = [];
        for (let newObject of this.props.AddedObjects) {
            if (newObject.DTO !== null && newObject.DTO !== undefined && newObject.Type !== EEntityType.Delivery && newObject.Type !== EEntityType.Tender && newObject.Type !== EEntityType.RequestLog) {
                let nameProp = this._entityService.GetEntityDefaultNameProperty(newObject.Type);
                let entityTypeName = i18next.t(this._entityService.GetEntityHeader(newObject.Type));

                addedObjects.push(
                    <li key={'addedObjects-' + newObject.DTO.Id} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{i18next.t("common:AddedEntity", { type: entityTypeName, name: newObject.DTO[nameProp] } as any)}</span>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(newObject.Type, newObject.DTO.Id, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(newObject.Type, newObject.DTO.Id))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>
                    </li>
                );
            }
            if (newObject.DTO !== null && newObject.DTO !== undefined && newObject.Type === EEntityType.RequestLog && (newObject.DTO as ApiMessageLogDTO).StatusCode !== REQUEST_OK) {
                let log = newObject.DTO as ApiMessageLogDTO;
                addedObjects.push(
                    <li key={'addedObjects-' + newObject.DTO.Id} className={this._inform("list-item").toString()}>
                        {
                            log.Direction === EApiRequestDirection.Incoming ?
                                <span className={this._inform("list-val").toString()}>{i18next.t("common:ErpLogFailedIncomingMessageAdded", { code: log.StatusCode } as any)}</span>
                            :
                                <span className={this._inform("list-val").toString()}>{i18next.t("common:ErpLogFailedOutgoingMessageAdded", { code: log.StatusCode } as any)}</span>
                        }

                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenListFromInfo(newObject.Type, newObject.DTO.Id, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(newObject.Type, newObject.DTO.Id))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>
                    </li>
                );
            }
        }
        return addedObjects;
    }
    private GetUpdatedObjectsNotifications(){
        let updatedObjects = [];
        for (let updatedObject of this.props.UpdatedObjects) {
            if (updatedObject.DTO !== null && updatedObject.DTO !== undefined && updatedObject.Type !== EEntityType.Delivery && updatedObject.Type !== EEntityType.Tender) {
                let nameProp = this._entityService.GetEntityDefaultNameProperty(updatedObject.Type);
                let entityTypeName = i18next.t(this._entityService.GetEntityHeader(updatedObject.Type));

                updatedObjects.push(
                    <li key={'updatedObject-' + updatedObject.DTO.Id} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{i18next.t("common:UpdatedEntity", { type: entityTypeName, name: updatedObject.DTO[nameProp] } as any)}</span>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(updatedObject.Type, updatedObject.DTO.Id, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(updatedObject.Type, updatedObject.DTO.Id))).bind(this)}
                            className={this._inform("list-link", {orange: true}).toString()}>
                            {i18next.t("common:CheckClose")}</a>
                    </li>
                );
            }
        }

        return updatedObjects;
    }
    private GetAddedTenderBetsNotifications(){
        let addedTenderBets = [];
        for (let tenderBet of this.props.AddedTenderBets) {
            if (tenderBet !== null && tenderBet !== undefined) {
                addedTenderBets.push(
                    <li key={'tenderBet-' + tenderBet.Id} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{i18next.t("common:AddedTenderBet", { bet: tenderBet.Bet, tender: tenderBet.TenderId } as any)}</span>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(EEntityType.TenderActive, tenderBet.TenderId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(EEntityType.TenderActive, tenderBet.TenderId))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return addedTenderBets;
    }

    private GetAddedBiddingBetsNotifications(){
        let addedBiddingBets = [];
        for (let biddingBet of this.props.AddedBiddingBets) {
            if (biddingBet !== null && biddingBet !== undefined) {
                let entity = this._authService.IsShipper() ? EEntityType.DeliveryActive : EEntityType.DeliveryToAcceptConfirm;
                addedBiddingBets.push(
                    <li key={'biddingBet-' + biddingBet.Id} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{i18next.t("common:AddedBiddingBet", { bet: biddingBet.Bet, delivery: biddingBet.DeliveryId } as any)}</span>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(entity, biddingBet.DeliveryId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(entity, biddingBet.DeliveryId))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return addedBiddingBets;
    }

    private GetTendersStartedNotifications(){
        let tendersStarted = [];
        let currentId = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.id);
        for (let tender of this.props.TendersStarted) {
            if (tender !== null && tender !== undefined) {
                tendersStarted.push(
                    <li key={'tenderStarted-' + tender.TenderId} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val", { green: true }).toString()}>{i18next.t("common:TenderStarted", { identifier: tender.Identifier } as any)}</span>
                        {currentId !== tender.TenderId && <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(EEntityType.TenderBettingPerforming, tender.TenderId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>}
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(EEntityType.TenderBettingPerforming, tender.TenderId))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return tendersStarted;
    }

    private GetTendersEndedNotifications(){
        let tendersEnded = [];
        let currentId = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.id);
        for (let tender of this.props.TendersEnded) {
            if (tender !== null && tender !== undefined) {
                tendersEnded.push(
                    <li key={'tenderEnded-' + tender.TenderId} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val").toString()}>{i18next.t("common:TenderEnded", { identifier: tender.Identifier } as any)}</span>
                        {currentId !== tender.TenderId && <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(EEntityType.TenderBettingPerforming, tender.TenderId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>}
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(EEntityType.TenderBettingPerforming, tender.TenderId))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return tendersEnded;
    }

    private GetComplaintAddedNotifications(){
        let complaintsAdded = [];
        let currentId = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.id);
        for (let complaint of this.props.ComplaintAdded) {
            if (complaint !== null && complaint !== undefined) {
                let text = undefined;
                if(complaint.ComplaintState === EComplaintState.Billed)
                    text = i18next.t("common:ComplaintBilled", { identifier: complaint.RegistrationCode } as any);
                else
                    text = i18next.t("common:ComplaintUpdated", { identifier: complaint.RegistrationCode, state: this._enumService.GetOptionLocalized(() => EComplaintState, complaint.ComplaintState) } as any);
                complaintsAdded.push(
                    <li key={'ComplaintAdded-' + complaint.Id} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val", { red: true }).toString()}>{text}</span>
                        {currentId !== complaint.Id && <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(EEntityType.Complaint, complaint.Id, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>}
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(EEntityType.Complaint, complaint.Id))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return complaintsAdded;
    }

    private GetComplaintCommentAddedNotifications(){
        let complaintsCommentsAdded = [];
        let currentId = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.id);
        for (let complaintComment of this.props.ComplaintCommentAdded) {
            if (complaintComment !== null && complaintComment !== undefined) {
                complaintsCommentsAdded.push(
                    <li key={'ComplaintCommentAdded-' + complaintComment.ComplaintId} className={this._inform("list-item").toString()}>
                        <span className={this._inform("list-val", { red: true}).toString()}>{i18next.t("common:ComplaintCommentAdded", { identifier: complaintComment.ComplaintIdentifier } as any)}</span>
                        {currentId !== complaintComment.ComplaintId && <a onClick={(() =>
                            this.props.dispatch(this._appActions.OpenItemFromInfo(EEntityType.Complaint, complaintComment.ComplaintId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                            className={this._inform("list-link").toString()}>
                            {i18next.t("common:CheckToSee")}</a>}
                        <a onClick={(() =>
                            this.props.dispatch(this._appActions.CloseItemFromInfo(EEntityType.Complaint, complaintComment.ComplaintId))).bind(this)}
                            className={this._inform("list-link", { orange: true }).toString()}>
                            {i18next.t("common:CheckClose")}</a>

                    </li>
                );
            }
        }

        return complaintsCommentsAdded;
    }

    private GetDeliveriesStatesNotifications() {
        let updatedDeliveryStates = [];
        let currentUser = this._authService.GetCurrentUser();
        for (let deliveryState of this.props.UpdatedDeliveriesStates) {
            if (deliveryState !== null && deliveryState !== undefined) {
                let notification = this._deliveryService.GetTextForNotification(deliveryState, currentUser);
                let text: string = notification.Text;
                let isRed: boolean = notification.IsRed;
                let isGreen: boolean = notification.IsGreen;

                if (text !== undefined) {
                    let currentId = PropertyHelper.SafeGetValue2(this.props, x => x.match.params, x => x.id);
                    let entity = this._deliveryService.GetEntityByDelivery(deliveryState.DeliveryState, deliveryState.Assigner)[0];
                    updatedDeliveryStates.push(
                        <li key={'deliveryState-' + deliveryState.DeliveryId} className={this._inform("list-item").toString()}>
                            <span className={this._inform("list-val", {red: isRed, green: isGreen}).toString()}>{text}</span>
                            {currentId !== deliveryState.DeliveryId && <a onClick={(() =>
                                this.props.dispatch(this._appActions.OpenItemFromInfo(entity, deliveryState.DeliveryId, PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname)))).bind(this)}
                                className={this._inform("list-link").toString()}>
                                {i18next.t("common:CheckToSee")}</a>}
                            <a onClick={(() =>
                                this.props.dispatch(this._appActions.CloseItemFromInfo(entity, deliveryState.DeliveryId))).bind(this)}
                                className={this._inform("list-link", { orange: true }).toString()}>
                                {i18next.t("common:CheckClose")}</a>

                        </li>
                    );
                }
            }
        }
        return updatedDeliveryStates;
    }

    private GetServicesInfo(){
        if (!PropertyHelper.IsObject(this._serviceInfoCache) && PropertyHelper.IsObject(this.props.ServiceInfo)){
            let props = { DataDTO: this.props.ServiceInfo} as IAboutProps;
            this._serviceInfoCache = (
                <div className={(this._specificationWrapper).toString()}>
                    {this._aboutEntityService.GetMainSpecificationForMainPage(props)}
                </div>
            );
        }
        return this._serviceInfoCache;
    }

    private GetBaseEntity(entity: string): EEntityType {
        let entityType = this._entityService.GetEntityType(entity);
        let entityService = this._entityService.GetEntityService(entityType);
        let baseEntity = entityType;
        if (entityService.GetBaseEntity() !== undefined)
            baseEntity = entityService.GetBaseEntity();
        return baseEntity;
    }

    private CloseServerErrors(){
        this.props.dispatch(this._appActions.CloseServerErrors())
    }

    private CloseValidationErrors(){
        this.props.dispatch(this._appActions.CloseValidationErrors())
    }

    private CloseInfoMessage(){
        this.props.dispatch(this._appActions.CloseInfoMessage())
    }

    private CargoTransporterChanged() {
        this.props.dispatch(this._appActions.UpdateCurrentUser(i18next.t("common:Loading")));
    }
}

const translated = withTranslation(['common'])(withRouter(App as any));

function select(state: IGlobalState): IAppProps {
    return state.App;
}

let connectedApp = connect<IAppProps, {}, {}>(select)(translated);
export default connectedApp;
