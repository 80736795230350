import { EComplaintState } from '../../../Domain/Enum/EComplaintState';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { ComplaintCommentDTO } from '../../../Domain/DTO/ComplaintCommentDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import {
    COMPLAINT_ACCEPTED_PAYMENT_EDITING,
    COMPLAINT_ADDITION_FILE_ADDED,
    COMPLAINT_ADDITION_FILE_DELETED,
    COMPLAINT_ADDITIONAL_FILES_LOADED,
    COMPLAINT_AUTHOR_LOADED,
    COMPLAINT_CARGO_TRANSPORTER_LOADED,
    COMPLAINT_COMMENT_ADDED,
    COMPLAINT_COMMENT_READED,
    COMPLAINT_COMPLAINT_COMMENTS_LOADED,
    COMPLAINT_COMPLAINT_FILE_DELETED,
    COMPLAINT_COMPLAINT_FILE_LOADED,
    COMPLAINT_COMPLAINT_FILE_UPDATED,
    COMPLAINT_COMPLAINT_MESSAGE_CHANGED,
    COMPLAINT_COMPLAINT_TEMPLATE_LOADED,
    COMPLAINT_COMPLAINT_TEMPLATE_UPDATED,
    COMPLAINT_STATE_UPDATED
} from '../../../Constants/AppConstants';
import { EntityAction } from '../BaseEntity/EntityAction';
import { injectable } from 'inversify';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { ShipperUserDTO } from '../../../Domain/DTO/ShipperUserDTO';
import { CargoTransporterUserDTO } from '../../../Domain/DTO/CargoTransporterUserDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import i18next from '../../i18n';
import { ComplaintStateDTO } from '../../../Domain/DTO/ComplaintStateDTO';
import { UpdateComplaintAcceptedPaymentDTO } from '../../../Domain/DTO/UpdateComplaintAcceptedPaymentDTO';
import { UpdateComplaintAdditionalFilesDTO } from '../../../Domain/DTO/UpdateComplaintAdditionalFilesDTO';
import { UpdateComplaintFileDTO } from '../../../Domain/DTO/UpdateComplaintFileDTO';
import { ComplaintServerInteraction } from '../../../Infrastructure/ServerInteraction/ComplaintServerInteraction';
import { IEditingAcceptedPaymentPayload } from './Payload';
import { EComplaintCommentState } from '../../../Domain/Enum/EComplaintCommentState';
import { ComplaintHelper } from './ComplaintHelper';
import { ComplaintDTO } from '../../../Domain/DTO/ComplaintDTO';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { AxiosResponse } from 'axios';
import { COMPLAINT_CARGOTRANSPORTERS_UPDATED, COMPLAINT_SHOWDUMMYSELECT_UPDATED, COMPLAINT_DELIVERIESVALUE_CLEAR } from '../../../Constants/AppConstants';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';

@injectable()
export class ComplaintActions extends EntityAction {
    private _complaintApi: ComplaintServerInteraction;
    private _tableApi: TableServerInteraction;
    private _helper: ComplaintHelper;

    constructor() {
        super();

        this._complaintApi = VolmaContainer.get<ComplaintServerInteraction>(Types.ComplaintServerInteraction);
        this._helper = VolmaContainer.get<ComplaintHelper>(Types.ComplaintHelper);
        this._tableApi = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
    }

    public LoadComplaintComments(id: string, waitingText: string, dto: ComplaintDTO) {
        return super.LoadChildDataTableItems(
            EEntityType.Complaint,
            id,
            EEntityType.ComplaintComment,
            (x: ComplaintCommentDTO) => x.Timestamp,
            ESortDir.Asc,
            waitingText,
            (data) => this.ComplaintCommentsLoaded(dto, data));
    }

    public LoadCargoTransporter(id: string, waitingText: string) {
        return super.LoadEntityCached<CargoTransporterTableDTO>(
            id,
            EEntityType.CargoTransporter,
            waitingText,
            this.CargoTransporterLoaded);
    }

    public LoadCargoTransportersForDelivery(deliveryId: string) {
        return (dispatch) => {
            if (deliveryId !== undefined) {
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => this._complaintApi.GetCargoTransportersByDelivery(deliveryId),
                    i18next.t("common:Loading"),
                    (response: AxiosResponse, dispatch: any) => {
                        let data = JSON.parse(response.data);
                        dispatch(this.UpdateCargoTransporterList(data.Items));
                    });
            } else {
                dispatch(this.UpdateCargoTransporterList([]));
            }

        }
    }

    public LoadComplaintTemplate(id: string, waitingText: string) {
        return super.LoadEntityCached<FileTableDTO>(
            id,
            EEntityType.ComplaintTemplate,
            waitingText,
            this.ComplaintTemplateLoaded);
    }

    public LoadShipperUser(id: string, waitingText: string) {
        return super.LoadEntityCached<ShipperUserDTO>(
            id,
            EEntityType.ShipperUser,
            waitingText,
            this.ComplaintAuthorLoaded);
    }

    public LoadCargoTransporterUser(id: string, waitingText: string) {
        return super.LoadEntityCached<CargoTransporterUserDTO>(
            id,
            EEntityType.CargoTransporterUser,
            waitingText,
            this.ComplaintAuthorLoaded);
    }

    public LoadComplaintFile(id: string, waitingText: string) {
        return super.LoadEntityCached<FileTableDTO>(
            id,
            EEntityType.File,
            waitingText,
            this.ComplaintFileLoaded);
    }

    public LoadAdditionFiles(id: string, waitingText: string, filter: string = undefined) {
        return super.LoadChildDataTableItems(
            EEntityType.Complaint,
            id,
            EEntityType.File,
            (x: FileTableDTO) => x.Timestamp,
            ESortDir.Asc,
            waitingText,
            this.AdditionFilesLoaded);
    }

    public ComplaintTemplateUpdated(data: ComplaintTemplateTableDTO): IActionPayloaded<ComplaintTemplateTableDTO> {
        return { type: COMPLAINT_COMPLAINT_TEMPLATE_UPDATED, Payload: data };
    }

    // public ComplaintFileUpdated(data: FileTableDTO): IActionPayloaded<FileTableDTO>{
    //     return { type: COMPLAINT_COMPLAINT_FILE_UPDATED, Payload: data };
    // }

    // public ComplaintFileDeleted(data: FileTableDTO): IActionPayloaded<FileTableDTO>{
    //     return { type: COMPLAINT_COMPLAINT_FILE_DELETED, Payload: data };
    // }

    public AdditionFileAdded(data: FileTableDTO): IActionPayloaded<FileTableDTO> {
        return { type: COMPLAINT_ADDITION_FILE_ADDED, Payload: data };
    }

    // public AdditionFileDeleted(data: FileTableDTO): IActionPayloaded<FileTableDTO>{
    //     return { type: COMPLAINT_ADDITION_FILE_DELETED, Payload: data };
    // }

    // public StateUpdated(data: EComplaintState): IActionPayloaded<ComplaintCommentDTO>{
    //     return { type: COMPLAINT_STATE_UPDATED, Payload: data };
    // }

    public SetCommentsReaded(commentIds: Array<string>) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.SetCommentReaded(commentIds),
                i18next.t("common:Loading"),
                (response) => {
                    this.SetCommentsReadedInternal(commentIds)
                });
        }
    }

    public CommentAdded(data: ComplaintCommentDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.AddComment(data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                    dispatch(this.MessageChanged(""));
                });
        }
    }

    public ChangeState(complaintId: string, data: ComplaintStateDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.ChangeState(complaintId, data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                });
        }
    }

    public UpdateAcceptedPayment(complaintId: string, data: UpdateComplaintAcceptedPaymentDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.UpdateAcceptedPayment(complaintId, data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                });
        }
    }

    public UpdateAdditionalFiles(complaintId: string, data: UpdateComplaintAdditionalFilesDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.UpdateAdditionalFiles(complaintId, data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                });
        }
    }

    public UpdateComplaintFile(complaintId: string, data: UpdateComplaintFileDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._complaintApi.UpdateFile(complaintId, data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                });
        }
    }

    public MessageChanged(value: string): IActionPayloaded<string> {
        return { type: COMPLAINT_COMPLAINT_MESSAGE_CHANGED, Payload: value };
    }

    public StartAcceptedPaymentEditing(value: number): IActionPayloaded<IEditingAcceptedPaymentPayload> {
        return { type: COMPLAINT_ACCEPTED_PAYMENT_EDITING, Payload: { StartEditing: true, SavedValue: value, RestoreSavedValue: undefined } };
    }

    public EndAcceptedPaymentEditing(restoreSavedValue: boolean, acceptedPayment: number, complaintId: string) {
        return (dispatch) => {
            if (!restoreSavedValue) {
                let dto = new UpdateComplaintAcceptedPaymentDTO();
                dto.AcceptedPayment = acceptedPayment;
                dto.ComplaintId = complaintId;
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => this._complaintApi.UpdateAcceptedPayment(complaintId, dto),
                    i18next.t("common:Loading"),
                    (response) => {
                        dispatch({ type: COMPLAINT_ACCEPTED_PAYMENT_EDITING, Payload: { StartEditing: false, SavedValue: undefined, RestoreSavedValue: restoreSavedValue } });
                    },
                    (response) => {
                        dispatch({ type: COMPLAINT_ACCEPTED_PAYMENT_EDITING, Payload: { StartEditing: false, SavedValue: undefined, RestoreSavedValue: true } });
                    });
            }
            else {
                dispatch({ type: COMPLAINT_ACCEPTED_PAYMENT_EDITING, Payload: { StartEditing: false, SavedValue: undefined, RestoreSavedValue: restoreSavedValue } });
            }

        }

    }

    public SetShowDummySelectPropertyValue(showDummySelectValue: boolean): IActionPayloaded<boolean> {
        return { type: COMPLAINT_SHOWDUMMYSELECT_UPDATED, Payload: showDummySelectValue };
    }

    public ClearCargoTransporterValue(): IActionPayloaded<Array<CargoTransporterTableDTO>> {
        return { type: COMPLAINT_DELIVERIESVALUE_CLEAR, Payload: undefined };
    }

    // private helpers
    private ComplaintCommentsLoaded(dto: ComplaintDTO, value: Array<ComplaintCommentDTO>) {
        return (dispatch) => {
            if (PropertyHelper.IsArray(value)) {
                value = value.sort((a, b) => a.OrderNum - b.OrderNum);
                let unreadedCommentsIds = new Array<string>();
                for (let comment of value) {
                    let isRight = (this._helper.IsUserBob(dto, comment.AuthorRole) && this._helper.IsAlice(dto)) ||
                        (this._helper.IsUserAlice(dto, comment.AuthorRole) && this._helper.IsBob(dto));
                    if (comment.CommentState === EComplaintCommentState.Unreded && isRight) {
                        unreadedCommentsIds.push(comment.Id);
                    }
                }
                if (unreadedCommentsIds.length > 0)
                    dispatch(this.SetCommentsReaded(unreadedCommentsIds));
                dispatch({ type: COMPLAINT_COMPLAINT_COMMENTS_LOADED, Payload: value });
            }
        }
    }

    private CargoTransporterLoaded(value: CargoTransporterTableDTO): IActionPayloaded<CargoTransporterTableDTO> {
        return { type: COMPLAINT_CARGO_TRANSPORTER_LOADED, Payload: value };
    }

    private ComplaintTemplateLoaded(value: ComplaintCommentDTO): IActionPayloaded<ComplaintCommentDTO> {
        return { type: COMPLAINT_COMPLAINT_TEMPLATE_LOADED, Payload: value };
    }

    private ComplaintAuthorLoaded(value: UserDTO): IActionPayloaded<UserDTO> {
        return { type: COMPLAINT_AUTHOR_LOADED, Payload: value };
    }

    private ComplaintFileLoaded(value: FileTableDTO): IActionPayloaded<FileTableDTO> {
        return { type: COMPLAINT_COMPLAINT_FILE_LOADED, Payload: value };
    }

    private AdditionFilesLoaded(value: Array<FileTableDTO>): IActionPayloaded<Array<FileTableDTO>> {
        return { type: COMPLAINT_ADDITIONAL_FILES_LOADED, Payload: value };
    }

    private SetCommentsReadedInternal(commentIds: Array<string>): IActionPayloaded<Array<string>> {
        return { type: COMPLAINT_COMMENT_READED, Payload: commentIds };
    }

    private ComplaintCommentAddedInternal(data: ComplaintCommentDTO): IActionPayloaded<ComplaintCommentDTO> {
        return { type: COMPLAINT_COMMENT_ADDED, Payload: data };
    }

    private UpdateCargoTransporterList(items: Array<CargoTransporterTableDTO>): IActionPayloaded<Array<CargoTransporterTableDTO>> {
        return { type: COMPLAINT_CARGOTRANSPORTERS_UPDATED, Payload: items };
    }
}