import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { ApiActions } from '../../../Shared/ApiActions';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';

export class GetInvoiceAction<THelper> extends VolmaTableAction<THelper>{
    private _deliveryTableActions: DeliveryTableActions;
    private _apiActions: ApiActions;

    public Title: string = "delivery:GetInvoice";

    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean {
        if (this._authService.IsCargoTransporter()) {
            if (!PropertyHelper.IsArray(selectedIds) || selectedIds.length < 1)
                return false;


            let allowedStates = [
                EDeliveryState.Approved,
                EDeliveryState.WaitForLoading,
                EDeliveryState.VehicleArrivedAtLoadingPoint,
                EDeliveryState.VehicleArrivedAtUnloadingPoint,
                EDeliveryState.LatenessRegistration,
                EDeliveryState.LoadingLatenessRegistration,
                EDeliveryState.LoadingBegin,
                EDeliveryState.LoadingEnd,
                EDeliveryState.Delivery,
                EDeliveryState.Delivered,
                EDeliveryState.Closed,
                EDeliveryState.ClosedOld];
                
            let consignorId = undefined;
            let firstItm = data.find(x => x.Id === selectedIds[0]);
            if (firstItm !== undefined) {
                consignorId = firstItm.ConsignorId;
            }
            for (let selectedId of selectedIds) {
                let itm = data.find(x => x.Id === selectedId);
                if (itm === undefined)
                    return false
                if (itm.ConsignorId !== consignorId || allowedStates.indexOf(itm.State) < 0)
                    return false
            }
            return true;
        }
        return false;
    }

    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        dispatch(this._deliveryTableActions.GetInvoice(selectedIds, i18next.t("common:Loading"), reloadData));
    }

    constructor() {
        super();
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
    }
}