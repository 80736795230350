import {
    ABOUT_LOADED,
    ABOUT_MOUNTED,
    ABOUT_UNMOUNTED
} from '../../../Constants/AppConstants';
import { ConfigurationDTO } from '../../../Domain/DTO/ConfigurationDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { Types } from '../../../Infrastructure/Types';
import { BaseCustomLoadSaveReducer } from '../BaseCustomLoadSave/BaseCustomLoadSaveReducer';

import { IAboutProps, AboutPropsInitial } from './IAboutProps';
import { injectable } from 'inversify';
import { ServicePartsVersionDTO } from '../../../Domain/DTO/ServicePartsVersionDTO';

@injectable()
export class AboutReducer extends BaseCustomLoadSaveReducer{
    private _entityService: EntityService;

    constructor() {
        super(ABOUT_MOUNTED, ABOUT_UNMOUNTED);

        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
    }

    public Reduce(state: IAboutProps = AboutPropsInitial, action: IActionPayloaded<any>): IAboutProps {
        state = super.Reduce(state, action);

        switch (action.type) {
            case ABOUT_LOADED:
                return this.ReduceAboutLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    public static Initialize(): (state: IAboutProps, action: IActionPayloaded<IAboutProps>) => IAboutProps {
        const reducer = new AboutReducer();
        return (state: IAboutProps, action: IActionPayloaded<IAboutProps>) => reducer.Reduce(state, action);
    }

    private ReduceAboutLoaded(state: IAboutProps, payload: ServicePartsVersionDTO):IAboutProps{
        let newState = <IAboutProps>{ ...state,
            DataDTO: payload,
            DataHelper: undefined,
            DataDTOStringify: JSON.stringify(payload),
            ClosedCardParts: this.GetInfoPartStatesFromLocalStorage(state.EntityType)};
        return newState;
    }
}