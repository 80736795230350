import { ApiActions } from '../Shared/ApiActions';
import { AboutServerInteraction } from '../../Infrastructure/ServerInteraction/AboutServerInteraction';
import { AxiosResponse } from 'axios';
import { ServicePartsVersionDTO } from '../../Domain/DTO/ServicePartsVersionDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import { Types } from '../../Infrastructure/Types';
import {
    APP_CLOSE_INFO_MESSAGE,
    APP_CLOSE_SERVER_ERRORS,
    APP_CLOSE_VALIDATION_ERRORS,
    APP_REMOVE_VIEWVIED_UPDATES,
    APP_SERVICE_INFO_LOADED,
    APP_SET_INFO_MESSAGE_COLLAPSED,
    APP_TOGGLE_INFO_MESSAGE,
    VOLMA_SIGNALR_START_CONNECTION,
    VOLMA_SIGNALR_STOP_CONNECTION
} from '../../Constants/AppConstants';
import { injectable } from 'inversify';
import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IViewedUpdates } from './Payloads';
import { history } from '../../ConfigureStore';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { UserDTO } from '../../Domain/DTO/UserDTO';

@injectable()
export class AppActions{
    private _routerService: RouterService;
    private _serverInteraction: AboutServerInteraction;
    private _apiActions: ApiActions;
    private _authService: AuthenticationService;
    private _authApi: AuthServerInteraction;

    constructor(){
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._serverInteraction = VolmaContainer.get<AboutServerInteraction>(Types.AboutServerInteraction);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._authApi = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
    }

    public LoadServiceInfo(waitingText: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._serverInteraction.GetServiceInfo(),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data: ServicePartsVersionDTO = response.data;
                    dispatch(this.ServiceInfoLoaded(data));
                });
        }
    }

    public UpdateCurrentUser(waitingText: string) {
        return (dispatch) => { 
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._authApi.GetUserInfo(),
                waitingText,
                (response: AxiosResponse) => {
                    const userDTO: UserDTO = JSON.parse(response.data);
                    this._authService.SetUser(userDTO);
                });
        };        
    }


    public CloseValidationErrors(): IAction{
        return {type: APP_CLOSE_VALIDATION_ERRORS }
    }

    public CloseInfoMessage(): IAction{
        return {type: APP_CLOSE_INFO_MESSAGE }
    }

    public ToggleInfo(): IAction {
        return { type: APP_TOGGLE_INFO_MESSAGE }
    }

    public SetInfoBlockCollapsed(value: boolean): IActionPayloaded<boolean> {
        return { type: APP_SET_INFO_MESSAGE_COLLAPSED,  Payload: value}
    }

    public CloseServerErrors(): IAction{
        return {type: APP_CLOSE_SERVER_ERRORS }
    }

    public OpenItemFromInfo(entity: EEntityType, id: string, currentLocation: string){
        return (dispatch) => {
            dispatch(this.RemoveViewedUpdates(entity, id));
            let newLocation = this._routerService.GetCertainEntityPathname(entity, id);
            if (currentLocation !== newLocation)
                history.push(newLocation)
            else{
                history.push(newLocation + "?" + Math.random().toString(36).substr(2, 5))
            }
        }
    }

    public OpenListFromInfo(entity: EEntityType, id: string, currentLocation: string){
        return (dispatch) => {
            dispatch(this.RemoveViewedUpdates(entity, id));
            let newLocation = this._routerService.GetTablePathname(entity);
            if (currentLocation !== newLocation)
                history.push(newLocation)
            else{
                history.push(newLocation + "?" + Math.random().toString(36).substr(2, 5))
            }
        }
    }

    public CloseItemFromInfo(entity: EEntityType, id: string){
        return (dispatch) => {
            dispatch(this.RemoveViewedUpdates(entity, id));
        }
    }

    public StartConnection(): IAction {
        return { type: VOLMA_SIGNALR_START_CONNECTION };
    }

    public StopConnection(): IAction {
        return { type: VOLMA_SIGNALR_STOP_CONNECTION };
    }

    private ServiceInfoLoaded(item: ServicePartsVersionDTO) {
        return { type: APP_SERVICE_INFO_LOADED, Payload: item };
    }

    private RemoveViewedUpdates(entity: EEntityType, id: string): IActionPayloaded<IViewedUpdates>{
        return { type: APP_REMOVE_VIEWVIED_UPDATES, Payload: {Type: entity, Id: id} }
    }

}