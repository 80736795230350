import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { ZoneTableDTO } from '../../../../Domain/DTO/ZoneTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { injectable } from 'inversify';


@injectable()
export class CargoTransporterRouteRateActions extends EntityAction {
}