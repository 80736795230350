import * as React from 'react';
import { SelectDriverActions } from './SelectDriverActions';
import { SelectDriverHelperProps, InitialSelectDriverHelperProps } from './HelperProps';
import { BaseEntityService } from '../../../BaseEntity/BaseEntityService';
import { DriverTableDTO } from '../../../../../Domain/DTO/DriverTableDTO';
import { IBaseEntityProps } from '../../../BaseEntity/IBaseEntityProps';
import i18next from '../../../../i18n';
import { IEntityReducer } from '../../../BaseEntity/IEntityReducer';
import { SelectDriverDTO } from './SelectDriverDTO';
import { IVolmaTableColumn } from '../../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaContainer } from '../../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../../Infrastructure/Types';
import { SelectDriverReducer } from './SelectDriverReducer';
import { volmaBlock } from '../../../../../Infrastructure/Services/BEM';
import VolmaSelect from '../../../../VolmaSelect/index';
import PropertyHelper from '../../../../../Infrastructure/Services/PropertyHelper';

export class SelectDriverEntityService extends BaseEntityService<SelectDriverDTO, SelectDriverHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _dispatch: any;

    private _reducer: SelectDriverReducer;
    private _actions: SelectDriverActions;

    private _moreInfo = volmaBlock("more-info");

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._reducer = VolmaContainer.get<SelectDriverReducer>(Types.SelectDriverReducer);
        this._actions = VolmaContainer.get<SelectDriverActions>(Types.SelectDriverActions);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetEditorModal(props: IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SelectDriverDTO) => val.SelectedDriver) as any) }
                                        Name={PropertyHelper.GetPropertyName((x: SelectDriverDTO) => x.SelectedDriver)}
                                        Label={i18next.t('selectdriver:Driver')}
                                        Placeholder={i18next.t('selectdriver:SelectDriver')}
                                        Options={props.DataHelper.DriverList}
                                        LabelKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Name)}
                                        ValueKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Id)}
                                        Value={props.DataHelper.SelectedDriver}
                                        HelperFieldName={PropertyHelper.GetPropertyName((val: SelectDriverHelperProps) => val.SelectedDriver)}
                                        HelperFieldToDTOFieldTranslator={(x: DriverTableDTO) => x}
                                        Required={true}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>): JSX.Element {
        return (
            <span>
            </span>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>): void {
        this._dispatch = props.dispatch;
        this._dispatch(this._actions.LoadAllDrivers(i18next.t("common:Loading")));
    }

    public OnAfterDataLoaded(dto: any): void {
    }

    public GetReducer(): IEntityReducer<SelectDriverDTO, SelectDriverHelperProps> {
        return this._reducer;
    }

    public GetInitialDataHelper(): SelectDriverHelperProps {
        return InitialSelectDriverHelperProps;
    }
}