import { VolmaSelectActions } from '../../../VolmaSelect/VolmaSelectActions';
import { IRouteSegmentTableHelperProps } from './ITableHelperProps';
import { RoutePointReducer } from './RoutePointReducer';
import { OptionCreatorParams } from '../../../VolmaSelect/IVolmaSelectProps';
import * as React from 'react';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERoutePointType } from '../../../../Domain/Enum/ERoutePointType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumn,
    IVolmaTableProps
} from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { InitialRoutePointHelperProps, RoutePointHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import VolmaSelect from '../../../VolmaSelect/index';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import * as uuid from 'uuid';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import VolmaCalendar from '../../../VolmaCalendar/index';
import VolmaTime from '../../../VolmaTime/index';
import { LocalityTableDTO } from '../../../../Domain/DTO/LocalityTableDTO';
import { volmaBlock } from '../../../../Infrastructure/Services/BEM';
import VolmaInput from '../../../VolmaInput/index';
import { RoutePointActions } from './RoutePointActions';
import { RouteSegmentDTO } from '../../../../Domain/DTO/RouteSegmentDTO';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { VolmaInputValidator } from '../../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaTableCellHeightMeasurers } from '../../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import { ExtraInformationDTO } from '../../../../Domain/DTO/ExtraInformationDTO';
import { HTML_BR } from '../../../../Constants/AppConstants';
import * as moment from 'moment';
import { VolmaTableDeliveryCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableDeliveryCellRenderers';



@injectable()
export class RoutePointEntityService extends BaseEntityService<RouteSegmentDTO, RoutePointHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _dispatch: any;
    private _inputValidator: VolmaInputValidator;

    private _reducer: RoutePointReducer;
    private _actions: RoutePointActions;
    private _selectActions: VolmaSelectActions;

    private _consigneeExtraFieldName = 'Грузополучатель';
    private _contactsExtraFieldName = 'Контакт выгрузки';
    private _customerExtraFieldName = 'Заказчик';
    private _commentExtraFieldName = 'Комментарий';

    private _moreInfo = volmaBlock("more-info");

    private _hasCustomPointField: boolean;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._reducer = VolmaContainer.get<RoutePointReducer>(Types.RoutePointReducer);
        this._actions = VolmaContainer.get<RoutePointActions>(Types.RoutePointActions);
        this._selectActions = VolmaContainer.get<VolmaSelectActions>(Types.VolmaSelectActions);

        this.GetPointCellText     = this.GetPointCellText.bind(this);
        this.GetExtraInfoCellText = this.GetExtraInfoCellText.bind(this);
        this.GetRequiredVehicleCellText = this.GetRequiredVehicleCellText.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.RouteSegment,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateStart),
                    CellRenderer: VolmaTableDeliveryCellRenderers.RouteSegmentDateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer,
                    Weight: 0.6,
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.PointType),
                    CellRenderer: (props: IVolmaTableProps<{}>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERoutePointType, this._enumService, props, cellRendererParams),
                    Weight: 0.4
                },
                {
                    DataKey: "Point",
                    CellTextGetter: this.GetPointCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                },
                {
                    DataKey: "RequiredVehicle",
                    CellTextGetter: this.GetRequiredVehicleCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ExtraInformation),
                    CellTextGetter: this.GetExtraInfoCellText,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer
                },
            ]
        );
    }

    public GetEditorModal(props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): JSX.Element {

        let extraInfoTable = this.GetExtraTable(props);
        this._hasCustomPointField = props.DataHelper.HasCustomPointField;
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("top")).toString()}>
                            <h2 className={(this._mainInfo("title")).toString()}>{props.DataDTO.PointType === ERoutePointType.Loading ? i18next.t("routesegment:LoadingPointTitle") : i18next.t("routesegment:UnloadingPointTitle")}</h2>
                            <a onClick={this.ShowCustomPointField.bind(this, props)} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                                </svg>
                                <span className={(this._btn("text")).toString()}>{props.DataHelper.HasCustomPointField ? i18next.t("routesegment:ShowSelectLoadingPoint") : i18next.t("routesegment:ShowCustomPointField")}</span>
                            </a>
                        </div>
                        {props.DataDTO.PointType === ERoutePointType.Loading && !props.DataHelper.HasCustomPointField &&
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaSelect
                                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteSegmentDTO) => val.LoadingPointId) as any) }
                                            Name={PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.LoadingPointId)}
                                            Label={i18next.t('routesegment:LoadingPointId')}
                                            Placeholder={i18next.t('routesegment:SelectLoadingPoint')}
                                            Entity={EEntityType.LoadingPoint}
                                            Value={props.DataDTO.LoadingPointId}
                                            Required={() => !this._hasCustomPointField}
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                            </div>}
                        {props.DataDTO.PointType === ERoutePointType.Unloading && !props.DataHelper.HasCustomPointField &&
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaSelect
                                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteSegmentDTO) => val.UnloadingPointId) as any) }
                                            Name={PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.UnloadingPointId)}
                                            Label={i18next.t('routesegment:UnloadingPointId')}
                                            Placeholder={i18next.t('routesegment:SelectUnloadingPoint')}
                                            Entity={EEntityType.Locality}
                                            Value={props.DataDTO.UnloadingPointId}
                                            Required={() => !this._hasCustomPointField}
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                            </div>}
                        {props.DataHelper.HasCustomPointField &&
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaInput
                                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.CustomPoint) }
                                            Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.CustomPoint)}
                                            Type="textarea"
                                            Label={props.DataDTO.PointType === ERoutePointType.Loading ? i18next.t('routesegment:CustomPointLoading') : i18next.t('routesegment:CustomPointUnloading')}
                                            Value={props.DataDTO.CustomPoint}
                                            Validator={this._inputValidator}
                                            //Required={props.DataHelper.HasCustomPointField}
                                            MaxLength={4000}
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteSegmentDTO) => val.HandlingTypeId) as any) }
                                        Name={PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.HandlingTypeId)}
                                        Label={i18next.t('routesegment:HandlingTypeId')}
                                        Placeholder={i18next.t('routesegment:SelectUnloadingType')}
                                        Required={true}
                                        Entity={EEntityType.HandlingType}
                                        Value={props.DataDTO.HandlingTypeId}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RouteSegmentDTO) => val.PayloadId) as any) }
                                        Name={PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.PayloadId)}
                                        Label={i18next.t('routesegment:PayloadId')}
                                        Placeholder={i18next.t('routesegment:SelectCarrying')}
                                        Entity={EEntityType.Payload}
                                        Required={true}
                                        Value={props.DataDTO.PayloadId}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.GetArrivalDateEditor(props)}
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("top")).toString()}>
                            <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("routesegment:ExtraInfoTitle")}</h2>
                            <a onClick={this.AddExtraInfoField.bind(this)} className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                                </svg>
                                <span className={(this._btn("text")).toString()}>{i18next.t("routesegment:AddExtraInfoField")}</span>
                            </a>
                        </div>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    {extraInfoTable}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetArrivalDateEditor (props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): JSX.Element {
        if(props.DataDTO.PointType === ERoutePointType.Unloading)
            return this.GetArrivalDateUnloadingPointEditor(props);
        return this.GetArrivalDateLoadingPointEditor(props);
    }

    private GetArrivalDateLoadingPointEditor (props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("item")).toString()}>
                <div className={(this._container()).toString()}>
                    <div className={(this._mainInfo("row")).toString()}>
                        <div className={this._mainInfo("cell", { 100: true }).toString()}>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaCalendar
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateStart) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateStart)}
                                    Label={i18next.t('routesegment:ArrivalPlanDate')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateStart}
                                    dispatch={props.dispatch} />
                            </div>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaTime
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateStart) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateStart)}
                                    Label={i18next.t('routesegment:ArrivalPlanTime')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateStart}
                                    dispatch={props.dispatch} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetArrivalDateUnloadingPointEditor (props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("item")).toString()}>
                <div className={(this._container()).toString()}>
                    <div className={(this._mainInfo("row")).toString()}>
                        <div className={this._mainInfo("cell", { 100: true }).toString()}>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaCalendar
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateStart) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateStart)}
                                    Label={i18next.t('routesegment:ArrivalPlanDateUnloadingStart')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateStart}
                                    dispatch={props.dispatch} />
                            </div>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaTime
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateStart) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateStart)}
                                    Label={i18next.t('routesegment:ArrivalPlanTimeUnloadingStart')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateStart}
                                    dispatch={props.dispatch} />
                            </div>
                        </div>
                        <div className={this._mainInfo("cell", { 100: true }).toString()}>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaCalendar
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateEnd) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateEnd)}
                                    Label={i18next.t('routesegment:ArrivalPlanDateUnloadingEnd')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateEnd}
                                    dispatch={props.dispatch} />
                            </div>
                            <div className={this._mainInfo("line", { fill: true }).toString()}>
                                <VolmaTime
                                    {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: RouteSegmentDTO) => val.ArrivalDateEnd) as any) }
                                    Name={PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.ArrivalDateEnd)}
                                    Label={i18next.t('routesegment:ArrivalPlanTimeUnloadingEnd')}
                                    Required={true}
                                    Value={props.DataDTO.ArrivalDateEnd}
                                    dispatch={props.dispatch} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): JSX.Element {
        return <span></span>
    }

    public InitializeEntity(props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): void {
        this._dispatch = props.dispatch;
        // this._dispatch(this._actions.LoadAllHandlingTypes(i18next.t("common:Loading")));
    }

    public OnAfterDataLoaded(dto: RouteSegmentDTO): void {
        // if (dto.PointType === ERoutePointType.Loading)
        //     this._dispatch(this._actions.LoadAllLoadingPoints(i18next.t("common:Loading")));
        // else if (dto.PointType === ERoutePointType.Unloading)
        //     this._dispatch(this._actions.LoadAllLocalities(i18next.t("common:Loading")));
        this._dispatch(this._actions.ShowCustomPointFieldChanged(dto.CustomPoint !== undefined && dto.CustomPoint !== null && dto.CustomPoint !== ""));

    }

    public GetReducer(): IEntityReducer<RouteSegmentDTO, RoutePointHelperProps> {
        return this._reducer;
    }
    public GetInitialDataHelper(): RoutePointHelperProps {
        return InitialRoutePointHelperProps;
    }

    private ShowCustomPointField(props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>){
        this._hasCustomPointField = !props.DataHelper.HasCustomPointField;
        this._dispatch(this._actions.ShowCustomPointFieldChanged(!props.DataHelper.HasCustomPointField));

        if(props.DataDTO.PointType === ERoutePointType.Loading){
            this._dispatch(this._selectActions.Validate(PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.LoadingPointId)))
        }
        else{
            this._dispatch(this._selectActions.Validate(PropertyHelper.GetPropertyName((val: RouteSegmentDTO) => val.UnloadingPointId)));
        }
    }

    private GetExtraTable(props: IBaseEntityProps<RouteSegmentDTO, RoutePointHelperProps>): Array<JSX.Element> {
        let rows = new Array<JSX.Element>();

        if (props.DataDTO.ExtraInformation === undefined)
            return rows;

        for (let extraInfoField of props.DataDTO.ExtraInformation) {
            rows.push(
                <div className={(this._moreInfo()).toString()} key={extraInfoField.Id}>
                    <div className={(this._moreInfo("row")).toString()}>
                        <div className={this._moreInfo("cell", { large: true }).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByNameString(props.Inputs, "key_" + extraInfoField.Id) }
                                Name={"key_" + extraInfoField.Id}
                                Label={i18next.t('routesegment:ExtraInfoFieldName')}
                                Value={extraInfoField.Key}
                                OnValueChange={(event: any) => this.OnExtraInfoFieldKeyChanged(extraInfoField.Id, event.target.value)}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._moreInfo("cell", { large: true }).toString()}>
                            <VolmaInput
                                {...PropertyHelper.GetInputPropertyByNameString(props.Inputs, "value_" + extraInfoField.Id) }
                                Name={"value_" + extraInfoField.Id}
                                Label={i18next.t('routesegment:ExtraInfoFieldValue')}
                                Value={extraInfoField.Value}
                                OnValueChange={(event: any) => this.OnExtraInfoFieldValueChanged(extraInfoField.Id, event.target.value)}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._moreInfo("cell", { small: true }).toString()}>
                            <div className={(this._moreInfo("redact")).toString()}>
                                <a onClick={((event: Event) => this.ExtraInfoFieldRemove(event, extraInfoField.Id)).bind(this)} className={this._moreInfo("redact-link").mix([this._btn(), this._btnRemove()]).toString()}>
                                    <svg className={(this._btnRemove("ico")).toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#cart"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div >
                </div >
            )
        }
        return rows;
    }

    private GetPointCellText(props: IVolmaTableProps<IRouteSegmentTableHelperProps>, rowIndex: number){
        let dto = props.Data[rowIndex] as RouteSegmentDTO;

        if(this.IsValueNotEmpty(dto.CustomPoint))
            return dto.CustomPoint;

        let pointName: string = undefined;
        let pointCode: string = undefined;

        if (dto.PointType === ERoutePointType.Loading){
            let loadingPoint = props.DataHelper.LoadingPoints !== undefined ?
                props.DataHelper.LoadingPoints.find(x => x.Id.toLowerCase() === dto.LoadingPointId.toLowerCase()) :
                undefined;
            pointName = this.GetSafeString(PropertyHelper.SafeGetValue(loadingPoint, x => x.Name ));
            pointCode = this.GetSafeString(PropertyHelper.SafeGetValue(loadingPoint, x => x.Code ));
        }
        else{
            let unloadingPoint = props.DataHelper.Localities !== undefined ?
                props.DataHelper.Localities.find(x => x.Id.toLowerCase() === dto.UnloadingPointId.toLowerCase()) :
                undefined;
            pointName = this.GetSafeString(PropertyHelper.SafeGetValue(unloadingPoint, x => x.Name ));
            pointCode = this.GetSafeString(PropertyHelper.SafeGetValue(unloadingPoint, x => x.Code));
        }

        return pointName + " (" + pointCode + ")";
    }

    private GetRequiredVehicleCellText(props: IVolmaTableProps<IRouteSegmentTableHelperProps>, rowIndex: number){
        let dto = props.Data[rowIndex] as RouteSegmentDTO;

        let results = new Array<string>();
        if (this.IsValueNotEmpty(dto.HandlingTypeId)){
            let handlingType = props.DataHelper.HandlingTypes !== undefined ?
                props.DataHelper.HandlingTypes.find(x => x.Id === dto.HandlingTypeId) :
                undefined;
            let handlingTypeName = this.GetSafeString(PropertyHelper.SafeGetValue(handlingType, x => x.Type ));
            let handlingTypeCode = this.GetSafeString(PropertyHelper.SafeGetValue(handlingType, x => x.Code ));
            let fieldName = i18next.t("routesegment:HandlingTypeId");
            results.push(fieldName + ": " + handlingTypeName + " (" + handlingTypeCode + ")")
        }
        if (this.IsValueNotEmpty(dto.PayloadId)){
            let payload = props.DataHelper.Payloads !== undefined ?
                props.DataHelper.Payloads.find(x => x.Id === dto.PayloadId) :
                undefined;
            let payloadTypeName = this.GetSafeString(PropertyHelper.SafeGetValue(payload, x => x.Name ));
            let payloadTypeCode = this.GetSafeString(PropertyHelper.SafeGetValue(payload, x => x.Code ));
            let fieldName = i18next.t("routesegment:PayloadId");
            results.push(fieldName + ": " + payloadTypeName + " (" + payloadTypeCode + ")")
        }

        return results.join(HTML_BR);
    }

    private GetExtraInfoCellText(props: IVolmaTableProps<{}>, rowIndex: number){
        const dto = props.Data[rowIndex] as RouteSegmentDTO;

        let result = new Array<string>();
        if (dto.ExtraInformation !== undefined) {
            if (dto.PointType === ERoutePointType.Unloading) {
                result = this.GetUnloadingExtraInfoText(dto.ExtraInformation);
            } else {
                for (let i = 0; i < dto.ExtraInformation.length; i++) {
                    result.push(`${dto.ExtraInformation[i].Key}: ${dto.ExtraInformation[i].Value}`);
                }
            }
        }

        return result.join(HTML_BR);
    }

    private GetUnloadingExtraInfoText(extraInfo: ExtraInformationDTO[]){
        const commonFields = new Array<ExtraInformationDTO>();
        const specialFields = new Array<Array<ExtraInformationDTO>>();
        for (let i = 0; i < extraInfo.length; i++) {
            if (extraInfo[i].Key === this._consigneeExtraFieldName ||
                extraInfo[i].Key === this._contactsExtraFieldName ||
                extraInfo[i].Key === this._customerExtraFieldName ||
                extraInfo[i].Key === this._commentExtraFieldName) {
                    const splited = extraInfo[i].Value.split('\r\n');
                    for (let j = 0; j < splited.length; j++) {
                        if (specialFields[j] === undefined) {
                            specialFields.push([]);
                        }
                        specialFields[j].push({
                            Key: extraInfo[i].Key,
                            Value: splited[j]
                        });
                    }
                } else {
                    commonFields.push(extraInfo[i]);
                }
        }

        const result = commonFields.map((value) => `${value.Key}: ${value.Value}`);
        for (let i = 0; i < specialFields.length; i++) {
            const consigneeField = specialFields[i].find((value) => value.Key === this._consigneeExtraFieldName);
            if (consigneeField) {
                result.push(`${consigneeField.Key}: ${consigneeField.Value}`);
            }
            const contactsField = specialFields[i].find((value) => value.Key === this._contactsExtraFieldName);
            if (contactsField) {
                result.push(`${contactsField.Key}: ${contactsField.Value}`);
            }
            const commentField = specialFields[i].find((value) => value.Key === this._commentExtraFieldName);
            if (commentField) {
                result.push(`${commentField.Key}: ${commentField.Value}`);
            }
            const customerField = specialFields[i].find((value) => value.Key === this._customerExtraFieldName);
            if (customerField) {
                result.push(`${customerField.Key}: ${customerField.Value}`);
            }
        }

        return result;
    }

    private OnExtraInfoFieldKeyChanged(id: string, value: string) {
        this._dispatch(this._actions.ExtraInfoFieldKeyChanged(id, value));
    }

    private OnExtraInfoFieldValueChanged(id: string, value: string) {
        this._dispatch(this._actions.ExtraInfoFieldValueChanged(id, value));
    }

    private AddExtraInfoField(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this._dispatch(this._actions.AddExtraInfoField());
    }

    private ExtraInfoFieldEdit(id: string) {
        this._dispatch(this._actions.EditExtraInfoField(id));
    }

    private ExtraInfoFieldRemove(event: Event, id: string) {
        event.preventDefault();
        event.stopPropagation();
        this._dispatch(this._actions.RemoveAddExtraInfoField(id));
    }
}