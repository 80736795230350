import { EComplaintTemplateDirection } from '../../../../Domain/Enum/EComplaintTemplateDirection';
import { ComplaintTableDTO } from '../../../../Domain/DTO/ComplaintTableDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { ComplaintTableActions } from '../Table/ComplaintTableActions';
import i18next from '../../../i18n';
import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { ComplaintActions } from '../ComplaintActions';
import { ComplaintCommentDTO } from '../../../../Domain/DTO/ComplaintCommentDTO';
import { IComplaintHelper } from '../IComplaintHelper';
import { ComplaintStateDTO } from '../../../../Domain/DTO/ComplaintStateDTO';
import { Cancelled } from '../Statuses/Cancelled';
import { Closed } from '../Statuses/Closed';

export class CancelAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _complaintActions: ComplaintActions;
    private _helper: IComplaintHelper;

    public Title: string = "complaint:Cancel";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<ComplaintTableDTO>): boolean {
        if (selectedIds === undefined || selectedIds.length !== 1)
            return false;

        for(let selectedId of selectedIds){
            let itm = data.find(x => x.Id === selectedId);
            if (itm === undefined)
                return false

            if (this._helper.IsAlice(itm))
                return itm.ComplaintState !== EComplaintState.Cancelled && itm.ComplaintState !== EComplaintState.Closed;
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ComplaintTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        dispatch(this._modalActions.OpenComplaintCommentModal(i18next.t("complaint:Cancel"), (dto: ComplaintCommentDTO) => {
            let state = new ComplaintStateDTO();
            state.State = EComplaintState.Cancelled;
            state.Comment = dto.Comment
            state.ComplaintId = id;
            dispatch(this._complaintActions.ChangeState(id, state, reloadData));
        }))
    }

    constructor(){
        super();
        this._modalActions     = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._complaintActions = VolmaContainer.get<ComplaintActions>(Types.ComplaintActions);
        this._helper           = VolmaContainer.get<IComplaintHelper>(Types.ComplaintHelper)
    }
}