import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { DELIVERY_TABLE_BETS_LOADED, DELIVERY_TABLE_BET_ADDED_OR_UPDATED, DELIVERY_TABLE_WHITH_BETS_REMOVED } from '../../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../../Domain/DTO/BiddingBetTableDTO';
import { DeliveryManualAssignDTO } from '../../../../Domain/DTO/DeliveryManualAssignDTO';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import { TenderBetTableDTO } from '../../../../Domain/DTO/TenderBetTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { TableDataDTO } from '../../../../Domain/TableDataDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { DeliveryServerInteraction } from '../../../../Infrastructure/ServerInteraction/DeliveryServerInteraction';
import { Types } from '../../../../Infrastructure/Types';
import i18next from '../../../i18n';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { BiddingBetExtendedDTO } from '../ActionBet/BiddingBetExtendedDTO';


@injectable()
export class DeliveryTableActions extends EntityAction {

    private _modalActions: VolmaModalActions;
    private _deliveryApi   : DeliveryServerInteraction;

    constructor() {
        super();

        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryApi = VolmaContainer.get<DeliveryServerInteraction>(Types.DeliveryServerInteraction);
    }

    public LoadUserBets() {
        return super.LoadDataTable<BiddingBetTableDTO>(
            EEntityType.ActiveBiddingBets,
            (x:BiddingBetTableDTO) => x.CargoTransporterName,
            ESortDir.Asc,
            undefined,
            i18next.t("common:Loading"),
            this.BetsLoaded);
    }

    public AddOrEditBet(userBet: number, currency: string, deliveryId: string) {
        return (dispatch) => {
            let maxBet = undefined ? 0 : userBet;

            let dto = new BiddingBetExtendedDTO();
            dto.Bet = userBet === undefined ? 0 : userBet;
            dto.Currency = currency;
            dto.MinBetValue = 1;
            // dto.MaxBetValue = maxBet; // enable user to set any bet
            dto.DeliveryId = deliveryId;
            dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.BiddingBet, dto, undefined, (dto) => {
                let request = this._deliveryApi.AddBet(dto)
                this._apiActions.DoApiRequest(
                    dispatch,
                    () => request,
                    i18next.t("common:Loading"),
                    (response: AxiosResponse, dispatch: any) => {
                        dispatch(this.BetAddedOrUpdated(dto));
                    });
                return request;
            }));
        }
    }

    public DeliveryAddState(dto: DeliveryStateDTO, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.AddState(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public DeliveryManualAssign(dto: DeliveryManualAssignDTO, deliveryId: string, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.ManualAssign(dto, deliveryId),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public DeliveryAddStates(dto: TableDataDTO<DeliveryStateDTO>, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.AddStates(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public DeliveryActualize(id: string, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.Actualize(id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    reload()
                });
        }
    }

    public GetStatement(ids: Array<string>, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.GetStatement(ids),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }

    public GetInvoice(ids: Array<string>, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.GetInvoice(ids),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {

                });
        }
    }

    public GetAccount(ids: Array<string>, waitingText: string, reload: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._deliveryApi.GetAccount(ids),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {

                });
        }
    }

    public FlushBiddingBetsByDelivery(deliveryId: string){
        return (dispatch) => {
            dispatch(this.DeliveryWithBetsRemoved(deliveryId));
        }
    }

    private BetsLoaded(value: Array<TenderBetTableDTO>): IActionPayloaded<Array<TenderBetTableDTO>> {
        return { type: DELIVERY_TABLE_BETS_LOADED, Payload: value };
    }

    private BetAddedOrUpdated(dto: TenderBetTableDTO): IActionPayloaded<TenderBetTableDTO> {
        return { type: DELIVERY_TABLE_BET_ADDED_OR_UPDATED, Payload: dto };
    }
    
    private DeliveryWithBetsRemoved(deliveryId: string): IActionPayloaded<string> {
        return { type: DELIVERY_TABLE_WHITH_BETS_REMOVED, Payload: deliveryId };
    }
}