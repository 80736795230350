import * as moment from 'moment';

import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { TenderHelperProps } from '../IHelperProps';

export class Performing extends StatusDefault<TenderDTO, TenderHelperProps>{
    public TitleCurrent : string = "tender:StatusPerformingCurrent";
    public TitleFinished: string = "tender:StatusPerformingFinished";
    public TitleFuture  : string = "tender:StatusPerformingFuture";

    public IsActive(dto: TenderDTO) {
        return true;
    }

    public IsCurrent(dto: TenderDTO) {
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isAfter(this._timeService.GetLocalTime(dto.StartDate)) && moment().isBefore(this._timeService.GetLocalTime(dto.EndDate))
    }

    public IsFinished(dto: TenderDTO) {
        // @ts-ignore: This expression is not callable
        return dto.State === ETenderState.Published && moment().isAfter(this._timeService.GetLocalTime(dto.EndDate));
    }

    public GetActions(dto: TenderDTO, currentUser: UserDTO, authService: AuthenticationService) {
        return [];
    }

}