import { injectable } from 'inversify';
import {
    VOLMA_ENTITY_DATA_LOADED
} from '../../../Constants/AppConstants';
import { MultiFactoryDTO } from '../../../Domain/DTO/MultiFactoryDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { MultiFactoryHelperProps } from './IHelperProps';


@injectable()
export class MultiFactoryReducer extends EntityReducer<MultiFactoryDTO, MultiFactoryHelperProps> {

    public Reduce(state: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>{
        switch (action.type) {
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceDataLoaded(state: IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps>, payload: MultiFactoryDTO): IBaseEntityProps<MultiFactoryDTO, MultiFactoryHelperProps> {
        return super.DataLoaded(state, payload);
    }
}
