import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { injectable } from 'inversify';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { AuthenticationService } from '../AuthService';
import { VolmaContainer } from '../../InversifyInject';
import { Types } from '../../Types';
import { EAccessType } from './EAccessType';
import { isNullOrUndefined } from '../Utils';

@injectable()
export class AuthorizationService {
    private _authService: AuthenticationService;


    private _rules: {[entityType: string]: {[accessType: string]: () => boolean}} = {};

    constructor(){

        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this.Configure();
    }

    private Configure() {
        this.AddRule(EAccessType.Invariant, EEntityType.Complaint, () => this._authService.IsCargoTransporter() || this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.ComplaintDraft, () => this._authService.IsCargoTransporter() || this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.ComplaintActive, () => this._authService.IsCargoTransporter() || this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.ComplaintClosed, () => this._authService.IsCargoTransporter() || this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.ComplaintWithNewMessages, () => this._authService.IsCargoTransporter() || this._authService.IsShipper());

        this.AddRule(EAccessType.Invariant, EEntityType.Rating, () => false);
        this.AddRule(EAccessType.Invariant, EEntityType.CurrentRating, () => false);
        this.AddRule(EAccessType.Invariant, EEntityType.ForecastRating, () => false);


        this.AddRule(EAccessType.Invariant, EEntityType.Settings, () => {
            let currentUser = this._authService.GetCurrentUser();
            return currentUser.Role === EUserRole.Administrator || currentUser.Role === EUserRole.ShipperLogistExtended
        });
        this.AddRule(EAccessType.Invariant, EEntityType.Configuration, () => {
            let currentUser = this._authService.GetCurrentUser();
            return currentUser.Role === EUserRole.Administrator || currentUser.Role === EUserRole.ShipperLogistExtended
        });

        this.AddRule(EAccessType.Invariant, EEntityType.ComplaintTemplate, () => this._authService.IsAdministrator());

        this.AddRule(EAccessType.Invariant, EEntityType.MultiFactory, () => this._authService.IsAdministrator());
        this.AddRule(EAccessType.Invariant, EEntityType.MultiCargoTransporter, () => this._authService.IsAdministrator());

        this.AddRule(EAccessType.Add, EEntityType.RouteRate, () => false);
        this.AddRule(EAccessType.Edit, EEntityType.RouteRate, () => this._authService.IsAdministrator());

        this.AddRule(EAccessType.Invariant, EEntityType.Zone, () => false);

        this.AddRule(EAccessType.Add, EEntityType.Locality, () => false);
        this.AddRule(EAccessType.Edit, EEntityType.Locality, () => this._authService.IsAdministrator());

        this.AddRule(EAccessType.Invariant, EEntityType.BodyType, () => false);
        this.AddRule(EAccessType.Invariant, EEntityType.HandlingType, () => false);
        this.AddRule(EAccessType.Invariant, EEntityType.Payload, () => false);
        this.AddRule(EAccessType.Invariant, EEntityType.Consignor, () => false);

        this.AddRule(EAccessType.Edit, EEntityType.LoadingPoint, () => this._authService.IsAdministrator());
        this.AddRule(EAccessType.Add, EEntityType.LoadingPoint, () => false);

        this.AddRule(EAccessType.Invariant, EEntityType.Tender, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderFinished, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderActive, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderBettingPerforming, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderFuture, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderInactive, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.TenderDraft, () => this._authService.IsShipper());

        this.AddRule(EAccessType.Invariant, EEntityType.Delivery, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryFinished, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryFuture, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryActive, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryPerforming, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryWithClaim, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryManual, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryAuction, () => this._authService.IsShipper());
        this.AddRule(EAccessType.Invariant, EEntityType.DeliveryToAcceptConfirm, () => this._authService.IsShipper());

        this.AddRule(EAccessType.Invariant, EEntityType.Shipper, () => this._authService.IsAdministrator());
        this.AddRule(EAccessType.Invariant, EEntityType.CargoTransporter, () => this._authService.IsAdministrator());

        this.AddRule(EAccessType.Invariant, EEntityType.ShipperUser, () => this._authService.IsAdministrator() || this._authService.IsShipperExtended());

        this.AddRule(EAccessType.Invariant, EEntityType.Driver, () => {
            let currentUser = this._authService.GetCurrentUser();
            return this._authService.IsAdministrator() || currentUser.Role === EUserRole.CargoTransporterLogistExtended;
        });
        this.AddRule(EAccessType.Invariant, EEntityType.Vehicle, () => {
            let currentUser = this._authService.GetCurrentUser();
            return this._authService.IsAdministrator() || currentUser.Role === EUserRole.CargoTransporterLogistExtended;
        });
        this.AddRule(EAccessType.Invariant, EEntityType.CargoTransporterRouteRate, () => {
            let currentUser = this._authService.GetCurrentUser();
            return this._authService.IsAdministrator() || currentUser.Role === EUserRole.CargoTransporterLogistExtended;
        });
        this.AddRule(EAccessType.Invariant, EEntityType.CargoTransporterUser, () => {
            let currentUser = this._authService.GetCurrentUser();
            return this._authService.IsAdministrator() || currentUser.Role === EUserRole.CargoTransporterLogistExtended;
        });
        this.AddRule(EAccessType.Invariant, EEntityType.Contract, () => {
            let currentUser = this._authService.GetCurrentUser();
            return this._authService.IsAdministrator() || currentUser.Role === EUserRole.CargoTransporterLogistExtended;
        });

        this.AddRule(EAccessType.Add, EEntityType.Instruction, () => this._authService.IsAdministrator());
        this.AddRule(EAccessType.Edit, EEntityType.Instruction, () => this._authService.IsAdministrator());
    }

    private AddRule(ruleType: EAccessType, entityType: EEntityType, canEdit: () => boolean) {
        let entityRules = this._rules[EEntityType[entityType]];
        if(!isNullOrUndefined(entityRules) && !isNullOrUndefined(entityRules[EAccessType[ruleType]]))
            throw new Error('Rule already exists');
        if(isNullOrUndefined(this._rules[EEntityType[entityType]]))
            this._rules[EEntityType[entityType]] = {};
        this._rules[EEntityType[entityType]][EAccessType[ruleType]] = canEdit;
    }

    public GetTableCanAddEntity(entity: EEntityType): boolean {
        let entityRules = this._rules[EEntityType[entity]];
        if(entityRules !== undefined){
            let invariantRule = entityRules[EAccessType[EAccessType.Invariant]];
            if(invariantRule !== undefined)
            {
                return invariantRule();
            } else {
                let rule = entityRules[EAccessType[EAccessType.Add]];
                if(rule !== undefined) {
                    return rule();
                }
            }
       }

        throw new Error('Unknown entity type' + EEntityType[entity]);
    }

    public IsEditable(entity: EEntityType): boolean {
        let entityRules = this._rules[EEntityType[entity]];
        if(entityRules !== undefined){
            let invariantRule = entityRules[EAccessType[EAccessType.Invariant]];
            if(invariantRule !== undefined)
            {
                return invariantRule();
            } else {
                let rule = entityRules[EAccessType[EAccessType.Edit]];
                if(rule !== undefined) {
                    return rule();
                }
            }
        }
         throw new Error('Unknown entity type' + EEntityType[entity]);
    }
}