import * as React from 'react';
import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';
import { RegisterUserDTO } from '../../Domain/DTO/RegisterUserDTO';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { IGlobalState } from '../../Reducers/IGlobalState';
import { VolmaActionLink } from '../Shared/VolmaActionLink';
import { VolmaButton } from '../Shared/VolmaButton';
import { VolmaComponent } from '../Shared/VolmaComponent';
import VolmaForm from '../VolmaForm';
import VolmaInput from '../VolmaInput';
import { IVolmaInputProps } from '../VolmaInput/IVolmaInputProps';
import { IRegisterHelperProps } from './IHelperProps';
import { IRegisterProps } from './IRegisterProps';
import { RegisterActions } from './RegisterActions';
import { MouseEvent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import VolmaSelect from '../VolmaSelect/index';
import { ERegisterResult } from '../../Domain/Enum/ERegisterResult';
import i18next from '../i18n';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import {withRouter } from 'react-router-dom';


class Register extends VolmaComponent<IRegisterProps> {
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _registerActions: RegisterActions;
    private _routerService: RouterService;

    constructor(props: IRegisterProps, context: any) {
        super(props, context);

        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._registerActions = VolmaContainer.get<RegisterActions>(Types.RegisterActions);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this.onSubmit = this.onSubmit.bind(this);
        super.SetActions(this._registerActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    render() {
        let registerNotStarted       = this.props.DataHelper.RegisterResult === undefined;
        let isRegisteredSuccessfully = this.props.DataHelper.RegisterResult === ERegisterResult.Success;
        let isRegisterFailed         = this.props.DataHelper.RegisterResult === ERegisterResult.Failed;


        let errors = [];
        if (this.props.DataHelper.Errors !== undefined) {
            for (let error of this.props.DataHelper.Errors) {
                errors.push(<div>{error}</div>);
            }
        }


        return <div className='component' style={this.props.style}>
            <h1>Registration</h1>
            {isRegisteredSuccessfully && <span>{i18next.t('PasswordRestore:Registered successfully')}</span>}
            {isRegisterFailed && <div><div>Errors occured</div> {errors}</div>}


            <VolmaForm {...this.props as any}>
                <div>
                <div>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(this.props.Inputs, (val: RegisterUserDTO) => val.Username) }
                        Name={PropertyHelper.GetPropertyName((val: RegisterUserDTO) => val.Username) }
                        Label={i18next.t('LabelUserName') }
                        Placeholder={i18next.t('PlaceholderUserName') }
                        Value={this.props.DataDTO.Username}
                        Validator={this._nonEmptyInputValidator}
                        dispatch={this.props.dispatch}/>
                </div>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(this.props.Inputs, (val: RegisterUserDTO) => val.Email) }
                        Name={PropertyHelper.GetPropertyName((val: RegisterUserDTO) => val.Email) }
                        Label={i18next.t('LabelEmail') }
                        Placeholder={i18next.t('PlaceholderEmail') }
                        Value={this.props.DataDTO.Email}
                        Validator={this._nonEmptyInputValidator}
                        dispatch={this.props.dispatch}/>
                </div>
                <div>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(this.props.Inputs, (val: RegisterUserDTO) => val.Password) }
                        Name={PropertyHelper.GetPropertyName((val: RegisterUserDTO) => val.Password) }
                        Label={i18next.t('LabelPassword') }
                        Placeholder={i18next.t('PlaceholderPassword') }
                        Value={this.props.DataDTO.Password}
                        Validator={this._nonEmptyInputValidator}
                        dispatch={this.props.dispatch}/>
                </div>

                <VolmaSelect
                    {...(PropertyHelper.GetSelectPropertyByName(this.props.Selects, (val: RegisterUserDTO) => val.Role) as any) }
                    Name={PropertyHelper.GetPropertyName((x: RegisterUserDTO) => x.Role)}
                    Options={this.props.DataHelper.AllRoles}
                    LabelKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Name)}
                    ValueKey={PropertyHelper.GetPropertyName((x: ListIntOptionDTO) => x.Id)}
                    Value={this.props.DataHelper.SelectedRole}
                    HelperFieldName={PropertyHelper.GetPropertyName((val: IRegisterHelperProps) => val.SelectedRole)}
                    HelperFieldToDTOFieldTranslator={(x: ListIntOptionDTO) => x.Id}
                    dispatch={this.props.dispatch} />

                <div>
                    <VolmaButton label={i18next.t('ButtonRegister')} onClick={this.onSubmit}/>
                </div>
                <div>
                    <VolmaActionLink path={this._routerService.GetHome()} label={i18next.t('ReturnToLogin')}/>
                </div>
            </VolmaForm>
        </div>;
    }


    private LoadData(){
        this.props.dispatch(this._registerActions.LoadAllRoles());
    }

    private onSubmit(evt: MouseEvent<any>) {
        super.OnSubmit(evt);
        if (this.props.IsValid) {
            this.props.dispatch(this._registerActions.DoRegister(this.props.DataDTO, i18next.t('common:WaitingForTheServer')))
        }
    }
}

const translated = withTranslation(['common'])(withRouter(Register));

function select(state: IGlobalState, ownProps: IRegisterProps): IRegisterProps {
    return state.Register;
}

let connectedRegister = connect<IRegisterProps, {}, {}>(select)(translated);
export default connectedRegister;
