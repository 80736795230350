import { MultiCargoTransporterTableDTO } from "../../../../Domain/DTO/MultiCargoTransporterTableDTO";
import { MultiTransporterTableDTO } from "../../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes";

export class DeliveryManualAssignHelperProps {
    AllMultiCargoTransporters: Array<MultiCargoTransporterTableDTO>;
    SelectedMultiTransporter: MultiTransporterTableDTO;
}

export const InitialDeliveryManualAssignHelperProps: DeliveryManualAssignHelperProps = <DeliveryManualAssignHelperProps>{
    AllMultiCargoTransporters: [],
    SelectedMultiTransporter: undefined,
};
