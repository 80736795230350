import {
    VEHICLE_DRIVER_ADDED_TO_VEHICLE,
    VEHICLE_DRIVER_REMOVED_TO_VEHICLE,
    VEHICLE_DRIVERS_LOADED
} from '../../../../Constants/AppConstants';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { VehicleDTO } from '../../../../Domain/DTO/VehicleDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { VehicleHelperProps } from './IHelperProps';
import { injectable } from 'inversify';


@injectable()
export class VehicleReducer extends EntityReducer<VehicleDTO, VehicleHelperProps> {

    public Reduce(state: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<VehicleDTO, VehicleHelperProps>{
        switch (action.type) {
            case VEHICLE_DRIVERS_LOADED:
                return this.ReduceDriversLoaded(state, action.Payload);
            case VEHICLE_DRIVER_ADDED_TO_VEHICLE:
                return this.ReduceDriverAddedToVehicle(state, action.Payload);
            case VEHICLE_DRIVER_REMOVED_TO_VEHICLE:
                return this.ReduceDriverRemovedFromVehicle(state, action.Payload);
            default:
                return state;
        }
    }
    private ReduceDriversLoaded(state: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, payload: Array<DriverTableDTO>): IBaseEntityProps<VehicleDTO, VehicleHelperProps> {
        return super.CollectionLoaded<DriverTableDTO>(state, payload, [x => x.Drivers, x => x.DriversOriginal]);
    }

    private ReduceDriverAddedToVehicle(state: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, payload: DriverTableDTO) : IBaseEntityProps<VehicleDTO, VehicleHelperProps>{
        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Drivers = super.CopyCollectionObject(state.DataHelper.Drivers);
        newState.DataDTO.Drivers = super.CopyCollectionObject(state.DataDTO.Drivers);
        
        newState.DataHelper.Drivers.push(payload);
        newState.DataDTO.Drivers.push(payload.Id);

        return newState;
    }

    private ReduceDriverRemovedFromVehicle(state: IBaseEntityProps<VehicleDTO, VehicleHelperProps>, payload: string[]) : IBaseEntityProps<VehicleDTO, VehicleHelperProps>{
        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Drivers = super.CopyCollectionObject(state.DataHelper.Drivers);
        newState.DataDTO.Drivers = super.CopyCollectionObject(state.DataDTO.Drivers);

        for(let id of payload){
            let index = newState.DataHelper.Drivers.findIndex(x => x.Id === id)
            if (index >= 0)
                newState.DataHelper.Drivers.splice(index, 1);
        }

        for(let id of payload){
            let index = newState.DataDTO.Drivers.findIndex(x => x === id)
            if (index >= 0)
                newState.DataDTO.Drivers.splice(index, 1);
        }

        return newState;
    }

}
