import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { VEHICLE_DRIVER_REMOVED_TO_VEHICLE, VEHICLE_DRIVERS_LOADED } from '../../../../Constants/AppConstants';
import { VEHICLE_DRIVER_ADDED_TO_VEHICLE } from '../../../../Constants/AppConstants';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { injectable } from 'inversify';

@injectable()
export class VehicleActions extends EntityAction{

    public LoadDrivers(id: string, waitingText: string, filter: string = undefined) {
        return super.LoadChildDataTableItems(
            EEntityType.Vehicle,
            id,
            EEntityType.Driver,
            (x: DriverTableDTO) => x.Name,
            ESortDir.Asc,
            waitingText,
            this.DriversLoaded);
    }

    public DriverAddedToVehicle(value: DriverTableDTO): IActionPayloaded<DriverTableDTO>{
        return { type: VEHICLE_DRIVER_ADDED_TO_VEHICLE, Payload: value};    
    }

    public DriverRemovedFromVehicle(value: string[]): IActionPayloaded<Array<string>>{
        return { type: VEHICLE_DRIVER_REMOVED_TO_VEHICLE, Payload: value};
    }

    private DriversLoaded(value: Array<DriverTableDTO>): IActionPayloaded<Array<DriverTableDTO>> {
        return { type: VEHICLE_DRIVERS_LOADED, Payload: value };
    }
}