import { injectable } from 'inversify';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { CHART_BORDER_WIDTH } from '../../../../Constants/AppConstants';
import { ListOptionDTO } from '../../../../Domain/DTO/ListOptionDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { TendersReportParametersDTO } from '../../../../Domain/DTO/TendersReportParametersDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { SafeMergeArrays } from '../../../../Infrastructure/Services/Utils';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar/index';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import VolmaSelect from '../../../VolmaSelect/index';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IChartDataset } from '../IChart';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { ReportService } from './ReportService';

@injectable()
export class ReportTendersExcelService extends ReportService<TendersReportParametersDTO>{
    public Title: string = "ReportTendersExcelService";
    protected _options = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    min: 0,
                }
            }],
        },
    }

    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<TendersReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TendersReportParametersDTO) => val.ZoneId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: TendersReportParametersDTO) => x.ZoneId)}
                                Label={i18next.t('report:Zones')}
                                Placeholder={i18next.t('report:SelectZones')}
                                Entity={EEntityType.Zone}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.ZoneId}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TendersReportParametersDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: TendersReportParametersDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: TendersReportParametersDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: TendersReportParametersDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.LoadingPointId, props.DataHelper.ReportParams.MultiFactoryId])}
                                AllowMultiselect={true}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TendersReportParametersDTO) => val.ConsignorId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: TendersReportParametersDTO) => x.ConsignorId)}
                                Label={i18next.t('report:Consignor')}
                                Placeholder={i18next.t('report:SelectConsignor')}
                                Entity={EEntityType.Consignor}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.ConsignorId}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TendersReportParametersDTO) => val.DateFrom) as any) }
                                Name={PropertyHelper.GetPropertyName((val: TendersReportParametersDTO) => val.DateFrom)}
                                Label={i18next.t('report:LabelDateFrom')}
                                Value={props.DataHelper.ReportParams.DateFrom}
                                DefaultHours={0}
                                DefaultMinutes={0}
                                DefaultSeconds={0}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch}
                                IsClearable />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TendersReportParametersDTO) => val.DateTo) as any) }
                                Name={PropertyHelper.GetPropertyName((val: TendersReportParametersDTO) => val.DateTo)}
                                Label={i18next.t('report:LabelDateTo')}
                                Value={props.DataHelper.ReportParams.DateTo}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch}
                                IsClearable />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public HasChart(): boolean {
        return true;
    }

   public GetChart(data: any) {
        return <Line data={data} options={this._options}/>
    }
    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset) {
        dataset.borderColor = borderColor;
        dataset.borderWidth = CHART_BORDER_WIDTH;
    }

}