import { DeliveryUpdateAssignTypeDTO } from '../../../../Domain/DTO/DeliveryUpdateAssignTypeDTO';
import { ERequestForDeliveryAssigner } from '../../../../Domain/Enum/ERequestForDeliveryAssigner';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { BaseValidator } from '../../../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import i18next from '../../../i18n';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../../VolmaInput/index';
import VolmaNumber from '../../../VolmaNumber';
import { IVolmaNumberProps } from '../../../VolmaNumber/IVolmaNumberProps';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { injectable } from 'inversify';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import * as React from 'react';


@injectable()
export class DeliveryUpdateAssignTypeEntityService extends BaseEntityService<DeliveryUpdateAssignTypeDTO, {}>{
    private _volmaTableService: VolmaTableService;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<DeliveryUpdateAssignTypeDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryUpdateAssignTypeDTO, {}>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryUpdateAssignTypeDTO) => val.AssignType) }
                                        Name={PropertyHelper.GetPropertyName((val: DeliveryUpdateAssignTypeDTO) => val.AssignType)}
                                        Label={i18next.t('deliveryupdateassigntype:AssignType')}
                                        Placeholder={i18next.t('deliveryupdateassigntype:SelectAssignType')}
                                        EnumGetter={() => ERequestForDeliveryAssigner}
                                        Value={props.DataDTO.AssignType}
                                        Required={true}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaInput
                                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryUpdateAssignTypeDTO) => val.Comment) }
                                        Name={PropertyHelper.GetPropertyName((val: DeliveryUpdateAssignTypeDTO) => val.Comment)}
                                        Type="textarea"
                                        Label={i18next.t('deliveryupdateassigntype:Comment')}
                                        Value={props.DataDTO.Comment}
                                        Required={true}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<DeliveryUpdateAssignTypeDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<DeliveryUpdateAssignTypeDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: DeliveryUpdateAssignTypeDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<DeliveryUpdateAssignTypeDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }
}