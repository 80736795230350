import { ITableDTO } from "../../Domain/ITableDTO";
import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import { BaseValidator } from "./BaseValidator";
import { IVolmaSelectProps } from "../../Components/VolmaSelect/IVolmaSelectProps";
import i18next from "../../Components/i18n";

@injectable()
export class VolmaSelectValidator extends BaseValidator<any> {
    public Validate(entity: IVolmaSelectProps): ValidationResult {
        if (entity === undefined || entity === null) throw new Error("Null reference exception");

        let required = undefined;
        if (typeof entity.Required === "function") {
            required = entity.Required();
        } else {
            required = entity.Required;
        }

        const entityValueIsNotDefined =
            entity.Value === undefined || entity.Value === null || (<Array<ITableDTO>>entity.Value).length === 0;

        if (required) {
            const isMultiEntitySelect = Array.isArray(entity.Entity);
            const someEntityMultiValueNotEmpty =
                entity.EntityMultiValue !== undefined &&
                entity.EntityMultiValue.some((v) => (Array.isArray(v) ? v.length > 0 : v !== undefined));

            if (isMultiEntitySelect && someEntityMultiValueNotEmpty) {
                return ValidationResult.Success();
            } else if (entityValueIsNotDefined) {
                return ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty"));
            }
        }

        if (!required && entityValueIsNotDefined) {
            return ValidationResult.Success();
        }

        return ValidationResult.Success();
    }
}
