import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import {BaseValidator} from "./BaseValidator";
import { IVolmaNumberProps } from '../../Components/VolmaNumber/IVolmaNumberProps';
import i18next from '../../Components/i18n';
import { IVolmaFileProps } from '../../Components/VolmaFile/IVolmaFileProps';

@injectable()
export class VolmaFileValidator extends BaseValidator<IVolmaFileProps> {

    public Validate(entity: IVolmaFileProps): ValidationResult {
        if (entity === undefined || entity === null)
            throw new Error("Null reference exception");

        if (entity.FileData !== undefined && entity.FileData.UploadErrorMessage != undefined && entity.FileData.UploadErrorMessage.length > 0)
            return ValidationResult.Fail(i18next.t("validate:UploadError", <any>{ error: entity.FileData.UploadErrorMessage }));

        if (entity.Required){
            if (entity.Value === undefined && entity.FileData.File === undefined)
                return ValidationResult.Fail(i18next.t("validate:FileMustBeChosen"));
            if (entity.Value === undefined && entity.FileData.File !== undefined)
                return ValidationResult.Fail(i18next.t("validate:FileMustBeUploaded"));
        }
        else{
            if (entity.Value === undefined && entity.FileData.File !== undefined)
                return ValidationResult.Fail(i18next.t("validate:FileMustBeUploaded"));
        }

    

        return ValidationResult.Success();
    }
}