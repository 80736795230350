import { injectable } from 'inversify';
import { BIDDING_BIDDING_BETS_LOADED } from '../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { Types } from '../../../Infrastructure/Types';
import { EntityAction } from '../BaseEntity/EntityAction';

@injectable()
export class BiddingActions extends EntityAction {

    private _api: TableServerInteraction;

    constructor() {
        super();

        this._api = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
    }


    public LoadBets(biddingId: string, waitingText: string) {
        return super.LoadChildDataTableItems<BiddingBetTableDTO>(
            EEntityType.Bidding,
            biddingId,
            EEntityType.BiddingBet,
            x => x.Bet,
            ESortDir.Asc,
            waitingText,
            x => this.BetsLoaded(x));
    }

    private BetsLoaded(value: Array<BiddingBetTableDTO>): IActionPayloaded<Array<BiddingBetTableDTO>> {
        return { type: BIDDING_BIDDING_BETS_LOADED, Payload: value };
    }

}