import { injectable } from 'inversify';
import * as React from 'react';

import { history } from '../../../../ConfigureStore';
import { SearchResultDTO } from '../../../../Domain/DTO/SearchResultDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import i18next from '../../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from './VolmaTableCommonCellRenderers';


@injectable()
export class VolmaTableSearchCellRenderers {

    public static SearchLinkCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams,
        routeService: RouterService) {

        let search = props.Data[params.rowIndex] as SearchResultDTO;
        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let entity = search.Type;
        if (search.Type === EEntityType.RequestForDeliveryState)
            entity = EEntityType.Delivery;
        if (search.Type === EEntityType.ComplaintComment)
            entity = EEntityType.Complaint;
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <a className={(VolmaTableCommonCellRenderers._tableCont("text", {link: true })).toString()} onClick={() => history.push({pathname:routeService.GetCertainEntityPathname(entity, search.Id)})}>{i18next.t("search:OpenEntity")}</a>
                </div>
            </div>
        )
    }
}