import { EntityReducer } from '../../../BaseEntity/EntityReducer';
import { SelectDriverHelperProps } from './HelperProps';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { IBaseEntityProps } from '../../../BaseEntity/IBaseEntityProps';
import { SelectDriverDTO } from './SelectDriverDTO';
import { VEHICLE_ALL_DRIVER_LOADED, VEHICLE_DRIVER_ADDED_TO_VEHICLE } from '../../../../../Constants/AppConstants';
import { DriverTableDTO } from '../../../../../Domain/DTO/DriverTableDTO';

export class SelectDriverReducer extends EntityReducer<SelectDriverDTO, SelectDriverHelperProps>{

    public Reduce(state: IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps> {
        switch (action.type) {
             case VEHICLE_ALL_DRIVER_LOADED:
                return this.ReduceAllDriversLoaded(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceAllDriversLoaded(state: IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>, payload: Array<DriverTableDTO>): IBaseEntityProps<SelectDriverDTO, SelectDriverHelperProps>{
        if (state.DataDTO.SelectedDriverIds === undefined){
            state.DataDTO.SelectedDriverIds = new Array();
        }
        let filtered = payload.filter(x => state.DataDTO.SelectedDriverIds.indexOf(x.Id) == -1);

        return super.CollectionLoaded<DriverTableDTO>(state, filtered, [x => x.DriverList]);
    }
}