import {
    VOLMA_SIGNALR_INVOKE,
    VOLMA_SIGNALR_START_CONNECTION,
    VOLMA_SIGNALR_STATE_CHANGED,
    VOLMA_SIGNALR_STOP_CONNECTION
} from '../../Constants/AppConstants';
import { ECQRSApi } from '../ServerInteraction/ECQRSApi';
import { UrlFabric } from '../ServerInteraction/UrlFabric';
import { ESignalRConnectionState } from './ESignalRConnectionState';

declare var $: any;
export const connection = $.hubConnection(UrlFabric.GetApiBaseUrl(ECQRSApi.Read));
connection.logging = false;

export default function createSignalrMiddleware(actionDispatcher) {
    return store => {

        const dispatch = store.dispatch.bind(store)
        var keepAlive:boolean = false
        var wasConnected:boolean = false
        var currentState: ESignalRConnectionState = null

        actionDispatcher(dispatch, connection);

        function onStateChanged(state: ESignalRConnectionState) {
            if (currentState === state) {
                return
            }
            currentState = state
            dispatch({
                type: VOLMA_SIGNALR_STATE_CHANGED,
                state: state
            });
        }

        connection.stateChanged(state => {
            const newState = state.newState as ESignalRConnectionState

            if (newState === ESignalRConnectionState.Connected) {
                wasConnected = true
                onStateChanged(newState)
            }
        });

        // When the connection drops, try to reconnect.
        connection.disconnected(function () {
            if (keepAlive) {
                if (wasConnected) {
                    onStateChanged(ESignalRConnectionState.Reconnecting)
                } else {
                    onStateChanged(ESignalRConnectionState.Connecting)
                }
                setTimeout(() => connection.start().done(() => {
                    console.log('SignalR: connectionid ', connection !== undefined && connection !== null ? connection.id : "null or undefined");
                }), 10000);
            }
        });

        return next => action => {
            const { type } = action;
            switch (type) {
                case VOLMA_SIGNALR_START_CONNECTION:
                    keepAlive = true
                    onStateChanged(ESignalRConnectionState.Connecting)
                    setTimeout(() => connection.start().done(() => {
                        console.log('SignalR: connectionid ', connection !== undefined && connection !== null ? connection.id : "null or undefined");
                    }), 0);
                    return;
                case VOLMA_SIGNALR_STOP_CONNECTION:
                    keepAlive = false
                    wasConnected = false
                    onStateChanged(ESignalRConnectionState.Disconnected)
                    connection.stop()
                    return;
                case VOLMA_SIGNALR_INVOKE:
                    const { hub, method, args } = action;
                    const proxy = connection[hub]
                    proxy.invoke(method, ...args)
                    return;
                default:
                    return next(action);
            }
        };
    };
}