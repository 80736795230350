import {
    COMPLAINT_TEMPLATE_FILE_DELETED,
    COMPLAINT_TEMPLATE_FILE_FAILED_TO_UPLOAD,
    COMPLAINT_TEMPLATE_FILE_INFO_LOADED,
    COMPLAINT_TEMPLATE_FILE_UPLOADED,
    VOLMA_ENTITY_DATA_LOADED
} from '../../../Constants/AppConstants';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { FileDTO } from '../../../Domain/DTO/FileDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ComplaintTemplateHelperProps } from './IHelperProps';
import { injectable } from 'inversify';


@injectable()
export class ComplaintTemplateReducer extends EntityReducer<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {

    public Reduce(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>{
        switch (action.type) {
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case COMPLAINT_TEMPLATE_FILE_INFO_LOADED:
                return this.ReduceFileInfoLoaded(state, action.Payload);
            case COMPLAINT_TEMPLATE_FILE_DELETED:
                return this.ReduceFileDeleted(state, action.Payload);
            case COMPLAINT_TEMPLATE_FILE_UPLOADED:
                return this.ReduceFileUploaded(state, action.Payload);
            case COMPLAINT_TEMPLATE_FILE_FAILED_TO_UPLOAD:
                return this.ReduceFileFailedToUpload(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceFileInfoLoaded(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, payload: FileDTO): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {
        let touchedFiles = new Array<FileDTO>();
        touchedFiles.push(payload);

        let newState = <IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>>{ ...state, 
            DataHelper: <ComplaintTemplateHelperProps>{ ...state.DataHelper, FileInfo: payload, TouchedFiles: touchedFiles} };

        return this.UpdateDTO(newState);
    }

    private ReduceDataLoaded(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, payload: ComplaintTemplateDTO): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {
        return super.DataLoaded(state, payload);
    }


    private ReduceFileDeleted(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, payload: FileDTO): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {
            
        return <IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>>{ ...state, 
            DataDTO: <ComplaintTemplateDTO>{ ...state.DataDTO, FileId: undefined },  
            DataHelper: <ComplaintTemplateHelperProps>{ ...state.DataHelper, FileInfo: undefined}
        };
    }

    private ReduceFileUploaded(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, payload: FileDTO): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {
        let touchedFiles = state.DataHelper.TouchedFiles.splice(0);
        touchedFiles.push(payload);
            
        return <IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>>{ ...state, 
            DataDTO: <ComplaintTemplateDTO>{ ...state.DataDTO, FileId: payload.Id },  
            DataHelper: <ComplaintTemplateHelperProps>{ ...state.DataHelper, UploadFailed: false, FileInfo: payload, TouchedFiles: touchedFiles}
        };
    }

    private ReduceFileFailedToUpload(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>, payload: string): IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps> {
        return <IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>>{ ...state, 
            DataHelper: <ComplaintTemplateHelperProps>{ ...state.DataHelper, UploadFailed: true, UploadErrorMessage: payload}
        };
    }
    
    protected UpdateDTO(state: IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>){
        let newState = state;

        if (state.DataDTO.FileId !== undefined && state.DataHelper.FileInfo != undefined ) {

            let fileInfo = state.DataHelper.FileInfo;

            newState = <IBaseEntityProps<ComplaintTemplateDTO, ComplaintTemplateHelperProps>>{
                ...state,
                DataDTO: <ComplaintTemplateDTO>{ ...state.DataDTO, FileId: fileInfo.Id },
                DataHelper: <ComplaintTemplateHelperProps>{ ...state.DataHelper, FileInfo: fileInfo }
            };
        }

        return newState;
    }
}
