import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import { DRIVER_MONITORING_DELIVERIES_LOADED, DRIVER_MONITORING_DRIVERS_LOADED, DRIVER_MONITORING_DRIVERS_SELECTED, DRIVER_MONITORING_FILTERS_CHANGED, DRIVER_MONITORING_MOUNTED, DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED, DRIVER_MONITORING_SELECTED_ROW, DRIVER_MONITORING_UNMOUNTED } from '../../../Constants/AppConstants';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { EntityDTO } from '../../../Domain/DTO/EntityDTO';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { TableDataDTO } from '../../../Domain/TableDataDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { DriverMonitoringActionsHelper } from './DriverMonitoringActionsHelper';
import { DeliveryMonitoringQuery, MultiTransporterTableDTO } from './IHelperProps';

@injectable()
export class DriverMonitoringActions extends ComponentActions {
    private _apiActions: ApiActions;
    private _apiTable: TableServerInteraction;

    private _driverMonitoringActionsHelper: DriverMonitoringActionsHelper;
    
    constructor() {
        super();
        super.Initialize(DRIVER_MONITORING_MOUNTED, DRIVER_MONITORING_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._apiTable = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);

        this._driverMonitoringActionsHelper = new DriverMonitoringActionsHelper();
    }

    public LoadDeliveriesBySelectedValue(waitingText: string, selectedMultiTransporters: Array<MultiTransporterTableDTO>, selectedDrivers: Array<DriverTableDTO>, identifier: string) {
        const selectedValueFilters = this._driverMonitoringActionsHelper.GetDeliveryFiltersBySelectedValues(selectedMultiTransporters, selectedDrivers, identifier);

        return this.LoadDeliveriesByFilters(waitingText, selectedValueFilters, true);
    }
    
    public LoadDeliveriesByQuery(waitingText: string, query: DeliveryMonitoringQuery) {
        const queryFilters = this._driverMonitoringActionsHelper.GetDeliveryFiltersByQuery(query);

        return this.LoadDeliveriesByFilters(waitingText, queryFilters, true);
    } 
    
    public LoadDeliveriesByFilters(waitingText: string, filters: FilterDTO[], enableFilterSaving?: boolean) {
        return this.LoadEntities(EEntityType.DeliveryPerforming, waitingText, filters, (data: TableDataDTO<DeliveryTableDTO>) => this.DeliveriesLoaded(data.Items), enableFilterSaving);
    }

    public LoadDriversBySelectedValues(waitingText: string, selectedMultiTransporters: Array<MultiTransporterTableDTO>) {
        const selectedValueFilters = this._driverMonitoringActionsHelper.GetFiltersBySelectedMultiTransporterValues(selectedMultiTransporters);

        return this.LoadEntities(EEntityType.Driver, waitingText, selectedValueFilters, (data: TableDataDTO<DriverTableDTO>) => this.DriversLoaded(data.Items));
    } 

    public LoadDriversByQuery(waitingText: string, query: DeliveryMonitoringQuery) {
        const queryFilters = this._driverMonitoringActionsHelper.GetFiltersByMultiTransporterQuery(query);

        return this.LoadEntities(EEntityType.Driver, waitingText, queryFilters, (data: TableDataDTO<DriverTableDTO>) => this.DriversLoaded(data.Items));
    } 
    
    public LoadEntities<T>(entityType: EEntityType, waitingText: string, filters: FilterDTO[], onLoadCallback: (data: TableDataDTO<T>) => void, enableFilterSaving?: boolean) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(entityType, PropertyHelper.GetPropertyName((x: EntityDTO) => x.Id), ESortDir.Asc, filters, undefined, undefined),
                waitingText,
                (response: AxiosResponse) => {
                    const data: TableDataDTO<T> = JSON.parse(response.data);             
                    dispatch(onLoadCallback(data));

                    if (enableFilterSaving) {
                        dispatch(this.DeliveryMonitoringFiltersChanged(filters));                        
                    }
                },
            );
        }
    }

    public ChangeMonitoringFiltersByQuery(value: DeliveryMonitoringQuery) {
        const filters = this._driverMonitoringActionsHelper.GetDeliveryFiltersByQuery(value);

        return this.DeliveryMonitoringFiltersChanged(filters);
    }  

    public MultiTransportersSelected(value: Array<MultiTransporterTableDTO>) {
        return { type: DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED, Payload: value };
    }

    public DriversSelected(value: Array<DriverTableDTO>) {
        return { type: DRIVER_MONITORING_DRIVERS_SELECTED, Payload: value };
    }
    
    public DriverOnRowSelected(value: IEntityDTO): IActionPayloaded<IEntityDTO> {
        return { type: DRIVER_MONITORING_SELECTED_ROW, Payload: value };
    }    

    public DeliveryMonitoringFiltersChanged(value: FilterDTO[]) {
        return { type: DRIVER_MONITORING_FILTERS_CHANGED, Payload: value };
    }

    private DeliveriesLoaded(value: Array<DeliveryTableDTO>) {
        return { type: DRIVER_MONITORING_DELIVERIES_LOADED, Payload: value };
    }

    private DriversLoaded(value: Array<DriverTableDTO>) {
        return { type: DRIVER_MONITORING_DRIVERS_LOADED, Payload: value };
    }
}