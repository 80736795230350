import { injectable } from 'inversify';
import * as React from 'react';
import { history } from '../../../../ConfigureStore';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERating } from '../../../../Domain/Enum/ERating';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import { Types } from '../../../../Infrastructure/Types';
import { IRatingTableHelperProps } from '../../../Entity/CargoTransporter/Rating/Table/ITableHelperProps';
import i18next from '../../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from './VolmaTableCommonCellRenderers';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';

@injectable()
export class VolmaTableRatingCellRenderers {
    public static RatingMultiPointNameCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams,
        backPath: string,
        withHistoryLink = true) {
        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);

        const rating = params.rowData as RatingTableDTO;
        const icons = VolmaTableRatingCellRenderers.RatingMultiPointIcons;
        const formatService = VolmaContainer.get<FormatService>(Types.FormatService);
        const isMultiFactory = formatService.IsValueNotEmpty(rating.MultiFactoryId);
        const ratingView = isMultiFactory ? { icon: icons[1], text: rating.MultiFactoryName } : { icon: icons[0], text: rating.LoadingPointName };
        
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", { selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    {ratingView.icon}                
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{ratingView.text}</span>
                    {withHistoryLink && <a className={(VolmaTableCommonCellRenderers._tableCont("text", {link: true })).toString()} 
                        onClick={() => VolmaTableRatingCellRenderers.OnRatingHistoryClick(props, params, backPath)}>{i18next.t("currentrating:OpenRatingHistory")}
                    </a>}
                </div>
            </div>
        )
    }

    public static ForecastRatingRenderer(
        enumService: EnumService,
        props: IVolmaTableProps<IRatingTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        const rowData = (props.Data[params.rowIndex] as RatingTableDTO);
        const loadingPointId = rowData.LoadingPointId;
        const cargoTransporterId = rowData.CargoTransporterId;
        const forecastRating: RatingTableDTO = props.DataHelper.ForecastRating !== undefined ?
            props.DataHelper.ForecastRating.find((x: RatingTableDTO) => x.LoadingPointId === loadingPointId && x.CargoTransporterId === cargoTransporterId ) :
            undefined;

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);

        if (forecastRating !== undefined)
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{enumService.GetOptionLocalized(() => ERating, forecastRating.RatingValue)}</span>
                    </div>
                </div>
            )
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("currentrating:NoRating")}</span>
                </div>
            </div>
        )
    }

    protected static OnRatingHistoryClick(
        props: IVolmaTableProps<{}>,
        cellRenderProps: IVolmaTableCellRendererParams,
        backPath: string) {

        const routerService = VolmaContainer.get<RouterService>(Types.RouterService);
        const cellData = cellRenderProps.rowData as RatingTableDTO;
        history.push(
            {
                pathname: routerService.GetCertainEntityPathname(EEntityType.CurrentRating, cellData.Id),
                search: `?back=${backPath}`
            }
        );
    }

    protected static get RatingMultiPointIcons(): JSX.Element[] {
        return [
            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico")).mix(["point-ico"]).toString()}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#loading-point"></use>
            </svg>,
            <svg className={(VolmaTableCommonCellRenderers._tableCont("ico")).mix(["point-ico"]).toString()}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#multi-factory"></use>
            </svg>
        ];
    }
}
