import { IBaseValueChangedPayload, IValidatePayload } from '../../VolmaInput/Payloads';
import { ISelectValueChangedPayload, ISelectValidatePayload } from '../../VolmaSelect/Payloads';
import {
    EMPTY_GUID,
    INPUT_VALUE_CHANGE,
    SELECT_VALUE_CHANGE,
    VOLMA_ENTITY_DATA_LOADED,
    VOLMA_ENTITY_INFO_LINE_TOGGLED,
    VOLMA_ENTITY_STATUS_TOGGLED
} from '../../../Constants/AppConstants';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ApiActions } from '../../Shared/ApiActions';
import { Types } from '../../../Infrastructure/Types';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { AxiosResponse } from 'axios';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { injectable } from 'inversify';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityState } from '../../../Domain/Enum/EEntityState';
import { SELECT_VALIDATE, INPUT_VALIDATE, VOLMA_ENTITY_FOOTER_NAV_ADDED } from '../../../Constants/AppConstants';
import { FooterNavLink } from '../../Footer/IFooterProps';
import { InMemoryStorageService } from '../../../Infrastructure/Services/InMemoryStorageService';
import { TableSignalRServerInteraction } from '../../../Infrastructure/ServerInteraction/TableSignalRServerInteraction';

@injectable()
export class EntityAction {
    protected _apiTable  : TableServerInteraction;
    protected _apiSignalR: TableSignalRServerInteraction;
    protected _apiActions: ApiActions;
    protected _cache     : InMemoryStorageService;
    protected _defaultChildTableItemsCount: number = 25000;

    constructor() {
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._apiTable   = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._cache      = VolmaContainer.get<InMemoryStorageService>(Types.InMemoryStorageService);
        this._apiSignalR = VolmaContainer.get<TableSignalRServerInteraction>(Types.TableSignalRServerInteraction);
    }

    public ToggleStatus(index: number): IActionPayloaded<number>{
        return { type: VOLMA_ENTITY_STATUS_TOGGLED, Payload: index}
    }

    public ToggleInfoLine(index: number): IActionPayloaded<number>{
        return { type: VOLMA_ENTITY_INFO_LINE_TOGGLED, Payload: index}
    }

    public FooterLinkAdded(item: FooterNavLink): IActionPayloaded<FooterNavLink>{
        return { type: VOLMA_ENTITY_FOOTER_NAV_ADDED, Payload: item};
    }

    private LoadDataTableNoSignalR<TTableDTO>(
        entity: EEntityType,
        orderFieldGetter: (x: TTableDTO) => any,
        sortDir: ESortDir,
        filter: Array<FilterDTO>,
        waitingText: string,
        success: (data: Array<TTableDTO>) => IAction) {

        let filterDto = undefined;

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(entity, orderFieldGetter !== undefined ? PropertyHelper.GetPropertyName(orderFieldGetter) : undefined, sortDir, filter, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    dispatch(success(data.Items));
                });
        }
    }

    protected LoadDataTable<TTableDTO>(
        entity: EEntityType,
        orderFieldGetter: (x: TTableDTO) => any,
        sortDir: ESortDir,
        filter: Array<FilterDTO>,
        waitingText: string,
        success: (data: Array<TTableDTO>) => IAction) {
        let sortBy = orderFieldGetter !== undefined ? PropertyHelper.GetPropertyName(orderFieldGetter) : undefined;

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiSignalR.GetTableData(entity, sortBy, sortDir, filter, undefined, undefined)
                    .then((tableData) => dispatch(success(tableData.Items)))
                    .catch((error) => { console.error(error); dispatch(this.LoadDataTableNoSignalR(entity, orderFieldGetter, sortDir, filter, waitingText, success)) })
                ,
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }


    private LoadChildDataTableItemsNoSignalR<TTableDTO>(
        baseEntity: EEntityType,
        id: string,
        childEntity: EEntityType,
        orderFieldGetter: (x: TTableDTO) => any,
        sortDir: ESortDir,
        waitingText: string,
        success: (data: Array<TTableDTO>) => any) {

        let sortBy = orderFieldGetter !== undefined ? PropertyHelper.GetPropertyName(orderFieldGetter) : undefined;

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetChildTableData(baseEntity, id, childEntity, sortBy, sortDir, undefined, this._defaultChildTableItemsCount, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    dispatch(success(data.Items));
                });
        }
    }

    protected LoadChildDataTableItems<TTableDTO>(
        baseEntity: EEntityType,
        id: string,
        childEntity: EEntityType,
        orderFieldGetter: (x: TTableDTO) => any,
        sortDir: ESortDir,
        waitingText: string,
        success: (data: Array<TTableDTO>) => any) {
        let sortBy = orderFieldGetter !== undefined ? PropertyHelper.GetPropertyName(orderFieldGetter) : undefined;

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiSignalR.GetChildTableData(baseEntity, id, childEntity, sortBy, sortDir, [], this._defaultChildTableItemsCount, undefined)
                    .then((tableData) => dispatch(success(tableData.Items)))
                    .catch((error) => { console.error(error); dispatch(this.LoadChildDataTableItemsNoSignalR(baseEntity, id, childEntity, orderFieldGetter, sortDir, waitingText, success)) })
                ,
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }


    private LoadEntityNoSignalR<TEntity>(
        id: string,
        entity: EEntityType,
        waitingText: string,
        success: (data: TEntity) => IAction) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetEntity(entity, id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    this._cache.SetKey(id, data);
                    dispatch(success(data));
                });
        }
    }


    protected LoadEntityCached<TEntity>(
        id: string,
        entity: EEntityType,
        waitingText: string,
        success: (data: TEntity) => IAction) {
        if(id === undefined || id === null || id === EMPTY_GUID)
            return () => {};
        if(this._cache.IsKeyExist(id))
            return (dispatch: any) => {dispatch(success(this._cache.GetKey(id)));}

        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiSignalR.GetEntity(entity, id)
                    .then((data) => { this._cache.SetKey(id, data); dispatch(success(data))})
                    .catch((error) => { console.error(error); dispatch(this.LoadEntityNoSignalR(id, entity, waitingText, success))})
                ,
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });

        }
    }

    public ReloadEntity(dispatch: any, entity: EEntityType, id: string, waitingText: string, onAfterDataLoaded: (dto: any) => void) {
        this._apiActions.DoApiRequest(
            dispatch,
            () => this._apiSignalR.GetEntity(entity, id)
                .then((data) => {onAfterDataLoaded(data); dispatch(this.EntityLoaded(data)); })
                .catch((error) => { console.error(error); this.ReloadEntityNoSignalR(dispatch, entity, id, waitingText, onAfterDataLoaded) })
            ,
            waitingText,
            (response: AxiosResponse, dispatch: any) => {
            });
    }

    private ReloadEntityNoSignalR(dispatch: any, entity: EEntityType, id: string, waitingText: string, onAfterDataLoaded: (dto: any) => void) {
        this._apiActions.DoApiRequest(
            dispatch,
            () => this._apiTable.GetEntity(entity, id),
            waitingText,
            (response: AxiosResponse, dispatch: any) => {
                let data = JSON.parse(response.data);
                onAfterDataLoaded(data);
                dispatch(this.EntityLoaded(data));
            });
    }

    private EntityLoaded(data: any): IActionPayloaded<any> {
        return { type: VOLMA_ENTITY_DATA_LOADED, Payload: data };
    }
}