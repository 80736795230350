import { IConfirmOnUnsavedChangesPayload } from './Payloads';
import {
    INPUT_VALUE_CHANGE,
    REQUEST_BEGIN,
    REQUEST_CLEAR_ERROR,
    REQUEST_END,
    REQUEST_SERVER_ERROR,
    SELECT_VALUE_CHANGE,
    VOLMA_MODAL_CLOSE,
    VOLMA_MODAL_DATA_LOADED,
    VOLMA_MODAL_OPEN,
    VOLMA_MODAL_SET_ENTITY_SERVICE,
    VOLMA_TABLE_DATA_UPDATED
} from '../../Constants/AppConstants';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import { Types } from '../../Infrastructure/Types';
import { IRequestBeginPayload, IRequestEndPayload, IRequestErrorPayload } from '../App/Payloads';
import { IEntityService } from '../Entity/BaseEntity/IEntityService';
import { IVolmaTableDataPayload } from '../Table/VolmaTable/Payload';
import { IVolmaFormProps } from '../VolmaForm/IVolmaFormProps';
import { VolmaFormReducer } from '../VolmaForm/VolmaFormReducer';
import { IBaseValueChangedPayload } from '../VolmaInput/Payloads';
import { IVolmaModalProps, VolmaModalPropsInitial } from './IVolmaModalProps';
import { ISelectValueChangedPayload } from '../VolmaSelect/Payloads';
import { VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE } from '../../Constants/AppConstants';
import { VolmaComponentReducer } from '../Shared/VolmaComponentReducer';

export class VolmaModalReducer implements IReducePayloaded<IVolmaModalProps<IEntityDTO, any>> {
    private _formReducer: VolmaFormReducer;
    
    constructor() {
        this._formReducer = VolmaContainer.get<VolmaFormReducer>(Types.VolmaFormReducer);
    }

    public Reduce(state: IVolmaModalProps<IEntityDTO, any> = VolmaModalPropsInitial, action: IActionPayloaded<any>): IVolmaModalProps<IEntityDTO, any> {

        if (state.IsMounted)
            state = this._formReducer.Reduce(state, action);

        let entityService = state.EntityService;
        if (entityService !== undefined && state.IsMounted) {
            let entityReducer = entityService.GetReducer();
            if (entityReducer !== undefined) {
                state = entityReducer.Reduce(state, action);
            }
        }

        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                if (state.IsMounted)
                    return this.UpdateData(state, action.Payload);
                return state;
            case SELECT_VALUE_CHANGE:
                if (state.IsMounted)
                    return this.SelectUpdateData(state, action.Payload);
                return state;
            case VOLMA_TABLE_DATA_UPDATED:
                if (state.IsMounted)
                    return this.UpdateTableData(state, action.Payload);
                return state;
            case VOLMA_MODAL_OPEN:
                return this.Mounted(state);
            case VOLMA_MODAL_CLOSE:
                return this.Unmounted(state);
            case VOLMA_MODAL_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case VOLMA_MODAL_SET_ENTITY_SERVICE:
                return this.ReduceSetEntityService(state, action.Payload);
            case REQUEST_BEGIN:
                if (!state.IsMounted) return state;
                return this.RequestBegin(state, action.Payload);
            case REQUEST_END:
                if (!state.IsMounted) return state;
                return this.RequestEnd(state, action.Payload);
            case REQUEST_SERVER_ERROR:
                if (!state.IsMounted) return state;
                return this.RequestError(state, action.Payload);
            case REQUEST_CLEAR_ERROR:
                if (!state.IsMounted) return state;
                return this.RequestClearError(state);
            case VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE:
                if (!state.IsMounted) return state;
                return this.ReduceToggleConfirmMessage(state, action.Payload);
            default:
                return state;
        }
    }

    private UpdateData(state: IVolmaModalProps<IEntityDTO, any>, payload: IBaseValueChangedPayload<any>): IVolmaFormProps {
        let newState: IVolmaModalProps<IEntityDTO, any> = { ...state, DataDTO: { ...state.DataDTO } };
        let inputState = state.Inputs.find(x => x.Name === payload.InputName);
        if (inputState.CustomDataUpdate === undefined) {
            if (newState.DataHelper.hasOwnProperty(payload.InputName))
                newState.DataHelper[payload.InputName] = payload.Value;
            else
                newState.DataDTO[payload.InputName] = payload.Value;
        }
        else
            inputState.CustomDataUpdate(newState, payload.InputName, payload.Value);
        return newState;
    }

    private SelectUpdateData(state: IVolmaModalProps<IEntityDTO, any>, payload: ISelectValueChangedPayload): IVolmaFormProps {
        let selectState = state.Selects.find(x => x.Name === payload.SelectName);

        if (selectState === undefined) {
            throw new Error("Unregistered select name '" + payload.SelectName + "'");
        }

        if (payload.Value === null)
            payload.Value = undefined;
        let fieldValue = undefined;
        if (payload.Value !== undefined)
            fieldValue = selectState.HelperFieldToDTOFieldTranslator(payload.Value);

        let newState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };
        const fieldName = payload.EntityData?.DTOFieldName || selectState.DTOFieldName;

        if (selectState.CustomDataUpdate === undefined) {            
            newState.DataDTO[fieldName] = fieldValue;
            
            if (selectState.HelperFieldName !== undefined) {
                newState.DataHelper[selectState.HelperFieldName] = payload.Value;
            }
        } else {
            selectState.CustomDataUpdate(newState, fieldName, selectState.HelperFieldName, fieldValue, payload.Value);
        }

        return newState;
    }

    private UpdateTableData(state: IVolmaModalProps<IEntityDTO, any>, payload: IVolmaTableDataPayload): IVolmaFormProps {
        let newState: IVolmaModalProps<IEntityDTO, any> = { ...state, DataHelper: { ...state.DataHelper } };
        newState.DataHelper[payload.TableName] = payload.Items;
        return newState;
    }

    private Mounted(state: IVolmaFormProps): IVolmaFormProps {
        const newState: IVolmaFormProps = { ...state, IsMounted: true };
        return newState;
    }

    private Unmounted(state: IVolmaFormProps): IVolmaModalProps<IEntityDTO, any> {
        const newState = { ...state, IsMounted: false, DataDTO: undefined, DataHelper:{}, Inputs: [], Selects: [], Tables:[], IsSubmitted: false, IsTouched: false, IsValid: false };

        return newState;
    }

    private ReduceDataLoaded(state: IVolmaModalProps<IEntityDTO, any>, payload: IEntityDTO): IVolmaModalProps<IEntityDTO, any> {
        return <IVolmaModalProps<IEntityDTO, any>>{ ...state, DataDTO: payload, DataDTOStringify: JSON.stringify(payload) };
    }

    private ReduceSetEntityService(state: IVolmaModalProps<IEntityDTO, any>, payload: IEntityService<IEntityDTO, any>): IVolmaModalProps<IEntityDTO, any> {
        let dataHelper = payload.GetInitialDataHelper();
        if (dataHelper !== undefined && dataHelper !== null) {
            dataHelper = JSON.parse(JSON.stringify(dataHelper));// otherwise we will change original initial items
        }

        return <IVolmaModalProps<IEntityDTO, any>>{ ...state, EntityService: payload, DataHelper: dataHelper };
    }


    // server interaction reducers
    private RequestBegin(state: IVolmaModalProps<IEntityDTO, any>, payload: IRequestBeginPayload): IVolmaModalProps<IEntityDTO, any> {
        const newState: IVolmaModalProps<IEntityDTO, any> = { ...state };
        newState.IsSending = true;
        newState.RequestsInProgress.push(payload);
        return newState;
    }

    private RequestEnd(state: IVolmaModalProps<IEntityDTO, any>, payload: IRequestEndPayload): IVolmaModalProps<IEntityDTO, any> {
        const newState: IVolmaModalProps<IEntityDTO, any> = { ...state, RequestsInProgress: [] };
        for (let i = 0; i < state.RequestsInProgress.length; i++) {
            let request = state.RequestsInProgress[i];
            if (request.Uuid !== payload.Uuid) {
                newState.RequestsInProgress.push(request);
            }
        }
        newState.IsSending = newState.RequestsInProgress.length > 0;
        return newState;
    }

    private RequestError(state: IVolmaModalProps<IEntityDTO, any>, payload: IRequestErrorPayload): IVolmaModalProps<IEntityDTO, any> {
        let newState: IVolmaModalProps<IEntityDTO, any> = { ...state, ServerError: payload };
        return newState;
    }

    private RequestClearError(state: IVolmaModalProps<IEntityDTO, any>): IVolmaModalProps<IEntityDTO, any> {
        let newState: IVolmaModalProps<IEntityDTO, any> = { ...state, ServerError: undefined };
        return newState;
    }

    private ReduceToggleConfirmMessage(state: IVolmaModalProps<IEntityDTO, any>, payload: IConfirmOnUnsavedChangesPayload): IVolmaModalProps<IEntityDTO, any> {
        const newState: IVolmaModalProps<IEntityDTO, any> = { ...state, ConfirmOnUnsavedChangesShown: payload.ConfirmOnUnsavedChangesShown };
        return newState;
    }
    
    public static Initialize(): (state: IVolmaModalProps<IEntityDTO, any>, action: IActionPayloaded<IVolmaModalProps<IEntityDTO, any>>) => IVolmaModalProps<IEntityDTO, any> {
        const reducer = new VolmaModalReducer();
        return (state: IVolmaModalProps<IEntityDTO, any>, action: IActionPayloaded<IVolmaModalProps<IEntityDTO, any>>) => reducer.Reduce(state, action);
    }
}