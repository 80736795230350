export enum EEntityType {
    BodyType,
    HandlingType,
    LoadingPoint,
    Locality,
    Zone,
    RouteRate,
    ComplaintTemplate,
    File,
    Payload,
    Consignor,
    Configuration,
    CargoTransporter,
    ShipperUser,
    CargoTransporterUser,
    Delivery,
    RouteSegment,
    Complaint,
    Tender,
    Driver,
    Vehicle,
    Shipper,
    User,
    CargoTransporterRouteRate,
    Settings,
    ReportChart,
    RequestForDeliveryState,
    Bidding,
    RequestLog,
    About,
    Report,
    Search,
    Rating,
    TenderBet,
    TenderChangelog,
    TenderWithdraw,
    ComplaintComment,
    CalendarEntry,
    BiddingBet,
    DeliveryState,
    DeliveryConfirm,
    DeliveryManualAssign,
    DeliveryUpdateAssignType,
    DeliveryWithdraw,
    DeliveryOneComment,
    DeliveryMarkAsDelivered,
    DeliveryMarkAsNotExecuted,
    UserRole,
    SelectVehicle,
    SelectDriver,
    UpdatePassword,
    ComplaintAdditionFile,
    ComplaintFile,
    BusinessCalendar,
    RatingDetails,
    ActiveBiddingBets,
    BiddingWinnerBets,
    ActiveTenderBets,
    TenderWinnerBets,
    DeliveryFinished,
    DeliveryFuture,
    DeliveryActive,
    DeliveryPerforming,
    DeliveryWithClaim,
    DeliveryManual,
    DeliveryAuction,
    DeliveryToAcceptConfirm,
    TenderFinished,
    TenderActive,
    TenderBettingPerforming,
    TenderFuture,
    TenderInactive,
    TenderDraft,
    CurrentRating,
    ForecastRating,
    TopCurrentRating,
    ComplaintReportItem,
    DeliverySummaryReportItem,
    RateReportItem,
    RatingReportItem,
    TenderReportItem,
    BaseRateReportItem,
    ReceivedDeliveryReportItem,
    RatingSummaryReportItem,
    CargoTransporterStaff,
    ComplaintDraft,
    ComplaintActive,
    ComplaintWithNewMessages,
    ComplaintClosed,
    DeliveryWaiting,
    DeliveryTransportation,
    DeliveryEnd,
    GeoCoordinate,
    DriverDelivery,
    ForecastRatingDetails,
    TenderRejectedDelivery,
    Contract,
    Consignee,
    DeliveryAttachedFilesReport,
    DriverLocation,
    DriverUserProfile,
    DriverScore,
    DriverMonitoring,
    MultiFactory,
    MultiCargoTransporter,
    DeliveryForComplaint,
    Instruction,
}