import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { ComplaintDTO } from '../../../Domain/DTO/ComplaintDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { Types } from '../../../Infrastructure/Types';
import { EComplaintTemplateDirection } from '../../../Domain/Enum/EComplaintTemplateDirection';
import { injectable } from 'inversify';
import { IComplaintHelper } from './IComplaintHelper';

@injectable()
export class ComplaintHelper implements IComplaintHelper{
    private _authService: AuthenticationService;

    constructor(){
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
    }

    public IsAlice(dto: ComplaintDTO){
        return dto.Direction === EComplaintTemplateDirection.ToCargoTransporter && this._authService.IsShipper() ||
            dto.Direction === EComplaintTemplateDirection.ToShipper && this._authService.IsCargoTransporter();
    }

    public IsBob(dto: ComplaintDTO){
        return dto.Direction === EComplaintTemplateDirection.ToCargoTransporter && this._authService.IsCargoTransporter() ||
            dto.Direction === EComplaintTemplateDirection.ToShipper && this._authService.IsShipper();
    }

    public IsUserAlice(dto: ComplaintDTO, role: EUserRole){
        return dto.Direction === EComplaintTemplateDirection.ToCargoTransporter && this._authService.IsUserShipper(role) ||
            dto.Direction === EComplaintTemplateDirection.ToShipper && this._authService.IsUserCargoTransporter(role);
    }

    public IsUserBob(dto: ComplaintDTO, role: EUserRole){
        return dto.Direction === EComplaintTemplateDirection.ToCargoTransporter && this._authService.IsUserCargoTransporter(role) ||
            dto.Direction === EComplaintTemplateDirection.ToShipper && this._authService.IsUserShipper(role);
    }
}