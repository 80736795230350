import { injectable } from "inversify";
import { CargoTransporterTableDTO } from "../../Domain/DTO/CargoTransporterTableDTO";
import { DeliveryDTO } from "../../Domain/DTO/DeliveryDTO";
import { LoadingPointTableDTO } from "../../Domain/DTO/LoadingPointTableDTO";
import { EEntityType } from "../../Domain/Enum/EEntityType";
import PropertyHelper from "../../Infrastructure/Services/PropertyHelper";
import { IVolmaTableColumnDefinition } from "./VolmaTable/IVolmaTableProps";
import { VolmaTableDeliveryCellRenderers } from "./VolmaTable/Renderers/VolmaTableDeliveryCellRenderers";
import { VolmaTableHeaderRenderers } from "./VolmaTable/Renderers/VolmaTableHeaderRenderers";

export type MultiColumnOptions = {
    DataKey: string;
    Weight?: number;
};

@injectable()
export class VolmaTableCommonColumnDefinition {

    public static GetMultiPointColumn(options: MultiColumnOptions): IVolmaTableColumnDefinition {
        return {
            DataKey: options.DataKey,
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            CellRenderer: VolmaTableDeliveryCellRenderers.IconLoadingPointCellRenderer,
            Weight: options.Weight,
            FilterKey: [
                PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.LoadingPointId),
                PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.MultiFactoryId),
            ],
            Entity: [EEntityType.LoadingPoint, EEntityType.MultiFactory],
            OptionsFilter: [(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined],
            EntityOptionIcon: [
                VolmaTableCommonColumnDefinition.GetSvgIcon("#loading-point"),
                VolmaTableCommonColumnDefinition.GetSvgIcon("#multi-factory"),
            ],
        };        
    }

    public static GetMultiTransporterColumn(options: MultiColumnOptions): IVolmaTableColumnDefinition {
        return {
            DataKey: options.DataKey,
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            Weight: options.Weight,
            FilterKey: [
                PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.CargoTransporterId),
                PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.MultiCargoTransporterId),
            ],
            Entity: [EEntityType.CargoTransporter, EEntityType.MultiCargoTransporter],
            OptionsFilter: [(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined],
            EntityOptionIcon: [
                VolmaTableCommonColumnDefinition.GetSvgIcon("#cargotransporter"),
                VolmaTableCommonColumnDefinition.GetSvgIcon("#multi-cargotransporter"),
            ],
        };
    }

    private static GetSvgIcon(xlinkHref: string) {
        return (
            <svg className="table-cont__ico">
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={xlinkHref}></use>
            </svg>
        );
    }
}
