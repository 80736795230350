import { GIS_COORDINATES_CHANGED, LAT_COORDINATES_CHANGED, LOCALITY_ZONES_LOADED, LON_COORDINATES_CHANGED, VOLMA_ENTITY_DATA_LOADED } from '../../../Constants/AppConstants';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { ZoneTableDTO } from '../../../Domain/DTO/ZoneTableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ILocalityHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import { GeoCoordinateDTO } from '../../../Domain/DTO/GeoCoordinateDTO';
import { LatLng, LatLngExpression, LatLngLiteral } from 'leaflet';



@injectable()
export class LocalityReducer extends EntityReducer<LocalityDTO, ILocalityHelperProps> {

    public Reduce(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<LocalityDTO, ILocalityHelperProps>{
        switch (action.type) {
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case LOCALITY_ZONES_LOADED:
                return this.ReduceZonesLoaded(state, action.Payload);
            case GIS_COORDINATES_CHANGED:
                return this.LoadPointCoordinatesChanged(state, action.Payload);
            case LON_COORDINATES_CHANGED:
                return this.LonCoordinatesChanged(state, action.Payload)
            case LAT_COORDINATES_CHANGED:
                return this.LatCoordinatesChanged(state, action.Payload)
            default:
                return state;
        }
    }

    private ReduceZonesLoaded(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, payload: Array<ZoneTableDTO>): IBaseEntityProps<LocalityDTO, ILocalityHelperProps> {
        return super.CollectionLoaded<ZoneTableDTO>(state, payload, [x => x.AllZones]);
    }

    private ReduceDataLoaded(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, payload: LocalityDTO): IBaseEntityProps<LocalityDTO, ILocalityHelperProps> {
        return super.DataLoaded(state, payload);
    }

    private LoadPointCoordinatesChanged(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, payload: LatLng) :IBaseEntityProps<LocalityDTO, ILocalityHelperProps> {
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                Longitude: payload.lng,
                Latitude: payload.lat
            }
        }
                
        return newState;
    }

    private LatCoordinatesChanged(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, payload: string) :IBaseEntityProps<LocalityDTO, ILocalityHelperProps>{
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                ...newState.DataDTO.GeoCoordinate,
                Latitude: Number.parseFloat(payload)
            }
        }                
        return newState;
    }

    private LonCoordinatesChanged(state: IBaseEntityProps<LocalityDTO, ILocalityHelperProps>, payload: string) :IBaseEntityProps<LocalityDTO, ILocalityHelperProps>{
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                ...newState.DataDTO.GeoCoordinate,
                Longitude: Number.parseFloat(payload)
            }
        }                
        return newState;
    }

}