import { ConfigurationDTO } from '../../../Domain/DTO/ConfigurationDTO';
import { injectable } from 'inversify';
import { ComponentActions } from '../../Shared/ComponentActions';
import { ApiActions } from '../../Shared/ApiActions';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import {
    FORM_SUBMITTED,
    PORTAL_CONFIGURATION_LOADED,
    PORTAL_CONFIGURATION_MOUNTED,
    PORTAL_CONFIGURATION_SAVED,
    PORTAL_CONFIGURATION_UNMOUNTED,
    VOLMA_ENTITY_INFO_LINE_TOGGLED
} from '../../../Constants/AppConstants';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { PortalConfigurationServerInteraction } from '../../../Infrastructure/ServerInteraction/PortalConfigurationServerInteraction';
import { IVolmaTableCellDataGetterParams } from '../../Table/VolmaTable/IVolmaTableProps';
import { AxiosResponse } from 'axios';
import PortalConfiguration from './index';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { TableDTO } from '../../../Domain/DTO/TableDTO';
import { FooterNavLink } from '../../Footer/IFooterProps';
import { VOLMA_ENTITY_FOOTER_NAV_ADDED, VOLMA_ENTITY_CLEAR_FOOTER, PORTAL_CONFIGURATION_CLEAR_FOOTER, VOLMA_ENTITY_DTO_STRINGIFY_UPDATED } from '../../../Constants/AppConstants';

@injectable()
export class PortalConfigurationActions extends ComponentActions {
    private _configurationServerInteraction: PortalConfigurationServerInteraction;
    private _apiActions: ApiActions;
    private _apiTable: TableServerInteraction;

    constructor() {
        super();
        super.Initialize(PORTAL_CONFIGURATION_MOUNTED, PORTAL_CONFIGURATION_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._apiTable = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._configurationServerInteraction = VolmaContainer.get<PortalConfigurationServerInteraction>(Types.PortalConfigurationServerInteraction);
    }

    public LoadPortalConfiguration(waitingText: string){
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._apiTable.GetTableData(EEntityType.Configuration, PropertyHelper.GetPropertyName((x: ConfigurationDTO) => x.Id), ESortDir.Asc, undefined, undefined, undefined),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data: TableDTO = JSON.parse(response.data);
                    if (data.TotalItems > 0)
                        dispatch(this.PortalConfigurationLoaded(data.Items[0] as any));
                    else
                        dispatch(this.PortalConfigurationLoaded({}));
                });
        }
    }

    public SavePortalConfiguration(dto: ConfigurationDTO, waitingText: string, onSuccess: () => void){
        return (dispatch: any) => {
            dispatch(this.Submit());

            this._apiActions.DoApiRequest(
                dispatch,
                () => (dto.Id === undefined) ? this._configurationServerInteraction.CreateConfiguration(dto) : this._configurationServerInteraction.PutConfiguration(dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    dispatch(this.PortalConfigurationSaved());
                    onSuccess();
                });
        }
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    public ClearFooter() : IAction{
        return { type: VOLMA_ENTITY_CLEAR_FOOTER };
    }

    private PortalConfigurationLoaded(item: ConfigurationDTO){
        return { type: PORTAL_CONFIGURATION_LOADED, Payload: item };
    }

    private PortalConfigurationSaved(): IAction{
        return { type: PORTAL_CONFIGURATION_SAVED };
    }

    public UpdateDTOStringify(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_ENTITY_DTO_STRINGIFY_UPDATED, Payload: data };
    }
}