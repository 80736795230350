import {
    REGISTER_FAILED,
    REGISTER_MOUNTED,
    REGISTER_ROLES_LOADED,
    REGISTER_SUCCESSFUL,
    REGISTER_UNMOUNTED
} from '../../Constants/AppConstants';
import { ListIntOptionDTO } from '../../Domain/DTO/ListIntOptionDTO';
import { ERegisterResult } from '../../Domain/Enum/ERegisterResult';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../Shared/VolmaComponentReducer';
import { IRegisterHelperProps } from './IHelperProps';
import { IRegisterProps, RegisterPropsInitial } from './IRegisterProps';
import { IRegisterFailedPayload } from './Payloads';

class RegisterReducer extends VolmaComponentReducer<IRegisterProps> {

    constructor() {
        super(REGISTER_MOUNTED, REGISTER_UNMOUNTED);
    }

    public Reduce(state: IRegisterProps = RegisterPropsInitial, action: IActionPayloaded<any>): IRegisterProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case REGISTER_ROLES_LOADED:
                return this.ReduceRolesLoaded(state, action.Payload);
            case REGISTER_SUCCESSFUL:
                return this.ReduceRegisterSuccessfully(state);
            case REGISTER_FAILED:
                return this.ReduceRegisterFailed(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceRolesLoaded(state: IRegisterProps, payload: Array<ListIntOptionDTO>): IRegisterProps {
        let newState = { ...state, DataHelper: <IRegisterHelperProps>{ ...state.DataHelper, AllRoles: payload } };

        return newState
    }

    private ReduceRegisterSuccessfully(state: IRegisterProps): IRegisterProps {
        return { ...state, DataHelper: <IRegisterHelperProps>{ ...state.DataHelper, RegisterResult: ERegisterResult.Success, Errors: [] } };
    }

    private ReduceRegisterFailed(state: IRegisterProps, payload: IRegisterFailedPayload): IRegisterProps {
        return { ...state, DataHelper: <IRegisterHelperProps>{ ...state.DataHelper, RegisterResult: payload.Result, Errors: payload.Errors } };
    }

    public static Initialize(): (state: IRegisterProps, action: IActionPayloaded<IRegisterProps>) => IRegisterProps {
        const reducer = new RegisterReducer();
        return (state: IRegisterProps, action: IActionPayloaded<IRegisterProps>) => reducer.Reduce(state, action);
    }
}

export { RegisterReducer };