import { RATING_TABLE_FORECAST_LOADED } from '../../../../../Constants/AppConstants';
import { RatingTableDTO } from '../../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../../../Infrastructure/InversifyInject';
import { RatingServerInteraction } from '../../../../../Infrastructure/ServerInteraction/RatingServerInteraction';
import { Types } from '../../../../../Infrastructure/Types';
import { EntityAction } from '../../../BaseEntity/EntityAction';
import i18next from '../../../../i18n';
import { ESortDir } from '../../../../../Domain/Enum/ESortDir';

export class RatingTableActions extends EntityAction{
    private _ratingApi: RatingServerInteraction;

    constructor() {
        super();

        this._ratingApi = VolmaContainer.get<RatingServerInteraction>(Types.RatingServerInteraction);
    }

    public LoadForecastRating() {
        return super.LoadDataTable<RatingTableDTO>(
            EEntityType.ForecastRating,
            x => x.Timestamp,
            ESortDir.Desc,
            undefined,
            i18next.t("common:Loading"),
            this.ForecastRatingLoaded);
    }

    private ForecastRatingLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: RATING_TABLE_FORECAST_LOADED, Payload: value };
    }

}