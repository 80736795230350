import { SettingsDTO } from '../../Domain/DTO/SettingsDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { AxiosPromise } from 'axios';


@injectable()
export class SettingsServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public PutSettings(dto: SettingsDTO): AxiosPromise {
        return this.Put(this._urlFabric.CreateUpdateSettings(ECQRSApi.Write), dto);
    }

    public CreateSettings(): AxiosPromise {
        return this.Post(this._urlFabric.CreateUpdateSettings(ECQRSApi.Write));
    }

    public GetCalendarEntries(fileId: string): AxiosPromise {
        return this.Get(this._urlFabric.GetCalendarEntries(fileId));
    }
}