import { injectable } from 'inversify';
import * as React from 'react';
import { MouseEvent } from 'react';
import { history } from '../../../ConfigureStore';
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { ComplaintCommentDTO } from '../../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintCommentUpdatedDTO } from '../../../Domain/DTO/ComplaintCommentUpdatedDTO';
import { ComplaintDTO } from '../../../Domain/DTO/ComplaintDTO';
import { ComplaintStateDTO } from '../../../Domain/DTO/ComplaintStateDTO';
import { ComplaintTemplateDTO } from '../../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { DeliveryDTO } from '../../../Domain/DTO/DeliveryDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { UpdateComplaintAdditionalFilesDTO } from '../../../Domain/DTO/UpdateComplaintAdditionalFilesDTO';
import { UpdateComplaintFileDTO } from '../../../Domain/DTO/UpdateComplaintFileDTO';
import { EComplaintCommentState } from '../../../Domain/Enum/EComplaintCommentState';
import { EComplaintState } from '../../../Domain/Enum/EComplaintState';
import { EComplaintTemplateDirection } from '../../../Domain/Enum/EComplaintTemplateDirection';
import { EDeliveryState } from '../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { EFilterType } from '../../../Domain/Enum/EFilterType';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { UrlFabric } from '../../../Infrastructure/ServerInteraction/UrlFabric';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { ChangelogService } from '../../../Infrastructure/Services/ChangelogService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { isDefined, isNullOrUndefined } from '../../../Infrastructure/Services/Utils';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { IStatus } from '../../../Infrastructure/Status/IStatus';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NumberRangeHelper } from '../../../Infrastructure/Validation/NumberRangeHelper';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { FileExtendedDTO } from '../../File/FileExtendedDTO';
import i18next from '../../i18n';
import {
    IVolmaTableAction,
    IVolmaTableColumn,
} from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import VolmaFile from '../../VolmaFile';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { DeleteComplaintsAction } from './Action/DeleteComplaintsAction';
import { ComplaintActions } from './ComplaintActions';
import { ComplaintReducer } from './ComplaintReducer';
import { IComplaintHelper } from './IComplaintHelper';
import { ComplaintHelperProps, InitialComplaintHelperProps } from './IHelperProps';
import { Billed } from './Statuses/Billed';
import { Cancelled } from './Statuses/Cancelled';
import { Closed } from './Statuses/Closed';
import { DecisionAccepted } from './Statuses/DecisionAccepted';
import { HarmonizationOfConditions } from './Statuses/HarmonizationOfConditions';
import { Processed } from './Statuses/Processed';
import { Rejected } from './Statuses/Rejected';
import { Satisfied } from './Statuses/Satisfied';
import { Saved } from './Statuses/Saved';
import { TakenIntoExamination } from './Statuses/TakenIntoExamination';
import { ComplaintColumnTemplates } from './Subsidiaries/ComplaintColumnTemplates';


@injectable()
export class ComplaintEntityService extends BaseEntityService<ComplaintDTO, ComplaintHelperProps>{

    protected _volmaTableService: VolmaTableService;
    protected _helper: IComplaintHelper;
    protected _complaintColumnTemplates: ComplaintColumnTemplates;

    protected _dto: ComplaintDTO;

    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _changelogService: ChangelogService;
    private _reducer: ComplaintReducer;
    private _action: ComplaintActions;
    private _volmaModalAction: VolmaModalActions;
    private _dispatch: any;
    private _urlFabric: UrlFabric;
    private _routerService: RouterService;

    private _id: string;
    private _state: EComplaintState;
    private _defaultDeliveryId: string;

    private _commentsCache: Array<ComplaintCommentDTO>;
    private _commentsCacheLayout: Array<JSX.Element>;
    private _chatWrapper: any;
    private _chatPostponeReloadTimeout: any;
    private _chatReloadDebounce: number = 400;

    private _correspondance        = volmaBlock("correspondence");
    private _correspondanceUser    = volmaBlock("correspondence-user");
    private _correspondanceMessage = volmaBlock("correspondence-message");
    private _correspondanceForm    = volmaBlock("correspondence-form");

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._changelogService = VolmaContainer.get<ChangelogService>(Types.ChangelogService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this._helper = VolmaContainer.get<IComplaintHelper>(Types.ComplaintHelper);
        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);

        this._reducer = VolmaContainer.get<ComplaintReducer>(Types.ComplaintReducer);
        this._action = VolmaContainer.get<ComplaintActions>(Types.ComplaintActions);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

        this._complaintColumnTemplates = new ComplaintColumnTemplates();

        this.OnComplaintTemplateChanged  = this.OnComplaintTemplateChanged.bind(this);
        this.MessageValueChanged         = this.MessageValueChanged.bind(this);
        this.ReloadChat                  = this.ReloadChat.bind(this);
        this.ReloadEntity                = this.ReloadEntity.bind(this);
        this.ReloadAdditionalFiles       = this.ReloadAdditionalFiles.bind(this);
        this.ComplaintCommentUpdated     = this.ComplaintCommentUpdated.bind(this);
        this.ComplaintUpdated            = this.ComplaintUpdated.bind(this);
        this.OnAfterDataLoaded           = this.OnAfterDataLoaded.bind(this);
        this.StartAcceptedPaymentEditing = this.StartAcceptedPaymentEditing.bind(this);
        this.EndAcceptedPaymentEditing   = this.EndAcceptedPaymentEditing.bind(this);
        this.UpdateComplaintFile         = this.UpdateComplaintFile.bind(this);
    }

    private ComplaintUpdated(dto: ComplaintDTO) {
        console.log('Complaint comment added', JSON.stringify(dto));
        if (dto !== undefined && this._id !== undefined) {
            if (dto.Id === this._id) {
                console.log('current complaint was updated');
                this.ReloadEntity();
            }
        }
    }

    private ComplaintCommentUpdated(dto: ComplaintCommentUpdatedDTO) {
        console.log('Complaint comment updated', JSON.stringify(dto));
        if (dto !== undefined && this._id !== undefined) {
            if (dto.ComplaintId === this._id) {
                console.log('current complaint was updated');
                this.ReloadChat();
            }
        }
    }

    public GetEntityId(props: IBaseEntityProps<ComplaintDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: ComplaintDTO) => x.RegistrationCode),
            (x: ComplaintDTO, id: string) => x.RegistrationCode.toLowerCase() === id);
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<ComplaintHelperProps>> {
        if (this.GetTableCanAddEntity(this._entity))
            return [new DeleteComplaintsAction()];
        return [];
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.ComplaintDraft, EEntityType.ComplaintActive, EEntityType.ComplaintWithNewMessages, EEntityType.ComplaintClosed]
    }


    public GetStatusList(): Array<IStatus<ComplaintDTO, ComplaintHelperProps>> {
        return [new Saved(), new Billed(), new TakenIntoExamination(), new Rejected(), new Processed(), new HarmonizationOfConditions(), new DecisionAccepted(), new Satisfied(), new Closed(), new Cancelled()]
    }

    public IsEditable(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): boolean {
        return this._authorizationService.IsEditable(this._entity) &&
        (this._dto === undefined || this._id === undefined || this._state === EComplaintState.Saved);
    }


    public GetColumnsList(): Array<IVolmaTableColumn> {
        const areAcceptableRoles = this._authService.IsShipper() || this._authService.IsIncludedCargoTransporter();

        const commonTemplates = [
            { ...this._complaintColumnTemplates.Id },
            { ...this._complaintColumnTemplates.RegistrationCode },
            { ...this._complaintColumnTemplates.TemplateName },
            { ...this._complaintColumnTemplates.ComplaintState },
            { ...this._complaintColumnTemplates.DateOfCreation },
            { ...this._complaintColumnTemplates.AcceptedPayment },
        ];

        if (areAcceptableRoles) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.Complaint,
                [
                    ...commonTemplates,
                    { ...this._complaintColumnTemplates.CargoTransporterName },
                    { ...this._complaintColumnTemplates.DateOfLastUpdate },
                ]
            );
        }

        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Complaint,
            [
                ...commonTemplates,
                { ...this._complaintColumnTemplates.DateOfLastUpdate },
            ]
        );
    }

    public GetHeaderEditor(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div className={(this._createCard("cell")).toString()}>
                <VolmaInput
                    {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.RegistrationCode) }
                    Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.RegistrationCode)}
                    Label={i18next.t('complaint:RegistrationCode')}
                    Placeholder={i18next.t('complaint:RegistrationCodePlaceholder')}
                    Value={props.DataDTO.RegistrationCode}
                    Validator={this._inputValidator}
                    Required={true}
                    IsInHeader={true}
                    dispatch={props.dispatch} />
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        if (props.DataDTO === undefined || props.DataDTO.Id === undefined || this._state === EComplaintState.Saved)
            return this.GetNewComplaintEditor(props)
        return this.GetExistingComplaintEditor(props)
    }

    public GetEditor(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div>
                {this.GetEditorModal(props)}
            </div>
        );
    }

    public InitializeEntity(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>) {
        this._dispatch = props.dispatch;
        this._dto = undefined;
        this._id = undefined;
        this._state = undefined;
        this._defaultDeliveryId = PropertyHelper.SafeGetValue2(props.match, x => x.params, x => x.delivery)
    }

    public InitializeDefaultDTOValues(dto: ComplaintDTO){
        if (this._defaultDeliveryId !== undefined) {
            dto.DeliveriesIdentifiers =  [];
            dto.DeliveriesIdentifiers.push(this._defaultDeliveryId);
            this._dispatch(this._action.LoadCargoTransportersForDelivery(this._defaultDeliveryId));
        }
        if(this._authService.IsCargoTransporter() && !this.IsValueNotEmpty(dto.CargoTransporterId)){
            dto.CargoTransporterId = this._authService.GetCurrentUser().CargoTransporterId;
        }
    }

    public DisposeEntity() {
        this._signalRService.ComplaintUpdated.Off(this.ComplaintUpdated);
        this._signalRService.ComplaintCommentUpdated.Off(this.ComplaintCommentUpdated);
    }

    public GetInitialDataHelper() {
        return InitialComplaintHelperProps;
    }

    public GetReducer() {
        return this._reducer;
    }

    public OnAfterDataLoaded(dto: ComplaintDTO): void {
        this._dto = dto;
        this._id = dto.Id;
        this._state = dto.ComplaintState;
        if (PropertyHelper.IsObject(this._dto) && this._dto.ComplaintState !== EComplaintState.Saved) {
            this._signalRService.ComplaintUpdated.Off(this.ComplaintUpdated);
            this._signalRService.ComplaintCommentUpdated.Off(this.ComplaintCommentUpdated);
            this._signalRService.ComplaintUpdated.On(this.ComplaintUpdated);
            this._signalRService.ComplaintCommentUpdated.On(this.ComplaintCommentUpdated);
        }

        if (isDefined(this._id)) {
            this._dispatch(this._action.LoadAdditionFiles(dto.Id, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadComplaintComments(dto.Id, i18next.t("common:Loading"), this._dto));
            this._dispatch(this._action.LoadComplaintTemplate(dto.ComplaintTemplateId, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadComplaintFile(dto.ComplaintFileId, i18next.t("common:Loading")));
            if(this.IsValueNotEmpty(dto.ShipperAuthorUserId))
                this._dispatch(this._action.LoadShipperUser(dto.ShipperAuthorUserId, i18next.t("common:Loading")));
            else if (this.IsValueNotEmpty(dto.CargoTransporterAuthorUserId))
                this._dispatch(this._action.LoadCargoTransporterUser(dto.CargoTransporterAuthorUserId, i18next.t("common:Loading")));
            this._dispatch(this._action.LoadCargoTransporter(dto.CargoTransporterId, i18next.t("common:Loading")));
            this._dispatch(this._action.SetShowDummySelectPropertyValue(false));
        }

        if (this._dto.ComplaintState === EComplaintState.Billed && this._helper.IsBob(dto)){
            let state = new ComplaintStateDTO();
            state.ComplaintId = this._id;
            state.State = EComplaintState.TakenIntoExamination;
            this._dispatch(this._action.ChangeState(this._id, state, () => {
                setTimeout(() => {
                    this.ReloadEntity()
                }, 0);
            }));
        }
    }

    private OnComplaintTemplateChanged(value: ComplaintTemplateTableDTO){
        this._dispatch(this._action.ComplaintTemplateUpdated(value));
    }
    private OnAddAdditionalFileClicked(isReadonlyMode: boolean) {
        this._dispatch(this._volmaModalAction.OpenFileUploadModal(i18next.t("complaint:AdditionalFile"), (fileDTO: FileExtendedDTO) => {
            if (isReadonlyMode){
                let dto = new UpdateComplaintAdditionalFilesDTO();
                dto.ComplaintId = this._id;
                dto.FilesIds = this._dto.AdditionalFilesIds !== undefined ? this._dto.AdditionalFilesIds.slice(0) : [];
                dto.FilesIds.push(fileDTO.FileId);
                this._dispatch(this._action.UpdateAdditionalFiles(this._id, dto, this.ReloadAdditionalFiles));
            }
            else
                this._dispatch(this._action.AdditionFileAdded(fileDTO.FileDTO));
        }))
    }

    private SendMessage(e: MouseEvent<HTMLButtonElement>, props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>){
        e.preventDefault();
        e.stopPropagation();
        let comment = new ComplaintCommentDTO();
        if (this.IsValueNotEmpty(this._id) && this.IsValueNotEmpty(props.DataHelper.Message)){
            comment.ComplaintId = this._id;
            comment.Comment = props.DataHelper.Message;
            comment.CommentState = EComplaintCommentState.Unreded;
            this._dispatch(this._action.CommentAdded(comment, this.ReloadChat))
        }
    }

    private StartAcceptedPaymentEditing(value: number){
        this._dispatch(this._action.StartAcceptedPaymentEditing(value));
    }

    private EndAcceptedPaymentEditing(saveChanges: boolean, newValue: number){
        this._dispatch(this._action.EndAcceptedPaymentEditing(!saveChanges, newValue, this._id));
    }

    private UpdateComplaintFile(){
        this._dispatch(this._volmaModalAction.OpenFileUploadModal(i18next.t("complaint:ComplaintFile"), (fileDTO: FileExtendedDTO) => {
            let dto = new UpdateComplaintFileDTO();
            dto.ComplaintId = this._id;
            dto.FilesId = fileDTO.FileId;
            this._dispatch(this._action.UpdateComplaintFile(this._id, dto, this.ReloadEntity));
        }))
    }

    private GetNewComplaintEditor(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("complaint:MainInfoTitle"), i18next.t("complaint:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.EditableFilesPart(props), 0)}
                        {this.GetMainInfoNoTitleItem(true, false, this.DescriptionPart(props), 1)}
                    </div>
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("complaint:MoneyTitle"), i18next.t("complaint:MoneyTitleRequestedDescription"), this.RequestedPaymentPart(props), undefined, props, this._dispatch)}
                </div>
            </div>
        )
    }


    private GetExistingComplaintEditor(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let components = new Array<JSX.Element>();

        let index = 0;
        let isDark = true;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("complaint:MainInfoTitle"), i18next.t("complaint:MainInfoReadonlyTitleDescription"), this.ReadonlyMainInfoPart(props), undefined, props, this._dispatch)}
            </div>
        );

        let isFinishing = this.IsComplaintInFinishingState();
        if (this._helper.IsAlice(this._dto) && !isFinishing){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("complaint:MoneyTitle"), i18next.t("complaint:MoneyTitleAcceptedDescription"), this.AcceptedPaymentPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        index++;
        isDark = !isDark;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("complaint:FilesTitle"), i18next.t("complaint:FilesTitleDescription"), this.ReadonlyFilesPart(props), undefined, props, this._dispatch)}
            </div>
        );

        index++;
        isDark = !isDark;
        components.push(
            <div key={index} className={this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""]).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("complaint:ChatTitle"), i18next.t("complaint:ChatTitleDescription"), this.ChatPart(props), undefined, props, this._dispatch)}
            </div>
        );
        return (
            <div className={(this._mainInfo()).toString()}>
                {this.GetStatusPart(props)}
                {components}
            </div>
        )
    }

    private GetStatusPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {


        return this.GetStatusLine(
            this._id,
            this._dto,
            props.DataHelper,
            EEntityType.Complaint,
            this._dispatch,
            this._action,
            props.OpenedStatusIndex,
            this._authService,
            () => {
                setTimeout(() => {
                    this.ReloadEntity()
                }, 0);
            },
            undefined)
    }

    private MainInfoPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {

        const deliveryFilter = new Array<FilterDTO>();
        const stateFilter = new FilterDTO();
        stateFilter.Key = 'DeliveryState';
        stateFilter.Type = EFilterType.Select;
        stateFilter.SelectSelectedValues = new Array<ITableDTO>();

        const identifierFilter = new FilterDTO();
        identifierFilter.Key = PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.Identifier);
        identifierFilter.Type = EFilterType.Text;
        identifierFilter.TextValue = '';

        const cargoTransporterFilter = new FilterDTO();
        cargoTransporterFilter.Key = 'AssignedCargoTransporters';
        cargoTransporterFilter.Type = EFilterType.Text;
        cargoTransporterFilter.TextValue = '';

        const multiCargoTransporterFilter = new FilterDTO();
        multiCargoTransporterFilter.Key = 'AssignedMultiCargoTransporters';
        multiCargoTransporterFilter.Type = EFilterType.Text;
        multiCargoTransporterFilter.TextValue = '';

        deliveryFilter.push(identifierFilter);
        deliveryFilter.push(cargoTransporterFilter);
        deliveryFilter.push(multiCargoTransporterFilter);

        return (
            <div className={(this._mainInfo("row")).toString()}>
                {this._authService.IsShipper() && <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.CargoTransporterId)}
                            Label={i18next.t('complaint:CargoTransporterId')}
                            Placeholder={i18next.t('complaint:SelectCargoTransporter')}
                            LabelKey={PropertyHelper.GetPropertyName((x: CargoTransporterTableDTO) => x.Name)}
                            ValueKey={PropertyHelper.GetPropertyName((x: CargoTransporterTableDTO) => x.Id)}
                            Entity={EEntityType.CargoTransporter}
                            Required={true}
                            Value={props.DataDTO.CargoTransporterId}
                            dispatch={props.dispatch}
                            OnValueChanged={(value: CargoTransporterTableDTO) =>
                                                {
                                                    this._dispatch(this._action.ClearCargoTransporterValue());
                                                    this._dispatch(this._action.SetShowDummySelectPropertyValue(true));
                                                    if(!isNullOrUndefined(value)){
                                                        setTimeout(() => {
                                                                this._dispatch(this._action.SetShowDummySelectPropertyValue(false));
                                                        }, 1);
                                                    }
                                                }
                                            }
                           />
                    </div>
                </div>}
                {this.GetDeliverySelect(props.DataHelper.ShowDummyDeliverySelect, identifierFilter, deliveryFilter, cargoTransporterFilter, multiCargoTransporterFilter, props)}
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintDTO) => val.ComplaintState) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.ComplaintState)}
                            Label={i18next.t('complaint:ComplaintState')}
                            Placeholder={i18next.t('complaint:SelectComplaintState')}
                            EnumGetter={() => EComplaintState}
                            OptionsFilter={(x: ListIntOptionDTO) => x.Id === EComplaintState.Saved || x.Id === EComplaintState.Billed }
                            Required={true}
                            Value={props.DataDTO.ComplaintState}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        )
    }

    private GetDeliverySelect(showDummyDeliverySelect: boolean, identifierFilter: FilterDTO, deliveryFilter: FilterDTO[], cargoTransporterFilter: FilterDTO,
        multiCargoTransporterFilter: FilterDTO, props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element
    {
        if(showDummyDeliverySelect) {
            return (
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                         <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintDTO) => val.DeliveriesId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.DeliveriesId)}
                            Label={i18next.t('complaint:DeliveryId')}
                            Placeholder={i18next.t('complaint:SelectDelivery')}
                            LabelKey={PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Identifier)}
                            ValueKey={PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Id)}
                            Required={true}
                            Options={[]}
                            dispatch={props.dispatch}
                            AllowMultiselect={true}
                            Value={props.DataDTO.DeliveriesId}
                            />
                    </div>
                </div>);

        }
        else{
            return (
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintDTO) => val.DeliveriesId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.DeliveriesId)}
                            Label={i18next.t('complaint:DeliveryId')}
                            Placeholder={i18next.t('complaint:SelectDelivery')}
                            LabelKey={PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Identifier)}
                            ValueKey={PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Id)}
                            Entity={EEntityType.DeliveryForComplaint}
                            ServerRequestFilter={(value: string) => {
                                identifierFilter.TextValue = value;
                                cargoTransporterFilter.TextValue = props.DataDTO.CargoTransporterId;
                                multiCargoTransporterFilter.TextValue = props.DataDTO.CargoTransporterId;
                                return deliveryFilter;
                            }}
                            ServerRequestLimit={NumberRangeHelper.maxAvailableValue()}
                            Required={true}
                            Value={props.DataDTO.DeliveriesId}
                            dispatch={props.dispatch}
                            AllowMultiselect={true}
                            Cache={props.DataHelper.DeliveriesCache}
                            />
                    </div>
                </div>);
        }
    }

    private ReadonlyMainInfoPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let isBob = this._helper.IsBob(this._dto);
        let isFinishing = this.IsComplaintInFinishingState();
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:DateOfCreation")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.DateOfCreation))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:ComplaintTemplateId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedComplaintTemplate, x => x.Name))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:Транспортировки")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetDeliveryIdentifiersWithLinks(props, props.DataDTO.DeliveriesIdentifiers)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:AuthorUserId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Author, x => x.Fio))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:CargoTransporterId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.CargoTransporter, x => x.Name))}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:RequestPayment")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>
                                    <svg className="rub"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use></svg>
                                    {this.GetSafeMoney(PropertyHelper.SafeGetValue(props.DataDTO, x => x.RequestPayment))}</dd>
                            </div>
                            {isBob || isFinishing && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("complaint:AcceptedPayment")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>
                                    <svg className="rub"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use></svg>
                                    {this.GetSafeMoney(PropertyHelper.SafeGetValue(props.DataDTO, x => x.AcceptedPayment))}</dd>
                            </div>}
                        </dl>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoDescription).toString()}>
                        <p className={(this._mainInfoDescription("text")).toString()}>
                            <span className={(this._mainInfoDescription("title")).toString()}>{i18next.t("complaint:Description")}:{" "}</span>
                            {PropertyHelper.SafeGetValue(props.DataDTO, x => x.Description)}</p>
                    </div>
                </div>
            </div>
        )
    }

    private GetDeliveryIdentifiersWithLinks(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>, identifiers: string[]) : JSX.Element
    {
        if(isNullOrUndefined(identifiers) ||  identifiers.length === 0)
            return i18next.t("common:NoData") as any;
         let bufer = []
        identifiers.forEach((element, index) => {
            if(index !== 0)
            {
                bufer.push(", ")
            }
            bufer.push(<a className={VolmaTableCommonCellRenderers._tableCont("text", { link: true }).toString()}
            onClick={() => history.push({ pathname: this._routerService.GetCertainEntityPathname(EEntityType.Delivery, element) })}>{element}</a>);
        });
        return (
            <div>
                {bufer}
            </div>
        );

    }

    private EditableFilesPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        let isdownloadTemplateDisabled = !this.IsValueNotEmpty(props.DataHelper.SelectedComplaintTemplate);
        let isCargoTransporter = this._authService.IsCargoTransporter();
        let isShipper = this._authService.IsShipper();
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { indent: true, full: true, wrap: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true, "no-right-padding": true, "no-error-space": true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintDTO) => val.ComplaintTemplateId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.ComplaintTemplateId)}
                            Label={i18next.t('complaint:ComplaintTemplateId')}
                            Placeholder={i18next.t('complaint:SelectComplaintTemplate')}
                            Entity={EEntityType.ComplaintTemplate}
                            OptionsFilter={(x: ComplaintTemplateDTO) => (x.Direction.find(x => x === EComplaintTemplateDirection.ToShipper) !== undefined && isCargoTransporter) ||
                                x.Direction.find(x => x === EComplaintTemplateDirection.ToCargoTransporter) !== undefined && isShipper}
                            Required={false}
                            Readonly={!isEditable}
                            Value={props.DataDTO.ComplaintTemplateId}
                            OnValueChanged={this.OnComplaintTemplateChanged}
                            dispatch={props.dispatch} />
                    </div>
                    {!isdownloadTemplateDisabled && <div
                    className={this._mainInfo("line", { flex50: true, "no-right-padding": true, full: true }).mix([isdownloadTemplateDisabled ? "disabled" : ""]).toString()}>
                        <div className={(this._volmaInput).toString()}>
                            <span className={this._volmaInput("input", { text: true }).mix("input").toString()}>{i18next.t("complaint:DownloadComplaintTemplate")}</span>
                        </div>
                    </div>}
                    {!isdownloadTemplateDisabled && <div className={this._mainInfo("line", { flex50: true, "no-right-padding":true, full: true }).toString()}>
                        <div className={(this._volmaFile).toString()}>
                            <a href={this._urlFabric.FileDownload(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedComplaintTemplate, x => x.TemplateFileId))}
                                className={this._volmaFile("input", { clear: true, "clear-no-border": true }).mix(["input", isdownloadTemplateDisabled ? "disabled": ""]).toString()}>
                                <div className={(this._volmaFile("clear")).toString()}>
                                    <svg className={this._volmaFile("clear-ico").toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                    </svg>
                                    <span className={this._volmaFile("clear-text").toString()}>{i18next.t("complaint:DownloadTemplate")}</span>
                                </div>
                            </a>
                        </div>
                    </div>}
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaFile
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.ComplaintFileId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.ComplaintFileId)}
                            Label={i18next.t('complaint:ComplaintFile')}
                            Value={props.DataDTO.ComplaintFileId}
                            Required={true}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        {this.GetAdditionalFilesCoponent(props)}
                    </div>
                </div>
            </div>
        );
    }

    private ReadonlyFilesPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        let isdownloadTemplateDisabled = !this.IsValueNotEmpty(props.DataHelper.SelectedComplaintTemplate);
        let isAlice = this._helper.IsAlice(this._dto);
        let isFinishing = this.IsComplaintInFinishingState();
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { indent: true, full: true, wrap: true }).toString()}>
                    <div className={this._mainInfo("line", { flex50: true, "no-right-padding": true, full: true }).mix([isdownloadTemplateDisabled ? "disabled" : ""]).toString()}>
                        <div className={(this._volmaInput).toString()}>
                            <span className={this._volmaInput("input", { text: true }).mix("input").toString()}>{i18next.t("complaint:ComplaintFile")}</span>
                        </div>
                    </div>
                    <div className={this._mainInfo("line", { flex50: true, "no-right-padding": true, full: true }).toString()}>
                        <div className={this._volmaFile({flex: true}).toString()}>
                            <a href={this._urlFabric.FileDownload(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.ComplaintFile, x => x.Id))}
                            className={this._volmaFile("input", { clear: true, "clear-no-border": true }).mix(["input", isdownloadTemplateDisabled ? "disabled" : ""]).toString()}>
                                <div className={(this._volmaFile("clear")).toString()}>
                                    <svg className={this._volmaFile("clear-ico").toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                    </svg>
                                    <span className={this._volmaFile("clear-text").toString()}>{i18next.t("complaint:DownloadComplaintFile")}</span>
                                </div>
                            </a>
                            {isAlice && !isFinishing && <a onClick={this.UpdateComplaintFile} className={this._btn.mix([this._btnEdit, this._volmaFile("edit")]).toString()}>
                                <svg className={(this._btnEdit("ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#edit"></use>
                                </svg>
                            </a>}
                        </div>
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        {this.GetAdditionalFilesCoponent(props)}
                    </div>
                </div>
            </div>
        );
    }


    private AcceptedPaymentPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        {!props.DataHelper.IsAcceptedPaymentEditing ? <div className={this._volmaInput({ edit: true, "no-padding": true}).toString()}>
                            <span className={this._volmaInput("input", { text: true }).mix(["input"]).toString()}>{i18next.t('complaint:AcceptedPaymentReadonly', {data: this.GetSafeMoney(props.DataDTO.AcceptedPayment)} as any)}</span>
                            <a onClick={() => this.StartAcceptedPaymentEditing(props.DataDTO.AcceptedPayment)} className={this._volmaInput("edit").mix([this._btnEdit({static: true}), this._btn()]).toString()}>
                                <svg className={(this._btnEdit("ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#edit"></use>
                                </svg>
                            </a>
                        </div>
                        : <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.AcceptedPayment) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.AcceptedPayment)}
                            Label={i18next.t('complaint:AcceptedPayment')}
                            Placeholder={i18next.t('complaint:AcceptedPayment')}
                            Value={props.DataDTO.AcceptedPayment}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={100}
                            Unit={i18next.t('common:rub')}
                            Autofocus={true}
                            HideUpDownBtns={true}
                            OnLostFocus={(cancelled: boolean) => {
                                    this.EndAcceptedPaymentEditing(!cancelled && PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.AcceptedPayment).IsValid,
                                    props.DataDTO.AcceptedPayment);
                            }}
                            dispatch={props.dispatch} />}
                    </div>
                </div>
            </div>
        );
    }

    private RequestedPaymentPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.RequestPayment) }
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.RequestPayment)}
                            Label={i18next.t('complaint:RequestPayment')}
                            Placeholder={i18next.t('complaint:RequestPayment')}
                            Value={props.DataDTO.RequestPayment}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={100}
                            Unit={i18next.t('common:rub')}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private DescriptionPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { full: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintDTO) => val.Description) }
                            Type="textarea"
                            Name={PropertyHelper.GetPropertyName((val: ComplaintDTO) => val.Description)}
                            Label={i18next.t('complaint:Description')}
                            Value={props.DataDTO.Description}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ChatPart(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let msgs = [];

        let index = 0;
        let isCargoTransporter = this._authService.IsCargoTransporter();
        if (props.DataHelper != undefined && props.DataHelper.Comments != undefined && props.DataHelper.Comments !== this._commentsCache) {
            for (let msg of props.DataHelper.Comments) {

                let isLeft = (this._helper.IsUserBob(this._dto, msg.AuthorRole) && this._helper.IsAlice(this._dto)) ||
                    (this._helper.IsUserAlice(this._dto, msg.AuthorRole) && this._helper.IsBob(this._dto));
                let isNew = msg.CommentState === EComplaintCommentState.Unreded;

                let changelog = this._changelogService.GetChangelog(EEntityType.Complaint, msg.OldValues, msg.NewValues, [{
                    isApplicable: (parentFields: Array<string>) => parentFields.length > 0 && parentFields[parentFields.length - 1] === PropertyHelper.GetPropertyName((x: ComplaintDTO) => x.ComplaintState),
                    localizer: (fieldNam, fieldValue) => this._enumService.GetOptionStringLocalized(() => EComplaintState, fieldValue)}
                ]);

                msgs.push(
                    <div key={index} className={this._correspondance("item", { left: isLeft, right: !isLeft, aqua: isNew, blue: !isNew && !isLeft, gray: !isNew && isLeft }).toString()}>
                        <div className={this._correspondance("item-left").toString()}>
                            <div className={(this._correspondanceUser).toString()}>
                                <svg className={this._correspondanceUser("ico").mix(["correspondence-user-ico"]).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#user"></use>
                                </svg>
                                <svg className={this._correspondanceUser("ico-bg").mix(["correspondence-user-ico-bg"]).toString()}>

                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#user"></use>
                                </svg>
                            </div>
                        </div>
                        <div className={this._correspondance("item-right").toString()}>
                            <div className={(this._correspondanceMessage).toString()}>
                                <div className={(this._correspondanceMessage("cont")).toString()}>
                                    <div className={(this._correspondanceMessage("top")).toString()}>
                                        <div className={(this._correspondanceMessage("title")).toString()}>
                                            {!isNew && <svg className={this._correspondanceMessage("title-ico").mix(["title-message-check-ico"]).toString()}>
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#message-check"></use>
                                            </svg>}
                                            {isNew && <svg className={this._correspondanceMessage("title-ico").mix(["title-airplane-ico"]).toString()}>
                                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#airplane"></use>
                                            </svg>}
                                            <span className={this._correspondanceMessage("title-text").toString()}><b>{msg.UserFio}</b></span>
                                        </div>
                                    </div>
                                    <div className={(this._correspondanceMessage("main")).toString()}>
                                        {this.IsValueNotEmpty(changelog) && <p className={this._correspondanceMessage("main-text").toString()} dangerouslySetInnerHTML={{ __html: changelog }}></p>}
                                        <p className={this._correspondanceMessage("main-text").toString()}>{msg.Comment}</p>
                                    </div>
                                </div>

                                    {!isNew && <div className={(this._correspondanceMessage("info")).toString()}>
                                        <svg className={this._correspondanceMessage("info-ico").mix(["inform-message-check-ico"]).toString()}>
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#message-check"></use>
                                        </svg>
                                        <span className={this._correspondanceMessage("info-text").toString()}>{i18next.t("complaint:MessageReaded")}</span>
                                    </div>}
                                    {isNew && <div className={(this._correspondanceMessage("info")).toString()}>
                                        <svg className={this._correspondanceMessage("info-ico").mix(["inform-airplane-ico"]).toString()}>
                                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#airplane"></use>
                                        </svg>
                                        <span className={this._correspondanceMessage("info-text").toString()}>{i18next.t("complaint:MessageNew")}</span>
                                    </div>}
                                </div>
                                <span className={this._correspondance("item-date").toString()}>{i18next.t("complaint:MessageSent", {
                                    date: this.GetSafeDate(msg.Timestamp),
                                    time: this.GetSafeTime(msg.Timestamp)
                                } as any)}</span>
                            </div>
                        </div>
                )
                index++;
            }
        }

        if (props.DataHelper.Comments === this._commentsCache){
            msgs = this._commentsCacheLayout || [];
        }
        else{
            this._commentsCache = props.DataHelper.Comments;
            this._commentsCacheLayout = msgs;
            setTimeout(() => {
                this.ScrollChatToBottom();
            }, 0);
        }

        return (
            <div className={(this._correspondance).toString()}>
                <div className={(this._correspondance("wrap")).toString()} ref={(el) => this._chatWrapper = el}>
                    <div className={(this._correspondance("box")).toString()}>
                        {msgs.length === 0 && <div className={(this._correspondance("title")).toString()}>
                            <span className={this._correspondance("title-text").toString()}>{i18next.t("complaint:NoMessages")}</span>
                        </div>}
                        <div className={(this._correspondance("content")).toString()}>
                            {msgs}
                        </div>
                    </div>
                </div>
                <div className={(this._correspondanceForm).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintHelperProps) => val.Message) }
                        Name={PropertyHelper.GetPropertyName((val: ComplaintHelperProps) => val.Message)}
                        Label={i18next.t('complaint:WriteNewMessage')}
                        Type="textarea"
                        Value={props.DataHelper.Message}
                        OnValueChange={this.MessageValueChanged}
                        Validator={this._inputValidator}
                        ButtonClass="correspondence-form__submit"
                        ButtonIcoClass="btn-airplane-ico"
                        ButtonSvg="#airplane"
                        OnButtonClicked={(e) => this.SendMessage(e, props)}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    private ScrollChatToBottom(){
        if (this._chatWrapper !== null && this._chatWrapper !== undefined){
            let scrollHeight = this._chatWrapper.scrollTop;
            let finalPosition = this._chatWrapper.scrollHeight;
            let scrollInterval = 20;
            let timeToPerformScroll = 600;//ms
            let delta = Math.max(5, Math.abs(finalPosition - this._chatWrapper.scrollTop) * scrollInterval / timeToPerformScroll);
            let id = setInterval(() => {
                if (this._chatWrapper !== null && this._chatWrapper !== undefined) {
                    let initTopScroll = this._chatWrapper.scrollTop;
                    if (this._chatWrapper.scrollTop > finalPosition) {
                        this._chatWrapper.scrollTop = Math.abs(this._chatWrapper.scrollTop - delta);
                    }
                    else {
                        this._chatWrapper.scrollTop = Math.abs(this._chatWrapper.scrollTop + delta);
                    }

                    if (Math.abs(this._chatWrapper.scrollTop - finalPosition) <= delta || initTopScroll === this._chatWrapper.scrollTop) {
                        this._chatWrapper.scrollTop = finalPosition;
                        clearInterval(id);
                    }
                }
                else{
                    clearInterval(id);
                }
            }, scrollInterval);

        }
    }

    private GetAdditionalFilesCoponent(props: IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        let files = [];
        if (props.DataHelper != undefined && props.DataHelper.AdditionalFiles !== undefined) {
            for(let i = 0; i < props.DataHelper.AdditionalFiles.length; i++){
                let file = props.DataHelper.AdditionalFiles[i];
                let isLast = i === (props.DataHelper.AdditionalFiles.length - 1);

                files.push(<div key={i} className={this._volmaMultifile("row", { blue: true, border: !isLast}).toString()}>
                    <div className={this._volmaMultifile("row-left").toString()}>
                        <svg className={this._volmaMultifile("row-left-ico").mix(["w-text-ico"]).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#w-text"></use>
                        </svg>
                    </div>
                    <div className={this._volmaMultifile("row-center").toString()}>
                        <span className={(this._volmaMultifile("name")).toString()}>{file.Name}</span>
                        <span className={(this._volmaMultifile("info")).toString()}>{i18next.t("delivery:FileLoaded", { timestamp: this.GetSafeDateTime(file.Timestamp), user: this.GetSafeString(file.UserFio)} as any)}</span>
                    </div>
                    <div className={this._volmaMultifile("row-right").toString()}>
                        <a href={this._urlFabric.FileDownload(file.Id)}>
                            <svg className={this._volmaMultifile("row-right-ico").mix(["file-upload-ico"]).toString()}>
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                            </svg>
                        </a>
                    </div>
                </div>
                )
            }
        }

        let noFiles = (
            <div className={(this._volmaMultifile("row")).toString()}>
                <div className={this._volmaMultifile("row-full").toString()}>
                    <span className={(this._volmaMultifile("name")).toString()}>{i18next.t("complaint:NoAdditionalFiles")}</span>
                </div>
            </div>
        );

        return (
            <div className={this._volmaMultifile({"bottom-padding": true}).toString()}>
                <div className={(this._volmaMultifile("left")).toString()}>
                    <span className={(this._volmaMultifile("title")).toString()}>{i18next.t('complaint:AdditionalFiles')}</span>
                </div>
                <div className={(this._volmaMultifile("center")).toString()}>
                    {files.length > 0 && files}
                    {files.length === 0 && noFiles}
                </div>
                <div className={(this._volmaMultifile("right")).toString()}>
                    <a onClick={(() => this.OnAddAdditionalFileClicked(isEditable === false)).bind(this)} className={(this._volmaMultifile("add")).toString()}>
                        <svg className={this._volmaMultifile("add-ico").toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                        </svg>
                    </a>
                </div>
            </div>
        );
    }

    private MessageValueChanged(event: any){
        this._dispatch(this._action.MessageChanged(event.target.value))
    }

    private ReloadChat(): void {
        clearTimeout(this._chatPostponeReloadTimeout)
        this._chatPostponeReloadTimeout = setTimeout(() => this._dispatch(this._action.LoadComplaintComments(this._id, i18next.t("common:Loading"), this._dto)), this._chatReloadDebounce);
    }

    private ReloadAdditionalFiles(): void {
        this._dispatch(this._action.LoadAdditionFiles(this._id, i18next.t("common:Loading")));
    }

    private ReloadEntity(): void{
        this._action.ReloadEntity(this._dispatch, EEntityType.Complaint, this._id, i18next.t("common:Loading"), this.OnAfterDataLoaded);
    }

    private IsComplaintInFinishingState() {
        return this._dto.ComplaintState === EComplaintState.Closed || this._dto.ComplaintState === EComplaintState.Satisfied || this._dto.ComplaintState === EComplaintState.DecisionAccepted
    }
}