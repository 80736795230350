import { SELECT_REGISTER, SELECT_TOUCHED, SELECT_VALIDATE, SELECT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IReduce } from '../../Infrastructure/Reducer/IReducer';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import {
    ISelectRegisterPayload,
    ISelectTouchedPayload,
    ISelectValidatePayload,
    ISelectValueChangedPayload
} from './Payloads';
import { injectable } from 'inversify';
import { ISelectMultiEntityData } from './Payloads';

@injectable()
export class VolmaSelectActions {

    public Touched(name: string): IActionPayloaded<ISelectTouchedPayload> {
        return { type: SELECT_TOUCHED, Payload: { SelectName: name } };
    }

    public Register(
        name: string,
        dtoFieldName: string,
        helperFieldName: string,
        helperFieldToDTOFieldTranslator: (x) => any,
        reducer: IReduce<any>,
        validator: BaseValidator<any>,
        props: any): IActionPayloaded<ISelectRegisterPayload> {
        return { type: SELECT_REGISTER, Payload: {
            SelectName: name,
            DTOFieldName: dtoFieldName,
            HelperFieldName: helperFieldName,
            HelperFieldToDTOFieldTranslator: helperFieldToDTOFieldTranslator,
            Reducer: reducer,
            Validator: validator,
            Props: props
        } };
    }

    public Validate(name: string): IActionPayloaded<ISelectValidatePayload> {
        return { type: SELECT_VALIDATE, Payload: { SelectName: name } };
    }

    public ChangeValue(name: string, value: any, entityData?: ISelectMultiEntityData): IActionPayloaded<ISelectValueChangedPayload> {
        return { type: SELECT_VALUE_CHANGE, Payload: { SelectName: name, Value: value, EntityData: entityData}};
    }

}