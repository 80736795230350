import { EmptyTemporaryFileDTO } from '../../Domain/DTO/EmptyTemporaryFileDTO';
import { FilesSelectionDTO } from '../../Domain/DTO/FilesSelectionDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { CancelToken, AxiosPromise } from 'axios';
import { injectable } from 'inversify';


@injectable()
export class FileServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public UploadFile(fileId: string, formData: FormData, cancelToken: CancelToken, progressUpdater: (progress: number) => void): AxiosPromise {
        return this.Post(this._urlFabric.File(ECQRSApi.Write, fileId), formData, { 
            onUploadProgress: (progressEvent: any) => {
                progressUpdater(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            },
            cancelToken: cancelToken
        });
    }

    public GetFileInfo(fileId: string): AxiosPromise {
        return this.Get(this._urlFabric.FileInfo(ECQRSApi.Read, fileId));
    }

    public CreateEmptyTemporaryFile(request: EmptyTemporaryFileDTO): AxiosPromise {
        return this.Post(this._urlFabric.PostCreateEmptyTemporaryFile(), request);
    }

    public SelectFiles(files: FilesSelectionDTO): AxiosPromise {
        return this.Post(this._urlFabric.PostSelectFiles(), files);
    }

    public DeleteSelection(id: string): AxiosPromise {
        return this.Delete(this._urlFabric.DeleteSelectedFiles(id));
    }
}