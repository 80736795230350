import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputActions } from '../Shared/BaseInputActions';

import { injectable } from 'inversify';
import * as moment from 'moment';
import { IVolmaTimeTimeDTOValueChangedPayload } from './Payloads';
import { isInteger } from '../../Infrastructure/Services/Utils';

@injectable()
export class VolmaTimeTimeDTOActions extends BaseInputActions {

    public ChangeHoursValue(name: string, time: TimeDTO, value: string, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let numValue = this.GetNumberValue(value);
        let newTime = this.GetTimeDTO(time);
        newTime.Hours = numValue;
        if(value === "-" && !newTime.IsNegative)
            newTime.IsNegative = true;
        if(numValue > 0 && newTime.IsNegative)
            newTime.IsNegative = false;
        this.NormalizeTime(newTime, canBeNegative);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }

    public ChangeMinutesValue(name: string, time: TimeDTO, value: string, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let numValue = this.GetNumberValue(value);
        let newTime = this.GetTimeDTO(time);
        newTime.Minutes = numValue;
        this.NormalizeTime(newTime, canBeNegative);
        if(newTime.Hours <= 0 && value === "-")
            newTime.IsNegative = true;
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }

    public IncrementHours(name: string, time: TimeDTO, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let newTime = this.GetTimeDTO(time);
        if(newTime.IsNegative && newTime.Hours === 0 && newTime.Minutes !== 0) {
            newTime.IsNegative = false;
        } else {
            if(!newTime.IsNegative)
                newTime.Hours += 1;
            else
                newTime.Hours -= 1;
        }
        this.NormalizeTime(newTime, canBeNegative);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }


    public DecrementHours(name: string, time: TimeDTO, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let newTime = this.GetTimeDTO(time);
        if(!newTime.IsNegative && newTime.Hours === 0 && newTime.Minutes !== 0) {
            newTime.IsNegative = true;
        } else {
            if(!newTime.IsNegative)
                newTime.Hours -= 1;
            else
                newTime.Hours += 1;
        }
        this.NormalizeTime(newTime, canBeNegative);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }

    public IncrementMinutes(name: string, time: TimeDTO, step: number, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let newTime = this.GetTimeDTO(time);
        if(!newTime.IsNegative)
            newTime.Minutes += step;
        else
            newTime.Minutes -= step;
        this.NormalizeTime(newTime, canBeNegative);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }

    public DecrementMinutes(name: string, time: TimeDTO, step: number, canBeNegative: boolean): IActionPayloaded<IVolmaTimeTimeDTOValueChangedPayload> {
        let newTime = this.GetTimeDTO(time);
        if(!newTime.IsNegative)
            newTime.Minutes -= step;
        else
            newTime.Minutes += step;
        this.NormalizeTime(newTime, canBeNegative);
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: newTime } };
    }

    private GetTimeDTO(time: TimeDTO): TimeDTO{
        if (time !== undefined){
            this.EnsureNoUndefined(time)
            return time;
        }

        let res = new TimeDTO()
        res.Hours = 0;;
        res.Minutes = 0;
        res.Seconds = 0;

        return res;
    }

    private GetNumberValue(value: string | number): number {
        if (isInteger(<number>value))
            return <number>value;

        let numValue: number = undefined;
        if (value === undefined || value === null)
            numValue = 0;
        else if (!isNaN(parseInt(<string>value))) {
            numValue = parseInt(<string>value);
        }
        else
            numValue = 0;

        return numValue;
    }

    private EnsureNoUndefined(time: TimeDTO) {
        if (time.Hours === undefined)
            time.Hours = 0;
        if (time.Minutes === undefined)
            time.Minutes = 0;
        if (time.Seconds === undefined)
            time.Seconds = 0;
    }

    private NormalizeTime(time: TimeDTO, canBeNegative: boolean) {
        let totalMinutes = time.Hours * 60 + time.Minutes;
        if (!canBeNegative && totalMinutes < 0)
            totalMinutes = 0;
        else {
            if(totalMinutes < 0)
                time.IsNegative = !time.IsNegative;
            if(time.IsNegative && time.Hours < 0)
                time.Hours = -time.Hours;
            totalMinutes =  Math.abs(time.Hours * 60 + time.Minutes);
        }
        time.Hours = Math.floor(totalMinutes / 60);
        time.Minutes = totalMinutes - time.Hours * 60;
        // if (time.Hours > 23)
            // time.Hours = 0;
    }
}

