import { TenderImportAction } from '../Actions/TenderImportAction';
import {
    IVolmaTableAction,
    IVolmaTableCellRendererParams,
    IVolmaTableColumn,
    IVolmaTableProps
} from '../../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { TenderEntityService } from '../TenderEntityService';
import { injectable } from 'inversify';
import { ETenderState } from '../../../../Domain/Enum/ETenderState';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { TenderImportExcelAction } from '../Actions/TenderImportExcelAction';
import { TenderPublishAction } from '../Actions/TenderPublishAction';
import { TenderHelperProps } from '../IHelperProps';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';

@injectable()
export class TenderInactiveEntityService extends TenderEntityService{

    public GetBaseEntity(): EEntityType {
        return EEntityType.Tender;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Tender,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Id)},
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Identifier),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.State), 
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => ETenderState,
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererProps: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ETenderState, this._enumService, props, cellRendererProps),
                    FilterKey: "TenderState",
                    SortKey: "TenderState",
                },
                VolmaTableCommonColumnDefinition.GetMultiPointColumn({ 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointName),
                }),
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.Zone,
                    FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneId)
                },
            ]
        );
    }
    
    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<TenderHelperProps>> {
        return [new TenderImportAction(), new TenderImportExcelAction(), new TenderPublishAction()];
    }
}