import { EComplaintCommentState } from '../../../Domain/Enum/EComplaintCommentState';
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ComplaintHelperProps } from './IHelperProps';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import {
    COMPLAINT_ACCEPTED_PAYMENT_EDITING,
    COMPLAINT_ADDITION_FILE_ADDED,
    COMPLAINT_ADDITION_FILE_DELETED,
    COMPLAINT_ADDITIONAL_FILES_LOADED,
    COMPLAINT_AUTHOR_LOADED,
    COMPLAINT_CARGO_TRANSPORTER_LOADED,
    COMPLAINT_COMMENT_ADDED,
    COMPLAINT_COMMENT_READED,
    COMPLAINT_COMPLAINT_COMMENTS_LOADED,
    COMPLAINT_COMPLAINT_FILE_DELETED,
    COMPLAINT_COMPLAINT_FILE_LOADED,
    COMPLAINT_COMPLAINT_FILE_UPDATED,
    COMPLAINT_COMPLAINT_MESSAGE_CHANGED,
    COMPLAINT_COMPLAINT_TEMPLATE_LOADED,
    COMPLAINT_COMPLAINT_TEMPLATE_UPDATED,
    COMPLAINT_STATE_UPDATED,
    INPUT_FILE_UPLOAD_SUCCESSFULLY,
    VOLMA_ENTITY_STATUS_TOGGLED,
    VOLMA_SOMEWHERE_CLICKED
} from '../../../Constants/AppConstants';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { ComplaintDTO } from '../../../Domain/DTO/ComplaintDTO';
import { ComplaintCommentDTO } from '../../../Domain/DTO/ComplaintCommentDTO';
import { UserDTO } from '../../../Domain/DTO/UserDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { injectable } from 'inversify';
import { EComplaintState } from '../../../Domain/Enum/EComplaintState';
import { IFileUploadSuccessfullyPayload } from '../../VolmaFile/Payloads';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { UpdateComplaintAcceptedPaymentDTO } from '../../../Domain/DTO/UpdateComplaintAcceptedPaymentDTO';
import { IEditingAcceptedPaymentPayload } from './Payload';
import { DataDTO } from '../../../Domain/DTO/DataDTO';
import { COMPLAINT_CARGOTRANSPORTERS_UPDATED, COMPLAINT_SHOWDUMMYSELECT_UPDATED, COMPLAINT_DELIVERIESVALUE_CLEAR } from '../../../Constants/AppConstants';


type IState = IBaseEntityProps<ComplaintDTO, ComplaintHelperProps>

@injectable()
export class ComplaintReducer extends EntityReducer<ComplaintDTO, ComplaintHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case COMPLAINT_COMPLAINT_COMMENTS_LOADED:
                return this.ReduceComplaintCommentsLoaded(state, action.Payload)
            case COMPLAINT_CARGO_TRANSPORTER_LOADED:
                return this.ReduceCargoTransporterLoaded(state, action.Payload)
            case COMPLAINT_COMPLAINT_TEMPLATE_LOADED:
                return this.ReduceComplaintTemplateLoaded(state, action.Payload)
            case COMPLAINT_AUTHOR_LOADED:
                return this.ReduceComplaintAuthorLoaded(state, action.Payload)
            case COMPLAINT_COMPLAINT_FILE_LOADED:
                return this.ReduceComplaintFileLoaded(state, action.Payload)
            case COMPLAINT_ADDITIONAL_FILES_LOADED:
                return this.ReduceAdditionalFilesLoaded(state, action.Payload)
            case COMPLAINT_COMPLAINT_TEMPLATE_UPDATED:
                return this.ReduceComplaintTemplateUpdated(state, action.Payload)
            case COMPLAINT_COMPLAINT_FILE_UPDATED:
                return this.ReduceComplaintFileUpdated(state, action.Payload)
            case COMPLAINT_COMPLAINT_FILE_DELETED:
                return this.ReduceComplaintFileDeleted(state, action.Payload)
            case COMPLAINT_ADDITION_FILE_ADDED:
                return this.ReduceAdditionFileAdded(state, action.Payload)
            case COMPLAINT_ADDITION_FILE_DELETED:
                return this.ReduceAdditionFileDeleted(state, action.Payload)
            case COMPLAINT_COMMENT_ADDED:
                return this.ReduceCommentAdded(state, action.Payload)
            case COMPLAINT_COMMENT_READED:
                return this.ReduceCommentReaded(state, action.Payload)
            case COMPLAINT_STATE_UPDATED:
                return this.ReduceStateUpdated(state, action.Payload)
            case COMPLAINT_COMPLAINT_MESSAGE_CHANGED:
                return this.ReduceMessageChanged(state, action.Payload)
            case COMPLAINT_ACCEPTED_PAYMENT_EDITING:
                return this.ReduceAcceptedPaymentEditnig(state, action.Payload);
            case VOLMA_ENTITY_STATUS_TOGGLED:
                return this.ReduceStatusToggled(state, action.Payload)
            case COMPLAINT_CARGOTRANSPORTERS_UPDATED:
                return this.UpdateCargotransportersList(state, action.Payload);
            case COMPLAINT_SHOWDUMMYSELECT_UPDATED:
                return this.UpdateShowDummyDeliverySelectPropertyValue(state, action.Payload);
            case COMPLAINT_DELIVERIESVALUE_CLEAR:
                return this.ClearDeliveriesValue(state, action.Payload);
           case VOLMA_SOMEWHERE_CLICKED:
                return this.ReduceStatusClose(state);
            default:
                return state;
        }
    }


    private ReduceComplaintCommentsLoaded(state: IState, payload: Array<ComplaintCommentDTO>): IState {
        return super.CollectionLoaded<ComplaintCommentDTO>(state, payload, [x => x.Comments]);
    }

    private ReduceCargoTransporterLoaded(state: IState, payload: CargoTransporterTableDTO): IState {
        return super.EntityLoaded<CargoTransporterDTO>(state, payload, x => x.CargoTransporter);
    }

    private ReduceComplaintTemplateLoaded(state: IState, payload: ComplaintTemplateTableDTO): IState {
        return super.EntityLoaded<ComplaintTemplateTableDTO>(state, payload, x => x.SelectedComplaintTemplate);
    }

    private ReduceComplaintAuthorLoaded(state: IState, payload: UserDTO): IState {
        return super.EntityLoaded<UserDTO>(state, payload, x => x.Author);
    }

    private ReduceComplaintFileLoaded(state: IState, payload: FileTableDTO): IState {
        return super.EntityLoaded<FileTableDTO>(state, payload, x => x.ComplaintFile);
    }

    private ReduceAdditionalFilesLoaded(state: IState, payload: Array<FileTableDTO>): IState {
        return super.CollectionLoaded<FileTableDTO>(state, payload, [x => x.AdditionalFiles]);
    }

    private ReduceComplaintTemplateUpdated(state: IState, payload: ComplaintTemplateTableDTO): IState {
        return super.UpdatedSelected<string, ComplaintTemplateTableDTO>(
            state,
            payload as any,
            x => x.ComplaintTemplateId,
            x => x.SelectedComplaintTemplate,
            x => x.Id);
    }

    private ReduceAdditionFileAdded(state: IState, payload: FileTableDTO): IState {
        let newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        this.ItemAddedToCollection(newState.DataDTO, payload.Id, [x => x.AdditionalFilesIds]);
        this.ItemAddedToCollection(newState.DataHelper, payload, [x => x.AdditionalFiles]);
        return newState;
    }

    private ReduceAdditionFileDeleted(state: IState, payload: FileTableDTO): IState {
        let newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        this.ItemDeletedFromCollection(newState.DataDTO, payload.Id, (a, b) => a === b, [x => x.AdditionalFilesIds]);
        this.ItemDeletedFromCollection(newState.DataHelper, payload, (a, b) => a.Id === b.Id, [x => x.AdditionalFiles]);

        return newState;
    }

    private ReduceCommentAdded(state: IState, payload: ComplaintCommentDTO): IState {
        let newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        this.ItemAddedToCollection(newState.DataDTO, payload.Id, [x => x.ComplaintCommentsIds]);
        this.ItemAddedToCollection(newState.DataHelper, payload, [x => x.Comments]);
        return newState;
    }

    private ReduceCommentReaded(state: IState, payload: Array<string>): IState {
        let newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO }, DataHelper: { ...<any>state.DataHelper } };

        newState.DataHelper.Comments = this.CopyCollectionObject(newState.DataHelper.Comments);
        for(let commentId of payload){
            let comment = newState.DataHelper.Comments.find(x => x.Id === commentId);
            if (comment !== undefined) {
                comment = { ...comment, CommentState: EComplaintCommentState.Readed };
            }
        }
        return newState;
    }

    private ReduceStateUpdated(state: IState, payload: EComplaintState): IState {
        let newState: IState = { ...state, DataDTO: { ...<any>state.DataDTO } };

        newState.DataDTO.ComplaintState = payload;
        return newState;
    }

    private ReduceComplaintFileUpdated(state: IState, payload: FileTableDTO): IState {
        return super.UpdatedSelected<string, FileTableDTO>(
            state,
            payload as any,
            x => x.ComplaintFileId,
            x => x.ComplaintFile,
            x => x.Id);
    }

    private ReduceComplaintFileDeleted(state: IState, payload: FileTableDTO): IState {
        return super.DeletedSelected<string, FileTableDTO>(
            state,
            x => x.ComplaintFileId,
            x => x.ComplaintFile);
    }

    private ReduceMessageChanged(state: IState, payload: string) {
        let newState = { ...state, DataHelper: {...state.DataHelper} };
        newState.DataHelper.Message = payload;
        return newState;
    }

    private ReduceFileUploadedSuccessfully(state: IState, payload: IFileUploadSuccessfullyPayload) {
        let newState = { ...state};
        super.ItemAddedToCollection(newState.DataHelper, payload.Value, [x => x.UploadedFilesCache])

        return newState;
    }
    private ReduceAcceptedPaymentEditnig(state: IState, payload: IEditingAcceptedPaymentPayload): IState {
        let newState = { ...state, DataHelper: { ...state.DataHelper }}

        newState.DataHelper.IsAcceptedPaymentEditing = payload.StartEditing;
        if (newState.DataHelper.IsAcceptedPaymentEditing){
            newState.DataHelper.SavedAcceptedPaymentValue = payload.SavedValue;
        }
        else if(payload.RestoreSavedValue){
            newState.DataDTO.AcceptedPayment = newState.DataHelper.SavedAcceptedPaymentValue;
        }
        return newState;
    }

    private ReduceStatusToggled(state: IState, payload: number): IState {
        return { ...state, OpenedStatusIndex: payload }
    }

    private ReduceStatusClose(state: IState): IState {
        return { ...state, OpenedStatusIndex: -1 }
    }

    private UpdateCargotransportersList(state: IState, payload: Array<CargoTransporterTableDTO>): IState {
        const newState = {
            ...state,
            DataHelper: {
                ...state.DataHelper,
                CargoTransporters: payload
            }
        }

        return newState;
    }

    private UpdateShowDummyDeliverySelectPropertyValue(state: IState, payload: boolean): IState {
        const newState = {
            ...state,
            DataHelper: {
                ...state.DataHelper,
                ShowDummyDeliverySelect: payload,
                DeliveriesCache: {}
            }
        }

        return newState;
    }

    private ClearDeliveriesValue(state: IState, payload: boolean): IState {
        const newState = {
            ...state,
            DataDTO: {
                ...state.DataDTO,
                DeliveriesId: []
            }
        }
        return newState;
    }
}
