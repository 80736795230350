import { DeliveryDTO } from '../../../Domain/DTO/DeliveryDTO';
import { DriverLocationDTO } from '../../../Domain/DTO/DriverLocationDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { EntityDTO } from '../../../Domain/DTO/EntityDTO';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EFilterType } from '../../../Domain/Enum/EFilterType';
import { FilterHelper } from '../../../Infrastructure/Services/FilterHelper';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaSelectExtendedTableDTO } from '../../VolmaSelect/IVolmaSelectProps';
import { DeliveryDriverMonitoringDTO, DeliveryMonitoringQuery, MultiTransporterDtoFields, MultiTransporterTableDTO } from './IHelperProps';

export class DriverMonitoringActionsHelper {
    private readonly CargoTransporterPropertyId = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.CargoTransporterId);
    private readonly MultiCargoTransporterPropertyId = PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.MultiCargoTransporterId);
    private readonly DriverPropertyId = PropertyHelper.GetPropertyName((x: DriverLocationDTO) => x.DriverId);
    private readonly IdentifierProperty = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Identifier);

    public GetDeliveryFiltersByQuery(query: DeliveryMonitoringQuery): FilterDTO[] {
        const filters = [this.GetDefaultDeliveryFilter()];
        filters.push(...this.GetFiltersByMultiTransporterQuery(query));

        this.AddFilterFromQueryIfDataExist(query.driver, this.DriverPropertyId, filters);
        this.AddFilterFromQueryIfDataExist(query.identifier, this.IdentifierProperty, filters);

        return filters;
    };

    public GetDeliveryFiltersBySelectedValues(selectedMultiTransporters: Array<MultiTransporterTableDTO>, selectedDrivers: Array<DriverTableDTO>, identifier: string): FilterDTO[] {
        const filters = [this.GetDefaultDeliveryFilter()];
        filters.push(...this.GetFiltersBySelectedMultiTransporterValues(selectedMultiTransporters));

        this.AddFilterFromSelectedValuesIfCollectionIsNotEmpty(selectedDrivers, this.DriverPropertyId, filters);
        this.AddFilterFromQueryIfDataExist(identifier, this.IdentifierProperty, filters);

        return filters;
    }

    public GetFiltersBySelectedMultiTransporterValues(value: Array<MultiTransporterTableDTO>): FilterDTO[] {
        const filters: FilterDTO[] = [];

        const cargoTransporters = this.GetMultiTransporterEntityByPropertyName(value, this.CargoTransporterPropertyId);
        const multiCargoTransporters = this.GetMultiTransporterEntityByPropertyName(value, this.MultiCargoTransporterPropertyId);

        this.AddFilterFromSelectedValuesIfCollectionIsNotEmpty(cargoTransporters, this.CargoTransporterPropertyId, filters);
        this.AddFilterFromSelectedValuesIfCollectionIsNotEmpty(multiCargoTransporters, this.MultiCargoTransporterPropertyId, filters);

        return filters;
    }

    public GetFiltersByMultiTransporterQuery(query: DeliveryMonitoringQuery): FilterDTO[] {
        const filters: FilterDTO[] = [];

        this.AddFilterFromQueryIfDataExist(query.cargoTransporter, this.CargoTransporterPropertyId, filters);
        this.AddFilterFromQueryIfDataExist(query.multiCargoTransporter, this.MultiCargoTransporterPropertyId, filters);

        return filters;
    }

    private GetDefaultDeliveryFilter(): FilterDTO {
        const defaultFilter = new FilterDTO();

        defaultFilter.Type = EFilterType.Text;
        defaultFilter.TextValue = 'true';
        defaultFilter.Key = 'HasLocation';

        return defaultFilter;
    }   

    private AddFilterFromSelectedValuesIfCollectionIsNotEmpty(values: EntityDTO[], key: string, filters: FilterDTO[]): void {
        if (values.length) {
            const fieldFilter = FilterHelper.CreateFilterByItems(values, key);    
            filters.push(fieldFilter);
        }
    }

    private AddFilterFromQueryIfDataExist(queryData: string, key: string, filters: FilterDTO[]): void {
        if (queryData) {
            const queryFilter = FilterHelper.CreateFilterByInputQuery(queryData, key);
            filters.push(queryFilter);
        }
    }

    private GetMultiTransporterEntityByPropertyName(multiValue: Array<MultiTransporterTableDTO>, key: string) {
        const extendedMultiValue = multiValue as VolmaSelectExtendedTableDTO[];
        return extendedMultiValue.filter(x => x.EntityIdx === MultiTransporterDtoFields.indexOf(key));
    }
}