import { injectable } from 'inversify';
import {
    DELIVERY_CONFIRM_ALL_DRIVERS_LOADED,
    DELIVERY_CONFIRM_ALL_VEHICLES_LOADED, DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED,
    DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER
} from '../../../../Constants/AppConstants';
import { CargoTransporterDTO } from '../../../../Domain/DTO/CargoTransporterDTO';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { DriverDTO } from '../../../../Domain/DTO/DriverDTO';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { MultiCargoTransporterDTO } from '../../../../Domain/DTO/MultiCargoTransporterDTO';
import { VehicleDTO } from '../../../../Domain/DTO/VehicleDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';

@injectable()
export class DeliveryConfirmActions extends EntityAction {
    constructor() {
        super();
    }

    public LoadAllDrivers(waitingText: string) {
        return super.LoadDataTable<DriverDTO>(
            EEntityType.Driver,
            x => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllDriversLoaded);
    }    

    public LoadAllVehicles(waitingText: string) {
        return super.LoadDataTable<VehicleDTO>(
            EEntityType.Vehicle,
            x => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            (data) => this.AllVehiclesLoaded(data));
    }

    public LoadMultiCargoTransporterById(waitingText: string, multiCargoTransporterId: string, dispatch: any) {
        return super.LoadEntityCached<MultiCargoTransporterDTO>(
            multiCargoTransporterId,
            EEntityType.MultiCargoTransporter,
            waitingText,
            (x) => {                
                dispatch(this.LoadCargoTransporterById(waitingText, x.MainCargoTransporter));

                return this.MultiCargoTransporterLoaded(x);
            });
    }

    public LoadCargoTransporterById(waitingText: string, cargoTransporterId: string) {
        return super.LoadEntityCached<CargoTransporterDTO>(
            cargoTransporterId,
            EEntityType.CargoTransporter,
            waitingText,            
            this.SelectCargoTransporter);
    }    

    public SelectCargoTransporter(value: CargoTransporterTableDTO): IActionPayloaded<CargoTransporterTableDTO> {
        return { type: DELIVERY_CONFIRM_SELECT_CARGO_TRANSPORTER, Payload: value };
    }

    private AllDriversLoaded(value: Array<DriverDTO>): IActionPayloaded<Array<DriverTableDTO>> {
        return { type: DELIVERY_CONFIRM_ALL_DRIVERS_LOADED, Payload: value };
    }

    private AllVehiclesLoaded(value: Array<VehicleDTO>): IActionPayloaded<Array<VehicleTableDTO>> {        
        return { type: DELIVERY_CONFIRM_ALL_VEHICLES_LOADED, Payload: this.SortVehiclesByNameAndPlateNumber(value) };
    }

    private MultiCargoTransporterLoaded(value: MultiCargoTransporterDTO) {
        return { type: DELIVERY_CONFIRM_MULTI_CARGO_TRANSPORTER_LOADED, Payload: value };
    }

    private SortVehiclesByNameAndPlateNumber(value: Array<VehicleDTO>): Array<VehicleDTO> {
        return value.sort((a, b) => a.Name.localeCompare(b.Name) || a.VehicleLicensePlateNumber.localeCompare(b.VehicleLicensePlateNumber));
    }

}