import { injectable } from 'inversify';
import * as React from 'react';
import { DatesReportParametersDTO } from '../../../Domain/DTO/DatesReportParametersDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../Domain/Enum/EReportType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { UrlFabric } from '../../../Infrastructure/ServerInteraction/UrlFabric';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import i18next from '../../i18n';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaSelect from '../../VolmaSelect';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { IReportService } from './ConcreteReports/IReportService';
import { ReportCargoTransportersRatingsExcelService } from './ConcreteReports/ReportCargoTransportersRatingsExcelService';
import { ReportCargoTransportersRatingSummaryExcelService } from './ConcreteReports/ReportCargoTransportersRatingSummaryExcelService';
import { ReportComplaintsExcelService } from './ConcreteReports/ReportComplaintsExcelService';
import { ReportDeliveriesAttachedFilesService } from './ConcreteReports/ReportDeliveriesAttachedFilesService';
import { ReportDeliveriesExcelService } from './ConcreteReports/ReportDeliveriesExcelService';
import { ReportDriverScoreService } from "./ConcreteReports/ReportDriverScoreService";
import { ReportRatesExcelService } from './ConcreteReports/ReportRatesExcelService';
import { ReportReceivedDeliveriesService } from './ConcreteReports/ReportReceivedDeliveriesService';
import { ReportTendersExcelService } from './ConcreteReports/ReportTendersExcelService';
import { InitialReportHelperProps, IReportHelperProps } from './IHelperProps';
import { ReportActions } from './ReportActions';
import { ReportDTO } from './ReportDTO';
import { ReportParametersExtendedDTO } from './ReportParametersExtendedDTO';
import { ReportReducer } from './ReportReducer';

@injectable()
export class ReportEntityService extends BaseEntityService<ReportDTO, IReportHelperProps<any>>{
    private _volmaTableService: VolmaTableService;
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _reducer: ReportReducer;
    private _action: ReportActions;
    private _urlFabric: UrlFabric;
    private _dispatch: any;
    private _charts: Array<ReportParametersExtendedDTO>;

    private _reportTendersExcelService                       : ReportTendersExcelService;
    private _reportRatesExcelService                         : ReportRatesExcelService;
    private _reportDeliveriesExcelService                    : ReportDeliveriesExcelService;
    private _reportComplaintsExcelService                    : ReportComplaintsExcelService;
    private _reportCargoTransportersRatingsExcelService      : ReportCargoTransportersRatingsExcelService;
    private _deliveriesAttachedFilesReport                   : ReportDeliveriesAttachedFilesService;
    private _reportReceivedDeliveriesService                 : ReportReceivedDeliveriesService;
    private _reportCargoTransportersRatingSummaryExcelService: ReportCargoTransportersRatingSummaryExcelService;
    private _reportDriverScore: ReportDriverScoreService;

    private _report = volmaBlock("report");

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator    = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer           = VolmaContainer.get<ReportReducer>(Types.ReportReducer);
        this._action            = VolmaContainer.get<ReportActions>(Types.ReportActions);
        this._urlFabric         = VolmaContainer.get<UrlFabric>(Types.UrlFabric);

        this._reportTendersExcelService                        = VolmaContainer.get<ReportTendersExcelService>(Types.ReportTendersExcelService);
        this._reportRatesExcelService                          = VolmaContainer.get<ReportRatesExcelService>(Types.ReportRatesExcelService);
        this._reportDeliveriesExcelService                     = VolmaContainer.get<ReportDeliveriesExcelService>(Types.ReportDeliveriesExcelService);
        this._reportComplaintsExcelService                     = VolmaContainer.get<ReportComplaintsExcelService>(Types.ReportComplaintsExcelService);
        this._reportCargoTransportersRatingsExcelService       = VolmaContainer.get<ReportCargoTransportersRatingsExcelService>(Types.ReportCargoTransportersRatingsExcelService);
        this._deliveriesAttachedFilesReport                    = VolmaContainer.get<ReportDeliveriesAttachedFilesService>(Types.ReportDeliveriesAttachedFilesService);
        this._reportCargoTransportersRatingSummaryExcelService = VolmaContainer.get<ReportCargoTransportersRatingSummaryExcelService>(Types.ReportCargoTransportersRatingSummaryExcelService);
        this._reportReceivedDeliveriesService                  = VolmaContainer.get<ReportReceivedDeliveriesService>(Types.ReportReceivedDeliveriesService);
        this._reportDriverScore                                = VolmaContainer.get<ReportDriverScoreService>(Types.ReportDriverScore);


        this.ChangeReportParams  = this.ChangeReportParams.bind(this);
        this.FilterReportTypes   = this.FilterReportTypes.bind(this);
        this.DownloadExcelReport = this.DownloadExcelReport.bind(this);
    }


    public GetColumnsList(): Array<IVolmaTableColumn> {
        return undefined;
    }

    public GetHeaderEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>): JSX.Element {
        return (
            <div className={(this._createCard("row")).toString()}>
                <div className={(this._createCard("header")).toString()}>
                    <span className={(this._createCard("title-icon")).toString()}>
                        <svg className={(this._createCard("title-ico").mix(["info-block-ico"])).toString()}>
                            <use xlinkHref="#info-block"></use>
                        </svg>
                        <svg className={(this._createCard("title-ico-bg").mix(["info-bg-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#info-block"></use>
                        </svg>
                    </span>
                    <span className={(this._createCard("header", {text: true })).toString()}>{i18next.t("report:Title")}</span>
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>): JSX.Element {
        let maxOrderNum: number = 0;
        if(props.DataHelper.ReportService !== undefined)
            props.DataHelper.ReportService.InitializeParams(props);
        if (PropertyHelper.IsArray(props.DataHelper.ReportCharts) && props.DataHelper.ReportCharts.length > 0){
            maxOrderNum = Math.max.apply(null, props.DataHelper.ReportCharts.map(x => x.OrderNum)) + 1;
        }
        return (
            <div className={(this._mainInfo({report: true})).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    <div className={(this._mainInfo("item", {blue: true })).toString()} >
                        <div className={(this._container()).toString()}>
                            <div className={(this._mainInfo("top")).toString()}>
                                <h2 className={(this._titleDrop("title").mix([this._mainInfo("title")])).toString()}>{i18next.t("report:ReportTypeTitle")} </h2>
                            </div>
                            {this.GetReportTypePart(props)}
                        </div>
                    </div>
                </div>

                {props.DataHelper.ReportService !== undefined &&
                    <div className={(this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""])).toString()}>
                    <div className={(this._mainInfo("item", {blue: true })).toString()} >
                        <div className={(this._container()).toString()} key={props.DataHelper.ReportService.Title}>
                            <div className={(this._mainInfo("top")).toString()}>
                                <h2 className={(this._titleDrop("title").mix([this._mainInfo("title")])).toString()}>{i18next.t("report:ReportParamsTitle")} </h2>
                            </div>
                            {props.DataHelper.ReportService.GetParamsEditor(props, () => null)}
                            <div className={(this._mainInfo("row", {"top-margin": true})).toString()}>
                                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        {props.DataHelper.ReportService !== undefined && props.DataHelper.ReportService.HasChart() && <VolmaInput
                                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ReportDTO) => val.Name) }
                                            Name={PropertyHelper.GetPropertyName((val: ReportDTO) => val.Name)}
                                            Label={i18next.t('report:Name')}
                                            Value={props.DataDTO.Name}
                                            Validator={this._inputValidator}
                                            dispatch={props.dispatch} />}
                                    </div>
                                </div>
                                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        {props.DataHelper.ReportService !== undefined && props.DataHelper.ReportService.HasChart() && props.IsValid &&
                                            <div className={(this._input({"no-padding": true })).toString()}>
                                                <div className={(this._btnGreen({"full-height": true }).mix([this._btn()])).toString()}>
                                                    <button
                                                        className={(this._btn.mix([this._btnGreen])).toString()}
                                                        onClick={((event: Event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            this.GenerateReport(
                                                                props.DataHelper.SelectedReportType,
                                                                props.DataDTO.Name,
                                                                props.DataHelper.ReportParams,
                                                                maxOrderNum,
                                                                props.DataHelper.ReportService)
                                                        }).bind(this)}>{i18next.t('report:GenerateChartReport')}</button>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div className={this._mainInfo("cell", { 33: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        {props.DataHelper.ReportService !== undefined && props.DataHelper.IsExcelReport && props.IsValid &&
                                            <div className={(this._input({"no-padding": true })).toString()}>
                                                <div className={(this._btnGreen({"full-height": true }).mix([this._btn])).toString()}>
                                                    <a onClick={() => this.DownloadExcelReport(props)} className={(this._btn.mix([this._btnGreen])).toString()} target="_blank">
                                                        {i18next.t('report:GenerateExcelReport')}
                                                    </a>
                                                </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className={(this._titleDropParent({relative: true})).toString()}>
                    {this.GetMainInfoNoDropItem(false, true, i18next.t("report:ChartsTitle"), this.GetChartsPart(props))}
                </div>

            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>) {
        this._dispatch = props.dispatch;
        this._dispatch(this._action.LoadReportCharts(i18next.t("common:Loading")));
    }

    public DisposeEntity(){
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialReportHelperProps;
    }

    public OnAfterDataLoaded(dto: ReportDTO) {
    }

    private ChangeReportParams(dto: ListIntOptionDTO) {
        if (dto === undefined || dto === null)
            setTimeout(() => this._dispatch(this._action.DeleteReportParams()));
        else
            setTimeout(() => this._dispatch(this._action.ChangeReportParams(dto.Id as EReportType, i18next.t("common:Loading"))))
    }

    private GenerateReport(reportType: EReportType, name: string, reportParams: any, orderNum: number, reportService: IReportService<any>) {
        let paramsToSend = { ...reportParams };
        this.AdjustTime(paramsToSend);

        this._dispatch(this._action.AddChart(reportType, name, paramsToSend, orderNum, reportService, i18next.t("common:Loading")))
    }

    private DownloadExcelReport(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>){
        let reportType = props.DataHelper.SelectedReportType;
        let paramsToSend = { ...props.DataHelper.ReportParams };
        this.AdjustTime(paramsToSend);

        this._dispatch(this._action.GetExcelReport(reportType, paramsToSend, i18next.t("common:Loading")))
    }

    private OnDeleteChartClick(reportParams: ReportParametersExtendedDTO){
        this._dispatch(this._action.DeleteChart(reportParams, i18next.t("common:Loading")))
    }

    private GetReportTypePart(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: IReportHelperProps<any>) => val.SelectedReportType) as any) }
                                Name={PropertyHelper.GetPropertyName((val: IReportHelperProps<any>) => val.SelectedReportType)}
                                Label={i18next.t('report:ReportType')}
                                Placeholder={i18next.t('report:SelectReportType')}
                                EnumGetter={() => EReportType}
                                Value={props.DataHelper.SelectedReportType}
                                OptionsFilter={x => this.FilterReportTypes(x)}
                                OnValueChanged={(x => this.ChangeReportParams(x)).bind(this)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private GetChartsPart(props: IBaseEntityProps<ReportDTO, IReportHelperProps<any>>): JSX.Element {
        this._charts = props.DataHelper.ReportCharts;
        let charts = new Array<JSX.Element>()
        if (PropertyHelper.IsArray(props.DataHelper.ReportCharts)) {
            for (let reportChart of props.DataHelper.ReportCharts){
                let reportService = this.GetReportService(reportChart.ReportType);
                charts.push(
                    <div key={reportChart.Id} className={(this._mainInfo("cell", {report: true })).toString()} style={{ order: -reportChart.OrderNum}}>
                        <div className={(this._mainInfo("line", {fill: true, report: true }).mix([this._report()])).toString()}>
                            <div className={(this._report("header")).toString()}>
                                <h3>{this._enumService.GetOptionLocalized(() => EEntityType, reportChart.ReportType)}</h3>
                                <a onClick={(() => this.OnDeleteChartClick(reportChart)).bind(this)} className={(this._report("delete")).toString()}>
                                    <svg className={(this._report("delete-ico")).toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                                    </svg>
                                </a>
                            </div>
                            {(reportChart.Name !== undefined && reportChart.Name !== null && reportChart.Name.length > 0) ? <h5>{reportChart.Name}</h5> : <h5>&nbsp;</h5>}
                            <div className={(this._report("chart")).toString()}>
                                {reportService.GetChart(reportChart.Data)}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return (
            <div className={(this._mainInfo("row", {report: true})).toString()}>
                {charts}
            </div>
        );
    }


    private GetReportService(type:EEntityType){
        switch (type) {
            case EEntityType.TenderReportItem:
                return this._reportTendersExcelService;
            case EEntityType.RateReportItem:
                return this._reportRatesExcelService;
            case EEntityType.DeliverySummaryReportItem:
                return this._reportDeliveriesExcelService;
            case EEntityType.ComplaintReportItem:
                return this._reportComplaintsExcelService;
            case EEntityType.RatingReportItem:
                return this._reportCargoTransportersRatingsExcelService;
            case EEntityType.RatingSummaryReportItem:
                return this._reportCargoTransportersRatingSummaryExcelService;
            case EEntityType.ReceivedDeliveryReportItem:
                return this._reportReceivedDeliveriesService;
            case EEntityType.DeliveryAttachedFilesReport:
                return this._deliveriesAttachedFilesReport;
        }
        throw new Error('Unexpected report type: ' + type);
    }

    private AdjustTime(reportParams: DatesReportParametersDTO) {
        if (PropertyHelper.IsDate(reportParams.DateFrom)) {
            reportParams.DateFrom = this._timeService.GetCorrectedTime(reportParams.DateFrom)
        }
        if (PropertyHelper.IsDate(reportParams.DateTo)) {
            reportParams.DateTo = this._timeService.GetCorrectedTime(reportParams.DateTo)
        }
    }

    private FilterReportTypes(value: ListIntOptionDTO){
        if(this._authService.IsShipper())
            return true;
        else if (this._authService.IsCargoTransporter())
            return value.Id === EReportType.CargoTransportersRatingsExcel ||
            value.Id === EReportType.ComplaintsExcel ||
            value.Id === EReportType.CargoTransportersRatingSummaryExcel;// ||
            // value.Id === EReportType.DeliveriesAttachedFilesExcel;
        return false;
    }
}