import { injectable } from 'inversify';
import { MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED, MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED, MULTI_CARGO_TRANSPORTER_RATING_EDITED, MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS } from '../../../../Constants/AppConstants';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { MultiCargoTransporterDTO } from '../../../../Domain/DTO/MultiCargoTransporterDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { MultiCargoTransporterHelperProps } from './InitialMultiCargoTransporterHelperProps';

type IState = IBaseEntityProps<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps>;

@injectable()
export class MultiCargoTransporterReducer extends EntityReducer<MultiCargoTransporterDTO, MultiCargoTransporterHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case MULTI_CARGO_TRANSPORTER_SET_SELECTED_CARGO_TRANSPORTERS:
                return this.ReduceSelectedCargoTransporters(state, action.Payload);
            case MULTI_CARGO_TRANSPORTER_CURRENT_RATINGS_LOADED:
                return this.ReduceCurrentRatingsLoaded(state, action.Payload);
            case MULTI_CARGO_TRANSPORTER_FORECAST_RATINGS_LOADED:
                return this.ReduceForecastRatingsLoaded(state, action.Payload);
            case MULTI_CARGO_TRANSPORTER_RATING_EDITED:
                return this.ReduceRatingEdited(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceSelectedCargoTransporters(state: IState, payload: Array<CargoTransporterTableDTO>): IState {
        return super.CollectionLoaded<CargoTransporterTableDTO>(state, payload, [x => x.SelectedCargoTransporters]);
    }

    private ReduceCurrentRatingsLoaded(state: IState, payload: Array<RatingTableDTO>): IState {       
        return super.CollectionLoaded(state, payload, [x => x.CurrentRating, x => x.CurrentRatingOriginal]);
    }

    private ReduceForecastRatingsLoaded(state: IState, payload: Array<RatingTableDTO>): IState {
        return super.CollectionLoaded(state, payload, [x => x.ForecastRating]);
    }

    private ReduceRatingEdited(state: IState, payload: RatingTableDTO): IState {
        const newState = { ...state, DataHelper: { ...state.DataHelper } };
        super.ItemEditedInCollection(newState.DataHelper, payload, [x => x.CurrentRating, x => x.CurrentRatingOriginal]);
        return newState;
    }
}
