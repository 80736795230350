import { injectable } from "inversify";
import { UserDTO } from "../../Domain/DTO/UserDTO";
import { EUserRole } from "../../Domain/Enum/EUserRole";
import { VolmaContainer } from "../InversifyInject";
import { Types } from "../Types";
import { LocalStorageService } from "./LocalStorageService";

@injectable()
export class AuthenticationService {
    private _storageService: LocalStorageService;

    private readonly _isAuthenticated: string = "authenticated";
    private readonly _user: string = "user";

    constructor() {
        this._storageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public IsAuthenticated(): boolean {
        return this._storageService.IsKeyExist(this._isAuthenticated);
    }

    public IsShipper(): boolean {
        const user = this.GetCurrentUser();
        return this.IsUserShipper(user.Role);
    }

    public IsUserShipper(role: EUserRole): boolean {
        return (
            role === EUserRole.Administrator ||
            role === EUserRole.ShipperLogist ||
            role === EUserRole.ShipperLogistExtended
        );
    }

    public IsCargoTransporter(): boolean {
        const user = this.GetCurrentUser();
        return this.IsUserCargoTransporter(user.Role);
    }

    public IsIncludedCargoTransporter(withInclusionInMulti: boolean = true): boolean {
        const user = this.GetCurrentUser();
        return this.IsUserCargoTransporter(user.Role) && user.IsIncludeInMultiCargoTransporter === withInclusionInMulti;
    }

    public IsUserCargoTransporter(role: EUserRole): boolean {
        return role === EUserRole.CargoTransporterLogist || role === EUserRole.CargoTransporterLogistExtended;
    }

    public IsAdministrator(): boolean {
        const user = this.GetCurrentUser();
        return user.Role === EUserRole.Administrator;
    }

    public IsShipperExtended(): boolean {
        const user = this.GetCurrentUser();
        return user.Role === EUserRole.ShipperLogistExtended;
    }

    public GetCurrentUser(): UserDTO {
        if (!this._storageService.IsKeyExist(this._user)) return new UserDTO();
        return JSON.parse(this._storageService.GetKey(this._user));
    }

    public SetAuthenticated(): void {
        this._storageService.SetKey(this._isAuthenticated, "1");
    }

    public SetUser(user: UserDTO): void {
        this._storageService.SetKey(this._user, JSON.stringify(user));
    }

    public ClearAuthenticated(): void {
        this._storageService.RemoveKey(this._isAuthenticated);
    }

    public ClearUser(): void {
        this._storageService.RemoveKey(this._user);
    }
}
