import { injectable } from 'inversify';
import i18next from '../../i18n';
import * as React from 'react';
import { IVolmaTableProps, IVolmaTableCellRendererParams } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { ECargoTransporterUserState } from '../../../Domain/Enum/ECargoTransporterUserState';



@injectable()
export class CargoTransporterUserTableCellRenderers {
    public static UserStatusRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

            let style = VolmaTableCommonCellRenderers.GetStyle(params);
            let key = VolmaTableCommonCellRenderers.GetKey(params);
            let val = undefined;
            if(params.cellData === undefined || params.cellData === ECargoTransporterUserState.Unblocked) {
                val = i18next.t("cargotransporteruser:Unblocked");
            }
            else {
                val = i18next.t("cargotransporteruser:Blocked");
            }

            return (
                <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected }).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{val}</span>
                    </div>

                </div>
        )
    }
}