import { ReactNode } from "react";
import * as React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

interface IVolmaActionLinkProps {
    path: string;
    label?: string;
    className?: string;
    children?: ReactNode;
}

interface IVolmaActionState {}

export class VolmaActionLink extends Component<IVolmaActionLinkProps, IVolmaActionState> {
    render() {
        return (
            <Link className={this.props.className ? this.props.className : undefined} to={this.props.path}>
                {this.props.label && this.props.label}
                {this.props.children && this.props.children}
            </Link>
        );
    }
}
