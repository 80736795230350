import { ITableDTO } from '../../Domain/ITableDTO';
import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import { EFilterType } from '../../Domain/Enum/EFilterType';

export class FilterHelper {
    public static CreateFilterByItems = (items: Array<ITableDTO>, key: string): FilterDTO => {
        const query = items.map(el => el.Id).join(";");
    
        return FilterHelper.CreateFilterByInputQuery(query, key);
    }

    public static CreateFilterByIds = (ids: Array<string>, key: string): FilterDTO => {
        const query = ids.join(";");
    
        return FilterHelper.CreateFilterByInputQuery(query, key);
    }
    
    public static CreateFilterByInputQuery = (query: string | undefined, key: string): FilterDTO | undefined => {
        if (!query) return undefined;
    
        const filter = new FilterDTO();
    
        filter.Type = EFilterType.Text;
        filter.TextValue = query
        filter.Key = key;
    
        return filter;
    }
}