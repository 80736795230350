import { injectable } from 'inversify';
import * as moment from 'moment';
import * as React from 'react';
import { CalendarEntryDTO } from '../../../../Domain/DTO/CalendarEntryDTO';
import { DateDTO } from '../../../../Domain/DTO/DateDTO';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { UrlFabric } from '../../../../Infrastructure/ServerInteraction/UrlFabric';
import { volmaBlock } from '../../../../Infrastructure/Services/BEM';
import { EnumService } from '../../../../Infrastructure/Services/EnumService';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TimeService } from '../../../../Infrastructure/Services/TimeService';
import { Types } from '../../../../Infrastructure/Types';
import { ITenderTableHelperProps } from '../../../Entity/Tender/Table/ITableHelperProps';
import i18next from '../../../i18n';
import { IVolmaTableCellRendererParams, IVolmaTableProps } from '../IVolmaTableProps';


@injectable()
export class VolmaTableCommonCellRenderers {
    public static _table     = volmaBlock("table");
    public static _tableCont = volmaBlock("table-cont");
    public static _setWager  = volmaBlock("set-wager");
    public static _btnEdit   = volmaBlock("btn-edit");
    public static _btn       = volmaBlock("btn");
    public static _volmaFile = volmaBlock("volma-file");

    private static _timeService:TimeService;


    public static DefaultCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()} dangerouslySetInnerHTML={{ __html: params.cellData }}></span>
                </div>

            </div>
        )
    }

    public static SafeTextCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{params.cellData}</span>
                </div>

            </div>
        )
    }

    public static SafeTextCellRendererWithUndefined(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let result = params.cellData;
        if(result === undefined || result === null)
            result = i18next.t("common:NoData");

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{result}</span>
                </div>

            </div>
        )
    }

    public static SafeTextCollectionCellRendererWithUndefiend(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let result = params.cellData as Array<string>;
        let text;
        if(result === undefined || result === null) {
            result = i18next.t("common:NoData") as any;
            text = result;
        }
        else {
            text = result.join("<br/>")
        }
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()} dangerouslySetInnerHTML={{ __html: text }}></span>
                </div>
            </div>
        )
    }


    public static MoneyCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                    </svg>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{params.cellData}</span>
                </div>
            </div>
        )
    }

    public static CountDownCellRenderer(
        props: IVolmaTableProps<ITenderTableHelperProps>,
        params: IVolmaTableCellRendererParams) {

        return VolmaTableCommonCellRenderers.GetTimeleftCell(params.cellData, params);
    }

    public static DateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {


        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        // @ts-ignore: This expression is not callable
        let isValid = params.cellData !== undefined && params.cellData !== null && moment(params.cellData).isValid();
        let dateConvertedToLocal = undefined;
        if(isValid){
            dateConvertedToLocal = VolmaTableCommonCellRenderers.GetTimeService().GetLocalTime(params.cellData);
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                {!isValid ? <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:NoData")}</span>
                </div>
                :
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateConvertedToLocal.format("DD.MM.YYYY")}</span>
                    </span>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dateConvertedToLocal.format("HH.mm")}</span>
                    </span>
                </div>}
            </div>
        )
    }

    public static DateDTOCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {


        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        let isValid = PropertyHelper.SafeGetValue(params.cellData as DateDTO, x => x.Day) != undefined &&
                      PropertyHelper.SafeGetValue(params.cellData as DateDTO, x => x.Month) != undefined &&
                      PropertyHelper.SafeGetValue(params.cellData as DateDTO, x => x.Year) != undefined;

        let dateDTOValue:DateDTO = (params.rowData as CalendarEntryDTO).Date;
        let zeroBeforeDayRequired = isValid && PropertyHelper.SafeGetValue(params.cellData as DateDTO, x => x.Day) < 10;
        let zeroBeforeMonthRequired = isValid && PropertyHelper.SafeGetValue(params.cellData as DateDTO, x => x.Month) < 9;

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                {!isValid ? <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:NoData")}</span>
                </div>
                :
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                        </svg>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{zeroBeforeDayRequired ? "0" : ""}{dateDTOValue.Day}.{zeroBeforeMonthRequired ? "0" : ""}{dateDTOValue.Month + 1}.{dateDTOValue.Year}</span>
                    </span>
                </div>}
            </div>
        )
    }

    public static OnlyDateCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {


        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);
        // @ts-ignore: This expression is not callable
        let isValid = params.cellData !== undefined && params.cellData !== null && moment(params.cellData).isValid();
        let dateConvertedToLocal = undefined;
        if(isValid){
            dateConvertedToLocal = VolmaTableCommonCellRenderers.GetTimeService().GetLocalTime(params.cellData);
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                {!isValid ? <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:NoData")}</span>
                </div>
                :
                <div className={(VolmaTableCommonCellRenderers._tableCont({wrap: true })).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("box")).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-date-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#date"></use>
                        </svg>
                        {/* @ts-ignore: This expression is not callable */}
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{moment(params.cellData).format("DD.MM.YYYY")}</span>
                    </span>
                </div>}
            </div>
        )
    }

    public static PriceCellRenderer(
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {


        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        if (params.cellData === 0)
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                        <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t('common:NoData')}</span>
                    </div>
                </div>
            )
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["rub"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                    </svg>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{params.cellData}</span>
                </div>
            </div>
        )
    }

    public static EnumCellRenderer(
        enumTypeGetter: () => any,
        enumService: EnumService,
        props: IVolmaTableProps<{}>,
        params: IVolmaTableCellRendererParams) {

        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        let text = "";
        if (Object.prototype.toString.call(params.cellData) === '[object Array]') {
            text = (params.cellData as Array<any>).map(x => enumService.GetOptionLocalized(enumTypeGetter, x)).join(", ");
        }
        else{
            text = enumService.GetOptionLocalized(enumTypeGetter, params.cellData);
        }

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{text}</span>
                </div>
            </div>
        )
    }


    public static GetTimeleftCell(data: Date, params: IVolmaTableCellRendererParams) {
        let style = VolmaTableCommonCellRenderers.GetStyle(params);
        let key = VolmaTableCommonCellRenderers.GetKey(params);

        if (VolmaTableCommonCellRenderers.GetTimeService().IsInFuture(data)) {
            let difference = VolmaTableCommonCellRenderers.GetTimeService().DiffToNow(data);
            let days = Math.floor(difference.asDays());
            return (
                <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                    <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                        <svg className={(VolmaTableCommonCellRenderers._tableCont("ico").mix(["table-cont-clock-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use>
                        </svg>
                        {days > 0 ? <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:DaysTimeLeft", {
                            count: days,
                            days: days,
                            hours: (difference.hours() < 10 ? "0" : "") + difference.hours().toString(),
                            minutes: (difference.minutes() < 10 ? "0" : "") + difference.minutes().toString(),
                            seconds: (difference.seconds() < 10 ? "0" : "") + difference.seconds().toString(),
                        } as any)} </span>
                        :
                            <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:ZeroDaysTimeLeft", {
                                hours: (difference.hours() < 10 ? "0" : "") + difference.hours().toString(),
                                minutes: (difference.minutes() < 10 ? "0" : "") + difference.minutes().toString(),
                                seconds: (difference.seconds() < 10 ? "0" : "") + difference.seconds().toString(),
                            } as any)} </span>}
                    </div>
                </div>
            )
        }
        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{i18next.t("common:TimeOver")}</span>
                </div>
            </div>
        )
    }

    public static FileDownloadCellRenderer(props: IVolmaTableProps<{}>, params: IVolmaTableCellRendererParams) {
        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);

        const urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);

        return (
            <div className={(VolmaTableCommonCellRenderers._table("cell", {selected: params.selected })).toString()} style={style} key={key}>
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <a 
                        target="_blank"
                        href={urlFabric.FileDownload(params.cellData)}
                        className={VolmaTableCommonCellRenderers._volmaFile("input", { clear: true, "clear-no-border": true }).mix(["input"]).toString()}>
                            <div className={(VolmaTableCommonCellRenderers._volmaFile("clear")).toString()}>
                                <svg className={VolmaTableCommonCellRenderers._volmaFile("clear-ico").toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                </svg>
                                <span className={VolmaTableCommonCellRenderers._volmaFile("clear-text").toString()}>{i18next.t("instruction:DownloadFile")}</span>
                            </div>
                    </a>
                </div>
            </div>
        );
    }

    public static GetStyle(cellRenderProps: IVolmaTableCellRendererParams) {
        let style:any = {
            width: cellRenderProps.width + "%"
        }
        if (cellRenderProps.height !== undefined)
            style = {
                ...style,
                height: cellRenderProps.height + "px",
                minHeight: cellRenderProps.height + "px",
                maxHeight: cellRenderProps.height + "px",
            }

        return style;
    }

    public static GetKey(cellRenderProps: IVolmaTableCellRendererParams) {
        return cellRenderProps.rowIndex.toString() + "-" + cellRenderProps.cellIndex.toString();
    }

    public static GetTimeService(){
        if (VolmaTableCommonCellRenderers._timeService === undefined)
            VolmaTableCommonCellRenderers._timeService = VolmaContainer.get<TimeService>(Types.TimeService);
        return VolmaTableCommonCellRenderers._timeService;
    }
}