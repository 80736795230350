import { GeoCoordinateDTO } from '../../Domain/DTO/GeoCoordinateDTO';
import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { VolmaFormPropsInitial } from '../VolmaForm/IVolmaFormProps';

export interface IVolmaMapWithSelectDTOProps extends IBaseInputProps<IVolmaMapWithSelectDTOProps> {
    Value: GeoCoordinateDTO
}

export const VolmaMapWithSelectPropsInitial: IVolmaMapWithSelectDTOProps = <IVolmaMapWithSelectDTOProps>{
    ...VolmaFormPropsInitial,
   
};