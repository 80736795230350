import { RatingDTO } from '../../../../Domain/DTO/RatingDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ERating } from '../../../../Domain/Enum/ERating';
import { ERatingType } from '../../../../Domain/Enum/ERatingType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import i18next from '../../../i18n';
import { IBaseTableProps } from '../../../Table/BaseTable/IBaseTableProps';
import {
    IVolmaTableCellRendererParams,
    IVolmaTableColumn,
    IVolmaTableProps
} from '../../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { InitialRatingHelperProps, RatingHelperProps } from './IHelperProps';
import { RatingActions } from './RatingActions';
import { RatingReducer } from './RatingReducer';
import { IRatingTableHelperProps } from './Table/ITableHelperProps';
import { RatingTableActions } from './Table/RatingTableActions';
import { RatingTableReducer } from './Table/RatingTableReducer';
import { injectable } from 'inversify';
import * as React from 'react';
import VolmaTable from '../../../Table/VolmaTable/index';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { RatingDetailsDTO } from '../../../../Domain/DTO/RatingDetailsDTO';
import { VolmaTableCellHeightMeasurers } from '../../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';
import { HTML_BR } from '../../../../Constants/AppConstants';
import { VolmaTableRatingCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableRatingCellRenderers';
import { RouterService } from '../../../../Infrastructure/Router/RouterService';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';

@injectable()
export class RatingEntityService extends BaseEntityService<RatingDTO, RatingHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _reducer: RatingReducer;
    private _action: RatingActions;
    private _tableReducer: RatingTableReducer;
    private _tableActions: RatingTableActions;
    private _routerService: RouterService;

    private _ratingHistoryColumns: Array<IVolmaTableColumn>;
    private _forecastRatingReasonsColumns: Array<IVolmaTableColumn>;    
    private _currentCargoTransporterId: string;

    private _dispatch: any;
    private _id: string;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._reducer           = VolmaContainer.get<RatingReducer>(Types.RatingReducer);
        this._action            = VolmaContainer.get<RatingActions>(Types.RatingActions);
        this._tableActions      = VolmaContainer.get<RatingTableActions>(Types.RatingTableActions);
        this._tableReducer      = VolmaContainer.get<RatingTableReducer>(Types.RatingTableReducer);
        this._routerService     = VolmaContainer.get<RouterService>(Types.RouterService);
        
        this._ratingHistoryColumns = this.GetRatingHistoryColumnsList();
        this._forecastRatingReasonsColumns = this.GetForecastReasonsColumnsList();
    }

    public AlwaysPost(): boolean {
        return true;
    }

    public GetBaseEntity(): EEntityType {
        return EEntityType.Rating;
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.TopCurrentRating]
    }

    public GetRatingHistoryColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Rating,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Timestamp),
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer
                 },
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointName),
                    CellRenderer: (props: IVolmaTableProps<RatingHelperProps>, params: IVolmaTableCellRendererParams) =>
                    VolmaTableRatingCellRenderers.RatingMultiPointNameCellRenderer(props, params, this._routerService.GetCertainEntityPathname(EEntityType.CargoTransporter, this._id), false), 
                },                
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.RatingValue),
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererParams)
                },
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Comments) },
            ]
        );
    }

    public GetForecastReasonsColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.RatingDetails,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RatingDetailsDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingDetailsDTO) => val.Timestamp),
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer
                },
                {
                    DataKey: "Data",
                    CellTextGetter: this.GetForecastDataCellText,
                    CellHeightMeasurer: VolmaTableCellHeightMeasurers.DefaultHeightMeasurer,
                    Weight: 2
                }
            ]
        );
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        if (this._authService.IsCargoTransporter()) {
            return this._volmaTableService.GetColumnsByKeys(
                EEntityType.CurrentRating,
                [
                    { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Id) },
                    { 
                        DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointName),
                        CellRenderer: (props: IVolmaTableProps<RatingHelperProps>, params: IVolmaTableCellRendererParams) =>
                            VolmaTableRatingCellRenderers.RatingMultiPointNameCellRenderer(props, params, this._routerService.GetCertainEntityPathname(EEntityType.CargoTransporter, this._id)), 
                    },
                    {
                        DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.RatingValue),
                        CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                            VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererParams)
                    },
                    {
                        DataKey: "Forecast",
                        CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                            VolmaTableRatingCellRenderers.ForecastRatingRenderer(this._enumService, props, cellRendererParams),
                        IsSortable: false
                    },
                ]
            );
        }
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CurrentRating,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.CargoTransporterName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.CargoTransporter,
                    FilterKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.CargoTransporterId)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    CellRenderer: (props: IVolmaTableProps<RatingHelperProps>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.RatingMultiPointNameCellRenderer(props, params, this._routerService.GetCertainEntityPathname(EEntityType.CargoTransporter, this._id)),
                    Entity: EEntityType.LoadingPoint,
                    FilterKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointId)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.RatingValue),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => ERating,
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererParams)
                },
                {
                    DataKey: "Forecast",
                    CellRenderer: (props: IVolmaTableProps<any>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.ForecastRatingRenderer(this._enumService, props, cellRendererParams),
                    IsSortable: false
                },
            ]
        );
    }

    public OnBeforeTableDataLoaded(props: IBaseTableProps<IRatingTableHelperProps>): void {
        props.dispatch(this._tableActions.LoadForecastRating());
    }

    public GetTableReducer() {
        return this._tableReducer;
    }

    public GetEditorModal(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.MainInfoPart(props))}
                </div>

            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        if(isEditable)
            return this.GetEditableEditor(props)
        return this.GetReadonlyEditor(props);
    }

    public InitializeEntity(props: IBaseEntityProps<RatingDTO, RatingHelperProps>) {
        this._dispatch = props.dispatch;
        if (props.match.params !== undefined){
            let entity = props.match.params.entity;
            let id = props.match.params.id;
            if(entity === EEntityType[EEntityType.CargoTransporter].toLowerCase())
                this._currentCargoTransporterId = id;
            else
                this._currentCargoTransporterId = undefined;
        }

        if(this._authService.IsCargoTransporter())
            this._currentCargoTransporterId = this._authService.GetCurrentUser().CargoTransporterId;

        this._dispatch(this._action.LoadAllLoadingPoints(i18next.t("common:Loading")));
        this._dispatch(this._action.LoadCurrentRating(this._currentCargoTransporterId, i18next.t("common:Loading")));

    }

    public InitializeDefaultDTOValues(dto: RatingDTO) {
        if (this._currentCargoTransporterId !== undefined && this._currentCargoTransporterId !== dto.CargoTransporterId){
            dto.CargoTransporterId = this._currentCargoTransporterId;
        }
        if (ERatingType.Current !== dto.Type){
            dto.Type = ERatingType.Current;
        }
    }


    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return InitialRatingHelperProps;
    }

    public OnAfterDataLoaded(dto: RatingDTO) {
        this._id = dto.Id;

        this._dispatch(this._action.LoadRatingHistory(dto.LoadingPointId, dto.MultiFactoryId, dto.CargoTransporterId, i18next.t("common:Loading")));     
        this._dispatch(this._action.LoadCargoTransporter(dto.CargoTransporterId, i18next.t("common:Loading")));
        this._dispatch(this._action.LoadForecastRating(dto.LoadingPointId, dto.MultiFactoryId, dto.CargoTransporterId, i18next.t("common:Loading")));

        if (dto.LoadingPointId) {
            this._dispatch(this._action.LoadLoadingPoint(dto.LoadingPointId, i18next.t("common:Loading")));
        }        

        if (dto.MultiFactoryId) {
            this._dispatch(this._action.LoadMultiFactory(dto.MultiFactoryId, i18next.t("common:Loading")));
        }
    }
   
    public GetEditableEditor(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("currentrating:MainInfoTitle"), i18next.t("currentrating:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("currentrating:HistoryTitle"), i18next.t("currentrating:HistoryTitleDescription"), this.HistoryPart(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    public GetReadonlyEditor(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("currentrating:MainInfoTitle"), i18next.t("currentrating:MainInfoTitleDescription"), this.ReadonlyMainInfoPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 1, i18next.t("currentrating:HistoryTitle"), i18next.t("currentrating:HistoryTitleDescription"), this.HistoryPart(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }


    private HistoryPart(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()} >
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: RatingHelperProps) => val.RatingHistory) }
                            Name={PropertyHelper.GetPropertyName((x: RatingHelperProps) => x.RatingHistory)}
                            Columns={this._ratingHistoryColumns}
                            Data={props.DataHelper.RatingHistory}
                            DataOriginal={props.DataHelper.RatingHistoryOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div >
        );
    }

    private MainInfoPart(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RatingDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: RatingDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: RatingDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: RatingDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={props.DataDTO.MultiFactoryId || props.DataDTO.LoadingPointId}
                                dispatch={props.dispatch}
                                Required />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RatingDTO) => val.CargoTransporterId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RatingDTO) => x.CargoTransporterId)}
                            Label={i18next.t('currentrating:CargoTransporterId')}
                            Placeholder={i18next.t('currentrating:SelectCargoTransporter')}
                            Entity={EEntityType.CargoTransporter}
                            Required={true}
                            Readonly={this._currentCargoTransporterId !== undefined}
                            Value={props.DataDTO.CargoTransporterId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { full: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: RatingDTO) => val.RatingValue) as any) }
                            Name={PropertyHelper.GetPropertyName((x: RatingDTO) => x.RatingValue)}
                            Label={i18next.t('currentrating:RatingValue')}
                            Placeholder={i18next.t('currentrating:SelectRatingValue')}
                            EnumGetter={() => ERating}
                            Required={true}
                            Value={props.DataDTO.RatingValue}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ReadonlyMainInfoPart(props: IBaseEntityProps<RatingDTO, RatingHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            {!props.DataDTO.MultiFactoryId && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("currentrating:LoadingPointId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.LoadingPoint, x => x.Name)}</dd>
                            </div>}
                            {props.DataDTO.MultiFactoryId && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("currentrating:MultiFactoryId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.MultiFactory, x => x.Name)}</dd>
                            </div>}
                            {this._authService.IsShipper() && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("currentrating:CargoTransporterId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.CargoTransporter, x => x.Name)}</dd>
                            </div>}
                        </dl>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("currentrating:RatingValue")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this._enumService.GetOptionLocalized(() => ERating, PropertyHelper.SafeGetValue(props.DataDTO, x => x.RatingValue))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("currentrating:Forecast")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{props.DataHelper.ForecastRating !== undefined ?
                                    this._enumService.GetOptionLocalized(() => ERating, PropertyHelper.SafeGetValue2(props.DataHelper, x => x.ForecastRating, x => x.RatingValue)) : i18next.t("currentrating:NoRating")}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        );
    }

    private GetForecastDataCellText(props: IVolmaTableProps<any>, rowIndex: number) {
        let dto = props.Data[rowIndex] as RatingDetailsDTO;

        let results = new Array<string>();
        let fieldName = i18next.t("forecastratingdetails:AssignedRequests");
        let fieldValue = dto.AssignedRequests;
        results.push(fieldName + ": " + fieldValue);

        fieldName = i18next.t("forecastratingdetails:RejectedRequests");
        fieldValue = dto.RejectedRequests;
        results.push(fieldName + ": " + fieldValue);

        fieldName = i18next.t("forecastratingdetails:SuccessRequests");
        fieldValue = dto.SuccessRequests;
        results.push(fieldName + ": " + fieldValue);

        fieldName = i18next.t("forecastratingdetails:Success");
        fieldValue = dto.Success;
        results.push(fieldName + ": " + fieldValue);

        return results.join(HTML_BR);
    }

}
