import { BodyTypeTableDTO } from '../../../../Domain/DTO/BodyTypeTableDTO';
import { HandlingTypeTableDTO } from '../../../../Domain/DTO/HandlingTypeTableDTO';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';

export class VehicleHelperProps{
    AllBodyTypes: Array<BodyTypeTableDTO>
    SelectedBodyType: BodyTypeTableDTO
    AllHandlingTypes: Array<HandlingTypeTableDTO>
    SelectedLoadingType: HandlingTypeTableDTO
    SelectedUnloadingType: HandlingTypeTableDTO
    Drivers: Array<DriverTableDTO>
    DriversOriginal: Array<DriverTableDTO>
}

export const InitialVehicleHelperProps: VehicleHelperProps = <VehicleHelperProps>{ AllBodyTypes: [], AllHandlingTypes: [], Drivers: [], DriversOriginal: []};