import * as React from 'react';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { Types } from '../../Infrastructure/Types';
import { EmptyValidator } from '../../Infrastructure/Validation/EmptyValidator';
import { VolmaCheckBoxActions } from './VolmaCheckBoxActions';
import { Component } from 'react';
import { VolmaInputValidator } from '../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaCheckBoxProps } from './IVolmaCheckBoxProps';
import { VolmaCheckBoxReducer} from './VolmaCheckBoxReducer';

class VolmaCheckBox extends Component<IVolmaCheckBoxProps, {}> {
    public props: IVolmaCheckBoxProps;

    private _defaultValidator: EmptyValidator;
    private _inputReducer;

    private _inputActions: VolmaCheckBoxActions;
    private _defaultInput = volmaBlock("volma-checkbox");

    constructor(props: IVolmaCheckBoxProps, context: any) {
        super(props, context);

        this._inputActions     = VolmaContainer.get<VolmaCheckBoxActions>(Types.VolmaCheckBoxActions);
        this._defaultValidator = VolmaContainer.get<EmptyValidator>(Types.EmptyValidator);
        this._defaultValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._inputReducer     = VolmaContainer.get<VolmaCheckBoxReducer>(Types.VolmaCheckBoxReducer);

        this.OnValueChange = this.OnValueChange.bind(this);
    }

    public shouldComponentUpdate(nextProps: IVolmaCheckBoxProps, nextState: any) {
        const shouldUpdate = nextProps.Checked !== this.props.Checked ||
            nextProps.Disabled     !== this.props.Disabled ||
            nextProps.IsTouched    !== this.props.IsTouched ||
            nextProps.IsSubmitted  !== this.props.IsSubmitted ||
            nextProps.ErrorMessage !== this.props.ErrorMessage ||
            nextProps.IsValid      !== this.props.IsValid ||
            nextProps.Autofocus    !== this.props.Autofocus;
        return shouldUpdate;
    }

    public render() {

        let inputProps = {
            type: "checkbox",
            checked: this.props.Checked || false,
            onChange: this.OnValueChange
        }

        return (
            <div className={(this._defaultInput("container")).toString()}>
                <input
                    className={(this._defaultInput("input")).toString()}
                    id={this.props.Id}
                    {...inputProps}
                />
                <label
                    htmlFor={this.props.Id}
                    className={(this._defaultInput("label", {text: true}).mix(["label"])).toString()}
                >
                    {this.props.Label}
                </label>
            </div>
        )
    }

    public componentDidMount() {
        let props: IVolmaCheckBoxProps = {
            Id: this.props.Id,
            Checked: this.props.Checked,
            CustomDataUpdate: this.props.CustomDataUpdate,
            Readonly: this.props.Readonly,
            Required: this.props.Required,
            Disabled: this.props.Disabled,
            IsValid: true
        };

        if (!this.props.SkipRegistration)
            this.props.dispatch(this._inputActions.Register(this.props.Name, this._inputReducer, this.props.Validator || this._defaultValidator, props));
    }

    private OnValueChange(event: any) {
        this.props.dispatch(this._inputActions.ChangeValue(this.props.Name, event.target.checked))
    }
}

export default VolmaCheckBox;