import { VehicleTableDTO } from "../../../../Domain/DTO/VehicleTableDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import PropertyHelper from "../../../../Infrastructure/Services/PropertyHelper";
import { IVolmaTableColumnDefinition } from "../../../Table/VolmaTable/IVolmaTableProps";
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from "../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers";
import { VolmaTableCommonColumnDefinition } from "../../../Table/VolmaTableCommonColumnDefinition";

export class VehicleColumnTemplates {

    public get Id(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.Id),
        };
    }

    public get MultiTransporterName(): IVolmaTableColumnDefinition {
        return VolmaTableCommonColumnDefinition.GetMultiTransporterColumn({
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.CargoTransporterName),
        });
    }

    public get CargoTransporterName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.CargoTransporterName),
            HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
            Entity: EEntityType.CargoTransporter,
            FilterKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.CargoTransporterId)
        };
    }

    public get Name(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.Name),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get VehicleLicensePlateNumber(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.VehicleLicensePlateNumber),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get HandlingTypeName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.HandlingTypeName),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
            CellRenderer: VolmaTableCommonCellRenderers.SafeTextCellRendererWithUndefined,
        };
    }

    public get PayloadName(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.PayloadName),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }

    public get TrailerModel(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.TrailerModel),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }
    
    public get TrailerLicensePlateNumber(): IVolmaTableColumnDefinition {
        return {
            DataKey: PropertyHelper.GetPropertyName((val: VehicleTableDTO) => val.TrailerLicensePlateNumber),
            HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
        };
    }
}
