import {
    INPUT_FILE_FILE_INFO_LOADED,
    INPUT_FILE_PROGRESS_UPDATED,
    INPUT_FILE_REMOVE_FILE,
    INPUT_FILE_UPLOAD_ERROR,
    INPUT_FILE_UPLOAD_STARTED,
    INPUT_FILE_UPLOAD_SUCCESSFULLY,
    INPUT_VALUE_CHANGE
} from '../../Constants/AppConstants';
import { EmptyTemporaryFileDTO } from '../../Domain/DTO/EmptyTemporaryFileDTO';
import { FileDTO } from '../../Domain/DTO/FileDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { FileServerInteraction } from '../../Infrastructure/ServerInteraction/FileServerInteraction';
import { Types } from '../../Infrastructure/Types';
import { BaseInputActions } from '../Shared/BaseInputActions';
import { IInputPayload } from '../VolmaInput/Payloads';
import {
    IFileProgressUpdatedPayload,
    IFileUploadErrorPayload,
    IFileUploadStartedPayload,
    IFileUploadSuccessfullyPayload,
    IFileValueChangedPayload
} from './Payloads';
import { CancelTokenSource } from 'axios';
import * as axios from 'axios';
import { injectable } from 'inversify';
import { FileTableDTO } from '../../Domain/DTO/FileTableDTO';
import { IFileInfoLoadedSuccessfullyPayload, IFileFilesChangedPayload } from './Payloads';
import { INPUT_FILE_FILES_CHANGED } from '../../Constants/AppConstants';


@injectable()
export class VolmaFileActions extends BaseInputActions {
    private _fileApi: FileServerInteraction;

    constructor(){
        super();

        this._fileApi = VolmaContainer.get<FileServerInteraction>(Types.FileServerInteraction);
    }

    public ChangeFiles(name: string, files: Array<File>): IActionPayloaded<IFileFilesChangedPayload> {
        return { type: INPUT_FILE_FILES_CHANGED, Payload: { InputName: name, Files: files} };
    }

    public UploadFile(name: string, filename: string, file: File, onFileUploaded: (dto: FileTableDTO) => void) {
        return (dispatch) => {

            let emptyFileDTO = new EmptyTemporaryFileDTO();
            emptyFileDTO.Name = filename;
            this._fileApi.CreateEmptyTemporaryFile(emptyFileDTO)
            .then((response) => {
                
                let cancelToken = (<any>axios).CancelToken;
                let source = cancelToken.source();

                dispatch(this.UploadStarted(name, source));

                let data = <FileDTO>(response.data.Data);
                let formData = new FormData();
                formData.append('file', file);
                this._fileApi.UploadFile(data.Id, formData, source.token, (progress: number) => dispatch(this.ProgressUpdated(name, progress)))
                .then((response) => {
                    let fileTableDTO = <FileDTO>(response.data.Data);
                    onFileUploaded(fileTableDTO);
                    dispatch(this.FileUploaded(name, fileTableDTO));
                    dispatch(this.ValueChanged(name, fileTableDTO.Id));
                    dispatch(super.Validate(name));
                })
                .catch((response) => {
                    dispatch(this.UploadError(name, response));
                    dispatch(super.Validate(name));
                })
            })
            .catch((response) => {
                dispatch(this.UploadError(name, response));
                dispatch(super.Validate(name));
            })
            // upload file
            // update progress
            // set updated
        }
    }

    public RemoveFile(name: string) {
        return (dispatch) => {
            dispatch(this.ValueChanged(name, undefined));
            dispatch({ type: INPUT_FILE_REMOVE_FILE, Payload: { InputName: name} });
            dispatch(super.Validate(name));
        }
    }

    public FileInfoLoaded(name: string, fileDTO: FileTableDTO): IActionPayloaded<IFileInfoLoadedSuccessfullyPayload> {
        return { type: INPUT_FILE_FILE_INFO_LOADED, Payload: { InputName: name, Value: fileDTO } };
    }


    private UploadError(name: string, response: any): IActionPayloaded<IFileUploadErrorPayload>{
        return { type: INPUT_FILE_UPLOAD_ERROR, Payload: { InputName: name, Response: response } };
    }
    
    private UploadStarted(name: string, cancelTokenSource: CancelTokenSource): IActionPayloaded<IFileUploadStartedPayload>{
        return { type: INPUT_FILE_UPLOAD_STARTED, Payload: { InputName: name, CancelTokenSource: cancelTokenSource} };
    }
    
    private FileUploaded(name: string, fileDTO: FileDTO): IActionPayloaded<IFileUploadSuccessfullyPayload>{
        return { type: INPUT_FILE_UPLOAD_SUCCESSFULLY, Payload: { InputName: name, Value: fileDTO } };
    }
    
    private ValueChanged(name: string, fileId: string): IActionPayloaded<IFileValueChangedPayload>{
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: fileId } };
    }
    
    private ProgressUpdated(name: string, progress: number): IActionPayloaded<IFileProgressUpdatedPayload>{
        return { type: INPUT_FILE_PROGRESS_UPDATED, Payload: { InputName: name, Progress: progress } };
    }
}

