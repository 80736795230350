import { AppReducer } from '../Components/App/AppReducer';
import { BaseEntityReducer } from '../Components/Entity/BaseEntity/BaseEntityReducer';
import { ReportComponentReducer } from '../Components/Entity/Report/ReportComponentReducer';
import { SettingsReducer } from '../Components/Entity/Settings/SettingsReducer';
import { LoginReducer } from '../Components/Login/LoginReducer';
import { PasswordResetReducer } from '../Components/PasswordReset/PasswordResetReducer';
import { PasswordRestoreReducer } from '../Components/PasswordRestore/PasswordRestoreReducer';
import { RegisterReducer } from '../Components/Register/RegisterReducer';
import { BaseTableReducer } from '../Components/Table/BaseTable/BaseTableReducer';
import { VolmaModalReducer } from '../Components/VolmaModal/VolmaModalReducer';
import { IGlobalState } from './IGlobalState';
import { combineReducers } from 'redux';
import { HeaderReducer } from '../Components/Header/HeaderReducer';
import { PortalConfigurationReducer } from '../Components/Entity/PortalConfiguration/PortalConfigurationReducer';
import { AboutReducer } from '../Components/Entity/About/AboutReducer';
import { SearchComponentReducer } from '../Components/Entity/Search/SearchComponentReducer';
import { DriverMonitoringComponentReducer } from '../Components/Entity/DriverMonitoring/DriverMonitoringComponentReducer';

const globalState: IGlobalState = <IGlobalState>{
    App: AppReducer.Initialize(),
    Login: LoginReducer.Initialize(),
    Register: RegisterReducer.Initialize(),
    PasswordRestore: PasswordRestoreReducer.Initialize(),
    PasswordReset: PasswordResetReducer.Initialize(),
    ModalEntity: VolmaModalReducer.Initialize(),
    BaseTable: BaseTableReducer.Initialize(),
    BaseEntity: BaseEntityReducer.Initialize(),
    Settings: SettingsReducer.Initialize(),
    About: AboutReducer.Initialize(),
    Report: ReportComponentReducer.Initialize(),
    Header: HeaderReducer.Initialize(),
    Search: SearchComponentReducer.Initialize(),
    PortalConfiguration: PortalConfigurationReducer.Initialize(),
    DriverMonitoring:  DriverMonitoringComponentReducer.Initialize(),
};

export default combineReducers(<any>globalState);