import { injectable } from 'inversify';
import {
    REPORT_CHART_ADDED,
    REPORT_CHART_DELETED,
    REPORT_CHART_UPDATED,
    REPORT_EXCEL_FILE_ID_UPDATED,
    REPORT_REPORT_CHARTS_LOADED,
    REPORT_REPORT_TYPE_UPDATED
} from '../../../Constants/AppConstants';
import { ReportParametersDTO } from '../../../Domain/DTO/ReportParametersDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IReportHelperProps } from './IHelperProps';
import { IReportProps } from './IReportProps';
import { IReportChartPayload, IUpdateReportTypePayload } from './Payloads';
import { ReportDTO } from './ReportDTO';
import { ReportParametersExtendedDTO } from './ReportParametersExtendedDTO';


@injectable()
export class ReportReducer extends EntityReducer<ReportDTO, IReportHelperProps<any>> {

    public Reduce(state: IReportProps, action: IActionPayloaded<any>): IReportProps {
        switch (action.type) {
            case REPORT_REPORT_CHARTS_LOADED:
                return this.ReduceReportChartsLoaded(state, action.Payload);
            case REPORT_CHART_ADDED:
                return this.ReduceReportChartAdded(state, action.Payload);
            case REPORT_CHART_UPDATED:
                return this.ReduceReportChartUpdated(state, action.Payload);
            case REPORT_CHART_DELETED:
                return this.ReduceReportChartDeleted(state, action.Payload);
            case REPORT_REPORT_TYPE_UPDATED:
                return this.ReduceReportTypeUpdated(state, action.Payload);
            case REPORT_EXCEL_FILE_ID_UPDATED:
                return this.ReduceExcelFileIdUpdated(state, action.Payload);
            default:
                return state;
        }
    }

    // charts collection modifier methods
    private ReduceReportChartsLoaded(state: IReportProps, payload: Array<ReportParametersExtendedDTO>): IReportProps {
        return super.CollectionLoaded(state, payload, [x => x.ReportCharts]);
    }

    private ReduceReportChartAdded(state: IReportProps, payload: IReportChartPayload): IReportProps {
        let newState = this.GetStateForReportChart(state);

        newState.DataHelper.ReportCharts.unshift(payload.ChartData);
        return newState;
    }

    private ReduceReportChartUpdated(state: IReportProps, payload: IReportChartPayload): IReportProps {
        let newState = this.GetStateForReportChart(state);

        let index = this.GetReportChartIndex(newState, payload.ChartData);
        newState.DataHelper.ReportCharts[index] = payload.ChartData;
        return newState;
    }

    private ReduceReportChartDeleted(state: IReportProps, payload: IReportChartPayload): IReportProps {
        let newState = this.GetStateForReportChart(state);

        let index = this.GetReportChartIndex(newState, payload.ChartData);
        newState.DataHelper.ReportCharts.splice(index, 1);
        return newState;
    }
    // end of charts collection modifier methods

    // update report type
    private ReduceReportTypeUpdated(state: IReportProps, payload: IUpdateReportTypePayload): IReportProps {
        let newState = {...state, DataHelper: {...state.DataHelper}};
        if (PropertyHelper.IsArray(state.Inputs)){
            newState.Inputs = state.Inputs.slice(0);
            for (let i = 0; i < newState.Inputs.length; i++){
                newState.Inputs[i].IsValid = true;
            }
        }
        if (PropertyHelper.IsArray(state.Selects)){
            newState.Selects = state.Selects.slice(0);
            for (let i = 0; i < newState.Selects.length; i++){
                newState.Selects[i].IsValid = true;
            }
        }
        newState.DataHelper.ReportService = payload.ReportService;
        newState.DataHelper.ReportParams = payload.ParamsDTO;
        newState.DataHelper.SelectedReportType = payload.ReportType;
        newState.DataHelper.IsExcelReport = payload.IsExcelReport;

        return newState;
    }

    private ReduceExcelFileIdUpdated(state: IReportProps, payload: string): IReportProps {
        let newState = {...state, DataHelper: {...state.DataHelper}};
        newState.DataHelper.ExcelFileId = payload;

        return newState;
    }

    // charts collection modifier helpers
    private GetStateForReportChart(state: IReportProps): IReportProps {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        if (newState.DataHelper.ReportCharts === undefined)
            newState.DataHelper.ReportCharts = [];
        else
            newState.DataHelper.ReportCharts = newState.DataHelper.ReportCharts.slice(0);

        return newState;
    }

    private GetReportChartIndex(state: IReportProps, dto: ReportParametersDTO) {
        let index = state.DataHelper.ReportCharts.findIndex(x => x.Id === dto.Id);
        if (index < 0)
            throw new Error('failed to find payload [' + JSON.stringify(dto) + '] in the array ' + JSON.stringify(state.DataHelper.ReportCharts));

        return index;
    }
    // end of charts collection modifier helpers
}
