import { DRIVER_VEHICLES_LOADED, DRIVER_VEHICLE_ADDED_TO_DRIVER, DRIVER_VEHICLE_REMOVED_FROM_DRIVER } from '../../../../Constants/AppConstants';
import { ContractTableDTO } from '../../../../Domain/DTO/ContractTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { injectable } from 'inversify';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';

@injectable()
export class ContractActions extends EntityAction{


    public LoadContracts(id: string, waitingText: string){
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            id,
            EEntityType.Contract,
            (x: ContractTableDTO) => x.EndTime,
            ESortDir.Desc,
            waitingText,
            this.VehiclesLoaded);
    }

    public VehicleAddedToDriver(value: ContractTableDTO): IActionPayloaded<ContractTableDTO>{
        return { type: DRIVER_VEHICLE_ADDED_TO_DRIVER, Payload: value};
    }

    public VehicleRemovedFromDriver(value: string[]): IActionPayloaded<Array<string>>{
        return { type: DRIVER_VEHICLE_REMOVED_FROM_DRIVER, Payload: value};
    }

    private VehiclesLoaded(value: Array<ContractTableDTO>): IActionPayloaded<Array<ContractTableDTO>> {
        return { type: DRIVER_VEHICLES_LOADED, Payload: value };
    }
}