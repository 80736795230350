import { injectable } from "inversify";
import { DRIVER_MONITORING_DELIVERIES_LOADED, DRIVER_MONITORING_DRIVERS_LOADED, DRIVER_MONITORING_DRIVERS_SELECTED, DRIVER_MONITORING_FILTERS_CHANGED, DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED, DRIVER_MONITORING_SELECTED_ROW } from '../../../Constants/AppConstants';
import { DeliveryTableDTO } from '../../../Domain/DTO/DeliveryTableDTO';
import { DriverTableDTO } from "../../../Domain/DTO/DriverTableDTO";
import { FilterDTO } from "../../../Domain/DTO/FilterDTO";
import { IActionPayloaded } from "../../../Infrastructure/Action/IAction";
import { IVolmaTableToggleSelectionPayload } from "../../Table/VolmaTable/Payload";
import { EntityReducer } from "../BaseEntity/EntityReducer";
import { IDriverMonitoringProps } from "./IDriverMonitoringProps";
import { DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps, MultiTransporterTableDTO } from './IHelperProps';

type IState = IDriverMonitoringProps;
@injectable()
export class DriverMonitoringReducer extends EntityReducer<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps> { 
    public Reduce(state: IState, action: IActionPayloaded<any>): IState {   
        switch (action.type) {
            case DRIVER_MONITORING_MULTI_TRANSPORTERS_SELECTED:
                return this.ReduceMultiTransporterSelected(state, action.Payload);
            case DRIVER_MONITORING_DRIVERS_LOADED:
                return this.ReduceDriversLoaded(state, action.Payload);
            case DRIVER_MONITORING_DRIVERS_SELECTED:
                return this.ReduceDriversSelected(state, action.Payload);
            case DRIVER_MONITORING_DELIVERIES_LOADED:
                return this.ReduceDeliveriesLoaded(state, action.Payload);            
            case DRIVER_MONITORING_SELECTED_ROW:
                return this.ReduceDriverOnRowSelected(state, action.Payload);            
            case DRIVER_MONITORING_FILTERS_CHANGED:
                return this.ReduceDriverMonitoringFiltersChanged(state, action.Payload);            
            default:
                return state;
        }
    }

    private ReduceMultiTransporterSelected(state: IState, payload: Array<MultiTransporterTableDTO>): IState {
        const newState = super.CollectionLoaded<MultiTransporterTableDTO>(state, payload, [x => x.SelectedMultiTransporters]);
        
        const filteredSelectedDrivers = newState.DataHelper.SelectedDrivers?.filter(driver => 
            payload.find(transporter => transporter.Id === driver.CargoTransporterId || transporter.Id === driver.MultiCargoTransporterId));
        
        return { ...newState, DataHelper: { ...newState.DataHelper, SelectedDrivers: filteredSelectedDrivers } };
    }   

    private ReduceDriversLoaded(state: IState, payload: Array<DriverTableDTO>): IState {
        return super.CollectionLoaded<DriverTableDTO>(state, payload, [x => x.DriversForSelectedMultiTransporters]);
    }

    private ReduceDriversSelected(state: IState, payload: Array<DriverTableDTO>): IState {
        return super.CollectionLoaded<DriverTableDTO>(state, payload, [x => x.SelectedDrivers]);
    }
    
    private ReduceDeliveriesLoaded(state: IState, payload: Array<DeliveryTableDTO>): IState {       
        const payloadWithNumbers = payload.map((delivery: DeliveryTableDTO, i) => (
            {
                ...delivery, 
                OrderNumber: i + 1, 
                LocationUpdateDate: new Intl.DateTimeFormat('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })
                    .format(new Date(delivery.LocationUpdateDate)) as any,
            }
        )).filter(d => d.Latitude && d.Longitude);
        
        const newState = super.CollectionLoaded<DeliveryDriverMonitoringDTO>(state, payloadWithNumbers, [x => x.Deliveries, x => x.DeliveriesOriginal]);
        
        newState.DataDTO = {...newState.DataDTO, Identifier: newState.DataDTO?.Identifier ?? ""}
        
        return newState;
    }

    private ReduceDriverOnRowSelected(state: IState, payload: IVolmaTableToggleSelectionPayload): IState {
        const newState = { ...state };

        const selectedDelivery = newState.DataHelper.Deliveries.find((delivery) => delivery.Id === payload.Id);
        
        newState.DataHelper = {
            ...newState.DataHelper,
            SelectedDelivery: { location: { lat: selectedDelivery.Latitude, lng: selectedDelivery.Longitude }, id: selectedDelivery.Id },
        };

        newState.DataDTO = {...newState.DataDTO, Identifier: newState.DataDTO.Identifier ?? ""};
        
        return newState;
    }
    
    private ReduceDriverMonitoringFiltersChanged(state: IState, payload: FilterDTO[]): IState {
        return super.EntityLoaded<FilterDTO[]>(state, payload, x => x.DeliveryMonitoringFilters);
    }
}