import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { UpdatePasswordDTO } from '../../../Domain/DTO/UpdatePasswordDTO';
import { IEntityReducer } from '../BaseEntity/IEntityReducer';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import i18next from '../../i18n';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaInput from '../../VolmaInput';
import * as React from 'react';
import { injectable } from 'inversify';



@injectable()
export class UpdatePasswordEntityService extends BaseEntityService<UpdatePasswordDTO, {}>{
    private _volmaTableService: VolmaTableService;
    private _inputValidator: BaseValidator<IVolmaInputProps>;

    constructor() {
        super();

        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<UpdatePasswordDTO, {}>): JSX.Element {
        return undefined;
    }

    public GetEditorModal(props: IBaseEntityProps<UpdatePasswordDTO, {}>): JSX.Element {
        return this.GetEditorInternal(props);
    }

    public GetEditor(props: IBaseEntityProps<UpdatePasswordDTO, {}>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<UpdatePasswordDTO, {}>): void {
        return undefined;
    }

    public OnAfterDataLoaded(dto: UpdatePasswordDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<UpdatePasswordDTO, {}> {
        return undefined;
    }

    public GetInitialDataHelper(): Object {
        return {};
    }

    private GetEditorInternal(props: IBaseEntityProps<UpdatePasswordDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent).toString()}>
                    {this.GetMainInfoNoTitleItem(true, false, this.PasswordsPart(props))}
                </div>
            </div>
        );
    }

    private PasswordsPart(props: IBaseEntityProps<UpdatePasswordDTO, {}>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: UpdatePasswordDTO) => val.CurrentPassword) }
                            Name={PropertyHelper.GetPropertyName((val: UpdatePasswordDTO) => val.CurrentPassword)}
                            Type="password"
                            Label={i18next.t('updatepassword:CurrentPassword')}
                            Value={props.DataDTO.CurrentPassword}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: UpdatePasswordDTO) => val.NewPassword) }
                            Name={PropertyHelper.GetPropertyName((val: UpdatePasswordDTO) => val.NewPassword)}
                            Type="password"
                            Label={i18next.t('updatepassword:NewPassword')}
                            Value={props.DataDTO.NewPassword}
                            Required={true}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}