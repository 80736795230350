import { LatLngLiteral } from "leaflet";
import { CargoTransporterTableDTO } from "../../../Domain/DTO/CargoTransporterTableDTO";
import { DeliveryDTO } from "../../../Domain/DTO/DeliveryDTO";
import { DeliveryTableDTO } from "../../../Domain/DTO/DeliveryTableDTO";
import { DriverDTO } from "../../../Domain/DTO/DriverDTO";
import { FilterDTO } from "../../../Domain/DTO/FilterDTO";
import { MultiCargoTransporterTableDTO } from "../../../Domain/DTO/MultiCargoTransporterTableDTO";
import PropertyHelper from "../../../Infrastructure/Services/PropertyHelper";

export type MultiTransporterTableDTO = CargoTransporterTableDTO | MultiCargoTransporterTableDTO;

export type DeliveryDriverMonitoringDTO = DeliveryTableDTO & {
    OrderNumber?: number;
};

export type SelectedDelivery =
    | {
          location: LatLngLiteral;
          id: string;
      }
    | undefined;

export type DeliveryMonitoringQuery = {
    cargoTransporter: string;
    multiCargoTransporter: string;
    driver: string;
    identifier: string;
};

export const MultiTransporterDtoFields = [
    PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.CargoTransporterId),
    PropertyHelper.GetPropertyName((val: DeliveryDTO) => val.MultiCargoTransporterId),
];
export interface DriverMonitoringHelperProps {
    Deliveries: Array<DeliveryDriverMonitoringDTO>;
    DeliveriesOriginal: Array<DeliveryDriverMonitoringDTO>;
    SelectedDelivery: SelectedDelivery;
    SelectedMultiTransporters: Array<MultiTransporterTableDTO>;
    DriversForSelectedMultiTransporters: Array<DriverDTO>;
    SelectedDrivers: Array<DriverDTO>;
    DeliveryMonitoringFilters: FilterDTO[];
}

export const DriverMonitoringHelperPropsInitial: DriverMonitoringHelperProps = {
    Deliveries: [],
    DeliveriesOriginal: [],
    SelectedDelivery: undefined,
    SelectedMultiTransporters: [],
    DriversForSelectedMultiTransporters: [],
    SelectedDrivers: [],
    DeliveryMonitoringFilters: [],
};
