import { volmaBlock } from '../../Infrastructure/Services/BEM';
import * as React from 'react';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaFileValidator } from '../../Infrastructure/Validation/VolmaFileValidator';
import { IVolmaFileProps, IVolmaFile, ETagType, VolmaFileData } from './IVolmaFileProps';
import { VolmaFileActions } from './VolmaFileActions';
import { Component } from 'react';
import { VolmaFileReducer } from './VolmaFileReducer';
import i18next from '../i18n';
import { FileTableDTO } from '../../Domain/DTO/FileTableDTO';
import { FileServerInteraction } from '../../Infrastructure/ServerInteraction/FileServerInteraction';
import { ApiActions } from '../Shared/ApiActions';
import { UrlFabric } from "../../Infrastructure/ServerInteraction/UrlFabric";
import { ECQRSApi } from '../../Infrastructure/ServerInteraction/ECQRSApi';
import { FileDTO } from '../../Domain/DTO/FileDTO';

class VolmaFile extends Component<IVolmaFileProps, { FileData: FileTableDTO, IsTemporary: boolean}> {
    private _defaultValidator: VolmaFileValidator = VolmaContainer.get<VolmaFileValidator>(Types.VolmaFileValidator);
    private _fileActions: VolmaFileActions;
    private _fileReducer: VolmaFileReducer;
    private _fileInput;
    private _fileApi: FileServerInteraction;
    private _filesDataCache: Array<FileTableDTO>
    private _apiActions: ApiActions;
    private _urlFabric: UrlFabric;
    private _fileData;

    private _defaultInputError = volmaBlock('default-input-error');
    private _volmaFile         = volmaBlock('volma-file');

    constructor(props: IVolmaFileProps, context: any) {
        super(props, context);

        this._fileActions = VolmaContainer.get<VolmaFileActions>(Types.VolmaFileActions);
        this._fileReducer = VolmaContainer.get<VolmaFileReducer>(Types.VolmaFileReducer);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);


        this._fileApi = VolmaContainer.get<FileServerInteraction>(Types.FileServerInteraction);
        this._filesDataCache = [];
        this.state = { FileData: undefined, IsTemporary: false};
    }

    render() {
        let hasErrors = !this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted);
        let errors = (hasErrors && !this.props.FileData.IsFileUploading) ?
            <div className={(this._defaultInputError()).toString()}>
                <span className={(this._defaultInputError("text")).toString()}>{this.props.ErrorMessage}</span>
            </div>
            :
            undefined;

        let fileInfo = this.GetFileInfo(this.props.Value);
        return (
            <div>
                <div className={(this._volmaFile).toString()}>
                    {this.props.Readonly !== true && <input className={(this._volmaFile("input")).toString()} ref={(input) => { this._fileInput = input; }} type="file" accept={this.props.FilesFilter} onChange={this.OnFileChanged.bind(this)} />}
                    {
                        // normal view with name and download buttons
                        (fileInfo !== undefined) &&
                        <label className={(this._volmaFile("input", {ready: true, readonly: this.props.Readonly}).mix(["input"])).toString()}>
                            <div className={(this._volmaFile("ready")).toString()}>
                                <div className={(this._volmaFile("ready-left")).toString()}>
                                    <span className={(this._volmaFile("ready-name")).toString()}>{this.props.Label}:</span>
                                </div>
                                <div className={(this._volmaFile("ready-right")).toString()}>
                                    <span className={(this._volmaFile("ready-val")).toString()}>{fileInfo.Name}</span>
                                    {this.state.IsTemporary !== true && <a href={this._urlFabric.FileDownload(this.props.Value)} target="_blank">
                                        <svg className={(this._volmaFile("ready-ico")).toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                    </svg></a>}
                                    {this.props.Readonly !== true && <svg className={(this._volmaFile("ready-ico").is({remove: true })).toString()} onClick={this.OnRemoveClicked.bind(this)}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                                    </svg>}
                                </div>
                            </div>
                        </label>
                    }
                    {
                        // file was just chosen, wait for uploading
                        (fileInfo === undefined && this.props.FileData !== undefined && this.props.FileData.File !== undefined && !this.props.FileData.IsFileUploading) &&
                        <label className={(this._volmaFile("input", {ready: true, readonly: this.props.Readonly}).mix(["input"])).toString()}>
                            <div className={(this._volmaFile("ready")).toString()}>
                                <div className={(this._volmaFile("ready-left")).toString()}>
                                    <span className={(this._volmaFile("ready-name")).toString()}>{this.props.Label}:</span>
                                </div>
                                <div className={(this._volmaFile("ready-right")).toString()}>
                                    <span className={(this._volmaFile("ready-val")).toString()}>{this.props.FileData.File.FileName}</span>
                                    <svg className={(this._volmaFile("ready-ico").is({upload: true })).toString()} onClick={this.OnUploadClicked.bind(this)}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload-up"></use>
                                    </svg>
                                    <svg className={(this._volmaFile("ready-ico").is({remove: true })).toString()} onClick={this.OnRemoveClicked.bind(this)}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                                    </svg>
                                </div>
                            </div>
                        </label>
                    }
                    {
                        // no stored file, no chosen file, show blue box for browse new file
                        (fileInfo === undefined && (this.props.FileData === undefined || this.props.FileData.File === undefined)) &&
                        <div className={(this._volmaFile("input", {clear: true }).mix([hasErrors ? "error" : undefined, "input"])).toString()} onClick={this.OnBrowseClicked.bind(this)} onDragOver={(e) => e.preventDefault()} onDrop={this.OnFileDrop.bind(this)}>
                            <div className={(this._volmaFile("clear")).toString()}>
                                <svg className={(this._volmaFile("clear-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#upload"></use>
                                </svg>
                                <span className={(this._volmaFile("clear-text")).toString()}>{i18next.t("file:LabelFileDropFile")}</span>
                            </div>
                        </div>
                    }
                    {
                        // file uploading
                        (fileInfo === undefined && this.props.FileData !== undefined && this.props.FileData.IsFileUploading) &&
                        <div className={(this._volmaFile("input", {upload: true }).mix("input")).toString()}>
                            <div className={(this._volmaFile("upload")).toString()}>
                                <div className={(this._volmaFile("upload-left")).toString()}>
                                    <span className={(this._volmaFile("upload-name")).toString()}>{this.props.Label}:</span>
                                    <span className={(this._volmaFile("upload-val")).toString()}>{i18next.t("file:LoadingFile")}{" "}
                                        <span className={(this._volmaFile("upload-val-text")).toString()}>{this.props.FileData.File.FileName}</span>
                                    </span>
                                </div>
                                <div className={(this._volmaFile("upload-right")).toString()}>
                                    <span className={(this._volmaFile("upload-progress")).toString()}>{this.props.FileData.ProgressDTO.Progress + '%'}</span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {errors}
            </div>
        );
    }

    public componentDidMount() {
        let props: IVolmaFileProps = {
            Value: this.props.Value,
            FilesFilter: this.props.FilesFilter,
            OnFileChanged: this.props.OnFileChanged || ((dto: FileTableDTO) => null),
            OnFileDeleted: this.props.OnFileDeleted || ((fileId: string) => null),
            FileData: new VolmaFileData(),
            CustomDataUpdate: this.props.CustomDataUpdate,
            Required: this.props.Required,
            Disabled: this.props.Disabled
        }

        this.props.dispatch(this._fileActions.Register(this.props.Name, this._fileReducer, this.props.Validator || this._defaultValidator, props));
        this.props.dispatch(this._fileActions.Validate(this.props.Name));
    }

    private OnFileChanged(event: any) {
        if(this.props.Readonly === true)
            return;
        this.MarkTouched();
        let files: Array<File> = event.files || event.target.files || event.dataTransfer.files;
        this.props.dispatch(this._fileActions.ChangeFiles(this.props.Name, files));
        this.props.dispatch(this._fileActions.Validate(this.props.Name));
        this.props.OnFileDeleted(this.props.Value);
    }

    private OnFileDrop(event: Event) {
        if (this.props.Readonly === true)
            return;
        event.preventDefault();
        this.OnFileChanged(event);
    }

    private OnDownloadClicked() {
    }

    private OnUploadClicked() {
        if (this.props.Readonly === true)
            return;
        this.MarkTouched();
        this.props.dispatch(this._fileActions.UploadFile(this.props.Name, this.props.FileData.File.FileName, this.props.FileData.File.File,
        ((dto: FileTableDTO) => {
            this._filesDataCache.push(dto);
            this.props.OnFileChanged(dto)
            this.setState({ IsTemporary: true, FileData: undefined})
        }).bind(this)));
    }

    private OnAbortdClicked() {
        if (this.props.Readonly === true)
            return;
        this.MarkTouched();
        this.props.FileData.CancelTokenSource.cancel(i18next.t('file:UploadCanceledByTheUser'));
    }

    private OnRemoveClicked() {
        if (this.props.Readonly === true)
            return;
        this.MarkTouched();
        this._fileInput.value = '';
        this.props.dispatch(this._fileActions.RemoveFile(this.props.Name));
    }

    private OnBrowseClicked(event: Event) {
        if (this.props.Readonly === true)
            return;
        this.MarkTouched();
        this._fileInput.click();
    }

    private MarkTouched() {
        if (!this.props.IsTouched)
            this.props.dispatch(this._fileActions.Touched(this.props.Name))
    }

    private GetFileInfo(fileId: string){
        if (fileId === undefined || fileId == null)
            return undefined;

        let info = this._filesDataCache.find(x => x.Id === fileId);
        if(info === undefined){
            let emptyInfo = new FileTableDTO();
            emptyInfo.Id = fileId;
            this._filesDataCache.push(emptyInfo);

            // since it is called from render method and DoApiRequest changes props, thus, it timeout is needed
            // load data from server
            setTimeout(() => {
                this._apiActions.DoApiRequest(
                    this.props.dispatch,
                    () => this._fileApi.GetFileInfo(fileId),
                    i18next.t("common:Loading"),
                    (response) => {
                        let dto = JSON.parse(response.data);
                        let index = this._filesDataCache.findIndex(x => x.Id === fileId);
                        if (index >= 0) {
                            if (dto.Id === fileId){
                                this._filesDataCache.splice(index, 1, dto);
                                this.props.dispatch(this._fileActions.FileInfoLoaded(this.props.Name, dto))
                                this.setState({ FileData: dto, IsTemporary: this.state.IsTemporary});
                            }
                            else
                            {
                                throw new Error('file info downloaded but file id differs from expected value. Expected id ' + fileId + " obtained dto: " + JSON.stringify(dto))
                            }
                        }
                    }
                )
            });
            return emptyInfo;
        }
        return info
    }
}

export default VolmaFile;