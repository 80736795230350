import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputActions } from '../Shared/BaseInputActions';
import { IBooleanValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
export class VolmaCheckBoxActions extends BaseInputActions{

    public ChangeValue(name: string, value: boolean): IActionPayloaded<IBooleanValueChangedPayload> {
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: value} };
    }
}

