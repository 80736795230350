import {
    VOLMA_SIGNALR_BIDDING_BET_SETTED,
    VOLMA_SIGNALR_CARGO_TRANSPORTER_CHANGED,
    VOLMA_SIGNALR_COMPLAINT_ADDED,
    VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED,
    VOLMA_SIGNALR_COMPLAINT_COMMENT_UPDATED,
    VOLMA_SIGNALR_COMPLAINT_UPDATED,
    VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED,
    VOLMA_SIGNALR_DELIVERY_STATE_UPDATED,
    VOLMA_SIGNALR_ENTITY_ADDED,
    VOLMA_SIGNALR_ENTITY_UPDATED,
    VOLMA_SIGNALR_TENDER_BET_SETTED,
    VOLMA_SIGNALR_TENDER_BIDDING_END,
    VOLMA_SIGNALR_TENDER_BIDDING_STARTED
} from '../../Constants/AppConstants';
import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintDTO } from '../../Domain/DTO/ComplaintDTO';
import { DeliveryStateUpdatedDTO } from '../../Domain/DTO/DeliveryStateUpdatedDTO';
import { EntityAddedDTO } from '../../Domain/DTO/EntityAddedDTO';
import { EntityUpdatedDTO } from '../../Domain/DTO/EntityUpdatedDTO';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { TenderBiddingStateChangedDTO } from '../../Domain/DTO/TenderBiddingStateChangedDTO';
import { DeliveryLocationUpdateDTO } from '../../Domain/DTO/DeliveryLocationUpdateDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IActionPayloaded } from '../Action/IAction';
import { VolmaContainer } from '../InversifyInject';
import { SignalRService } from '../Services/SignalRService';
import { Types } from '../Types';
import { injectable } from 'inversify';
import { AuthenticationService } from '../Services/AuthService';

@injectable()
export class HubProxyActions {
    private _signalRService: SignalRService;
    private _authService   : AuthenticationService;

    constructor() {
        this._signalRService = VolmaContainer.get<SignalRService>(Types.SignalRService);
        this._authService    = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
    }

    public RegisterListners(proxy, dispatch){
        proxy.on('DeliveryLocationUpdated', (dto: DeliveryLocationUpdateDTO) => {
            const action: IActionPayloaded<DeliveryLocationUpdateDTO> = {type: VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED, Payload: dto};
            dispatch(action);
            this._signalRService.DeliveryLocationUpdated.Trigger(dto);
        })

        proxy.on('EntityAdded', (dto: EntityAddedDTO) => {
            const action: IActionPayloaded<EntityAddedDTO> = {type: VOLMA_SIGNALR_ENTITY_ADDED, Payload: dto};
            dispatch(action);
            this._signalRService.EntityAdded.Trigger(dto);
        })

        proxy.on('EntityUpdated', (dto: EntityUpdatedDTO) => {
            const action: IActionPayloaded<EntityUpdatedDTO> = {type: VOLMA_SIGNALR_ENTITY_UPDATED, Payload: dto};
            dispatch(action);
            this._signalRService.EntityUpdated.Trigger(dto);
        })

        proxy.on('DeliveryUpdated', (dto: DeliveryStateUpdatedDTO) => {
            const action: IActionPayloaded<DeliveryStateUpdatedDTO> = { type: VOLMA_SIGNALR_DELIVERY_STATE_UPDATED, Payload: dto};
            dispatch(action);
        })

        proxy.on('TenderBetSetted', (dto: TenderBetDTO) => {
            const action: IActionPayloaded<TenderBetDTO> = { type: VOLMA_SIGNALR_TENDER_BET_SETTED, Payload: dto };
            dispatch(action);
            this._signalRService.EntityUpdated.Trigger({ Type: EEntityType.Tender, DTO: {} } as EntityUpdatedDTO);
            this._signalRService.TenderBetSetted.Trigger(dto);
        })

        proxy.on('BiddinggBetSetted', (dto: BiddingBetDTO) => {
            const action: IActionPayloaded<BiddingBetDTO> = { type: VOLMA_SIGNALR_BIDDING_BET_SETTED, Payload: dto };
            dispatch(action);
            this._signalRService.EntityUpdated.Trigger({ Type: EEntityType.Delivery, DTO: {} } as EntityUpdatedDTO);
            this._signalRService.BiddingBetSetted.Trigger(dto);
        })

        proxy.on('TenderBiddingStarted', (dto: TenderBiddingStateChangedDTO) => {
            const action = { type: VOLMA_SIGNALR_TENDER_BIDDING_STARTED, Payload: dto };
            dispatch(action);
            this._signalRService.EntityUpdated.Trigger({ Type: EEntityType.Tender, DTO: {Id: dto.TenderId} } as EntityUpdatedDTO);
        })

        proxy.on('TenderBiddingEnd', (dto: TenderBiddingStateChangedDTO) => {
            const action = { type: VOLMA_SIGNALR_TENDER_BIDDING_END, Payload: dto };
            dispatch(action);
            this._signalRService.EntityUpdated.Trigger({ Type: EEntityType.Tender, DTO: {Id: dto.TenderId} } as EntityUpdatedDTO);
        })

        proxy.on('ComplaintAdded', (dto: ComplaintDTO) => {
            const action: IActionPayloaded<ComplaintDTO> = { type: VOLMA_SIGNALR_COMPLAINT_ADDED, Payload: dto };
            dispatch(action);
            this._signalRService.ComplaintAdded.Trigger(dto);
        })

        proxy.on('ComplaintUpdated', (dto: ComplaintDTO, showNotification: boolean) => {
            if (showNotification){
                const action: IActionPayloaded<ComplaintDTO> = { type: VOLMA_SIGNALR_COMPLAINT_UPDATED, Payload: dto };
                dispatch(action);
            }
            this._signalRService.ComplaintUpdated.Trigger(dto);
        })

        proxy.on('ComplaintCommentAdded', (dto: ComplaintCommentDTO) => {
            const action: IActionPayloaded<ComplaintCommentDTO> = { type: VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED, Payload: dto };
            dispatch(action);
            this._signalRService.ComplaintCommentAdded.Trigger(dto);
        })

        proxy.on('ComplaintCommentUpdated', (dto: ComplaintCommentDTO) => {
            const action: IActionPayloaded<ComplaintCommentDTO> = { type: VOLMA_SIGNALR_COMPLAINT_COMMENT_UPDATED, Payload: dto };
            dispatch(action);
            this._signalRService.ComplaintCommentUpdated.Trigger(dto);
        })

        proxy.on('CargoTransporterChanged', () => {
            const action: IActionPayloaded<void> = { type: VOLMA_SIGNALR_CARGO_TRANSPORTER_CHANGED };
            dispatch(action);
            this._signalRService.CargoTransporterChanged.Trigger();
        })
    }
}