
import { ReportDTO } from '../ReportDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IReportHelperProps } from '../IHelperProps';
import { ReportService } from './ReportService';
import { injectable } from 'inversify';
import * as React from 'react';
import VolmaSelect from '../../../VolmaSelect/VolmaSelect';
import { ComplaintsReportParametersDTO } from '../../../../Domain/DTO/ComplaintsReportParametersDTO';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import { EComplaintTemplateDirection } from '../../../../Domain/Enum/EComplaintTemplateDirection';
import { SafeMergeArrays } from '../../../../Infrastructure/Services/Utils';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { VolmaMultiPointSelect } from '../../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import { VolmaMultiTransporterSelect } from '../../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';

@injectable()
export class ReportComplaintsExcelService extends ReportService<ComplaintsReportParametersDTO>{
    public Title: string = "ReportComplaintsExcelService";
    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<ComplaintsReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintsReportParametersDTO) => val.LoadingPointIds) as any) }
                                Name={PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.LoadingPointIds)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.LoadingPointIds), 
                                    PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.LoadingPointIds, props.DataHelper.ReportParams.MultiFactoryId])}
                                AllowMultiselect
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    {this._authService.IsShipper() && <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiTransporterSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintsReportParametersDTO) => val.CargoTransporterIds) as any) }
                                Name={PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.CargoTransporterIds)}
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.CargoTransporterIds), 
                                    PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.MultiCargoTransporterId),
                                ]}
                                OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                                Value={SafeMergeArrays([props.DataHelper.ReportParams.CargoTransporterIds, props.DataHelper.ReportParams.MultiCargoTransporterId])}
                                AllowMultiselect
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} /> 
                        </div>
                    </div>}
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintsReportParametersDTO) => val.States) as any) }
                                Name={PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.States)}
                                Label={i18next.t('report:ComplaintState')}
                                Placeholder={i18next.t('report:SelectComplaintState')}
                                EnumGetter={() => EComplaintState}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.States}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: ComplaintsReportParametersDTO) => val.Direction) as any) }
                                Name={PropertyHelper.GetPropertyName((x: ComplaintsReportParametersDTO) => x.Direction)}
                                Label={i18next.t('report:ComplaintDirection')}
                                Placeholder={i18next.t('report:SelectComplaintDirection')}
                                EnumGetter={() => EComplaintTemplateDirection}
                                AllowMultiselect={false}
                                Required={true}
                                IsSubmitted={true}
                                Value={props.DataHelper.ReportParams.Direction}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintsReportParametersDTO) => val.DateFrom) as any) }
                                Name={PropertyHelper.GetPropertyName((val: ComplaintsReportParametersDTO) => val.DateFrom)}
                                Label={i18next.t('report:LabelDateFrom')}
                                Value={props.DataHelper.ReportParams.DateFrom}
                                DefaultHours={0}
                                DefaultMinutes={0}
                                DefaultSeconds={0}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ComplaintsReportParametersDTO) => val.DateTo) as any) }
                                Name={PropertyHelper.GetPropertyName((val: ComplaintsReportParametersDTO) => val.DateTo)}
                                Label={i18next.t('report:LabelDateTo')}
                                Value={props.DataHelper.ReportParams.DateTo}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}