import { WithTranslationProps } from 'react-i18next/*';

import { RestorePasswordDTO } from '../../Domain/DTO/RestorePasswordDTO';
import { BaseEntityPropsInitial, IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { InitialPasswordRestoreHelperProps, IPasswordRestoreHelperProps } from './IHelperProps';


export interface IPasswordRestoreProps extends IBaseEntityProps<RestorePasswordDTO, IPasswordRestoreHelperProps>, WithTranslationProps {
}

export const PasswordRestorePropsInitial: IPasswordRestoreProps = { ...BaseEntityPropsInitial, DataHelper: InitialPasswordRestoreHelperProps, DataDTO: {}};
