import { injectable } from 'inversify';
import {
    DELIVERY_ADDITION_FILES_LOADED, DELIVERY_BIDDING_BETS_LOADED,
    DELIVERY_BIDDING_WINNER_BETS_LOADED,
    DELIVERY_CARGOTRANSPORTER_LOADED,
    DELIVERY_COMPLAINTS_LOADED,
    DELIVERY_CONSIGNOR_LOADED, DELIVERY_DELIVERY_STATES_LOADED,
    DELIVERY_DRIVER_LOADED, DELIVERY_FIRST_LOADING_POINT_LOADED, DELIVERY_HANDLING_TYPE_LOADED,
    DELIVERY_LOADING_POINT_LOADED,
    DELIVERY_LOCALITY_LOADED,
    DELIVERY_PAYLOAD_LOADED, DELIVERY_PENDING_CHANGES_LOADED, DELIVERY_ROUTE_POINTS_DELETED,
    DELIVERY_ROUTE_POINTS_LOADED, DELIVERY_ROUTE_POINT_ADDED,
    DELIVERY_ROUTE_POINT_UPDATED, DELIVERY_VEHICLE_LOADED, DELIVERY_ZONE_LOADED
} from '../../../Constants/AppConstants';
import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { ComplaintTableDTO } from '../../../Domain/DTO/ComplaintTableDTO';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { DeliveryAdditionalFilesDTO } from '../../../Domain/DTO/DeliveryAdditionalFilesDTO';
import { DeliveryPendingChangesItemDTO } from '../../../Domain/DTO/DeliveryPendingChangesItemDTO';
import { DeliveryStateTableDTO } from '../../../Domain/DTO/DeliveryStateTableDTO';
import { DriverDTO } from '../../../Domain/DTO/DriverDTO';
import { FileDTO } from '../../../Domain/DTO/FileDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { HandlingTypeDTO } from '../../../Domain/DTO/HandlingTypeDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { RouteSegmentDTO } from '../../../Domain/DTO/RouteSegmentDTO';
import { VehicleDTO } from '../../../Domain/DTO/VehicleDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { DeliveryServerInteraction } from '../../../Infrastructure/ServerInteraction/DeliveryServerInteraction';
import { Types } from '../../../Infrastructure/Types';
import i18next from '../../i18n';
import { EntityAction } from '../BaseEntity/EntityAction';

@injectable()
export class DeliveryActions extends EntityAction {
    private _api: DeliveryServerInteraction;

    constructor() {
        super();

        this._api = VolmaContainer.get<DeliveryServerInteraction>(Types.DeliveryServerInteraction);
    }

    public LoadBets(id: string, waitingText: string) {
        return super.LoadChildDataTableItems<BiddingBetTableDTO>(
            EEntityType.Delivery,
            id,
            EEntityType.BiddingBet,
            x => x.Bet,
            ESortDir.Asc,
            waitingText,
            x => this.BetsLoaded(x));
    }

    public LoadWinnersBets(id: string, waitingText: string) {
		return super.LoadChildDataTableItems<BiddingBetTableDTO>(
			EEntityType.Delivery,
			id,
            EEntityType.BiddingWinnerBets,
			x => x.Bet,
            ESortDir.Asc,
            waitingText,
            this.WinnersBetsLoaded);
    }

    public LoadDeliveryStates(id: string, waitingText: string) {
        return super.LoadChildDataTableItems<DeliveryStateTableDTO>(
            EEntityType.Delivery,
            id,
            EEntityType.DeliveryState,
            x => x.OrderNum,
            ESortDir.Desc,
            waitingText,
            x => this.DeliveryStatesLoaded(x));
    }

    public LoadComplaints(id: string, waitingText: string) {
        return super.LoadChildDataTableItems<ComplaintTableDTO>(
            EEntityType.Delivery,
            id,
            EEntityType.Complaint,
            x => x.DateOfLastUpdate,
            ESortDir.Asc,
            waitingText,
            x => this.ComplaintsLoaded(x));
    }

    public LoadVehicle(id: string, waitingText: string) {
        return super.LoadEntityCached<VehicleDTO>(
            id,
            EEntityType.Vehicle,
            waitingText,
            x => this.VehicleLoaded(x));
    }

    public LoadDriver(id: string, waitingText: string) {
        return super.LoadEntityCached<DriverDTO>(
            id,
            EEntityType.Driver,
            waitingText,
            x => this.DriverLoaded(x));
    }

    public LoadCargoTransporter(id: string, waitingText: string) {
        return super.LoadEntityCached<CargoTransporterDTO>(
            id,
            EEntityType.CargoTransporter,
            waitingText,
            x => this.CargoTransporterLoaded(x));
    }

    public LoadConsignor(id: string, waitingText: string) {
        return super.LoadEntityCached<ConsignorDTO>(
            id,
            EEntityType.Consignor,
            waitingText,
            x => this.ConsignorLoaded(x));
    }

    public LoadFile(deliveryId: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.Delivery,
            deliveryId,
            EEntityType.File,
            (x: FileTableDTO) => x.Timestamp,
            ESortDir.Asc,
            waitingText,
            x => this.AdditionFilesLoaded(x));
    }

    public LoadLoadingPoint(id: string, waitingText: string) {
        return super.LoadEntityCached<LoadingPointDTO>(
            id,
            EEntityType.LoadingPoint,
            waitingText,
            x => this.LoadingPointLoaded(x));
    }

    public LoadFirstLoadingPointAndSetTimeZone(loadingPointId: string, waitingText: string) {
        return super.LoadEntityCached<LoadingPointDTO>(
            loadingPointId,
            EEntityType.LoadingPoint,
            waitingText,
            x => this.FirstLoadingPointLoaded(x));
    }

    public LoadLocality(id: string, waitingText: string) {
        return super.LoadEntityCached<LocalityDTO>(
            id,
            EEntityType.Locality,
            waitingText,
            x => this.LocalityLoaded(x));
    }

    public LoadHandlingType(id: string, waitingText: string) {
        return super.LoadEntityCached<HandlingTypeDTO>(
            id,
            EEntityType.HandlingType,
            waitingText,
            x => this.HandlingTypeLoaded(x));
    }

    public LoadPayload(id: string, waitingText: string) {
        return super.LoadEntityCached<PayloadDTO>(
            id,
            EEntityType.Payload,
            waitingText,
            x => this.PayloadLoaded(x));
    }

    public LoadZone(id: string, waitingText: string) {
        return super.LoadEntityCached<ZoneDTO>(
            id,
            EEntityType.Zone,
            waitingText,
            x => this.ZoneLoaded(x));
    }

    public AddAdditionalFiles(deliveryId: string, data: DeliveryAdditionalFilesDTO, reloadData: () => void) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.AddAdditionalFiles(deliveryId, data),
                i18next.t("common:Loading"),
                (response) => {
                    reloadData();
                });
        }
    }

    public AcceptPendingChanges(deliveryId: string, waitingText: string) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.AcceptPendingChanges(deliveryId),
                waitingText,
                (response, dispatch) => {
                    dispatch(this.PendingChangesLoaded([]));
                });
        }
    }

    public RejectPendingChanges(deliveryId: string, waitingText: string) {
        return (dispatch) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.RejectPendingChanges(deliveryId),
                waitingText,
                (response, dispatch) => {
                    dispatch(this.PendingChangesLoaded([]));
                });
        }
    }

    public RouteSegmentsLoaded(data: Array<RouteSegmentDTO>): IActionPayloaded<Array<RouteSegmentDTO>> {
        return { type: DELIVERY_ROUTE_POINTS_LOADED, Payload: data };
    }

    public PendingChangesLoaded(data: Array<DeliveryPendingChangesItemDTO>): IActionPayloaded<Array<DeliveryPendingChangesItemDTO>> {
        return { type: DELIVERY_PENDING_CHANGES_LOADED, Payload: data };
    }

    public RoutePointAdded(data: RouteSegmentDTO): IActionPayloaded<RouteSegmentDTO>{
        return { type: DELIVERY_ROUTE_POINT_ADDED, Payload: data};
    }

    public RoutePointUpdated(data: RouteSegmentDTO): IActionPayloaded<RouteSegmentDTO>{
        return { type: DELIVERY_ROUTE_POINT_UPDATED, Payload: data};
    }

    public RoutePointsDeleted(data: Array<string>): IActionPayloaded<Array<string>>{
        return { type: DELIVERY_ROUTE_POINTS_DELETED, Payload: data};
    }

    private DeliveryStatesLoaded(value: Array<DeliveryStateTableDTO>): IActionPayloaded<Array<DeliveryStateTableDTO>> {
        return { type: DELIVERY_DELIVERY_STATES_LOADED, Payload: value };
    }

    private BetsLoaded(value: Array<BiddingBetTableDTO>): IActionPayloaded<Array<BiddingBetTableDTO>> {
        return { type: DELIVERY_BIDDING_BETS_LOADED, Payload: value };
    }

    private WinnersBetsLoaded(value: Array<BiddingBetTableDTO>): IActionPayloaded<Array<BiddingBetTableDTO>> {
        return { type: DELIVERY_BIDDING_WINNER_BETS_LOADED, Payload: value };
    }

    private ComplaintsLoaded(value: Array<ComplaintTableDTO>): IActionPayloaded<Array<ComplaintTableDTO>> {
        return { type: DELIVERY_COMPLAINTS_LOADED, Payload: value };
    }

    private VehicleLoaded(value: VehicleDTO): IActionPayloaded<VehicleDTO> {
        return { type: DELIVERY_VEHICLE_LOADED, Payload: value };
    }

    private DriverLoaded(value: DriverDTO): IActionPayloaded<DriverDTO> {
        return { type: DELIVERY_DRIVER_LOADED, Payload: value };
    }

    private CargoTransporterLoaded(value: CargoTransporterDTO): IActionPayloaded<CargoTransporterDTO> {
        return { type: DELIVERY_CARGOTRANSPORTER_LOADED, Payload: value };
    }

    private ConsignorLoaded(value: ConsignorDTO): IActionPayloaded<ConsignorDTO> {
        return { type: DELIVERY_CONSIGNOR_LOADED, Payload: value };
    }

    private FileLoaded(value: FileDTO): IActionPayloaded<FileDTO> {
        return { type: DELIVERY_ADDITION_FILES_LOADED, Payload: value };
    }

    private AdditionFilesLoaded(value: Array<FileTableDTO>): IActionPayloaded<FileDTO> {
        return { type: DELIVERY_ADDITION_FILES_LOADED, Payload: value as any };
    }

    private LoadingPointLoaded(value: LoadingPointDTO): IActionPayloaded<LoadingPointDTO> {
        return { type: DELIVERY_LOADING_POINT_LOADED, Payload: value };
    }

    private FirstLoadingPointLoaded(value: LoadingPointDTO): IActionPayloaded<LoadingPointDTO> {
        return { type: DELIVERY_FIRST_LOADING_POINT_LOADED, Payload: value };
    }

    private LocalityLoaded(value: LocalityDTO): IActionPayloaded<LocalityDTO> {
        return { type: DELIVERY_LOCALITY_LOADED, Payload: value };
    }

    private HandlingTypeLoaded(value: HandlingTypeDTO): IActionPayloaded<HandlingTypeDTO> {
        return { type: DELIVERY_HANDLING_TYPE_LOADED, Payload: value };
    }

    private PayloadLoaded(value: PayloadDTO): IActionPayloaded<PayloadDTO> {
        return { type: DELIVERY_PAYLOAD_LOADED, Payload: value };
    }

    private ZoneLoaded(value: ZoneDTO): IActionPayloaded<ZoneDTO> {
        return { type: DELIVERY_ZONE_LOADED, Payload: value };
    }
}