import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ServicePartsVersionDTO } from '../../Domain/DTO/ServicePartsVersionDTO';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { IRequestBeginPayload } from "./Payloads";
import { ICommonProps } from "../Shared/ICommonProps";
import {AuthenticationService} from '../../Infrastructure/Services/AuthService';
import {VolmaContainer} from '../../Infrastructure/InversifyInject';
import {Types} from '../../Infrastructure/Types';
import ValidationResult from '../../Infrastructure/Validation/ValidationResult';
import { ObjectValidationResultDTO } from '../../Domain/DTO/ObjectValidationResultDTO';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { EntityUpdatedDTO } from '../../Domain/DTO/EntityUpdatedDTO';
import { EntityAddedDTO } from '../../Domain/DTO/EntityAddedDTO';
import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { TenderBiddingStateChangedDTO } from '../../Domain/DTO/TenderBiddingStateChangedDTO';
import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintDTO } from '../../Domain/DTO/ComplaintDTO';
import { ComplaintCommentUpdatedDTO } from '../../Domain/DTO/ComplaintCommentUpdatedDTO';
import { DeliveryStateUpdatedDTO } from '../../Domain/DTO/DeliveryStateUpdatedDTO';
import { ComplaintCommentAddedDTO } from '../../Domain/DTO/ComplaintCommentAddedDTO';
import { WithTranslationProps } from 'react-i18next/*';
import { IRouteProps } from '../../Infrastructure/Router/RouteProps';
import { DeliveryLocationUpdateDTO } from '../../Domain/DTO/DeliveryLocationUpdateDTO';

export interface IAppProps extends ICommonProps, WithTranslationProps, IRouteProps {
    IsSending?: boolean;

    ValidationError?   : ObjectValidationResultDTO;
    ServerError?       : ServerErrorDTO;
    RequestsInProgress?: Array<IRequestBeginPayload>;
    WaitingText?       : string;
    IsModalOpened?     : boolean;
    ModalContent?      : JSX.Element;
    ModalProps?        : any;
    DebounceObject?    : any;
    ServiceInfo?       : ServicePartsVersionDTO;
    CurrentEntity?     : EEntityType;

    IsSignalRMuted?         : boolean
    InfoCollapsed?          : boolean;
    UpdatedObjects?         : Array<EntityUpdatedDTO>;
    AddedObjects?           : Array<EntityAddedDTO>;
    AddedTenderBets?        : Array<TenderBetDTO>;
    AddedBiddingBets?       : Array<BiddingBetDTO>;
    UpdatedDeliveriesStates?: Array<DeliveryStateUpdatedDTO>
    TendersStarted?         : Array<TenderBiddingStateChangedDTO>
    TendersEnded?           : Array<TenderBiddingStateChangedDTO>
    ComplaintAdded?         : Array<ComplaintDTO>
    ComplaintCommentAdded?  : Array<ComplaintCommentAddedDTO>
    ComplaintCommentUpdated?: Array<ComplaintCommentUpdatedDTO>
	DeliveryLocationUpdated?: Array<DeliveryLocationUpdateDTO>
}

export const AppPropsInitial: IAppProps = <IAppProps>{
    IsSending         : false,
    InformMessage     : undefined,
    ValidationError   : undefined,
    ServerError       : undefined,
    RequestsInProgress: [],
    IsModalOpened     : false,
    ModalProps        : undefined,

    IsSignalRMuted         : false,
    InfoCollapsed          : false,
    AddedObjects           : [],
    UpdatedObjects         : [],
    AddedTenderBets        : [],
    AddedBiddingBets       : [],
    UpdatedDeliveriesStates: [],
    TendersStarted         : [],
    TendersEnded           : [],
    ComplaintAdded         : [],
    ComplaintCommentAdded  : [],
    ComplaintCommentUpdated: [],
	DeliveryLocationUpdated: [],
}
