import { FORM_SUBMITTED, REPORT_LOADED, REPORT_MOUNTED, REPORT_UNMOUNTED } from '../../../Constants/AppConstants';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { ReportServerInteraction } from '../../../Infrastructure/ServerInteraction/ReportServerInteraction';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { ReportEntityService } from './ReportEntityService';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

@injectable()
export class ReportComponentActions extends ComponentActions {
    private _apiActions: ApiActions;
    private _api: ReportServerInteraction;
    private _reportEntityService: ReportEntityService;

    constructor() {
        super();
        super.Initialize(REPORT_MOUNTED, REPORT_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._api = VolmaContainer.get<ReportServerInteraction>(Types.ReportServerInteraction);
        this._reportEntityService = VolmaContainer.get<ReportEntityService>(Types.ReportEntityService);
    }

    public FakeLoadData(){
        return { type: REPORT_LOADED, Payload: {}}
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

}