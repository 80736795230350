import {
    PASSWORD_RESET_CLEAR,
    PASSWORD_RESET_MOUNTED,
    PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE,
    PASSWORD_RESET_UNMOUNTED
} from '../../Constants/AppConstants';
import { ResetPasswordDTO } from '../../Domain/DTO/ResetPasswordDTO';
import { IAction } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { Types } from '../../Infrastructure/Types';
import { ApiActions } from '../Shared/ApiActions';
import { ComponentActions } from '../Shared/ComponentActions';
import { injectable } from 'inversify';
import { VolmaModalActions } from '../VolmaModal/VolmaModalActions';
import { AxiosResponse } from 'axios';


@injectable()
class PasswordResetActions extends ComponentActions {
    private _api: AuthServerInteraction;
    private _apiActions: ApiActions;
    private _modalActions: VolmaModalActions;

    constructor() {
        super();
        super.Initialize(PASSWORD_RESET_MOUNTED, PASSWORD_RESET_UNMOUNTED);
        this._api = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
    }

    public DoPasswordReset(PasswordResetCommand: ResetPasswordDTO, text: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.PasswordReset(PasswordResetCommand),
                text,
                (response: AxiosResponse, dispatch:any) => 
                {
                    dispatch(this.PasswordResetSuccesful())
                });
        }
    }

    public Clear(): IAction {
        return { type: PASSWORD_RESET_CLEAR }
    }

    private PasswordResetSuccesful(): IAction {
        return { type: PASSWORD_RESET_SUCCESSFUL_SENT_MESSAGE };
    }
}

 
export {PasswordResetActions}