import { INPUT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { BaseInputReducer } from '../Shared/BaseInputReducer';
import { IVolmaNumberProps } from '../VolmaNumber/IVolmaNumberProps';
import { VolmaNumberPropsInitial } from './IVolmaNumberProps';
import { INumberValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';

@injectable()
class VolmaNumberReducer extends BaseInputReducer<IVolmaNumberProps> {

    public Reduce(state: IVolmaNumberProps = VolmaNumberPropsInitial, action: IActionPayloaded<any>): IVolmaNumberProps {
        state = super.Reduce(state, action);
        switch (action.type) {
            case INPUT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaNumberProps, payload: INumberValueChangedPayload) {
        let newState: IVolmaNumberProps = {...state, Value: payload.Value};
        return newState;
    }
}

export default VolmaNumberReducer;