import { DeliveryWithdrawDTO } from '../../../../Domain/DTO/DeliveryWithdrawDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EWithdrawOwner } from '../../../../Domain/Enum/EWithdrawOwner';
import { IEntityDTO } from '../../../../Domain/IEntityDTO';


export class DeliveryWithdrawExtendedDTO extends DeliveryWithdrawDTO {
    public Comment?: string = undefined;
    public DeliveryState?: EDeliveryState = undefined;
    public IsCargotransporterBlame?: boolean = undefined;
    public ShowCargotransporterBlameCheckBox?: boolean = undefined;
}