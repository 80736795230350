import { Action, Location } from 'history';
import { Component, MouseEvent } from 'react';

import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import i18next from '../i18n';
import { VolmaFormActions } from '../VolmaForm/VolmaFormActions';
import { VolmaModalActions } from '../VolmaModal/VolmaModalActions';
import { ComponentActions } from './ComponentActions';

export class VolmaComponent<T extends IBaseEntityProps<{}, any>> extends Component<T, {}> {
    public props: T;
    protected _context: any;
    protected _actions: ComponentActions;
    protected _formActions: VolmaFormActions;
    protected _modalActions: VolmaModalActions;
    protected _noopSender: any;
    private _unblock: any;


    constructor(props: T, context:any) {
        super(props, context);

        this._context = context;
        this._formActions = VolmaContainer.get<VolmaFormActions>(Types.VolmaFormActions);
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this.RouterWillLeave = this.RouterWillLeave.bind(this);
    }

    protected SetActions(actions: ComponentActions) {
        this._actions = actions;
    }
    public componentDidMount(){
        let withinHook = false;
        let finalResult:boolean = undefined;
        let finalResultSet = false;
        this._unblock = this.props.history.block(((targetLocation: Location<undefined>, action: Action) => {
                withinHook = true;
                if (!finalResultSet) {
                    let shouldLeave = this.RouterWillLeave();
                    if (PropertyHelper.IsPromise(shouldLeave)){
                        shouldLeave.then((result: boolean)=> {
                            finalResult = result;
                            finalResultSet = true;
                            if (!withinHook && targetLocation) {
                                // Re-schedule the navigation
                                this.props.history.push(targetLocation);
                            }
                        }).catch((e: any) => {
                        });
                    }
                    else {
                        finalResult = shouldLeave as boolean;
                        finalResultSet = true;
                    }
                }
                let result = finalResultSet ? finalResult : false;
                withinHook = false;
                finalResult = undefined;
                finalResultSet = false;
                return result;
            }) as any);
    }

    public UNSAFE_componentWillMount() {
        this.props.dispatch(this._actions.Mount());
        this.props.dispatch(this._actions.CloseNav());

        this._noopSender = setInterval(() => {
            this.props.dispatch(this._actions.Noop())
        }, 1e3);
    }

    public componentWillUnmount() {
        this.props.dispatch(this._actions.Unmount());
        clearInterval(this._noopSender);
        this._unblock();
    }

    protected OnSubmit(evt: MouseEvent<HTMLButtonElement>) {
        evt.preventDefault();
        this.props.dispatch(this._formActions.Submit());
    }

    protected RouterWillLeave(): any {
        if (this.props.DataDTO === undefined)
            return true;
        if (this.props.DataDTOStringify === JSON.stringify(this.props.DataDTO))
            return true;
            
        return new Promise((resolve, reject) => {
            if(this.props.DataDTOStringify === JSON.stringify(this.props.DataDTO)){
                setTimeout(() => resolve(true));
            } else {
                const onSuccess = () => setTimeout(() => resolve(true), 1);
                const onDecline = () => setTimeout(() => reject(true), 1);
                this.props.dispatch(this._modalActions.OpenConfirmationModal(i18next.t("common:UnsavedChanges"), onSuccess, onDecline ));
            }
        });
    }
}
