import {
    APP_CLOSE_INFO_MESSAGE,
    APP_CLOSE_SERVER_ERRORS,
    APP_CLOSE_VALIDATION_ERRORS,
    APP_REMOVE_VIEWVIED_UPDATES,
    APP_SERVICE_INFO_LOADED,
    APP_SET_INFO_MESSAGE_COLLAPSED,
    APP_TOGGLE_INFO_MESSAGE,
    GENERAL_INFORM_NOTIFICATION_LIFETIME,
    INFO_BLOCK_COLLAPSED,
    NOOP,
    REQUEST_BEGIN,
    REQUEST_CLEAR_ERROR,
    REQUEST_DEBOUNCE,
    REQUEST_END,
    REQUEST_SERVER_ERROR,
    REQUEST_VALIDATION_ERROR,
    SHORT_INFORM_NOTIFICATION_LIFETIME,
    VOLMA_ENTITY_SET_ENTITY,
    VOLMA_MODAL_CLOSE,
    VOLMA_MODAL_INITIALIZE_ENTITY,
    VOLMA_MODAL_OPEN,
    VOLMA_SIGNALR_BIDDING_BET_SETTED,
    VOLMA_SIGNALR_COMPLAINT_ADDED,
    VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED,
    VOLMA_SIGNALR_COMPLAINT_UPDATED,
    VOLMA_SIGNALR_DELIVERY_STATE_UPDATED,
    VOLMA_SIGNALR_ENTITY_ADDED,
    VOLMA_SIGNALR_ENTITY_UPDATED,
    VOLMA_SIGNALR_MUTE,
    VOLMA_SIGNALR_TENDER_BET_SETTED,
    VOLMA_SIGNALR_TENDER_BIDDING_END,
    VOLMA_SIGNALR_TENDER_BIDDING_STARTED,
    VOLMA_SIGNALR_UNMUTE,
    VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED
} from '../../Constants/AppConstants';
import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { ComplaintCommentAddedDTO } from '../../Domain/DTO/ComplaintCommentAddedDTO';
import { ComplaintDTO } from '../../Domain/DTO/ComplaintDTO';
import { DeliveryStateUpdatedDTO } from '../../Domain/DTO/DeliveryStateUpdatedDTO';
import { EntityAddedDTO } from '../../Domain/DTO/EntityAddedDTO';
import { EntityUpdatedDTO } from '../../Domain/DTO/EntityUpdatedDTO';
import { ObjectValidationResultDTO } from '../../Domain/DTO/ObjectValidationResultDTO';
import { ServicePartsVersionDTO } from '../../Domain/DTO/ServicePartsVersionDTO';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { TenderBiddingStateChangedDTO } from '../../Domain/DTO/TenderBiddingStateChangedDTO';
import { EDeliveryState } from '../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { ERequestForDeliveryAssigner } from '../../Domain/Enum/ERequestForDeliveryAssigner';
import { IInformMessage } from '../../Domain/IInformMessage';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { DeliveryService } from '../../Infrastructure/Services/DeliveryService';
import { LocalStorageService } from '../../Infrastructure/Services/LocalStorageService';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { IVolmaModalPayload } from '../VolmaModal/Payloads';
import { AppPropsInitial, IAppProps } from './IAppProps';
import { IRequestBeginPayload, IRequestEndPayload, IViewedUpdates } from './Payloads';
import { DeliveryLocationUpdateDTO } from '../../Domain/DTO/DeliveryLocationUpdateDTO';

export class AppReducer implements IReducePayloaded<IAppProps> {
    private _authService: AuthenticationService;
    private _deliveryService: DeliveryService;
    private _localStorageService: LocalStorageService;

    constructor(){
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._deliveryService = VolmaContainer.get<DeliveryService>(Types.DeliveryService);
        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);
    }

    public Reduce(state: IAppProps = AppPropsInitial, action: IActionPayloaded<any>): IAppProps {
        switch (action.type) {
            case REQUEST_BEGIN:
                return this.RequestBegin(state, action.Payload);
            case REQUEST_DEBOUNCE:
                return this.RequestDebounce(state);
            case REQUEST_END:
                return this.RequestEnd(state, action.Payload);
            case REQUEST_SERVER_ERROR:
                return this.RequestServerError(state, action.Payload);
            case REQUEST_VALIDATION_ERROR:
                return this.RequestValidationError(state, action.Payload);
            case REQUEST_CLEAR_ERROR:
                return this.RequestClearError(state);
            case VOLMA_MODAL_OPEN:
                return this.ReduceModalOpen(state, action.Payload);
            case VOLMA_MODAL_CLOSE:
                return this.ReduceModalClose(state);
            case VOLMA_MODAL_INITIALIZE_ENTITY:
                return this.ReduceInitializeModal(state, action.Payload);
            case APP_CLOSE_VALIDATION_ERRORS:
                return this.ReduceClearValidationErrors(state);
            case APP_CLOSE_SERVER_ERRORS:
                return this.ReduceClearServerErrors(state);
            case APP_CLOSE_INFO_MESSAGE:
                return this.ReduceClearInform(state);
            case APP_TOGGLE_INFO_MESSAGE:
                return this.ReduceToggleInform(state);
            case APP_SET_INFO_MESSAGE_COLLAPSED:
                return this.ReduceSetInfoMessageState(state, action.Payload);
            case VOLMA_SIGNALR_ENTITY_UPDATED:
                return this.ReduceSignalREntityUpdated(state, action.Payload);
            case VOLMA_SIGNALR_ENTITY_ADDED:
                return this.ReduceSignalREntityAdded(state, action.Payload);
            case VOLMA_SIGNALR_TENDER_BET_SETTED:
                return this.ReduceSignalRTenderBetSetted(state, action.Payload);
            case VOLMA_SIGNALR_BIDDING_BET_SETTED:
                return this.ReduceSignalRBiddingBetSetted(state, action.Payload);
            case VOLMA_SIGNALR_DELIVERY_STATE_UPDATED:
                return this.ReduceSignalRDeliveryStateUpdated(state, action.Payload);
            case VOLMA_SIGNALR_TENDER_BIDDING_STARTED:
                return this.ReduceSignalRTenderStarted(state, action.Payload);
            case VOLMA_SIGNALR_TENDER_BIDDING_END:
                return this.ReduceSignalRTenderEnded(state, action.Payload);
            case VOLMA_SIGNALR_COMPLAINT_ADDED:
            case VOLMA_SIGNALR_COMPLAINT_UPDATED:
                return this.ReduceSignalRComplaintAdded(state, action.Payload);
            case VOLMA_SIGNALR_COMPLAINT_COMMENT_ADDED:
                return this.ReduceSignalRComplaintCommentAdded(state, action.Payload);
            case VOLMA_SIGNALR_MUTE:
                return this.ReduceSignalRMute(state);
            case VOLMA_SIGNALR_UNMUTE:
                return this.ReduceSignalRUnmute(state);
			case VOLMA_SIGNALR_DELIVERY_LOCATION_UPDATED:
                return this.ReduceSignalRDeliveryLocationUpdated(state, action.Payload);
            case APP_REMOVE_VIEWVIED_UPDATES:
                return this.ReduceRemoveViewedUpdates(state, action.Payload);
            case APP_SERVICE_INFO_LOADED:
                return this.ReduceServiceInfoLoaded(state, action.Payload);
            case VOLMA_ENTITY_SET_ENTITY:
                return this.ReduceClearServerAndValidationErrors(state, action.Payload);
            case NOOP:
                return this.ReduceClearOutdatedInform(state);
            default:
                return state;
        }
    }

    private RequestBegin(state: IAppProps, payload: IRequestBeginPayload): IAppProps {
        let newState: IAppProps = {...state};
        newState.IsSending = true;
        newState.RequestsInProgress.push(payload);
        newState.WaitingText = payload.Text;
        return newState;
    }

    private RequestDebounce(state: IAppProps): IAppProps {
        if(state.RequestsInProgress === undefined || state.RequestsInProgress.length === 0)
            return state;
        let newState: IAppProps = {...state};
        newState.DebounceObject = new Object();
        return newState;
    }

    private RequestEnd(state: IAppProps, payload: IRequestEndPayload): IAppProps {
        let newState: IAppProps = {...state, RequestsInProgress: []};
        for (let i = 0; i < state.RequestsInProgress.length; i++) {
            let request = state.RequestsInProgress[i];
            if (request.Uuid !== payload.Uuid) {
                newState.RequestsInProgress.push(request);
            }
        }
        newState.IsSending = newState.RequestsInProgress.length > 0;
        return newState;
    }

    private RequestServerError(state: IAppProps, payload: ServerErrorDTO): IAppProps {
        let newState: IAppProps = {...state, ServerError: payload };
        return newState;
    }

    private RequestValidationError(state: IAppProps, payload: ObjectValidationResultDTO): IAppProps {
        let newState: IAppProps = { ...state, ValidationError: payload };
        return newState;
    }

    private RequestClearError(state: IAppProps): IAppProps {
        let newState: IAppProps = {...state, ValidationError: undefined };
        return newState;
    }

    private ReduceClearServerErrors(state: IAppProps): IAppProps {
        let newState: IAppProps = {...state, ServerError: undefined };
        return newState;
    }

    private ReduceClearValidationErrors(state: IAppProps): IAppProps {
        let newState: IAppProps = {...state, ValidationError: undefined };
        return newState;
    }

    private ReduceClearServerAndValidationErrors(state: IAppProps, payload: EEntityType): IAppProps {
        let newState: IAppProps = { ...state, ValidationError: undefined, ServerError: undefined, CurrentEntity: payload };
        return newState;
    }

    private ReduceClearInform(state: IAppProps): IAppProps {
        let newState: IAppProps = { ...state,
            AddedObjects           : [],
            UpdatedObjects         : [],
            AddedTenderBets        : [],
            AddedBiddingBets       : [],
            UpdatedDeliveriesStates: [],
            TendersStarted         : [],
            TendersEnded           : [],
            ComplaintAdded         : [],
            ComplaintCommentAdded  : [],
            ComplaintCommentUpdated: [],
            DeliveryLocationUpdated: []
        };
        return newState;
    }

    private ReduceClearOutdatedInform(state: IAppProps): IAppProps {
        let hasOutdated = this.HasOutdated(state.AddedObjects)              ||
                          this.HasOutdated(state.UpdatedObjects)            ||
                          this.HasOutdated(state.AddedTenderBets)           ||
                          this.HasOutdated(state.AddedBiddingBets)          ||
                          this.HasOutdated(state.UpdatedDeliveriesStates)   ||
                          this.HasOutdated(state.TendersStarted)            ||
                          this.HasOutdated(state.TendersEnded)              ||
                          this.HasOutdated(state.ComplaintAdded)            ||
                          this.HasOutdated(state.ComplaintCommentAdded)     ||
                          this.HasOutdated(state.ComplaintCommentUpdated)   ||
                          this.HasOutdated(state.DeliveryLocationUpdated);
        if (!hasOutdated)
            return state;
        let newState: IAppProps = { ...state };
        newState.AddedObjects            = this.ClearOutdated(state.AddedObjects);
        newState.UpdatedObjects          = this.ClearOutdated(state.UpdatedObjects);
        newState.AddedTenderBets         = this.ClearOutdated(state.AddedTenderBets);
        newState.AddedBiddingBets        = this.ClearOutdated(state.AddedBiddingBets);
        newState.UpdatedDeliveriesStates = this.ClearOutdated(state.UpdatedDeliveriesStates);
        newState.TendersStarted          = this.ClearOutdated(state.TendersStarted);
        newState.TendersEnded            = this.ClearOutdated(state.TendersEnded);
        newState.ComplaintAdded          = this.ClearOutdated(state.ComplaintAdded);
        newState.ComplaintCommentAdded   = this.ClearOutdated(state.ComplaintCommentAdded);
        newState.ComplaintCommentUpdated = this.ClearOutdated(state.ComplaintCommentUpdated);
        newState.DeliveryLocationUpdated = this.ClearOutdated(state.DeliveryLocationUpdated);

        return newState;
    }

    private ReduceToggleInform(state: IAppProps): IAppProps {
        let newState: IAppProps = { ...state};
        newState.InfoCollapsed = newState.InfoCollapsed === true ? false : true;
        this._localStorageService.SetKey(INFO_BLOCK_COLLAPSED, JSON.stringify(newState.InfoCollapsed));
        return newState;
    }

    private ReduceSetInfoMessageState(state: IAppProps, payload: boolean): IAppProps {
        let newState: IAppProps = { ...state};
        newState.InfoCollapsed = payload;
        return newState;
    }

    private ReduceModalOpen(state: IAppProps, payload: IVolmaModalPayload): IAppProps{
        let newState: IAppProps = { ...state, IsModalOpened: true, ModalContent: payload.Content}
        return newState;
    }

    private ReduceModalClose(state: IAppProps): IAppProps{
        let newState: IAppProps = { ...state, IsModalOpened: false, ModalContent: undefined, ModalProps: undefined}
        return newState;
    }

    private ReduceInitializeModal(state: IAppProps, props: any): IAppProps{
        let newState: IAppProps = { ...state, ModalProps: props}
        return newState;
    }

    private ReduceSignalREntityAdded(state: IAppProps, props: EntityAddedDTO): IAppProps{
        return state;
        // return this.SignalRNotificationAdded(state, x => x.AddedObjects, props, (x, y) => x.DTO.Id === y.DTO.Id);
    }

    private ReduceSignalREntityUpdated(state: IAppProps, props: EntityUpdatedDTO): IAppProps{
        return state;
        // return this.SignalRNotificationAdded(state, x => x.UpdatedObjects, props, (x, y) => x.DTO.Id === y.DTO.Id);
    }

    private ReduceSignalRBiddingBetSetted(state: IAppProps, props: BiddingBetDTO): IAppProps{
        return state;
        // return this.SignalRNotificationAdded(state, x => x.AddedBiddingBets, props, (x, y) => x.Id === y.Id);
    }

    private ReduceSignalRDeliveryLocationUpdated(state: IAppProps, props: DeliveryLocationUpdateDTO): IAppProps{
        return state;
    }

    private ReduceSignalRDeliveryStateUpdated(state: IAppProps, props: DeliveryStateUpdatedDTO): IAppProps{
        let removeAfterTimeInMs: number = undefined;

        let notification = this._deliveryService.GetTextForNotification(props, this._authService.GetCurrentUser());
        if (this._authService.IsCargoTransporter() &&
            props.DeliveryState == EDeliveryState.Assignation && props.Assigner == ERequestForDeliveryAssigner.Bidding)
            removeAfterTimeInMs = (new Date()).getTime() + SHORT_INFORM_NOTIFICATION_LIFETIME;
        else if (!notification.IsRed && !notification.IsGreen)
            removeAfterTimeInMs = (new Date()).getTime() + GENERAL_INFORM_NOTIFICATION_LIFETIME;

        if (notification.Text !== undefined){
            return this.SignalRNotificationAdded(state, x => x.UpdatedDeliveriesStates, props, (x, y) => x.DeliveryId === y.DeliveryId, removeAfterTimeInMs);
        }
        else if (PropertyHelper.IsArray(state.UpdatedDeliveriesStates)){
            let index = state.UpdatedDeliveriesStates.findIndex(x => x.DeliveryId === props.DeliveryId);
            if(index > -1){
                let currentNotification = this._deliveryService.GetTextForNotification(state.UpdatedDeliveriesStates[index], this._authService.GetCurrentUser());
                // if message was without color or Green, then remove it
                if (!currentNotification.IsRed && currentNotification.Text !== undefined){
                    let newState = { ...state };
                    newState.UpdatedDeliveriesStates = state.UpdatedDeliveriesStates.slice(0);
                    newState.UpdatedDeliveriesStates.splice(index, 1);
                    return newState;
                }
            }
        }
        return state;
    }

    private ReduceSignalRTenderBetSetted(state: IAppProps, props: TenderBetDTO): IAppProps{
        return state;
        // return this.SignalRNotificationAdded(state, x => x.AddedTenderBets, props, (x, y) => x.Id === y.Id);
    }

    private ReduceSignalRTenderStarted(state: IAppProps, props: TenderBiddingStateChangedDTO): IAppProps{
        let newState = this.SignalRNotificationAdded(state, x => x.TendersStarted, props, (x, y) => x.TenderId === y.TenderId);
        newState.TendersEnded = newState.TendersEnded.filter(x => x.TenderId !== props.TenderId);
        return newState;
    }

    private ReduceSignalRTenderEnded(state: IAppProps, props: TenderBiddingStateChangedDTO): IAppProps{
        if (this._authService.IsShipper()) {
            let newState = this.SignalRNotificationAdded(state, x => x.TendersEnded, props, (x, y) => x.TenderId === y.TenderId);
            newState.TendersStarted = newState.TendersStarted.filter(x => x.TenderId !== props.TenderId);
            return newState;
        }
        else{
            let newState = { ...state };
            newState.TendersStarted = newState.TendersStarted.filter(x => x.TenderId !== props.TenderId);
            return newState;
        }
    }

    private ReduceSignalRComplaintAdded(state: IAppProps, props: ComplaintDTO): IAppProps{
        let newState = this.SignalRNotificationAdded(state, x => x.ComplaintAdded, props, (x, y) => x.Id === y.Id);
        newState.ComplaintCommentAdded = newState.ComplaintCommentAdded.filter(x => x.ComplaintId != props.Id);
        return newState;
    }

    private ReduceSignalRComplaintCommentAdded(state: IAppProps, props: ComplaintCommentAddedDTO): IAppProps{
        if (!PropertyHelper.IsArray(state.ComplaintAdded) || state.ComplaintAdded.find(x => x.Id === props.ComplaintId) === undefined)
            return this.SignalRNotificationAdded(state, x => x.ComplaintCommentAdded, props, (x, y) => x.ComplaintId === y.ComplaintId);
        return state;
    }

    private ReduceRemoveViewedUpdates(state: IAppProps, props: IViewedUpdates): IAppProps{
        let newState = <IAppProps>{ ...state};

        newState.AddedObjects            = this.GetCopyReduced(newState, x => x.AddedObjects            , props, x => x.DTO.Id);
        newState.UpdatedObjects          = this.GetCopyReduced(newState, x => x.UpdatedObjects          , props, x => x.DTO.Id);
        newState.AddedTenderBets         = this.GetCopyReduced(newState, x => x.AddedTenderBets         , props, x => x.Id);
        newState.AddedBiddingBets        = this.GetCopyReduced(newState, x => x.AddedBiddingBets        , props, x => x.Id);
        newState.UpdatedDeliveriesStates = this.GetCopyReduced(newState, x => x.UpdatedDeliveriesStates , props, x => x.DeliveryId);
        newState.TendersStarted          = this.GetCopyReduced(newState, x => x.TendersStarted          , props, x => x.TenderId);
        newState.TendersEnded            = this.GetCopyReduced(newState, x => x.TendersEnded            , props, x => x.TenderId);
        newState.ComplaintAdded          = this.GetCopyReduced(newState, x => x.ComplaintAdded          , props, x => x.Id);
        newState.ComplaintCommentAdded   = this.GetCopyReduced(newState, x => x.ComplaintCommentAdded   , props, x => x.ComplaintId);
        newState.ComplaintCommentUpdated = this.GetCopyReduced(newState, x => x.ComplaintCommentUpdated , props, x => x.ComplaintId);

        return newState;
    }

    private ReduceSignalRMute(state: IAppProps) : IAppProps{
        return {...state, IsSignalRMuted: true};
    }

    private ReduceSignalRUnmute(state: IAppProps) : IAppProps{
        return {...state, IsSignalRMuted: false};
    }

    private SignalRNotificationAdded<T>(state: IAppProps, property: (object: IAppProps) => Array<T>, payload: T, areEqual: (x: T, y: T) => boolean, removeAtTimeInMs: number = undefined) {
        let newState = { ...state};

        let propName = PropertyHelper.GetPropertyName(property);
        newState[propName] = state[propName].slice();
        if (removeAtTimeInMs !== undefined)
            (payload as IInformMessage).RemoveAfterTimeInMs = removeAtTimeInMs;
        if (!state.IsSignalRMuted && payload !== null && payload !== undefined) {
            let index = newState[propName].findIndex(x => areEqual(x, payload));
            if (index < 0)
                newState[propName].push(payload);
            else{
                newState[propName].splice(index, 1, payload);
            }
        }
        else
            return state;
        return newState;
    }

    private GetCopyReduced<T>(state: IAppProps, property: (object: IAppProps) => Array<T>, props: IViewedUpdates, idGetter: (x: T) => string) {

        let propName = PropertyHelper.GetPropertyName(property);
        state[propName] = state[propName].slice();

        let copy = [];

        for (let newObj of state[propName]) {
            if (idGetter(newObj) !== props.Id)
                copy.push(newObj);
        }

        return copy;
    }

    private HasOutdated(array: Array<any>): boolean {
        if (array === undefined || array === null || !(array.length > 0))
            return false;
        let now = new Date();
        let hasOutdated = array.find(x => x.RemoveAfterTimeInMs !== undefined && now.getTime() > x.RemoveAfterTimeInMs) !== undefined;
        return hasOutdated;
    }

    private ClearOutdated(array: Array<any>) {
        if (!this.HasOutdated(array))
            return array;
        let now = new Date();
        return array.filter(x => x.RemoveAfterTimeInMs === undefined || now.getTime() < x.RemoveAfterTimeInMs);
    }

    private ReduceServiceInfoLoaded(state: IAppProps, payload: ServicePartsVersionDTO): IAppProps {
        return {...state,ServiceInfo: payload};
    }

    public static Initialize(): (state: IAppProps, action: IActionPayloaded<any>) => IAppProps{
        const reducer = new AppReducer();
        return (state: IAppProps, action: IActionPayloaded<any>) => reducer.Reduce(state, action);
    }
}

