import { injectable } from 'inversify';
import * as React from 'react';
import { MouseEvent } from 'react';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { EFilterType } from '../../../Domain/Enum/EFilterType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { IReducePayloaded } from '../../../Infrastructure/Reducer/IReducer';
import { TableServerInteraction } from '../../../Infrastructure/ServerInteraction/TableServerInteraction';
import { TableSignalRServerInteraction } from '../../../Infrastructure/ServerInteraction/TableSignalRServerInteraction';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { AuthorizationService } from '../../../Infrastructure/Services/AccessService/AuthorizationService';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { EnumService } from '../../../Infrastructure/Services/EnumService';
import { FormatService } from '../../../Infrastructure/Services/FormatService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { SignalRService } from '../../../Infrastructure/Services/SignalRService';
import { TimeService } from '../../../Infrastructure/Services/TimeService';
import { IStatus } from '../../../Infrastructure/Status/IStatus';
import { Types } from '../../../Infrastructure/Types';
import { FooterNavLink } from '../../Footer/IFooterProps';
import MainInfoItem from '../../MainInfoItem/MainInfoItem';
import { DeleteItemsAction } from '../../Table/VolmaTable/Actions/DeleteItemsAction';
import { IVolmaTableAction, IVolmaTableColumn, IVolmaTableProps } from '../../Table/VolmaTable/IVolmaTableProps';
import i18next from '../../i18n';
import { EntityAction } from './EntityAction';
import { IBaseEntityProps } from './IBaseEntityProps';
import { IEntityReducer } from './IEntityReducer';
import { IEntityService } from './IEntityService';
import { EntityUpdatedDTO } from '../../../Domain/DTO/EntityUpdatedDTO';
import { RouteUtils } from '../../../Infrastructure/Router/RouteUtils';
import { isDefined } from '../../../Infrastructure/Services/Utils';

@injectable()
export abstract class BaseEntityService<TDTO extends {}, THelper> implements IEntityService<TDTO, THelper>{
    protected _createCard            = volmaBlock('create-card');
    protected _mainInfo              = volmaBlock('main-info');
    protected _mainInfoSpecification = volmaBlock('main-info-specification');
    protected _mainInfoDescription   = volmaBlock('main-info-description');
    protected _titleDropParent       = volmaBlock('title-drop-parent');
    protected _titleDrop             = volmaBlock('title-drop');
    protected _infoDrop              = volmaBlock('info-drop');
    protected _container             = volmaBlock('container');

    protected _table       = volmaBlock('table');
    protected _tableButton = volmaBlock("table-button");
    protected _btn         = volmaBlock("btn");
    protected _btnGreen    = volmaBlock("btn-green");
    protected _fullHeigthButton  = volmaBlock("full-height-button");
    protected _zeroPadding    = volmaBlock("zero-padding");
    protected _btnTable    = volmaBlock("btn-table");
    protected _btnEdit     = volmaBlock("btn-edit");
    protected _btnRemove   = volmaBlock("btn-remove");
    protected _bgIco       = volmaBlock("bg-icon");
    protected _bgPlusIco   = volmaBlock("bg-plus-ico");

    protected _input = volmaBlock("input");

    protected _volmaMultifile = volmaBlock("volma-multifile");
    protected _volmaInput     = volmaBlock("volma-input");
    protected _volmaFile      = volmaBlock("volma-file");

    protected _status     = volmaBlock("status");
    protected _statusDrop = volmaBlock("status-drop");
    protected _radio      = volmaBlock("radio");

    protected _ratesWinner = volmaBlock("rates-winner");
    protected _rates = volmaBlock("rates");

    protected _authService            : AuthenticationService;
    protected _authorizationService   : AuthorizationService;
    protected _enumService            : EnumService;
    protected _timeService            : TimeService;
    protected _signalRService         : SignalRService;
    protected _formatService          : FormatService;
    protected _tableInteraction       : TableServerInteraction;
    protected _tableSignalRInteraction: TableSignalRServerInteraction;


    protected _entity: EEntityType;

    public ItemsPerPage: number = 250;
    constructor() {
        this._authService             = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._enumService             = VolmaContainer.get<EnumService>(Types.EnumService);
        this._timeService             = VolmaContainer.get<TimeService>(Types.TimeService);
        this._authorizationService    = VolmaContainer.get<AuthorizationService>(Types.AuthorizationService);
        this._signalRService          = VolmaContainer.get<SignalRService>(Types.SignalRService);
        this._formatService           = VolmaContainer.get<FormatService>(Types.FormatService);
        this._tableInteraction        = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
        this._tableSignalRInteraction = VolmaContainer.get<TableSignalRServerInteraction>(Types.TableSignalRServerInteraction);
    }

    public AlwaysPost(): boolean{
        return false;
    }

    public DisposeEntity(): void {
        return;
    }

    public SetEntity(entity: EEntityType){
        this._entity = entity;
    }

    public GetBaseEntity(): EEntityType{
        return undefined;
    }

    public GetTableEntity(): EEntityType{
        return undefined;
    }

    public GetDisableItemEdit(): boolean {
        return false;
    }

    public GetEntityId(props: IBaseEntityProps<TDTO, THelper>): string {
        return props.match.params.id;
    }

    public abstract GetColumnsList(): Array<IVolmaTableColumn>;

    public GetOnEntityUpdated(): (dto: EntityUpdatedDTO, debounce:() => void, dispatchFilteredData:() => void) => void
    {
        return undefined;
    }

    public GetTableCanAddEntity(entity: EEntityType): boolean{
        return this._authorizationService.GetTableCanAddEntity(this._entity);
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<THelper>> {
        if (this.GetTableCanAddEntity(this._entity))
            return [new DeleteItemsAction()];
        return [];
    }

    public GetTableReducer(): IReducePayloaded<IVolmaTableProps<any>> {
        return undefined;
    }
    public OnBeforeTableDataLoaded(props: IVolmaTableProps<any>): void {
        return;
    }
    public OnAfterTableDataLoaded(data: Array<ITableDTO>): Array<ITableDTO> | undefined {
        return undefined;
    }
    public GetTableSubsidiaryEntities(): Array<EEntityType>{
        return []
    }

    public GetTableRowClass(rowData: any): string{
        return this._table("row", { body: true }).toString();
    }
    public InitializeTable(props: IVolmaTableProps<any>): void{}
    public DisposeTable(): void{}


    public CustomEntitySaveAction(entity: EEntityType, dto: IEntityDTO, isFormValid: boolean, waitingText: string, id?: string) {
        return undefined;
    }

    public GetStatusList(): Array<IStatus<TDTO, THelper>> {
        return undefined
    }
    public GetHeaderEditor(props: IBaseEntityProps<TDTO, THelper>): JSX.Element {
        return undefined;
    }

    public abstract GetEditorModal(props: IBaseEntityProps<TDTO, THelper>): JSX.Element;
    public abstract GetEditor(props: IBaseEntityProps<TDTO, THelper>): JSX.Element;
    public abstract InitializeEntity(props: IBaseEntityProps<TDTO, THelper>): void;
    public abstract OnAfterDataLoaded(dto: TDTO): void;
    public abstract GetReducer(): IEntityReducer<{}, any>;
    public abstract GetInitialDataHelper(): THelper;

    public InitializeDefaultDTOValues(props: TDTO): void{
        return undefined;
    }

    public IsEditable(props: IBaseEntityProps<TDTO, THelper>): boolean{
        return this._authorizationService.IsEditable(this._entity) || this.IsGodMode(props);
    }

    public IsGodMode(props: IBaseEntityProps<TDTO, THelper>): boolean {
        const godVariable = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(props.location, x => x.search), "GodMode");
        
        return godVariable == 'true' || godVariable == '1';
    }

    protected GetMainInfoNoTitleItem(isDark: boolean, isLight: boolean, row: JSX.Element, key?: number): JSX.Element {
        return (
            <div key={key} className={this._mainInfo("item", { dark: isDark, light: isLight }).toString()} >
                <div className={(this._container()).toString()}>
                    {row}
                </div>
            </div>
        );
    }

    protected GetMainInfoNoDropItem(isDark: boolean, isLight: boolean, title: string, row: JSX.Element, buttons?: Array<JSX.Element>): JSX.Element {
        return (
            <div className={this._mainInfo("item", { dark: isDark, light: isLight }).toString()} >
                <div className={(this._container()).toString()}>
                    <div className={(this._mainInfo("top")).toString()}>
                        <h2 className={this._titleDrop("title").mix([this._mainInfo("title")]).toString()}>{title} </h2>
                        {buttons}
                    </div>
                    {row}
                </div>
            </div>
        );
    }

    protected GetMainInfoItem(
        isDark: boolean,
        isLight: boolean,
        isTitle: boolean,
        index: number,
        title: string,
        description: string,
        row: JSX.Element,
        buttons: Array<JSX.Element>,
        props: IBaseEntityProps<any, any>,
        dispatch: any): JSX.Element {
        let id  = props.DataDTO !== undefined ? props.DataDTO.Id : "";
        return (
            <MainInfoItem
                IsDark={isDark}
                IsLight={isLight}
                IsTitle={isTitle}
                Index={index}
                Title={title}
                Description={description}
                Row={row}
                Buttons={buttons}
                FooterNavList={props.FooterNavList}
                ClosedCardParts={props.ClosedCardParts}
                dispatch={dispatch}/>
        );
    }

    private SaveFooterNavLink(props: IBaseEntityProps<any, any>, action: {FooterLinkAdded: (link: FooterNavLink) => IActionPayloaded<FooterNavLink>}, dispatch: any, link: HTMLElement, title: string, index: number){
        if (link !== null && link !== undefined){
            if(props.FooterNavList[index] === undefined)
                dispatch(action.FooterLinkAdded({Ref: link, Title: title, Index: index}));
        }
    }

    protected GetStatusLine(
        id: string,
        dto: TDTO,
        dtoHelper: THelper,
        entity: EEntityType,
        dispatch: any,
        action: EntityAction,
        openedStatusIndex: number,
        authService: AuthenticationService,
        reload: () => void, tableDataHelper: any): JSX.Element {

        let currentUser = this._authService.GetCurrentUser();

        let statuses = this.GetStatusList();
        if(statuses === undefined || statuses.length === 0 || id === undefined || dto === undefined)
            return undefined;

        let statusesEls = new Array<JSX.Element>();
        let index = 1;
        for(let i = 0; i < statuses.length; i++){
            let status = statuses[i];
            let isFinished = status.IsFinished(dto);
            let isCurrent = status.IsCurrent(dto);
            let isActive = status.IsActive(dto, dtoHelper);

            if (!isActive)
                continue;

            let actionsEls = new Array<JSX.Element>();
            let actions = this.IsEntityStatusChangingAvailable(dto, dtoHelper) ? status.GetActions(dto, currentUser, authService) : [];
            if (actions !== undefined && actions.length > 0 && isCurrent)
            {
                for(let j = 0; j < actions.length; j++){
                    let action = actions[j];
                    if (action.IsAvailable([id], [dto], dtoHelper)){
                        let key = i.toString() + "-" + j.toString();
                        actionsEls.push(
                            <div key={key} className={(this._statusDrop("item")).toString()} onClick={(() => action.OnActivate(undefined, entity, [id], [dto], dispatch, reload, tableDataHelper)).bind(this)}>
                                <label className={(this._radio).toString()}>
                                    <span className={(this._radio("text")).toString()}>{i18next.t(action.Title)}</span>
                                </label>
                            </div>
                        );
                    }
                }
            }

            let hasActions = actionsEls.length > 0;
            let additionData = undefined;
            let showClock = true;
            if(isCurrent){
                let data = status.GetTimeLeftInStatus(dto, dtoHelper);
                if (data !== undefined && this._timeService.IsInFuture(data)) {
                    let difference = this._timeService.DiffToNow(data);
                    let total
                    let hours = Math.floor(difference.asHours());
                    additionData = i18next.t("common:TimeLeft", {
                        hours: (hours < 10 ? "0" : "") + hours.toString(),
                        minutes: (difference.minutes() < 10 ? "0" : "") + difference.minutes().toString(),
                        seconds: (difference.seconds() < 10 ? "0" : "") + difference.seconds().toString(),
                    } as any);

                }
            }
            else if(isFinished){
                let data = status.GetStatusFinishedDate(dto, dtoHelper);
                if (data !== undefined) {
                    additionData = this.GetSafeDateTimeShort(data);
                }
            }

            statusesEls.push(
                <li key={i} className={(this._status("item")).toString()}>
                    <a onClick={(e: MouseEvent<HTMLAnchorElement>) => {e.
                            preventDefault();
                            e.stopPropagation();
                            dispatch(action.ToggleStatus(i))}
                        }
                        className={this._status("link", { green: isCurrent, blue: isFinished }).mix([isFinished || isCurrent ? "changed" : ""]).toString()}>
                        <span className={this._status("link-text").toString()}>{isCurrent ?
                            i18next.t(status.TitleCurrent, { index: index, dto: dto } as any):
                                (isFinished ?
                                    i18next.t(status.TitleFinished, { index: index } as any) :
                                i18next.t(status.TitleFuture, { index: index } as any))}
                            {additionData && " ("}
                            {additionData && showClock && <svg className={this._status("link-icon").mix(["status-clock-ico"]).toString()} > <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#clock"></use></svg>}
                            {additionData}
                            {additionData && ")"}</span>
                        {hasActions && <svg className={(this._status("arrow")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-down"></use>
                        </svg>}
                    </a >
                    {hasActions && <div className={this._statusDrop.mix([i === openedStatusIndex ? "active" : ""]).toString()}>
                        {actionsEls}
                    </div>}
                </li>
            )
            index++;
        }

        return (
            <div className={this._mainInfo("item", { dark: false, light: true, title: false }).toString()} >
                <div className={(this._container()).toString()}>
                    <div className={(this._status).toString()}>
                        <ul className={(this._status("list")).toString()}>
                            {statusesEls}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    protected IsEntityStatusChangingAvailable(dto: TDTO, dtoHelper: THelper): boolean {
        return true;
    }

    public IsEntitySendingAvailable(dto: TDTO, dataHelper: THelper): boolean {
        return true;
    }
    
    public IsEntityEditCase(entityId: string | undefined): boolean {
        return isDefined(entityId) && PropertyHelper.IsGuid(entityId);
    }

    protected GetAlternateEntityId<T extends ITableDTO>(id: string, propName: string, itemsComparer: (a: T, id: string) => boolean) {

        if (this.IsValueNotEmpty(id)) {
            if (PropertyHelper.IsGuid(id)) {
                return id;
            }
            else return new Promise((resolve, reject) => {
                let filter = new FilterDTO();
                filter.Type = EFilterType.Text;
                filter.TextValue = id;
                filter.Key = propName;
                let entity = this._entity;
                if(this.GetBaseEntity() !== undefined)
                    entity = this.GetBaseEntity();
                this._tableInteraction.GetTableData(entity, propName, ESortDir.Desc, [filter], 25000, 1).
                    then((response) => {
                        let data = JSON.parse(response.data);
                        let item = data.Items.find((x: T) => itemsComparer(x, id)) as T;
                        if (item !== undefined)
                            resolve(item.Id);
                        else
                            reject("Identifier " + id + " not found");
                    })
            })
        }

        return id;
    }

    protected GetSafeValue(value: any, unit: string = "", allowZero: boolean = false): JSX.Element {
        if (!this.IsValueNotEmpty(value))
            return i18next.t("common:NoData");
        return <span dangerouslySetInnerHTML={{ __html: value + (unit !== "" ? (" " + unit) : "") }}></span>;
    }

    protected GetSafeMoney(value: any,  allowZero: boolean = false): string {
        return this._formatService.GetSafeMoney(value, allowZero);
    }

    protected NonEmptyCollection(collection: Array<any>): boolean{
        if(collection === undefined || collection === null || collection.length === 0)
            return false;
        return true;
    }

    protected GetSafeString(value: any): string{
        return this._formatService.GetSafeString(value);
    }

    protected GetSafeDateNoCorrection(value: any): string{
        return this._formatService.GetSafeDateNoCorrection(value);
    }

    protected GetSafeDate(value: any): string{
        return this._formatService.GetSafeDate(value);
    }

    protected GetSafeTime(value: any): string{
        return this._formatService.GetSafeTime(value);
    }

    protected GetSafeDateTime(value: any): string{
        return this._formatService.GetSafeDateTime(value);
    }

    protected GetSafeDateTimeShort(value: any): string{
        return this._formatService.GetSafeDateTimeShort(value);
    }

    protected IsValueNotEmpty(value: any, allowZero: boolean = false){
        return this._formatService.IsValueNotEmpty(value, allowZero);
    }

    protected IsDateValid(value: any) {
        return this._formatService.IsDateValid(value);
    }

    protected GetLocalDate(value: any) {
        return this._timeService.GetLocalTime(value);
    }
}