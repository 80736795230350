import { injectable } from 'inversify';
import i18next from '../../Components/i18n';
import { EMPTY_GUID } from '../../Constants/AppConstants';
import * as moment from 'moment';
import { VolmaContainer } from '../InversifyInject';
import { TimeService } from './TimeService';
import { Types } from '../Types';

@injectable()
export class FormatService{
    private _timeService: TimeService;

    constructor(){
        this._timeService = VolmaContainer.get<TimeService>(Types.TimeService);
    }

    public GetSafeMoney(value: any, allowZero: boolean = false): string {
        if (!this.IsValueNotEmpty(value))
            return i18next.t("common:NoData");

        let stringValue = parseInt(value.toString()).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ');
        return stringValue;
    }

    public GetSafeString(value: any): string {
        if (!this.IsValueNotEmpty(value))
            return i18next.t("common:NoData");

        if (typeof (value) === "string" || typeof (value) === "number")
            return value.toString();

        return JSON.stringify(value);
    }

    public GetSafeDateNoCorrection(value: any): string {
        // @ts-ignore: This expression is not callable
        if (!this.IsValueNotEmpty(value) || !moment(value).isValid())
            return i18next.t("common:NoData");

        // @ts-ignore: This expression is not callable
        return moment(value).format("DD.MM.YYYY")
    }

    public GetSafeDate(value: any): string {
        // @ts-ignore: This expression is not callable
        if (!this.IsValueNotEmpty(value) || !moment(value).isValid())
            return i18next.t("common:NoData");

        return this._timeService.GetLocalTime(value).format("DD.MM.YYYY")
    }

    public GetSafeTime(value: any): string {
        // @ts-ignore: This expression is not callable
        if (value === undefined || value === null || value === 0 || value === EMPTY_GUID || value === "" || !moment(value).isValid())
            return i18next.t("common:NoData");

        return this._timeService.GetLocalTime(value).format("HH:mm:ss")
    }

    public GetSafeDateTime(value: any): string {
        // @ts-ignore: This expression is not callable
        if (value === undefined || value === null || value === 0 || value === EMPTY_GUID || value === "" || !moment(value).isValid())
            return i18next.t("common:NoData");

        return this._timeService.GetLocalTime(value).format("DD.MM.YYYY HH:mm:ss")
    }

    public GetSafeDateTimeShort(value: any): string {
        // @ts-ignore: This expression is not callable
        if (value === undefined || value === null || value === 0 || value === EMPTY_GUID || value === "" || !moment(value).isValid())
            return i18next.t("common:NoData");

        return this._timeService.GetLocalTime(value).format("DD.MM HH:mm")
    }

    public GetSafeDateTimeDashed(value: Date): string {
        // @ts-ignore: This expression is not callable
        if (value === undefined || value === null || !moment(value).isValid())
            return i18next.t("common:NoData");

        return this._timeService.GetLocalTime(value).format("YYYY-MM-DD HH:mm:ss")
    }

    public IsValueNotEmpty(value: any, allowZero: boolean = false) {
        return value !== undefined && value !== null && value !== 0 && value !== EMPTY_GUID && value !== "";
    }

    public IsDateValid(value: any) {
        // @ts-ignore: This expression is not callable
        return this.IsValueNotEmpty(value) && moment(value).isValid();
    }
}