import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { CloseAction } from '../Action/CloseAction';
import { RejectExaminationAction } from '../Action/RejectExaminationAction';
import { TakeIntoExaminationAction } from '../Action/TakeIntoExaminationAction';
import { Billed } from './Billed';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { CancelAction } from '../Action/CancelAction';

export class TakenIntoExamination extends ComplaintStatusDefault{

    public TitleCurrent : string = "complaint:StatusTakenIntoExaminationCurrent";
    public TitleFinished: string = "complaint:StatusTakenIntoExaminationFinished";
    public TitleFuture  : string = "complaint:StatusTakenIntoExaminationFuture";

    public GetActions(dto: ComplaintDTO){
        return [new TakeIntoExaminationAction(), new RejectExaminationAction(), new CancelAction()];
    }

    constructor(){
        super();
        this._prevState = new Billed();
        this._currentStatus = EComplaintState.TakenIntoExamination;
    }
}