import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { IEntityService } from '../../Entity/BaseEntity/IEntityService';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded, IAction } from '../../../Infrastructure/Action/IAction';
import {
    BASE_TABLE_MOUNTED,
    BASE_TABLE_SET_ENTITY_SERVICE,
    BASE_TABLE_UNMOUNTED,
    VOLMA_ENTITY_SET_ENTITY
} from '../../../Constants/AppConstants';
import { ComponentActions } from '../../Shared/ComponentActions';
import { injectable } from 'inversify';
import { BASE_TABLE_CLEAR_FILTERS_AND_SELECTION } from '../../../Constants/AppConstants';

@injectable()
export class BaseTableActions extends ComponentActions {
    constructor() {
        super();
        super.Initialize(BASE_TABLE_MOUNTED, BASE_TABLE_UNMOUNTED);
    }

    public SetEntityService(entityService: IEntityService<IEntityDTO, any>): IActionPayloaded<IEntityService<IEntityDTO, any>> {
        return { type: BASE_TABLE_SET_ENTITY_SERVICE, Payload: entityService };
    }

    public ClearFiltersAndSelection(): IAction {
        return { type: BASE_TABLE_CLEAR_FILTERS_AND_SELECTION};
    }
}
