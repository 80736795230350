import i18next from "i18next";
import { injectable } from "inversify";
import { DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED, DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER } from '../../../../Constants/AppConstants';
import { MultiCargoTransporterDTO } from "../../../../Domain/DTO/MultiCargoTransporterDTO";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { ESortDir } from "../../../../Domain/Enum/ESortDir";
import { IActionPayloaded } from "../../../../Infrastructure/Action/IAction";
import { MultiTransporterTableDTO } from "../../../VolmaMultiSelect/MultiTransporter/MultiTransporterTypes";
import { EntityAction } from "../../BaseEntity/EntityAction";

@injectable()
export class DeliveryManualAssignActions extends EntityAction {
    constructor() {
        super();
    }

    public LoadAllMultiTransporters() {
        return super.LoadDataTable<MultiCargoTransporterDTO>(
            EEntityType.MultiCargoTransporter,
            (x: MultiCargoTransporterDTO) => x.Name,
            ESortDir.Asc,
            undefined,
            i18next.t("common:Loading"),
            this.MultiCargoTransportersLoaded
        );
    }

    public SelectMultiTransporter(value: MultiTransporterTableDTO): IActionPayloaded<MultiTransporterTableDTO> {
        return { type: DELIVERY_MANUAL_ASSIGN_SELECT_MULTI_TRANSPORTER, Payload: value };
    }

    private MultiCargoTransportersLoaded(value: Array<MultiCargoTransporterDTO>): IActionPayloaded<Array<MultiCargoTransporterDTO>> {
        return { type: DELIVERY_MANUAL_ASSIGN_MULTI_CARGO_TRANSPORTERS_LOADED, Payload: value };
    }
}
