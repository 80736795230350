import { EEntityType } from '../../Domain/Enum/EEntityType';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { AxiosResponse } from 'axios';
import { IEntityService } from '../Entity/BaseEntity/IEntityService';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { ApiActions } from '../Shared/ApiActions';
import { Types } from '../../Infrastructure/Types';
import { TableServerInteraction } from '../../Infrastructure/ServerInteraction/TableServerInteraction';
import {
    FORM_SUBMITTED,
    REQUEST_SERVER_ERROR,
    VOLMA_MODAL_CLOSE,
    VOLMA_MODAL_DATA_LOADED,
    VOLMA_MODAL_ENTITY_SAVED,
    VOLMA_MODAL_INITIALIZE_ENTITY,
    VOLMA_MODAL_OPEN,
    VOLMA_MODAL_SET_ENTITY_SERVICE,
    VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE
} from '../../Constants/AppConstants';
import { IActionPayloaded, IAction } from '../../Infrastructure/Action/IAction';
import { IVolmaModalPayload, IVolmaModalServerEntityEditPayload, IVolmaModalConfirmPayload, IVolmaModalLocalEntityEditPayload, IConfirmOnUnsavedChangesPayload, IVolmaModalNotificationPayload } from './Payloads';
import { ConnectedVolmaModalConfirm } from './VolmaModalConfirm';
import { injectable } from 'inversify';
import { ITableDTO } from '../../Domain/ITableDTO';
import { ConnectedVolmaModalServerEntityEdit } from './VolmaModalServerEntityEdit';
import { ConnectedVolmaModalLocalEntityEdit } from './VolmaModalLocalEntityEdit';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { FilterDTO } from '../../Domain/DTO/FilterDTO';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { EFilterType } from '../../Domain/Enum/EFilterType';
import { DataTableDTO } from '../../Domain/DTO/DataTableDTO';
import { ConnectedVolmaModalNotification } from './VolmaModalNotification';
import { FileExtendedDTO } from '../File/FileExtendedDTO';
import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';

@injectable()
export class VolmaModalActions {

    private _apiActions: ApiActions;
    private _api: TableServerInteraction;

    constructor() {
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._api = VolmaContainer.get<TableServerInteraction>(Types.TableServerInteraction);
    }

    public OpenConfirmationModal(text: string, onConfirmed: () => void = () => null, onDeclined: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeConfirmModal(text, onConfirmed, onDeclined));
            dispatch(this.OpenModal(ConnectedVolmaModalConfirm))
        }
    }

    public OpenLoadingEndModal(text: string, onConfirmed: () => void = () => null, onDeclined: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeConfirmModal(text, onConfirmed, onDeclined));
            dispatch(this.OpenModal(ConnectedVolmaModalConfirm))
        }
    }

    public OpenNotificationModal(text: string, onClosed: () => void = () => null){
        return (dispatch) => {
            dispatch(this.InitializeNotificationModal(text, onClosed));
            dispatch(this.OpenModal(ConnectedVolmaModalNotification))
        }
    }

    public OpenServerEntityModal(entityType: EEntityType, entityId: string, params: any, onSaved: (dto: ITableDTO) => void = () => null, onCancelled: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeEditServerEntityModal(entityType, entityId, params, onSaved, onCancelled));
            dispatch(this.OpenModal(ConnectedVolmaModalServerEntityEdit))
        }
    }

    public OpenLocalEntityModal<TDTO>(entityType: EEntityType, entity: TDTO, header: string, onSaved: (dto: TDTO, dataHelper?: any) => void = () => null, onCancelled: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeEditLocalEntityModal(entityType, entity, header, onSaved, onCancelled));
            dispatch(this.OpenModal(ConnectedVolmaModalLocalEntityEdit))
        }
    }

    public OpenFileUploadModal(header: string, onSaved: (dto: FileExtendedDTO) => void = () => null, onCancelled: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeEditLocalEntityModal(EEntityType.File, new FileExtendedDTO(), header, onSaved, onCancelled));
            dispatch(this.OpenModal(ConnectedVolmaModalLocalEntityEdit))
        }
    }

    public OpenComplaintCommentModal(header: string, onSaved: (dto: ComplaintCommentDTO) => void = () => null, onCancelled: () => void = () => null) {
        return (dispatch) => {
            dispatch(this.InitializeEditLocalEntityModal(EEntityType.ComplaintComment, new ComplaintCommentDTO(), header, onSaved, onCancelled));
            dispatch(this.OpenModal(ConnectedVolmaModalLocalEntityEdit))
        }
    }

    public CloseModal(): IAction {
        return { type: VOLMA_MODAL_CLOSE }
    }

    public ShowConfirmOnUnsavedChanges(): IActionPayloaded<IConfirmOnUnsavedChangesPayload> {
        return { type: VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE, Payload: {ConfirmOnUnsavedChangesShown: true} }
    }

    public HideConfirmOnUnsavedChanges(): IActionPayloaded<IConfirmOnUnsavedChangesPayload> {
        return { type: VOLMA_MODAL_TOGGLE_CONFIRM_MESSAGE, Payload: {ConfirmOnUnsavedChangesShown: false} }
    }

    public SaveServerEntity(entity: EEntityType, baseEntity: EEntityType, dto: IEntityDTO, isFormValid: boolean, waitingText: string, id: string, onSaved: (dto: ITableDTO) => void, alwaysPost: boolean) {
        if(alwaysPost){
            id = undefined;
            dto.Id = undefined;
        }

        return (dispatch: any) => {

            dispatch(this.Submit());

            this._apiActions.DoApiRequest(
                dispatch,
                () => id === undefined ? this._api.PostEntity(baseEntity, dto) : this._api.PutEntity(baseEntity, id, dto),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = response.data;
                    if (data.Data !== undefined){
                        let filter = new FilterDTO();
                        filter.Key = PropertyHelper.GetPropertyName((x: ITableDTO) => x.Id);
                        filter.TextValue = data.Data;
                        filter.Type = EFilterType.Text;
                        this._apiActions.DoApiRequest(
                            dispatch,
                            () => this._api.GetTableData(baseEntity, undefined, undefined, [filter], undefined, undefined),
                            waitingText,
                            (response: AxiosResponse, dispatch: any) => {
                                let data: DataTableDTO = JSON.parse(response.data);
                                if (data.ItemsPerPage === 1){
                                    onSaved(data.Items[0]);
                                    dispatch(this.EntitySaved(data.Items[0]));
                                    dispatch(this.CloseModal());
                                }
                                else{
                                    dispatch(this.ServerError("Item was saved successfully, but failed to get table dto for the saved entity", response));
                                }
                            });
                    }
                    else
                        dispatch(this.ServerError(JSON.stringify(data), response));
                });
        }
    }

    public SaveLocalEntity(entity: EEntityType, dto: IEntityDTO, isFormValid: boolean, onSaved: (dto: ITableDTO, dataHelper?: any) => any, dataHelper?: any) {
        return (dispatch: any) => {

            dispatch(this.Submit());

            let res = onSaved(dto, dataHelper);
            if (PropertyHelper.IsPromise(res)){
                res.then(() => {
                    dispatch(this.EntitySaved(dto));
                    dispatch(this.CloseModal());
                })
            }
            else{
                dispatch(this.EntitySaved(dto));
                dispatch(this.CloseModal());
            }
        }
    }

    public SetEntityService(entityService: IEntityService<IEntityDTO, any>): IActionPayloaded<IEntityService<IEntityDTO, any>> {
        return { type: VOLMA_MODAL_SET_ENTITY_SERVICE, Payload: entityService };
    }

    public LoadServerEntity(entityService: IEntityService<IEntityDTO, any>, entity: EEntityType, baseEntity: EEntityType, waitingText: string, id?: string) {
        return (dispatch: any) => {

            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.GetEntity(baseEntity, id),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data.replace(/null/g, "\"\""));

                    entityService.OnAfterDataLoaded(data);
                    entityService.InitializeDefaultDTOValues(data)
                    dispatch(this.EntityLoaded(data));
                });
        }
    }

    public LoadLocalEntity(entityService: IEntityService<IEntityDTO, any>, dto: any) {
        return (dispatch) => {
            entityService.OnAfterDataLoaded(dto);
            dispatch(this.EntityLoaded(dto));
        }
    }

    public EntityLoaded(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_MODAL_DATA_LOADED, Payload: data };
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    private EntitySaved(data: ITableDTO): IActionPayloaded<ITableDTO> {
        return { type: VOLMA_MODAL_ENTITY_SAVED, Payload: data };
    }

    private InitializeEditServerEntityModal(type: EEntityType, id: string, params, onSaved: (dto: ITableDTO) => void = () => null, onCancelled: () => void = () => null): IActionPayloaded<IVolmaModalServerEntityEditPayload> {
        return { type: VOLMA_MODAL_INITIALIZE_ENTITY, Payload: { Type: type, Id: id, OnSaved: onSaved, OnCancelled: onCancelled,  Params: params} };
    }

    private InitializeEditLocalEntityModal<TDTO>(type: EEntityType, dto: TDTO, header: string, onSaved: (dto: TDTO, dataHelper?: any) => void = () => null, onCancelled: () => void = () => null,): IActionPayloaded<IVolmaModalLocalEntityEditPayload<TDTO>> {
       
        return { type: VOLMA_MODAL_INITIALIZE_ENTITY, Payload: { Type: type, DTO: dto, Header: header, OnSaved: onSaved, OnCancelled: onCancelled } };
    }

    private InitializeConfirmModal(text: string, onConfirmed: () => void = () => null, onDeclined: () => void = () => null): IActionPayloaded<IVolmaModalConfirmPayload> {
        return { type: VOLMA_MODAL_INITIALIZE_ENTITY, Payload: { Text: text, OnConfirmed: onConfirmed, OnDeclined: onDeclined } };
    }

    private InitializeNotificationModal(text: string, onClosed: () => void): IActionPayloaded<IVolmaModalNotificationPayload> {
        return { type: VOLMA_MODAL_INITIALIZE_ENTITY, Payload: { Text: text, OnClosed: onClosed } };
    }

    private OpenModal(content: any): IActionPayloaded<IVolmaModalPayload> {
        return { type: VOLMA_MODAL_OPEN, Payload: { ModalId: undefined, Content: content } };
    }

    private ServerError(message: string, response: AxiosResponse = <any>{}): IActionPayloaded<ServerErrorDTO> {
        return { type: REQUEST_SERVER_ERROR, Payload: { Data: JSON.stringify(response.data), Status: response.status, StatusText: response.statusText, Message: message, Url: response.config !== undefined ? response.config.url : "" } };
    }
}