import { volmaBlock } from '../../Infrastructure/Services/BEM';
import * as React from 'react';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { VolmaNumberValidator } from '../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaNumberProps } from './IVolmaNumberProps';
import { VolmaNumberActions } from './VolmaNumberActions';
import VolmaNumberReducer from './VolmaNumberReducer';
import { Component } from 'react';
import { LogService } from '../../Infrastructure/Services/LogService';
import i18next from '../i18n';

// Для хранения значения инпута был заведён реакт-стейт компонента, а значение инпута обновляется в сторе после событий onBlur вместо handleChange.
// Это сделано для того, чтобы можно было ввести дробное число с клавиатуры, а не только стрелочками вверх-вниз.
// Значение инпута приводится к float и записывается в стор после окончания ввода (onBlur).

type IVolmaNumberInputState = { inputValue: string };

class VolmaNumber extends Component<IVolmaNumberProps, IVolmaNumberInputState> {
    private _inputEl;
    private _validator: BaseValidator<IVolmaNumberProps>;
    private _defaultValidator: VolmaNumberValidator;
    private _numberReducer;

    private _logService: LogService;

    private _escPressed: boolean;
    private _upTimer: any;
    private _downTimer: any;
    private _timerIntervalMs: any = 150;

    private _precision: number;
    private _valueString: string;

    private _dotSymbol = '.';

    private _volmatInput = volmaBlock('volma-input');
    private _numberNav = volmaBlock('number-nav');
    private _defaultInputError = volmaBlock('default-input-error');
    private _volmaNumber = volmaBlock('volma-number');

    private _numberActions: VolmaNumberActions;

    constructor(props: IVolmaNumberProps, context: any) {
        super(props, context);

        this.state = { inputValue: '' };

        this._numberActions = VolmaContainer.get<VolmaNumberActions>(Types.VolmaNumberActions);
        this._defaultValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);
        this._numberReducer = VolmaContainer.get<VolmaNumberReducer>(Types.VolmaNumberReducer);

        this._logService = VolmaContainer.get<LogService>(Types.LogService);

        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.OnKeyPressStart = this.OnKeyPressStart.bind(this);
        this.OnKeyPressEnd = this.OnKeyPressEnd.bind(this);
        this.OnUpStart = this.OnUpStart.bind(this);
        this.OnUpEnd = this.OnUpEnd.bind(this);
        this.OnUp = this.OnUp.bind(this);
        this.OnDownStart = this.OnDownStart.bind(this);
        this.OnDownEnd = this.OnDownEnd.bind(this);
        this.OnDown = this.OnDown.bind(this);
    }

    public shouldComponentUpdate(nextProps: IVolmaNumberProps, nextState: IVolmaNumberInputState) {
        const shouldUpdate =
            nextProps.Value !== this.props.Value ||
            nextProps.Disabled !== this.props.Disabled ||
            nextProps.IsTouched !== this.props.IsTouched ||
            nextProps.IsSubmitted !== this.props.IsSubmitted ||
            nextProps.ErrorMessage !== this.props.ErrorMessage ||
            nextProps.IsValid !== this.props.IsValid ||
            nextProps.Unit !== this.props.Unit ||
            nextProps.Autofocus !== this.props.Autofocus ||
            nextState.inputValue !== this.state.inputValue;

        return shouldUpdate;
    }

    render() {
        let errors =
            !this.props.IsValid && (this.props.IsTouched || this.props.IsSubmitted) ? (
                <div className={this._defaultInputError().toString()}>
                    <div className={this._defaultInputError('text').toString()}>{this.props.ErrorMessage}</div>
                </div>
            ) : undefined;

        let inputProps = {
            ref: (ref) => (this._inputEl = ref),
            type: 'text',
            placeholder: '0',
            value: this.state.inputValue,
            onFocus: this.handleFocus,
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            className: this._volmatInput('input', { center: true }).mix(['input', 'input-number']),
            readOnly: this.props.Readonly === true,
        };

        return (
            <div>
                <div className={this._volmaNumber().toString()}>
                    {this.props.Label !== undefined && (
                        <div className={this._volmatInput.mix(this._volmaNumber('text')).toString()}>
                            <span
                                className={this._volmatInput('input', {
                                    text: true,
                                })
                                    .mix(['input'])
                                    .toString()}
                            >
                                {this.props.Label}
                            </span>
                        </div>
                    )}
                    <div className={this._volmatInput.mix(this._volmaNumber('value')).toString()}>
                        <input
                            {...inputProps}
                            value={this.state.inputValue}
                            onKeyDown={this.OnKeyPressStart}
                            onKeyUp={this.OnKeyPressEnd}
                        />
                        {this.props.HideUpDownBtns !== true && (
                            <div className={this._numberNav.toString()}>
                                <div
                                    className={this._numberNav('link', {
                                        up: true,
                                        readonly: this.props.Readonly === true,
                                    }).toString()}
                                    onMouseDown={this.OnUpStart}
                                    onMouseUp={this.OnUpEnd}
                                    onMouseLeave={this.OnUpEnd}
                                >
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                </div>
                                <div
                                    className={this._numberNav('link', {
                                        down: true,
                                        readonly: this.props.Readonly === true,
                                    }).toString()}
                                    onMouseDown={this.OnDownStart}
                                    onMouseUp={this.OnDownEnd}
                                    onMouseLeave={this.OnDownEnd}
                                >
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                    <svg className={this._numberNav('link-ico').toString()}>
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                    {this.props.Unit !== undefined && (
                        <div className={this._volmatInput.mix(this._volmaNumber('unit')).toString()}>
                            <span
                                className={this._volmatInput('input', {
                                    text: true,
                                })
                                    .mix(['input'])
                                    .toString()}
                                dangerouslySetInnerHTML={{
                                    __html: this.props.Unit
                                }}
                            ></span>
                        </div>
                    )}
                </div>
                {errors}
            </div>
        );
    }

    public componentDidMount() {
        let props: IVolmaNumberProps = {
            Value: this.props.Value,
            MinValue: this.props.MinValue,
            MaxValue: this.props.MaxValue,
            Step: this.props.Step || 1,
            Unit: this.props.Unit,
            CustomDataUpdate: this.props.CustomDataUpdate,
            OnLostFocus: this.props.OnLostFocus,
            HideUpDownBtns: this.props.HideUpDownBtns,
            Required: this.props.Required,
            Disabled: this.props.Disabled,
        };

        this.props.dispatch(
            this._numberActions.Register(
                this.props.Name,
                this._numberReducer,
                this.props.Validator || this._defaultValidator,
                props
            )
        );
        this.props.dispatch(this._numberActions.Validate(this.props.Name));

        if (this.props.Value) {
            this.setState({
                inputValue: this.props.Value.toString(),
            });
        }

        this._precision = this.GetPresision(this.props.Step);

        if (this.props.Autofocus === true) {
            setTimeout(() => {
                if (this._inputEl !== undefined && this._inputEl !== null) {
                    this._inputEl.focus();
                }
            }, 100);
        }
    }

    public componentDidUpdate(prevProps: Readonly<IVolmaNumberProps>, prevState: Readonly<IVolmaNumberInputState>, snapshot?: any): void {
        if (this.props.Value && prevProps.Value !== this.props.Value) {
            this.setState({
                inputValue: this.props.Value.toString(),
            });
        }
    }

    public componentWillUnmount() {
        clearInterval(this._upTimer);
        clearInterval(this._downTimer);
    }

    private handleChange(event: any) {
        if (this.props.Readonly === true) return;
        this._valueString = event.target.value;

        this._valueString = this._valueString.replace(/[^\d.-]/g, '');
        this.setState({ inputValue: this._valueString });
    }

    private handleFocus() {
        if (this.props.Readonly === true) return;
        if (!this.props.IsTouched) this.props.dispatch(this._numberActions.Touched(this.props.Name));
    }

    private handleBlur() {
        if (this.props.Readonly === true) return;
        if (!this.props.IsTouched) this.props.dispatch(this._numberActions.Touched(this.props.Name));

        const precision = Math.min(this._precision, this.GetSymbolsAfterDotCount(this.state.inputValue));
        const parsed = parseFloat(this.state.inputValue).toFixed(precision).toString();
        const numberValue = this.GetNumberValue(parsed);
        this.setState({ inputValue: numberValue.toString() });

        this.props.dispatch(this._numberActions.ChangeValue(this.props.Name, numberValue));
        this.props.dispatch(this._numberActions.Validate(this.props.Name));

        if (this.props.OnLostFocus !== undefined) {
            // Добавлено для того, чтобы в OnLostFocus мы могли использовать обновлённые значения из стора
            setTimeout(() => this.props.OnLostFocus(this._escPressed), 0);
        }
    }

    private OnKeyPressStart(event: any) {
        if (this.props.Readonly === true) return;

        this._escPressed = false;
        if (event.keyCode === 38)
            // up key
            this.OnUpStart();
        else if (event.keyCode === 40)
            // down key
            this.OnDownStart();
        else if (event.keyCode === 13 && this._inputEl !== undefined && this._inputEl !== null)
            // enter
            this._inputEl.blur();
        else if (event.keyCode === 27 && this._inputEl !== undefined && this._inputEl !== null) {
            // esc
            this._escPressed = true;
            this._inputEl.blur();
        } else {
            this._escPressed = false;
        }
    }

    private OnKeyPressEnd(event: any) {
        if (this.props.Readonly === true) return;

        if (event.keyCode === 38)
            // up key
            this.OnUpEnd();
        if (event.keyCode === 40)
            // down key
            this.OnDownEnd();
    }

    //  up
    private OnUpStart() {
        if (this.props.Readonly === true) return;

        if (!this.props.IsTouched) this.props.dispatch(this._numberActions.Touched(this.props.Name));

        let numberValue = this.GetIncrementedValue(
            parseFloat(this.state.inputValue),
            this.props.MaxValue,
            this.props.Step,
            this._precision
        );
        this.setState({ inputValue: numberValue.toString() });
        this.props.dispatch(this._numberActions.ChangeValue(this.props.Name, numberValue));

        clearInterval(this._upTimer);
        this._upTimer = setInterval(this.OnUp, this._timerIntervalMs);
    }

    private OnUpEnd() {
        if (this.props.Readonly === true) return;

        clearInterval(this._upTimer);
        this.props.dispatch(this._numberActions.Validate(this.props.Name));
    }

    private OnUp() {
        if (this.props.Readonly === true) return;

        let numberValue = this.GetIncrementedValue(
            parseFloat(this.state.inputValue),
            this.props.MaxValue,
            this.props.Step,
            this._precision
        );
        this.setState({ inputValue: numberValue.toString() });
        this.props.dispatch(this._numberActions.ChangeValue(this.props.Name, numberValue));
    }

    //  down
    private OnDownStart() {
        if (this.props.Readonly === true) return;

        if (!this.props.IsTouched) this.props.dispatch(this._numberActions.Touched(this.props.Name));

        let numberValue = this.GetDecrementedValue(
            parseFloat(this.state.inputValue),
            this.props.MinValue,
            this.props.Step,
            this._precision
        );
        this.setState({ inputValue: numberValue.toString() });
        this.props.dispatch(this._numberActions.ChangeValue(this.props.Name, numberValue));

        clearInterval(this._downTimer);
        this._downTimer = setInterval(this.OnDown, this._timerIntervalMs);
    }

    private OnDownEnd() {
        if (this.props.Readonly === true) return;

        clearInterval(this._downTimer);
        this.props.dispatch(this._numberActions.Validate(this.props.Name));
    }

    private OnDown() {
        if (this.props.Readonly === true) return;

        let numberValue = this.GetDecrementedValue(
            parseFloat(this.state.inputValue),
            this.props.MinValue,
            this.props.Step,
            this._precision
        );
        this.setState({ inputValue: numberValue.toString() });
        this.props.dispatch(this._numberActions.ChangeValue(this.props.Name, numberValue));
    }

    private GetPresision(step: number): number {
        if (step !== undefined && step !== null) {
            let stringValue = step.toString();
            let dotpos = stringValue.indexOf('.');
            if (dotpos >= 0) {
                return stringValue.slice(dotpos + 1).length;
            }
        }

        return 0;
    }

    private GetSymbolsAfterDotCount(value: string) {
        if (value.indexOf(this._dotSymbol) >= 0) {
            return value.length - 1 - value.indexOf(this._dotSymbol);
        }

        return 0;
    }

    public GetIncrementedValue(value: number, maxValue: () => number, step: number, precision: number) {
        let numValue = this.GetNumberValue((this.GetNumberValue(value) + step).toFixed(precision));
        if (maxValue !== undefined && maxValue() !== undefined) {
            numValue = Math.min(numValue, maxValue());
        }

        return numValue;
    }

    public GetDecrementedValue(value: number, minValue: () => number, step: number, precision: number) {
        let numValue = this.GetNumberValue((this.GetNumberValue(value) - step).toFixed(precision));
        if (minValue !== undefined && minValue !== undefined) {
            numValue = Math.max(numValue, minValue());
        }

        return numValue;
    }

    private GetNumberValue(value: string | number): number {
        let numValue: number = undefined;

        if (value === undefined || value === null) {
            numValue = 0;
        } else if (!isNaN(parseFloat(value as string))) {
            numValue = parseFloat(value as string);
        } else numValue = 0;

        return numValue;
    }
}

export default VolmaNumber;
