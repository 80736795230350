import { INPUT_REGISTER, INPUT_SET_VALIDATOR, INPUT_TOUCHED, INPUT_VALIDATE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IReduce } from '../../Infrastructure/Reducer/IReducer';
import { BaseValidator } from '../../Infrastructure/Validation/BaseValidator';
import { IRegisterPayload, ISetValidatorPayload, ITouchedPayload, IValidatePayload } from '../VolmaInput/Payloads';
import { injectable } from 'inversify';
import { IVolmaInputProps } from '../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../VolmaNumber/IVolmaNumberProps';

@injectable()
export class BaseInputActions {

    public SetValidator(name: string, validator: BaseValidator<any>): IActionPayloaded<ISetValidatorPayload> {
        return { type: INPUT_SET_VALIDATOR, Payload: { InputName: name, Validator: validator } };
    }

    public Touched(name: string): IActionPayloaded<ITouchedPayload> {
        return { type: INPUT_TOUCHED, Payload: { InputName: name } };
    }

    public Register(name: string, reducer: IReduce<any>, validator: BaseValidator<any>, props: any): IActionPayloaded<IRegisterPayload> {
        return { type: INPUT_REGISTER, Payload: { InputName: name, Reducer: reducer, Validator: validator, Props: props } };
    }

    public Validate(name: string): IActionPayloaded<IValidatePayload> {
        return { type: INPUT_VALIDATE, Payload: { InputName: name } };
    }
}