import { InstructionEntityService } from './../../Components/Entity/Instruction/InstructionEntityService';
import { InstructionDTO } from './../../Domain/DTO/InstructionDTO';
import { UpdatePasswordDTO } from '../../Domain/DTO/UpdatePasswordDTO';
import { SetRoleDTO } from '../../Domain/DTO/SetRoleDTO';
import {
    SelectDriverEntityService
} from '../../Components/Entity/CargoTransporter/Vehicle/SelectDriverDialog/SelectDriverEntityService';
import { IEntityService } from '../../Components/Entity/BaseEntity/IEntityService';
import { BodyTypeEntityService } from '../../Components/Entity/BodyType/BodyTypeEntityService';
import { CalendarEntryEntityService } from '../../Components/Entity/CalendarEntry/CalendarEntryEntityService';
import {
    CargoTransporterRouteRateEntityService
} from '../../Components/Entity/CargoTransporter/CargoTransporterRoteRate/CargoTransporterRouteRateEntityService';
import { DriverEntityService } from '../../Components/Entity/CargoTransporter/Driver/DriverEntityService';
import { ContractEntityService } from '../../Components/Entity/CargoTransporter/Contract/ContractEntityService';
import { RatingEntityService } from '../../Components/Entity/CargoTransporter/Rating/RatingEntityService';
import { VehicleEntityService } from '../../Components/Entity/CargoTransporter/Vehicle/VehicleEntityService';
import {
    CargoTransporterEntityService
} from '../../Components/Entity/CargoTransporterEntity/CargoTransporterEntityService';
import {
    CargoTransporterUserEntityService
} from '../../Components/Entity/CargoTransporterUser/CargoTransporterUserEntityService';
import { ComplaintEntityService } from '../../Components/Entity/Complaint/ComplaintEntityService';
import {
    ComplaintTemplateEntityService
} from '../../Components/Entity/ComplaintTemplate/ComplaintTemplateEntityService';
import { DeliveryEntityService } from '../../Components/Entity/Delivery/DeliveryEntityService';
import { HandlingTypeEntityService } from '../../Components/Entity/HandlingType/HandlingTypeEntityService';
import { LoadingPointEntityService } from '../../Components/Entity/LoadingPoint/LoadingPointTypeEntityService';
import { LocalityEntityService } from '../../Components/Entity/Locality/LocalityEntityService';
import { RouteRateEntityService } from '../../Components/Entity/RouteRate/RouteRateEntityService';
import { ShipperUserEntityService } from '../../Components/Entity/ShipperUser/ShipperUserEntityService';
import { TenderEntityService } from '../../Components/Entity/Tender/TenderEntityService';
import { ZoneEntityService } from '../../Components/Entity/Zone/ZoneEntityService';
import { IVolmaTableColumn } from '../../Components/Table/VolmaTable/IVolmaTableProps';
import { BodyTypeDTO } from '../../Domain/DTO/BodyTypeDTO';
import { BodyTypeTableDTO } from '../../Domain/DTO/BodyTypeTableDTO';
import { CalendarEntryDTO } from '../../Domain/DTO/CalendarEntryDTO';
import { CargoTransporterDTO } from '../../Domain/DTO/CargoTransporterDTO';
import { CargoTransporterRouteRateTableDTO } from '../../Domain/DTO/CargoTransporterRouteRateTableDTO';
import { CargoTransporterTableDTO } from '../../Domain/DTO/CargoTransporterTableDTO';
import { CargoTransporterUserDTO } from '../../Domain/DTO/CargoTransporterUserDTO';
import { CargoTransporterUserTableDTO } from '../../Domain/DTO/CargoTransporterUserTableDTO';
import { ComplaintTableDTO } from '../../Domain/DTO/ComplaintTableDTO';
import { ComplaintTemplateDTO } from '../../Domain/DTO/ComplaintTemplateDTO';
import { ComplaintTemplateTableDTO } from '../../Domain/DTO/ComplaintTemplateTableDTO';
import { DeliveryDTO } from '../../Domain/DTO/DeliveryDTO';
import { DeliveryTableDTO } from '../../Domain/DTO/DeliveryTableDTO';
import { DriverDTO } from '../../Domain/DTO/DriverDTO';
import { DriverTableDTO } from '../../Domain/DTO/DriverTableDTO';
import { HandlingTypeDTO } from '../../Domain/DTO/HandlingTypeDTO';
import { HandlingTypeTableDTO } from '../../Domain/DTO/HandlingTypeTableDTO';
import { LoadingPointDTO } from '../../Domain/DTO/LoadingPointDTO';
import { LoadingPointTableDTO } from '../../Domain/DTO/LoadingPointTableDTO';
import { LocalityDTO } from '../../Domain/DTO/LocalityDTO';
import { LocalityTableDTO } from '../../Domain/DTO/LocalityTableDTO';
import { RouteRateDTO } from '../../Domain/DTO/RouteRateDTO';
import { RouteRateTableDTO } from '../../Domain/DTO/RouteRateTableDTO';
import { ShipperUserDTO } from '../../Domain/DTO/ShipperUserDTO';
import { ShipperUserTableDTO } from '../../Domain/DTO/ShipperUserTableDTO';
import { TenderDTO } from '../../Domain/DTO/TenderDTO';
import { TenderTableDTO } from '../../Domain/DTO/TenderTableDTO';
import { VehicleDTO } from '../../Domain/DTO/VehicleDTO';
import { VehicleTableDTO } from '../../Domain/DTO/VehicleTableDTO';
import { ZoneDTO } from '../../Domain/DTO/ZoneDTO';
import { ZoneTableDTO } from '../../Domain/DTO/ZoneTableDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../InversifyInject';
import { RouterService } from '../Router/RouterService';
import { Types } from '../Types';
import { LogService } from './LogService';
import { injectable } from 'inversify';
import { RoutePointEntityService } from '../../Components/Entity/Delivery/RouteSegment/RoutePointEntityService';
import { DeliveryConfirmDTO } from '../../Components/Entity/Delivery/ActionConfirm/DeliveryConfirmDTO';
import { DeliveryConfirmEntityService } from '../../Components/Entity/Delivery/ActionConfirm/DeliveryConfirmEntityService';
import PropertyHelper from './PropertyHelper';
import { TenderBetDTO } from '../../Domain/DTO/TenderBetDTO';
import { TenderBetTableDTO } from '../../Domain/DTO/TenderBetTableDTO';
import { SelectDriverDTO } from '../../Components/Entity/CargoTransporter/Vehicle/SelectDriverDialog/SelectDriverDTO';
import { SelectVehicleDTO } from '../../Components/Entity/CargoTransporter/Driver/SelectVehicleDialog/SelectVehicleDTO';
import { SelectVehicleEntityService } from '../../Components/Entity/CargoTransporter/Driver/SelectVehicleDialog/SelectVehicleEntityService';
import { SelectContractEntityService } from '../../Components/Entity/CargoTransporter/Contract/SelectContractDialog/SelectContractEntityService';
import { TenderBetEntityService } from '../../Components/Entity/Tender/TenderBetEntityService';
import { UserRoleEntityService } from '../../Components/Entity/Role/UserRoleEntityService';
import { ShipperDTO } from '../../Domain/DTO/ShipperDTO';
import { ShipperEntityService } from '../../Components/Entity/Shipper/ShipperEntityService';
import { DeliveryToAcceptConfirmEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryToAcceptConfirmEntityService';
import { DeliveryAuctionEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryAuctionEntityService';
import { DeliveryManualEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryManualEntityService';
import { DeliveryWithClaimEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryWithClaimEntityService';
import { DeliveryPerformingEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryPerformingEntityService';
import { DeliveryActiveEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryActiveEntityService';
import { DeliveryFutureEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryFutureEntityService';
import { DeliveryFinishedEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryFinishedEntityService';
import { TenderFinishedEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderFinishedEntityService';
import { TenderActiveEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderActiveEntityService';
import { TenderFutureEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderFutureEntityService';
import { TenderInactiveEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderInactiveEntityService';
import { TenderBettingPerformigEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderBettingPerformingEntityService';
import { BiddingBetEntityService } from '../../Components/Entity/Delivery/ActionBet/BiddingBetEntityService';
import { BiddingBetTableDTO } from '../../Domain/DTO/BiddingBetTableDTO';
import { DeliveryManualAssignDTO } from '../../Domain/DTO/DeliveryManualAssignDTO';
import { DeliveryManualAssignEntityService } from '../../Components/Entity/Delivery/ActionManualAssign/DeliveryManualAssignEntityService';
import { DeliveryUpdateAssignTypeEntityService } from '../../Components/Entity/Delivery/ActionUpdateAssignType/DeliveryUpdateAssignTypeEntityService';
import { DeliveryUpdateAssignTypeDTO } from '../../Domain/DTO/DeliveryUpdateAssignTypeDTO';
import { DeliveryWithdrawDTO } from '../../Domain/DTO/DeliveryWithdrawDTO';
import { DeliveryWithdrawEntityService } from '../../Components/Entity/Delivery/ActionWithdraw/DeliveryWithdrawEntityService';
import { TenderCancelDTO } from '../../Domain/DTO/TenderCancelDTO';
import { TenderWithdrawEntityService } from '../../Components/Entity/Tender/Withdraw/TenderWithdrawEntityService';
import { RouteSegmentDTO } from '../../Domain/DTO/RouteSegmentDTO';
import { PayloadEntityService } from '../../Components/Entity/Payload/PayloadEntityService';
import { PayloadTableDTO } from '../../Domain/DTO/PayloadTableDTO';
import { RatingTableDTO } from '../../Domain/DTO/RatingTableDTO';
import { PayloadDTO } from '../../Domain/DTO/PayloadDTO';
import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { UpdatePasswordEntityService } from '../../Components/Entity/UpdatePassword/UpdatePasswordEntityService';
import { ConsignorTableDTO } from '../../Domain/DTO/ConsignorTableDTO';
import { ConsignorDTO } from '../../Domain/DTO/ConsignorDTO';
import { DeliveryMarkAsDeliveredEntityService } from '../../Components/Entity/Delivery/ActionMarkAsDelivered/DeliveryMarkAsDeliveredEntityService';
import { ApiMessageLogDTO } from '../../Domain/DTO/ApiMessageLogDTO';
import { RequestLogLogEntityService } from '../../Components/Entity/RequestLog/RequestLogEntityService';
import { TenderDraftEntityService } from '../../Components/Entity/Tender/Subsidiary/TenderDraftEntityService';
import { FileDTO } from '../../Domain/DTO/FileDTO';
import { FileEntityService } from '../../Components/File/FileEntityService';
import { DeliveryNotExecutedEntityService } from '../../Components/Entity/Delivery/ActionNotExecuted/DeliveryNotExecutedEntityService';
import { ComplaintCommentEntityService } from '../../Components/Entity/Complaint/Comment/ComplaintCommentEntityService';
import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';
import { AboutEntityService } from '../../Components/Entity/About/AboutEntityService';
import { ServicePartsVersionDTO } from '../../Domain/DTO/ServicePartsVersionDTO';
import { PortalConfigurationEntityService } from '../../Components/Entity/PortalConfiguration/PortalConfigurationEntityService';
import { SettingsEntityService } from '../../Components/Entity/Settings/SettingsEntityService';
import { ReportEntityService } from '../../Components/Entity/Report/ReportEntityService';
import { ComplaintDraftEntityService } from '../../Components/Entity/Complaint/Subsidiaries/ComplaintDraftEntityService';
import { ComplaintActiveEntityService } from '../../Components/Entity/Complaint/Subsidiaries/ComplaintActiveEntityService';
import { ComplaintClosedEntityService } from '../../Components/Entity/Complaint/Subsidiaries/ComplaintClosedEntityService';
import { ComplaintWithNewMessagesEntityService } from '../../Components/Entity/Complaint/Subsidiaries/ComplaintWithNewMessagesEntityService';
import { ITableDTO } from '../../Domain/ITableDTO';
import { DeliveryOneCommentEntityService } from '../../Components/Entity/Delivery/ActionOneComment/DeliveryOneCommentEntityService';
import { BiddingEntityService } from '../../Components/Entity/Bidding/BiddingEntityService';
import { EntityUpdatedDTO } from '../../Domain/DTO/EntityUpdatedDTO';
import { ContractTableDTO } from '../../Domain/DTO/ContractTableDTO';
import { ContractDTO } from '../../Domain/DTO/ContractDTO';
import { ConsigneeDTO } from '../../Domain/DTO/ConsigneeDTO';
import { ConsignorEntityService } from '../../Components/Entity/Consignor/ConsignorEntityService';
import { ConsigneeEntityService } from '../../Components/Entity/Consignee/ConsigneeEntityService';
import { DriverMonitoringEntityService } from '../../Components/Entity/DriverMonitoring/DriverMonitoringEntityService';
import { MultiFactoryEntityService } from '../../Components/Entity/MultiFactory/MultiFactoryEntityService';
import { MultiFactoryTableDTO } from '../../Domain/DTO/MultiFactoryTableDTO';
import { MultiFactoryDTO } from '../../Domain/DTO/MultiFactoryDTO';
import { MultiCargoTransporterEntityService } from '../../Components/Entity/CargoTransporter/MultiCargoTransporter/MultiCargoTransporterEntityService';
import { MultiCargoTransporterTableDTO } from '../../Domain/DTO/MultiCargoTransporterTableDTO';
import { MultiCargoTransporterDTO } from '../../Domain/DTO/MultiCargoTransporterDTO';
import { DeliveryDriverMonitoringDTO } from '../../Components/Entity/DriverMonitoring/IHelperProps';
import { DeliveryForComplaintEntityService } from '../../Components/Entity/Delivery/Subsidiary/DeliveryForComplaint';
import { InstructionTableDTO } from '../../Domain/DTO/InstructionTableDTO';

interface IEntityMetadata{
    EntityType: EEntityType;
    EntityTypeString: string;
    EntityRoute: string;
    TableRoute  : string;
    TableColumns: Array<IVolmaTableColumn>;
    TableDefaultSort: string;
    DefaultNameProperty: string;
    TableHeader: string;
    AddEntity: string;
    EntityHeader: string;
    EntityService: IEntityService<any, any>;
    DefaultSelectLabelGetter: (x: ITableDTO) => string;
    OnEntityUpdated: (dto: EntityUpdatedDTO, debounce:() => void, dispatchFilteredData:() => void) => void;
}

@injectable()
export class EntityService {
    private _logger: LogService;
    private _routerService: RouterService;
    private _metadata: Array<IEntityMetadata>;
    private _payloadEntityService                  : PayloadEntityService;
    private _zoneEntityService                     : ZoneEntityService;
    private _localityEntityService                 : LocalityEntityService;
    private _bodyTypeEntityService                 : BodyTypeEntityService;
    private _handlingTypeEntityService             : HandlingTypeEntityService;
    private _loadingPointEntityService             : LoadingPointEntityService;
    private _routeRateEntityService                : RouteRateEntityService;
    private _consignorEntityService                : ConsignorEntityService;
    private _consigneeEntityService                : ConsigneeEntityService;
    private _complaintTemplateEntityService        : ComplaintTemplateEntityService;
    private _multiFactoryEntityService             : MultiFactoryEntityService;
    private _multiCargoTransporterEntityService    : MultiCargoTransporterEntityService;
    private _driverEntityService                   : DriverEntityService;
    private _contractEntityService                 : ContractEntityService;
    private _vehicleEntityService                  : VehicleEntityService;
    private _shipperUserEntityService              : ShipperUserEntityService;
    private _cargoTransporterUserEntityService     : CargoTransporterUserEntityService;
    private _cargoTransporterEntityService         : CargoTransporterEntityService;
    private _deliveryEntityService                 : DeliveryEntityService;
    private _deliveryFinishedEntityService         : DeliveryFinishedEntityService;
    private _deliveryFutureEntityService           : DeliveryFutureEntityService;
    private _deliveryActiveEntityService           : DeliveryActiveEntityService;
    private _deliveryPerformingEntityService       : DeliveryPerformingEntityService;
    private _deliveryWithClaimEntityService        : DeliveryWithClaimEntityService;
    private _deliveryManualEntityService           : DeliveryManualEntityService;
    private _deliveryAuctionEntityService          : DeliveryAuctionEntityService;
    private _deliveryToAcceptConfirmEntityService  : DeliveryToAcceptConfirmEntityService;
    private _deliveryManualAssignEntityService     : DeliveryManualAssignEntityService;
    private _deliveryUpdateAssignTypeEntityService : DeliveryUpdateAssignTypeEntityService;
    private _deliveryWithdrawEntityService         : DeliveryWithdrawEntityService;
    private _deliveryMarkAsDeliveredEntityService  : DeliveryMarkAsDeliveredEntityService;
    private _deliveryNotExecutedEntityService      : DeliveryNotExecutedEntityService;
    private _deliveryOneCommentEntityService       : DeliveryOneCommentEntityService;
    private _biddingBetEntityService               : BiddingBetEntityService;
    private _tenderEntityService                   : TenderEntityService;
    private _tenderFinishedEntityService           : TenderFinishedEntityService;
    private _tenderActiveEntityService             : TenderActiveEntityService;
    private _tenderBettingPerformingEntityService  : TenderBettingPerformigEntityService;
    private _tenderFutureEntityService             : TenderFutureEntityService;
    private _tenderInactiveEntityService           : TenderInactiveEntityService;
    private _tenderDraftEntityService              : TenderDraftEntityService;
    private _tenderBetEntityService                : TenderBetEntityService;
    private _tenderWithdrawEntityService           : TenderWithdrawEntityService;
    private _complaintEntityService                : ComplaintEntityService;
    private _cargoTransporterRouteRateEntityService: CargoTransporterRouteRateEntityService;
    private _calendarEntryEntityService            : CalendarEntryEntityService;
    private _ratingEntityService                   : RatingEntityService;
    private _routePointEntityService               : RoutePointEntityService;
    private _deliveryConfirmEntityService          : DeliveryConfirmEntityService;
    private _userRoleEntityService                 : UserRoleEntityService;
    private _selectDriverEntityService             : SelectDriverEntityService;
    private _selectVehicleEntityService            : SelectVehicleEntityService;
    private _selectContractEntityService           : SelectContractEntityService;
    private _shipperEntityService                  : ShipperEntityService;
    private _updatePasswordEntityService           : UpdatePasswordEntityService;
    private _requestLogLogEntityService            : RequestLogLogEntityService;
    private _fileEntityService                     : FileEntityService;
    private _complaintCommentEntityService         : ComplaintCommentEntityService;
    private _aboutEntityService                    : AboutEntityService;
    private _settingsEntityService                 : SettingsEntityService;
    private _configurationEntityService            : PortalConfigurationEntityService;
    private _reportEntityService                   : ReportEntityService;
    private _complaintDraftEntityService           : ComplaintDraftEntityService;
    private _complaintActiveEntityService          : ComplaintActiveEntityService;
    private _complaintWithNewMessagesEntityService : ComplaintWithNewMessagesEntityService;
    private _complaintClosedEntityService          : ComplaintClosedEntityService;
    private _biddingEntityService                  : BiddingEntityService;
    private _driverMonitoringEntityService         : DriverMonitoringEntityService;
    private _deliveryForComplaintEntityService     : DeliveryForComplaintEntityService;
    private _instructionEntityService              : InstructionEntityService;

    constructor() {
        this._logger = VolmaContainer.get<LogService>(Types.LogService);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this._payloadEntityService                   = VolmaContainer.get<PayloadEntityService>(Types.PayloadEntityService);
        this._zoneEntityService                      = VolmaContainer.get<ZoneEntityService>(Types.ZoneEntityService);
        this._localityEntityService                  = VolmaContainer.get<LocalityEntityService>(Types.LocalityEntityService);
        this._bodyTypeEntityService                  = VolmaContainer.get<BodyTypeEntityService>(Types.BodyTypeEntityService);
        this._handlingTypeEntityService              = VolmaContainer.get<HandlingTypeEntityService>(Types.HandlingTypeEntityService);
        this._loadingPointEntityService              = VolmaContainer.get<LoadingPointEntityService>(Types.LoadingPointEntityService);
        this._routeRateEntityService                 = VolmaContainer.get<RouteRateEntityService>(Types.RouteRateEntityService);
        this._consignorEntityService                 = VolmaContainer.get<ConsignorEntityService>(Types.ConsignorEntityService);
        this._complaintTemplateEntityService         = VolmaContainer.get<ComplaintTemplateEntityService>(Types.ComplaintTemplateEntityService);
        this._multiFactoryEntityService              = VolmaContainer.get<MultiFactoryEntityService>(Types.MultiFactoryEntityService);
        this._multiCargoTransporterEntityService     = VolmaContainer.get<MultiCargoTransporterEntityService>(Types.MultiCargoTransporterEntityService);
        this._driverEntityService                    = VolmaContainer.get<DriverEntityService>(Types.DriverEntityService);
        this._contractEntityService                  = VolmaContainer.get<ContractEntityService>(Types.ContractEntityService);
        this._vehicleEntityService                   = VolmaContainer.get<VehicleEntityService>(Types.VehicleEntityService);
        this._shipperUserEntityService               = VolmaContainer.get<ShipperUserEntityService>(Types.ShipperUserEntityService);
        this._cargoTransporterUserEntityService      = VolmaContainer.get<CargoTransporterUserEntityService>(Types.CargoTransporterUserEntityService);
        this._cargoTransporterEntityService          = VolmaContainer.get<CargoTransporterEntityService>(Types.CargoTransporterEntityService);
        this._deliveryEntityService                  = VolmaContainer.get<DeliveryEntityService>(Types.DeliveryEntityService);
        this._deliveryEntityService                  = VolmaContainer.get<DeliveryEntityService>(Types.DeliveryEntityService);
        this._deliveryFinishedEntityService          = VolmaContainer.get<DeliveryFinishedEntityService>(Types.DeliveryFinishedEntityService);
        this._deliveryFutureEntityService            = VolmaContainer.get<DeliveryFutureEntityService>(Types.DeliveryFutureEntityService);
        this._deliveryActiveEntityService            = VolmaContainer.get<DeliveryActiveEntityService>(Types.DeliveryActiveEntityService);
        this._deliveryPerformingEntityService        = VolmaContainer.get<DeliveryPerformingEntityService>(Types.DeliveryPerformingEntityService);
        this._deliveryWithClaimEntityService         = VolmaContainer.get<DeliveryWithClaimEntityService>(Types.DeliveryWithClaimEntityService);
        this._deliveryManualEntityService            = VolmaContainer.get<DeliveryManualEntityService>(Types.DeliveryManualEntityService);
        this._deliveryAuctionEntityService           = VolmaContainer.get<DeliveryAuctionEntityService>(Types.DeliveryAuctionEntityService);
        this._deliveryToAcceptConfirmEntityService   = VolmaContainer.get<DeliveryToAcceptConfirmEntityService>(Types.DeliveryToAcceptConfirmEntityService);
        this._deliveryManualAssignEntityService      = VolmaContainer.get<DeliveryManualAssignEntityService>(Types.DeliveryManualAssignEntityService);
        this._deliveryUpdateAssignTypeEntityService  = VolmaContainer.get<DeliveryUpdateAssignTypeEntityService>(Types.DeliveryUpdateAssignTypeEntityService);
        this._deliveryWithdrawEntityService          = VolmaContainer.get<DeliveryWithdrawEntityService>(Types.DeliveryWithdrawEntityService);
        this._deliveryMarkAsDeliveredEntityService   = VolmaContainer.get<DeliveryMarkAsDeliveredEntityService>(Types.DeliveryMarkAsDeliveredEntityService);
        this._deliveryNotExecutedEntityService       = VolmaContainer.get<DeliveryNotExecutedEntityService>(Types.DeliveryNotExecutedEntityService);
        this._deliveryOneCommentEntityService        = VolmaContainer.get<DeliveryOneCommentEntityService>(Types.DeliveryOneCommentEntityService);
        this._biddingBetEntityService                = VolmaContainer.get<BiddingBetEntityService>(Types.BiddingBetEntityService);
        this._tenderEntityService                    = VolmaContainer.get<TenderEntityService>(Types.TenderEntityService);
        this._tenderFinishedEntityService            = VolmaContainer.get<TenderFinishedEntityService>(Types.TenderFinishedEntityService);
        this._tenderActiveEntityService              = VolmaContainer.get<TenderActiveEntityService>(Types.TenderActiveEntityService);
        this._tenderBettingPerformingEntityService   = VolmaContainer.get<TenderBettingPerformigEntityService>(Types.TenderBettingPerformingEntityService);
        this._tenderFutureEntityService              = VolmaContainer.get<TenderFutureEntityService>(Types.TenderFutureEntityService);
        this._tenderInactiveEntityService            = VolmaContainer.get<TenderInactiveEntityService>(Types.TenderInactiveEntityService);
        this._tenderDraftEntityService               = VolmaContainer.get<TenderInactiveEntityService>(Types.TenderDraftEntityService);
        this._tenderBetEntityService                 = VolmaContainer.get<TenderBetEntityService>(Types.TenderBetEntityService);
        this._tenderWithdrawEntityService            = VolmaContainer.get<TenderWithdrawEntityService>(Types.TenderWithdrawEntityService);
        this._complaintEntityService                 = VolmaContainer.get<ComplaintEntityService>(Types.ComplaintEntityService);
        this._cargoTransporterRouteRateEntityService = VolmaContainer.get<CargoTransporterRouteRateEntityService>(Types.CargoTransporterRouteRateEntityService);
        this._calendarEntryEntityService             = VolmaContainer.get<CalendarEntryEntityService>(Types.CalendarEntryEntityService);
        this._ratingEntityService                    = VolmaContainer.get<RatingEntityService>(Types.RatingEntityService);
        this._routePointEntityService                = VolmaContainer.get<RoutePointEntityService>(Types.RoutePointEntityService);
        this._deliveryConfirmEntityService           = VolmaContainer.get<DeliveryConfirmEntityService>(Types.DeliveryConfirmEntityService);
        this._userRoleEntityService                  = VolmaContainer.get<UserRoleEntityService>(Types.UserRoleEntityService);
        this._selectDriverEntityService              = VolmaContainer.get<SelectDriverEntityService>(Types.SelectDriverEntityService);
        this._selectVehicleEntityService             = VolmaContainer.get<SelectVehicleEntityService>(Types.SelectVehicleEntityService);
        this._selectContractEntityService            = VolmaContainer.get<SelectContractEntityService>(Types.SelectContractEntityService);
        this._shipperEntityService                   = VolmaContainer.get<ShipperEntityService>(Types.ShipperEntityService);
        this._updatePasswordEntityService            = VolmaContainer.get<UpdatePasswordEntityService>(Types.UpdatePasswordEntityService);
        this._requestLogLogEntityService             = VolmaContainer.get<RequestLogLogEntityService>(Types.RequestLogLogEntityService);
        this._fileEntityService                      = VolmaContainer.get<FileEntityService>(Types.FileEntityService);
        this._complaintCommentEntityService          = VolmaContainer.get<ComplaintCommentEntityService>(Types.ComplaintCommentEntityService);
        this._aboutEntityService                     = VolmaContainer.get<AboutEntityService>(Types.AboutEntityService);
        this._settingsEntityService                  = VolmaContainer.get<SettingsEntityService>(Types.SettingsEntityService);
        this._configurationEntityService             = VolmaContainer.get<PortalConfigurationEntityService>(Types.PortalConfigurationEntityService);
        this._reportEntityService                    = VolmaContainer.get<ReportEntityService>(Types.ReportEntityService);
        this._complaintDraftEntityService            = VolmaContainer.get<ComplaintDraftEntityService>(Types.ComplaintDraftEntityService);
        this._complaintActiveEntityService           = VolmaContainer.get<ComplaintActiveEntityService>(Types.ComplaintActiveEntityService);
        this._complaintWithNewMessagesEntityService  = VolmaContainer.get<ComplaintWithNewMessagesEntityService>(Types.ComplaintWithNewMessagesEntityService);
        this._complaintClosedEntityService           = VolmaContainer.get<ComplaintClosedEntityService>(Types.ComplaintClosedEntityService);
        this._biddingEntityService                   = VolmaContainer.get<BiddingEntityService>(Types.BiddingEntityService);
        this._consigneeEntityService                 = VolmaContainer.get<ConsigneeEntityService>(Types.ConsigneeEntityService);
        this._driverMonitoringEntityService          = VolmaContainer.get<DriverMonitoringEntityService>(Types.DriverMonitoringEntityService);
        this._deliveryForComplaintEntityService      = VolmaContainer.get<DeliveryForComplaintEntityService>(Types.DeliveryForComplaintEntityService);
        this._instructionEntityService               = VolmaContainer.get<InstructionEntityService>(Types.InstructionEntityService);

        this._metadata = new Array<IEntityMetadata>();

        this.Initialize();
    }

    private Initialize(): void{

        let defaultSortColumn = PropertyHelper.GetPropertyName((x: ZoneTableDTO) => x.Name);
        let defaultNameColumn = PropertyHelper.GetPropertyName((x: ZoneDTO) => x.Code);
        let defaultSelectItemName = (x: ZoneTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.Zone, this._zoneEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ConsigneeDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ConsigneeDTO) => x.Name);
        defaultSelectItemName = (x: ConsigneeDTO) => x.Name;
        this.AddMetadata(EEntityType.Consignee, this._consigneeEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: LocalityTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: LocalityDTO) => x.Code);
        defaultSelectItemName = (x: LocalityTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.Locality, this._localityEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: BodyTypeTableDTO) => x.Type);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: BodyTypeDTO) => x.Type);
        defaultSelectItemName = (x: BodyTypeTableDTO) => x.Type + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.BodyType, this._bodyTypeEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: HandlingTypeTableDTO) => x.Type);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: HandlingTypeDTO) => x.Code);
        defaultSelectItemName = (x: HandlingTypeTableDTO) => x.Type + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.HandlingType, this._handlingTypeEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: LoadingPointTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: LoadingPointDTO) => x.Code);
        defaultSelectItemName = (x: LoadingPointTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.LoadingPoint, this._loadingPointEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: PayloadTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: PayloadDTO) => x.Code);
        defaultSelectItemName = (x: PayloadTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.Payload, this._payloadEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: RouteRateTableDTO) => x.LoadingPointName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: RouteRateDTO) => x.Rate);
        defaultSelectItemName = (x: RouteRateTableDTO) => x.LoadingPointName + " - " + x.TargetZoneName;
        this.AddMetadata(EEntityType.RouteRate, this._routeRateEntityService, defaultSortColumn, defaultNameColumn), defaultSelectItemName;

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ConsignorTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ConsignorDTO) => x.Name);
        defaultSelectItemName = (x: ConsignorTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.Consignor, this._consignorEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ComplaintTemplateDTO) => x.Name);
        defaultSelectItemName = (x: ComplaintTemplateTableDTO) => x.Name;
        this.AddMetadata(EEntityType.ComplaintTemplate, this._complaintTemplateEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: MultiFactoryTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: MultiFactoryDTO) => x.Name);
        defaultSelectItemName = (x: MultiFactoryTableDTO) => x.Name;
        this.AddMetadata(EEntityType.MultiFactory, this._multiFactoryEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: MultiCargoTransporterTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: MultiCargoTransporterDTO) => x.Name);
        defaultSelectItemName = (x: MultiCargoTransporterTableDTO) => x.Name;
        this.AddMetadata(EEntityType.MultiCargoTransporter, this._multiCargoTransporterEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DriverDTO) => x.Name);
        defaultSelectItemName = (x: DriverTableDTO) => x.Name;
        this.AddMetadata(EEntityType.Driver, this._driverEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ContractTableDTO) => x.EndTime);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ContractDTO) => x.ContractNumber);
        defaultSelectItemName = (x: ContractTableDTO) => x.ContractNumber;
        this.AddMetadata(EEntityType.Contract, this._contractEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);


        defaultSortColumn = PropertyHelper.GetPropertyName((x: VehicleTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: VehicleDTO) => x.VehicleLicensePlateNumber);
        defaultSelectItemName = (x: VehicleTableDTO) => x.Name + " (" + x.VehicleLicensePlateNumber + ")";
        this.AddMetadata(EEntityType.Vehicle, this._vehicleEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ShipperUserTableDTO) => x.UserName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ShipperUserDTO) => x.Fio);
        defaultSelectItemName = (x: ShipperUserTableDTO) => x.Fio;
        this.AddMetadata(EEntityType.ShipperUser, this._shipperUserEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: CargoTransporterUserTableDTO) => x.UserName)
        defaultNameColumn = PropertyHelper.GetPropertyName((x: CargoTransporterUserDTO) => x.UserName);
        defaultSelectItemName = (x: CargoTransporterUserTableDTO) => x.Fio;
        this.AddMetadata(EEntityType.CargoTransporterUser, this._cargoTransporterUserEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: CargoTransporterTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: CargoTransporterDTO) => x.Name);
        defaultSelectItemName = (x: CargoTransporterTableDTO) => x.Name + " (" + x.Code + ")";
        this.AddMetadata(EEntityType.CargoTransporter, this._cargoTransporterEntityService, defaultSortColumn, defaultNameColumn, defaultSelectItemName);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.Identifier);
        this.AddMetadata(EEntityType.Delivery, this._deliveryEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryFinished, this._deliveryFinishedEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryFuture, this._deliveryFutureEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryActive, this._deliveryActiveEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryPerforming, this._deliveryPerformingEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryWithClaim, this._deliveryWithClaimEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryManual, this._deliveryManualEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryAuction, this._deliveryAuctionEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryToAcceptConfirm, this._deliveryToAcceptConfirmEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryMarkAsDelivered, this._deliveryMarkAsDeliveredEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryMarkAsNotExecuted, this._deliveryNotExecutedEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.DeliveryOneComment, this._deliveryOneCommentEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: BiddingBetTableDTO) => x.CargoTransporterName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: BiddingBetDTO) => x.Bet);
        this.AddMetadata(EEntityType.BiddingBet, this._biddingBetEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.DriverId);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.DriverId);
        this.AddMetadata(EEntityType.DeliveryConfirm, this._deliveryConfirmEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryManualAssignDTO) => x.CargoTransporterId);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryManualAssignDTO) => x.CargoTransporterId);
        this.AddMetadata(EEntityType.DeliveryManualAssign, this._deliveryManualAssignEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryUpdateAssignTypeDTO) => x.AssignType);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryUpdateAssignTypeDTO) => x.AssignType);
        this.AddMetadata(EEntityType.DeliveryUpdateAssignType, this._deliveryUpdateAssignTypeEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryWithdrawDTO) => x.CommentForShipper);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryWithdrawDTO) => x.CommentForCargotransporter);
        this.AddMetadata(EEntityType.DeliveryWithdraw, this._deliveryWithdrawEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: TenderTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: TenderDTO) => x.Identifier);
        this.AddMetadata(EEntityType.Tender, this._tenderEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderFinished, this._tenderFinishedEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderActive, this._tenderActiveEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderBettingPerforming, this._tenderBettingPerformingEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderFuture, this._tenderFutureEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderInactive, this._tenderInactiveEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.TenderDraft, this._tenderDraftEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: TenderBetTableDTO) => x.CargoTransporterName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: TenderBetDTO) => x.Bet);
        this.AddMetadata(EEntityType.TenderBet, this._tenderBetEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: TenderCancelDTO) => x.CommentForShipper);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: TenderCancelDTO) => x.CommentForCargoTransporter);
        this.AddMetadata(EEntityType.TenderWithdraw, this._tenderWithdrawEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ComplaintTableDTO) => x.DateOfLastUpdate);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ComplaintTableDTO) => x.DateOfLastUpdate);
        this.AddMetadata(EEntityType.Complaint, this._complaintEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: CargoTransporterRouteRateTableDTO) => x.LoadingPointName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: CargoTransporterRouteRateTableDTO) => x.LoadingPointName);
        this.AddMetadata(EEntityType.CargoTransporterRouteRate, this._cargoTransporterRouteRateEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: CalendarEntryDTO) => x.Date);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: CalendarEntryDTO) => x.Date);
        this.AddMetadata(EEntityType.CalendarEntry, this._calendarEntryEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        this.AddMetadata(EEntityType.CurrentRating, this._ratingEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        this.AddMetadata(EEntityType.TopCurrentRating, this._ratingEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: RatingTableDTO) => x.LoadingPointName);
        this.AddMetadata(EEntityType.Rating, this._ratingEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.OrderNum);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: RouteSegmentDTO) => x.OrderNum);
        this.AddMetadata(EEntityType.RouteSegment, this._routePointEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: SetRoleDTO) => x.Role);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: SetRoleDTO) => x.Role);
        this.AddMetadata(EEntityType.UserRole, this._userRoleEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: SelectDriverDTO) => x.SelectedDriver);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: SelectDriverDTO) => x.SelectedDriver);
        this.AddMetadata(EEntityType.SelectDriver, this._selectDriverEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: SelectVehicleDTO) => x.SelectedVehicle);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: SelectVehicleDTO) => x.SelectedVehicle);
        this.AddMetadata(EEntityType.SelectVehicle, this._selectVehicleEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ShipperDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ShipperDTO) => x.Name);
        this.AddMetadata(EEntityType.Shipper, this._shipperEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: UpdatePasswordDTO) => x.CurrentPassword);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: UpdatePasswordDTO) => x.CurrentPassword);
        this.AddMetadata(EEntityType.UpdatePassword, this._updatePasswordEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ApiMessageLogDTO) => x.Id);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ApiMessageLogDTO) => x.Id);
        this.AddMetadata(EEntityType.RequestLog, this._requestLogLogEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: FileDTO) => x.Id);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: FileDTO) => x.Id);
        this.AddMetadata(EEntityType.File, this._fileEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: ComplaintCommentDTO) => x.Id);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ComplaintCommentDTO) => x.Id);
        this.AddMetadata(EEntityType.ComplaintComment, this._complaintCommentEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.ComplaintDraft, this._complaintDraftEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.ComplaintActive, this._complaintActiveEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.ComplaintWithNewMessages, this._complaintWithNewMessagesEntityService, defaultSortColumn, defaultNameColumn);
        this.AddMetadata(EEntityType.ComplaintClosed, this._complaintClosedEntityService, defaultSortColumn, defaultNameColumn);


        defaultSortColumn = PropertyHelper.GetPropertyName((x: ServicePartsVersionDTO) => x.ReadApiVersion);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: ServicePartsVersionDTO) => x.ReadApiVersion);
        this.AddMetadata(EEntityType.About, this._aboutEntityService, defaultSortColumn, defaultNameColumn);

        this.AddMetadata(EEntityType.Settings, this._settingsEntityService, undefined, undefined);

        this.AddMetadata(EEntityType.Configuration, this._configurationEntityService, undefined, undefined);
        this.AddMetadata(EEntityType.Report, this._reportEntityService, undefined, undefined);

        this.AddMetadata(EEntityType.Bidding, this._biddingEntityService, undefined, undefined);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Name);
        this.AddMetadata(EEntityType.DriverMonitoring, this._driverMonitoringEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: DeliveryDTO) => x.Name);
        this.AddMetadata(EEntityType.DeliveryForComplaint, this._deliveryForComplaintEntityService, defaultSortColumn, defaultNameColumn);

        defaultSortColumn = PropertyHelper.GetPropertyName((x: InstructionTableDTO) => x.Name);
        defaultNameColumn = PropertyHelper.GetPropertyName((x: InstructionTableDTO) => x.Name);
        this.AddMetadata(EEntityType.Instruction, this._instructionEntityService, defaultSortColumn, defaultNameColumn);

    }

    private AddMetadata(
        entityType: EEntityType,
        entityService: IEntityService<any, any>,
        defaultSortColumn: string,
        defaultNameColumn: string,
        defaultSelectLabelGetter?: (entity: ITableDTO) => string){

        entityService.SetEntity(entityType);
        let metadata = {
            EntityType: entityType,
            EntityTypeString: EEntityType[entityType].toLowerCase(),
            EntityRoute: this._routerService.GetEntityPathname(EEntityType[entityType].toLowerCase()),
            TableRoute: this._routerService.GetTablePathname(entityType),
            TableColumns: entityService.GetColumnsList(),
            TableHeader: EEntityType[entityType].toLowerCase() + ":Table",
            EntityHeader: EEntityType[entityType].toLowerCase() + ":Entity",
            AddEntity: EEntityType[entityType].toLowerCase() + ":Add",
            EntityService: entityService,
            TableDefaultSort: defaultSortColumn,
            DefaultNameProperty: defaultNameColumn,
            DefaultSelectLabelGetter: defaultSelectLabelGetter,
            OnEntityUpdated: entityService.GetOnEntityUpdated()
        };

        this._metadata.push(metadata);
    }

    public GetEntityType(entity: string): EEntityType{
        let entityLower = entity.toLowerCase();
        let metadata = this._metadata.find(x => x.EntityTypeString === entityLower);
        if (metadata !== undefined)
            return metadata.EntityType;
        throw new Error('failed to find metadata for type ' + entity);
    }

    public GetEntityTableColumns(entityType: EEntityType): Array<IVolmaTableColumn>{
        let metadata = this.GetMetadata(entityType);
        return metadata.TableColumns;
    }

    public GetEntityTableDefaultSort(entityType: EEntityType): string{
        let metadata = this.GetMetadata(entityType);
        return metadata.TableDefaultSort;
    }

    public GetEntityDefaultNameProperty(entityType: EEntityType): string{
        let metadata = this.GetMetadata(entityType);
        return metadata.DefaultNameProperty;
    }

    public GetEntityDefaultSelectLabelGetter(entityType: EEntityType): (x: ITableDTO) => string{
        let metadata = this.GetMetadata(entityType);
        return metadata.DefaultSelectLabelGetter;
    }

    public GetEntityTableHeader(entityType: EEntityType): string{
        let metadata = this.GetMetadata(entityType);
        return metadata.TableHeader;
    }

    public GetAddEntity(entityType: EEntityType): string{
        let metadata = this.GetMetadata(entityType);
        return metadata.AddEntity;
    }

    public GetEntityHeader(entityType: EEntityType): string{
        let metadata = this.GetMetadata(entityType);
        return metadata.EntityHeader;
    }

    public GetEntityService(entityType: EEntityType): IEntityService<any, any>{
        let metadata = this.GetMetadata(entityType);
        return metadata.EntityService;
    }

    public GetEntityRoute(entityType: EEntityType, id?: string, params?: Array<{Key: string, Value: string}>): string{
        let metadata = this.GetMetadata(entityType);
        let route =  metadata.EntityRoute + (id !== undefined ? ("/" + id) : "");
        if (params !== undefined)
            route += "/?" + params.map(x => encodeURIComponent(x.Key) + "=" + encodeURIComponent(x.Value)).join("&");
        return route;
    }

    public GetEntityTableRoute(entityType: EEntityType){
        let metadata = this.GetMetadata(entityType)
        return metadata.TableRoute;
    }

    public GetOnEntityUpdated(entityType: EEntityType){
        let metadata = this.GetMetadata(entityType)
        return metadata.OnEntityUpdated;
    }

    private GetMetadata(entityType: EEntityType): IEntityMetadata{
        let metadata = this._metadata.find(x => x.EntityType === entityType);
        if (metadata === undefined)
            throw new Error('Failed to find metadata for entity ' + EEntityType[entityType]);
        return metadata;
    }

}

