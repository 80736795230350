import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ICalendarEntryHelperProps } from './IHelperProps';
import { injectable } from 'inversify';

@injectable()
export class CalendarEntryReducer extends EntityReducer<CalendarEntryDTO, ICalendarEntryHelperProps> {

    public Reduce(state: IBaseEntityProps<CalendarEntryDTO, ICalendarEntryHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<CalendarEntryDTO, ICalendarEntryHelperProps> {
        switch (action.type) {
            default:
                return state;
        }
    }

}