import { injectable } from 'inversify';
import * as React from 'react';
import { DriverScoreReportParametersDTO } from '../../../../Domain/DTO/DriverScoreReportParametersDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar';
import VolmaSelect from '../../../VolmaSelect';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { ReportService } from './ReportService';
@injectable()
export class ReportDriverScoreService extends ReportService<DriverScoreReportParametersDTO>{
    public Title: string = "ReportDriverScore";

    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<DriverScoreReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        const today = new Date();

        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverScoreReportParametersDTO) => val.Start) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DriverScoreReportParametersDTO) => val.Start)}
                                Label={i18next.t('report:LabelDateFrom')}
                                Value={props.DataHelper.ReportParams.Start}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                Required={true}
                                MaxDate={today}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>  

                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DriverScoreReportParametersDTO) => val.CargoTransporterId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DriverScoreReportParametersDTO) => x.CargoTransporterId)}
                                Label={i18next.t('report:SelectCargoTransporter')}
                                Placeholder={i18next.t('report:SelectCargoTransporter')}
                                Entity={EEntityType.CargoTransporter}
                                Value={props.DataHelper.ReportParams.CargoTransporterId}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>    

                    <div className={this._mainInfo("cell", { 33: true }).toString()}></div>   

                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DriverScoreReportParametersDTO) => val.End) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DriverScoreReportParametersDTO) => val.End)}
                                Label={i18next.t('report:LabelDateTo')}
                                Value={props.DataHelper.ReportParams.End}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                Required={true}
                                MaxDate={today}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div> 
                     
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { full: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DriverScoreReportParametersDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DriverScoreReportParametersDTO) => x.LoadingPointId)}
                                Label={i18next.t('report:SelectLoadingPoint')}
                                Placeholder={i18next.t('report:SelectLoadingPoint')}
                                Value={props.DataHelper.ReportParams.LoadingPointId}
                                Entity={EEntityType.LoadingPoint}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>            
                </div>
            </div >
        );
    }
}