import { injectable } from "inversify";
import { ListIntOptionDTO } from "../../../../Domain/DTO/ListIntOptionDTO";
import { EDeliveryState } from "../../../../Domain/Enum/EDeliveryState";
import { EEntityType } from "../../../../Domain/Enum/EEntityType";
import { IReducePayloaded } from "../../../../Infrastructure/Reducer/IReducer";
import { IVolmaTableColumn, IVolmaTableProps } from "../../../Table/VolmaTable/IVolmaTableProps";
import { DeliveryEntityService } from "../DeliveryEntityService";

@injectable()
export class DeliveryToAcceptConfirmEntityService extends DeliveryEntityService {
    public GetBaseEntity(): EEntityType {
        return EEntityType.Delivery;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(EEntityType.Delivery, [
            { ...this._deliveryColumnTemplates.Id },
            { ...this._deliveryColumnTemplates.Identifier },
            { ...this._deliveryColumnTemplates.LoadingDate },
            { ...this._deliveryColumnTemplates.UnloadingDate },
            { ...this._deliveryColumnTemplates.MultiPointName, Weight: 0.7 },
            { ...this._deliveryColumnTemplates.UnloadingPointName },
            { ...this._deliveryColumnTemplates.AssignType },
            {
                ...this._deliveryColumnTemplates.State,
                OptionsFilter: (x: ListIntOptionDTO) =>
                    x.Id === EDeliveryState.Assigned || x.Id === EDeliveryState.Accepted,
            },
            { ...this._deliveryColumnTemplates.NextStateDate },
        ]);
    }

    public GetTableReducer(): IReducePayloaded<IVolmaTableProps<any>> {
        return this._tableReducer;
    }
}
