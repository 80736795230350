import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { MultiCargoTransporterDTO } from '../../../../Domain/DTO/MultiCargoTransporterDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';

export class DeliveryConfirmHelperProps {
    AllDrivers   : Array<DriverTableDTO>
    AllVehicles: Array<VehicleTableDTO>
    MultiCargoTransporter: MultiCargoTransporterDTO
    SelectedCargoTransporter: CargoTransporterTableDTO
}

export const InitialDeliveryConfirmHelperProps: DeliveryConfirmHelperProps = <DeliveryConfirmHelperProps>{ 
    AllDrivers: [], 
    AllVehicles: [],
    MultiCargoTransporter: undefined,
    SelectedCargoTransporter: undefined,
};

