import "./leaflet.scss";
import "./VolmaMap.scss";

import * as React from "react";
import {
  CircleMarker,
  MapContainer,
  Popup,
  TileLayer,
  Tooltip,
  useMapEvents,
} from "react-leaflet";
import { LatLng, LatLngExpression, LatLngLiteral } from "leaflet";

const Clicker = (props: { onClick?: (latlng: LatLngExpression) => void }) => {
  const map = useMapEvents({
    click: (e) => {
      if (props.onClick) {
        props.onClick(e.latlng);
      }
    },
  });
  return null;
};

const Centerer = (props: { newCenter?: LatLngExpression }) => {
  const map = useMapEvents({});
  if (props.newCenter) {
    map.flyTo(props.newCenter);
  }
  return null;
};

export interface VolmaMapProps {
  locations: Array<{
    Latitude: number;
    Longitude: number;
    PopupText?: string | JSX.Element;
    OrderNumber: number;
  }>;
  center?: LatLngLiteral;
  onClick?: (latlng: LatLngExpression) => void;
}

export class VolmaMap extends React.Component<VolmaMapProps, unknown> {
  constructor(props) {
    super(props);
    this.state = {
      currentPos: null,
    };
  }

  private _defaultCenterCoordinates: LatLngLiteral = { lat: 48.7, lng: 44.5 };

  render() {
    const { locations } = this.props;

    const computedCenter =
      locations.length > 0 && locations[0].Latitude && locations[0].Longitude
        ? { lat: locations[0].Latitude, lng: locations[0].Longitude }
        : this._defaultCenterCoordinates;

    const center =
      this.props.center && this.props.center.lat && this.props.center.lng
        ? this.props.center
        : computedCenter;

    const nonNullableLocation = locations.filter((location) => location.Latitude && location.Longitude)

    const mapContainer = (
      <MapContainer
        center={center}
        zoom={13}
        className="main-map"
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution="OpenStreetMap"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Clicker onClick={this.props.onClick} />
        <Centerer newCenter={center} />
        {nonNullableLocation.map((object, i) => {
          const objectArea = `${object.Latitude} ${object.Longitude}`;
          const lat = object.Latitude;
          const lon = object.Longitude;
         
          return (
            <CircleMarker key={`${i} ${objectArea}`} center={[lat, lon]}>
              {object.PopupText && (
                <Popup>
                  <span>{object.PopupText}</span>
                </Popup>
              )}

              <Tooltip direction="bottom" offset={[0, 10]} permanent>
                <span>{object.OrderNumber }</span>
              </Tooltip>
            </CircleMarker>
          );
        })}
      </MapContainer>
    );

    return mapContainer;
  }
}
