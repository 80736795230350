import { injectable } from 'inversify';
import {
    RATING_CARGO_TRANSPORTER_LOADED,
    RATING_CURRENT_RATING_LOADED,
    RATING_FORECAST_RATING_LOADED, RATING_LOADING_POINTS_LOADED, RATING_LOADING_POINT_LOADED, RATING_MULTI_FACTORY_LOADED, RATING_RATING_HISTORY_LOADED,
    VOLMA_ENTITY_DATA_LOADED,
    VOLMA_MODAL_DATA_LOADED
} from '../../../../Constants/AppConstants';
import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { RatingDTO } from '../../../../Domain/DTO/RatingDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { EntityReducer } from '../../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { RatingHelperProps } from './IHelperProps';
import { MultiFactoryDTO } from '../../../../Domain/DTO/MultiFactoryDTO';

type IState = IBaseEntityProps<RatingDTO, RatingHelperProps>;
@injectable()
export class RatingReducer extends EntityReducer<RatingDTO, RatingHelperProps> {

    public Reduce(state: IState, action: IActionPayloaded<any>): IState {
        switch (action.type) {
            case VOLMA_MODAL_DATA_LOADED:
            case VOLMA_ENTITY_DATA_LOADED:
                return this.ReduceUpdateAvailablePoints(state, action.Payload);
            case RATING_LOADING_POINTS_LOADED:
                return this.ReduceAllLoadingPointsLoaded(state, action.Payload);
            case RATING_CURRENT_RATING_LOADED:
                return this.ReduceCurrenRatingsLoaded(state, action.Payload);
            case RATING_LOADING_POINT_LOADED: 
                return this.ReduceLoadingPointLoaded(state, action.Payload)
            case RATING_MULTI_FACTORY_LOADED: 
                return this.ReduceMultiFactoryLoaded(state, action.Payload)
            case RATING_CARGO_TRANSPORTER_LOADED: 
                return this.ReduceCargoTransporterLoaded(state, action.Payload)
            case RATING_FORECAST_RATING_LOADED: 
                return this.ReduceForecastRatingLoaded(state, action.Payload)
            case RATING_RATING_HISTORY_LOADED: 
                return this.ReduceRatingHistoryLoaded(state, action.Payload)
            default:
                return state;
        }
    }

    private ReduceUpdateAvailablePoints(state: IState, payload: RatingDTO): IState {
        let newState = <IState>{ ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.AvailableLoadingPoints = this.GetAvailablePointsList(newState, payload);
        return newState;
    }

    private ReduceCurrenRatingsLoaded(state: IState, payload: Array<RatingTableDTO>): IState {
        let newState = <IState>{ ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.CurrentRatings = payload;
        newState.DataHelper.AvailableLoadingPoints = this.GetAvailablePointsList(newState, newState.DataDTO);
        return newState;
    }

    private ReduceAllLoadingPointsLoaded(state: IState, payload: Array<LoadingPointTableDTO>): IState {
        let newState = <IState>{ ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.AllLoadingPoints = payload;
        newState.DataHelper.AvailableLoadingPoints = this.GetAvailablePointsList(newState, newState.DataDTO);
        return newState;
    }

    private ReduceLoadingPointLoaded(state: IState, payload: LoadingPointDTO): IState {
        return super.EntityLoaded<LoadingPointDTO>(state, payload, x => x.LoadingPoint);
    }

    private ReduceMultiFactoryLoaded(state: IState, payload: MultiFactoryDTO): IState {
        return super.EntityLoaded<MultiFactoryDTO>(state, payload, x => x.MultiFactory);
    }

    private ReduceCargoTransporterLoaded(state: IState, payload: LoadingPointDTO): IState {
        return super.EntityLoaded<RatingDTO>(state, payload, x => x.CargoTransporter);
    }

    private ReduceForecastRatingLoaded(state: IState, payload: LoadingPointDTO): IState {
        return super.EntityLoaded<RatingDTO>(state, payload, x => x.ForecastRating);
    }

    private ReduceRatingHistoryLoaded(state: IState, payload: Array<RatingTableDTO>): IState {
        if (payload !== undefined && payload.length > 0)
            payload.splice(0, 1);// remove current rating
        return super.CollectionLoaded<RatingDTO>(state, payload, [x => x.RatingHistory, x => x.RatingHistoryOriginal]);
    }

    private GetAvailablePointsList(newState: IState, dto: RatingDTO): Array<LoadingPointTableDTO>{
        let availableLoadingPoints = [];
        for (let loadingPoint of newState.DataHelper.AllLoadingPoints) {
            let ratingForLoadingPointDefined = false;
            for (let rating of newState.DataHelper.CurrentRatings) {
                if (rating.LoadingPointId === loadingPoint.Id && rating.LoadingPointId !== PropertyHelper.SafeGetValue(dto, x => x.LoadingPointId)) {
                    ratingForLoadingPointDefined = true;
                    break;
                }
            }
            if (!ratingForLoadingPointDefined) {
                availableLoadingPoints.push(loadingPoint);
            }
        }        

        return availableLoadingPoints;
    }
}
