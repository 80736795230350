import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import {BaseValidator} from "./BaseValidator";
import { IVolmaNumberProps } from '../../Components/VolmaNumber/IVolmaNumberProps';
import i18next from '../../Components/i18n';
import { IVolmaCalendarProps } from '../../Components/VolmaCalendar/IVolmaCalendarProps';
import * as moment from 'moment';

@injectable()
export class VolmaCalendarValidator extends BaseValidator<IVolmaCalendarProps> {

    public Validate(entity: IVolmaCalendarProps): ValidationResult {
        if (entity === undefined || entity === null)
            throw new Error("Null reference exception");

        if ((entity.Value === undefined || entity.Value === null ) && entity.Required)
            return ValidationResult.Fail(i18next.t("validate:ValueMustBeNonEmpty"));
        if ((entity.Value === undefined || entity.Value === null ) && !entity.Required)
            return ValidationResult.Success();
        // @ts-ignore: This expression is not callable
        if (!moment(entity.Value).isValid())
            return ValidationResult.Fail(i18next.t("validate:DateNotValid"));


        return ValidationResult.Success();
    }
}