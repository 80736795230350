import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import VolmaTable from '../../Table/VolmaTable';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaNumber from '../../VolmaNumber';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { ConsignorHelperProps, InitialConsignorHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import * as React from 'react';
import i18next from '../../i18n';
import { ConsignorActions } from './ConsignorActions';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { ConsignorTableDTO } from '../../../Domain/DTO/ConsignorTableDTO';

@injectable()
export class ConsignorEntityService extends BaseEntityService<ConsignorDTO, ConsignorHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;

    private _volmaTableService: VolmaTableService;
    private _actions: ConsignorActions;

    private _id: string;
    private _dto: ConsignorDTO;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._actions = VolmaContainer.get<ConsignorActions>(Types.ConsignorActions);
    }


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Consignor]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Consignor,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: ConsignorTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: ConsignorTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ConsignorTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ConsignorTableDTO) => val.Email), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: ConsignorTableDTO) => val.Head), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
            ]
        );
    }

    public GetEntityId(props: IBaseEntityProps<ConsignorTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: ConsignorTableDTO) => x.Code),
            (x: ConsignorTableDTO, id: string) => x.Code.toLowerCase() === id);
    }


    public GetHeaderEditor(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Name)}
                        Label={i18next.t('consignor:Name')}
                        Placeholder={i18next.t('consignor:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>

                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Code)}
                        Label={i18next.t('consignor:Code')}
                        Placeholder={i18next.t('consignor:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        if (this.IsEditable(props)){
            return this.GetNewConsignorEditor(props);
        }
        return this.GetReadonlyConsignorEditor(props);
    }

    public GetEditor(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return this.GetEditorModal(props)
    }

    public InitializeEntity(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>) {
        this._dispatch = props.dispatch;
        this._id = undefined;
        this._dto = undefined;
    }

    public GetInitialDataHelper() {
        return InitialConsignorHelperProps;
    }

    public GetReducer() {
        return undefined;
    }

    public OnAfterDataLoaded(dto: ConsignorDTO): void {
        this._id = dto.Id;
        this._dto = dto;
    }


    private GetNewConsignorEditor(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("consignor:MainInfoTitle"), i18next.t("consignor:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.ContactPersonPart(props), 0)}
                        {this.GetMainInfoNoTitleItem(true, false, this.ContactDataPart(props), 1)}
                        {this.GetMainInfoNoTitleItem(false, true, this.AddressPart(props), 2)}
                    </div>
                </div>
            </div>);
    }

    private GetReadonlyConsignorEditor(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("consignor:MainInfoTitle"), i18next.t("consignor:MainInfoTitleDescription"), this.ReadonlyMainInfoPart(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    private ReadonlyMainInfoPart(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:Head")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Head)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:ContactPerson")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.ContactPerson)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:ContactPersonMobilePhoneNumber")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.ContactPersonMobilePhoneNumber)}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:PhoneNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.PhoneNumber)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:FaxNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.FaxNumber)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:Email")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Email)}</dd>
                                </div>
                            </dl>
                        </dl>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoDescription).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:Tin")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Tin)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:TaxRegistrationReasonCode")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.TaxRegistrationReasonCode)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:Psrn")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Psrn)}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:LegalAddress")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.LegalAddress)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("consignor:PostalAddress")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.PostalAddress)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        );
    }

    private MainInfoPart(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Tin) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Tin)}
                            Label={i18next.t('consignor:Tin')}
                            Required={true}
                            Value={props.DataDTO.Tin}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.TaxRegistrationReasonCode) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.TaxRegistrationReasonCode)}
                            Label={i18next.t('consignor:TaxRegistrationReasonCode')}
                            Required={true}
                            Value={props.DataDTO.TaxRegistrationReasonCode}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Psrn) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Psrn)}
                            Label={i18next.t('consignor:Psrn')}
                            Required={true}
                            Value={props.DataDTO.Psrn}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
       );
    }

    private ContactDataPart(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.PhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.PhoneNumber)}
                            Label={i18next.t('consignor:PhoneNumber')}
                            Required={true}
                            Value={props.DataDTO.PhoneNumber}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.FaxNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.FaxNumber)}
                            Label={i18next.t('consignor:FaxNumber')}
                            Required={true}
                            Value={props.DataDTO.FaxNumber}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Email) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Email)}
                            Label={i18next.t('consignor:Email')}
                            Required={true}
                            Value={props.DataDTO.Email}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ContactPersonPart(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.ContactPerson) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.ContactPerson)}
                            Label={i18next.t('consignor:ContactPerson')}
                            Required={true}
                            Value={props.DataDTO.ContactPerson}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.ContactPersonMobilePhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.ContactPersonMobilePhoneNumber)}
                            Label={i18next.t('consignor:ContactPersonMobilePhoneNumber')}
                            Required={true}
                            Value={props.DataDTO.ContactPersonMobilePhoneNumber}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.Head) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.Head)}
                            Label={i18next.t('consignor:Head')}
                            Required={true}
                            Value={props.DataDTO.Head}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private AddressPart(props: IBaseEntityProps<ConsignorDTO, ConsignorHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.LegalAddress) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.LegalAddress)}
                            Label={i18next.t('consignor:LegalAddress')}
                            Type="textarea"
                            Required={true}
                            Value={props.DataDTO.LegalAddress}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: ConsignorDTO) => val.PostalAddress) as any) }
                            Name={PropertyHelper.GetPropertyName((val: ConsignorDTO) => val.PostalAddress)}
                            Label={i18next.t('consignor:PostalAddress')}
                            Type="textarea"
                            Required={true}
                            Value={props.DataDTO.PostalAddress}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}