import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { ChangeCargoTransporterAndConfirmAction } from '../Actions/ChangeCargoTransporterAndConfirmAction';
import { DeliveryWithdrawAction } from '../Actions/DeliveryWithdrawAction';
import { NotConfirmDeliveryAction } from '../Actions/NotConfirmDeliveryAction';
import { SynchronizeDeliveryAction } from '../Actions/SynchronizeDeliveryAction';
import { DeliveryHelperProps } from '../IHelperProps';
import { Acceptance } from './Acceptance';

export class Confirmation extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusConfirmationCurrent";
    public TitleFinished: string = "delivery:StatusConfirmationFinished";
    public TitleFuture  : string = "delivery:StatusConfirmationFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.Accepted
    }

    public IsFinished(dto: DeliveryDTO){
        return !this.IsCurrent(dto) && new Acceptance().IsFinished(dto);
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [
            new ChangeCargoTransporterAndConfirmAction(),
            new DeliveryWithdrawAction(), 
            new NotConfirmDeliveryAction(), 
            new SynchronizeDeliveryAction()];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;

        return undefined;
    }

}
