import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { LocalityDTO } from '../../../../Domain/DTO/LocalityDTO';
import { HandlingTypeDTO } from '../../../../Domain/DTO/HandlingTypeDTO';
import { PayloadDTO } from '../../../../Domain/DTO/PayloadDTO';
import { TimeDTO } from '../../../../Domain/DTO/TimeDTO';

export interface IRouteSegmentTableHelperProps{
    LoadingPoints: Array<LoadingPointDTO>;
    Localities   : Array<LocalityDTO>;
    HandlingTypes: Array<HandlingTypeDTO>;
    Payloads     : Array<PayloadDTO>;
    TimeZone     : TimeDTO;
}

export const InitialRouteSegmentTableHelperProps: IRouteSegmentTableHelperProps = <IRouteSegmentTableHelperProps>{
    LoadingPoints: [],
    Localities   : [],
    HandlingTypes: [],
    Payloads     : [],
    TimeZone     : undefined
};

