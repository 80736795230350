import { FileDTO } from '../../../Domain/DTO/FileDTO';

export class ComplaintTemplateHelperProps{
    FileInfo: FileDTO
    TouchedFiles: Array<FileDTO>
    UploadFailed: boolean
    UploadErrorMessage: string
}

export const InitialComplaintTemplateHelperProps: ComplaintTemplateHelperProps = <ComplaintTemplateHelperProps>{ 
    FileInfo: {},
    TouchedFiles: [],
    UploadFailed: false,
    UploadErrorMessage: undefined
};