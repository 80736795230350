import {IVolmaInputProps} from "../../Components/VolmaInput/IVolmaInputProps";
import { IVolmaTableProps } from '../../Components/Table/VolmaTable/IVolmaTableProps';
import { IVolmaSelectProps } from '../../Components/VolmaSelect/IVolmaSelectProps';

export default class PropertyHelper {
    private static _guidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    static GetPropertyName(property: (object: any) => void) {
        const chaine = property.toString();
        let arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
        if(arr == null)
            // "function () { return _Domain_Enum_EDeliveryState__WEBPACK_IMPORTED_MODULE_4__["EDeliveryState"]; }"
            arr = chaine.match(/\"([a-zA-Z]+)\"/);
        if(arr == null)
            // function(){return EDeliveryState}
            arr = chaine.match(/[\s\S]*{[\s\S]*return\s+([^\.; ]*)[ ;\n]*}/);
        return arr[1];
    }

    static GetInputPropertyByName(inputs: Array<IVolmaInputProps>, property: (object: any) => void): any {
        const chaine = property.toString();
        const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
        return PropertyHelper.GetInputPropertyByNameString(inputs, arr[1]);
    }

    static GetInputPropertyByNameString(inputs: Array<IVolmaInputProps>, name: string): any {
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].Name === name)
                return inputs[i];
        }
        return undefined;
    }

    static GetSelectPropertyByName(selects: Array<IVolmaSelectProps>, property: (object: any) => void) {
        const chaine = property.toString();
        const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
        for (let i = 0; i < selects.length; i++) {
            if (selects[i].Name === arr[1])
                return selects[i];
        }
        return undefined;
    }

    static GetTablePropertyByNameString(tables: Array<IVolmaTableProps<any>>, name: string): any {
        for (let i = 0; i < tables.length; i++) {
            if (tables[i].Name === name)
                return tables[i];
        }
        return undefined;
    }

    static GetTablePropertyByName(tables: Array<IVolmaTableProps<any>>, property: (object: any) => void) {
        const chaine = property.toString();
        const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
        return PropertyHelper.GetTablePropertyByNameString(tables, arr[1]);
    }

    static SafeGetValue<T, TResult>(entity: T, property: (object: T) => TResult){
        if(entity === undefined || entity === null)
            return undefined;
        return property(entity);
    }

    static SafeGetValue2<T1, T2, TResult>(entity: T1, property1: (object: T1) => T2, property2: (object: T2) => TResult ){
        if (entity === undefined || entity === null)
            return undefined;
        const prop1Val = property1(entity);
        if (prop1Val !== undefined && prop1Val !== null){
            return property2(prop1Val);
        }

        return undefined;
    }

    static SageGetValueFromCollection<T1, T2, TResult>(entities: T1[], key: (object: T1) => T2, keyValue: T2, property1: (object: T1) => TResult)
    {
        const entity = entities.find(x => key(x) == keyValue);
        if (entity === undefined || entity === null)
            return undefined;

        return property1(entity);
    }

    static IsPromise(obj: any): boolean {
        return obj !== undefined && !!obj.then && typeof obj.then === 'function';
    }

    static IsArray(obj: any): boolean{
        return Object.prototype.toString.call(obj) === '[object Array]'
    }

    static IsObject(obj: any): boolean{
        return Object.prototype.toString.call(obj) === '[object Object]'
    }

    static IsNumber(obj: any): boolean{
        return Object.prototype.toString.call(obj) === '[object Number]'
    }

    static IsString(obj: any): boolean{
        return Object.prototype.toString.call(obj) === '[object String]'
    }

    static IsDate(obj: any): boolean{
        return Object.prototype.toString.call(obj) === '[object Date]'
    }

    static IsGuid(id: string): boolean {
        return PropertyHelper._guidRegExp.test(id);
    }

}
