import * as React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { LogService } from '../../../Infrastructure/Services/LogService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import Footer from '../../Footer/Footer';
import Header from '../../Header/index';
import { VolmaComponent } from '../../Shared/VolmaComponent';
import VolmaForm from '../../VolmaForm/index';
import i18next from '../../i18n';
import { BaseEntityActions } from './BaseEntityActions';
import { IBaseEntityProps } from './IBaseEntityProps';
import { IEntityService } from './IEntityService';
import { history } from "../../../ConfigureStore";
import { withRouter } from "react-router-dom";
import { RouteUtils } from '../../../Infrastructure/Router/RouteUtils';
import { Prompt } from "react-router";


class BaseEntity extends VolmaComponent<IBaseEntityProps<any, any>>{
    private _baseActions: BaseEntityActions;
    private _entityService: EntityService;
    private _authService: AuthenticationService;
    private _entity: EEntityType;
    private _id: string;
    private _headerTitle: string;
    private _currentEntityService: IEntityService<any, any>;
    private _logService: LogService;

    private _oldLocation: any;
    private _oldSearch: any;

    private _header     = volmaBlock('header');
    private _createCard = volmaBlock('create-card');
    private _btn        = volmaBlock('btn');
    private _mainInfo   = volmaBlock('main-info');
    private _footer     = volmaBlock('footer');
    private _content    = volmaBlock('content');

    private _parentDiv: Element;

    constructor(props: IBaseEntityProps<any, any>, context: any) {
        super(props, context);

        this._baseActions   = VolmaContainer.get<BaseEntityActions>(Types.BaseEntityActions);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._authService   = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._logService    = VolmaContainer.get<LogService>(Types.LogService);

        super.SetActions(this._baseActions);

        this.OnMainClick = this.OnMainClick.bind(this);
    }

    public UNSAFE_componentWillMount() {
        this.InitializeEntity().then(() => this.LoadData());
        super.UNSAFE_componentWillMount();

    }

    public componentWillUnmount(){
        if (this._currentEntityService !== undefined) {
            this._currentEntityService.DisposeEntity();
        }
        super.componentWillUnmount();
    }

    public componentDidUpdate(prevProps) {
        let newLocation = PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname);
        let newSearch   = PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.search);
        if (newLocation !== this._oldLocation || newSearch !== this._oldSearch) {
            this.props.dispatch(this._baseActions.Unmount());
            this.props.dispatch(this._baseActions.Mount());
            this.InitializeEntity().then(() => this.LoadData());
        }
    }


    public shouldComponentUpdate(nextProps: IBaseEntityProps<any, any>, nextState: any) {
        let newLocation = PropertyHelper.SafeGetValue2(nextProps, x => x.location, x => x.pathname);
        let currentLocation = PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname);

        const shouldUpdate =
            newLocation !== currentLocation ||
            nextProps.DataDTO !== this.props.DataDTO ||
            nextProps.DataHelper !== this.props.DataHelper ||
            nextProps.Inputs !== this.props.Inputs ||
            nextProps.Selects !== this.props.Selects ||
            nextProps.Tables !== this.props.Tables ||
            nextProps.OpenedStatusIndex !== this.props.OpenedStatusIndex ||
            nextProps.IsSubmitted !== this.props.IsSubmitted ||
            nextProps.ClosedCardParts !== this.props.ClosedCardParts ||
            nextProps.FooterNavList !== this.props.FooterNavList ||
            nextProps.FooterOpened !== this.props.FooterOpened ||
            nextProps.NoopObject !== this.props.NoopObject;
        let willUpdate = (shouldUpdate && nextProps.IsSending === false) || (nextProps.IsSending === false && nextProps.IsSending !== this.props.IsSending);
        //console.log(willUpdate);
        return willUpdate;
    }

    public render() {
        // this._logService.Trace('component rendered');
        let headerTitle  = this._entityService.GetEntityHeader(this._entity);
        let service      = this.props.DataDTO !== undefined ? this._entityService.GetEntityService(this._entity): undefined;
        let editor       = this.props.DataDTO !== undefined ? service.GetEditor(this.props)                     : undefined;
        let headerEditor = this.props.DataDTO !== undefined ? service.GetHeaderEditor(this.props)               : undefined;
        let isEditable   = this.props.DataDTO !== undefined ? service.IsEditable(this.props)                    : false;

        return (
            <div className="wrapper" onClick={this.OnMainClick}>
                <header className={(this._header()).toString()}>
                    <Header/>
                </header>

                <main className={this._content({"footer-active": this.props.FooterOpened}).toString()}>
                    <div className='content-wrap' style={this.props.style}>

                        <VolmaForm {...this.props as any} Ref={((l) => this._parentDiv = l).bind(this)} key={this._id} >
                            <div className={(this._createCard()).toString()}>
                                <div className="container">
                                    <div className={(this._createCard("form")).toString()}>
                                        <div className={(this._createCard("top")).toString()}>
                                            <span className={(this._createCard("title")).toString()}>
                                                <span className={this._createCard("title-icon").toString()}>
                                                    <svg className={this._createCard("title-ico").mix(["info-block-ico"]).toString()}>
                                                        <use xlinkHref="#info-block"></use>
                                                    </svg>
                                                    <svg className={this._createCard("title-ico-bg").mix(["info-bg-ico"]).toString()}>
                                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#info-block"></use>
                                                    </svg>
                                                </span>{' '}
                                                <span className={this._createCard("title-text").toString()}>{i18next.t(headerTitle)}</span>
                                            </span>
                                            <div className={(this._createCard("buttons")).toString()}>
                                                <button onClick={this.OnCancelClick.bind(this)} className={this._createCard("link").mix(["btn", "btn-red"]).toString()}>
                                                    <span className={(this._btn("text")).toString()}>{i18next.t("common:ButtonCancel")}</span>
                                                    <svg className={this._btn("ico").mix("close-ico").toString()}>
                                                        <use xlinkHref="#close"></use>
                                                    </svg>
                                                </button>
                                                {isEditable && <button onClick={this.OnSaveClick.bind(this)} className={this._createCard("link").mix(["btn", "btn-green"]).toString()}>
                                                    <span className={(this._btn("text")).toString()}>{i18next.t("common:ButtonSave")}</span>
                                                    <svg className={this._btn("ico").mix("plus-ico").toString()}>
                                                        <use xlinkHref="#plus"></use>
                                                    </svg>
                                                </button>}
                                            </div>
                                        </div>
                                        <div className={(this._createCard("row")).toString()}>
                                            {headerEditor}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {editor}
                        </VolmaForm>
                    </div>
                </main>
                {this.GetFooter()}
            </div>
        );
    }

    private OnSaveClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();

        let service = this._entityService.GetEntityService(this._entity);
        let isEditable = service.IsEditable(this.props);
        if(!isEditable)
            return;

        if (this.props.IsValid) {
            let customSaveEntityAction = service.CustomEntitySaveAction(this._entity, this.props.DataDTO, this.props.IsValid, i18next.t('common:WaitingForTheServer'), this._id);
            if (customSaveEntityAction === undefined)
                this.props.dispatch(this._baseActions.SaveEntity(this._entity, this.GetBaseEntity(), this.props.DataDTO, this.props.IsValid, i18next.t('common:WaitingForTheServer'), this.props, service.AlwaysPost(), this._id));
            else{
                this.props.dispatch(this._baseActions.UpdateDTOStringify(this.props.DataDTO));
                this.props.dispatch(customSaveEntityAction);
            }
        }
        else
            this.props.dispatch(this._baseActions.Submit());
    }

    private OnCancelClick(e: Event) {
        e.preventDefault();
        e.stopPropagation();
        let back = RouteUtils.GetQueryValue(PropertyHelper.SafeGetValue(this.props.location, x => x.search), "back");
        if (back !== undefined)
            history.push(back);
        else
            history.push(this._entityService.GetEntityTableRoute(this._entity));
    }

    private OnMainClick(){
        this.props.dispatch(this._baseActions.SomewhereClicked());
    }
    public LoadData() {
        this._currentEntityService.InitializeEntity(this.props, this._id)
        if (this._id !== undefined){
            this.props.dispatch(this._baseActions.LoadEntity(this._currentEntityService, this.GetBaseEntity(), i18next.t('common:WaitingForLoadingEntityData'), this._id))
        }
        else{
            let data = {};
            this._currentEntityService.InitializeDefaultDTOValues(data);
            this.props.dispatch(this._baseActions.EntityLoaded(data));
        }
    }

    private GetBaseEntity():EEntityType{
        let entityService = this._entityService.GetEntityService(this._entity);
        let baseEntity = this._entity;
        if (entityService.GetBaseEntity() !== undefined)
            baseEntity = entityService.GetBaseEntity();
        return baseEntity;
    }

    protected InitializeEntity(): Promise<void> {

        this._entity = this._entityService.GetEntityType(this.props.match.params.entity);
        this._headerTitle = this._entityService.GetEntityHeader(this._entity);

        this._oldLocation = PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.pathname);
        this._oldSearch = PropertyHelper.SafeGetValue2(this.props, x => x.location, x => x.search);

        if (this._currentEntityService !== undefined){
            this._currentEntityService.DisposeEntity();
        }

        this._currentEntityService = this._entityService.GetEntityService(this._entity);

        let idGetter = this._currentEntityService.GetEntityId(this.props);
        if (PropertyHelper.IsPromise(idGetter)){
            return idGetter.then((id: string) => {
                this._id = id;
                this.props.dispatch(this._baseActions.SetEntityType(this._entity));
                this.props.dispatch(this._baseActions.SetEntityService(this._currentEntityService));
                this.props.dispatch(this._baseActions.ClearFooter());
                this.props.dispatch(this._baseActions.CloseNav());
            })
        }
        else{
            this._id = idGetter;
            this.props.dispatch(this._baseActions.SetEntityType(this._entity));
            this.props.dispatch(this._baseActions.SetEntityService(this._currentEntityService));
            this.props.dispatch(this._baseActions.ClearFooter());
            this.props.dispatch(this._baseActions.CloseNav());

            return Promise.resolve();
        }

    }

    private GetFooter() : JSX.Element {
        if (this.props.FooterNavList !== undefined && this.props.FooterNavList.length > 0){
            let footer = new Array<JSX.Element>();
            return (
                <div>
                    <footer className={this._footer({ open: true }).mix([this.props.FooterOpened ? "active" : ""]).toString()}>
                        <Footer Blocks={this.props.FooterNavList} dispatch={this.props.dispatch} ParentDiv={this._parentDiv} />
                    </footer>
                </div>);
        }

        return undefined;
    }
}

const translated = withTranslation(['common', 'enum'])(withRouter(BaseEntity));

function select(state: IGlobalState, ownProps: IBaseEntityProps<any, any>): IBaseEntityProps<any, any> {
    return state.BaseEntity;
}

let connectedBaseEntity = connect<IBaseEntityProps<any, any>, {}, {}>(select)(translated);
export default connectedBaseEntity;
