import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';

export interface IVolmaCalendarProps extends IBaseInputProps<IVolmaCalendarProps> {
    Value: Date;
    DefaultHours: number;
    DefaultMinutes: number;
    DefaultSeconds: number;
    ShowOverlay: boolean;
    IsActive: boolean;
    IsInHeader: boolean;
    IsClearable: boolean;
    MinDate?: Date;
    MaxDate?: Date;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
    OnOverlayToggled?: (value: boolean) => void;
    OnValueChange?: (date: Date) => void;
}

export const VolmaCalendarPropsInitial: IVolmaCalendarProps = <IVolmaCalendarProps>{
    ...VolmaBaseInputPropsInitial,
    ShowOverlay: false,
    IsActive: false,
    IsClearable: false,
};