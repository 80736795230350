import { injectable } from 'inversify';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { CHART_BORDER_WIDTH } from '../../../../Constants/AppConstants';
import { DeliveriesAttachedFilesReportParametersDTO } from '../../../../Domain/DTO/DeliveriesAttachedFilesReportParametersDTO';
import { DeliveriesReportParametersDTO } from '../../../../Domain/DTO/DeliveriesReportParametersDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { EReportType } from '../../../../Domain/Enum/EReportType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../../i18n';
import VolmaCalendar from '../../../VolmaCalendar/VolmaCalendar';
import VolmaSelect from '../../../VolmaSelect';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IChartDataset } from '../IChart';
import { IReportHelperProps } from '../IHelperProps';
import { ReportDTO } from '../ReportDTO';
import { ReportService } from './ReportService';

@injectable()
export class ReportDeliveriesAttachedFilesService extends ReportService<DeliveriesReportParametersDTO>{
    public Title: string = "DeliveriesAttachedFilesReport";

    private _options: {
        scales: {
            xAxes: [{
                stacked: true,
                gridLines: { display: false },
            }],
            yAxes: [{
                stacked: true,
            }],
        },
        legend: { display: true }
    }

    public GetParamsEditor(props: IBaseEntityProps<ReportDTO, IReportHelperProps<DeliveriesAttachedFilesReportParametersDTO>>, onReportParamsChanged: (reportType: EReportType, reportParams: any) => void): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesAttachedFilesReportParametersDTO) => val.Consignees) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesAttachedFilesReportParametersDTO) => x.Consignees)}
                                Label={i18next.t('report:Consignees')}
                                Placeholder={i18next.t('report:SelectConsignees')}
                                Entity={EEntityType.Consignee}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.Consignees}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesAttachedFilesReportParametersDTO) => val.LoadingPointIds) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesAttachedFilesReportParametersDTO) => x.LoadingPointIds)}
                                Label={i18next.t('report:LoadingPoints')}
                                Placeholder={i18next.t('report:SelectLoadingPoints')}
                                Entity={EEntityType.LoadingPoint}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.LoadingPointIds}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    {this._authService.IsShipper() && <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveriesAttachedFilesReportParametersDTO) => val.CargoTransporterIds) as any) }
                                Name={PropertyHelper.GetPropertyName((x: DeliveriesAttachedFilesReportParametersDTO) => x.CargoTransporterIds)}
                                Label={i18next.t('report:CargoTransporters')}
                                Placeholder={i18next.t('report:SelectCargoTransporters')}
                                Entity={EEntityType.CargoTransporter}
                                AllowMultiselect={true}
                                Value={props.DataHelper.ReportParams.CargoTransporterIds}
                                CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => this.UpdateReportParamsSelectField(state, dtoFieldName, helperFieldName, fieldVal, helperVal, onReportParamsChanged)}
                                dispatch={props.dispatch} />
                        </div>
                    </div>}
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveriesReportParametersDTO) => val.DateFrom) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DeliveriesReportParametersDTO) => val.DateFrom)}
                                Label={i18next.t('report:DeliveryDateFrom')}
                                Value={props.DataHelper.ReportParams.DateFrom}
                                DefaultHours={0}
                                DefaultMinutes={0}
                                DefaultSeconds={0}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveriesReportParametersDTO) => val.DateTo) as any) }
                                Name={PropertyHelper.GetPropertyName((val: DeliveriesReportParametersDTO) => val.DateTo)}
                                Label={i18next.t('report:DeliveryDateTo')}
                                Value={props.DataHelper.ReportParams.DateTo}
                                DefaultHours={23}
                                DefaultMinutes={59}
                                DefaultSeconds={59}
                                CustomDataUpdate={(state, dtoFieldName, fieldVal) => this.UpdateReportParamsCalendarField(state, dtoFieldName, fieldVal, onReportParamsChanged)}
                                dispatch={props.dispatch} 
                                IsClearable />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public HasChart(): boolean {
        return false;
    }

    public GetChart(data: any) {
        return <Bar data={data} options={this._options} />
    }

    public SetColor(backgroundColor: string, borderColor: string, dataset: IChartDataset, index: number) {
        dataset.backgroundColor = [];
        dataset.borderColor = [];
        dataset.borderWidth = CHART_BORDER_WIDTH;
        if(index === 0 || index === 1)
            dataset.stack = index + 1;
        else
            dataset.stack = 3;
        dataset.backgroundColor = backgroundColor;
        dataset.borderColor = borderColor;
    }

}