import { injectable } from 'inversify';
import * as React from 'react';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { VehicleTableDTO } from '../../../../Domain/DTO/VehicleTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../../Infrastructure/Types';
import i18next from '../../../i18n';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import VolmaSelect from '../../../VolmaSelect/index';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { DeliveryConfirmActions } from './DeliveryConfirmActions';
import { DeliveryConfirmDTO } from './DeliveryConfirmDTO';
import { DeliveryConfirmReducer } from './DeliveryConfirmReducer';
import { DeliveryConfirmHelperProps, InitialDeliveryConfirmHelperProps } from './IHelperProps';
import { DriverTableDTO } from '../../../../Domain/DTO/DriverTableDTO';
import { isDefined } from '../../../../Infrastructure/Services/Utils';
import VolmaInput from '../../../VolmaInput';

@injectable()
export class DeliveryConfirmEntityService extends BaseEntityService<DeliveryConfirmDTO, DeliveryConfirmHelperProps> {
    private _dispatch: any;

    private _reducer: DeliveryConfirmReducer;
    private _actions: DeliveryConfirmActions;

    constructor() {
        super();

        this._reducer = VolmaContainer.get<DeliveryConfirmReducer>(Types.ConfirmReducer);
        this._actions = VolmaContainer.get<DeliveryConfirmActions>(Types.ConfirmActions);

        this.AreDriverAndVehicleSelectsShown = this.AreDriverAndVehicleSelectsShown.bind(this);
        this.OnChangeCargoTransporter = this.OnChangeCargoTransporter.bind(this);
        this.UpdateCargoTransporterField = this.UpdateCargoTransporterField.bind(this);        
    }

    public GetEditor(props: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): JSX.Element {
        return <span></span>
    }

    public InitializeEntity(props: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): void {
        this._dispatch = props.dispatch;                   
    }

    public OnAfterDataLoaded(dto: DeliveryConfirmDTO): void {
        if (this.AreDriverAndVehicleSelectsShown(dto)) {
            this._dispatch(this._actions.LoadAllDrivers(i18next.t("common:Loading")));
            this._dispatch(this._actions.LoadAllVehicles(i18next.t("common:Loading")));
        }        
         
        if (dto.CargoTransporterId) {            
            this._dispatch(this._actions.LoadCargoTransporterById(i18next.t("common:Loading"), dto.CargoTransporterId))
        }
        
        if (dto.MultiCargoTransporterId) {
            this._dispatch(this._actions.LoadMultiCargoTransporterById(i18next.t("common:Loading"), dto.MultiCargoTransporterId, this._dispatch));
        }
    }

    public GetReducer(): IEntityReducer<DeliveryConfirmDTO, DeliveryConfirmHelperProps> {
        return this._reducer;
    }

    public GetInitialDataHelper(): DeliveryConfirmHelperProps {
        return InitialDeliveryConfirmHelperProps;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public IsEntitySendingAvailable(dto: DeliveryConfirmDTO, dataHelper: DeliveryConfirmHelperProps): boolean {
        if (isDefined(dto?.MultiCargoTransporterId)) {
            return isDefined(dataHelper?.SelectedCargoTransporter) || isDefined(dataHelper?.MultiCargoTransporter?.MainCargoTransporter);
        }

        return true;
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): JSX.Element {
        const showCargoTransporterField = props.DataDTO.IsCargoTransporterAvailable;
        const showDriverInfo = props.DataDTO.DriverId !== undefined;
        const showVehicleInfo = props.DataDTO.VehicleId !== undefined;
        const showDriverAndVehicleSelects = this.AreDriverAndVehicleSelectsShown(props.DataDTO);
        const driverOrVehicleDisabled = showCargoTransporterField ? !props.DataHelper.SelectedCargoTransporter : false;        

        return (
            <div className={(this._mainInfo()).toString()}>
                {showCargoTransporterField && <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryConfirmDTO) => val.CargoTransporterId))}
                                        Name={PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.CargoTransporterId)}
                                        Label={i18next.t('deliveryconfirm:CargoTransporter')}
                                        Placeholder={i18next.t('deliveryconfirm:SelectCargoTransporter')}
                                        Entity={EEntityType.CargoTransporter}
                                        Value={props.DataHelper.SelectedCargoTransporter?.Id}
                                        OnValueChanged={(value: CargoTransporterTableDTO) => this.OnChangeCargoTransporter(value)}
                                        CustomDataUpdate={this.UpdateCargoTransporterField}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>}
                {showDriverAndVehicleSelects && <>
                    <div className={(this._mainInfo("item")).toString()}>
                        <div className={(this._container()).toString()}>
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaSelect
                                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryConfirmDTO) => val.DriverId)) }
                                            Name={PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.DriverId)}
                                            Label={i18next.t('deliveryconfirm:Driver')}
                                            Placeholder={i18next.t('deliveryconfirm:SelectDriver')}
                                            Options={this.FilterDataByCargoTransporterId<DriverTableDTO>(props.DataHelper, PropertyHelper.GetPropertyName((x: DeliveryConfirmHelperProps) => x.AllDrivers))}
                                            LabelKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Name)}
                                            ValueKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Id)} 
                                            Value={props.DataDTO.DriverId}
                                            Disabled={driverOrVehicleDisabled}                                 
                                            Required={showDriverAndVehicleSelects} 
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                                {showDriverInfo && this.GetDriverInfo(props)}
                            </div>
                        </div>
                    </div>
                    <div className={(this._mainInfo("item")).toString()}>
                        <div className={(this._container()).toString()}>
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaSelect
                                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryConfirmDTO) => val.VehicleId)) }
                                            Name={PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.VehicleId)}
                                            Label={i18next.t('deliveryconfirm:Vehicle')}
                                            Placeholder={i18next.t('deliveryconfirm:SelectVehicle')}
                                            LabelKeyGetter={(x: VehicleTableDTO) => this.GetVehicleLabelKey(x)}
                                            Value={props.DataDTO.VehicleId}
                                            Options={this.FilterDataByCargoTransporterId<VehicleTableDTO>(props.DataHelper, PropertyHelper.GetPropertyName((x: DeliveryConfirmHelperProps) => x.AllVehicles))}
                                            ValueKey={PropertyHelper.GetPropertyName((x: VehicleTableDTO) => x.Id)}
                                            Disabled={driverOrVehicleDisabled}                                           
                                            Required={showDriverAndVehicleSelects} 
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                                {showVehicleInfo && this.GetVehicleInfo(props)}
                            </div>
                        </div>
                    </div>
                    <div className={(this._mainInfo("item")).toString()}>
                        <div className={(this._container()).toString()}>
                            <div className={(this._mainInfo("row")).toString()}>
                                <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                                        <VolmaInput
                                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryConfirmDTO) => val.ContainerId)) }
                                            Name={PropertyHelper.GetPropertyName((x: DeliveryConfirmDTO) => x.ContainerId)}
                                            Label={i18next.t('deliveryconfirm:ContainerId')}
                                            Value={props.DataDTO.ContainerId}
                                            Disabled={driverOrVehicleDisabled}                                 
                                            dispatch={props.dispatch} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}                
            </div>
        );
    }

    private GetDriverInfo(props: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): JSX.Element {
        return <>
            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                <div className={this._mainInfo("line", { fill: true }).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("deliveryconfirm:Driver")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SageGetValueFromCollection(props.DataHelper.AllDrivers, x => x.Id, props.DataDTO.DriverId, x => x.Name)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("deliveryconfirm:DriverPhone")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SageGetValueFromCollection(props.DataHelper.AllDrivers, x => x.Id, props.DataDTO.DriverId, x => x.PhoneNumber)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("deliveryconfirm:DriverDetails")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SageGetValueFromCollection(props.DataHelper.AllDrivers, x => x.Id, props.DataDTO.DriverId, x => x.Details)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>;
    }

    private GetVehicleInfo(props: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): JSX.Element {
        return <>
            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                <div className={this._mainInfo("line", { fill: true }).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("deliveryconfirm:VehicleTrailerMark")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SageGetValueFromCollection(props.DataHelper.AllVehicles, x => x.Id, props.DataDTO.VehicleId, x => x.TrailerModel)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("deliveryconfirm:VehicleTrailerPlateNumber")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SageGetValueFromCollection(props.DataHelper.AllVehicles, x => x.Id, props.DataDTO.VehicleId, x => x.TrailerLicensePlateNumber)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </>;
    }

    private GetVehicleLabelKey(x: VehicleTableDTO): string {
        return this.GetSafeString(x.Name) + (this.IsValueNotEmpty(x.VehicleLicensePlateNumber) ? (" (" + x.VehicleLicensePlateNumber + ")") : "");
    }

    private AreDriverAndVehicleSelectsShown(dataDto: DeliveryConfirmDTO): boolean {
        return !dataDto.MultiCargoTransporterId;
    }

    private FilterDataByCargoTransporterId<TData extends { CargoTransporterId?: string }>(
        dataHelper: DeliveryConfirmHelperProps, 
        dataHelperKey: keyof DeliveryConfirmHelperProps | string
        ): TData[] {
        const data = dataHelper[dataHelperKey] as TData[];

        return dataHelper.SelectedCargoTransporter ? data.filter(x => x.CargoTransporterId === dataHelper.SelectedCargoTransporter.Id) : data;
    }

    private OnChangeCargoTransporter(value: CargoTransporterTableDTO): void {
        this._dispatch(this._actions.SelectCargoTransporter(value));
    }

    private UpdateCargoTransporterField(
        state: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>, 
        dtoFieldName: string, 
        helperFieldName: string, 
        fieldVal: any, 
        helperVal: any): void {
        state.DataDTO[dtoFieldName] = fieldVal;

        if (!fieldVal && this.AreDriverAndVehicleSelectsShown(state.DataDTO)) {
            this.ClearDriverAndVehicleFields(state);
        }
    }

    private ClearDriverAndVehicleFields(state: IBaseEntityProps<DeliveryConfirmDTO, DeliveryConfirmHelperProps>): void {
        state.DataDTO.DriverId = undefined;
        state.DataDTO.VehicleId = undefined;
    }
}