import { Component } from "react";
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { DivMouseEventHandler, IVolmaTimeInputProps } from './IVolmaTimeProps';

interface IVolmaTimeInputState {};

export class VolmaTimeInput extends Component<IVolmaTimeInputProps, IVolmaTimeInputState> {

    private _defaultInput      = volmaBlock("volma-input");
    private _numberNav         = volmaBlock("number-nav");
    private _volmaTime         = volmaBlock('volma-time');

    render() {
        return (
            <div className={(this._defaultInput.mix(this._volmaTime(this.props.className))).toString()}>
                <input
                    {...this.props.inputControl}
                    onKeyDown={this.props.onInputKeyDown}
                    onKeyUp={this.props.onInputKeyUp} />
                <div className={(this._numberNav()).toString()}>
                    {this.GetButton('up', this.props.onUpValueStart, this.props.onUpValueEnd)}
                    {this.GetButton('down', this.props.onDownValueStart, this.props.onDownValueEnd)}                            
                </div>
            </div>
        )
    }

    private GetButton(key: string, onMouseDown: DivMouseEventHandler, onMouseUp: DivMouseEventHandler) {
        const svgAngleDown = <>
            <svg className={this._numberNav("link-ico").toString()}>
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
            </svg>
        </>

        return <div className={(this._numberNav("link", { [key]: true })).toString()}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
            onClick={this.props.onSkipEvent}>
            {svgAngleDown}
            {svgAngleDown}
            {svgAngleDown}
        </div>
    }
}