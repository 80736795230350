import 'react-app-polyfill/ie11';
import './styles/index.scss';
import 'reflect-metadata';

import * as moment from 'moment';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

// не переупорядочивай импорты
import configureStore, { history } from './ConfigureStore';
import {defaultAuth, defaultUnauth, authService, timeService} from './IndexInitializer'
import App from './Components/App';
import About from './Components/Entity/About';
import BaseEntity from './Components/Entity/BaseEntity';
import DriverMonitoring from './Components/Entity/DriverMonitoring/DriverMonitoring';
import PortalConfiguration from './Components/Entity/PortalConfiguration';
import Report from './Components/Entity/Report';
import Search from './Components/Entity/Search';
import Settings from './Components/Entity/Settings';
import Login from './Components/Login';
import PasswordReset from './Components/PasswordReset';
import PasswordRestore from './Components/PasswordRestore';
import Register from './Components/Register';
import BaseTable from './Components/Table/BaseTable';
import * as route from './Infrastructure/Router/RouterConstants';

// import 'leaflet/dist/leaflet.css';
moment.locale("ru");

 timeService.Initialize();

const store = configureStore({});

const component = (
  <Provider store={store}>
    <Router history={history}>
        <App>
           <Switch>
              <Route path={`/${route.LOGIN}`} render={() => authService.IsAuthenticated() ? <Redirect to={defaultAuth}/> : <Login/>}/>
              <Route path={`/${route.REGISTER}`} component={Register} />
              <Route path={`/${route.PASSWORD_RESET}`}component={PasswordReset} />
              <Route path={`/${route.PASSWORD_RESTORE}`}component={PasswordRestore} />
              <Route path={`/${route.ABSTRACTLIST}/:entity`} render={(props) => !authService.IsAuthenticated() ? <Redirect to={defaultUnauth}/> : <BaseTable {...props.match}/>}/>
              <Route path={`/${route.ABSTRACTENTITY}/:entity/:id?`} render={(props) => !authService.IsAuthenticated() ? <Redirect to={defaultUnauth}/> : <BaseEntity {...props.match}/>}/>
              <Route path={`/${route.RATING}`} component={BaseEntity} />
              <Route path={`/${route.SETTINGS}`} component={Settings} />
              <Route path={`/${route.ABOUT}`} component={About} />
              <Route path={`/${route.PORTAL_CONFIGURATION}`} component={PortalConfiguration} />
              <Route path={`/${route.REPORTS}`} component={Report} />
              <Route path={`/${route.SEARCH}`} component={Search} />
              <Route path={`/${route.DRIVER_MONITORING}`} component={DriverMonitoring} />
              <Redirect path="*" to={!authService.IsAuthenticated() ? defaultUnauth : defaultAuth}/>
          </Switch>
        </App>
      </Router>
  </Provider>
);

ReactDOM.render(component, document.getElementById("root"));
