export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
}

export function isDefined<T>(obj: T | null | undefined): boolean {
    return !isNullOrUndefined(obj);
}

export function isInteger (value) {
    return typeof value === "number" &&
        isFinite(value) &&
        Math.floor(value) === value;
};

export function SafeMergeArrays<T>(arrays: Array<Array<T>>): Array<T> {
    const mergedArr = [];

    for (const array of arrays) {
        if (array && isDefined(array) && array.length) {
            mergedArr.push(...array);
        }            
    }

    return mergedArr;
}