import * as React from 'react';
import { Component } from 'react';
import { IMainInfoItemProps } from './IMainInfoItemProps';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { FooterNavLink } from '../Footer/IFooterProps';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaComponent } from '../Shared/VolmaComponent';
import { MainInfoItemActions } from './MainInfoItemActions';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { Types } from '../../Infrastructure/Types';

class MainInfoItem extends Component<IMainInfoItemProps, {}>{
    private _mainInfo = volmaBlock('main-info');
    private _container = volmaBlock('container');
    private _titleDrop = volmaBlock('title-drop');
    private _infoDrop = volmaBlock('info-drop');

    private _mainInfoItemActions : MainInfoItemActions;
    private _link : HTMLElement;

    public props: IMainInfoItemProps;

    constructor(props: IMainInfoItemProps, context: any) {
        super(props, context);

        this._mainInfoItemActions = VolmaContainer.get<MainInfoItemActions>(Types.MainInfoItemActions);
    }

    public componentDidMount(){
        this.SaveFooterNavLink();
    }

    render(){
        return (
            <div className={(this._mainInfo("item", {dark: this.props.IsDark, light: this.props.IsLight, title: this.props.IsTitle })).toString()}
                ref={(link) => this._link = link }>
                <div className={(this._container()).toString()}>
                    <div className={(this._titleDrop).toString()}>
                        <div className={(this._titleDrop("left")).toString()}>
                            <h2 className={(this._titleDrop("title").mix([this._mainInfo("title")])).toString()}>{this.props.Title} </h2>
                            {this.props.Buttons}
                            <span className={(this._titleDrop("text")).toString()}> {this.props.Description} </span>
                        </div>
                        <div className={(this._titleDrop("right")).toString()} >
                            <span onClick={(e: any) => {this.props.dispatch(this._mainInfoItemActions.ToggleInfoLine(this.props.Index))}} className={(this._titleDrop("link").mix([this.props.ClosedCardParts[this.props.Index] ? "active" : ""])).toString()} > </span>
                        </div>
                    </div>
                    <div className={(this._infoDrop).toString()} >
                        {this.props.Row}
                    </div>
                </div>
            </div>
        );
    }

    private SaveFooterNavLink(){
        if (this._link !== null && this._link !== undefined){
            if(this.props.FooterNavList[this.props.Index] === undefined)
                this.props.dispatch(this._mainInfoItemActions.FooterLinkAdded({Ref: this._link, Title: this.props.Title, Index: this.props.Index}));
        }
    }
}

export default MainInfoItem;