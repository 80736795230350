import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

import { APP_LOGIN, LOGIN_MOUNTED, LOGIN_UNMOUNTED } from '../../Constants/AppConstants';
import { LoginDTO } from '../../Domain/DTO/LoginDTO';
import { UserDTO } from '../../Domain/DTO/UserDTO';
import { IAction } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { Types } from '../../Infrastructure/Types';
import { ApiActions } from '../Shared/ApiActions';
import { ComponentActions } from '../Shared/ComponentActions';

@injectable()
export class LoginActions extends ComponentActions {
    private _api: AuthServerInteraction;
    private _authService: AuthenticationService;
    private _apiActions: ApiActions;
    private _routerService: RouterService;

    constructor() {
        super();
        super.Initialize(LOGIN_MOUNTED, LOGIN_UNMOUNTED);
        this._api = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
    }

    public DoLogin(loginCommand: LoginDTO, text: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._api.Login(loginCommand),
                text,
                (response: AxiosResponse, dispatch:any) => {
                    this._apiActions.DoApiRequest(
                        dispatch,
                        () => this._api.GetUserInfo(),
                        text,
                        (response: AxiosResponse, dispatch: any) => {
                            let data = response.data;
                            let userDTO: UserDTO = JSON.parse(data);
                            // this._authService.SetAuthenticatedToken(response.data);
                            this._authService.SetAuthenticated();
                            this._authService.SetUser(userDTO);
                            dispatch(this.LoginSuccesful());
                            window.location.href = this._routerService.GetHome();
                        });
                });
        }
    }

    private LoginSuccesful(): IAction {
        return { type: APP_LOGIN };
    }

}