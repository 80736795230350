import { BaseEntityActions } from '../../../BaseEntity/BaseEntityActions';
import { EntityAction } from '../../../BaseEntity/EntityAction';
import { EEntityType } from '../../../../../Domain/Enum/EEntityType';
import { DriverTableDTO } from '../../../../../Domain/DTO/DriverTableDTO';
import { IActionPayloaded } from '../../../../../Infrastructure/Action/IAction';
import { VEHICLE_ALL_DRIVER_LOADED, VEHICLE_DRIVER_ADDED_TO_VEHICLE } from '../../../../../Constants/AppConstants';
import { ESortDir } from '../../../../../Domain/Enum/ESortDir';

export class SelectDriverActions extends EntityAction {

    public LoadAllDrivers(
        text: string
    ){
        return super.LoadDataTable<DriverTableDTO>(
            EEntityType.Driver,
            x => x.Name,
            ESortDir.Asc,
            undefined,
            text,
            this.AllDriversLoaded);
    }

    private AllDriversLoaded(value: Array<DriverTableDTO>): IActionPayloaded<Array<DriverTableDTO>> {
        return { type: VEHICLE_ALL_DRIVER_LOADED, Payload: value };
    }
}