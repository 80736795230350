import { EEntityType } from '../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { ReportParametersDTO } from '../../Domain/DTO/ReportParametersDTO';
import { AxiosPromise } from 'axios';


@injectable()
export class ReportServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public GetReport(): AxiosPromise {
        return this.Get(this._urlFabric.Report());
    }

    public PostExcelReportParams(entity: EEntityType, params: any): AxiosPromise {
        return this.Post(this._urlFabric.ExcelReportParams(ECQRSApi.Read, entity), params);
    }

    public GetExcelFile(id: string) {
        return this.DownloadFileNoParams(this._urlFabric.ExcelReport(id));
    }

    public PostChartParams(dto: ReportParametersDTO): AxiosPromise {
        return this.Post(this._urlFabric.ReportParameters(ECQRSApi.Write), dto);
    }

    // public GetReportExcel(id: string) : string{
    //     return this._urlFabric.ExcelReport(id);
    // }

    public GetReportData(entity: EEntityType, params: any): AxiosPromise {
        return this.Get(this._urlFabric.ReportData(ECQRSApi.Read, entity, params));
    }

    public PostChart(dto: ReportParametersDTO): AxiosPromise {
        return this.Post(this._urlFabric.ReportParameters(ECQRSApi.Write), dto);
    }

    public DeleteChart(id: string): AxiosPromise {
        return this.Delete(this._urlFabric.ReportParameters(ECQRSApi.Write, id));
    }
}