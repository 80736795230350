import { RouterService } from '../../Infrastructure/Router/RouterService';
import {
    REQUEST_BAD_REQUEST,
    REQUEST_BEGIN,
    REQUEST_CLEAR_ERROR,
    REQUEST_DEBOUNCE,
    REQUEST_END,
    REQUEST_OVERLAY_DEBOUNCE,
    REQUEST_SERVER_ERROR,
    REQUEST_UNAUTHORIZED,
    REQUEST_VALIDATION_ERROR
} from '../../Constants/AppConstants';
import { IAction, IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { Types } from '../../Infrastructure/Types';
import { IRequestEndPayload } from '../App/Payloads';
import { injectable } from 'inversify';
import { ServerErrorDTO } from '../../Domain/ServerErrorDTO';
import { ObjectValidationResultDTO } from '../../Domain/DTO/ObjectValidationResultDTO';
import * as uuid from 'uuid';
import { AxiosResponse } from 'axios';


@injectable()
export class ApiActions {
    private _api          : AuthServerInteraction;
    private _authService  : AuthenticationService;
    private _routerService: RouterService;

    constructor() {
        this._api           = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._authService   = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
    }

    public DoApiRequest(dispatch: any, requestCommand: () => any, text: string, responseCommand: (response: AxiosResponse, dispatch: any) => void, onError: (response: AxiosResponse, dispatch: any) => void = () => null) {
        let id = uuid.v4();
        dispatch(this.BeginRequest(id, text));
        // console.log("started", id);
        let request;
        try{
            request = requestCommand()
            .then((response: AxiosResponse) => {
                responseCommand(response, dispatch);
                dispatch(this.EndRequest(id));
                dispatch(this.ClearServerError());
                // console.log("finished", id)
            })
            .catch((response: any) => {
                onError(response, dispatch);

                console.error(response);
                dispatch(this.EndRequest(id));

                if(response.response !== undefined && response.response.status === REQUEST_UNAUTHORIZED){
                    this._authService.ClearAuthenticated();
                    window.location.href = this._routerService.GetHome();
                }

                if (response.response !== undefined && response.response.status === REQUEST_BAD_REQUEST)
                    dispatch(this.ValidationError(response.message, response.response));
                else
                    dispatch(this.ServerError(response.message, response.response));
                // console.log("finished", id)
            });
        }
        catch(error){
            console.log("finished in catch", id)
            dispatch(this.EndRequest(id));
            dispatch(this.ServerError((<Error>error).message + " " + (<Error>error).stack, {} as any));
        }
        return request;
    }

    private BeginRequest(id: string, text: string) {
        return (dispatch) => {
            dispatch({ type: REQUEST_BEGIN, Payload: { Uuid: id, Text: text, Date: new Date() } })
            setTimeout(() => dispatch({ type: REQUEST_DEBOUNCE }), REQUEST_OVERLAY_DEBOUNCE);
        }
    }

    private EndRequest(id: string): IActionPayloaded<IRequestEndPayload> {
        return { type: REQUEST_END, Payload: { Uuid: id } };
    }

    private ServerError(message: string, response: AxiosResponse = <any>{}): IActionPayloaded<ServerErrorDTO> {
        return { type: REQUEST_SERVER_ERROR, Payload: { Data: JSON.stringify(response.data), Status: response.status, StatusText: response.statusText, Message: message, Url: response.config !== undefined ? response.config.url : ""} };
    }

    private ValidationError(message: string, response: AxiosResponse = <any>{}): IActionPayloaded<ObjectValidationResultDTO> {
        return { type: REQUEST_VALIDATION_ERROR, Payload: response.data };
    }

    private ClearServerError(): IAction {
        return { type: REQUEST_CLEAR_ERROR };
    }
}
