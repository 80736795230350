import { injectable } from 'inversify';
import {
    RATING_CARGO_TRANSPORTER_LOADED,
    RATING_CURRENT_RATING_LOADED,
    RATING_FORECAST_RATING_LOADED, RATING_LOADING_POINTS_LOADED, RATING_LOADING_POINT_LOADED, RATING_MULTI_FACTORY_LOADED, RATING_RATING_HISTORY_LOADED
} from '../../../../Constants/AppConstants';
import { CargoTransporterDTO } from '../../../../Domain/DTO/CargoTransporterDTO';
import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { LoadingPointTableDTO } from '../../../../Domain/DTO/LoadingPointTableDTO';
import { RatingDTO } from '../../../../Domain/DTO/RatingDTO';
import { RatingTableDTO } from '../../../../Domain/DTO/RatingTableDTO';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../../Domain/Enum/ESortDir';
import { IActionPayloaded } from '../../../../Infrastructure/Action/IAction';
import { FilterHelper } from '../../../../Infrastructure/Services/FilterHelper';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { EntityAction } from '../../BaseEntity/EntityAction';
import { MultiFactoryDTO } from '../../../../Domain/DTO/MultiFactoryDTO';

@injectable()
export class RatingActions extends EntityAction {

    public LoadRatingHistory(loadingPointId: string, multiFactoryId: string | undefined, cargoTransporterId: string, waitingText: string) {
        return super.LoadDataTable(
            EEntityType.CurrentRating,
            (x: RatingTableDTO) => x.Timestamp,
            ESortDir.Desc,
            [this.GetCargoTransporterFilter(cargoTransporterId), this.GetMultiPointFilter(loadingPointId, multiFactoryId)],
            waitingText,
            this.RatingHistoryLoaded);
    } 

    public LoadAllLoadingPoints(waitingText: string) {
        return super.LoadDataTable(
            EEntityType.LoadingPoint,
            (x: LoadingPointTableDTO) => x.Name,
            ESortDir.Asc,
            undefined,
            waitingText,
            this.AllLoadingPointsLoaded);
    }

    public LoadCurrentRating(cargoTransporterId: string, waitingText: string) {
        return super.LoadChildDataTableItems(
            EEntityType.CargoTransporter,
            cargoTransporterId,
            EEntityType.CurrentRating,
            (x: RatingTableDTO) => x.Id,
            ESortDir.Asc,
            waitingText,
            this.AllCurrentRatingLoaded);
    }


    public LoadLoadingPoint(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.LoadingPoint,
            waitingText,
            this.LoadingPointLoaded);
    }

    public LoadMultiFactory(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.MultiFactory,
            waitingText,
            this.MultiFactoryLoaded);
    }

    public LoadCargoTransporter(id: string, waitingText: string) {
        return super.LoadEntityCached(
            id,
            EEntityType.CargoTransporter,
            waitingText,
            this.CargoTransporterLoaded);
    }

    public LoadForecastRating(loadingPointId: string, multiFactoryId: string, cargoTransporterId: string, waitingText: string) {
        return super.LoadDataTable(
            EEntityType.ForecastRating,
            (x: RatingTableDTO) => x.Timestamp,
            ESortDir.Desc,
            [this.GetCargoTransporterFilter(cargoTransporterId), this.GetMultiPointFilter(loadingPointId, multiFactoryId)],
            waitingText,
            this.ForecastRatingLoaded);
    }
    
    private RatingHistoryLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: RATING_RATING_HISTORY_LOADED, Payload: value };
    }

    private AllLoadingPointsLoaded(value: Array<LoadingPointTableDTO>): IActionPayloaded<Array<LoadingPointTableDTO>> {        
        return { type: RATING_LOADING_POINTS_LOADED, Payload: value };
    }

    private AllCurrentRatingLoaded(value: Array<RatingTableDTO>): IActionPayloaded<Array<RatingTableDTO>> {
        return { type: RATING_CURRENT_RATING_LOADED, Payload: value };
    }

    private LoadingPointLoaded(value: LoadingPointDTO): IActionPayloaded<LoadingPointDTO> {
        return { type: RATING_LOADING_POINT_LOADED, Payload: value };
    }

    private MultiFactoryLoaded(value: MultiFactoryDTO): IActionPayloaded<MultiFactoryDTO> {
        return { type: RATING_MULTI_FACTORY_LOADED, Payload: value };
    }

    private CargoTransporterLoaded(value: CargoTransporterDTO): IActionPayloaded<CargoTransporterDTO> {
        return { type: RATING_CARGO_TRANSPORTER_LOADED, Payload: value };
    }

    private ForecastRatingLoaded(value: Array<RatingTableDTO>): IActionPayloaded<RatingTableDTO> {
        if(value.length === 0)
            return { type: RATING_FORECAST_RATING_LOADED, Payload: undefined };

        return { type: RATING_FORECAST_RATING_LOADED, Payload: value[0] };
    }

    private GetMultiPointFilter(loadingPointId: string, multiFactoryId: string | undefined) {
        const multiPointKey = multiFactoryId 
            ? PropertyHelper.GetPropertyName((x: RatingDTO) => x.MultiFactoryId) 
            : PropertyHelper.GetPropertyName((x: RatingDTO) => x.LoadingPointId);

        return FilterHelper.CreateFilterByIds([multiFactoryId || loadingPointId], multiPointKey);
    }

    private GetCargoTransporterFilter(cargoTransporterId: string) {
        return FilterHelper.CreateFilterByIds(
            [cargoTransporterId], PropertyHelper.GetPropertyName((x: RatingDTO) => x.CargoTransporterId)
        );
    }
}