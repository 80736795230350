import { SELECT_TOUCHED, SELECT_VALIDATE, SELECT_VALUE_CHANGE } from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { IReducePayloaded } from '../../Infrastructure/Reducer/IReducer';
import { IErrorPayload } from '../VolmaInput/Payloads';
import { IVolmaSelectProps, VolmaSelectPropsInitial } from './IVolmaSelectProps';
import { injectable } from 'inversify';
import { ISelectValueChangedPayload } from './Payloads';

@injectable()
export class VolmaSelectReducer implements IReducePayloaded<IVolmaSelectProps> {

    public Reduce(state: IVolmaSelectProps = VolmaSelectPropsInitial, action: IActionPayloaded<any>): IVolmaSelectProps {
        switch (action.type) {
            case SELECT_VALUE_CHANGE:
                return this.ReduceValue(state, action.Payload);
            case SELECT_TOUCHED:
                return this.ReduceTouched(state);
            case SELECT_VALIDATE:
                return this.ReduceValidate(state, action.Payload);
            default:
                return state;
        }
    }

    private ReduceValue(state: IVolmaSelectProps, payload: ISelectValueChangedPayload) {
        let multiValue = state.EntityMultiValue;

        if (payload.EntityData) {
            if (!multiValue) {
                multiValue = [];
            }

            multiValue[payload.EntityData.EntityIdx] = payload.Value;
        }        

        const newState: IVolmaSelectProps = { ...state, Value: payload.Value, EntityMultiValue: multiValue };

        return newState;
    }

    private ReduceTouched(state: IVolmaSelectProps) {
        return { ...state, IsTouched: true };
    }

    private ReduceValidate(state: IVolmaSelectProps, payload: IErrorPayload) {
        let validationResult = state.Validator.Validate(state);
        
        if (validationResult.IsValid === state.IsValid && validationResult.ErrorMessage === state.ErrorMessage) {
            return state;
        }
        
        return { ...state, ErrorMessage: validationResult.ErrorMessage, IsValid: validationResult.IsValid };
    }

}
