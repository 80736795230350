import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import { IGlobalState } from '../../../Reducers/IGlobalState';
import i18next from '../../i18n';
import { BaseCustomLoadSaveComponent } from '../BaseCustomLoadSave/BaseCustomLoadSaveComponent';
import { AboutActions } from './AboutActions';
import { AboutEntityService } from './AboutEntityService';
import { IAboutProps } from './IAboutProps';

class About extends BaseCustomLoadSaveComponent<IAboutProps> {
    private _aboutActions : AboutActions;
    private _aboutEntityService: AboutEntityService;

    constructor(props: IAboutProps, context: any) {
        super(props, context, EEntityType.About);

        this._key = "about";
        this._aboutActions = VolmaContainer.get<AboutActions>(Types.AboutActions);
        this._aboutEntityService = VolmaContainer.get<AboutEntityService>(Types.AboutEntityService);

        this.SetActions(this._aboutActions);
    }

    public UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount();
        this.LoadData();
    }

    public OnSaveClick(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    public OnCancelClick(e: any) {
        e.preventDefault();
        e.stopPropagation();

    }

    public LoadData(){
        this._aboutEntityService.InitializeEntity(this.props);
        this.props.dispatch(this._aboutActions.LoadAbout(i18next.t('common:Loading')));
        this.props.dispatch(this._aboutActions.SetEntityType(EEntityType.About));
    }

    public IsEditable(): boolean {
        return false;
    }

}

const translated = withTranslation(['common'])(withRouter(About));

function select(state: IGlobalState, ownProps: IAboutProps): IAboutProps {
    return state.About;
}

let connectedAboutEntity = connect<IAboutProps, {}, {}>(select)(translated);
export default connectedAboutEntity;