import { injectable } from 'inversify';
import * as React from 'react';
import { InstructionDTO } from '../../../Domain/DTO/InstructionDTO';
import { InstructionTableDTO } from '../../../Domain/DTO/InstructionTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import i18next from '../../i18n';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaFile from '../../VolmaFile';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import VolmaInput from '../../VolmaInput/VolmaInput';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { InstructionReducer } from './InstructionReducer';


@injectable()
export class InstructionEntityService extends BaseEntityService<InstructionDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;
    private _reducer: InstructionReducer;
    private readonly _nameMaxLength = 32;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);

        this._reducer = VolmaContainer.get<InstructionReducer>(Types.InstructionReducer);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.Instruction]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Instruction,
            [
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: InstructionTableDTO) => val.Name), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    Weight: 2,
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: InstructionTableDTO) => val.FileId), 
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer, 
                    CellRenderer: VolmaTableCommonCellRenderers.FileDownloadCellRenderer,
                    IsFilterable: false,
                    IsSortable: false,
                },
            ]
        );
    }

    public GetHeaderEditor(props: IBaseEntityProps<InstructionDTO, any>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={this._createCard("cell")}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: InstructionDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: InstructionDTO) => val.Name)}
                        Label={i18next.t('instruction:Name')}
                        Placeholder={i18next.t('instruction:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        MaxLength={this._nameMaxLength}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<InstructionDTO, any>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public GetEditorModal(props: IBaseEntityProps<InstructionDTO, any>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("instruction:MainInfoTitle"), undefined, this.GetMainInfoBlock(props), undefined, props, props.dispatch)}
                </div>
            </div>
        );
    }  

    public InitializeEntity(props: IBaseEntityProps<InstructionDTO, any>) {
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetInitialDataHelper() {
        return {};
    }

    public OnAfterDataLoaded(dto: InstructionDTO): void {
    }

    private GetMainInfoBlock(props: IBaseEntityProps<InstructionDTO, any>): JSX.Element {
        const isEditable = this.IsEditable(props);
        return (
            <div className={this._mainInfo("row")}>
                <div className={this._mainInfo("cell")}>
                    <div className={this._mainInfo("line", { full: true })}>
                        <VolmaFile
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: InstructionDTO) => val.FileId) as any) }
                            Name={PropertyHelper.GetPropertyName((val: InstructionDTO) => val.FileId)}
                            Label={i18next.t('instruction:FileId')}
                            Value={props.DataDTO.FileId}
                            Required={true}
                            Readonly={!isEditable}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    public IsEditable(props: IBaseEntityProps<InstructionDTO, any>): boolean {
        return this._authorizationService.IsEditable(props.EntityType);
    }
}