import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';

export class BiddingHelperProps {
    Bets:         Array<BiddingBetTableDTO>
    BetsOriginal: Array<BiddingBetTableDTO>

}

export const InitialBiddingHelperProps: BiddingHelperProps = <BiddingHelperProps>{
    Bets: [],
    BetsOriginal: []
};