import { injectable } from 'inversify';
import { LatLng } from 'leaflet';
import { GIS_COORDINATES_CHANGED, LAT_COORDINATES_CHANGED, LON_COORDINATES_CHANGED, SET_IS_MAX_PERCENTAGE_AVAILABLE } from '../../../Constants/AppConstants';
import { GeoCoordinateDTO } from '../../../Domain/DTO/GeoCoordinateDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { EntityReducer } from '../BaseEntity/EntityReducer';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';

// пропсы определены как эни, потому что они так были определены и до правок в LoadingPointTypeEntityService

@injectable()
export class LoadingPointReducer extends EntityReducer<LoadingPointDTO, any> {
    public static Initialize(): any {
        const reducer = new LoadingPointReducer();
        return (state: any, action: IActionPayloaded<any>) => reducer.Reduce(state, action);
    }

    public Reduce(state: IBaseEntityProps<LoadingPointDTO, any>, action: IActionPayloaded<any>): IBaseEntityProps<LoadingPointDTO, any>{
        if (state === undefined) return {}
        
        switch (action.type) {
            case GIS_COORDINATES_CHANGED:
                return this.LoadPointCoordinatesChanged(state, action.Payload);
            case LON_COORDINATES_CHANGED:
                return this.LonCoordinatesChanged(state, action.Payload)
            case LAT_COORDINATES_CHANGED:
                return this.LatCoordinatesChanged(state, action.Payload)
            case SET_IS_MAX_PERCENTAGE_AVAILABLE:
                return this.SetIsMaxPercentageAvailable(state, action.Payload)
            default:
                return state;
        }
    }

    private SetIsMaxPercentageAvailable(state: IBaseEntityProps<LoadingPointDTO, any>, payload: boolean) :any{
        const newState = { ...state };
        
        newState.DataHelper = {
            ...newState.DataHelper,
            IsMaxPercentageAvailable: payload
        }
                
        
        return newState;
    }

    private LoadPointCoordinatesChanged(state: IBaseEntityProps<LoadingPointDTO, any>, payload: LatLng) :any{
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                Longitude: payload.lng,
                Latitude: payload.lat
            }
        }
                
        return newState;
    }

    private LatCoordinatesChanged(state: IBaseEntityProps<LoadingPointDTO, any>, payload: number) :IBaseEntityProps<LoadingPointDTO, any>{
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                ...newState.DataDTO.GeoCoordinate,
                Latitude: payload
            }
        }
                
        return newState;
    }

    private LonCoordinatesChanged(state: IBaseEntityProps<LoadingPointDTO, any>, payload: number) :IBaseEntityProps<LoadingPointDTO, any>{
        const newState = { ...state };
        
        newState.DataDTO = {
            ...newState.DataDTO,
            GeoCoordinate: {
                ...newState.DataDTO.GeoCoordinate,
                Longitude: payload
            }
        }   

        return newState;
    }


}
