import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import * as React from 'react';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { ServicePartsVersionDTO } from '../../../Domain/DTO/ServicePartsVersionDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableCellRendererParams } from '../../Table/VolmaTable/IVolmaTableProps';
import { IAboutProps } from './IAboutProps';
import { AboutActions } from './AboutActions';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { Types } from '../../../Infrastructure/Types';
import VolmaInput from '../../VolmaInput/index';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import i18next from '../../i18n';
import { injectable } from 'inversify';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import About from './index';
import { VERSION } from '../../../Constants/AppConstants';

@injectable()
export class AboutEntityService extends BaseEntityService<ServicePartsVersionDTO, any>{
    private _actions: AboutActions;
    private _dispatch: any;

    constructor(){
        super();
        this._actions = VolmaContainer.get<AboutActions>(Types.AboutActions);
    }

    public InitializeEntity(props: IAboutProps){
        this._dispatch = props.dispatch;
        this._dispatch(this._actions.SetEntityType(EEntityType.About));
        this._dispatch(this._actions.ClearFooter());
    }

    public GetColumnsList(): Array<IVolmaTableColumn>{
        return [];
    }

    public GetReducer(){
        return undefined;
    }

    public OnAfterDataLoaded(dto: ServicePartsVersionDTO){

    }

    public GetInitialDataHelper(){
        return {};
    }

    public IsEditable(props: IAboutProps): boolean {
        return false;
    }

    public GetHeaderEditor(props: IAboutProps): JSX.Element {
        return (
            <div className={(this._createCard("header")).toString()}>
                <span className={this._createCard("title-icon").toString()}>
                    <svg className={this._createCard("title-ico").mix(["info-block-ico"]).toString()}>
                        <use xlinkHref="#info-block"></use>
                    </svg>
                    <svg className={this._createCard("title-ico-bg").mix(["info-bg-ico"]).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#info-block"></use>
                    </svg>
                </span>
                <span className={this._createCard("header", { text: true }).toString()}>{i18next.t("about:Title")}</span>
            </div>
        );
    }

    public GetEditor(props: IAboutProps): JSX.Element {
        if (props.DataDTO === undefined)
            return;

        return this.GetReadonlyEditor(props);
    }

    public GetEditorModal(props: IAboutProps): JSX.Element {
        return undefined;
    }

    public GetReadonlyEditor(props: IAboutProps): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("about:MainSiteInfoTitle"), i18next.t("about:MainSiteInfoDescription"), this.GetReadonlyMainSiteInfoBlock(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    public GetMainSpecification(props: IAboutProps){
        return (
        <div className={(this._mainInfoSpecification).toString()}>
            <dl className={(this._mainInfoSpecification("box")).toString()}>
                <div className={(this._mainInfoSpecification("row")).toString()}>
                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:SpaVersion")}:</dt>
                    <dd className={(this._mainInfoSpecification("val")).toString()}>{VERSION + " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.SpaVersion)) + ")"}</dd>
                </div>
                <div className={(this._mainInfoSpecification("row")).toString()}>
                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:ReadApiVersion")}:</dt>
                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadApiAssemblyVersion)) +
                        " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadApiVersion)) + ")"}</dd>
                </div>
                <div className={(this._mainInfoSpecification("row")).toString()}>
                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:WriteApiVersion")}:</dt>
                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WriteApiAssemblyVersion)) +
                        " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WriteApiVersion)) + ")"}</dd>
                </div>
                <div className={(this._mainInfoSpecification("row")).toString()}>
                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:ServerVersion")}:</dt>
                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ServerAssemblyVersion)) +
                        " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ServerVersion)) + ")"}</dd>
                </div>
                <div className={(this._mainInfoSpecification("row")).toString()}>
                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:ReadServiceVersion")}:</dt>
                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadServiceAssemblyVersion)) +
                        " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadServiceVersion)) + ")"}</dd>
                </div>
            </dl>
        </div>
        );
    }

    public GetMainSpecificationForMainPage(props: IAboutProps){
        return (
        <div className={(this._mainInfoSpecification).toString()}>
            <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:Spa")}:</dt>
            <dd className={(this._mainInfoSpecification("val")).toString()}>{VERSION + " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.SpaVersion)) + ")"}</dd>
            <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:ReadApi")}:</dt>
            <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadApiAssemblyVersion)) +
                " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadApiVersion)) + ")"}</dd>
            <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:WriteApi")}:</dt>
            <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WriteApiAssemblyVersion)) +
                " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WriteApiVersion)) + ")"}</dd>
            <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:Server")}:</dt>
            <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ServerAssemblyVersion)) +
                " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ServerVersion)) + ")"}</dd>
            <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("about:ReadService")}:</dt>
            <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeString(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadServiceAssemblyVersion)) +
                " (" + this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ReadServiceVersion)) + ")"}</dd>
        </div>
        );
    }
    private GetReadonlyMainSiteInfoBlock(props: IAboutProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    {this.GetMainSpecification(props)}
                </div>
            </div>
        );
    }
}