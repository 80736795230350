import { DeliveryManualAssignDTO } from '../../../../Domain/DTO/DeliveryManualAssignDTO';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaNumberValidator } from '../../../../Infrastructure/Validation/VolmaNumberValidator';
import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../../VolmaInput/index';
import VolmaNumber from '../../../VolmaNumber';
import { BaseEntityService } from '../../BaseEntity/BaseEntityService';
import { IBaseEntityProps } from '../../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../../BaseEntity/IEntityReducer';
import { injectable } from 'inversify';
import * as React from 'react';
import i18next from '../../../i18n';
import { VolmaMultiTransporterSelect } from '../../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect';
import { CargoTransporterTableDTO } from '../../../../Domain/DTO/CargoTransporterTableDTO';
import { DeliveryManualAssignReducer } from './DeliveryManualAssignReducer';
import { DeliveryManualAssignActions } from './DeliveryManualAssignActions';
import { DeliveryManualAssignHelperProps, InitialDeliveryManualAssignHelperProps } from './IHelperProps';
import { IEntityDTO } from '../../../../Domain/IEntityDTO';
import { MultiCargoTransporterTableDTO } from '../../../../Domain/DTO/MultiCargoTransporterTableDTO';


@injectable()
export class DeliveryManualAssignEntityService extends BaseEntityService<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>{
    private _volmaTableService: VolmaTableService;
    private _numberValidator: VolmaNumberValidator;

    private _reducer: DeliveryManualAssignReducer;
    private _actions: DeliveryManualAssignActions;

    private _dispatch;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._reducer = VolmaContainer.get<DeliveryManualAssignReducer>(Types.DeliveryManualAssignReducer);
        this._actions = VolmaContainer.get<DeliveryManualAssignActions>(Types.DeliveryManualAssignActions);

        this.UpdateManualAssignFields = this.UpdateManualAssignFields.bind(this);
        this.OnChangeMultiTransporter = this.OnChangeMultiTransporter.bind(this);
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetHeaderEditor(props: IBaseEntityProps<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>): JSX.Element {
        return undefined;
    }   
    
    private OnChangeMultiTransporter(value) {        
        this._dispatch(this._actions.SelectMultiTransporter(value));
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>): JSX.Element {        
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaMultiTransporterSelect
                                        {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryManualAssignDTO) => val.CargoTransporterId))}
                                        Name={PropertyHelper.GetPropertyName((x: DeliveryManualAssignDTO) => x.CargoTransporterId)}
                                        OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                                        Value={props.DataHelper.SelectedMultiTransporter}
                                        OnValueChanged={this.OnChangeMultiTransporter}
                                        CustomDataUpdate={(state, dtoFieldName, helperFieldName, fieldVal, helperVal) => 
                                            this.UpdateManualAssignFields(state, dtoFieldName, helperFieldName, fieldVal, helperVal)}
                                        dispatch={props.dispatch}
                                        Required /> 
                                </div>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaNumber
                                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryManualAssignDTO) => val.Rate) }
                                        Name={PropertyHelper.GetPropertyName((val: DeliveryManualAssignDTO) => val.Rate)}
                                        Label={i18next.t('deliverymanualassign:Rate')}
                                        Placeholder={i18next.t('deliverymanualassign:Rate')}
                                        Value={props.DataDTO.Rate}
                                        Validator={this._numberValidator}
                                        MinValue={() => 0}
                                        Required={true}
                                        Step={1}
                                        Unit={props.DataDTO.Currency}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this._mainInfo("item")).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._mainInfo("row")).toString()}>
                            <div className={this._mainInfo("cell", { 100: true }).toString()}>
                                <div className={this._mainInfo("line", { fill: true }).toString()}>
                                    <VolmaInput
                                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryManualAssignDTO) => val.Comment) }
                                        Name={PropertyHelper.GetPropertyName((val: DeliveryManualAssignDTO) => val.Comment)}
                                        Type="textarea"
                                        Label={i18next.t('deliverymanualassign:Comment')}
                                        Required={true}
                                        Value={props.DataDTO.Comment}
                                        dispatch={props.dispatch} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public GetEditor(props: IBaseEntityProps<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps>): void {
        this._dispatch = props.dispatch;

        this._dispatch(this._actions.LoadAllMultiTransporters());
    }

    public OnAfterDataLoaded(dto: DeliveryManualAssignDTO): void {
        return undefined;
    }

    public GetReducer(): IEntityReducer<DeliveryManualAssignDTO, DeliveryManualAssignHelperProps> {
        return this._reducer;
    }

    public GetInitialDataHelper(): DeliveryManualAssignHelperProps {
        return InitialDeliveryManualAssignHelperProps;
    }

    private UpdateManualAssignFields(
        state: IBaseEntityProps<IEntityDTO, any>, 
        dtoFieldName: string, 
        helperFieldName: string, 
        fieldVal: any, 
        helperVal: any) {
        const allMultiCargoTransporters: MultiCargoTransporterTableDTO[] = state.DataHelper.AllMultiCargoTransporters;
        const mainCargoTransporterId = PropertyHelper.SageGetValueFromCollection(allMultiCargoTransporters, x => x.Id, fieldVal, x => x.MainCargoTransporterId);

        state.DataDTO[dtoFieldName] = mainCargoTransporterId ? mainCargoTransporterId : fieldVal;
    }
}