import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import VolmaInput from '../../VolmaInput';
import * as React from 'react';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { Types } from '../../../Infrastructure/Types';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import { LocalityTableDTO } from '../../../Domain/DTO/LocalityTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { BodyTypeDTO } from '../../../Domain/DTO/BodyTypeDTO';
import { BodyTypeTableDTO } from '../../../Domain/DTO/BodyTypeTableDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import i18next from '../../i18n';
import { DEBUG } from '../../../Constants/AppConstants';

@injectable()
export class BodyTypeEntityService extends BaseEntityService<BodyTypeDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _volmaTableService: VolmaTableService;

    constructor() {
        super()

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.BodyType]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.BodyType,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: BodyTypeTableDTO) => val.Id) },
                { DataKey: PropertyHelper.GetPropertyName((val: BodyTypeTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
                { DataKey: PropertyHelper.GetPropertyName((val: BodyTypeTableDTO) => val.Type), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer },
            ]
        );
    }

    public GetEntityId(props: IBaseEntityProps<BodyTypeTableDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: BodyTypeTableDTO) => x.Code),
            (x: BodyTypeTableDTO, id: string) => x.Code.toLowerCase() === id);
    }

    public GetHeaderEditor(props: IBaseEntityProps<BodyTypeDTO, any>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: BodyTypeDTO) => val.Type) }
                        Name={PropertyHelper.GetPropertyName((val: BodyTypeDTO) => val.Type)}
                        Label={i18next.t('bodytype:Type')}
                        Placeholder={i18next.t('bodytype:TypePlaceholder')}
                        Value={props.DataDTO.Type}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: BodyTypeDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: BodyTypeDTO) => val.Code)}
                        Label={i18next.t('bodytype:Code')}
                        Placeholder={i18next.t('bodytype:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<BodyTypeDTO, any>): JSX.Element {
        return undefined;
    }

    public GetEditor(props: IBaseEntityProps<BodyTypeDTO, any>): JSX.Element {
        return undefined;
    }

    public InitializeEntity(props: IBaseEntityProps<LocalityDTO, any>) {
    }

    public GetInitialDataHelper() {
        return {};
    }

    public OnAfterDataLoaded(dto: BodyTypeDTO) {
        return;
    }

    public GetReducer() {
        return undefined;
    }
}