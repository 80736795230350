import { ISearchTableDataPayload } from './Payloads';
import {
    SEARCH_MOUNTED,
    SEARCH_RESULTS_LOADED,
    SEARCH_TEXT_UPDATED,
    SEARCH_UNMOUNTED
} from '../../../Constants/AppConstants';
import { SEARCH_LOADED } from '../../../Constants/AppConstants';
import { DataDTO } from '../../../Domain/DTO/DataDTO';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaComponentReducer } from '../../Shared/VolmaComponentReducer';
import { ISearchProps, SearchPropsInitial } from './ISearchProps';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';


@injectable()
export class SearchComponentReducer extends VolmaComponentReducer<ISearchProps> {

    constructor() {
        super(SEARCH_MOUNTED, SEARCH_UNMOUNTED);

    }

    public Reduce(state: ISearchProps = SearchPropsInitial, action: IActionPayloaded<any>): ISearchProps {
        state = super.Reduce(state, action);
        if (!state.IsMounted)
            return state;
        switch (action.type) {
            case SEARCH_LOADED:
                return this.ReduceDataLoaded(state, action.Payload);
            case SEARCH_RESULTS_LOADED:
                return this.ReduceSearchResultsLoaded(state, action.Payload);
            case SEARCH_TEXT_UPDATED:
                return this.ReduceSearchTextUpdated(state, action.Payload);
            default:
                return state;
                
        }
    }

    private ReduceDataLoaded(state: ISearchProps, payload: IEntityDTO): ISearchProps {
        return <ISearchProps>{ ...state, DataDTO: payload };
    }

    private ReduceSearchResultsLoaded(state: ISearchProps, payload: ISearchTableDataPayload): ISearchProps {
        let newState = { ...state, DataHelper: { ...state.DataHelper } };

        newState.DataHelper.SearchResults = payload.Items;
        newState.DataHelper.ItemsPerPage = payload.ItemsPerPage;
        newState.DataHelper.PageNumber   = payload.PageNumber;
        newState.DataHelper.TotalItems   = payload.TotalItems;
        newState.DataHelper.TotalPages   = payload.TotalPages;
        
        return newState;
    }

    private ReduceSearchTextUpdated(state: ISearchProps, payload: string): ISearchProps {
        return { ...state, DataDTO: { SearchString: payload } };
    }

    public static Initialize(): (state: ISearchProps, action: IActionPayloaded<ISearchProps>) => ISearchProps {
        const reducer = new SearchComponentReducer();
        return (state: ISearchProps, action: IActionPayloaded<ISearchProps>) => reducer.Reduce(state, action);
    }
}