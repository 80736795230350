import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableCellRendererParams } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { ITenderTableHelperProps } from './Table/ITableHelperProps';
import { ETenderOperationType } from '../../../Domain/Enum/ETenderOperationType';
import { TenderChangelogTableDTO } from '../../../Domain/DTO/TenderChangelogTableDTO';
import { EnumService } from '../../../Infrastructure/Services/EnumService';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { ChangelogService } from '../../../Infrastructure/Services/ChangelogService';
import { VolmaTableTenderCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableTenderCellRenderers';
import { VolmaTableCellHeightMeasurers } from '../../Table/VolmaTable/Renderers/VolmaTableCellHeightMeasurers';

@injectable()
export class TenderChangelogEntityService{
    private _volmaTableService: VolmaTableService;
    private _enumService      : EnumService;
    private _changelogService : ChangelogService;

    constructor() {
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._enumService       = VolmaContainer.get<EnumService>(Types.EnumService);
        this._changelogService  = VolmaContainer.get<ChangelogService>(Types.ChangelogService);
    }

    public GetColumns(): Array<IVolmaTableColumn>{
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.TenderChangelog,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.Id)},
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.UserFio),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.Timestamp), 
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.OperationType),
                    CellRenderer: (props: IVolmaTableProps<ITenderTableHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ETenderOperationType, this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterEnum: () => ETenderOperationType
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.NewValues),
                    CellRenderer: (props: IVolmaTableProps<ITenderTableHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableTenderCellRenderers.TenderChangelogCellRenderer(EEntityType.Tender, this._changelogService, this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    Weight: 2
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderChangelogTableDTO) => val.CommentsForShipper),
                    CellRenderer: (props: IVolmaTableProps<ITenderTableHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableTenderCellRenderers.TenderCommentsCellRenderer(props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer,
                    Weight: 2
                },
            ]
        );

    }
}