import { VOLMA_ENTITY_CLEAR_FOOTER, VOLMA_ENTITY_DTO_STRINGIFY_UPDATED } from '../../../Constants/AppConstants';
import {
    ABOUT_LOADED,
    ABOUT_MOUNTED,
    ABOUT_UNMOUNTED,
    FORM_SUBMITTED
} from '../../../Constants/AppConstants';
import { ServicePartsVersionDTO } from '../../../Domain/DTO/ServicePartsVersionDTO';
import { IAction, IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AboutServerInteraction } from '../../../Infrastructure/ServerInteraction/AboutServerInteraction';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';

@injectable()
export class AboutActions extends ComponentActions {
    private _entityService: EntityService;
    private _serverInteraction: AboutServerInteraction;
    private _apiActions: ApiActions;

    constructor() {
        super();
        super.Initialize(ABOUT_MOUNTED, ABOUT_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._serverInteraction = VolmaContainer.get<AboutServerInteraction>(Types.AboutServerInteraction);
    }

    public LoadAbout(waitingText: string){
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._serverInteraction.GetServiceInfo(),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data: ServicePartsVersionDTO = response.data;
                    dispatch(this.AboutLoaded(data));
                });
        }
    }

    public Submit(): IAction {
        return { type: FORM_SUBMITTED };
    }

    public ClearFooter() : IAction{
        return { type: VOLMA_ENTITY_CLEAR_FOOTER };
    }

    private AboutLoaded(item: ServicePartsVersionDTO){
        return { type: ABOUT_LOADED, Payload: item };
    }

    public UpdateDTOStringify(data: any): IActionPayloaded<Array<any>> {
        return { type: VOLMA_ENTITY_DTO_STRINGIFY_UPDATED, Payload: data };
    }
}