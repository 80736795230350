import { injectable } from "inversify";
import {ELogLevel} from "../../Domain/ELogLevel";

@injectable()
export class LogService {
    private _minLevel: ELogLevel;

    constructor() {
        this._minLevel = ELogLevel.Trace;
    }

    public Trace(message?: any, ...optionalParams: any[]): void {
        if(this._minLevel <= ELogLevel.Trace)
            console.log(message, optionalParams);
    }

    public Info(message?: any, ...optionalParams: any[]): void {
        if (this._minLevel <= ELogLevel.Info)
            console.info(message, optionalParams);
    }
    public Warn(message?: any, ...optionalParams: any[]): void {
        if (this._minLevel <= ELogLevel.Warn)
            console.warn(message, optionalParams);
    }
    public Debug(message?: any, ...optionalParams: any[]): void {
        if (this._minLevel <= ELogLevel.Debug)
            console.debug(message, optionalParams);
    }
    public Error(message?: any, ...optionalParams: any[]): void {
        if (this._minLevel <= ELogLevel.Error)
            console.error(message, optionalParams);
    }
}