import { ComplaintDTO } from '../../../../Domain/DTO/ComplaintDTO';
import { EComplaintState } from '../../../../Domain/Enum/EComplaintState';
import { CloseAction } from '../Action/CloseAction';
import { ComplaintStatusDefault } from './ComplaintStatusDefault';
import { HarmonizationOfConditions } from './HarmonizationOfConditions';
import { CancelAction } from '../Action/CancelAction';

export class Satisfied extends ComplaintStatusDefault{

    public TitleCurrent : string = "complaint:StatusSatisfiedCurrent";
    public TitleFinished: string = "complaint:StatusSatisfiedFinished";
    public TitleFuture  : string = "complaint:StatusSatisfiedFuture";

    public GetActions(dto: ComplaintDTO){
        return [new CloseAction(), new CancelAction()];
    }

    constructor(){
        super();
        this._prevState = new HarmonizationOfConditions();
        this._currentStatus = EComplaintState.Satisfied;
    }
}