import {
    INPUT_CALENDAR_TOGGLE_OVERLAY,
    INPUT_REMOVE_ACTIVE,
    INPUT_SET_ACTIVE,
    INPUT_VALUE_CHANGE
} from '../../Constants/AppConstants';
import { IActionPayloaded } from '../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import { FileServerInteraction } from '../../Infrastructure/ServerInteraction/FileServerInteraction';
import { Types } from '../../Infrastructure/Types';
import { BaseInputActions } from '../Shared/BaseInputActions';
import { IInputPayload } from '../VolmaInput/Payloads';
import { ICalendarToggleOverlayPayload, ICalendarValueChangedPayload } from './Payloads';
import { injectable } from 'inversify';


@injectable()
export class VolmaCalendarActions extends BaseInputActions {
    private _fileApi: FileServerInteraction;

    constructor(){
        super();

        this._fileApi = VolmaContainer.get<FileServerInteraction>(Types.FileServerInteraction);
    }

    public ChangeValue(name: string, date: Date): IActionPayloaded<ICalendarValueChangedPayload> {
        return { type: INPUT_VALUE_CHANGE, Payload: { InputName: name, Value: date, ShowOverlay: false} };
    }

    public ToggleOverlay(name: string, showOverlay: boolean): IActionPayloaded<ICalendarToggleOverlayPayload> {
        return { type: INPUT_CALENDAR_TOGGLE_OVERLAY, Payload: { InputName: name, ShowOverlay: showOverlay} };
    }

    public SetActive(name: string): IActionPayloaded<IInputPayload> {
        return { type: INPUT_SET_ACTIVE, Payload: { InputName: name } };
    }

    public RemoveActive(name: string): IActionPayloaded<IInputPayload> {
        return { type: INPUT_REMOVE_ACTIVE, Payload: { InputName: name } };
    }
}

