import { injectable } from "inversify";
import { DRIVER_MONITORING_MOUNTED, DRIVER_MONITORING_UNMOUNTED } from "../../../Constants/AppConstants";
import { IActionPayloaded } from "../../../Infrastructure/Action/IAction";
import { VolmaContainer } from "../../../Infrastructure/InversifyInject";
import { Types } from "../../../Infrastructure/Types";
import { BaseCustomLoadSaveReducer } from "../BaseCustomLoadSave/BaseCustomLoadSaveReducer";
import { DriverMonitoringEntityService } from "./DriverMonitoringEntityService";
import { DriverMonitoringReducer } from "./DriverMonitoringReducer";
import { DriverMonitoringPropsInitial, IDriverMonitoringProps } from "./IDriverMonitoringProps";

@injectable()
export class DriverMonitoringComponentReducer extends BaseCustomLoadSaveReducer {
    private _driverMonitoringEntityService: DriverMonitoringEntityService;
    private _driverMonitoringReducer: DriverMonitoringReducer;

    constructor() {
        super(DRIVER_MONITORING_MOUNTED, DRIVER_MONITORING_UNMOUNTED);

        this._driverMonitoringEntityService = VolmaContainer.get<DriverMonitoringEntityService>(Types.DriverMonitoringEntityService);
        this._driverMonitoringReducer = this._driverMonitoringEntityService.GetReducer();
    }

    public Reduce(state: IDriverMonitoringProps = DriverMonitoringPropsInitial, action: IActionPayloaded<any>): IDriverMonitoringProps {
        state = super.Reduce(state, action) as any;
        
        if (!state.IsMounted)
            return state;
            
        state = this._driverMonitoringReducer.Reduce(state, action);
        
        switch (action.type) {
            default:
                return state;

        }
    }


    public static Initialize() {
        const reducer = new DriverMonitoringComponentReducer();
        return (state: IDriverMonitoringProps, action: IActionPayloaded<IDriverMonitoringProps>) => reducer.Reduce(state, action);
    }
}