import { TenderDTO } from '../../../../Domain/DTO/TenderDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { TenderHelperProps } from '../IHelperProps';

export class Created extends StatusDefault<TenderDTO, TenderHelperProps>{
    public TitleCurrent : string = "tender:StatusCreatedCurrent";
    public TitleFinished: string = "tender:StatusCreatedFinished";
    public TitleFuture  : string = "tender:StatusCreatedFuture";

    public IsActive(dto: TenderDTO) {
        return true;
    }

    public IsCurrent(dto: TenderDTO) {
        return false;
    }

    public IsFinished(dto: TenderDTO) {
        return true;
    }

    public GetActions(dto: TenderDTO, currentUser: UserDTO, authService: AuthenticationService) {
        return [];
    }
}