import { LoadingPointDTO } from '../../../../Domain/DTO/LoadingPointDTO';
import { LocalityDTO } from '../../../../Domain/DTO/LocalityDTO';
import { RouteSegmentDTO } from '../../../../Domain/DTO/RouteSegmentDTO';

export interface IDeliveryStateTableHelperProps{
    RouteSegments: Array<RouteSegmentDTO>;
    LoadingPoints: Array<LoadingPointDTO>;
    Localities   : Array<LocalityDTO>;
}

export const InitialDeliveryStateTableHelperProps: IDeliveryStateTableHelperProps = <IDeliveryStateTableHelperProps>{
    LoadingPoints: [],
    Localities   : [],
    RouteSegments: [],
};

