import { AxiosResponse } from 'axios';
import { Component } from 'react';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { history } from '../../ConfigureStore';
import { HIDE_RATINGS_LIST, HIDE_UNFINISHED } from '../../Constants/AppConstants';
import { UpdatePasswordDTO } from '../../Domain/DTO/UpdatePasswordDTO';
import { UserDTO } from '../../Domain/DTO/UserDTO';
import { EEntityType } from '../../Domain/Enum/EEntityType';
import { EUserRole } from '../../Domain/Enum/EUserRole';
import { VolmaContainer } from '../../Infrastructure/InversifyInject';
import {
    DRIVER_MONITORING,
    PORTAL_CONFIGURATION,
    REPORTS,
    SEARCH,
    SETTINGS,
} from '../../Infrastructure/Router/RouterConstants';
import { RouterService } from '../../Infrastructure/Router/RouterService';
import { AuthServerInteraction } from '../../Infrastructure/ServerInteraction/AuthServerInteraction';
import { AuthenticationService } from '../../Infrastructure/Services/AuthService';
import { volmaBlock } from '../../Infrastructure/Services/BEM';
import { EntityService } from '../../Infrastructure/Services/EntityService';
import PropertyHelper from '../../Infrastructure/Services/PropertyHelper';
import { Types } from '../../Infrastructure/Types';
import { IGlobalState } from '../../Reducers/IGlobalState';
import i18next from '../i18n';
import { ApiActions } from '../Shared/ApiActions';
import { VolmaModalActions } from '../VolmaModal/VolmaModalActions';
import { HeaderActions } from './HeaderActions';
import { IHeaderProps } from './IHeaderProps';

class Header extends Component<IHeaderProps, {}>{

    private _headerActions: HeaderActions;
    private _modalActions : VolmaModalActions;
    private _routerService: RouterService;
    private _entityService: EntityService;
    private _authService  : AuthenticationService;
    private _authApi      : AuthServerInteraction;
    private _apiActions   : ApiActions;

    private _currentUser: UserDTO;
    private _username   : string;
    private _company    : string;
    private _role       : string;

    private _header         = volmaBlock('header');
    private _headerLogo     = volmaBlock('header-logo');
    private _nav            = volmaBlock('nav');
    private _navDrop        = volmaBlock('nav-drop');
    private _headerUser     = volmaBlock('header-user');
    private _searchHeader   = volmaBlock("search-header");


    private _handbooks              : Array<EEntityType>
    private _cargoTransporter       : Array<EEntityType>
    private _shipper                : Array<EEntityType>
    private _shipperCargoTransporter: Array<EEntityType>
    private _entities               : Array<EEntityType>
    private _tenders                : Array<EEntityType>
    private _deliveries             : Array<EEntityType>
    private _complaints             : Array<EEntityType>

    private _userType: EEntityType;

    private _handbookLinks                : Array<JSX.Element>;
    private _shipperCargoTransporterLinks: Array<JSX.Element>;

    constructor(props: IHeaderProps, context: any) {
        super(props, context);

        this._authService   = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._headerActions = VolmaContainer.get<HeaderActions>(Types.HeaderActions);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);
        this._entityService = VolmaContainer.get<EntityService>(Types.EntityService);
        this._modalActions  = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._authApi       = VolmaContainer.get<AuthServerInteraction>(Types.AuthServerInteraction);
        this._apiActions    = VolmaContainer.get<ApiActions>(Types.ApiActions);

        this.OnLogout           = this.OnLogout.bind(this);
        this.ChangePassword     = this.ChangePassword.bind(this);
        this.SearchKeyDown      = this.SearchKeyDown.bind(this);
        this.SearchValueChanged = this.SearchValueChanged.bind(this);
        this.SearchIcoClicked   = this.SearchIcoClicked.bind(this);
        this.ToggleNav          = this.ToggleNav.bind(this);

        this._handbooks = [
            EEntityType.Zone, 
            EEntityType.Payload, 
            EEntityType.Locality, 
            EEntityType.BodyType, 
            EEntityType.HandlingType, 
            EEntityType.LoadingPoint, 
            EEntityType.MultiFactory, 
            EEntityType.ComplaintTemplate, 
            EEntityType.RouteRate, 
            EEntityType.Consignor,
            EEntityType.Instruction,
        ];
        
        this._cargoTransporter = [
            EEntityType.Driver, 
            EEntityType.Vehicle, 
            EEntityType.CargoTransporterRouteRate, 
            EEntityType.CargoTransporterUser, 
            EEntityType.TopCurrentRating,
        ];        
        
        this._shipper = [EEntityType.ShipperUser];
        
        if (HIDE_RATINGS_LIST) {
            this._shipperCargoTransporter = [
                EEntityType.CargoTransporter, 
                EEntityType.CargoTransporterUser, 
                EEntityType.Driver, 
                EEntityType.Vehicle, 
                EEntityType.MultiCargoTransporter
            ];
        } else {
            this._shipperCargoTransporter = [
                EEntityType.CargoTransporter, 
                EEntityType.CargoTransporterUser, 
                EEntityType.Driver, 
                EEntityType.Vehicle, 
                EEntityType.TopCurrentRating, 
                EEntityType.MultiCargoTransporter
            ];
        }
        
        this._entities = [
            EEntityType.Delivery, 
            EEntityType.Tender, 
            EEntityType.ComplaintActive
        ];

        this._tenders = [
            EEntityType.Tender, 
            EEntityType.TenderFinished, 
            EEntityType.TenderActive,
            EEntityType.TenderBettingPerforming, 
            EEntityType.TenderFuture, 
            EEntityType.TenderInactive
        ];

        this._deliveries = [
            EEntityType.Delivery, 
            EEntityType.DeliveryFinished, 
            EEntityType.DeliveryFuture,
            EEntityType.DeliveryActive, 
            EEntityType.DeliveryPerforming, 
            EEntityType.DeliveryWithClaim,
            EEntityType.DeliveryManual, 
            EEntityType.DeliveryAuction, 
            EEntityType.DeliveryToAcceptConfirm
        ];

        this._complaints = [
            EEntityType.ComplaintDraft, 
            EEntityType.ComplaintActive, 
            EEntityType.ComplaintWithNewMessages,
            EEntityType.ComplaintClosed
        ];

        this._handbookLinks = this.GetNavDropLinks(this._handbooks);
        this._shipperCargoTransporterLinks = this.GetNavDropLinks(this._shipperCargoTransporter);

        this._currentUser = this._authService.GetCurrentUser();
        this._username = this._currentUser.Fio !== undefined && this._currentUser.Fio !== null ? this._currentUser.Fio : "";
        this._company = this._authService.IsCargoTransporter ? this._currentUser.CargoTransporterName : this._currentUser.ShipperName;
        this._company = this._company !== undefined && this._company !== null ? this._company : ""
        this._role = this._currentUser.Role !== undefined ?  " (" + EUserRole[this._currentUser.Role] + ")" : undefined;

        switch(this._currentUser.Role){
            case EUserRole.CargoTransporterLogist:
            case EUserRole.CargoTransporterLogistExtended:
                this._userType = EEntityType.CargoTransporterUser;
                break;
            default:
                this._userType = EEntityType.ShipperUser;
                break;
        }
        
        if (this._currentUser.IsIncludeInMultiCargoTransporter) {
            this._cargoTransporter.push(EEntityType.CargoTransporter)
        }
    }

    public componentDidMount(){
        this.props.dispatch(this._headerActions.SetCurrentUserInfo(this._currentUser));
    }

    render() {
        const upperRow = <ul className={(this._nav("list", {light: true})).toString()}>
            {this.GetSubMenuListItem(0, i18next.t("header:Handbooks"), this._handbooks, this._handbookLinks)}

            <li className={(this._nav("list-item")).toString()}>
                <NavLink className={this._nav("list-link").toString()} activeClassName={("active").toString()} to={this._routerService.GetPathname(SETTINGS)}>{i18next.t('header:Settings')}</NavLink>
            </li>

            {this._authService.IsAdministrator() && <>
                <li className={(this._nav("list-item")).toString()}>
                    <NavLink className={this._nav("list-link").toString()}  activeClassName={("active").toString()} to={this._routerService.GetPathname(PORTAL_CONFIGURATION)}>{i18next.t('header:PortalConfiguration')}</NavLink>
                </li>
                <li className={(this._nav("list-item")).toString()}>
                    <NavLink className={this._nav("list-link").toString()} activeClassName={("active").toString()} to={this._routerService.GetTablePathname(EEntityType.RequestLog)}>{i18next.t('header:RequestLog')}</NavLink>
                </li>
            </>}

            {!HIDE_UNFINISHED && <li className={(this._nav("list-item")).toString()}>
                <NavLink className={this._nav("list-link").toString()} activeClassName={("active").toString()} to={this._routerService.GetPathname(REPORTS)}>{i18next.t('header:Reports')}</NavLink>
            </li>}

            {this._authService.IsShipper() && <li className={(this._nav("list-item")).toString()}>
                <span className={this._searchHeader.toString()}>
                    <input className={(this._searchHeader("input")).toString()} value={this.props.SearchString} placeholder={i18next.t('header:TypeSearch')} onKeyDown={this.SearchKeyDown} onChange={this.SearchValueChanged}/>
                    <a onClick={this.SearchIcoClicked} className={(this._searchHeader("right-link")).toString()}>
                        <svg className={(this._searchHeader("right-link-ico").mix(["header-search-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#search"></use>
                        </svg>
                    </a>
                </span>
            </li>}            
        </ul>;

        const downerRow = <ul className={(this._nav("list", { reduced: this._authService.IsCargoTransporter() })).toString()}>
            <li className={(this._nav("list-item")).toString()}>
                <Link className={(this._nav("list-link").mix([this.IsActive(this._deliveries) ? "active" : ""])).toString()}  to={this._routerService.GetTablePathname(EEntityType.DeliveryPerforming)}>{i18next.t('header:Deliveries')}</Link>
            </li>

            <li className={(this._nav("list-item")).toString()}>
                <Link className={(this._nav("list-link").mix([this.IsActive(this._tenders) ? "active" : ""])).toString()} to={this._routerService.GetTablePathname(EEntityType.TenderActive)}>{i18next.t('header:Tenders')}</Link>
            </li>

            {!HIDE_UNFINISHED && <li className={(this._nav("list-item")).toString()}>
                <Link className={(this._nav("list-link").mix([this.IsActive([EEntityType.ComplaintActive]) ? "active" : ""])).toString()} to={this._routerService.GetTablePathname(EEntityType.ComplaintActive)}>{i18next.t('header:Complaints')}</Link>
            </li>}
            
            {this._authService.IsCargoTransporter() && <>
                {this.GetLinks(this._cargoTransporter)}
                {!this._currentUser.IsIncludeInMultiCargoTransporter && <li className={(this._nav("list-item")).toString()}>
                    <Link className={(this._nav("list-link")).toString()} to={this._routerService.GetCertainEntityPathname(EEntityType.CargoTransporter, this._currentUser.CargoTransporterId)}>{i18next.t("header:CurrentCargoTransporter")}</Link>
                </li>}
            </>}

            {this._authService.IsShipper() && <>
                {this.GetLinks(this._shipper)}
                {this.GetSubMenuListItem(1, i18next.t("header:CargoTransporter"), this._shipperCargoTransporter, this._shipperCargoTransporterLinks)}
            </>}

            {!this._authService.IsCargoTransporter() && <li className={(this._nav("list-item")).toString()}>
                <NavLink className={(this._nav("list-link")).toString()} activeClassName={("active").toString()} to={this._routerService.GetPathname(DRIVER_MONITORING)}>{i18next.t("header:DriverMonitoring")}</NavLink>
            </li>}
        </ul>;

        const headerUser = <div className={(this._headerUser("box")).toString()}>
            <a onClick={this.ToggleMenu.bind(this)} className={(this._headerUser("link", {open: this.props.IsUserMenuOpened })).toString()}>
                <div className={(this._headerUser("desc")).toString()}>
                    <span className={(this._headerUser("desc-name")).toString()}>{this.props.CurrentUser.Fio}</span>
                    <span className={(this._headerUser("desc-text")).toString()}>{this.GetUserCompany()}</span>
                    <span className={(this._headerUser("desc-text")).toString()}>{this.GetUserRole()}</span>
                </div>
                <div className={(this._headerUser("avatar")).toString()}>
                    <div className={(this._headerUser("avatar-user")).toString()}>
                        <svg className={(this._headerUser("avatar-user-ico").mix(["avatar-user-ico"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#user"></use>
                        </svg>
                        <svg className={(this._headerUser("avatar-user-ico-bg").mix(["avatar-user-ico-bg"])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#user"></use>
                        </svg>
                    </div>
                </div>
                <div className={(this._headerUser("arrow")).toString()}>
                    <svg className={(this._headerUser("arrow-ico").mix(["arrow-down-ico"])).toString()}>
                        <use xlinkHref="#angle-down"></use>
                    </svg>
                </div>
            </a>
            <div className={(this._headerUser("drop", {open: this.props.IsUserMenuOpened })).toString()}>
                <Link className={(this._headerUser("drop-link")).toString()} to={this._routerService.GetProfilePathname(this._userType)}>{i18next.t('header:UserProfile')}</Link>
                <a onClick={this.ChangePassword.bind(this)} className={(this._headerUser("drop-link")).toString()}>{i18next.t("header:ChangePassword")}</a>
                <a onClick={this.OnLogout.bind(this)} className={(this._headerUser("drop-link")).toString()}>{i18next.t("header:LogOut")}</a>
            </div>
        </div>;

        return (
            (
                <div className={(this._header("flex")).toString()}>
                    <div className={(this._headerLogo()).toString()}>
                        <span className={(this._headerLogo("link")).toString()}>
                            <div className={(this._headerLogo("img")).toString()}></div>
                        </span>
                    </div>
                    <nav className={(this._nav()).toString()}>
                        {upperRow}
                        {downerRow}
                    </nav>
                    <div className={(this._headerUser()).toString()}>
                        {headerUser}
                    </div>
                </div>
            )
        )
    }

    private GetUserRole(){
        return this.props.CurrentUser.Role !== undefined ?  EUserRole[this.props.CurrentUser.Role] : undefined;
    }

    private GetUserCompany(){
        const company = this._authService.IsCargoTransporter ? this.props.CurrentUser.CargoTransporterName : this.props.CurrentUser.ShipperName;
        return company !== undefined && company !== null ? company : "";
    }

    private OnLogout() {
        this.props.dispatch(this._headerActions.Logout(i18next.t('common:WaitingForTheServer')));
    }

    private ToggleMenu() {
        this.props.dispatch(this._headerActions.ToggleUserMenu());
    }

    private ToggleNav(index: number) {
        this.props.dispatch(this._headerActions.ToggleNav(index));
    }

    private ChangePassword() {
        const dto = new UpdatePasswordDTO();
        this.props.dispatch(this._modalActions.OpenLocalEntityModal(EEntityType.UpdatePassword, dto, undefined, (dto) => {
            const request = this._authApi.PasswordUpdate(dto)
            this._apiActions.DoApiRequest(
                this.props.dispatch,
                () => request,
                i18next.t("common:Loading"),
                (response: AxiosResponse, dispatch: any) => {

                });
            return request;
        }))
    }

    private SearchKeyDown(event: any){
        if (event.keyCode === 13){ // enter
            this.SearchIcoClicked();
        }
    }

    private SearchIcoClicked(){
        if (PropertyHelper.IsString(this.props.SearchString) && this.props.SearchString.length > 0)
            history.push(this._routerService.GetPathname(SEARCH) + "?search=" + encodeURIComponent(this.props.SearchString))
    }

    private SearchValueChanged(event: any){
        this.props.dispatch(this._headerActions.SearchUpdated(event.target.value))
    }  

    private GetNavDropLinks(array: Array<EEntityType>): Array<JSX.Element> {
        const res = [];
        for (const [index, itm] of array.entries()) {
            res.push(
                <li key={index} className={(this._navDrop("list-item")).toString()}>
                    <NavLink
                        className={this._navDrop("list-link").toString()}
                        activeClassName={("active").toString()}
                        to={this._entityService.GetEntityTableRoute(itm)}>
                        {i18next.t("header:" + EEntityType[itm])}
                    </NavLink>
                </li>)
        }
        return res;
    }

    private GetLinks(array: Array<EEntityType>): Array<JSX.Element> {
        const res = [];
        for (const [index, itm] of array.entries()) {
            res.push(
                <li key={index} className={(this._nav("list-item")).toString()}>
                    <Link
                        className={(this._nav("list-link").mix([this.IsActive([itm]) ? "active" : ""])).toString()}
                        to={this._entityService.GetEntityTableRoute(itm)}>
                        {i18next.t("header:" + EEntityType[itm])}
                    </Link>
                </li>)
        }
        return res;
    }

    private GetSubMenuListItem(openedNavIdx: number, title: string, entities: EEntityType[], entityLinks: JSX.Element[]) {
        return <li className={(this._nav("list-item", { submenu: true }).mix([this.props.OpenedNav === openedNavIdx ? "active" : ""])).toString()}>
            <a onClick={(() => this.ToggleNav(openedNavIdx))} className={(this._nav("list-link").mix([this.IsActive(entities) && this.props.OpenedNav !== openedNavIdx ? "active" : ""])).toString()}>
                {title}
                <svg className={(this._nav("list-link-ico")).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#chevron-down"></use>
                </svg>
            </a>
            <div className={(this._navDrop.mix([this.props.OpenedNav === openedNavIdx ? "active" : ""])).toString()}>
                <ul className={(this._navDrop("list")).toString()}>
                    {entityLinks}
                </ul>
            </div>
        </li>;
    }

    private IsActive(entities: Array<EEntityType>): boolean{
        if (this.props.CurrentEntity === undefined)
            return false;

        for(const ent of entities){
            if (ent === this.props.CurrentEntity)
                return true;
        }

        return false;
    }
}

const translated = withTranslation(['common'])(withRouter(Header));

function select(state: IGlobalState): IHeaderProps {
    return state.Header;
}

const connectedHeader = connect<IHeaderProps, {}, {}>(select)(translated);
export default connectedHeader;