import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import i18next from '../../../i18n';
import { DeliveryServerInteraction } from '../../../../Infrastructure/ServerInteraction/DeliveryServerInteraction';
import { ApiActions } from '../../../Shared/ApiActions';
import { AxiosResponse } from 'axios';

export class SynchronizeDeliveryAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;

    public Title: string = "delivery:SynchronizeDelivery";
    
    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean{
        if (this._authService.IsShipper()) {
            if (selectedIds === undefined || selectedIds.length !== 1)
                return false;
            let itm = data.find(x => x.Id === selectedIds[0]);
            if (itm === undefined)
                return false;
            return itm.FromErp === true && itm.State !== EDeliveryState.New && itm.State !== EDeliveryState.Revoked;
        }
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        let id = selectedIds[0];
        let identifiers = new Array<string>();
        for (let selectedId of selectedIds) {
            let itm = data.find(x => x.Id === selectedId);
            if (itm !== undefined)
                identifiers.push(itm.Identifier);
        }
        
        let text = i18next.t("delivery:SynchronizeSelectedDeliveries", { count: selectedIds.length, identifier: identifiers.join(', ') } as any)
        dispatch(this._modalActions.OpenConfirmationModal(text, () => {
            dispatch(this._deliveryTableActions.DeliveryActualize(id, i18next.t("common:Loading"), reloadData));
        }))

    }

    constructor(){
        super();
        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
    }
}