import { ComplaintCommentDTO } from '../../Domain/DTO/ComplaintCommentDTO';
import { ComplaintStateDTO } from '../../Domain/DTO/ComplaintStateDTO';
import { UpdateComplaintAcceptedPaymentDTO } from '../../Domain/DTO/UpdateComplaintAcceptedPaymentDTO';
import { UpdateComplaintAdditionalFilesDTO } from '../../Domain/DTO/UpdateComplaintAdditionalFilesDTO';
import { UpdateComplaintFileDTO } from '../../Domain/DTO/UpdateComplaintFileDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { AxiosPromise } from 'axios';


@injectable()
export class ComplaintServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public SetCommentReaded(commentIds: Array<string>): AxiosPromise {
        return this.Post(this._urlFabric.ComplaintSetCommentsReaded(ECQRSApi.Write), commentIds);
    }

    public AddComment(dto: ComplaintCommentDTO): AxiosPromise {
        return this.Post(this._urlFabric.ComplaintAddComment(ECQRSApi.Write), dto);
    }

    public ChangeState(complaintId: string, dto: ComplaintStateDTO){
        return this.Post(this._urlFabric.ComplaintChangeState(ECQRSApi.Write, complaintId), dto);
    }

    public UpdateAdditionalFiles(complaintId: string, dto: UpdateComplaintAdditionalFilesDTO){
        return this.Put(this._urlFabric.ComplaintUpdateAdditionalFiles(ECQRSApi.Write, complaintId), dto);
    }

    public UpdateFile(complaintId: string, dto: UpdateComplaintFileDTO){
        return this.Put(this._urlFabric.ComplaintUpdateFile(ECQRSApi.Write, complaintId), dto);
    }

    public UpdateAcceptedPayment(complaintId: string, dto: UpdateComplaintAcceptedPaymentDTO){
        return this.Put(this._urlFabric.ComplaintUpdateAcceptedPayment(ECQRSApi.Write, complaintId), dto);
    }

    public GetCargoTransportersByDelivery(deliveryId) {
        return this.Get(this._urlFabric.ComplaintGetCargotransportersByDelivery(ECQRSApi.Read, deliveryId));
    }
}