import { Performing } from './Statuses/Performing';
import { Created } from './Statuses/Created';
import { TenderWithdrawAction } from './Actions/TenderWithdrawAction';
import { TenderBetEntityService } from './TenderBetEntityService';
import { TenderChangelogEntityService } from './TenderChangelogEntityService';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import VolmaTable from '../../Table/VolmaTable';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { TenderReducer } from './TenderReducer';
import { TenderActions } from './TenderActions';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import { IVolmaTableColumn, IVolmaTableAction } from '../../Table/VolmaTable/IVolmaTableProps';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { InitialTenderHelperProps, TenderHelperProps } from './IHelperProps';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import i18next from '../../i18n';
import VolmaInput from '../../VolmaInput';
import VolmaNumber from '../../VolmaNumber';
import * as React from 'react';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { TenderDTO } from '../../../Domain/DTO/TenderDTO';
import { injectable } from 'inversify';
import { TenderTableDTO } from '../../../Domain/DTO/TenderTableDTO';
import VolmaCalendar from '../../VolmaCalendar/index';
import VolmaSelect from '../../VolmaSelect/index';
import { LoadingPointTableDTO } from '../../../Domain/DTO/LoadingPointTableDTO';
import { TenderDeliveryEntityService } from './TenderDeliveryEntityService';
import VolmaTime from '../../VolmaTime/index';
import { TenderTableActions } from './Table/TenderTableActions';
import { TenderTableReducer } from './Table/TenderTableReducer';
import { ITenderTableHelperProps } from './Table/ITableHelperProps';
import { TenderBetDTO } from '../../../Domain/DTO/TenderBetDTO';
import { TenderBetTableDTO } from '../../../Domain/DTO/TenderBetTableDTO';
import { ETenderState } from '../../../Domain/Enum/ETenderState';
import * as moment from 'moment';
import { TenderImportAction } from './Actions/TenderImportAction';
import { TenderPublishAction } from './Actions/TenderPublishAction';
import { IStatus } from '../../../Infrastructure/Status/IStatus';
import { Waiting } from './Statuses/Waiting';
import { Bidding } from './Statuses/Bidding';
import { Finished } from './Statuses/Finished';
import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { TenderBiddingStateChangedDTO } from '../../../Domain/DTO/TenderBiddingStateChangedDTO';
import { TenderImportExcelAction } from './Actions/TenderImportExcelAction';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { ERating } from '../../../Domain/Enum/ERating';
import { TenderRatingActions } from './Rating/TenderRatingActions';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { VolmaMultiPointSelect } from '../../VolmaMultiSelect/MultiPoint/VolmaMultiPointSelect';
import { ECargoTransporterType } from '../../../Domain/Enum/ECargoTransporterType';
import VolmaCheckBox from '../../VolmaCheckBox';
import { isDefined, isNullOrUndefined } from '../../../Infrastructure/Services/Utils';
import { TenderRejectedDeliveryEntityService } from './TenderRejectedDeliveryEntityService';

@injectable()
export class TenderEntityService extends BaseEntityService<TenderDTO, TenderHelperProps>{
    protected _dispatch: any;
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;

    protected _volmaTableService: VolmaTableService;
    protected _volmaModalAction: VolmaModalActions;

    protected _tenderReducer: TenderReducer;
    protected _tenderAction: TenderActions;
    protected _tenderTableAction: TenderTableActions;
    protected _tenderTableReducer: TenderTableReducer;
    protected _tenderRatingActions: TenderRatingActions;

    private _tenderChangelogEntityService: TenderChangelogEntityService;
    private _tenderBetEntityService: TenderBetEntityService;
    private _tenderDeliveryEntityService: TenderDeliveryEntityService;
    private _tenderRejectedDeliveryEntityService: TenderRejectedDeliveryEntityService;

    private _changelogColumns: Array<IVolmaTableColumn>;
    private _betColumns: Array<IVolmaTableColumn>;
    private _deliveryColumns: Array<IVolmaTableColumn>;
    private _rejectedDeliveriesColumns: Array<IVolmaTableColumn>;
    

    private _id: string;
    private _dto: TenderDTO;
    private _state: ETenderState;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._tenderReducer = VolmaContainer.get<TenderReducer>(Types.TenderReducer);
        this._tenderAction = VolmaContainer.get<TenderActions>(Types.TenderActions);
        this._tenderTableAction = VolmaContainer.get<TenderTableActions>(Types.TenderTableActions);
        this._tenderTableReducer = VolmaContainer.get<TenderTableReducer>(Types.TenderTableReducer);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tenderRatingActions = VolmaContainer.get<TenderRatingActions>(Types.TenderRatingActions);

        this._tenderChangelogEntityService = VolmaContainer.get<TenderChangelogEntityService>(Types.TenderChangelogEntityService);
        this._tenderBetEntityService = VolmaContainer.get<TenderBetEntityService>(Types.TenderBetEntityService);
        this._tenderDeliveryEntityService = VolmaContainer.get<TenderDeliveryEntityService>(Types.TenderDeliveryEntityService);
        this._tenderRejectedDeliveryEntityService = VolmaContainer.get<TenderRejectedDeliveryEntityService>(Types.TenderRejectedDeliveryEntityService);

        this._betColumns = this._tenderBetEntityService.GetColumnsList();
        this._changelogColumns = this._tenderChangelogEntityService.GetColumns();
        this._deliveryColumns = this._tenderDeliveryEntityService.GetColumns();
        this._rejectedDeliveriesColumns = this._tenderRejectedDeliveryEntityService.GetColumns();

        this.OnAfterDataLoaded = this.OnAfterDataLoaded.bind(this);

        this.TenderStarted = this.TenderStarted.bind(this);
        this.TenderEnded   = this.TenderEnded.bind(this);
        this.TenderBetAdded = this.TenderBetAdded.bind(this);
    }

    private TenderStarted(dto: TenderBiddingStateChangedDTO) {
        console.log('Tender started', JSON.stringify(dto));
        if (dto !== undefined && this._id !== undefined) {
            if (dto.TenderId === this._id) {
                console.log('current tender was started');
                this._tenderAction.ReloadEntity(this._dispatch, EEntityType.Tender, this._id, i18next.t("common:Loading"), this.OnAfterDataLoaded);
            }
        }
    }

    private TenderEnded(dto: TenderBiddingStateChangedDTO) {
        console.log('Tender ended', JSON.stringify(dto));
        if (dto !== undefined && this._id !== undefined) {
            if (dto.TenderId === this._id) {
                console.log('current tender was ended');
                this._tenderAction.ReloadEntity(this._dispatch, EEntityType.Tender, this._id, i18next.t("common:Loading"), this.OnAfterDataLoaded);
            }
        }
    }

    protected TenderBetAdded(dto: TenderBetDTO){
        if (this._dispatch !== undefined && this._dispatch !== null)
            this._dispatch(this._tenderTableAction.LoadUserBets());
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Tender,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Id)},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Identifier),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.LoadingPoint,
                    FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointId)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.Zone,
                    FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneId)
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.EndBiddingDate),
                    CellRenderer: VolmaTableCommonCellRenderers.CountDownCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
            ]
        );
    }

    public IsEditable(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): boolean {
        if (super.IsEditable(props))
            return this._id === undefined || this._state === ETenderState.Draft;
        return false;
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<TenderHelperProps>> {
        return [new TenderImportAction(), new TenderImportExcelAction(), new TenderWithdrawAction(), new TenderPublishAction()];
    }

    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        if(this._authService.IsShipper())
            return [EEntityType.TenderActive, EEntityType.TenderBettingPerforming, EEntityType.TenderFinished, EEntityType.TenderFuture, EEntityType.TenderInactive, EEntityType.TenderDraft];
        return [EEntityType.TenderActive, EEntityType.TenderBettingPerforming, EEntityType.TenderFinished, EEntityType.TenderFuture, EEntityType.TenderInactive];
    }


    public GetStatusList(): Array<IStatus<TenderDTO, TenderHelperProps>>{
        return [new Created(), new Waiting(), new Bidding(), new Performing(), new Finished()]
    }

    public GetEntityId(props: IBaseEntityProps<TenderDTO, {}>): any {
        return super.GetAlternateEntityId(
            props.match.params.id,
            PropertyHelper.GetPropertyName((x: TenderDTO) => x.Identifier),
            (x: TenderDTO, id: string) => x.Identifier.toLowerCase() === id);
    }

    public GetHeaderEditor(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element {
        const isEditable = this.IsEditable(props);
        const showName = (!isEditable && (props.DataDTO.Name === undefined || props.DataDTO.Name === null)) || isEditable;
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.Identifier) }
                        Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.Identifier)}
                        Label={i18next.t('tender:LabelIdentifier')}
                        Placeholder={i18next.t('tender:PlaceholderIdentifier')}
                        Value={props.DataDTO.Identifier}
                        Validator={this._inputValidator}
                        Required={true}
                        Readonly={!isEditable}
                        IsInHeader={true}
                        dispatch={props.dispatch} />
                </div>
                {showName && <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.Name)}
                        Label={i18next.t('tender:LabelName')}
                        Placeholder={i18next.t('tender:PlaceholderName')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>}
            </div>
        );
    }


    public GetEditorModal(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element {
        if (this.IsEditable(props))
            return this.GetTenderEditor(props);
        return this.GetReadonlyEditor(props);
    }

    public GetEditor(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element {
        return this.GetEditorModal(props);
    }

    public InitializeEntity(props: IBaseEntityProps<TenderDTO, TenderHelperProps>) {
        this._dispatch = props.dispatch;
        this._id = undefined;
        this._dto = undefined;
        this._signalRService.TenderStarted.On(this.TenderStarted);
        this._signalRService.TenderEnded.On(this.TenderEnded);
    }

    public DisposeEntity() {
        this._signalRService.TenderStarted.Off(this.TenderStarted);
        this._signalRService.TenderEnded.Off(this.TenderEnded);
    }


    public GetInitialDataHelper() {
        return InitialTenderHelperProps;
    }

    public GetReducer() {
        return this._tenderReducer;
    }

    public OnAfterDataLoaded(dto: TenderDTO): void {
        this._state = dto.State;
        this._id = dto.Id;
        this._dto = dto;

        this._dispatch(this._tenderAction.LoadBets(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadWinnersBets(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadChangelogs(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadDeliveries(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadRejectedDeliveries(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadZone(dto.ZoneId, i18next.t("common:Loading")));
        this._dispatch(this._tenderAction.LoadConsignor(dto.ConsignorId, i18next.t("common:Loading")));

        if (dto.LoadingPointId) {
            this._dispatch(this._tenderAction.LoadLoadingPoint(dto.LoadingPointId, i18next.t("common:Loading"))); 
        } 
        
        if (dto.MultiFactoryId) {
            this._dispatch(this._tenderAction.LoadMultiFactory(dto.MultiFactoryId, i18next.t("common:Loading"))); 
        }                

        this.LoadWinner(dto, i18next.t("common:Loading"));
        this.LoadForecastRating(i18next.t("common:Loading"));

        if (dto.Payloads !== undefined && dto.Payloads !== null && dto.Payloads.length > 0) {
            dto.Payloads.forEach(payload => {
                this.EnsurePayloadCached(payload);
            });
        }
    }

    private LoadWinner(dto: TenderDTO, waitingText: string): void {
        if (dto.WinnerType === ECargoTransporterType.None) {
            return;
        }

        const transporterEntity = dto.WinnerType === ECargoTransporterType.CargoTransporter ? EEntityType.CargoTransporter : EEntityType.MultiCargoTransporter;
        this._dispatch(this._tenderAction.LoadWinnerCargoTransporter(dto.WinnerId, waitingText, transporterEntity));
    }

    private LoadForecastRating(waitingText: string): void {
        const loadingPoints = [this.GetInitialDataHelper().LoadingPoint];
        this._dispatch(this._tenderRatingActions.LoadForecastRating(loadingPoints, waitingText));
    }

    private EnsurePayloadCached(id: string) {
        if (this.IsValueNotEmpty(id)) {
            if (this.GetInitialDataHelper().Payloads.find(x => x.Id === id && x.Name !== undefined) === undefined) {
                this.GetInitialDataHelper().Payloads.push({ Id: id });
                this._dispatch(this._tenderAction.LoadPayload(id, i18next.t("common:Loading")))
            }
        }
    }

    private GetTenderEditor(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        const isNewEntityCreating = this._id === undefined;
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("tender:MainInfoTitle"), i18next.t("tender:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.TenderTimePart(props), 0)}
                        {this.GetMainInfoNoTitleItem(true, false, this.DescriptionPart(props), 1)}
                    </div>
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("tender:TenderInfoTitle"), i18next.t("tender:TenderInfoTitleDescription"), this.BiddingTimePart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.MaxBetAndForbidBetPart(props))}
                    </div>
                </div>

                {!isNewEntityCreating && this.NonEmptyCollection(props.DataHelper.Bets) &&
                <div className={(this._rates.mix([props.ClosedCardParts[2] ? "parent-close" : ""])).toString()}>
                    {this.GetBetPart(props, 2)}
                </div>}

                {!isNewEntityCreating && <div className={(this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 3, i18next.t("tender:ChangelogTitle"), i18next.t("tender:ChangelogTitleDescription"), this.ChangelogsPart(props), undefined, props, this._dispatch)}
                </div>}

                {!isNewEntityCreating && <div className={(this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 4, i18next.t("tender:DeliveriesTitle"), i18next.t("tender:DeliveriesTitleDescription"), this.DeliveriesPart(props), undefined, props, this._dispatch)}
                </div>}
                {!isNewEntityCreating && <div className={(this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 4, i18next.t("tender:RejectedDeliveriesTitle"), i18next.t("tender:DeliveriesTitleDescription"), this.RejectedDeliveriesPart(props), undefined, props, this._dispatch)}
                </div>}
            </div>
        );
    }

    private GetReadonlyEditor(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        const components = new Array<JSX.Element>();

        let index = 0;
        let isDark = true;
        components.push(
            <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:MainInfoTitle"), i18next.t("tender:MainInfoTitleDescription"), this.ReadonlyMainInfoPart(props), undefined, props, this._dispatch)}
            </div>
        );

        index++;
        isDark = !isDark;
        // @ts-ignore: This expression is not callable
        if (this._timeService.GetLocalTime(this._dto.EndBiddingDate).isBefore(moment())) {
            components.push(
                <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:WinnerTitle"), i18next.t("tender:WinnerTitleDescription"), this.ReadonlyWinnerPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        if (this.NonEmptyCollection(props.DataHelper.Bets)){
            index++;
            isDark = !isDark;
            if (this._authService.IsShipper()) {
                components.push(
                    <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                    {this.GetBetPart(props, index)}
                    </div>
                );
            }
            else{
                components.push(
                    <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                        {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:BetsTitle"), i18next.t("tender:BetsTitleDescription"), this.GetBetsTable(props), undefined, props, this._dispatch)}
                    </div>
                );
            }
        }

        if (this.NonEmptyCollection(props.DataHelper.Changelogs)){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:ChangelogTitle"), i18next.t("tender:ChangelogTitleDescription"), this.ChangelogsPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        if (this.NonEmptyCollection(props.DataHelper.Deliveries)){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:DeliveriesTitle"), i18next.t("tender:DeliveriesTitleDescription"), this.DeliveriesPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        if (this.NonEmptyCollection(props.DataHelper.RejectedDeliveries)){
            index++;
            isDark = !isDark;
            components.push(
                <div key={index} className={(this._titleDropParent.mix([props.ClosedCardParts[index] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(isDark, !isDark, true, index, i18next.t("tender:RejectedDeliveriesTitle"), i18next.t("tender:DeliveriesTitleDescription"), this.RejectedDeliveriesPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }

        return (
            <div className={(this._mainInfo()).toString()}>
                {this.GetStatusPart(props)}

                {components}
            </div>
        );
    }

    private GetStatusPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element {
        const tableHelper: ITenderTableHelperProps = { AllUserBets: new Array<TenderBetTableDTO>(), CargoTransporterForecastRatings: new Array<RatingTableDTO>() };
        const userBet = props.DataHelper.BetsOriginal.find(x => x.TenderId === this._id);
        
        if (userBet !== undefined)
            tableHelper.AllUserBets.push({ Bet: userBet.Bet, TenderId: this._id } as TenderBetTableDTO)
        else
            tableHelper.AllUserBets.push({ TenderId: this._id } as TenderBetTableDTO)


        return this.GetStatusLine(
            this._id,
            this._dto,
            props.DataHelper,
            EEntityType.Tender,
            this._dispatch,
            this._tenderAction,
            props.OpenedStatusIndex,
            this._authService,
            () => {
                setTimeout(() => {
                    this._tenderAction.ReloadEntity(props.dispatch, EEntityType.Tender, this._id, i18next.t("common:Loading"), this.OnAfterDataLoaded)
                }, 0);
            },
            tableHelper)
    }

    private ReadonlyMainInfoPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:ConsignorId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Consignor, x => x.Name))}</dd>
                            </div>
                            {!props.DataDTO.MultiFactoryId && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:LoadingPointId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.LoadingPoint, x => x.Name))}</dd>
                            </div>}
                            {props.DataDTO.MultiFactoryId && <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:MultiFactoryId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.MultiFactory, x => x.Name))}</dd>
                            </div>}
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:ZoneId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.Zone, x => x.Name))}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:StartDate")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateNoCorrection(PropertyHelper.SafeGetValue(props.DataDTO, x => x.StartDate))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:EndDate")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateNoCorrection(PropertyHelper.SafeGetValue(props.DataDTO, x => x.EndDate))}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:StartBiddingDate")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.StartBiddingDate))}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:EndBiddingDate")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeDateTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.EndBiddingDate))}</dd>
                                </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                                {props.DataDTO.ForecastRatingFilter !== undefined && props.DataDTO.ForecastRatingFilter !== null && props.DataDTO.ForecastRatingFilter.toString() !== "" &&
                                    <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:ForecastRatingFilter")}:</dt>
                                        <dd className={(this._mainInfoSpecification("val")).toString()}>{this._enumService.GetOptionLocalized(() => ERating, PropertyHelper.SafeGetValue(props.DataDTO, x => x.ForecastRatingFilter))}</dd>
                                    </div>
                                }
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:MaxBet")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        <svg className="rub"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use></svg>
                                        <span>{this.GetSafeMoney(PropertyHelper.SafeGetValue(props.DataDTO, x => x.MaxBet))}</span></dd>
                                </div>
                            </dl>
                        </dl>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoDescription).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:DeliveriesCapacity")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.DeliveriesCapacity))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:Weight")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Weight), i18next.t("common:tonn", { count: PropertyHelper.SafeGetValue(props.DataDTO, x => x.Weight)}))}</dd>
                            </div>
                            {props.DataDTO.Payloads !== undefined && props.DataDTO.Payloads !== null && props.DataDTO.Payloads.length > 0 &&
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:PayloadId")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeValue(this.GetPayloads(props.DataDTO.Payloads, props))}</dd>
                                </div>
                            }
                            
                        </dl>
                        <dl>
                            {isDefined(props.DataDTO.ForbidBetFromCompetingWinner) &&
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("tender:ForbidBetFromCompetingWinnerView")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>
                                        {props.DataDTO.ForbidBetFromCompetingWinner 
                                            ? i18next.t('tender:ForbidBetFromCompetingWinnerActive') 
                                            : i18next.t('tender:ForbidBetFromCompetingWinnerInactive')}
                                    </dd>
                                </div>}
                            <p className={(this._mainInfoDescription("text")).toString()}>
                                <span className={(this._mainInfoDescription("title")).toString()}>{i18next.t("tender:Description")}:{" "}</span>
                                {this.GetSafeValue(PropertyHelper.SafeGetValue(props.DataDTO, x => x.Description))}
                            </p>
                        </dl>                        
                    </div>
                </div>
            </div>
        );
    }

    private GetPayloads(payloads:string[], props: IBaseEntityProps<TenderDTO, TenderHelperProps>): string
    {
        return payloads.map(x =>PropertyHelper.SafeGetValue2(props.DataHelper, y => y.Payloads.find(z => z.Id == x), y => y.Name)).join(', ');
    }

    private ReadonlyWinnerPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element {
        const bestBetDTO = this.GetWinnerBetDTO(props);

        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <div className={(this._volmaInput({blue: true})).toString()}>
                            <span className={(this._volmaInput("input", {text: true }).mix(["input"])).toString()}>
                                {i18next.t("tender:BestBet")}:
                                <svg className={(this._volmaInput("ico").mix(["rub"])).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#rub"></use>
                                </svg>
                                <span>{this.GetSafeMoney(bestBetDTO?.Bet)}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <div className={(this._volmaInput({blue: true })).toString()}>
                            <span className={(this._volmaInput("input", {text: true }).mix(["input"])).toString()}>
                                {i18next.t("tender:WinnerCargoTransporter")}:
                                <svg className={(this._volmaInput("ico").mix(["table-cont-building-ico"])).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#building"></use>
                                </svg>{this.GetSafeValue(PropertyHelper.SafeGetValue2(props.DataHelper, x => x.WinnerCargoTransporter, x => x.Name))}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetWinnerBetDTO(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): TenderBetTableDTO | undefined {
        if (isNullOrUndefined(props.DataHelper.WinnersBets) || props.DataHelper.WinnersBets.length === 0 || isNullOrUndefined(props.DataHelper.WinnerCargoTransporter)) {           
            return undefined;
        }
        
        const winnerId = props.DataHelper?.WinnerCargoTransporter?.Id;
        const winnerBetField =
            props.DataDTO.WinnerType === ECargoTransporterType.CargoTransporter
                ? PropertyHelper.GetPropertyName((x: TenderBetTableDTO) => x.CargoTransporterId)
                : PropertyHelper.GetPropertyName((x: TenderBetTableDTO) => x.MultiCargoTransporterId);

        return props.DataHelper.WinnersBets.find(x => x[winnerBetField] === winnerId);
    }

    private MainInfoPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.ConsignorId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.ConsignorId)}
                            Label={i18next.t('tender:ConsignorId')}
                            Placeholder={i18next.t('tender:SelectConsignor')}
                            Required={true}
                            Entity={EEntityType.Consignor}
                            Value={props.DataDTO.ConsignorId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaMultiPointSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.LoadingPointId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.LoadingPointId)}                                
                                EntityDtoField={[
                                    PropertyHelper.GetPropertyName((x: TenderDTO) => x.LoadingPointId), 
                                    PropertyHelper.GetPropertyName((x: TenderDTO) => x.MultiFactoryId),
                                ]}
                                OptionsFilter={[(x: LoadingPointTableDTO) => !x.IsIncludeInMultiFactory || x.Id === props.DataDTO.LoadingPointId, undefined]}
                                Value={props.DataDTO.LoadingPointId || props.DataDTO.MultiFactoryId}
                                dispatch={props.dispatch}
                                Required />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.ZoneId) as any) }
                            Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.ZoneId)}
                            Label={i18next.t('tender:ZoneId')}
                            Placeholder={i18next.t('tender:SelectZone')}
                            Required={true}
                            Entity={EEntityType.Zone}
                            Value={props.DataDTO.ZoneId}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.DeliveriesCapacity) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.DeliveriesCapacity)}
                            Label={i18next.t('tender:DeliveriesCapacity')}
                            Placeholder={i18next.t('tender:DeliveriesCapacity')}
                            Required={true}
                            Value={props.DataDTO.DeliveriesCapacity}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={100}
                            Unit={i18next.t('common:pcs')}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.Weight) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.Weight)}
                            Label={i18next.t('tender:Weight')}
                            Value={props.DataDTO.Weight}
                            Validator={this._numberValidator}
                            Required={true}
                            MinValue={() => 0}
                            Step={1}
                            Unit={i18next.t('common:tonn', { count: props.DataDTO.Weight })}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.Payloads) as any) }
                            Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.Payloads)}
                            Label={i18next.t('tender:PayloadId')}
                            Placeholder={i18next.t('tender:SelectPayload')}
                            Required={true}
                            AllowMultiselect = {true}
                            Entity={EEntityType.Payload}
                            Value={props.DataDTO.Payloads}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                {<div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfo("line", {fill: true})).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.State) as any) }
                            Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.State)}
                            Label={i18next.t('tender:State')}
                            Placeholder={i18next.t('tender:SelectState')}
                            EnumGetter={() => ETenderState}
                            OptionsFilter={(x: ListIntOptionDTO) => x.Id === ETenderState.Draft || x.Id === ETenderState.Published}
                            Required={true}
                            Value={props.DataDTO.State}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                }
                {<div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfo("line", {fill: true})).toString()}>
                        <VolmaSelect
                            {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: TenderDTO) => val.ForecastRatingFilter) as any) }
                            Name={PropertyHelper.GetPropertyName((x: TenderDTO) => x.ForecastRatingFilter)}
                            Label={i18next.t('tender:ForecastRatingFilter')}
                            Placeholder={i18next.t('tender:SelectForecastRatingFilter')}
                            EnumGetter={() => ERating}
                            OptionsFilter={(x: ListIntOptionDTO) => x.Id != ERating.Outsiders}
                            Required={false}
                            Value={props.DataDTO.ForecastRatingFilter}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                }
            </div>
        );
    }
    private TenderTimePart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCalendar
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.StartDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.StartDate)}
                            Label={i18next.t('tender:StartDate')}
                            Required={true}
                            Value={props.DataDTO.StartDate}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCalendar
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.EndDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.EndDate)}
                            Label={i18next.t('tender:EndDate')}
                            Required={true}
                            Value={props.DataDTO.EndDate}
                            dispatch={props.dispatch}
                            DefaultHours = {23}
                            DefaultMinutes = {59}
                            DefaultSeconds = {59} />
                    </div>
                </div>
            </div>
        );
    }
    private BiddingTimePart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCalendar
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.StartBiddingDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.StartBiddingDate)}
                            Label={i18next.t('tender:StartBiddingDate')}
                            Required={true}
                            Value={props.DataDTO.StartBiddingDate}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTime
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.StartBiddingDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.StartBiddingDate)}
                            Label={i18next.t('tender:StartBiddingTime')}
                            Required={true}
                            Value={props.DataDTO.StartBiddingDate}
                            dispatch={props.dispatch} />
                    </div>
                </div>

                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCalendar
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.EndBiddingDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.EndBiddingDate)}
                            Label={i18next.t('tender:EndBiddingDate')}
                            Required={true}
                            Value={props.DataDTO.EndBiddingDate}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTime
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.EndBiddingDate) as any) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.EndBiddingDate)}
                            Label={i18next.t('tender:EndBiddingTime')}
                            Required={true}
                            Value={props.DataDTO.EndBiddingDate}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
    private DescriptionPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.Description) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.Description)}
                            Type="textarea"
                            Label={i18next.t('tender:Description')}
                            Value={props.DataDTO.Description}
                            MaxLength={4000}
                            Validator={this._inputValidator}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private MaxBetAndForbidBetPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.MaxBet) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.MaxBet)}
                            Label={i18next.t('tender:MaxBet')}
                            Placeholder={i18next.t('tender:MaxBet')}
                            Required={true}
                            Value={props.DataDTO.MaxBet}
                            Validator={this._numberValidator}
                            MinValue={() => 0}
                            Step={100}
                            Unit={i18next.t('common:rub')}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaCheckBox
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: TenderDTO) => val.ForbidBetFromCompetingWinner) }
                            Name={PropertyHelper.GetPropertyName((val: TenderDTO) => val.ForbidBetFromCompetingWinner)}
                            Label={i18next.t('tender:ForbidBetFromCompetingWinner')}
                            Checked={props.DataDTO.ForbidBetFromCompetingWinner}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private GetBetsTable(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <VolmaTable
                {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: TenderHelperProps) => val.Bets) }
                Name={PropertyHelper.GetPropertyName((x: TenderHelperProps) => x.Bets)}
                Columns={this._betColumns}
                Data={props.DataHelper.Bets}
                DataOriginal={props.DataHelper.BetsOriginal}
                LoadDataFromServer={false}
                IsTableInsideEntity={true}
                Actions={[]}
                dispatch={props.dispatch} />

        );
    }

    private GetBetPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>, index: number): JSX.Element{
        let firstPlace: TenderBetTableDTO  = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[0] : undefined;
        let secondPlace: TenderBetTableDTO = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[1] : undefined;;
        let thirdPlace: TenderBetTableDTO  = props.DataHelper.WinnersBets !== undefined ? props.DataHelper.WinnersBets[2] : undefined;;
        return (
            <div>
                <div className={(this._ratesWinner({drop: true, info: true})).toString()}>
                    <div className={(this._container()).toString()}>
                        <div className={(this._titleDrop).toString()}>
                            <div className={(this._titleDrop("left")).toString()}>
                                <h2 className={(this._titleDrop("title").mix([this._mainInfo("title")])).toString()}>{i18next.t("tenderbet:BetsTitle")}</h2>
                                <span className={(this._titleDrop("text")).toString()}>{i18next.t("tenderbet:BetsTitleDescription")}</span>
                            </div>
                            <div className={(this._titleDrop("right")).toString()}>
                                <span onClick={(e: any) => {props.dispatch(this._tenderAction.ToggleInfoLine(index))}} className={(this._titleDrop("link").mix([props.ClosedCardParts[index] ? "active" : ""])).toString()}> </span>
                            </div>
                        </div>
                        <div className={(this._infoDrop).toString()}>
                            <div className={(this._ratesWinner("flex")).toString()}>
                                {firstPlace &&
                                <div className={(this._ratesWinner("item", {first: true})).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:FirstPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={(this._ratesWinner("company-title")).toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={(this._ratesWinner("company-name")).toString()}>{this.GetSafeString(firstPlace.CargoTransporterType === ECargoTransporterType.MultiCargoTransporter ? firstPlace.MultiCargoTransporterName : firstPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={(this._ratesWinner("stake-title")).toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={(this._ratesWinner("stake-val")).toString()}>{this.GetSafeMoney(firstPlace.Bet)}{" "}{i18next.t("common:rub")}</div>
                                    </div>
                                </div>}
                                {secondPlace &&
                                <div className={(this._ratesWinner("item", {second: true})).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:SecondPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={(this._ratesWinner("company-title")).toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={(this._ratesWinner("company-name")).toString()}>{this.GetSafeString(secondPlace.CargoTransporterType === ECargoTransporterType.MultiCargoTransporter ? secondPlace.MultiCargoTransporterName : secondPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={(this._ratesWinner("stake-title")).toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={(this._ratesWinner("stake-val")).toString()}>{this.GetSafeMoney(secondPlace.Bet)}{" "}{i18next.t("common:rub")}</div>
                                    </div>
                                </div>}
                                {thirdPlace &&
                                <div className={(this._ratesWinner("item", {third: true})).toString()}>
                                    <span className={(this._ratesWinner("prize")).toString()}>{i18next.t("tenderbet:ThirdPlaceTitle")}</span>
                                    <div className={(this._ratesWinner("company")).toString()}>
                                        <div className={(this._ratesWinner("company-title")).toString()}>{i18next.t("tenderbet:CompanyTitle")}</div>
                                        <div className={(this._ratesWinner("company-name")).toString()}>{this.GetSafeString(thirdPlace.CargoTransporterType === ECargoTransporterType.MultiCargoTransporter ? thirdPlace.MultiCargoTransporterName : thirdPlace.CargoTransporterName)}</div>
                                    </div>
                                    <div className={(this._ratesWinner("stake")).toString()}>
                                        <div className={(this._ratesWinner("stake-title")).toString()}>{i18next.t("tenderbet:StakeTitle")}</div>
                                        <div className={(this._ratesWinner("stake-val")).toString()}>{this.GetSafeMoney(thirdPlace.Bet)}{" "}{i18next.t("common:rub")}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this._infoDrop).toString()}>
                    {this.GetBetsTable(props)}
                </div>
            </div>
        );
    }

    private ChangelogsPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: TenderHelperProps) => val.Changelogs) }
                            Name={PropertyHelper.GetPropertyName((x: TenderHelperProps) => x.Changelogs)}
                            Columns={this._changelogColumns}
                            Data={props.DataHelper.Changelogs}
                            DataOriginal={props.DataHelper.ChangelogsOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private DeliveriesPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: TenderHelperProps) => val.Deliveries) }
                            Name={PropertyHelper.GetPropertyName((x: TenderHelperProps) => x.Deliveries)}
                            Columns={this._deliveryColumns}
                            Data={props.DataHelper.Deliveries}
                            DataOriginal={props.DataHelper.DeliveriesOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private RejectedDeliveriesPart(props: IBaseEntityProps<TenderDTO, TenderHelperProps>): JSX.Element{
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: TenderHelperProps) => val.RejectedDeliveries) }
                            Name={PropertyHelper.GetPropertyName((x: TenderHelperProps) => x.RejectedDeliveries)}
                            Columns={this._rejectedDeliveriesColumns}
                            Data={props.DataHelper.RejectedDeliveries}
                            DataOriginal={props.DataHelper.RejectedDeliveriesOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }
}
