import { TimeDTO } from '../../Domain/DTO/TimeDTO';
import { IEntityDTO } from '../../Domain/IEntityDTO';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';

export interface IVolmaTimeTimeDTOProps extends IBaseInputProps<IVolmaTimeTimeDTOProps> {
    Value: TimeDTO;
    CanBeNegative:boolean;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
}

export const VolmaTimeTimeDTOPropsInitial: IVolmaTimeTimeDTOProps = <IVolmaTimeTimeDTOProps>{
    ...VolmaBaseInputPropsInitial,
};