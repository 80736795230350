import { VolmaTableHeaderRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { DriverEntityService } from '../CargoTransporter/Driver/DriverEntityService';
import { VehicleEntityService } from '../CargoTransporter/Vehicle/VehicleEntityService';
import * as React from 'react';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { CargoTransporterTableDTO } from '../../../Domain/DTO/CargoTransporterTableDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { IVolmaTableColumn, IVolmaTableProps, IVolmaTableCellRendererParams, IVolmaTableAction } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaInput from '../../VolmaInput';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { CargoTransporterActions } from './CargoTransporterActions';
import { CargoTransporterReducer } from './CargoTransporterReducer';
import { CargoTransporterHelperProps, InitialCargoTransporterHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import VolmaTable from '../../Table/VolmaTable';
import i18next from '../../i18n';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { VehicleTableDTO } from '../../../Domain/DTO/VehicleTableDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { RouterService } from '../../../Infrastructure/Router/RouterService';
import { VolmaTableCommonCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { ERating } from '../../../Domain/Enum/ERating';
import { CargoTransporterImportAction } from './Actions/CargoTransporterImportAction';
import { DeleteItemsAction } from '../../Table/VolmaTable/Actions/DeleteItemsAction';
import { CargoTransporterImportExcelAction } from './Actions/CargoTransporterImportExcelAction';
import { CargoTransporterRatingImportAction } from './Actions/CargoTransporterRatingImportAction';
import { VolmaTableRatingCellRenderers } from '../../Table/VolmaTable/Renderers/VolmaTableRatingCellRenderers';
import { ContractTableDTO } from '../../../Domain/DTO/ContractTableDTO';
import { ContractEntityService } from '../CargoTransporter/Contract/ContractEntityService';
import { LocalDeleteContractAction } from '../CargoTransporter/Contract/TableActions/LocalDeleteContractAction';

@injectable()
export class CargoTransporterEntityService extends BaseEntityService<CargoTransporterDTO, CargoTransporterHelperProps>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;

    private _volmaTableService: VolmaTableService;
    private _driverEntityService: DriverEntityService;
    private _contractEntityService: ContractEntityService;
    private _vehicleEntityService: VehicleEntityService;
    private _routerService: RouterService;

    private _reducer: CargoTransporterReducer;
    private _action: CargoTransporterActions;
    private _volmaModalAction: VolmaModalActions;

    private _driverColumns: Array<IVolmaTableColumn>;
    private _vehicleColumns: Array<IVolmaTableColumn>;
    private _ratingColumns: Array<IVolmaTableColumn>;
    private _contractColumns: Array<IVolmaTableColumn>;

    private _id: string;
    private _dto: CargoTransporterDTO;
    private _dispatch: any;

    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._reducer = VolmaContainer.get<CargoTransporterReducer>(Types.CargoTransporterReducer);
        this._action = VolmaContainer.get<CargoTransporterActions>(Types.CargoTransporterActions);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);

        this._driverEntityService = VolmaContainer.get<DriverEntityService>(Types.DriverEntityService);
        this._contractEntityService = VolmaContainer.get<ContractEntityService>(Types.ContractEntityService);
        this._vehicleEntityService = VolmaContainer.get<VehicleEntityService>(Types.VehicleEntityService);
        this._routerService = VolmaContainer.get<RouterService>(Types.RouterService);

        this._driverColumns = this._driverEntityService.GetColumnsList();
        this._vehicleColumns = this._vehicleEntityService.GetColumnsList();
        this._ratingColumns = this.GetRatingColumnsList();
        this._contractColumns = this._contractEntityService.GetColumnsList();
    }


    public GetTableSubsidiaryEntities(): Array<EEntityType> {
        return [EEntityType.CargoTransporter]
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CargoTransporter,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Id)},
                { DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Code), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Name), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Email), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                { DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.Head), HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.MultiCargoTransporterName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.MultiCargoTransporter,
                    FilterKey: PropertyHelper.GetPropertyName((val: CargoTransporterTableDTO) => val.MultiCargoTransporterId)
                },
            ]
        );
    }

    public GetTableActions(entity: EEntityType): Array<IVolmaTableAction<CargoTransporterHelperProps>> {
        if (this.GetTableCanAddEntity(entity))
            return [new DeleteItemsAction(), new CargoTransporterImportAction(), new CargoTransporterImportExcelAction(), new CargoTransporterRatingImportAction()];
        return [new CargoTransporterImportAction(), new CargoTransporterImportExcelAction(), new CargoTransporterRatingImportAction()];
    }


    public GetHeaderEditor(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        let isEditable = this.IsEditable(props);
        return (
            <div>
                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Name) }
                        Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Name)}
                        Label={i18next.t('cargotransporter:Name')}
                        Placeholder={i18next.t('cargotransporter:NamePlaceholder')}
                        Value={props.DataDTO.Name}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>

                <div className={(this._createCard("cell")).toString()}>
                    <VolmaInput
                        {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Code) }
                        Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Code)}
                        Label={i18next.t('cargotransporter:Code')}
                        Placeholder={i18next.t('cargotransporter:CodePlaceholder')}
                        Value={props.DataDTO.Code}
                        Validator={this._inputValidator}
                        Required={true}
                        IsInHeader={true}
                        Readonly={!isEditable}
                        dispatch={props.dispatch} />
                </div>
            </div>
        );
    }

    public GetEditorModal(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        if (this.IsEditable(props)){
            if(this._id !== undefined)
                return this.GetExistingCargoTransporterEditor(props);
            return this.GetNewCargoTransporterEditor(props);
        }
        return this.GetReadonlyCargoTransporterEditor(props, this._id !== undefined && this._authService.IsShipper());
    }

    public GetEditor(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return this.GetEditorModal(props)
    }

    public InitializeEntity(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>) {
        this._dispatch = props.dispatch;
        this._id = undefined;
        this._dto = undefined;
    }

    public GetInitialDataHelper() {
        return InitialCargoTransporterHelperProps;
    }

    public GetReducer() {
        return this._reducer;
    }

    public OnAfterDataLoaded(dto: CargoTransporterDTO): void {
        this._id = dto.Id;
        this._dto = dto;
        this._dispatch(this._action.LoadVehicles(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._action.LoadDrivers(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._action.LoadCurrentRatings(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._action.LoadForecastRatings(dto.Id, i18next.t("common:Loading")));
        this._dispatch(this._action.LoadContracts(dto.Id, i18next.t("common:Loading")));
    }

    protected OnAddDriverRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Driver, undefined, props.match.params, dto =>
            this._dispatch(this._action.DriverAdded(dto))
        ));
    }

    protected OnEditDriverRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, id: string) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Driver, id, props.match.params, dto =>
            this._dispatch(this._action.DriverEdited(dto))
        ));
    }

    protected OnAddVehicleRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Vehicle, undefined, props.match.params, dto =>
            this._dispatch(this._action.VehicleAdded(dto))
        ));
    }

    protected OnEditVehicleRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, id: string) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Vehicle, id, props.match.params, dto =>
            this._dispatch(this._action.VehicleEdited(dto))
        ));
    }

    protected OnAddContractRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>) {
        if(!this._authService.IsShipper())
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Contract, undefined, props.match.params, dto =>
            this._dispatch(this._action.ContractAdded(dto))
        ));
    }

    protected OnEditContractRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, id: string) {
        if(!this._authService.IsShipper())
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.Contract, id, props.match.params, dto =>
            this._dispatch(this._action.ContractEdited(dto))
        ));
    }

    protected OnAddRatingRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.CurrentRating, undefined, props.match.params, dto =>
            this._dispatch(this._action.RatingAdded(dto))
        ));
    }

    protected OnEditRatingRequired(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, id: string) {
        if (!this.IsEditable(props))
            return;

        this._dispatch(this._volmaModalAction.OpenServerEntityModal(EEntityType.CurrentRating, id, props.match.params.params, dto =>
            this._dispatch(this._action.RatingEdited(dto))
        ));
    }

    private GetExistingCargoTransporterEditor(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporter:MainInfoTitle"), i18next.t("cargotransporter:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.ContactPersonPart(props), 0)}
                        {this.GetMainInfoNoTitleItem(true, false, this.ContactDataPart(props), 1)}
                        {this.GetMainInfoNoTitleItem(false, true, this.AddressPart(props), 2)}
                    </div>
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsAdministrator() && this.GetMainInfoItem(true, false, true, 1, i18next.t("cargotransporter:DriversTitle"), i18next.t("cargotransporter:DriversTitleDescription"), this.DriversPart(props), [this.AddDriverButton(props, 0)], props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsAdministrator() && this.GetMainInfoItem(false, true, true, 2, i18next.t("cargotransporter:VehiclesTitle"), i18next.t("cargotransporter:VehiclesTitleDescription"), this.VehiclesPart(props), [this.AddVehicleButton(props, 0)], props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsAdministrator() && this.GetMainInfoItem(true, false, true, 3, this.GetRatingTitle(props.DataDTO), i18next.t("cargotransporter:RatingTitleDescription"), this.RatingPart(props), [this.AddRatingButton(props, 0)], props, this._dispatch)}
                </div>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsAdministrator() && this.GetMainInfoItem(false, true, true, 4, i18next.t("cargotransporter:ContractsTitle"), i18next.t("cargotransporter:ContractsTitleDescription"), this.ContractsPart(props), [this.AddContractButton(props, 0)], props, this._dispatch)}
                </div>
            </div>);
    }

    private GetNewCargoTransporterEditor(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporter:MainInfoTitle"), i18next.t("cargotransporter:MainInfoTitleDescription"), this.MainInfoPart(props), undefined, props, this._dispatch)}

                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.ContactPersonPart(props), 0)}
                        {this.GetMainInfoNoTitleItem(true, false, this.ContactDataPart(props), 1)}
                        {this.GetMainInfoNoTitleItem(false, true, this.AddressPart(props), 2)}
                    </div>
                </div>
            </div>);
    }

    private GetReadonlyCargoTransporterEditor(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, isContractsEditable: boolean): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("cargotransporter:MainInfoTitle"), i18next.t("cargotransporter:MainInfoTitleDescription"), this.ReadonlyMainInfoPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsShipper() && this.GetMainInfoItem(false, true, true, 1, i18next.t("cargotransporter:DriversTitle"), i18next.t("cargotransporter:DriversTitleDescription"), this.DriversPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsShipper() && this.GetMainInfoItem(true, false, true, 2, i18next.t("cargotransporter:VehiclesTitle"), i18next.t("cargotransporter:VehiclesTitleDescription"), this.VehiclesPart(props), undefined, props, this._dispatch)}
                </div>

                <div className={this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""]).toString()}>
                    {this._authService.IsShipper() && this.GetMainInfoItem(false, true, true, 3, this.GetRatingTitle(props.DataDTO), i18next.t("cargotransporter:RatingTitleDescription"), this.RatingPart(props), undefined, props, this._dispatch)}
                </div>
                {this.GetContractsPart(props, isContractsEditable)}
            </div>
        );
    }

    private GetRatingTitle(dataDTO: CargoTransporterDTO): string {
        return dataDTO.MultiCargoTransporterId
        ? i18next.t("cargotransporter:MultiRatingTitle", { multiCargoTransporterName: dataDTO.MultiCargoTransporterName })
        : i18next.t("cargotransporter:RatingTitle");
    }

    private GetContractsPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, isContractsEditable: boolean): JSX.Element {
        if(isContractsEditable)        {
            return (
                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 4, i18next.t("cargotransporter:ContractsTitle"), i18next.t("cargotransporter:ContractsTitleDescription"), this.ContractsPart(props), [this.AddContractButton(props, 0)], props, this._dispatch)}
                </div>
            );
        } else {
            return (
                <div className={this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""]).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("cargotransporter:ContractsTitle"), i18next.t("cargotransporter:ContractsTitleDescription"), this.ContractsPart(props), undefined, props, this._dispatch)}
                </div>
            );
        }
    }

    private ReadonlyMainInfoPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:Head")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Head)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:ContactPerson")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.ContactPerson)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:ContactPersonMobilePhoneNumber")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.ContactPersonMobilePhoneNumber)}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:PhoneNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.PhoneNumber)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:FaxNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.FaxNumber)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:Email")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Email)}</dd>
                                </div>
                            </dl>
                        </dl>
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoDescription).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:Tin")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Tin)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:TaxRegistrationReasonCode")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.TaxRegistrationReasonCode)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:Psrn")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.Psrn)}</dd>
                            </div>
                        </dl>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:LegalAddress")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.LegalAddress)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("cargotransporter:PostalAddress")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.PostalAddress)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        );
    }

    private MainInfoPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Tin) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Tin)}
                            Label={i18next.t('cargotransporter:Tin')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.Tin}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.TaxRegistrationReasonCode) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.TaxRegistrationReasonCode)}
                            Label={i18next.t('cargotransporter:TaxRegistrationReasonCode')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.TaxRegistrationReasonCode}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Psrn) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Psrn)}
                            Label={i18next.t('cargotransporter:Psrn')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.Psrn}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
       );
    }

    private ContactDataPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.PhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.PhoneNumber)}
                            Label={i18next.t('cargotransporter:PhoneNumber')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.PhoneNumber}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.FaxNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.FaxNumber)}
                            Label={i18next.t('cargotransporter:FaxNumber')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.FaxNumber}
                            dispatch={props.dispatch} />
                    </div>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Email) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Email)}
                            Label={i18next.t('cargotransporter:Email')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.Email}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ContactPersonPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.ContactPerson) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.ContactPerson)}
                            Label={i18next.t('cargotransporter:ContactPerson')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.ContactPerson}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.ContactPersonMobilePhoneNumber) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.ContactPersonMobilePhoneNumber)}
                            Label={i18next.t('cargotransporter:ContactPersonMobilePhoneNumber')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.ContactPersonMobilePhoneNumber}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.Head) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.Head)}
                            Label={i18next.t('cargotransporter:Head')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={255}
                            Value={props.DataDTO.Head}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private AddressPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.LegalAddress) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.LegalAddress)}
                            Type="textarea"
                            Label={i18next.t('cargotransporter:LegalAddress')}
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={4000}
                            Value={props.DataDTO.LegalAddress}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaInput
                            {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: CargoTransporterDTO) => val.PostalAddress) as any) }
                            Name={PropertyHelper.GetPropertyName((val: CargoTransporterDTO) => val.PostalAddress)}
                            Label={i18next.t('cargotransporter:PostalAddress')}
                            Type="textarea"
                            Required={true}
                            Validator={this._inputValidator}
                            MaxLength={4000}
                            Value={props.DataDTO.PostalAddress}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private DriversPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        let rowEdit = () => null;
        if (this.IsEditable(props))
            rowEdit = ((rowData: DriverTableDTO) => this.OnEditDriverRequired(props, rowData.Id)).bind(this)
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {..."Drivers"}
                            Name={"Drivers"}
                            Columns={this._driverColumns}
                            Data={props.DataHelper.Drivers}
                            DataOriginal={props.DataHelper.DriversOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={rowEdit}
                            Actions={[]}
                            dispatch={props.dispatch} />
                    </div>
                </div>
            </div>
        );
    }

    private ContractsPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        let rowEdit = () => null;
        let actions = [];
        if(this._authService.IsShipper())
        {
            rowEdit = ((rowData: ContractTableDTO) => this.OnEditContractRequired(props, rowData.Id)).bind(this);
            actions = [new LocalDeleteContractAction<any>((ids: Array<string>) => { this.DeleteContracts(ids) })];
        }
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: CargoTransporterHelperProps) => val.Contracts) }
                            Name={"Contracts"}
                            Columns={this._contractColumns}
                            Data={props.DataHelper.Contracts}
                            DataOriginal={props.DataHelper.ContractsOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={rowEdit}
                            Actions={actions}
                            dispatch={props.dispatch}
                            Entity = {EEntityType.Contract}
                            />
                    </div>
                </div>
            </div>
        );
    }

    private VehiclesPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        let rowEdit = () => null;
        if (this.IsEditable(props))
            rowEdit = ((rowData: VehicleTableDTO) => this.OnEditVehicleRequired(props, rowData.Id)).bind(this)
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {..."Vehicles" }
                            Name={"Vehicles"}
                            Columns={this._vehicleColumns}
                            Data={props.DataHelper.Vehicles}
                            DataOriginal={props.DataHelper.VehiclesOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={rowEdit}
                            Actions={[]}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    private RatingPart(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>): JSX.Element {
        let rowEdit = () => null;
        if (this.IsEditable(props))
            rowEdit = ((rowData: RatingTableDTO) => this.OnEditRatingRequired(props, rowData.Id)).bind(this)
        return(
            <div className={(this._mainInfo("row")).toString()}>
                <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                    <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaTable
                            {..."CurrentRating" }
                            Name={"CurrentRating"}
                            Columns={this._ratingColumns}
                            Data={props.DataHelper.CurrentRating}
                            DataOriginal={props.DataHelper.CurrentRatingOriginal}
                            DataHelper={{ForecastRating: props.DataHelper.ForecastRating}}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            OnRowEditRequired={rowEdit}
                            Actions={[]}
                            dispatch={props.dispatch}/>
                    </div>
                </div>
            </div>
        );
    }

    private DeleteContracts(ids: string[]){
        this._dispatch(this._action.ContractsRemovedFromCargoTransporter(ids));
    }

    private AddDriverButton(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, key: number){
        return (
            <a key={key} onClick={(() => this.OnAddDriverRequired(props)).bind(this)}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("cargotransporter:AddDriver")}</span>
            </a>
        );
    }

    private AddVehicleButton(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, key: number){
        return (
            <a key={key} onClick={(() => this.OnAddVehicleRequired(props)).bind(this)}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("cargotransporter:AddVehicle")}</span>
            </a>
        );
    }

    private AddContractButton(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, key: number){
        return (
            <a key={key} onClick={(() => this.OnAddContractRequired(props)).bind(this)}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("cargotransporter:AddContract")}</span>
            </a>
        );
    }

    private AddRatingButton(props: IBaseEntityProps<CargoTransporterDTO, CargoTransporterHelperProps>, key: number){
        return (
            <a key={key} onClick={(() => this.OnAddRatingRequired(props)).bind(this)}
            className={this._tableButton("add").mix([this._btn(), this._btnTable({ bg: true, green: true })]).toString()}>
                <svg className={this._bgIco.mix([this._bgPlusIco()]).toString()}>
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                </svg>
                <span className={(this._btn("text")).toString()}>{i18next.t("cargotransporter:AddRating")}</span>
            </a>
        );
    }

    public GetRatingColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.CurrentRating,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.Id) },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointName),
                    CellRenderer: (props: IVolmaTableProps<CargoTransporterHelperProps>, params: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.RatingMultiPointNameCellRenderer(props, params,
                        this._routerService.GetCertainEntityPathname(EEntityType.CargoTransporter, this._id)),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    FilterKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.LoadingPointId),
                    Entity: EEntityType.LoadingPoint
                },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: RatingTableDTO) => val.RatingValue),
                    CellRenderer: (props: IVolmaTableProps<CargoTransporterHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableCommonCellRenderers.EnumCellRenderer(() => ERating, this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                {
                    DataKey: "Forecast",
                    CellRenderer: (props: IVolmaTableProps<CargoTransporterHelperProps>, cellRendererParams: IVolmaTableCellRendererParams) =>
                        VolmaTableRatingCellRenderers.ForecastRatingRenderer(this._enumService, props, cellRendererParams),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
            ]
        );
    }

}