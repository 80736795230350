import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { AxiosPromise } from 'axios';
import { injectable } from 'inversify';



@injectable()
export class CargoTransporterServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public Import(id: string): AxiosPromise {
        return this.Post(this._urlFabric.CargoTransporterImport(ECQRSApi.Write, id));
    }

    public ImportExcel(id: string): AxiosPromise {
        return this.Post(this._urlFabric.CargoTransporterImportExcel(ECQRSApi.Write, id));
    }

    public ImportRating(id: string): AxiosPromise {
        return this.Post(this._urlFabric.CargoTransporterImportRating(ECQRSApi.Write, id));
    }

}