import { IVolmaTableColumn } from '../../../Table/VolmaTable/IVolmaTableProps';
import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TenderTableDTO } from '../../../../Domain/DTO/TenderTableDTO';
import { VolmaTableCommonCellRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers';
import { TenderEntityService } from '../TenderEntityService';
import { injectable } from 'inversify';
import { VolmaTableHeaderRenderers } from '../../../Table/VolmaTable/Renderers/VolmaTableHeaderRenderers';
import { VolmaTableCommonColumnDefinition } from '../../../Table/VolmaTableCommonColumnDefinition';

@injectable()
export class TenderFutureEntityService extends TenderEntityService{

    public GetBaseEntity(): EEntityType {
        return EEntityType.Tender;
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.Tender,
            [
                { DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Id)},
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.Identifier),
                    HeaderRenderer: VolmaTableHeaderRenderers.TextHeaderRenderer
                },
                VolmaTableCommonColumnDefinition.GetMultiPointColumn({ 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.LoadingPointName),
                }),
                {
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneName),
                    HeaderRenderer: VolmaTableHeaderRenderers.SelectHeaderRenderer,
                    Entity: EEntityType.Zone,
                    FilterKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.ZoneId)
                },
                { 
                    DataKey: PropertyHelper.GetPropertyName((val: TenderTableDTO) => val.StartBiddingDate), 
                    CellRenderer: VolmaTableCommonCellRenderers.DateCellRenderer,
                    HeaderRenderer: VolmaTableHeaderRenderers.DateHeaderRenderer
                },
            ]
        );
    }
}