import { injectable } from "inversify";
import ValidationResult from "./ValidationResult";
import {BaseValidator} from "./BaseValidator";
import i18n from "../../Components/i18n";
import { IVolmaInputProps } from '../../Components/VolmaInput/IVolmaInputProps';


@injectable()
export class NonEmptyTextValidator extends BaseValidator<IVolmaInputProps> {
    public Validate(entity: IVolmaInputProps): ValidationResult {
        if (entity === undefined || entity === null)
            throw new Error("Null reference exception");
        if (!entity.Value)
            return ValidationResult.Fail(i18n.t("validate:ValueMustBeNonEmpty"));
        return ValidationResult.Success();
    }
}