import { LoginDTO } from '../../Domain/DTO/LoginDTO';
import { IVolmaFormProps, VolmaFormPropsInitial } from "../VolmaForm/IVolmaFormProps";
import {ICommonProps} from "../Shared/ICommonProps";
import { WithTranslationProps } from 'react-i18next/*';
import { IRouteProps } from '../../Infrastructure/Router/RouteProps';

export interface ILoginProps extends IVolmaFormProps, WithTranslationProps, IRouteProps {
    DataDTO?: LoginDTO;
}

export const LoginPropsInitial: ILoginProps = {
    ...VolmaFormPropsInitial,
    DataDTO: <LoginDTO>{Password: "", Username: ""}};