import { IBaseInputProps, VolmaBaseInputPropsInitial } from '../Shared/IBaseInputProps';
import { IBaseEntityProps } from '../Entity/BaseEntity/IBaseEntityProps';
import { IEntityDTO } from '../../Domain/IEntityDTO';

export interface IVolmaCheckBoxProps extends IBaseInputProps<IVolmaCheckBoxProps> {
    Checked: boolean;
    CustomDataUpdate?: (state: IBaseEntityProps<IEntityDTO, any>, dtoFieldName: string, fieldValue: any) => void;
    OnLostFocus?: (cancelled: boolean) => void;
    Autofocus?: boolean;
    Id: string;
}

export const VolmaCheckBoxPropsInitial: IVolmaCheckBoxProps = <IVolmaCheckBoxProps>{
    ...VolmaBaseInputPropsInitial,
    Checked: false,
    Id: "volma-checkbox",
    IsValid: true,
    IsTouched: false,
    IsSubmitted: false,
    ErrorMessage: "",
    Reducer: undefined,
    SkipRegistration: false,

    Readonly: false,
    Required: false,
    Disabled: false,
};