import {
    SEARCH_MOUNTED,
    SEARCH_RESULTS_LOADED,
    SEARCH_TEXT_UPDATED,
    SEARCH_UNMOUNTED
} from '../../../Constants/AppConstants';
import { TableDTO } from '../../../Domain/DTO/TableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { SearchServerInteraction } from '../../../Infrastructure/ServerInteraction/SearchServerInteraction';
import { Types } from '../../../Infrastructure/Types';
import { ApiActions } from '../../Shared/ApiActions';
import { ComponentActions } from '../../Shared/ComponentActions';
import { ISearchTableDataPayload } from './Payloads';
import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { DataTableDTO } from '../../../Domain/DTO/DataTableDTO';

@injectable()
export class SearchComponentActions extends ComponentActions {
    private _apiActions: ApiActions;
    private _searchApi: SearchServerInteraction;

    constructor() {
        super();
        super.Initialize(SEARCH_MOUNTED, SEARCH_UNMOUNTED);

        this._apiActions = VolmaContainer.get<ApiActions>(Types.ApiActions);
        this._searchApi = VolmaContainer.get<SearchServerInteraction>(Types.SearchServerInteraction);
    }

    public GetSearchResult(searchText: string, waitingText: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._searchApi.Search(searchText),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                    let data = JSON.parse(response.data);
                    if (PropertyHelper.IsObject(data) && PropertyHelper.IsArray(data.Items)) {
                        dispatch(this.SearchResultsLoaded(data));
                    }
                });
        }
    }

    public RebuildIndex(waitingText: string) {
        return (dispatch: any) => {
            this._apiActions.DoApiRequest(
                dispatch,
                () => this._searchApi.RebuildIndex(),
                waitingText,
                (response: AxiosResponse, dispatch: any) => {
                });
        }
    }

    public SearchTextUpdated(val: string): IActionPayloaded<string>{
        return {type: SEARCH_TEXT_UPDATED, Payload: val};
    }

    private SearchResultsLoaded(data: DataTableDTO): IActionPayloaded<ISearchTableDataPayload> {
        return {
            type: SEARCH_RESULTS_LOADED, Payload: {
                Items: data.Items,
                PageNumber: data.PageNumber,
                TotalPages: data.TotalPages,
                ItemsPerPage: data.ItemsPerPage,
                TotalItems: data.TotalItems
            }
        };
    }
}