import * as React from 'react';
import { Component, MouseEvent } from 'react';
import { Grid } from 'react-virtualized';
import { history } from '../../../ConfigureStore';
import { VOLMA_TABLE_CURRENT_SCROLL_POS } from '../../../Constants/AppConstants';
import { DeliveryDTO } from '../../../Domain/DTO/DeliveryDTO';
import { EntityAddedDTO } from '../../../Domain/DTO/EntityAddedDTO';
import { EntityUpdatedDTO } from '../../../Domain/DTO/EntityUpdatedDTO';
import { FilterDTO } from '../../../Domain/DTO/FilterDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ESortDir } from '../../../Domain/Enum/ESortDir';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { TableSignalRServerInteraction } from '../../../Infrastructure/ServerInteraction/TableSignalRServerInteraction';
import { UrlFabric } from '../../../Infrastructure/ServerInteraction/UrlFabric';
import { volmaBlock } from '../../../Infrastructure/Services/BEM';
import { DeliveryService } from '../../../Infrastructure/Services/DeliveryService';
import { EntityService } from '../../../Infrastructure/Services/EntityService';
import { LocalStorageService } from '../../../Infrastructure/Services/LocalStorageService';
import { SignalRService } from '../../../Infrastructure/Services/SignalRService';
import { Types } from '../../../Infrastructure/Types';
import { ScrollBarSize } from '../../../Infrastructure/Utils/ScrollBarSize';
import i18next from '../../i18n';
import VolmaAutoSizer from '../../VolmaAutoSizer/VolmaAutoSizer';
import { IVolmaTableCellRendererParams, IVolmaTableHeaderRenderProps, IVolmaTableProps } from './IVolmaTableProps';
import { VolmaTableHeaderRenderers } from './Renderers/VolmaTableHeaderRenderers';
import { VolmaTableActions } from './VolmaTableActions';
import { VolmaTableReducer } from './VolmaTableReducer';

export default class ExperimentalVolmaTable extends Component<IVolmaTableProps<{}>, { UpdateInProgress: boolean}>{
    private readonly _longTouchThreshold  = 750;
    private readonly _itemsPerPageDefault = 250;
    private readonly _reloadDebounce      = 20000;
    private _reloadCount = 0;

    private _itemsPerPage = 250;

    private _volmaTableActions: VolmaTableActions;
    private _entityService    : EntityService;
    private _volmaTableReducer: VolmaTableReducer<IVolmaTableProps<{}>>;
    protected _apiSignalR     : TableSignalRServerInteraction;

    private _grid         : any;
    private _entity       : EEntityType;
    private _baseEntity   : EEntityType;
    private _followUpdates: boolean;
    private _gridWidth    : number = 0;

    private _longTapDetectorTimeout: any;
    private _isDesktopView         : boolean;
    private _dataClearRequested    : boolean;

    private _localStorageService: LocalStorageService;
    private _signalRService     : SignalRService;
    private _deliveryService    : DeliveryService;

    private _debouncedReloadTimeout: any;
    private _reloadPostponed       : boolean;

    private readonly _table              = volmaBlock('table');
    private readonly _tableBox           = volmaBlock('table-box');
    private readonly _tableActions       = volmaBlock('table-actions');
    private readonly _tableNav           = volmaBlock('table-nav');
    private readonly _tableNavPagination = volmaBlock('table-nav-pagination');

    private readonly _defaultlistHeight = 500;
    private readonly _rowHeight         = 93;// 92 cell + 1 bottom border
    private readonly _mobileRowHeight   = 128;
    private readonly _mobileColumnWidth = 200;
    private readonly _mobileHeadWidth   = 250;
    private readonly _mobileBreakWidth  = 1000;
    private readonly _headerHeight      = 128;
    private readonly _headerHeight1600  = 107;
    private readonly _actionsHeight     = 70;
    private readonly _actionsHeight1600 = 62;


    constructor(props: IVolmaTableProps<any>, context: any) {
        super(props, context);

        this._volmaTableActions = VolmaContainer.get<VolmaTableActions>(Types.VolmaTableActions);
        this._entityService     = VolmaContainer.get<EntityService>(Types.EntityService);
        this._volmaTableReducer = VolmaContainer.get<VolmaTableReducer<IVolmaTableProps<{}>>>(Types.VolmaTableReducer);
        this._signalRService    = VolmaContainer.get<SignalRService>(Types.SignalRService);
        this._apiSignalR        = VolmaContainer.get<TableSignalRServerInteraction>(Types.TableSignalRServerInteraction);
        this._deliveryService   = VolmaContainer.get<DeliveryService>(Types.DeliveryService);

        this._localStorageService = VolmaContainer.get<LocalStorageService>(Types.LocalStorageService);

        this.state = {UpdateInProgress: false};

        this.LoadData            = this.LoadData.bind(this);
        this.DebounceReloadData  = this.DebounceReloadData.bind(this);
        this.RowRenderer         = this.RowRenderer.bind(this);
        this.OnEntityAdded       = this.OnEntityAdded.bind(this);
        this.OnEntityUpdated     = this.OnEntityUpdated.bind(this);
        this.InstantReloadDeliveryData = this.InstantReloadDeliveryData.bind(this);
        this.NoRowsRenderer      = this.NoRowsRenderer.bind(this);
        this.GetDesktopRowHeight = this.GetDesktopRowHeight.bind(this);
        this.OnNextPage          = this.OnNextPage.bind(this);
        this.OnPrevPage          = this.OnPrevPage.bind(this);
    }

    public componentDidMount() {
        this.props.dispatch(this._volmaTableActions.Register(this.props.Name, this._volmaTableReducer, {
            IsTableInsideEntity: this.props.IsTableInsideEntity,
            Entity: this.props.Entity,
            DisableItemEdit: this.props.DisableItemEdit }));
        this._itemsPerPage = this.props.ItemsPerPage || this._itemsPerPageDefault;
        this.props.dispatch(this._volmaTableActions.ResetPaging(this.props.Name, this.props.Entity));
        this.props.dispatch(this._volmaTableActions.UpdateFilters(this.props.Name, this.props.Entity));
        setTimeout(() => {
            if (this.props.OnBeforeDataLoaded !== undefined)
                this.props.OnBeforeDataLoaded(this.props);
            this.LoadData(undefined, undefined, undefined, undefined, undefined, data => this.props.OnAfterDataLoaded(data));
        }, 0);

        this._signalRService.EntityAdded.On(this.OnEntityAdded);
        this._signalRService.EntityUpdated.On(this.OnEntityUpdated);
        this._signalRService.CargoTransporterChanged.On(this.InstantReloadDeliveryData);
    }

    public UNSAFE_componentWillUpdate(nextProps){
        const newId = nextProps.Entity
        const oldId = this.props.Entity
        if (newId !== oldId) {
            this._itemsPerPage = this.props.ItemsPerPage || this._itemsPerPageDefault;
            this.setState({UpdateInProgress: true});
            this.ClearData();
        }
        if(this.state.UpdateInProgress === true && nextProps.Data !== this.props.Data){
            this.setState({ UpdateInProgress: false })
        }
    }

    public componentDidUpdate(prevProps) {
        const oldId = prevProps.Entity
        const newId = this.props.Entity
        if (newId !== oldId) {
            this._itemsPerPage = this.props.ItemsPerPage || this._itemsPerPageDefault;
            this.props.dispatch(this._volmaTableActions.ResetPaging(this.props.Name, newId));
            this.props.dispatch(this._volmaTableActions.UpdateFilters(this.props.Name, newId));
            setTimeout(() => {
                if (this.props.OnBeforeDataLoaded !== undefined)
                    this.props.OnBeforeDataLoaded(this.props);
                this.LoadData()
            }, 0);
        }
    }

    public componentWillUnmount() {
        this._signalRService.EntityAdded.Off(this.OnEntityAdded);
        this._signalRService.EntityUpdated.Off(this.OnEntityUpdated);
        this._signalRService.CargoTransporterChanged.Off(this.InstantReloadDeliveryData);
        clearTimeout(this._debouncedReloadTimeout);
    }

    public shouldComponentUpdate(nextProps: IVolmaTableProps<any>, nextState: {UpdateInProgress: boolean}) {
        const shouldUpdate =
            nextState.UpdateInProgress  !== this.state.UpdateInProgress ||
            nextProps.NoopObject        !== this.props.NoopObject       ||
            nextProps.Entity            !== this.props.Entity           ||
            nextProps.Data              !== this.props.Data             ||
            nextProps.DataHelper        !== this.props.DataHelper       ||
            nextProps.IsFilterOpened    !== this.props.IsFilterOpened   ||
            nextProps.OpenedFilter      !== this.props.OpenedFilter     ||
            nextProps.SelectedIds       !== this.props.SelectedIds      ||
            nextProps.SortOptions       !== this.props.SortOptions      ||
            nextProps.Paging            !== this.props.Paging           ||
            nextProps.Inputs            !== this.props.Inputs;

        //если число строк в таблице не изменилось, но изменились данные, то приходится делать принудительное обновление
        //https://github.com/bvaughn/react-virtualized/blob/master/docs/List.md#forceupdategrid
        if (shouldUpdate){
            if (this._grid !== undefined )
                setTimeout(() => {
                    if (this._grid !== undefined && this._grid !== null) {
                        this._grid.recomputeGridSize();
                    }
                });
        }

        return shouldUpdate;
    }

    public render() {
        this._entity = this.props.Entity;
        this._baseEntity = this.props.BaseEntity;
        this._followUpdates = this.props.ReloadOnSignalREvent;
        const rowCount = this.GetRowCount(this.props);
        const columnsCount = this.props.Columns.length;

        const actions = this.GetActions();
        return (
            <div className={(this._tableBox({"bottom-padding": this.props.IsTableInsideEntity })).toString()}>
                <VolmaAutoSizer disableWidth disableHeight>
                    {({ height, width }) =>this.GetTableSized(width, height, rowCount, columnsCount, actions)}
                </VolmaAutoSizer>
            </div>
        );
    }

    private GetTableSized(width: number, height: number, rowCount: number, columnsCount: number, actions: JSX.Element[]): JSX.Element[] {
        this._isDesktopView = window.innerWidth > this._mobileBreakWidth;

        const showActions = this.props.HideActions !== true && this._isDesktopView && (rowCount > 0 || (actions !== undefined && actions.length > 0));
        const showGrid = rowCount > 0 && this._dataClearRequested !== true && this.state.UpdateInProgress !== true;

        const scrollbarWidth = ScrollBarSize();
        let widthCorrection = 0;
        let headerHeight = width > 1600 ? this._headerHeight : this._headerHeight1600;
        if(this.props.HideHeader === true)
            headerHeight = 0;

        let actionsHeight = showActions ? (width > 1600 ? this._actionsHeight : this._actionsHeight1600): 0;
        if (this.props.HideActions === true)
            actionsHeight = 0;

        const expectedGridHeight = rowCount * this._rowHeight;
        const minHeight = Math.min(this._defaultlistHeight, expectedGridHeight + headerHeight + actionsHeight)
        const listHeight = this.props.IsTableInsideEntity || this.props.UseRealHeight ? minHeight : height;

        if ((listHeight - headerHeight - actionsHeight) < (expectedGridHeight))
            widthCorrection = scrollbarWidth;

        const gridColumnCount = this._isDesktopView ? 1                        : rowCount;
        const gridColumnWidth = this._isDesktopView ? (width - widthCorrection): this._mobileColumnWidth;
        const gridWidth       = this._isDesktopView ? width                    : width - (this.props.HideHeader === true ? 0 : 50);

        const gridRowCount  = this._isDesktopView ? rowCount                                    : 1;
        const gridRowHeight = this._isDesktopView ? this.GetDesktopRowHeight                    : (columnsCount * this._mobileRowHeight - scrollbarWidth);
        const gridHeight    = this._isDesktopView ? (listHeight - headerHeight - actionsHeight) :  columnsCount * this._mobileRowHeight;

        let headerStyle = {};
        if (!this._isDesktopView)
            headerStyle = { width: this._mobileHeadWidth + 'px' };
        const res = new Array <JSX.Element>();
        res.push(
                <div key={"table"} className={(this._table()).toString()}>
                    {this.props.HideHeader !== true && <div className={(this._table("head")).toString()}>
                        <div className={(this._table("row")).toString()} style={headerStyle}>
                            {this.GetTableHeaderCells(this._isDesktopView, widthCorrection)}
                        </div>
                    </div>}
                    <div className={(this._table("body")).toString()}>
                        {showGrid && this.GetGridElement(gridWidth, gridColumnWidth, gridColumnCount, gridHeight, gridRowHeight, gridRowCount)}
                        {!showGrid && showActions && <div style={{ width: gridWidth, height: gridHeight }}></div>}
                    </div>
                </div>
        )
        if (showActions)
            res.push(this.GetActionsElement(actions));
        return res;
    }

    private GetGridElement(gridWidth: number, gridColumnWidth: number, gridColumnCount: number,
                          gridHeight: number, gridRowHeight: any,   gridRowCount: number): JSX.Element{

        this._gridWidth = gridColumnWidth;
        return (
            <Grid
                ref                 = {((ref) => this._grid = ref).bind(this)}
                columnCount         = {gridColumnCount}
                columnWidth         = {gridColumnWidth}
                width               = {gridWidth}
                rowCount            = {gridRowCount}
                rowHeight           = {gridRowHeight}
                height              = {gridHeight}
                autoContainerWidth  = {false}
                overscanColumnCount = {5}
                overscanRowCount    = {5}
                noContentRenderer   = {this.NoRowsRenderer}
                cellRenderer        = {this.RowRenderer}
            />
        )
    }


    private GetPaging() {
        if (this.props.IsTableInsideEntity)
            return undefined;
        let startIndex = 0;
        if (this.props.Paging !== undefined && this.props.Paging.ItemsPerPage !== undefined && this.props.Paging.PageNumber !== undefined){
            startIndex = this._itemsPerPage * (this.props.Paging.PageNumber - 1) + 1;
        }
        let endIndex = startIndex;
        if (this.props.Data !== undefined)
            endIndex = startIndex + this.props.Data.length - 1;
        if(startIndex <= 0 || endIndex < startIndex)
            return undefined;

        return (
            <div key='paging' className={(this._tableNav()).toString()}>
                {false && <div className={(this._tableNav("left")).toString()}>
                    <div className={(this._tableNav("item")).toString()}>
                        <span className={(this._tableNav("item-text")).toString()}>{i18next.t("base-table:Page")}:</span>
                        <span className="minimize-select">
                            <select className="minimize-select__select">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <svg className="minimize-select__arrow">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                            </svg>
                        </span>
                    </div>
                    <div className={(this._tableNav("item")).toString()}>
                        <span className={(this._tableNav("item-text")).toString()}>{i18next.t("base-table:ItemsPerPage")}:</span>
                        <span className="minimize-select">
                            <select className="minimize-select__select">
                                <option value="1">{i18next.t("base-table:All")}</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                            <svg className="minimize-select__arrow">
                                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                            </svg>
                        </span>
                    </div>
                </div>}
                <div className={(this._tableNav("right")).toString()}>
                    <div className={(this._tableNavPagination()).toString()}>
                        <span className={(this._tableNavPagination("val")).toString()}>
                            {startIndex}{" "}-{" "}{endIndex}{" "}
                            {i18next.t("base-table:of")} {this.props.Paging.TotalItems}</span>
                        <div className={(this._tableNavPagination("box")).toString()}>
                            <a className={(this._tableNavPagination("link", {prev: true })).toString()} onClick={this.OnPrevPage}>
                                <svg className={(this._tableNavPagination("link-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </a>
                            <a className={(this._tableNavPagination("link", {next: true })).toString()} onClick={this.OnNextPage}>
                                <svg className={(this._tableNavPagination("link-ico")).toString()}>
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#angle-down"></use>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    private GetActionsElement(actions: JSX.Element[]): JSX.Element{
        return (
            <div key={"actions"} className={(this._tableActions()).toString()}>
                <span className={(this._tableActions("text")).toString()}>{i18next.t("common:SelectedNElements", {
                    count: this.props.SelectedIds === undefined ? 0 : this.props.SelectedIds.length,
                    total: this.props.Data === undefined ? 0 : this.props.Data.length
                } as any)}</span>
                <div className={(this._tableActions("list")).toString()}>
                    {actions}
                </div>
                <div className={(this._tableActions("right")).toString()}>
                    <a  onClick={this.ClearSelection.bind(this)} className={(this._tableActions("close")).toString()}>
                        <span className={(this._tableActions("close-text")).toString()}>{i18next.t("common:ClearSelection")}</span>
                        <svg className={(this._tableActions("close-ico")).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#close"></use>
                        </svg>
                    </a>
                    {this.GetPaging()}
                </div>
            </div>
        );
    }

    private GetDesktopRowHeight(rowIndex: {index: number}): number{
        const colsDefs = this.props.Columns;

        const totalWeights = this.GetColumnDefinitionsTotalWeights();
        let cellIndex = 0;
        let maxHeight = 0;
        for (const colDef of colsDefs) {
            const width = this._isDesktopView ? 1 / totalWeights * colDef.Weight : 100;
            const cellText = colDef.CellTextGetter !== undefined ? colDef.CellTextGetter(this.props, rowIndex.index) : this.props.Data[rowIndex.index][colDef.DataKey];
            const dataHeight = colDef.CellHeightMeasurer(cellText, width * this._gridWidth);
            cellIndex++;
            if (maxHeight < dataHeight)
                maxHeight = dataHeight;
        }

        return Math.max(this._rowHeight, maxHeight);
    }

    private GetRowCount(props: IVolmaTableProps<any>): number {
        return props.Data !== undefined ? props.Data.length : 0;
    }

    private GetTableHeaderCells(isDesktopView: boolean, widthCorection: number): Array<JSX.Element> {
        const headerCells = new Array<JSX.Element>();
        const colsDefs = this.props.Columns;

        const totalWeights = this.GetColumnDefinitionsTotalWeights();
        let index = 0;

        for (const colDef of colsDefs) {
            const headerRenderProps: IVolmaTableHeaderRenderProps = {
                ColumnData: undefined,
                DataKey: colDef.DataKey,
                FilterKey: colDef.FilterKey,
                SortKey: colDef.SortKey,
                IsSortable: colDef.IsSortable && !this.props.IsTableInsideEntity,
                IsFilterable: colDef.IsFilterable && !this.props.IsTableInsideEntity,
                Label: i18next.t(colDef.Label),
                Description: i18next.t(colDef.Description),
                SortBy: undefined,
                SortDirection: undefined,
                FilterEnum: colDef.FilterEnum,
                Width: isDesktopView ? 100 / totalWeights * colDef.Weight : 100,
                Height: isDesktopView ? undefined : this._mobileRowHeight,
                ScrollbarWidth: isDesktopView ? (widthCorection / totalWeights * colDef.Weight) : 0,
                Index: index++,
                FilterActive: this.IsFilterActive(colDef.FilterKey),
                FilterEntity: colDef.FilterEntity,
                Options: colDef.Options,
                OptionsFilter: colDef.OptionsFilter,
                EntityOptionIcon: colDef.EntityOptionIcon,
            };
            if (!this.props.IsTableInsideEntity)
                headerCells.push(colDef.HeaderRenderer(this.props, this.LoadData, headerRenderProps))
            else
                headerCells.push(VolmaTableHeaderRenderers.DefaultHeaderRenderer(this.props, this.LoadData, headerRenderProps))
        }
        return headerCells;
    }

    private GetTableRowCells(index: number): Array<JSX.Element> {
        const rowCells = new Array<JSX.Element>();
        const colsDefs = this.props.Columns;
        let cellIndex = 0;

        const totalWeights = this.GetColumnDefinitionsTotalWeights();
        for (const colDef of colsDefs) {
            const cellText = colDef.CellTextGetter !== undefined ? colDef.CellTextGetter(this.props, index) : this.props.Data[index][colDef.DataKey];
            const cellRenderParams: IVolmaTableCellRendererParams = {
                cellData: cellText,
                dataKey: colDef.DataKey,
                rowData: this.props.Data[index],
                rowIndex: index,
                cellIndex: cellIndex++,
                selected: this.IsColumnSelected(colDef.FilterKey, index),
                width: this._isDesktopView ? 100 / totalWeights * colDef.Weight : 100,
                height: this._isDesktopView ? undefined : this._mobileRowHeight,
            };

            rowCells.push(colDef.CellRenderer(this.props, cellRenderParams))
        }
        return rowCells;
    }

    private GetColumnDefinitionsTotalWeights(): number {
        return this.props.Columns.map(x => x.Weight).reduce((x, y) => x + y);
    }

    private IsFilterActive(colDefFilterKey: string | string[]): boolean {
        return this.props.OpenedFilter !== undefined && this.OneOfFiltersOpened(colDefFilterKey);
    }

    private IsColumnSelected(colDefFilterKey: string | string[], index: number): boolean {
        return this.props.SelectedIds !== undefined && (
                (this.props.SelectedIds.find(x => x == this.props.Data[index].Id) !== undefined) || this.IsFilterActive(colDefFilterKey)
        );
    }

    private OneOfFiltersOpened(colDefFilterKey: string | string[]): boolean {
        return Array.isArray(colDefFilterKey)
            ? colDefFilterKey.some(key => key === this.props.OpenedFilter?.Key) 
            : this.props.OpenedFilter?.Key === colDefFilterKey;
    }

    private RowRenderer({columnIndex, isScrolling, isVisible, key, parent, rowIndex, style}) {
        const index = columnIndex + rowIndex;
        return (
            <div
                className={(this.props.AlternateRowClass !== undefined ? this.props.AlternateRowClass(this.props.Data[index]) : this._table("row", {body: true })).toString()}
                key={key}
                onClick={((event: MouseEvent<any>) => this.OnRowClick(this.props, event, index, this.props.Data[index])).bind(this)}
                onTouchStart={((event: MouseEvent<any>) => this.OnRowTouchStart(this.props, event, index, this.props.Data[index])).bind(this)}
                onTouchEnd={((event: MouseEvent<any>) => this.OnRowTouchEnd()).bind(this)}
                onTouchMove={((event: MouseEvent<any>) => this.OnRowTouchMove()).bind(this)}
                onDoubleClick={((event: MouseEvent<any>) => this.OnRowDoubleClick(this.props, event, index, this.props.Data[index])).bind(this)}
                style={style}>
                {this.GetTableRowCells(index)}
            </div>
        )
    }

    private NoRowsRenderer() {
        return (
            <div>{i18next.t("common:NoRows")}</div>
        )
    }

    private GetActions(): Array<JSX.Element> {
        const actions = new Array<JSX.Element>();
        if (this.props.Actions === undefined)
            return actions;
        const selectedCount = this.props.SelectedIds != undefined ? this.props.SelectedIds.length : 0;
        for (let i = 0; i < this.props.Actions.length; i++) {
            const action = this.props.Actions[i];
            if (action.IsAvailable(this.props.SelectedIds, this.props.Data, this.props.DataHelper)){
                actions.push(
                    <div className={(this._tableActions("list-item")).toString()} key={i}>
                        <a
                            className={(this._tableActions("list-link")).toString()}
                            onClick={(() => action.OnActivate(this.props.Name, this.props.Entity, this.props.SelectedIds, this.props.Data, this.props.dispatch, () => this.LoadData(), this.props.DataHelper)).bind(this)}>
                            {i18next.t(action.Title, { count: selectedCount})}
                        </a>
                    </div>
                 );
            }
        }

        return actions;
    }

    // USER INTERACTION
    protected OnRowClick(props: IVolmaTableProps<any>, event: MouseEvent<any>, index: number, rowData: ITableDTO) {
        props.dispatch(this._volmaTableActions.ToggleSelection(props.Name, rowData.Id, event.ctrlKey, event.shiftKey));

        if (props.OnRowClick) {
            props.OnRowClick(props.Name, rowData.Id, event.ctrlKey, event.shiftKey)
        }
    }

    protected OnRowTouchStart(props: IVolmaTableProps<any>, event: MouseEvent<any>, index: number, rowData: ITableDTO) {
        clearTimeout(this._longTapDetectorTimeout);
        this._longTapDetectorTimeout = setTimeout(() => {
            this.OnRowDoubleClick(props, event, index, rowData);
        }, this._longTouchThreshold);
    }

    protected OnRowTouchEnd() {
        clearTimeout(this._longTapDetectorTimeout);
    }

    protected OnRowTouchMove() {
        clearTimeout(this._longTapDetectorTimeout);
    }

    protected OnRowDoubleClick(props: IVolmaTableProps<any>, event: MouseEvent<any>, index: number, rowData: ITableDTO) {
        if (props.LoadDataFromServer){
            if (props.DisableItemEdit !== true)
                history.push(this._entityService.GetEntityRoute(props.Entity, rowData.Id));
        }
        else {
            if (props.OnRowEditRequired)
                props.OnRowEditRequired(rowData);
            else
                props.dispatch(this._volmaTableActions.EditRowRequired(props.Name, rowData.Id));
        }
    }

    public OnCloseFilterClick(event: MouseEvent<any>) {
        this.props.dispatch(this._volmaTableActions.CloseFilter(this.props.Name));
    }

    public OnPrevPage() {
        if (this.props.Paging.PageNumber > 1) {
            this.LoadData(undefined, undefined, undefined, undefined, --this.props.Paging.PageNumber);
        }
    }

    public OnNextPage() {
        const totalPages = this.GetTotalPages();
        if (this.props.Paging.PageNumber < totalPages) {
            this.LoadData(undefined, undefined, undefined, undefined, ++this.props.Paging.PageNumber);
        }
    }

    public ClearSelection() {
        this.props.dispatch(this._volmaTableActions.ClearSelection(this.props.Name))
    }

    public ReloadData() {
        this._reloadCount++;
        clearTimeout(this._debouncedReloadTimeout);

        this._apiSignalR.GetTableData(this.props.Entity, this.props.SortOptions.sortBy, this.props.SortOptions.sortDirection, this.props.Filter, this._itemsPerPage, this.props.Paging.PageNumber)
            .then((tableData) => { this._reloadPostponed = false; this.props.dispatch(this._volmaTableActions.TableDataLoaded(this.props.Name, tableData))})
            .catch(() => { this._reloadPostponed = false; this.LoadData();})
    }

    private ClearData(){
        this._dataClearRequested = true;
        this.props.dispatch(this._volmaTableActions.ClearData(this.props.Name));
        setTimeout(() => {this._dataClearRequested = false;}, 0);
    }

    private LoadData(sortBy?: string, sortDir?: ESortDir, filter?: Array<FilterDTO>, itemsPerPage?: number, pageNumber?: number, dataLoadedAction?: (data: Array<ITableDTO>) => Array<ITableDTO> | undefined) {
        this._reloadPostponed = false;

        if (sortBy === undefined && this.props.SortOptions !== undefined)
            sortBy = this.props.SortOptions.sortBy;

        if (sortDir === undefined && this.props.SortOptions !== undefined)
            sortDir = this.props.SortOptions.sortDirection;

        if (filter === undefined)
            filter = this.props.Filter;

        itemsPerPage = this._itemsPerPage;

        if (pageNumber === undefined && this.props.Paging !== undefined)
            pageNumber = this.props.Paging.PageNumber;

        if (this.props.LoadDataFromServer) {
            this.props.dispatch(this._volmaTableActions.LoadData(
                this.props.Name,
                this.props.Entity,
                i18next.t("common:LoadingTableData"),
                sortBy,
                sortDir,
                filter,
                itemsPerPage,
                pageNumber,
                dataLoadedAction || this.props.OnAfterDataLoaded));
        }
        else {
            this.props.dispatch(this._volmaTableActions.UpdateData(
                this.props.Name,
                this.props.DataOriginal,
                sortBy,
                sortDir,
                filter));
        }
    }

    private OnScroll({ clientHeight, clientWidth, scrollHeight, scrollLeft, scrollTop, scrollWidth }){
        const currentVisiblePos = scrollTop;
        if (this.props.Data !== undefined && this.props.Data.length > 0 && !this.props.IsTableInsideEntity){
            const savedRows = this._localStorageService.GetKey(VOLMA_TABLE_CURRENT_SCROLL_POS);
            let currentRows = {};

            if (savedRows !== undefined){
                currentRows = JSON.parse(savedRows);
            }
            currentRows[this.props.Entity] = currentVisiblePos;
            this._localStorageService.SetKey(VOLMA_TABLE_CURRENT_SCROLL_POS, JSON.stringify(currentRows));
        }
    }

    private GetCurrentScrollPos() {
        if (this.props.Data !== undefined && this.props.Data.length > 0 && !this.props.IsTableInsideEntity){
            const savedRows = this._localStorageService.GetKey(VOLMA_TABLE_CURRENT_SCROLL_POS);
            let currentRows = {};
            if (savedRows !== undefined){
                currentRows = JSON.parse(savedRows);
            }
            if (currentRows[this.props.Entity] !== undefined){
                return currentRows[this.props.Entity];
            }
        }

        return -1;
    }

    private GetTotalPages(): number {
        if (this.props.Paging === undefined || this.props.Paging.TotalPages === undefined)
            return -1;

        return this.props.Paging.TotalPages;
    }

    private OnEntityUpdated(dto: EntityUpdatedDTO) {
        if (this !== undefined){
            if(this.props.OnEntityUpdated !== undefined)
            {
                this.props.OnEntityUpdated(dto, () => this.DebounceReloadData(), () => this.DispatchFilteredData(dto))
            } else {
                if (this._followUpdates === true && (this._entity === dto.Type || this._baseEntity === dto.Type)) {
                    if (this._baseEntity !== EEntityType.Delivery){
                        this.DebounceReloadData();
                    }
                }
            }
        }
    }

    private DispatchFilteredData(dto: EntityUpdatedDTO)
    {
        this.props.dispatch(this._volmaTableActions.TableDataUpdated(this.props.Name, this.props.DataOriginal.slice(0).filter(x => x.Id !== dto.Id)));
    }

    private OnEntityAdded(dto: EntityAddedDTO) {
        if (this !== undefined) {
            if (this._followUpdates === true && (this._entity === dto.Type || this._baseEntity === dto.Type)) {
                if (this._baseEntity !== EEntityType.Delivery) {
                    this.DebounceReloadData();
                }
                // special treatment for delivery
                else {
                    const currentDeliveryTableEntities = this._deliveryService.GetEntityByDelivery((dto.DTO as DeliveryDTO).State, (dto.DTO as DeliveryDTO).AssignType);
                    if (currentDeliveryTableEntities.findIndex(x => x === this._entity) > -1)
                        this.DebounceReloadData();
                }
            }
        }
    }

    private DebounceReloadData() {
        if (!this._reloadPostponed) {
            this._reloadPostponed = true;
            clearTimeout(this._debouncedReloadTimeout);
            const timeout = this._reloadDebounce * Math.random();
            console.log('update planned in ', timeout);
            this._debouncedReloadTimeout = setTimeout(() => { this.ReloadData(); }, timeout);
        }
        else
        console.log('skip update because of postponed update')
    }

    private InstantReloadDeliveryData(): void {
        if (this._baseEntity === EEntityType.Delivery) {
            this._reloadPostponed = true;
            this.ReloadData();
        }
    }
}
