import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './Reducers/index';
import { VOLMA_SIGNALR_STOP_CONNECTION } from './Constants/AppConstants';
import { Types } from './Infrastructure/Types';
import { HubProxyActions } from './Infrastructure/SignalR/HubProxyActions';
import { VolmaContainer } from './Infrastructure/InversifyInject';
import createSignalrMiddleware from './Infrastructure/SignalR/SignalRMiddleware';



export const history = createBrowserHistory();

const signalrMiddleware = createSignalrMiddleware((dispatch, connection) => {
    const proxy = connection.createHubProxy('VolmaSPAHub');

    let hubActions = VolmaContainer.get<HubProxyActions>(Types.HubProxyActions);
    hubActions.RegisterListners(proxy, dispatch);

    proxy.on('Disconnect', () => dispatch({ type: VOLMA_SIGNALR_STOP_CONNECTION }))
})

export default function configureStore(preloadedState) {
    const store = createStore(
        rootReducer,
        preloadedState,
        compose(
            applyMiddleware(
                thunk,
                signalrMiddleware
            ),
        ),
    )

    return store
}
