import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';
import { UserDTO } from '../../../../Domain/DTO/UserDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { AuthenticationService } from '../../../../Infrastructure/Services/AuthService';
import { StatusDefault } from '../../../../Infrastructure/Status/StatusDefault';
import { AcceptDeliveriesAction } from '../Actions/AcceptDeliveriesAction';
import { DeliveryWithdrawAction } from '../Actions/DeliveryWithdrawAction';
import { ManualAssignDeliveriesAction } from '../Actions/ManualAssignDeliveryAction';
import { NotAcceptDeliveriesAction } from '../Actions/NotAcceptDeliveriesAction';
import { SynchronizeDeliveryAction } from '../Actions/SynchronizeDeliveryAction';
import { UpdateAssignTypeDeliveriesAction } from '../Actions/UpdateAssignTypeAction';
import { DeliveryHelperProps } from '../IHelperProps';
import { Assignation } from './Assignation';


export class Acceptance extends StatusDefault<DeliveryDTO, DeliveryHelperProps>{
    public TitleCurrent : string = "delivery:StatusAcceptanceCurrent";
    public TitleFinished: string = "delivery:StatusAcceptanceFinished";
    public TitleFuture  : string = "delivery:StatusAcceptanceFuture";

    public IsActive(dto: DeliveryDTO) {
        return true;
    }

    public IsCurrent(dto: DeliveryDTO){
        return dto.State === EDeliveryState.Assigned
    }

    public IsFinished(dto: DeliveryDTO){
        return !this.IsCurrent(dto) && new Assignation().IsFinished(dto) && dto.State !== EDeliveryState.NotExecuted && dto.State !== EDeliveryState.Cancelled;
    }

    public GetActions(dto: DeliveryDTO, currentUser: UserDTO, authService: AuthenticationService){
        return [new DeliveryWithdrawAction(), new ManualAssignDeliveriesAction(), new UpdateAssignTypeDeliveriesAction(),
        new AcceptDeliveriesAction(), new NotAcceptDeliveriesAction(), new SynchronizeDeliveryAction()];
    }

    public GetTimeLeftInStatus(dto: DeliveryDTO): Date {
        if (dto.NextStateDate !== undefined && dto.NextStateDate !== null)
            return dto.NextStateDate;
        return undefined;
    }

}
