import { injectable } from "inversify";
import { parse } from "query-string";
import * as React from "react";
import { CargoTransporterTableDTO } from "../../../Domain/DTO/CargoTransporterTableDTO";
import { DriverLocationDTO } from "../../../Domain/DTO/DriverLocationDTO";
import { DriverTableDTO } from "../../../Domain/DTO/DriverTableDTO";
import { EntityUpdatedDTO } from "../../../Domain/DTO/EntityUpdatedDTO";
import { FilterDTO } from "../../../Domain/DTO/FilterDTO";
import { EEntityType } from "../../../Domain/Enum/EEntityType";
import { VolmaContainer } from "../../../Infrastructure/InversifyInject";
import { debounce } from "../../../Infrastructure/Services/DebounceService";
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from "../../../Infrastructure/Services/VomaTableService";
import { Types } from "../../../Infrastructure/Types";
import { VolmaInputValidator } from "../../../Infrastructure/Validation/VolmaInputValidatorValidator";
import i18next from '../../i18n';
import ExperimentalVolmaTable from "../../Table/VolmaTable/ExperimentalVolmaTable";
import { IVolmaTableCellRendererParams, IVolmaTableColumn, IVolmaTableProps } from "../../Table/VolmaTable/IVolmaTableProps";
import { VolmaTableCommonCellRenderers } from "../../Table/VolmaTable/Renderers/VolmaTableCommonCellRenderers";
import VolmaInput from "../../VolmaInput";
import { VolmaMultiTransporterSelect } from "../../VolmaMultiSelect/MultiTransporter/VolmaMultiTransporterSelect";
import VolmaSelect from "../../VolmaSelect";
import { VolmaSelectExtendedTableDTO } from "../../VolmaSelect/IVolmaSelectProps";
import { BaseEntityService } from "../BaseEntity/BaseEntityService";
import { IBaseEntityProps } from "../BaseEntity/IBaseEntityProps";
import { DeliveryColumnTemplates } from "../Delivery/Subsidiary/DeliveryColumnTemplates";
import "./DriverMonitoring.scss";
import { DriverMonitoringActions } from "./DriverMonitoringActions";
import { DriverMap } from "./DriverMonitoringMap";
import { DriverMonitoringReducer } from "./DriverMonitoringReducer";
import { DeliveryDriverMonitoringDTO, DeliveryMonitoringQuery, DriverMonitoringHelperProps, DriverMonitoringHelperPropsInitial } from "./IHelperProps";

@injectable()
export class DriverMonitoringEntityService extends BaseEntityService<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps> {
    protected _volmaTableService: VolmaTableService;

    private _reducer: DriverMonitoringReducer;
    private _action: DriverMonitoringActions;
    private _driverColumns: Array<IVolmaTableColumn>;    
    private _filters: FilterDTO[];
    private _inputValidator: VolmaInputValidator;
    private _deliveryColumnTemplates = new DeliveryColumnTemplates();
    private _driverLoadDebounceTimeInMS = 300;
    private _driverLoadDebounceTimeout: NodeJS.Timeout;
    private _dispatch: any;
    
    constructor() {
        super();

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._reducer = VolmaContainer.get<DriverMonitoringReducer>(Types.DriverMonitoringReducer);
        this._action = VolmaContainer.get<DriverMonitoringActions>(Types.DriverMonitoringActions);      
        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);

        this._driverColumns = this.GetColumnsList();
        this._filters = [];

        this.OnAfterDataLoaded = this.OnAfterDataLoaded.bind(this);
        this.LocationUpdated = this.LocationUpdated.bind(this);
        this.OnApplyFilters = this.OnApplyFilters.bind(this);
    }

    public GetInitialDataHelper() {
        return DriverMonitoringHelperPropsInitial;
    }

    public GetReducer() {
        return this._reducer;
    }

    public GetEditorModal(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>): JSX.Element {
        return this.GetEditor(props);
    }

    public GetHeaderEditor(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>): JSX.Element {
        return (
            <div className={(this._createCard("header")).toString()}>
                <span className={this._createCard("header", { text: true }).toString()}>{i18next.t("drivermonitoring:MainTitle")}</span>
            </div>
        );
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return this._volmaTableService.GetColumnsByKeys(
            EEntityType.DriverMonitoring,
            [
                { ...this._deliveryColumnTemplates.Id },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.OrderNumber), Weight: 0.5, },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.AssignedDriverName),
                    FilterKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.AssignedDriverName),
                },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.AssignedDriverPhone), },
                { ...this._deliveryColumnTemplates.Identifier, Weight: 0.8 },
                { ...this._deliveryColumnTemplates.State, OptionsFilter: undefined },
                { DataKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.LocationUpdateDate), },
                {
                    DataKey: PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.Longitude),
                    CellRenderer: this.LocationCellRenderer,
                },
            ])
    }

    public OnAfterDataLoaded(dto: DeliveryDriverMonitoringDTO): void {}

    public InitializeEntity(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>) {
        this._dispatch = props.dispatch;
        
        this._signalRService.DeliveryLocationUpdated.On(this.LocationUpdated);      

        const query = parse(props.location.search) as DeliveryMonitoringQuery;
        this._dispatch(this._action.ChangeMonitoringFiltersByQuery(query));    
    }

    public DisposeEntity() {
        this._signalRService.DeliveryLocationUpdated.Off(this.LocationUpdated);        
    }

    public GetEditor(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>): JSX.Element {                
        return (
            <div>
                {this.GetMainInfoNoDropItem(false, true, i18next.t("drivermonitoring:Map"), this.GetMapPart(props))}
                {this.GetMainInfoNoDropItem(false, true, i18next.t("drivermonitoring:Deliveries"), this.DriverLocationTable(props))}
            </div>
        );
    }

    private GetMapPart(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>) {
        this.CacheFilters(props.DataHelper.DeliveryMonitoringFilters);
        return (
            <div>
                {this.SelectPart(props)}
                <div className={this._mainInfo("row")}>
                    <div className={this._mainInfo("line", { fill: true })}>
                        <DriverMap center={props.DataHelper.SelectedDelivery} drivers={props.DataHelper.Deliveries ?? []} />
                    </div>
                </div>
            </div>
        )
    }

    private CacheFilters(filters: FilterDTO[]): void {
        this._filters = filters;
    }    

    private SelectPart(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>) { 
        const { identifier } = parse(props.location.search) as DeliveryMonitoringQuery;
        const isReadonlyDriver = props.DataHelper.SelectedMultiTransporters?.length === 0;        
        return (               
            <div className={this._mainInfo("row", { fill: true })}>
                <div className={this._mainInfo("cell", { 33: true })}> 
                    <div className={this._mainInfo("line", { fill: true })}>
                        <VolmaMultiTransporterSelect
                            {...PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DeliveryDriverMonitoringDTO) => val.CargoTransporterId)}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.CargoTransporterId)}
                            AllowMultiselect
                            OptionsFilter={[(x: CargoTransporterTableDTO) => !x.IsIncludeInMultiCargoTransporter, undefined]}
                            Value={props.DataHelper.SelectedMultiTransporters}                            
                            OnValueChanged={this.OnMultiTransporterChanged}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 33: true })}> 
                    <div className={this._mainInfo("line", { fill: true })}>
                        <VolmaSelect
                            {...PropertyHelper.GetSelectPropertyByName(props.Selects, (val: DriverLocationDTO) => val.DriverId)}
                            Name={PropertyHelper.GetPropertyName((val: DriverLocationDTO) => val.DriverId)}
                            Label={i18next.t("drivermonitoring:DriverTitle")}
                            Placeholder={i18next.t("drivermonitoring:DriverSelectPlaceholder")}
                            LabelKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Name)}
                            ValueKey={PropertyHelper.GetPropertyName((x: DriverTableDTO) => x.Id)} 
                            Options={props.DataHelper.DriversForSelectedMultiTransporters}
                            Readonly={isReadonlyDriver}
                            AllowMultiselect
                            Value={props.DataHelper.SelectedDrivers}
                            OnValueChanged={this.OnDriversChanged}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                <div className={this._mainInfo("cell", { 33: true })}> 
                    <div className={this._mainInfo("line", { fill: true })}>
                        <VolmaInput
                            {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: DeliveryDriverMonitoringDTO) => val.Identifier)}
                            Name={PropertyHelper.GetPropertyName((val: DeliveryDriverMonitoringDTO) => val.Identifier)}
                            Label={i18next.t("drivermonitoring:DeliveryNumber")}
                            Value={props.DataDTO.Identifier}
                            DefaultValue={identifier}
                            InputClassName={"monitoring-long-input"}
                            PlaceholderClassName={"monitoring-placeholder"}
                            Validator={this._inputValidator}
                            Readonly={false}
                            CharCounter={false}
                            dispatch={props.dispatch} />
                    </div>
                </div>
                {this.ApplyButtonPart(props)}
            </div>
        )
    }

    private ApplyButtonPart(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>) {
        return (
            <div className={this._mainInfo("cell", { 33: true }).mix(['right-aligned'])}> 
                <div className={this._mainInfo("line", { fill: true }).mix(['long-button'])} >
                    <div className={this._btnGreen({ "full-height": true }).mix([this._btn(), this._zeroPadding()])}>
                        <button className={this._btn.mix([this._btnGreen, this._fullHeigthButton()])}
                            onClick={(e) => this.OnApplyFilters(props, e)}>{i18next.t('drivermonitoring:Submit')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    

    private OnMultiTransporterChanged = (value: Array<VolmaSelectExtendedTableDTO>) => {
        this._dispatch(this._action.MultiTransportersSelected(value));       

        if (value.length === 0) {
            this.OnDriversChanged([]);
        } else {
            this.LoadDriversWithDebounce(value);
        }        
    }

    private LoadDriversWithDebounce(value: Array<VolmaSelectExtendedTableDTO>) {
        if (this._driverLoadDebounceTimeout !== undefined) {
            clearTimeout(this._driverLoadDebounceTimeout);
        }

        const driverLoad = () => this._dispatch(this._action.LoadDriversBySelectedValues(i18next.t('common:LoadingDriversTable'), value));
        this._driverLoadDebounceTimeout = debounce(driverLoad, this._driverLoadDebounceTimeInMS, false)();
    }   

    private OnDriversChanged = (value: Array<DriverTableDTO>) => {
        this._dispatch(this._action.DriversSelected(value));
    }

    private OnApplyFilters(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        event.stopPropagation();

        this._dispatch(this._action.LoadDeliveriesBySelectedValue(
            i18next.t('common:LoadingDriversTable'), 
            props.DataHelper.SelectedMultiTransporters || [], 
            props.DataHelper.SelectedDrivers || [], 
            props.DataDTO.Identifier
        ));            
    }

    private LocationUpdated(dto: EntityUpdatedDTO) {
        console.log('Location updated', JSON.stringify(dto));
        this._dispatch(this._action.LoadDeliveriesByFilters(i18next.t("common:Loading"), this._filters));
    }

    private DriverLocationTable(props: IBaseEntityProps<DeliveryDriverMonitoringDTO, DriverMonitoringHelperProps>): JSX.Element{      
        const isEmptyTable = props.DataHelper.Deliveries === undefined || props.DataHelper.Deliveries.length === 0           
        return (
            <div className={this._mainInfo("row")}>
                <div className={this._mainInfo("cell", { full: true, 100: true })}>
                    <div className={this._mainInfo("line", { fill: true }).mix(['driver-table'])}>
                        {!isEmptyTable &&                     
                            <ExperimentalVolmaTable
                            {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: DeliveryDriverMonitoringDTO) => val.Name) }
                            Name={PropertyHelper.GetPropertyName((x: DeliveryDriverMonitoringDTO) => x.Name)}
                            Columns={this._driverColumns}
                            HideActions={true}
                            Data={props.DataHelper.Deliveries}
                            DataOriginal={props.DataHelper.DeliveriesOriginal}
                            LoadDataFromServer={false}
                            IsTableInsideEntity={true}
                            UseRealHeight={true}
                            OnRowClick={this.OnRowClick}
                            dispatch={props.dispatch}/>}
                        {isEmptyTable && <span>{i18next.t("drivermonitoring:DeliveriesNotFound")}</span>}
                    </div>
                </div>
            </div>
        );
    }

    private OnRowClick = (name: string, id: string, ctrlPressed: boolean, shiftPressed: boolean) => {
        return this._dispatch(this._action.DriverOnRowSelected({ Id: id }));
    }

    private LocationCellRenderer(props: IVolmaTableProps<any>, params: IVolmaTableCellRendererParams) { 
        const dto = params.rowData as DeliveryDriverMonitoringDTO;

        const style = VolmaTableCommonCellRenderers.GetStyle(params);
        const key = VolmaTableCommonCellRenderers.GetKey(params);
        
        return  (
            <div className={VolmaTableCommonCellRenderers._table("cell", { selected: params.selected }).toString()} style={style} key={key} >
                <div className={(VolmaTableCommonCellRenderers._tableCont()).toString()}>
                    <span className={(VolmaTableCommonCellRenderers._tableCont("text")).toString()}>{dto.Latitude}, {dto.Longitude}</span>
                </div>
            </div>
        );
    }
}
