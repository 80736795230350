import { ShipperUserDTO } from '../../../Domain/DTO/ShipperUserDTO';
import { ITableDTO } from '../../../Domain/ITableDTO';
import { IActionPayloaded } from '../../../Infrastructure/Action/IAction';
import { IBaseEntityProps } from '../BaseEntity/IBaseEntityProps';
import { IEntityReducer } from '../BaseEntity/IEntityReducer';
import { ShipperUserHelperProps } from './IHelperProps';
import { injectable } from 'inversify';
import { ShipperTableDTO } from '../../../Domain/DTO/ShipperTableDTO';
import { ListIntOptionDTO } from '../../../Domain/DTO/ListIntOptionDTO';
import { IEntityDTO } from '../../../Domain/IEntityDTO';
import { EUserRole } from '../../../Domain/Enum/EUserRole';
import { EntityReducer } from '../BaseEntity/EntityReducer';



@injectable()
export class ShipperUserReducer extends EntityReducer<ShipperUserDTO, ShipperUserHelperProps> {

    public Reduce(state: IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>, action: IActionPayloaded<any>): IBaseEntityProps<ShipperUserDTO, ShipperUserHelperProps>{
        switch (action.type) {
            default:
                return state;
        }
    }
        //newState = super.UpdateDTOField<number, ListIntOptionDTO>(newState,
        //    x => x.Role,
        //    x => x.AllRoles,
        //    x => x.SelectedRole,
        //    x => x.Id)
        //newState = super.UpdateDTOField<string, ShipperTableDTO>(newState,
        //    x => x.ShipperId,
        //    x => x.AllShippers,
        //    x => x.SelectedShipper,
        //    x => x.Id)
}
