import { VehicleTableDTO } from '../../../Domain/DTO/VehicleTableDTO';
import { DriverTableDTO } from '../../../Domain/DTO/DriverTableDTO';
import { RatingTableDTO } from '../../../Domain/DTO/RatingTableDTO';
import { ContractTableDTO } from '../../../Domain/DTO/ContractTableDTO';


export class CargoTransporterHelperProps {
    Vehicles: Array<VehicleTableDTO>
    Drivers: Array<DriverTableDTO>
    Contracts: Array<ContractTableDTO>

    VehiclesOriginal: Array<VehicleTableDTO>
    DriversOriginal: Array<DriverTableDTO>
    ContractsOriginal: Array<ContractTableDTO>

    CurrentRating: Array<RatingTableDTO>
    CurrentRatingOriginal: Array<RatingTableDTO>
    ForecastRating: Array<RatingTableDTO>
}

export const InitialCargoTransporterHelperProps: CargoTransporterHelperProps = <CargoTransporterHelperProps>{
    Vehicles: [],
    Drivers: [],
    Contracts: [],
    VehiclesOriginal: [],
    DriversOriginal: [],
    ContractsOriginal: [],

    CurrentRating: [],
    CurrentRatingOriginal: [],
    ForecastRating: []
};