import { BiddingBetDTO } from '../../Domain/DTO/BiddingBetDTO';
import { DeliveryStateDTO } from '../../Domain/DTO/DeliveryStateDTO';
import { VolmaContainer } from '../InversifyInject';
import { Types } from '../Types';
import { CommonServerInteraction } from './CommonServerInteraction';
import { ECQRSApi } from './ECQRSApi';
import { UrlFabric } from './UrlFabric';
import { injectable } from 'inversify';
import { TableDataDTO } from '../../Domain/TableDataDTO';
import { DeliveryManualAssignDTO } from '../../Domain/DTO/DeliveryManualAssignDTO';
import { DeliveryAdditionalFilesDTO } from '../../Domain/DTO/DeliveryAdditionalFilesDTO';
import { AxiosPromise } from 'axios';


@injectable()
export class DeliveryServerInteraction extends CommonServerInteraction {
    private _urlFabric: UrlFabric;

    constructor() {
        super();

        this._urlFabric = VolmaContainer.get<UrlFabric>(Types.UrlFabric);
    }

    public AddBet(dto: BiddingBetDTO): AxiosPromise {
        return this.Post(this._urlFabric.BiddingBet(ECQRSApi.Write), dto);
    }

    public AddState(dto: DeliveryStateDTO): AxiosPromise {
        return this.Post(this._urlFabric.DeliveryState(ECQRSApi.Write), dto);
    }

    public ManualAssign(dto: DeliveryManualAssignDTO, deliveryId: string): AxiosPromise {
        return this.Post(this._urlFabric.ManulaAssign(ECQRSApi.Write, deliveryId), dto);
    }

    public AddStates(dto: TableDataDTO<DeliveryStateDTO>): AxiosPromise {
        return this.Post(this._urlFabric.DeliveryStates(ECQRSApi.Write), dto);
    }

    public Actualize(id: string){
        return this.Post(this._urlFabric.DeliveryActualize(id))
    }

    public GetStatement(ids: Array<string>){
        return this.DownloadFileWithParams(this._urlFabric.GetStatement(), JSON.stringify(ids))
    }

    public GetInvoice(ids: Array<string>){
        return this.DownloadFileWithParams(this._urlFabric.GetInvoice(), JSON.stringify(ids));
    }

    public GetAccount(ids: Array<string>){
        return this.DownloadFileWithParams(this._urlFabric.GetAccount(), JSON.stringify(ids))
    }

    public AddAdditionalFiles(complaintId: string, dto: DeliveryAdditionalFilesDTO){
        return this.Post(this._urlFabric.DeliveryAddAdditionalFiles(ECQRSApi.Write, complaintId), dto);
    }

    public AcceptPendingChanges(deliveryId: string){
        return this.Post(this._urlFabric.AcceptPendingChanges(deliveryId));
    }

    public RejectPendingChanges(deliveryId: string){
        return this.Post(this._urlFabric.RejectPendingChanges(deliveryId));
    }
}