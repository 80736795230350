import { BiddingBetTableDTO } from '../../../Domain/DTO/BiddingBetTableDTO';
import { CargoTransporterDTO } from '../../../Domain/DTO/CargoTransporterDTO';
import { ComplaintTableDTO } from '../../../Domain/DTO/ComplaintTableDTO';
import { ConsignorDTO } from '../../../Domain/DTO/ConsignorDTO';
import { DeliveryPendingChangesItemDTO } from '../../../Domain/DTO/DeliveryPendingChangesItemDTO';
import { DeliveryStateTableDTO } from '../../../Domain/DTO/DeliveryStateTableDTO';
import { DriverDTO } from '../../../Domain/DTO/DriverDTO';
import { FileTableDTO } from '../../../Domain/DTO/FileTableDTO';
import { HandlingTypeDTO } from '../../../Domain/DTO/HandlingTypeDTO';
import { LoadingPointDTO } from '../../../Domain/DTO/LoadingPointDTO';
import { LocalityDTO } from '../../../Domain/DTO/LocalityDTO';
import { PayloadDTO } from '../../../Domain/DTO/PayloadDTO';
import { RouteSegmentDTO } from '../../../Domain/DTO/RouteSegmentDTO';
import { VehicleDTO } from '../../../Domain/DTO/VehicleDTO';
import { ZoneDTO } from '../../../Domain/DTO/ZoneDTO';
import { IDeliveryStateTableHelperProps, InitialDeliveryStateTableHelperProps } from './DeliveryState/ITableHelperProps';
import { InitialRouteSegmentTableHelperProps, IRouteSegmentTableHelperProps } from './RouteSegment/ITableHelperProps';

export class DeliveryHelperProps{
    DeliveryStates        : Array<DeliveryStateTableDTO>
    DeliveryStatesOriginal: Array<DeliveryStateTableDTO>
    Complaints            : Array<ComplaintTableDTO>
    ComplaintsOriginal    : Array<ComplaintTableDTO>
    Bets                  : Array<BiddingBetTableDTO>
    WinnersBets           : Array<BiddingBetTableDTO>
    BetsOriginal          : Array<BiddingBetTableDTO>
    PendingChanges        : Array<DeliveryPendingChangesItemDTO>
    PendingChangesOriginal: Array<DeliveryPendingChangesItemDTO>

    LoadingPoints: Array<LoadingPointDTO>;
    Localities: Array<LocalityDTO>;
    HandlingTypes: Array<HandlingTypeDTO>;
    Payloads: Array<PayloadDTO>;

    ChangelogTableDataHelper: IDeliveryStateTableHelperProps;
    RoutePointsTableDataHelper: IRouteSegmentTableHelperProps;

    RouteSegments: Array<RouteSegmentDTO>;

    CargoTransporter: CargoTransporterDTO;
    Consignor       : ConsignorDTO;
    Vehicle         : VehicleDTO;
    Driver          : DriverDTO;
    Zone            : ZoneDTO;
    
    AdditionalFiles: Array<FileTableDTO>;
}

export const InitialDeliveryHelperProps: DeliveryHelperProps = <DeliveryHelperProps>{ 
    DeliveryStates        : [],
    DeliveryStatesOriginal: [],
    Complaints            : [],
    ComplaintsOriginal    : [],
    Bets                  : [],
    WinnersBets           : [],
    BetsOriginal          : [],
    PendingChanges        : [],
    PendingChangesOriginal: [],

    LoadingPoints: [],
    Localities   : [],
    HandlingTypes: [],
    Payloads     : [],

    ChangelogTableDataHelper: InitialDeliveryStateTableHelperProps,
    RoutePointsTableDataHelper: InitialRouteSegmentTableHelperProps,
    
    RouteSegments: [],

    AdditionalFiles: [],

    CargoTransporter: undefined,
    Vehicle         : undefined,
    Driver          : undefined
};

