import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import i18next from '../../../i18n';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaTableActions } from '../VolmaTableActions';
import { IVolmaTableAction } from '../IVolmaTableProps';
import { ITableDTO } from '../../../../Domain/ITableDTO';
import { VolmaTableAction } from '../VolmaTableAction';

export class LocalDeleteItemsAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _tableActions: VolmaTableActions;
    private _action: (ids: Array<string>) => void;

    public Title: string = i18next.t("common:DeleteSelectedItems");
    
    public IsAvailable(selectedIds: Array<string>){
        return selectedIds !== undefined && selectedIds.length > 0;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<ITableDTO>, dispatch: any, reloadData: () => void) {
        dispatch(this._modalActions.OpenConfirmationModal("common:AreYouSureYouWantToDeleteSelectedItems", () => {
            this._action(selectedIds);
        }));
    }

    constructor(action: (ids: Array<string>) => void){
        super();
        this._modalActions = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._tableActions = VolmaContainer.get<VolmaTableActions>(Types.VolmaTableActions);
        this._action = action;
    }
}