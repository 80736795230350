import { EEntityType } from '../../../../Domain/Enum/EEntityType';
import { VolmaContainer } from '../../../../Infrastructure/InversifyInject';
import { Types } from '../../../../Infrastructure/Types';
import { VolmaModalActions } from '../../../VolmaModal/VolmaModalActions';
import { DeliveryTableActions } from '../Table/DeliveryTableActions';
import i18next from '../../../i18n';
import { DeliveryTableDTO } from '../../../../Domain/DTO/DeliveryTableDTO';
import { EDeliveryState } from '../../../../Domain/Enum/EDeliveryState';
import { VolmaTableAction } from '../../../Table/VolmaTable/VolmaTableAction';
import { DeliveryStateDTO } from '../../../../Domain/DTO/DeliveryStateDTO';
import PropertyHelper from '../../../../Infrastructure/Services/PropertyHelper';
import { TableDataDTO } from '../../../../Domain/TableDataDTO';
import { isDefined } from '../../../../Infrastructure/Services/Utils';
import { DeliveryConfirmDTO } from '../ActionConfirm/DeliveryConfirmDTO';
import { FormatService } from '../../../../Infrastructure/Services/FormatService';
import { DeliveryConfirmHelperProps } from '../ActionConfirm/IHelperProps';
import { DeliveryDTO } from '../../../../Domain/DTO/DeliveryDTO';

export class AcceptDeliveriesAction<THelper> extends VolmaTableAction<THelper>{
    private _modalActions: VolmaModalActions;
    private _deliveryTableActions: DeliveryTableActions;
    private _formatService: FormatService;

    public Title: string = "delivery:AcceptDeliveries";

    constructor() {
        super();

        this._modalActions         = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._deliveryTableActions = VolmaContainer.get<DeliveryTableActions>(Types.DeliveryTableActions);
        this._formatService        = VolmaContainer.get<FormatService>(Types.FormatService);
    }
    
    public IsAvailable(selectedIds: Array<string>, data: Array<DeliveryTableDTO>): boolean {
        if (this._authService.IsCargoTransporter()) {
            if (!PropertyHelper.IsArray(selectedIds) || selectedIds.length < 1)
                return false;

            for (const selectedId of selectedIds) {
                const itm = data.find(x => x.Id === selectedId);

                if (itm === undefined)
                    return false;

                if (itm.State !== EDeliveryState.Assigned)
                    return false;
            }

            return true;
        }
        
        return false;
    }
    
    public OnActivate(name: string, entity: EEntityType, selectedIds: Array<string>, data: Array<DeliveryTableDTO>, dispatch: any, reloadData: () => void) {
        const identifiers = new Array<string>();

        for (const selectedId of selectedIds) {
            const itm = data.find(x => x.Id === selectedId);

            if (itm !== undefined)
                identifiers.push(itm.Identifier);
        }       

        const deliveryDTO = data.find((x) => x.Id === selectedIds[0]);

        if (this._formatService.IsValueNotEmpty(deliveryDTO.MultiCargoTransporterId)) {
            const dto = new DeliveryConfirmDTO();

            dto.MultiCargoTransporterId = deliveryDTO.MultiCargoTransporterId;
            dto.CargoTransporterId = undefined;
            dto.IsCargoTransporterAvailable = true;         

            const localEntityModalTitle = i18next.t("delivery:ChangeCargoTransporterAndAcceptDelivery");
            
            dispatch(
                this._modalActions.OpenLocalEntityModal(EEntityType.DeliveryConfirm, dto, localEntityModalTitle, (dto, dataHelper: DeliveryConfirmHelperProps) =>                                                       
                    this.OnAcceptedAndSavedCargoTransporter(dto, selectedIds, dispatch, reloadData, dataHelper)
                )
            );
        } else {
            const confirmModalTitle = i18next.t("delivery:AcceptSelectedDeliveries", { count: selectedIds.length, identifier: identifiers.join(', ') });

            dispatch(
                this._modalActions.OpenConfirmationModal(confirmModalTitle, () => 
                    this.OnAcceptedAndSavedCargoTransporter(undefined, selectedIds, dispatch, reloadData))
            );
        }
    }

    private OnAcceptedAndSavedCargoTransporter(
        dto: DeliveryConfirmDTO | undefined,
        selectedIds: Array<string>,
        dispatch: any,
        reloadData: () => void,
        dataHelper?: DeliveryConfirmHelperProps,
    ): void {        
        const tableDataDTO = new TableDataDTO<DeliveryStateDTO>();
        tableDataDTO.Items = new Array<DeliveryStateDTO>();
        tableDataDTO.Count = 0;

        for (const selectedId of selectedIds) {
            const stateDTO = new DeliveryStateDTO();

            stateDTO.DeliveryId = selectedId;
            stateDTO.DeliveryState = EDeliveryState.Accepted;

            if (isDefined(dto)) {
                stateDTO.CargoTransporterId = dto.CargoTransporterId || dataHelper.MultiCargoTransporter.MainCargoTransporter;
            }                   

            tableDataDTO.Items.push(stateDTO);
            tableDataDTO.Count++;
        }

        dispatch(this._deliveryTableActions.DeliveryAddStates(tableDataDTO, i18next.t("common:Loading"), reloadData));
    }    
}