import { injectable } from 'inversify';
import * as React from 'react';
import * as uuid from 'uuid';

import { CalendarEntryDTO } from '../../../Domain/DTO/CalendarEntryDTO';
import { ComplaintTemplateTableDTO } from '../../../Domain/DTO/ComplaintTemplateTableDTO';
import { SettingsDTO } from '../../../Domain/DTO/SettingsDTO';
import { TimeDTO } from '../../../Domain/DTO/TimeDTO';
import { EEntityType } from '../../../Domain/Enum/EEntityType';
import { ERateChangeType } from '../../../Domain/Enum/ERateChangeType';
import { VolmaContainer } from '../../../Infrastructure/InversifyInject';
import { AuthenticationService } from '../../../Infrastructure/Services/AuthService';
import PropertyHelper from '../../../Infrastructure/Services/PropertyHelper';
import { VolmaTableService } from '../../../Infrastructure/Services/VomaTableService';
import { Types } from '../../../Infrastructure/Types';
import { BaseValidator } from '../../../Infrastructure/Validation/BaseValidator';
import { EmptyValidator } from '../../../Infrastructure/Validation/EmptyValidator';
import { NonEmptyTextValidator } from '../../../Infrastructure/Validation/NonEmptyTextValidatorValidator';
import { NumberRangeHelper } from '../../../Infrastructure/Validation/NumberRangeHelper';
import { VolmaInputValidator } from '../../../Infrastructure/Validation/VolmaInputValidatorValidator';
import { VolmaNumberValidator } from '../../../Infrastructure/Validation/VolmaNumberValidator';
import { VolmaTimeTimeDTOValidator } from '../../../Infrastructure/Validation/VolmaTimeTimeDTOValidator';
import { FileExtendedDTO } from '../../File/FileExtendedDTO';
import i18next from '../../i18n';
import { LocalDeleteItemsAction } from '../../Table/VolmaTable/Actions/LocalDeleteItemsAction';
import ExperimentalVolmaTable from '../../Table/VolmaTable/ExperimentalVolmaTable';
import { IVolmaTableColumn } from '../../Table/VolmaTable/IVolmaTableProps';
import VolmaCalendar from '../../VolmaCalendar';
import VolmaCheckBox from '../../VolmaCheckBox';
import { IVolmaInputProps } from '../../VolmaInput/IVolmaInputProps';
import { VolmaModalActions } from '../../VolmaModal/VolmaModalActions';
import { IVolmaNumberProps } from '../../VolmaNumber/IVolmaNumberProps';
import VolmaNumber from '../../VolmaNumber/VolmaNumber';
import VolmaSelect from '../../VolmaSelect';
import VolmaTime from '../../VolmaTime';
import { IVolmaTimeTimeDTOProps } from '../../VolmaTimeTimeDTO/IVolmaTimeTimeDTOProps';
import { VolmaTimeTimeDTO } from '../../VolmaTimeTimeDTO/VolmaTimeTimeDTO';
import { BaseEntityService } from '../BaseEntity/BaseEntityService';
import { CalendarEntryEntityService } from '../CalendarEntry/CalendarEntryEntityService';
import { ISettingsHelperProps } from './ISettingsHelperProps';
import { ISettingsProps } from './ISettingsProps';
import { SettingsActions } from './SettingsActions';
import { SettingsReducer } from './SettingsReducer';

@injectable()
export class SettingsEntityService extends BaseEntityService<SettingsDTO, any>{
    private _inputValidator: BaseValidator<IVolmaInputProps>;
    private _numberValidator: BaseValidator<IVolmaNumberProps>;
    private _timeTimeDTOValidator: BaseValidator<IVolmaTimeTimeDTOProps>;
    private _nonEmptyInputValidator: BaseValidator<IVolmaInputProps>;
    private _emptyValidator: EmptyValidator;
    private _numberInputValidator: BaseValidator<IVolmaNumberProps>;

    private _volmaTableService: VolmaTableService;

    private _actions: SettingsActions;
    private _reducer: SettingsReducer;
    private _volmaModalAction: VolmaModalActions;
    private _calendarEntryEntityService: CalendarEntryEntityService;

    private _calendarEntryColumns: Array<IVolmaTableColumn>;

    private _dispatch: any;

    constructor() {
        super();
        this._emptyValidator = VolmaContainer.get<EmptyValidator>(Types.EmptyValidator);
        this._nonEmptyInputValidator = VolmaContainer.get<NonEmptyTextValidator>(Types.NonEmptyTextValidator);
        this._inputValidator = VolmaContainer.get<VolmaInputValidator>(Types.VolmaInputValidator);
        this._timeTimeDTOValidator = VolmaContainer.get<VolmaTimeTimeDTOValidator>(Types.VolmaTimeTimeDTOValidator);

        this._numberValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);

        this._volmaTableService = VolmaContainer.get<VolmaTableService>(Types.VolmaTableService);

        this._actions = VolmaContainer.get<SettingsActions>(Types.SettingsActions);
        this._volmaModalAction = VolmaContainer.get<VolmaModalActions>(Types.VolmaModalActions);
        this._authService = VolmaContainer.get<AuthenticationService>(Types.AuthenticationService);
        this._calendarEntryEntityService = VolmaContainer.get<CalendarEntryEntityService>(Types.CalendarEntryEntityService);
        this._numberInputValidator = VolmaContainer.get<VolmaNumberValidator>(Types.VolmaNumberValidator);


        this._calendarEntryColumns = this._calendarEntryEntityService.GetColumnsList();
    }

    public GetColumnsList(): Array<IVolmaTableColumn> {
        return [];
    }

    public GetEditorModal(props: ISettingsProps): JSX.Element {
        return undefined;
    }

    public GetInitialDataHelper() {
        return {};
    }

    public GetReducer() {
        return this._reducer;
    }

    public IsEditable(props: ISettingsProps): boolean {
        return this._authorizationService.IsEditable(EEntityType.Settings);
    }

    public GetHeaderEditor(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._createCard("header")).toString()}>
                <span className={(this._createCard("title-icon")).toString()}>
                    <svg className={(this._createCard("title-ico").mix(["info-block-ico"])).toString()}>
                        <use xlinkHref="#info-block"></use>
                    </svg>
                    <svg className={(this._createCard("title-ico-bg").mix(["info-bg-ico"])).toString()}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#info-block"></use>
                    </svg>
                </span>
                <span className={(this._createCard("header", {text: true })).toString()}>{i18next.t("settings:SettingsTitle")}</span>
            </div>
        );
    }

    public GetEditor(props: ISettingsProps): JSX.Element {
        if (props.DataDTO === undefined)
            return;

        let isEditable = this.IsEditable(props);
        if (isEditable)
            return this.GetEditableEditor(props);
        return this.GetReadonlyEditor(props);
    }

    public InitializeEntity(props: ISettingsProps) {
        this._dispatch = props.dispatch;
        this._dispatch(this._actions.LoadComplaintTemplates(i18next.t("common:Loading")));
        this._dispatch(this._actions.SetEntityType(EEntityType.Settings));
        this._dispatch(this._actions.ClearFooter());
    }

    public OnAfterDataLoaded(dto: SettingsDTO): void {
    }

    protected OnAddCalendarEntryRequired(props: ISettingsProps) {
        let calendarEntry = new CalendarEntryDTO();
        calendarEntry.Id = uuid.v4();
        this._dispatch(this._volmaModalAction.OpenLocalEntityModal(EEntityType.CalendarEntry, calendarEntry, undefined, x =>
            this._dispatch(this._actions.CalendarEntryAdded(x))));
    }

    protected OnCalendarEntryEditRequired(props: ISettingsProps, id: string) {
        let calendarEntry = props.DataDTO.BusinessCalendar.find(x => x.Id === id);
        this._dispatch(this._volmaModalAction.OpenLocalEntityModal(EEntityType.CalendarEntry, calendarEntry, undefined, x =>
            this._dispatch(this._actions.CalendarEntryUpdated(x))));
    }

    private OnLoadCalendarRequired(props: ISettingsProps){
        this._dispatch(this._volmaModalAction.OpenFileUploadModal(i18next.t("settings:BusinessCalendar"), (x: FileExtendedDTO) =>
            {
                this._dispatch(this._actions.ImportData(x.FileId, i18next.t("settings:BusinessCalendarLoading")));
            }));
    }

    private RemoveCalendarEntries(ids: string[]) {
        this._dispatch(this._actions.CalendarEntriesRemoved(ids));
    }

    private GetReadonlyEditor(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("settings:TimeSettingsTitle"), i18next.t("settings:TimeSettingsDescription"), this.GetReadonlyTimeInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("settings:PercentSettingsTitle"), i18next.t("settings:PercentSettingsDescription"), this.GetReadonlyPercentInfoRows(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetReadonlyPercentAdditionalInfoRows(props))}
                    </div>
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 2, i18next.t("settings:RateSettingsTitle"), i18next.t("settings:RateSettingsDescription"), this.GetReadonlyRateInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 3, i18next.t("settings:WorkTimeSettingsTitle"), i18next.t("settings:WorkTimeSettingsDescription"), this.GetReadonlyWorkTimeInfoRows(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.GetReadonlyHolidayTable(props))}
                    </div>
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 5, i18next.t("settings:RemoveDataSettingsTitle"), i18next.t("settings:RemoveDataSettingsDescription"), this.GetReadonlyRemoveDataSettingsInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[5] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 4, i18next.t("settings:ComplaintTemplateSettingsTitle"), i18next.t("settings:ComplaintTemplateSettingsDescription"), this.GetReadonlyComplaintTemplateInfoRows(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    private GetEditableEditor(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo()).toString()}>
                <div className={(this._titleDropParent.mix([props.ClosedCardParts[0] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 0, i18next.t("settings:TimeSettingsTitle"), i18next.t("settings:TimeSettingsDescription"), this.GetTimeInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[1] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 1, i18next.t("settings:PercentSettingsTitle"), i18next.t("settings:PercentSettingsDescription"), this.GetPercentInfoRows(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(true, false, this.GetPercentAdditionalInfoRows(props))}
                    </div>
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[2] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 2, i18next.t("settings:RateSettingsTitle"), i18next.t("settings:RateSettingsDescription"), this.GetRateInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[3] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 3, i18next.t("settings:WorkTimeSettingsTitle"), i18next.t("settings:WorkTimeSettingsDescription"), this.GetWorkTimeInfoRows(props), undefined, props, this._dispatch)}
                    <div className={(this._infoDrop).toString()}>
                        {this.GetMainInfoNoTitleItem(false, true, this.GetHolidayTable(props))}
                    </div>
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[4] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 4, i18next.t("settings:RemoveDataSettingsTitle"), i18next.t("settings:RemoveDataSettingsDescription"), this.GetRemoveDataSettingsInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[5] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(false, true, true, 5, i18next.t("settings:ComplaintTemplateSettingsTitle"), i18next.t("settings:ComplaintTemplateSettingsDescription"), this.GetComplaintTemplateInfoRows(props), undefined, props, this._dispatch)}
                </div>

                <div className={(this._titleDropParent.mix([props.ClosedCardParts[6] ? "parent-close" : ""])).toString()}>
                    {this.GetMainInfoItem(true, false, true, 6, i18next.t("settings:ScoreTemplateSettingsTitle"), i18next.t("settings:ScoreTemplateSettingsDescription"), this.GetScoreInfoRows(props), undefined, props, this._dispatch)}
                </div>
            </div>
        );
    }

    private GetScoreInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.ScoreForLoadingPoint) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.ScoreForLoadingPoint)}
                                Label={i18next.t('settings:ScoreForLoadingPointPlaceholder')}
                                Placeholder={i18next.t('settings:ScoreForLoadingPointPlaceholder')}
                                Value={props.DataDTO.ScoreForLoadingPoint}
                                MinValue={NumberRangeHelper.minPositiveValue}
                                MaxValue={NumberRangeHelper.million}
                                Validator={this._numberInputValidator}
                                Readonly={false}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.ScoreForUnloadingPoint) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.ScoreForUnloadingPoint)}
                                Label={i18next.t('settings:ScoreForUnloadingPointPlaceholder')}
                                Placeholder={i18next.t('settings:ScoreForUnloadingPointPlaceholder')}
                                Value={props.DataDTO.ScoreForUnloadingPoint}
                                MinValue={NumberRangeHelper.minPositiveValue}
                                MaxValue={NumberRangeHelper.million}
                                Validator={this._numberInputValidator}
                                Readonly={false}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                        <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.ProfileScore) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.ProfileScore)}
                                Label={i18next.t('settings:ProfileScorePlaceholder')}
                                Placeholder={i18next.t('settings:ProfileScorePlaceholder')}
                                Value={props.DataDTO.ProfileScore}
                                MinValue={NumberRangeHelper.minPositiveValue}
                                MaxValue={NumberRangeHelper.million}
                                Validator={this._numberInputValidator}
                                Readonly={false}
                                dispatch={props.dispatch} />
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    private GetTimeInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.BiddingDuration) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.BiddingDuration)}
                                Label={i18next.t('settings:BiddingDuration')}
                                Placeholder={i18next.t('settings:PlaceholderBiddingDuration')}
                                Value={props.DataDTO.BiddingDuration}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.ApproveDuration) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.ApproveDuration)}
                                Label={i18next.t('settings:ApproveDuration')}
                                Placeholder={i18next.t('settings:PlaceholderApproveDuration')}
                                Value={props.DataDTO.ApproveDuration}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.AcceptationDurationAssignedByTender) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.AcceptationDurationAssignedByTender)}
                                Label={i18next.t('settings:AcceptationDurationAssignedByTender')}
                                Placeholder={i18next.t('settings:PlaceholderAcceptationDurationAssignedByTender')}
                                Value={props.DataDTO.AcceptationDurationAssignedByTender}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.AcceptationDurationAssignedByBidding) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.AcceptationDurationAssignedByBidding)}
                                Label={i18next.t('settings:AcceptationDurationAssignedByBidding')}
                                Placeholder={i18next.t('settings:PlaceholderAcceptationDurationAssignedByBidding')}
                                Value={props.DataDTO.AcceptationDurationAssignedByBidding}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.AcceptationDurationAssignedByRating) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.AcceptationDurationAssignedByRating)}
                                Label={i18next.t('settings:AcceptationDurationAssignedByRating')}
                                Placeholder={i18next.t('settings:PlaceholderAcceptationDurationAssignedByRating')}
                                Value={props.DataDTO.AcceptationDurationAssignedByRating}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.AcceptationDurationAssignedByHand) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.AcceptationDurationAssignedByHand)}
                                Label={i18next.t('settings:AcceptationDurationAssignedByHand')}
                                Placeholder={i18next.t('settings:PlaceholderAcceptationDurationAssignedByHand')}
                                Value={props.DataDTO.AcceptationDurationAssignedByHand}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>

                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.RequestDurationInNewStatus) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.RequestDurationInNewStatus)}
                                Label={i18next.t('settings:RequestDurationInNewStatus')}
                                Placeholder={i18next.t('settings:PlaceholderRequestDurationInNewStatus')}
                                Value={props.DataDTO.RequestDurationInNewStatus}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.TimeToChangeRequestStatusToDelivered) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.TimeToChangeRequestStatusToDelivered)}
                                Label={i18next.t('settings:TimeToChangeRequestStatusToDelivered')}
                                Placeholder={i18next.t('settings:PlaceholderTimeToChangeRequestStatusToDelivered')}
                                Value={props.DataDTO.TimeToChangeRequestStatusToDelivered}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.TimeToChangeRequestStatusToClosed) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.TimeToChangeRequestStatusToClosed)}
                                Label={i18next.t('settings:TimeToChangeRequestStatusToClosed')}
                                Placeholder={i18next.t('settings:PlaceholderTimeToChangeRequestStatusToClosed')}
                                Value={props.DataDTO.TimeToChangeRequestStatusToClosed}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.ThresholdBiddingRestartTime ) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.ThresholdBiddingRestartTime )}
                                Label={i18next.t('settings:ThresholdBiddingRestartTime')}
                                Placeholder={i18next.t('settings:PlaceholderThresholdBiddingRestartTime')}
                                Value={props.DataDTO.ThresholdBiddingRestartTime}
                                Validator={this._emptyValidator}
                                dispatch={props.dispatch}/>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    private GetPercentInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.RequestCountThreshold) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.RequestCountThreshold)}
                                Label={i18next.t('settings:RequestCountThreshold')}
                                Placeholder={i18next.t('settings:PlaceholderRequestCountThreshold')}
                                Value={props.DataDTO.RequestCountThreshold}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                Step={1}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.MaxStandardDeviation) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.MaxStandardDeviation)}
                                Label={i18next.t('settings:MaxStandardDeviation')}
                                Placeholder={i18next.t('settings:PlaceholderMaxStandardDeviation')}
                                Value={props.DataDTO.MaxStandardDeviation}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.MaxPercentageOfBetIncrementation) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.MaxPercentageOfBetIncrementation)}
                                Label={i18next.t('settings:MaxPercentageOfBetIncrementation')}
                                Placeholder={i18next.t('settings:PlaceholderMaxPercentageOfBetIncrementation')}
                                Value={props.DataDTO.MaxPercentageOfBetIncrementation}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                            <div className={this._mainInfo("line", { full: true })}>
                                <VolmaCheckBox
                                    {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.SkipRatingForOverdue) }
                                    Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.SkipRatingForOverdue)} 
                                    Label={i18next.t('settings:SkipRatingForOverdue')}
                                    Checked={props.DataDTO.SkipRatingForOverdue}
                                    Required={false}
                                    dispatch={props.dispatch}
                                />
                            </div>                        
                    </div>                   
                </div>
            </div>
        );
    }

    private GetPercentAdditionalInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("top")).toString()}>
                    <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("settings:TransportationThreshold")}</h2>
                </div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 25: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.SuccessPercentThresholdForGroupA) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.SuccessPercentThresholdForGroupA)}
                                Label={i18next.t('settings:SuccessPercentThresholdForGroupA')}
                                Placeholder={i18next.t('settings:PlaceholderSuccessPercentThresholdForGroupA')}
                                Value={props.DataDTO.SuccessPercentThresholdForGroupA}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 25: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.SuccessPercentThresholdForGroupB) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.SuccessPercentThresholdForGroupB)}
                                Label={i18next.t('settings:SuccessPercentThresholdForGroupB')}
                                Placeholder={i18next.t('settings:PlaceholderSuccessPercentThresholdForGroupB')}
                                Value={props.DataDTO.SuccessPercentThresholdForGroupB}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 25: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.SuccessPercentThresholdForGroupC) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.SuccessPercentThresholdForGroupC)}
                                Label={i18next.t('settings:SuccessPercentThresholdForGroupC')}
                                Placeholder={i18next.t('settings:PlaceholderSuccessPercentThresholdForGroupC')}
                                Value={props.DataDTO.SuccessPercentThresholdForGroupC}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 25: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.SuccessPercentThresholdForGroupOutsiders) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.SuccessPercentThresholdForGroupOutsiders)}
                                Label={i18next.t('settings:SuccessPercentThresholdForGroupOutsiders')}
                                Placeholder={i18next.t('settings:PlaceholderSuccessPercentThresholdForGroupOutsiders')}
                                Value={props.DataDTO.SuccessPercentThresholdForGroupOutsiders}
                                Validator={this._numberValidator}
                                MinValue={() => 0}
                                MaxValue={() => 100}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetRateInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.RateChangeForAGroup) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.RateChangeForAGroup)}
                                Label={i18next.t('settings:RateChangeForAGroup')}
                                Placeholder={i18next.t('settings:PlaceholderRateChangeForAGroup')}
                                Value={props.DataDTO.RateChangeForAGroup}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.RateChangeTypeForAGroup) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.RateChangeTypeForAGroup)}
                                Label={i18next.t('settings:PlaceholderRateChangeTypeForAGroup')}
                                Placeholder={i18next.t('settings:PlaceholderRateChangeTypeForAGroup')}
                                Value={props.DataDTO.RateChangeTypeForAGroup}
                                EnumGetter={() => ERateChangeType}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.RateChangeForCGroup) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.RateChangeForCGroup)}
                                Label={i18next.t('settings:RateChangeForCGroup')}
                                Placeholder={i18next.t('settings:PlaceholderRateChangeForCGroup')}
                                Value={props.DataDTO.RateChangeForCGroup}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.RateChangeTypeForCGroup) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.RateChangeTypeForCGroup)}
                                Label={i18next.t('settings:PlaceholderRateChangeTypeForCGroup')}
                                Placeholder={i18next.t('settings:PlaceholderRateChangeTypeForCGroup')}
                                Value={props.DataDTO.RateChangeTypeForCGroup}
                                EnumGetter={() => ERateChangeType}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.EditingRateStartDay) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.EditingRateStartDay)}
                                Label={i18next.t('settings:EditingRateStartDay')}
                                Placeholder={i18next.t('settings:PlaceholderEditingRateStartDay')}
                                Value={props.DataDTO.EditingRateStartDay}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.EditingRateEndDay ) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.EditingRateEndDay )}
                                Label={i18next.t('settings:EditingRateEndDay')}
                                Placeholder={i18next.t('settings:PlaceholderEditingRateEndDay')}
                                Value={props.DataDTO.EditingRateEndDay }
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.RateMinimumNumber) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.RateMinimumNumber)}
                                Label={i18next.t('settings:RateMinimumNumber')}
                                Placeholder={i18next.t('settings:PlaceholderRateMinimumNumber')}
                                Value={props.DataDTO.RateMinimumNumber}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>

                    </div>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.MinimumSuccessDeliveriesNumber) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.MinimumSuccessDeliveriesNumber)}
                                Label={i18next.t('settings:MinimumSuccessDeliveriesNumber')}
                                Placeholder={i18next.t('settings:PlaceholderMinimumSuccessDeliveriesNumber')}
                                Value={props.DataDTO.MinimumSuccessDeliveriesNumber}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetWorkTimeInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.WorkingDays) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.WorkingDays)}
                                Label={i18next.t('settings:WorkingDays')}
                                Placeholder={i18next.t('settings:PlaceholderWorkingDays')}
                                Value={props.DataDTO.WorkingDays}
                                MinValue={() => 0}
                                MaxValue={() => 7}
                                Validator={this._numberValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.WorkingTimeStart) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.WorkingTimeStart)}
                                Label={i18next.t('settings:WorkingTimeStart')}
                                Placeholder={i18next.t('settings:PlaceholderWorkingTimeStart')}
                                Value={props.DataDTO.WorkingTimeStart}
                                Validator={this._timeTimeDTOValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTimeTimeDTO
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.WorkingTimeEnd) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.WorkingTimeEnd)}
                                Label={i18next.t('settings:WorkingTimeEnd')}
                                Placeholder={i18next.t('settings:PlaceholderWorkingTimeEnd')}
                                Value={props.DataDTO.WorkingTimeEnd}
                                Validator={this._timeTimeDTOValidator}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetHolidayTable(props: ISettingsProps): JSX.Element {
        try
        {
            props.DataDTO.BusinessCalendar = props.DataDTO.BusinessCalendar.sort((b, a) => (a.Date.Year - b.Date.Year)*10000 + (a.Date.Month - b.Date.Month)*100 + (a.Date.Day - b.Date.Day));
        }
        catch(ex){
            console.warn("Failed to sort array" , ex);
        }

        return (
            <div>
                <div className={(this._mainInfo("top")).toString()}>
                    <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("settings:HolidayTableTitle")}</h2>
                    <a className={(this._tableButton("add").mix([this._btn(), this._btnTable({bg: true, green: true })])).toString()} onClick={this.OnAddCalendarEntryRequired.bind(this)}>
                        <svg className={(this._bgIco.mix([this._bgPlusIco()])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                        </svg>
                        <span className={(this._btn("text")).toString()}>{i18next.t("settings:AddHolidayButtonTitle")}</span>
                    </a>
                    <a className={(this._tableButton("add").mix([this._btn(), this._btnTable({bg: true, green: true })])).toString()} onClick={this.OnLoadCalendarRequired.bind(this)}>
                        <svg className={(this._bgIco.mix([this._bgPlusIco()])).toString()}>
                            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#plus"></use>
                        </svg>
                        <span className={(this._btn("text")).toString()}>{i18next.t("settings:UploadBusinessCalendarButton")}</span>
                    </a>
                </div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <ExperimentalVolmaTable
                                {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: CalendarEntryDTO) => val.Date) }
                                Name={PropertyHelper.GetPropertyName((x: CalendarEntryDTO) => x.Date)}
                                Columns={this._calendarEntryColumns}
                                Data={props.DataDTO.BusinessCalendar}
                                DataOriginal={props.DataDTO.BusinessCalendar}
                                LoadDataFromServer={false}
                                IsTableInsideEntity={true}
                                dispatch={props.dispatch}
                                OnRowEditRequired={((rowData: CalendarEntryDTO) => this.OnCalendarEntryEditRequired(props, rowData.Id)).bind(this)}
                                Actions={[new LocalDeleteItemsAction<any>((ids: Array<string>) => { this.RemoveCalendarEntries(ids) })]}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetComplaintTemplateInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.TemplateForCargotransporterLateForLoadingComplaintId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.TemplateForCargotransporterLateForLoadingComplaintId)}
                                Options={props.DataHelper.ComplaintTemplateList}
                                LabelKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name)}
                                ValueKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Id)}
                                Label={i18next.t('settings:PlaceholderTemplateForCargotransporterLateForLoadingComplaint')}
                                Placeholder={i18next.t('settings:PlaceholderTemplateForCargotransporterLateForLoadingComplaint')}
                                Value={props.DataHelper.SelectedTemplateForCargotransporterLateForLoadingComplaint}
                                HelperFieldName={PropertyHelper.GetPropertyName((val: ISettingsHelperProps) => val.SelectedTemplateForCargotransporterLateForLoadingComplaint)}
                                HelperFieldToDTOFieldTranslator={(x: ComplaintTemplateTableDTO) => x.Id}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.TemplateForCargoTransporterSupplyDisruptionComplaintId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.TemplateForCargoTransporterSupplyDisruptionComplaintId)}
                                Options={props.DataHelper.ComplaintTemplateList}
                                LabelKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name)}
                                ValueKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Id)}
                                Label={i18next.t('settings:PlaceholderTemplateForCargoTransporterSupplyDisruptionComplaint')}
                                Placeholder={i18next.t('settings:PlaceholderTemplateForCargoTransporterSupplyDisruptionComplaint')}
                                Value={props.DataHelper.SelectedTemplateForCargoTransporterSupplyDisruptionComplaint}
                                HelperFieldName={PropertyHelper.GetPropertyName((val: ISettingsHelperProps) => val.SelectedTemplateForCargoTransporterSupplyDisruptionComplaint)}
                                HelperFieldToDTOFieldTranslator={(x: ComplaintTemplateTableDTO) => x.Id}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    {/*<div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId)}
                                Options={props.DataHelper.ComplaintTemplateList}
                                LabelKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name)}
                                ValueKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Id)}
                                Label={i18next.t('settings:PlaceholderTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint')}
                                Placeholder={i18next.t('settings:PlaceholderTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint')}
                                Value={props.DataHelper.SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint}
                                HelperFieldName={PropertyHelper.GetPropertyName((val: ISettingsHelperProps) => val.SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint)}
                                HelperFieldToDTOFieldTranslator={(x: ComplaintTemplateTableDTO) => x.Id}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell", { 33: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaSelect
                                {...(PropertyHelper.GetSelectPropertyByName(props.Selects, (val: SettingsDTO) => val.TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId) as any) }
                                Name={PropertyHelper.GetPropertyName((x: SettingsDTO) => x.TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId)}
                                Options={props.DataHelper.ComplaintTemplateList}
                                LabelKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Name)}
                                ValueKey={PropertyHelper.GetPropertyName((x: ComplaintTemplateTableDTO) => x.Id)}
                                Label={i18next.t('settings:PlaceholderTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint')}
                                Placeholder={i18next.t('settings:PlaceholderTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint')}
                                Value={props.DataHelper.SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint}
                                HelperFieldName={PropertyHelper.GetPropertyName((val: ISettingsHelperProps) => val.SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint)}
                                HelperFieldToDTOFieldTranslator={(x: ComplaintTemplateTableDTO) => x.Id}
                                dispatch={props.dispatch} />
                        </div>
                    </div>*/}
                </div>
            </div>
        );
    }

    private GetRemoveDataSettingsInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell").toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaNumber
                                {...PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.StorageDuration) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.StorageDuration)}
                                Label={i18next.t('settings:StorageDuration')}
                                Placeholder={i18next.t('settings:PlaceholderStorageDuration')}
                                Value={props.DataDTO.StorageDuration}
                                MinValue={() => 0}
                                MaxValue={() => 10}
                                Validator={this._numberValidator}
                                Step={1}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                    <div className={this._mainInfo("cell").toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaCalendar
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.DeleteDate) as any) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.DeleteDate)}
                                Label={i18next.t('settings:DeleteDateDateDescription')}
                                Value={props.DataDTO.DeleteDate}
                                AdditionalText={i18next.t('settings:DeleteDateDateAdditionalInfo')}
                                dispatch={props.dispatch} />
                        </div>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <VolmaTime
                                {...(PropertyHelper.GetInputPropertyByName(props.Inputs, (val: SettingsDTO) => val.DeleteDate) as any) }
                                Name={PropertyHelper.GetPropertyName((val: SettingsDTO) => val.DeleteDate)}
                                Label={i18next.t('settings:DeleteDateTime')}
                                Required={true}
                                Value={props.DataDTO.DeleteDate}
                                AdditionalText={i18next.t('settings:DeleteDateTimeAdditionalInfo')}
                                dispatch={props.dispatch} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetReadonlyTimeInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:BiddingDuration")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.BiddingDuration))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:ApproveDuration")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ApproveDuration))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:AcceptationDurationAssignedByTender")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.AcceptationDurationAssignedByTender))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:AcceptationDurationAssignedByBidding")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.AcceptationDurationAssignedByBidding))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:AcceptationDurationAssignedByRating")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.AcceptationDurationAssignedByRating))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:AcceptationDurationAssignedByHand")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.AcceptationDurationAssignedByHand))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:RequestDurationInNewStatus")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.RequestDurationInNewStatus))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:TimeToChangeRequestStatusToDelivered")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.TimeToChangeRequestStatusToDelivered))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:TimeToChangeRequestStatusToClosed")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.TimeToChangeRequestStatusToClosed))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:ThresholdBiddingRestartTime")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.ThresholdBiddingRestartTime))}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    private GetReadonlyPercentInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={(this._mainInfoSpecification).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:RequestCountThreshold")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.RequestCountThreshold)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:MaxStandardDeviation")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.MaxStandardDeviation)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:MaxPercentageOfBetIncrementation")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.MaxPercentageOfBetIncrementation)}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private GetReadonlyPercentAdditionalInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("top")).toString()}>
                    <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("settings:TransportationThreshold")}</h2>
                </div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={(this._mainInfo("cell")).toString()}>
                        <div className={(this._mainInfoSpecification).toString()}>
                            <dl className={(this._mainInfoSpecification("box")).toString()}>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:SuccessPercentThresholdForGroupA")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.SuccessPercentThresholdForGroupA)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:SuccessPercentThresholdForGroupB")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.SuccessPercentThresholdForGroupB)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:SuccessPercentThresholdForGroupC")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.SuccessPercentThresholdForGroupC)}</dd>
                                </div>
                                <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:SuccessPercentThresholdForGroupOutsiders")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.SuccessPercentThresholdForGroupOutsiders)}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetReadonlyRateInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:RateChangeForAGroup")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>
                                    {PropertyHelper.SafeGetValue(props.DataDTO, x => x.RateChangeForAGroup)}
                                    {" "}
                                    {this._enumService.GetOptionLocalized(() => ERateChangeType, PropertyHelper.SafeGetValue(props.DataDTO, x => x.RateChangeTypeForAGroup))}
                                </dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:RateChangeForCGroup")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>
                                    {PropertyHelper.SafeGetValue(props.DataDTO, x => x.RateChangeForCGroup)}
                                    {" "}
                                    {this._enumService.GetOptionLocalized(() => ERateChangeType, PropertyHelper.SafeGetValue(props.DataDTO, x => x.RateChangeTypeForCGroup))}
                                </dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:EditingRateStartDay")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.EditingRateStartDay)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:EditingRateEndDay")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.EditingRateEndDay)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:RateMinimumNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.RateMinimumNumber)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                    <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:MinimumSuccessDeliveriesNumber")}:</dt>
                                    <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.MinimumSuccessDeliveriesNumber)}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    private GetReadonlyWorkTimeInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:WorkingDays")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.WorkingDays)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:WorkingTimeStart")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WorkingTimeStart))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:WorkingTimeEnd")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatTimeDTO(PropertyHelper.SafeGetValue(props.DataDTO, x => x.WorkingTimeEnd))}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    private GetReadonlyHolidayTable(props: ISettingsProps): JSX.Element {
        return (
            <div>
                <div className={(this._mainInfo("top")).toString()}>
                    <h2 className={(this._mainInfo("title")).toString()}>{i18next.t("settings:HolidayTableTitle")}</h2>
                </div>
                <div className={(this._mainInfo("row")).toString()}>
                    <div className={this._mainInfo("cell", { full: true, 100: true }).toString()}>
                        <div className={this._mainInfo("line", { fill: true }).toString()}>
                            <ExperimentalVolmaTable
                                {...PropertyHelper.GetTablePropertyByName(props.Tables, (val: CalendarEntryDTO) => val.Date) }
                                Name={PropertyHelper.GetPropertyName((x: CalendarEntryDTO) => x.Date)}
                                Columns={this._calendarEntryColumns}
                                Data={props.DataDTO.BusinessCalendar}
                                DataOriginal={props.DataDTO.BusinessCalendar}
                                LoadDataFromServer={false}
                                IsTableInsideEntity={true}
                                dispatch={props.dispatch}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private GetReadonlyComplaintTemplateInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:TemplateForCargotransporterLateForLoadingComplaintId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedTemplateForCargotransporterLateForLoadingComplaint, x => x.Name)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:PlaceholderTemplateForCargoTransporterSupplyDisruptionComplaint")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedTemplateForCargoTransporterSupplyDisruptionComplaint, x => x.Name)}</dd>
                            </div>
                            {/*<div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:TemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaintId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedTemplateForCargotransporterNotAcceptRequestAssignedByTenderComplaint, x => x.Name)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:TemplateForCargotransporterNotApproveRequestAssignedByTenderComplaintId")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue2(props.DataHelper, x => x.SelectedTemplateForCargotransporterNotApproveRequestAssignedByTenderComplaint, x => x.Name)}</dd>
                            </div>*/}
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    private GetReadonlyRemoveDataSettingsInfoRows(props: ISettingsProps): JSX.Element {
        return (
            <div className={(this._mainInfo("row")).toString()}>
                <div className={(this._mainInfo("cell")).toString()}>
                    <div className={(this._mainInfoSpecification).toString()}>
                        <dl className={(this._mainInfoSpecification("box")).toString()}>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:StorageDuration")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{PropertyHelper.SafeGetValue(props.DataDTO, x => x.StorageDuration)}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:DeleteDateDate")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.FormatDeleteDate(PropertyHelper.SafeGetValue(props.DataDTO, x => x.DeleteDate))}</dd>
                            </div>
                            <div className={(this._mainInfoSpecification("row")).toString()}>
                                <dt className={(this._mainInfoSpecification("text")).toString()}>{i18next.t("settings:DeleteDateTime")}:</dt>
                                <dd className={(this._mainInfoSpecification("val")).toString()}>{this.GetSafeTime(PropertyHelper.SafeGetValue(props.DataDTO, x => x.DeleteDate))}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }

    private FormatDeleteDate(date: Date) {
        if (!this.IsDateValid(date))
            return i18next.t("common:NoData");

        var localDate = this.GetLocalDate(date);
        return `${localDate.date()} ${i18next.t(`common:${localDate.format("MMMM")}`)}`;
    }

    private FormatTimeDTO(time: TimeDTO){
        return i18next.t("common:TimeDTO", time)
    }
}