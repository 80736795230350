import { SearchResultDTO } from '../../../Domain/DTO/SearchResultDTO';


export interface ISearchHelperProps<TReportParams>{
    SearchResults: Array<SearchResultDTO>;
    PageNumber: number;
    TotalPages: number;
    ItemsPerPage: number;
    TotalItems: number;
}

export const InitialSearchHelperProps: ISearchHelperProps<any> = { 
    SearchResults: [],
    PageNumber: 1,
    TotalPages: undefined,
    ItemsPerPage: 250,
    TotalItems: undefined
};